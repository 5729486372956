import React, { useEffect, useMemo } from "react";
import { MessageContainer } from "components/messageContainer";
import {
  MessageData,
  convertOneToOneChimeMessagesToCustomMessageData
} from "components/messageContainer/messageContainer";
import { SelectedConversationUserInfo } from "pages/messages/messages";
import { useQuery } from "react-query";
import Skeleton from "antd/lib/skeleton";
import { createApiClient } from "apiClient";

interface AllMessagesSectionProps {
  selectedConversationUserInfo: SelectedConversationUserInfo | null;
  onCloseMessageContainer: () => void;
}

interface ProviderData {
  readonly name: string;
  readonly speciality: string;
  readonly subject: string;
  readonly degree: string;
  readonly picture: string;
}

export const AllMessagesSection: React.FC<AllMessagesSectionProps> = ({
  selectedConversationUserInfo,
  onCloseMessageContainer
}) => {
  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchChannelMessages
  } = useQuery(
    [
      "patient_all_view_selected_channel_messages",
      selectedConversationUserInfo
    ],
    async () => {
      return await createApiClient().listChannelMessages(
        selectedConversationUserInfo?.channel.channel_name || ""
      );
    },
    { enabled: selectedConversationUserInfo ? true : false }
  );

  useEffect(() => {
    if (selectedConversationUserInfo) {
      refetchChannelMessages();
    }
  }, [selectedConversationUserInfo]);

  const isChannelMessagesLoading = useMemo(
    () => isLoading || isFetching,
    [isFetching, isLoading]
  );

  const initialMessagesList: MessageData[] = useMemo(() => {
    const prvUsername = selectedConversationUserInfo?.channel.user1_uname || "";
    return convertOneToOneChimeMessagesToCustomMessageData(
      selectedConversationUserInfo,
      data?.messages || [],
      prvUsername
    );
  }, [data]);

  const messagesListNextToken = useMemo(() => data?.next_token || null, [data]);

  const selectedProviderData: ProviderData = useMemo(() => {
    if (selectedConversationUserInfo) {
      const prvUsername = selectedConversationUserInfo.channel.user1_uname;
      const patUsername = selectedConversationUserInfo.channel.pat_uname;
      return {
        name: `${
          selectedConversationUserInfo.userInfo[prvUsername].first_name || ""
        } ${
          selectedConversationUserInfo.userInfo[prvUsername].last_name || ""
        }`,
        speciality:
          selectedConversationUserInfo.userInfo[prvUsername].specialty || "",
        degree: selectedConversationUserInfo.userInfo[prvUsername].degree || "",
        picture: "",
        subject: `${
          selectedConversationUserInfo.userInfo[patUsername].first_name || ""
        } ${selectedConversationUserInfo.userInfo[patUsername].last_name || ""}`
      };
    }
    return {
      name: "",
      speciality: "",
      subject: "",
      degree: "",
      picture: ""
    };
  }, [selectedConversationUserInfo]);

  return isChannelMessagesLoading ? (
    <Skeleton active />
  ) : (
    <MessageContainer
      messageItemWidth="80%"
      allMessagesFormat={false}
      sender={selectedProviderData.name}
      specialization={selectedProviderData.speciality}
      subject={selectedProviderData.subject}
      degree={selectedProviderData.degree}
      picture={selectedProviderData.picture}
      messageData={initialMessagesList}
      style={{
        width: "calc(100% + 18px)",
        borderRadius: "8px 0px 0px 8px",
        height: "calc(62vh + 72px)"
      }}
      nextToken={messagesListNextToken}
      noFooter
      onCloseMessageContainer={onCloseMessageContainer}
    />
  );
};
