import { Colors } from "helpers/colors";
import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const AppointmentTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-top: 18px;
  margin-bottom: 15px;
`;
export const OverflowContainer = styled.div`
  height: 70vh;
  overflow: auto;
  @media (max-width: 800px) {
    height: 180vh;
  }
`;
export const EmptyDataText = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;
export const ErrorMessage = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  color: red;
  opacity: 0.3;
  font-weight: bold;
`;
export const AddAppointmentButtonContaner = styled.div`
  display: flex;
  align-items: center;
`;
export const AddAppointmentButtonText = styled.span`
  margin-left: 5px;
`;
export const AppointmentHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const TimestampText = styled.div`
  font-weight: bold;
  font-size: 12px;
`;
export const ActionsContainer = styled.div`
  width: 90px;
  display: flex;
  justify-content: space-around;
`;
export const ActionIconContainer = styled.div`
  display: grid;
  place-items: center;
  cursor: pointer;
`;

export const AppointmentItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
`;
export const AppointmentDetailsContainer = styled.div`
  display: flex;
`;
interface DetailItemContainerProps {
  readonly showLeftBorder: boolean;
}
export const DetailItemContainer = styled.div<DetailItemContainerProps>`
  min-width: 200px;
  border-left: ${props =>
    props.showLeftBorder ? `1px solid ${Colors.Lavender}` : "none"};
  padding-left: ${props => (props.showLeftBorder ? "10px" : "none")};
`;

export const DetailItemHeaderText = styled.div`
  font-size: 12px;
  color: ${Colors.gothicBoldLight};
`;
interface DetailItemValueTextProps {
  readonly bold?: boolean;
}
export const DetailItemValueText = styled.div<DetailItemValueTextProps>`
  font-size: 14px;
  font-weight: ${props => (props.bold ? "bold" : "normal")};
`;

export const AppointmentErrorContainer = styled.div`
  height: 36px;
  border-radius: 8px;
  border: 1px solid ${Colors.Red};
  max-width: fit-content;
  display: flex;
  background-color: white;
  margin-bottom: 20px;
`;
export const ErrorIconContainer = styled.div`
  border-radius: 7px 0 0 7px;
  background-color: rgba(243, 30, 19, 0.1);
  width: 35px;
  display: grid;
  place-items: center;
`;
export const ErrorText = styled.div`
  font-size: 10px;
  padding: 0 5px;
  display: grid;
  place-items: center;
`;
export const SetAppointmentFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;
export const FormActionButtonContainer = styled.div`
  // border-top: 1px solid ${Colors.Lavender};
  height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: auto;
    row-gap: 340px;
`;
