import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "antd";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Col from "antd/lib/col";
import { AppSelect } from "components/inputs";
import { SearchBox } from "components/searchBox";

interface ExtensionItemsProps {
  onFilterChange: (
    filterType: string,
    searchValue: string,
    status: string
  ) => void;
}

export const ExtensionItems: React.FC<ExtensionItemsProps> = ({
  onFilterChange
}) => {
  const [filterType, setFilterType] = useState("Billing Code");
  const [searchValue, setSearchValue] = useState<string>("");
  const [status, setStatus] = useState<string>("Draft"); // Default to "Draft"

  const billingCodes = [
    "99496",
    "99495",
    "99489",
    "99487",
    "99491",
    "99458",
    "99457",
    "99454",
    "99453"
  ];

  const filterOptions: { label: string; value: string }[] = [
    {
      label: "CPT Codes",
      value: "Billing Code"
    },
    ...billingCodes.map(code => ({
      label: code,
      value: code
    }))
  ];

  const statusOptions: { label: string; value: string }[] = [
    { label: "All", value: "All" },
    { label: "Submitted", value: "Approve" },
    { label: "Draft", value: "Draft" }
  ];

  useEffect(() => {
    // Call onFilterChange with default values on mount
    handleReset();
    onFilterChange(filterType, searchValue, status);
  }, []);

  const handleReset = () => {
    setFilterType("Billing Code");
    setSearchValue(""); // Reset search value
    setStatus("Draft"); // Reset status to "Draft"
    onFilterChange("Billing Code", "", "Draft");
  };

  const handleFilterChange = (type: string) => {
    setFilterType(type);
    onFilterChange(type, searchValue, status);
  };

  const handleStatusChange = (value: string) => {
    setStatus(value);
    onFilterChange(filterType, searchValue, value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    onFilterChange(filterType, value, status);
  };

  return (
    <RowStyled align="middle" justify="space-between">
      <TitleCol span={4}>All Billings</TitleCol>
      <FiltersCol span={16}>
        <CenterWrapper>
          <Space>
            <AppSelect
              value={filterType}
              label="Filter By"
              onChange={handleFilterChange}
              style={{ marginRight: 5, width: "120px" }}
              options={filterOptions}
              dropdownStyle={{ borderRadius: "8px" }}
            />
            <SearchBox
              value={searchValue} // Bind search value
              placeholder="Search by Patient name"
              onChange={handleSearchChange}
            />
            <AppSelect
              value={status}
              label="Status"
              onChange={handleStatusChange}
              style={{ marginRight: 5, width: "120px" }}
              options={statusOptions}
              dropdownStyle={{ borderRadius: "8px" }}
            />
          </Space>
        </CenterWrapper>
      </FiltersCol>
      <SearchButtonCol span={4}>
        <Button
          type="default"
          style={{
            height: "45px",
            borderRadius: "10px",
            color: "grey",
            fontWeight: "700"
          }}
          onClick={handleReset}
        >
          Reset
        </Button>
      </SearchButtonCol>
    </RowStyled>
  );
};

const TitleCol = styled(Col)`
  padding: 20px;
  font-family: Century Gothic Bold;
  text-align: left;
`;

const FiltersCol = styled(Col)`
  padding: 10px;
  display: flex;
  justify-content: center;
`;

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SearchButtonCol = styled(Col)`
  text-align: right;
  padding: 12px;
`;

const RowStyled = styled(Row)`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
`;

export default ExtensionItems;
