import React from "react";

export const EditIcon: React.FC = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_103_2)">
        <mask
          id="mask0_103_2"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="-14"
          y="-15"
          width="44"
          height="47"
        >
          <path d="M30 -14.875H-14V31.875H30V-14.875Z" fill="white" />
        </mask>
        <g mask="url(#mask0_103_2)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.808 3.28099L10.592 4.57299L8.096 1.92099L9.312 0.628988C9.44 0.492988 9.59733 0.424988 9.784 0.424988C9.97067 0.424988 10.128 0.492988 10.256 0.628988L11.808 2.27799C11.936 2.41399 12 2.58115 12 2.77949C12 2.97782 11.936 3.14499 11.808 3.28099ZM0 10.523L7.376 2.68599L9.872 5.33799L2.496 13.175H0V10.523Z"
            fill="#979DAB"
          />
          <path
            d="M14.5 14.875H0.5C0.223858 14.875 0 15.1128 0 15.4062V16.4688C0 16.7622 0.223858 17 0.5 17H14.5C14.7761 17 15 16.7622 15 16.4688V15.4062C15 15.1128 14.7761 14.875 14.5 14.875Z"
            fill="#979DAB"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_103_2">
          <rect width="15" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
