import * as React from "react";

export const DialysisSymptomsIcon: React.FC = () => (
  <svg
    width="55px"
    height="49px"
    viewBox="0 0 55 49"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="CareGEM-Patient-Report-Symptoms-V1.1-O3"
        transform="translate(-1057.000000, -527.000000)"
      >
        <g id="Group-15" transform="translate(1031.000000, 503.000000)">
          <g id="Group-18" transform="translate(26.000000, 25.000000)">
            <path
              d="M22.9681727,31.8461889 C22.8925157,31.2422421 22.377191,30.775 21.752736,30.775 L9.88511673,30.775 C9.45481974,30.775 9.05607388,31.0007661 8.8346879,31.3697427 L7.91729299,32.8987342 C7.83508261,33.0357515 7.78697116,33.1905045 7.77700385,33.3499816 C7.74261957,33.90013 8.16072902,34.3739874 8.71087743,34.4083716 L20.4811126,35.1440113 C20.9713529,35.1746514 21.4324906,34.9094942 21.6526225,34.4703857 L22.9681727,31.8461889 Z"
              id="Rectangle-Copy-78"
              stroke="#212B40"
              strokeWidth="1.55"
              fill="#7CBA5B"
            ></path>
            <path
              d="M38.7729305,39.9028826 L38.7729305,35.733272 C38.7729305,33.9271835 37.624901,32.3208193 35.9161366,31.7359433 L24.4931382,27.8260781 C24.3943316,27.7922585 24.2906152,27.775 24.186181,27.775 C23.6626819,27.775 23.238302,28.1993799 23.238302,28.722879 L23.238302,30.6490697 C23.238302,31.0908641 23.1328189,31.5262737 22.9306259,31.9190846 L21.886376,33.9478031 C21.8206106,34.0755691 21.7830323,34.2159616 21.7761797,34.3594965 C21.7497326,34.9134638 22.1773722,35.3839826 22.7313395,35.4104297 L31.1538048,35.8125288 L38.7729305,39.9028826 Z"
              id="Rectangle-Copy-79"
              stroke="#212B40"
              strokeWidth="1.55"
              fill="#D6D6D6"
            ></path>
            <path
              d="M31.775,30.1706487 L36.2004146,31.8561366 C37.0914831,32.1955139 37.8211144,32.8595202 38.2427234,33.7147546 L38.4942616,34.225 L47,34.225 C47.6765488,34.225 48.225,33.6765488 48.225,33 L48.225,20 C48.225,19.3234512 47.6765488,18.775 47,18.775 L33,18.775 C32.3234512,18.775 31.775,19.3234512 31.775,20 L31.775,30.1706487 Z"
              id="Rectangle-Copy-81"
              stroke="#212B40"
              strokeWidth="1.55"
              fill="#FBEB98"
            ></path>
            <rect
              id="Rectangle-Copy-88"
              stroke="#212B40"
              strokeWidth="1.5"
              fill="#FBEB98"
              x="31.75"
              y="3.75"
              width="16.5"
              height="12.5"
              rx="2"
            ></rect>
            <path
              d="M2.24086279,31.9907875 L1.06076505,33.7609342 L10.2291711,39.25 L29.212348,39.25 L40.4994253,44.9099696 L41.1266425,42.4011009 L29.855714,36.75 L10.8328757,36.75 L2.24086279,31.9907875 Z"
              id="Rectangle-Copy-80"
              stroke="#212B40"
              strokeWidth="1.5"
              fill="#FFFFFF"
            ></path>
            <circle
              id="Oval-Copy-5"
              stroke="#212B40"
              strokeWidth="1.5"
              fill="#FFFFFF"
              cx="6.5"
              cy="29.5"
              r="2.5"
            ></circle>
            <rect
              id="Rectangle-Copy-82"
              stroke="#212B40"
              strokeWidth="1.5"
              x="35.75"
              y="7.75"
              width="8.5"
              height="4.5"
              rx="1"
            ></rect>
            <rect
              id="Rectangle-Copy-83"
              stroke="#212B40"
              strokeWidth="1.5"
              x="19.75"
              y="1.75"
              width="3.5"
              height="4.5"
              rx="1"
            ></rect>
            <rect
              id="Rectangle-Copy-84"
              stroke="#212B40"
              strokeWidth="1.5"
              x="48.75"
              y="7.75"
              width="2.5"
              height="4.5"
              rx="1"
            ></rect>
            <rect
              id="Rectangle-Copy-85"
              stroke="#212B40"
              strokeWidth="1.5"
              x="51.75"
              y="14.75"
              width="2.5"
              height="7.5"
              rx="1"
            ></rect>
            <rect
              id="Rectangle-Copy-86"
              stroke="#212B40"
              strokeWidth="1.5"
              x="25.75"
              y="39.75"
              width="2.5"
              height="7.5"
              rx="1"
            ></rect>
            <rect
              id="Rectangle-Copy-87"
              stroke="#212B40"
              strokeWidth="1.5"
              x="10.75"
              y="39.75"
              width="2.5"
              height="7.5"
              rx="1"
            ></rect>
            <polygon
              id="Rectangle-Copy-89"
              fill="#212B40"
              points="35 16 36.4755924 16 36.4755924 19 35 19"
            ></polygon>
            <polygon
              id="Rectangle-Copy-90"
              fill="#212B40"
              points="44 16 45.4755924 16 45.4755924 19 44 19"
            ></polygon>
            <polyline
              id="Path-24-Copy"
              stroke="#212B40"
              strokeWidth="1.5"
              points="51 10 52.4906249 10 52.4906249 14.5"
            ></polyline>
            <path
              d="M52.6484999,22 L52.6484999,24.8523375 C52.5145,27.7592708 50.9650001,29.3193375 48,29.5325375"
              id="Path-25-Copy"
              stroke="#212B40"
              strokeWidth="1.5"
            ></path>
            <rect
              id="Rectangle-Copy-91"
              stroke="#212B40"
              strokeWidth="1.5"
              fill="#D8D8D8"
              x="40.75"
              y="21.75"
              width="4.5"
              height="2.5"
            ></rect>
            <rect
              id="Rectangle-Copy-92"
              fill="#212B40"
              x="45"
              y="34"
              width="2"
              height="13"
            ></rect>
            <path
              d="M35.9196936,22 C34.7812054,23.2264011 33.2632307,23.7264011 31.3657695,23.5 L24.3300603,23.5 C22.6798976,23.7132 21.1032965,24.7132 19.6002567,26.5 C17.3456972,29.1802 16.4559578,30.5 12,30.5"
              id="Path-26-Copy"
              stroke="#212B40"
              strokeWidth="1.5"
              strokeLinecap="round"
            ></path>
            <path
              d="M19,5.46007684e-13 C20.593734,1.49645261 22.1093479,1.49645261 23.5468418,5.46007809e-13 C24.3200281,0.0175558134 25.4036435,0.0175558134 26.797688,5.46007809e-13 C28.2338138,1.53951475 29.7516108,1.53951475 31.351079,5.46007684e-13"
              id="Path-27-Copy"
              stroke="#212B40"
              strokeWidth="1.5"
              strokeLinecap="round"
            ></path>
            <path
              d="M25.5,-1.8189894e-12 L25.5,27.8909279"
              id="Path-28-Copy"
              stroke="#212B40"
              strokeWidth="1.5"
            ></path>
            <path
              d="M21.0005033,6 L21.0005033,11.1640625 C20.9737154,13.0546875 22.0163099,14 24.1282867,14 C27.2962519,14 27.5375988,16 27.5375988,17 C27.5375988,17.6666667 27.5375988,18.1666667 27.5375988,18.5 C27.5375988,20.5 28.7513286,21.5 31.1787881,21.5"
              id="Path-29-Copy"
              stroke="#212B40"
              strokeWidth="1.5"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
