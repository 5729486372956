import React from "react";
import styled from "styled-components";
import Table, { ColumnsType } from "antd/lib/table";
import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import { LabData } from "sharedTypes";

interface AllLabRecordsTableTypes {
  data: LabData[];
}

const AllLabRecordsTable: React.FC<AllLabRecordsTableTypes> = ({ data }) => {
  const columns: ColumnsType<LabData> = [
    {
      key: "date_tested",
      dataIndex: "date_tested",
      render: (date_tested: number) => (
        <RowStyle>
          <DateTimeFormat date={date_tested} />
        </RowStyle>
      ),
      width: "40%"
    },
    {
      key: "name",
      dataIndex: "name",
      render: (name: string) => {
        return <RowStyle>{name}</RowStyle>;
      },
      width: "35%"
    },
    {
      key: "value",
      dataIndex: "value",
      render: (value: string) => {
        return <RowReading>{value}</RowReading>;
      },
      width: "30%"
    }
  ];

  return (
    <AppCard cardHeight="inherit" cardWidth="100%">
      <TitleStyle>Recent Labs</TitleStyle>
      <Table<LabData>
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={false}
        scroll={{ y: "calc(23vh)" }}
        showHeader={false}
      />
    </AppCard>
  );
};

const TitleStyle = styled.div`
  background-color: ${Colors.BlackSqueeze};
  display: grid;
  place-items: center;
  height: 40px;
  border-radius: 8px;
  color: ${Colors.Grey};
  font-weight: 600;
  font-size: 12px;
`;

const RowStyle = styled.div`
  font-size: 12px;
  color: ${Colors.Black};
`;
const RowReading = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.Black};
`;

export default AllLabRecordsTable;
