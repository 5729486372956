import React from "react";
import { MENU_ITEM_ICON_DISABLED_COLOR } from "components/sideBar/sideBar";

interface IconProps {
  readonly isDisabled?: boolean;
}

export const CareGuidelinesIcon: React.FC<IconProps> = ({ isDisabled }) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Provider-Global-navigation-V2.1"
          transform="translate(-13.000000, -599.000000)"
        >
          <g
            id="Care-Plan-menu-Copy-5"
            transform="translate(14.000000, 599.000000)"
          >
            <g id="Group-5" transform="translate(7.000000, 6.000000)">
              <g id="Rectangle-Copy-213">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill={isDisabled ? MENU_ITEM_ICON_DISABLED_COLOR : "#9D62DE"}
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Provider-Global-navigation-V2.1"
          transform="translate(-13.000000, -599.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g
            id="Care-Plan-menu-Copy-5"
            transform="translate(14.000000, 599.000000)"
          >
            <g id="Group-5" transform="translate(7.000000, 6.000000)">
              <path
                d="M9.87283647,11.7048076 L11.0490384,11.7048076 L11.0490384,12.8810095 C11.0490384,12.9738093 11.1242675,13.0490384 11.2170672,13.0490384 L12.2252403,13.0490384 C12.31804,13.0490384 12.3932691,12.9738093 12.3932691,12.8810095 L12.3932691,11.7048076 L13.569471,11.7048076 C13.6622708,11.7048076 13.7374999,11.6295785 13.7374999,11.5367788 L13.7374999,10.5286057 C13.7374999,10.435806 13.6622708,10.3605769 13.569471,10.3605769 L12.3932691,10.3605769 L12.3932691,9.18437497 C12.3932691,9.0915752 12.31804,9.01634612 12.2252403,9.01634612 L11.2170672,9.01634612 C11.1242675,9.01634612 11.0490384,9.0915752 11.0490384,9.18437497 L11.0490384,10.3605769 L9.87283647,10.3605769 C9.7800367,10.3605769 9.70480762,10.435806 9.70480762,10.5286057 L9.70480762,11.5367788 C9.70480762,11.6295785 9.7800367,11.7048076 9.87283647,11.7048076 Z M17.0980767,8.00817306 C17.0980767,7.45137445 16.6467023,7 16.0899037,7 L7.35240381,7 C6.7956052,7 6.34423075,7.45137445 6.34423075,8.00817306 L6.34423075,15.0653845 L17.0980767,15.0653845 L17.0980767,8.00817306 Z M15.753846,13.7211537 L7.6884615,13.7211537 L7.6884615,8.34423075 L15.753846,8.34423075 L15.753846,13.7211537 Z M18.1062498,15.7374999 L13.0141357,15.7374999 C13.017037,15.9192806 12.9450552,16.0942551 12.8150783,16.2213718 C12.6851015,16.3484886 12.5085689,16.4165599 12.3268977,16.4096152 L11.0490384,16.4096152 C10.6803978,16.3971845 10.3820389,16.1057472 10.3609603,15.7374999 L5.33605769,15.7374999 C5.15045815,15.7374999 5,15.887958 5,16.0735576 L5,16.4096152 C5,17.1520134 5.60183261,17.753846 6.34423075,17.753846 L17.0980767,17.753846 C17.8404749,17.753846 18.4423075,17.1520134 18.4423075,16.4096152 L18.4423075,16.0735576 C18.4423075,15.887958 18.2918493,15.7374999 18.1062498,15.7374999 Z"
                id="care-guideline-icon"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
