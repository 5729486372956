import React from "react";

const RemoteMonitoringIcon: React.FC<{ status: string }> = ({ status }) => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="SettingsRemoteIcon"
    width={25}
    height={18}
    fill={
      status === "I"
        ? "#f2d720"
        : status === "Y"
        ? "#4CAF50"
        : status === ""
        ? "#9E9E9E"
        : "#9E9E9E"
    }
  >
    <path d="M15 9H9c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V10c0-.55-.45-1-1-1zm-3 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM7.05 6.05l1.41 1.41C9.37 6.56 10.62 6 12 6s2.63.56 3.54 1.46l1.41-1.41C15.68 4.78 13.93 4 12 4s-3.68.78-4.95 2.05zM12 0C8.96 0 6.21 1.23 4.22 3.22l1.41 1.41C7.26 3.01 9.51 2 12 2s4.74 1.01 6.36 2.64l1.41-1.41C17.79 1.23 15.04 0 12 0z"></path>
  </svg>
);

export default RemoteMonitoringIcon;
