import React, { useEffect, useState } from "react";
import Layout from "antd/lib/layout";
import styled from "styled-components";

import { Colors } from "helpers/colors";
import { ProviderTopbar } from "components/providerTopBar";
import { TopbarButtons } from "components/providerTopBar/topbarButtons";
import { TopbarLogo } from "components/providerTopBar/topbarLogo";
import SideBar from "components/sideBar/sideBar";
import { useLocation } from "react-router-dom";
import { ProvidersRoutesRef } from "constants/routes";
import { resetCareTeamDropdownValues } from "pages/careteam/resetCareTeamDropdownValues";

export const ProvidersLayout: React.FC = ({ children }) => {
  const location = useLocation();

  const renderSidebar =
    location.pathname.includes(ProvidersRoutesRef.Patients) &&
    location.pathname.replace("/", "") !== ProvidersRoutesRef.Patients;

  if (!location.pathname.includes(ProvidersRoutesRef.CareTeam)) {
    resetCareTeamDropdownValues();
  }

  const [hideTop, sethideTop] = useState("");
  const epicLogin: any = sessionStorage.getItem("epicLogin");

  useEffect(() => {
    sethideTop(String(sessionStorage.getItem("hideTop")));
  }, [sessionStorage.getItem("hideTop")]);

  useEffect(() => {
    if (
      location.pathname.includes(ProvidersRoutesRef.SurveyReports) ||
      location.pathname.includes(ProvidersRoutesRef.Dashboard)
    ) {
      console.log("ProvidersRoutesRef.SurveyReports");
      sethideTop("survey-reports");
    }

    return () => {
      console.log("cleanup");
      sethideTop("true");
    };
  }, [location.pathname]);

  useEffect(() => {
    console.log(hideTop);
  }, [hideTop]);

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100vh",
        paddingTop:
          hideTop == "true"
            ? "125px"
            : hideTop === "survey-reports"
            ? "65px"
            : epicLogin == "true"
            ? "75px"
            : "125px"
      }}
    >
      {/* {epicLogin !== "true" && <ProviderTopbar />} */}
      <ProviderTopbar />
      {epicLogin !== "true" && <TopbarButtons />}
      {epicLogin !== "true" && <TopbarLogo />}
      {renderSidebar ? (
        <LayoutStyled style={{ padding: "0px", overflowX: "hidden" }}>
          <SideBar>{children}</SideBar>
        </LayoutStyled>
      ) : (
        <LayoutStyled>{children}</LayoutStyled>
      )}
    </Layout>
  );
};

const LayoutStyled = styled(Layout)`
  overflow-x: hidden;
  overflow: auto;
  padding: 15px 18px 18px 18px;
  background-color: ${Colors.GhostWhite};
`;
