import Space from "antd/lib/space";
import Table, { ColumnsType } from "antd/lib/table";
import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { createApiClient } from "apiClient";

import { AppAvatar } from "components/avatar";
import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import { UserInfoMap, ChannelData } from "sharedTypes";
import { useQuery } from "react-query";
import { TableMessageIcon } from "svgIcons/tableMessageIcon";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import moment from "moment";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { UserContext, UserContextState } from "contextApis/userContext";
import { SelectedConversationUserInfo } from "pages/messages/messages";

interface PatientMessagesTableProps {
  selectedConversationUserInfo: SelectedConversationUserInfo | null;
  setSelectedConversationUserInfo: (item: {
    userInfo: UserInfoMap;
    channel: ChannelData;
  }) => void;
  setShowMessages(show: boolean): void;
}

const INCLUDE_PRV_CHATS = false;
const SHOW_LAST_MESSAGE = false;

export const PatientMessagesTable: React.FC<PatientMessagesTableProps> = ({
  selectedConversationUserInfo,
  setSelectedConversationUserInfo,
  setShowMessages
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);
  const {
    isLoading: isLoadingAllUserChannelsList,
    data: allUserChannelsListData,
    error: isAllUserChannelsListError,
    isFetching: isAllUserChannelsListRefetchLoading
  } = useQuery(
    "all-messages-list",
    async () => {
      return await createApiClient().getUserChannels(
        patientContext.patientData?.username || "",
        INCLUDE_PRV_CHATS,
        SHOW_LAST_MESSAGE
      );
    },
    {
      enabled:
        patientContext.patientData && patientContext.patientData.username
          ? true
          : false
    }
  );

  const compareNames = (a: any, b: any) => {
    const providerAData = allUserChannelsListData?.user_info[a.user1_uname];
    const providerBData = allUserChannelsListData?.user_info[b.user1_uname];

    if (providerAData && providerBData) {
      return providerAData.last_name === providerBData.last_name
        ? providerAData.first_name.localeCompare(providerBData.first_name || "")
        : providerAData.last_name.localeCompare(providerBData.last_name || "");
    } else {
      return 0;
    }
  };

  const columns: ColumnsType<ChannelData> = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (name: string, row: ChannelData) => {
        const providerData =
          allUserChannelsListData?.user_info[row.user1_uname];
        return (
          <AppAvatar
            imageSrc={""}
            size="large"
            mainContent={`${providerData?.last_name || ""}, ${
              providerData?.first_name || ""
            } ${providerData?.degree || ""}`}
            subText={providerData?.specialty || ""}
            additionalNote={row.latest_message}
          />
        );
      },
      width: "35%",
      sorter: (a, b) => compareNames(a, b),
      showSorterTooltip: { title: "" },
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: null
    },
    {
      title: "Date of latest message",
      key: "latest_message_timestamp",
      dataIndex: "latest_message_timestamp",
      render: (_appointmentDate: number, row: ChannelData) => (
        <RowStyle>
          <DateTimeFormat
            date={moment(
              moment.utc(row.latest_message_timestamp).toDate(),
              "YYYY/MM/DD, HH:mm:ss"
            ).valueOf()}
          />
        </RowStyle>
      ),
      width: "55%",
      sorter: (a, b) =>
        moment(a.latest_message_timestamp).valueOf() -
        moment(b.latest_message_timestamp).valueOf(),
      showSorterTooltip: { title: "Sort by last message date" },
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend"
    },
    {
      key: "actions",
      dataIndex: "actions",
      render: (_, row: ChannelData) => (
        <div style={{ display: "grid", placeItems: "center", width: "60px" }}>
          <Space
            size={20}
            onClick={() => {
              setSelectedConversationUserInfo({
                userInfo: allUserChannelsListData?.user_info || {},
                channel: row
              });
              setShowMessages(true);
            }}
          >
            <TableMessageIcon />
          </Space>
        </div>
      ),
      width: "10%"
    }
  ];

  const channelList = useMemo<ChannelData[]>(() => {
    const loggedInProviderUsername = userContext.userData?.userName || "";
    if (allUserChannelsListData) {
      return allUserChannelsListData.channel_arns.filter(
        channel => channel.user1_uname !== loggedInProviderUsername
      );
    }
    return [];
  }, [allUserChannelsListData, userContext.userData]);

  return (
    <AppCard cardHeight="inherit" cardWidth="100%">
      {isAllUserChannelsListError ? (
        <CommonErrorMessage message="There was an error fetching the conversations" />
      ) : (
        <Table<ChannelData>
          columns={columns}
          dataSource={channelList}
          rowKey="id"
          pagination={false}
          scroll={{ y: "66.5vh" }}
          loading={
            isAllUserChannelsListRefetchLoading || isLoadingAllUserChannelsList
          }
          rowClassName={record =>
            record.channel_arn ===
            selectedConversationUserInfo?.channel.channel_arn
              ? "care-team-member-selected"
              : ""
          }
        />
      )}
    </AppCard>
  );
};

const RowStyle = styled.div`
  font-size: 12px;
  color: ${Colors.Black};
`;
