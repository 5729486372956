import moment from "moment";
import { lowerCaseRemoveSpace } from "helpers/utils";
import { ExtendedSymptomData } from "pages/symptoms/SymptomsModal";

export const fatigueMapper = ({
  filteredExtendedSymptomData,
  mapperType,
  mapperValue
}: {
  filteredExtendedSymptomData: ExtendedSymptomData[] | null;
  mapperType?: string | undefined;
  mapperValue?: string | number | undefined;
}) => {
  const severity: [string, number, string][] = [];

  const sortedData = filteredExtendedSymptomData?.sort(
    (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
  );

  if (mapperType) {
    let tooltipValue = "";
    if (lowerCaseRemoveSpace(mapperType) === "severity") {
      switch (mapperValue) {
        case 1:
          tooltipValue =
            "Only vigorous activities (No limitation of normal activity)";
          break;
        case 4:
          tooltipValue =
            "Walking briskly, or on incline (Slight limitation of ordinary activity)";
          break;
        case 7:
          tooltipValue =
            "Walking 20 - 100 feet on the level (Moderate limitation of LESS-THAN-ordinary activity)";
          break;
        case 10:
          tooltipValue =
            "At rest or on minimal activity (E.g. dressing, getting out of bed)";
          break;
      }
    }
    return tooltipValue;
  }

  sortedData?.forEach(item => {
    item.data?.map(item2 => {
      if (lowerCaseRemoveSpace(item2?.key) === "severity") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "onlyvigorousactivities(nolimitationofnormalactivity)":
            value = 1;
            break;
          case "walkingbriskly,oronincline(slightlimitationofordinaryactivity)":
            value = 4;
            break;
          case "walking20-100feetonthelevel(moderatelimitationofless-than-ordinaryactivity)":
            value = 7;
            break;
          case "atrestoronminimalactivity(e.g.dressing,gettingoutofbed)":
            value = 10;
            break;
        }
        severity.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Severity"
        ]);
      }
    });
  });

  return [severity];
};
