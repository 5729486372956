import React from "react";
import Input, { InputRef } from "antd/lib/input";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";

interface SearchBoxProps {
  readonly placeholder: string;
  readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly value?: string;
}

const InputStyled = styled(Input)`
  height: 45px;
  width: 323px;
  border: 1px solid #e2edf3;
  border-radius: 10px;
  background-color: #ffffff;
  .ant-input-prefix {
    height: 40px;
    width: 40px;
  }

  @media screen and (max-width: 1300px) {
    width: 270px;
  }

  @media screen and (max-width: 1260px) {
    width: 170px;
  }
`;

const StyledSearch = styled(SearchOutlined)`
  color: #979dab;
  width: 16px;
  font-family: material;
  font-size: 20px;
  letter-spacing: 0;
  svg {
    margin-top: -5px;
  }
`;
export const SearchBoxComponent = React.forwardRef<any, SearchBoxProps>(
  (props, ref) => {
    const { placeholder, onChange, value } = props;
    return (
      <InputStyled
        placeholder={placeholder}
        prefix={<StyledSearch />}
        onChange={onChange}
        allowClear
        ref={ref}
        value={value}
      />
    );
  }
);

SearchBoxComponent.displayName = "SearchBoxComponent";
