import React from "react";
import styled from "styled-components";

const ListStyle = styled.ul`
  margin-left: -40px;
`;

export const urineProteinLevelQA = [
  {
    question: "What does it mean when there is protein in the urine?",
    answer: (
      <ListStyle>
        <li>
          Proteins are important players in the body and it is the job of the
          kidneys to keep them there and prevent them from accidentally being
          filtered out through the urine. If large amounts of protein start
          showing up in your urine, this may be a sign that there is damage or
          illness.
        </li>
      </ListStyle>
    )
  },
  {
    question: "What causes there to be protein in the urine?",
    answer: (
      <ListStyle>
        <li>
          The kidneys act like gatekeepers, and prevent valuable proteins from
          leaving the body. If the kidneys become damaged or infected, one way
          of determining this is to look for proteins in the urine.
        </li>
        <li>
          Possible causes include: cardiovascular disease, diabetes, trauma or
          kidney disease.
        </li>
      </ListStyle>
    )
  },
  {
    question: "What does it mean when there is increased protein in the urine?",
    answer: (
      <ListStyle>
        <li>
          If the amount of proteins in your urine increases, this may be a sign
          of worsening kidney health. Consult your doctor for more information.
        </li>
      </ListStyle>
    )
  },
  {
    question: "How can I reduce the protein in my urine?",
    answer: (
      <ListStyle>
        <li>
          Consult your doctor beforehand to discuss possible treatment options.
          <ul>
            <li>Change in diet</li>
            <li>Weight loss</li>
            <li>Blood pressure management</li>
            <li>Diabetes medication</li>
            <li>Dialysis</li>
          </ul>
        </li>
      </ListStyle>
    )
  }
];

export const gfrQA = [
  {
    question: "What is GFR?",
    answer: (
      <ListStyle>
        <li>
          GFR or Glomerular filtration rate is a measure of how much blood is
          cleaned by your kidneys per minute
        </li>
      </ListStyle>
    )
  },
  {
    question: "How is GFR measured?",
    answer: (
      <ListStyle>
        <li>
          GFR can be measured by comparing the level of waste products in the
          blood and urine. GFR can also be calculated from blood levels of waste
          products like creatinine. Increase in levels of creatinine are
          associated with a decline in GFR
        </li>
      </ListStyle>
    )
  },
  {
    question: "What causes a decrease in GFR?",
    answer: (
      <>
        <ListStyle>
          <li>
            GFR can decline with decreases in blood volume when fluid and
            electrolyte losses are not replaced. This can occur if you have
            persistent vomiting or diarrhea, or insufficient intake of food and
            fluids, or excessive sweat related losses in hot weather.
          </li>
          <li>
            Consult with your doctor to discuss your GFR to address the reasons
            for any changes. Some other reasons for reduction in GFR include:
          </li>
          <ul>
            <li>
              Disorders of kidney blood flow such as in heart failure or liver
              disease.
            </li>
            <li>
              Long term effects of conditions like high blood pressure,
              diabetes, or progression of other kidney diseases.
            </li>
            <li>
              Long term effects of conditions like high blood pressure,
              diabetes, or progression of other kidney diseases.
            </li>
            <li>
              In some circumstances, use of other medications to manage heart
              failure or high blood pressure, or infection
            </li>
          </ul>
        </ListStyle>
      </>
    )
  },
  {
    question: "How can I improve my GFR?",
    answer: (
      <>
        <ListStyle>
          <li>
            Consult with your doctor to discuss if there are any underlying
            reversible factors, that may be impacting your GFR. These may
            include:
          </li>
          <ul>
            <li>Improving your hydration status</li>
            <li>Avoiding use of certain medications that impact GFR</li>
            <li>
              Control of long term disorders such as diabetes or hypertension,
              and obesity
            </li>
            <li>
              Adjusting your diet to reduce animal protein, particularly red
              meat, and greater intake of fresh fruits and vegetable
            </li>
          </ul>
        </ListStyle>
      </>
    )
  }
];

export const createnineQA = [
  {
    question:
      "What is creatinine and how is it used to check my kidney function?",
    answer: (
      <ListStyle>
        <li>
          Creatinine is a waste substance that your muscles produce and your
          kidneys remove. Looking at the level of creatinine in your blood can
          help your doctor understand how well your kidneys are working.
          Generally, an increase in creatinine level can indicate a decline of
          kidney function. Because kidney disease may cause little or no
          symptoms till it is advanced, creatinine levels may be the first
          indication of presence of kidney disease. Creatinine levels however
          may not be elevated in early stages of kidney disease
        </li>
        <li>
          Changes in creatinine that represent an overall trend over time
          usually suggest a change in kidney function. Often your doctor will
          review these trends to see how your kidney function is progressing.
        </li>
        <li>
          Small variations in level of creatinine can occur due to factors such
          as hydration status, diet containing a lot of cooked meat, or
          excessive use of creatine, which is broken down in your body to
          creatinine. Use of certain medications can change the measured
          creatinine without affecting kidney function. Your creatinine level
          may be in higher range of normal if you have a lot of muscle mass.
          Variations between labs that test for creatinine can also affect
          creatinine level. Please discuss the significance of your creatinine
          level with your doctor.
        </li>
      </ListStyle>
    )
  },
  {
    question: "How can I improve my creatinine level?",
    answer: (
      <ListStyle>
        <li>
          Consult with your doctor to discuss if there are any underlying
          reversible factors, that may be impacting your kidney function and
          creatinine level. You may wish to discuss the following:
        </li>
        <ul>
          <li>Improve your hydration status</li>
          <li>Avoiding medications that may be impacting kidney function,</li>
          <li>
            Control of long-term disorders such as diabetes or hypertension, and
            obesity
          </li>
          <li>
            Change your diet to less animal protein, particularly red meat, and
            greater intake of fresh fruits and vegetable
          </li>
        </ul>
      </ListStyle>
    )
  }
];

export const ckdQA = [
  {
    question: "What is my CKD stage?",
    answer: (
      <ListStyle>
        <li>
          CKD stage gives an indication of your level of kidney function as
          measured by GFR. As kidney function worsens the CKD stage increases.
          Your doctor may adjust your treatment plan based upon your CKD stage
        </li>
      </ListStyle>
    )
  },
  {
    question: "How does my health care team use CKD stage to manage my health?",
    answer: (
      <ListStyle>
        <li>
          Your health care team will tailor your treatments to manage your stage
          of kidney disease. These will include the following overall goals:
        </li>
        <ul>
          <li>
            Prevent progression to more advanced stages of kidney disease, by
            control of underlying risk factors, adjusting your diet, lifestyle
            and medications, and avoiding any factors that may cause sudden
            decreases in kidney function
          </li>
          <li>
            Treat any complications associated with each stage such as anemia,
            bone disease, acidosis, high potassium, high blood pressure
          </li>
          <li>
            If your kidney function is worsening, discuss your preferred options
            for renal replacement therapy such as dialysis or transplantation,
            and making preparations such as referrals to surgery for access
            placement, or for transplant evaluation
          </li>
          <li>
            Educate you and your family regarding the each of the above items
          </li>
        </ul>
      </ListStyle>
    )
  },
  {
    question: "What does my CKD stage mean?",
    answer: (
      <ListStyle>
        <li>There are five CKD stages</li>
        <ul>
          <li>
            Stage 1 = Minimal change: some damage but kidneys have near normal
            function (GFR&gt;90)
          </li>
          <li>
            Stage 2 = Mild: some kidney damage; your kidneys have mildly reduced
            function (GFR 60-90)
          </li>
          <li>
            Stage 3a = Mild to moderate: your kidneys have significantly reduced
            function (GFR 45-60)
          </li>
          <li>
            Stage 3b = Moderate to severe: your kidneys have moderate to severe
            reduction in function (GFR 30-45)
          </li>
          <li>
            Stage 4 = Severe: kidneys nearing end stage kidney disease (GFR 15-
            30)
          </li>
          <li>
            Stage 5 = Very severe: kidneys very close to requiring dialysis or
            transplant (GFR less than 15)
          </li>
        </ul>
        <li>
          Each stage of kidney disease requires some actions to prevent
          progression to higher stages and management of any metabolic and
          functional abnormalities associated with the stage
        </li>
      </ListStyle>
    )
  }
];
