import React, { createContext, useEffect, useState } from "react";
import { CheckboxMenuCommonInterface } from "sharedTypes";

export type BillingContextState = {
  diagnosesMenuItems: CheckboxMenuCommonInterface[];
  setDiagnosesMenuItems: (obj: CheckboxMenuCommonInterface[]) => void;
  chargesMenuItems: CheckboxMenuCommonInterface[];
  setChargesMenuItems: (obj: CheckboxMenuCommonInterface[]) => void;
};

const initialValue: BillingContextState = {
  diagnosesMenuItems: [],
  setDiagnosesMenuItems: () => null,
  chargesMenuItems: [],
  setChargesMenuItems: () => null
};

export const BillingContext = createContext<BillingContextState>(initialValue);

BillingContext.displayName = "CheckboxMenuContext";

export const BillingProvider: React.FC = ({ children }) => {
  const [diagnosesMenuItems, setDiagnosesMenuItems] = useState<
    CheckboxMenuCommonInterface[]
  >([]);

  const [chargesMenuItems, setChargesMenuItems] = useState<
    CheckboxMenuCommonInterface[]
  >([]);

  useEffect(() => {
    console.log(diagnosesMenuItems);
    console.log(chargesMenuItems);
  });

  return (
    <BillingContext.Provider
      value={{
        diagnosesMenuItems,
        setDiagnosesMenuItems,
        chargesMenuItems,
        setChargesMenuItems
      }}
    >
      {children}
    </BillingContext.Provider>
  );
};
