import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./contextApis/userContext";
import { PatientProvider } from "./contextApis/patientContext";
import { MeetingModalProvider } from "./contextApis/meetingModalContext";
import { NotificationsProvider } from "./contextApis/notificationsContext";
import { QueryClientProvider, QueryClient } from "react-query";
import { IsExpandedProvider } from "./contextApis/expandContext";
import { DraftBillProvider } from "contextApis/draftBillContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      staleTime: 0
    }
  }
});

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <IsExpandedProvider>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <MeetingModalProvider>
              <PatientProvider>
                <DraftBillProvider>
                  <NotificationsProvider>
                    <App />
                  </NotificationsProvider>
                </DraftBillProvider>
              </PatientProvider>
            </MeetingModalProvider>
          </UserProvider>
        </QueryClientProvider>
      </IsExpandedProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);
reportWebVitals();
