import React, { useContext, useMemo } from "react";
import Col from "antd/lib/col";
import Skeleton from "antd/lib/skeleton";
import { useQuery } from "react-query";
import styled from "styled-components";

import { createApiClient } from "apiClient";
import { AppCard } from "components/card";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import AllLabRecordsTable from "./AllLabRecordsTable";
import { LatestLabRecord } from "./LatestLabRecords";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserRoles } from "constants/roles";
import get from "lodash/get";

export const LaboratoryPage: React.FC = () => {
  const patientContext = useContext<PatientContextState>(PatientContext);

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const userId = sessionStorage.getItem("userId") || "";

  const isApiEnabled = useMemo(() => {
    if (patientContext.patientData) {
      if (patientContext.patientData.id) return true;
    }
    return false;
  }, [patientContext.patientData]);

  const {
    isLoading: isLoadingLabData,
    data: labData,
    error: isLabDataError
  } = useQuery(
    "get-lab-data",
    async () => {
      return await createApiClient().getLabData(
        String(
          userIsPatient ? userId : get(patientContext, "patientData.id", "")
        ),
        true
      );
    },
    { enabled: userIsPatient || isApiEnabled ? true : false }
  );

  const showTopbarExtesion = !userIsPatient;

  return (
    <>
      {showTopbarExtesion && <CommonPatientExtensionItems />}
      <TitleCol span={3}>Laboratory</TitleCol>
      {isLabDataError && (
        <CommonErrorMessage message="There was an error loading laboratory data." />
      )}
      {isLoadingLabData ? (
        <AppCard cardWidth="100%" cardHeight="200px">
          <Skeleton />
        </AppCard>
      ) : (
        <>
          <LatestLabRecord data={labData ? labData : []} />
          <AllLabRecordsTable data={labData ? labData : []} />
        </>
      )}
    </>
  );
};

const TitleCol = styled(Col)`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 18px;
  padding-top: 18px;
`;
