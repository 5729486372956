import { ArrowLeftOutlined } from "@ant-design/icons";
import Divider from "antd/lib/divider";
import Space from "antd/lib/space";
import React from "react";
import { AppAvatar } from "components/avatar";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";

import {
  ProfileHeaderContainer,
  AvatarContainer,
  AvatarTextContainer,
  AvatarNameText,
  AvatarSpecialityText,
  ResetPasswordLinkContainer,
  ProfileContentContainer,
  GridItem,
  GridItemTitle,
  GridItemValue,
  GridItemGap,
  ContactNumberContainer,
  ContactNumberGap
} from "./careTeamProviderProfileStyles";
import { UserRoles } from "constants/roles";
import { lowerCaseRemoveSpace } from "helpers/utils";

interface CareTeamProviderProfile {
  setShowProviderProfile(show: boolean): void;
  providerProfileData: {
    first_name: string;
    last_name: string;
    role: string;
    specialty: string;
    group: string;
    organisations: { id: string; name: string }[];
    office_address: string;
    phoneNumbers: { title: string; number: string }[];
    degree: string;
  };
}

export const CareTeamProviderProfile: React.FC<CareTeamProviderProfile> = ({
  setShowProviderProfile,
  providerProfileData
}) => {
  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  return (
    <>
      <AppButton
        type={ButtonType.Link}
        style={{
          color: "#8E94A0",
          fontStyle: "normal",
          fontSize: "11px",
          marginBottom: "20px"
        }}
        buttonContent={
          <Space align="start" style={{ marginLeft: "-16px" }}>
            <ArrowLeftOutlined />
            <span style={{ marginLeft: "-5px" }}>Back to Care Team list</span>
          </Space>
        }
        onClick={() => setShowProviderProfile(false)}
      />
      <AppCard cardWidth="100%" cardHeight="auto">
        <ProfileHeaderContainer>
          <AvatarContainer>
            <AppAvatar imageSrc="" size={80} />
            <AvatarTextContainer>
              <AvatarNameText>
                {providerProfileData.first_name} {providerProfileData.last_name}
              </AvatarNameText>
              <AvatarSpecialityText>
                {providerProfileData.degree}
              </AvatarSpecialityText>
            </AvatarTextContainer>
          </AvatarContainer>
          <ResetPasswordLinkContainer>
            {/* <ResetPasswordLink onClick={onResetPasswordClick}>
            Reset Password
          </ResetPasswordLink> */}
          </ResetPasswordLinkContainer>
        </ProfileHeaderContainer>
        <Divider />
        <ProfileContentContainer>
          <GridItem>
            <GridItemTitle topPadding>Role</GridItemTitle>
            <GridItemValue bold>
              {providerProfileData.role &&
                providerProfileData.role.charAt(0).toUpperCase() +
                  providerProfileData.role.slice(1)}
            </GridItemValue>
            <GridItemGap />
            <GridItemTitle>Speciality</GridItemTitle>
            <GridItemValue bold>{providerProfileData.specialty}</GridItemValue>
            <GridItemGap />
            <GridItemTitle>Group</GridItemTitle>
            <GridItemValue bold>{providerProfileData.group}</GridItemValue>
            <GridItemGap />
            <GridItemTitle>Organisation</GridItemTitle>
            {providerProfileData.organisations
              ? providerProfileData.organisations.map((item, index) => {
                  return (
                    <GridItemValue bold key={index}>
                      {item.name}
                    </GridItemValue>
                  );
                })
              : ""}
          </GridItem>
          <GridItem leftBorder>
            <GridItemTitle topPadding>Office Address</GridItemTitle>
            <GridItemValue bold>
              {providerProfileData.office_address}
            </GridItemValue>
            {/* <GridItemValue bold>Anytown, IN 46321</GridItemValue> */}
            <GridItemGap />
            <GridItemTitle>Contact Number</GridItemTitle>
            <ContactNumberContainer>
              {providerProfileData.phoneNumbers.map(item => {
                if (
                  (userIsPatient || userIsCaregiver) &&
                  !lowerCaseRemoveSpace(item.title).includes("office") &&
                  providerProfileData.role !== UserRoles.CAREGIVERS
                ) {
                  return null;
                }

                return (
                  <>
                    <GridItemTitle>{item.title}</GridItemTitle>
                    <GridItemValue bold>{item.number}</GridItemValue>
                    <ContactNumberGap />
                  </>
                );
              })}
            </ContactNumberContainer>
          </GridItem>
        </ProfileContentContainer>
      </AppCard>
    </>
  );
};
