// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from "react";

import MeetingRoster from "components/meetings/containers/MeetingRoster";
import Navigation from ".";
import { useNavigation } from "components/meetings/providers/NavigationProvider";
import { Flex } from "amazon-chime-sdk-component-library-react";

const NavigationControl = () => {
  const { showNavbar, showRoster, showChat } = useNavigation();

  const view = () => {
    if (showRoster && showChat) {
      return (
        <Flex layout="stack" style={{ height: "100vh" }}>
          <MeetingRoster />
        </Flex>
      );
    }
    if (showRoster) {
      return <MeetingRoster />;
    }
    return null;
  };

  return (
    <>
      {showNavbar ? <Navigation /> : null}
      {view()}
    </>
  );
};

export default NavigationControl;
