import { FormInstance } from "antd";
import React, { useEffect, useState } from "react";
import { AppTextArea } from "components/inputs";
import { SelectedCallRecordDraft } from "pages/callRecords/callRecords";

export interface CallRecordOtherIssuesProps {
  readonly otherIssues?: string;
}

interface AddCallRecordFormProps {
  readonly value?: CallRecordOtherIssuesProps;
  readonly onChange?: (value: CallRecordOtherIssuesProps) => void;
  readonly clearForm: boolean;
  readonly selectedDraft?: SelectedCallRecordDraft;
  readonly form?: FormInstance;
  readonly disabled?: boolean;
  readonly handleMouseLeave?: (
    e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>
  ) => void;
}

export const CallRecordOtherIssues: React.FC<AddCallRecordFormProps> = ({
  value,
  onChange,
  clearForm,
  selectedDraft,
  form,
  disabled,
  handleMouseLeave
}) => {
  const [otherIssues, setOtherIssues] = useState<string>("");

  const triggerChange = (changedValue: CallRecordOtherIssuesProps) => {
    onChange?.({
      otherIssues,
      ...value,
      ...changedValue
    });
  };

  const onOtherIssuesChange = (newOtherIssues: string) => {
    setOtherIssues(newOtherIssues);
    if (form) {
      form.setFieldsValue({
        otherIssues: { otherIssues: newOtherIssues }
      });
    }
    triggerChange({ otherIssues: newOtherIssues });
  };

  useEffect(() => {
    if (selectedDraft) {
      setOtherIssues(String(selectedDraft.other));
      if (form) {
        form.setFieldsValue({
          otherIssues: { otherIssues: selectedDraft.other }
        });
      }
    }
  }, [selectedDraft]);

  useEffect(() => {
    if (clearForm) {
      setOtherIssues("");
      if (form) {
        form.setFieldsValue({
          otherIssues: { otherIssues: "" }
        });
      }
    }
  }, [clearForm]);

  return (
    <AppTextArea
      value={otherIssues}
      label="Other Issues"
      onChange={onOtherIssuesChange}
      placeholder="Enter Text"
      disabled={disabled}
      handleMouseLeave={handleMouseLeave}
    />
  );
};
