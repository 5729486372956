import Skeleton from "antd/lib/skeleton";
import Space from "antd/lib/space";
import React, { useContext, useMemo, useState } from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { AppAvatar } from "components/avatar";
import { AppCard } from "components/card";
import { Dropdown } from "components/dropDownBox";
import { TopbarExtension } from "components/topbarExtension";
import { ProvidersRoutesRef } from "constants/routes";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import BackArrow from "svgIcons/backArrow";
import ExpandDownArrow from "svgIcons/expandDownArrow";
import { TableMessageIcon } from "svgIcons/tableMessageIcon";
import { TablePhoneIcon } from "svgIcons/tablePhoneIcon";

import {
  DateRowStyle,
  DropdownContentContainer,
  DropdownContentItemContainer,
  DropdownContentItemContent,
  DropdownContentItemTitle,
  DropdownHeaderContainer,
  ExpandArrowContainer,
  MoreDetailsContainer,
  PhoneNumberDropdownContainer,
  ProviderAvatarContainer,
  ProviderBackLink,
  ProviderDataContainer,
  ProviderDataItemContainer,
  ProviderDataItemContentText,
  ProviderDataItemHeaderText,
  ProviderDetailsContainer,
  ProviderName,
  SharedPatientsTitle,
  SharedPatientsTitleContainer,
  StyledEyeOutlined
} from "./style";
import { startCase } from "helpers/utils";
import { SearchBox } from "components/searchBox";
import { get } from "lodash";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { ProviderProfile, PrvProfilePatientData } from "sharedTypes";
import { PrvDetailsMessagesSection } from "./messagesSection";
import moment from "moment";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";
import { Radio } from "antd";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { AppModal } from "components/modal";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { CircleIndicator } from "components/circleIndicator";

export interface SelectedProviderData {
  readonly degree: string;
  readonly group: string;
  readonly role: string;
  readonly specialty: string;
  readonly picture: string;
  readonly addressCity: string;
  readonly addressZip: string;
  readonly name: string;
  readonly officeAddr1: string;
  readonly state: string;
  readonly username: string;
  readonly internal_id: string;
}

interface ProviderDetailsPageInterface {
  readonly data: ProviderProfile | undefined;
  readonly isLoading: boolean;
  readonly isFetching: boolean;
  readonly searchValue: string;
  readonly setSearchValue: (value: string) => void;
}

export const ProviderDetailsPage: React.FC<ProviderDetailsPageInterface> = ({
  data,
  isLoading,
  isFetching,
  searchValue,
  setSearchValue
}) => {
  const [isPhoneDropdownVisible, setIsPhoneDropdownVisible] =
    useState<boolean>(false);
  const [isMoreDetailsDropdownVisible, setIsMoreDetailsDropdownVisible] =
    useState<boolean>(false);
  const [showMessages, setShowMessages] = useState<boolean>(false);
  const [selectedPatient, setSelectedPatient] =
    useState<PrvProfilePatientData | null>(null);
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const patientContext = useContext<PatientContextState>(PatientContext);
  const [showPhoneNumberSelectModal, setShowPhoneNumberSelectModal] =
    useState(false);

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);
  const userContext = useContext<UserContextState>(UserContext);

  const navigate = useNavigate();

  const onPhoneDropdownVisibilityChange = (isVisible: boolean) =>
    setIsPhoneDropdownVisible(isVisible);

  const onMoreDetailsDropdownVisibilityChange = (isVisible: boolean) =>
    setIsMoreDetailsDropdownVisible(isVisible);

  const onBackLinkClick = () => navigate(`/${ProvidersRoutesRef.Providers}`);

  const onCloseMessageContainer = () => {
    setShowMessages(false);
    setSelectedPatient(null);
  };

  const onSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value);

  const compareNames = (a: any, b: any) =>
    a.last_name === b.last_name
      ? a.first_name.localeCompare(b.first_name)
      : a.last_name.localeCompare(b.last_name);

  const unreadMessageNotifications = useMemo(() => {
    const unseenNotifications =
      notificationsContext.filterMessageNotificationsByStatus(
        notificationsContext.notifications?.messages || [],
        "unread"
      );
    return unseenNotifications;
  }, [notificationsContext.notifications?.messages]);

  const columns: ColumnsType<PrvProfilePatientData> = [
    {
      title: "Name",
      key: "patientName",
      dataIndex: "patientName",
      render: (name: string, row: PrvProfilePatientData) => {
        const [firstName, lastName] = name.split(/\s+/);
        return (
          <AppAvatar
            imageSrc={row.picture || ""}
            size="large"
            mainContent={lastName + ", " + firstName}
            subText={row.messages.lastMessage}
          />
        );
      },
      width: "35%",
      sorter: (a, b) => {
        const [aFirstName, aLastName] = a.patientName.split(/\s+/);
        const [bFirstName, bLastName] = b.patientName.split(/\s+/);
        const customA = { first_name: aFirstName, last_name: aLastName };
        const customB = { first_name: bFirstName, last_name: bLastName };
        return compareNames(customA, customB);
      },
      showSorterTooltip: { title: "Sort alphabetically" },
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "ascend"
    },
    {
      title: `Appointments with ${data?.name || ""}`,
      key: "visit_date",
      dataIndex: "visit_date",
      render: (appointmentDate: number) => {
        const appointmentTimestamp = appointmentDate
          ? moment(appointmentDate, "YYYY-MM-DD hh:mm").valueOf()
          : 0;
        return (
          <DateRowStyle>
            <DateTimeFormat date={appointmentTimestamp} />
          </DateRowStyle>
        );
      },
      width: "60%"
    },
    {
      key: "actions",
      dataIndex: "actions",
      render: (_, row: PrvProfilePatientData) => {
        const unreadMessages = unreadMessageNotifications.filter(
          item =>
            item.channel_name.includes(row?.username) &&
            item.channel_name.includes(providerData?.username || "") &&
            item.channel_name.includes(userContext.userData?.userName || "")
        );

        return (
          <ActionsWrapper>
            <span
              onClick={() => {
                setSelectedPatient(row);
                setShowMessages(true);
              }}
            >
              {unreadMessages.length > 0 && (
                <CircleIndicatorWrapper>
                  <CircleIndicator
                    outerColor={"#fbddb7"}
                    innerColor={"#f18e13"}
                  />
                </CircleIndicatorWrapper>
              )}
              <TableMessageIcon />
            </span>
          </ActionsWrapper>
        );
      },
      width: "5%"
    }
  ];

  const tableData: PrvProfilePatientData[] = useMemo(() => {
    if (!data) return [];
    return data.patients;
  }, [data]);

  const providerData = useMemo<SelectedProviderData | null>(() => {
    if (!data) return null;
    return {
      username: data.username,
      addressCity: data.addressCity,
      addressZip: data.addressZip,
      degree: data.degree,
      group: data.group,
      name: data.name,
      officeAddr1: data.officeAddr1,
      picture: data.picture,
      role: data.role,
      specialty: data.specialty,
      state: data.state,
      internal_id: data.internal_id
    };
  }, [data]);

  return (
    <>
      <TopbarExtension>
        <ProviderDetailsContainer>
          {isLoading || isFetching ? (
            <Skeleton
              paragraph={false}
              loading={isLoading || isFetching}
              avatar={{ shape: "circle", size: "large" }}
              active={isLoading || isFetching}
            />
          ) : (
            <>
              <ProviderDataContainer>
                <AppAvatar size="large" imageSrc="" />
                <ProviderAvatarContainer>
                  <ProviderName>{get(data, "name", "")}</ProviderName>
                  <ProviderBackLink onClick={onBackLinkClick}>
                    <BackArrow /> Back to Providers
                  </ProviderBackLink>
                </ProviderAvatarContainer>
                <ProviderDataItemContainer>
                  <ProviderDataItemHeaderText>
                    {get(data, "degree", "")}
                  </ProviderDataItemHeaderText>
                </ProviderDataItemContainer>
                <ProviderDataItemContainer>
                  <ProviderDataItemHeaderText>
                    Speciality
                  </ProviderDataItemHeaderText>
                  <ProviderDataItemContentText>
                    {get(data, "specialty", "")}
                  </ProviderDataItemContentText>
                </ProviderDataItemContainer>
                <ProviderDataItemContainer>
                  <ProviderDataItemHeaderText>Group</ProviderDataItemHeaderText>
                  <ProviderDataItemContentText>
                    {get(data, "group", "")}
                  </ProviderDataItemContentText>
                </ProviderDataItemContainer>
                <ProviderDataItemContainer>
                  <ProviderDataItemHeaderText>
                    Phone Number
                  </ProviderDataItemHeaderText>
                  <ProviderDataItemContentText>
                    {get(data, "phoneNumbers[0].number", "")}
                  </ProviderDataItemContentText>
                </ProviderDataItemContainer>
                <Dropdown
                  arrow
                  borderradius="8px"
                  visible={isPhoneDropdownVisible}
                  setVisible={onPhoneDropdownVisibilityChange}
                  placement="bottomRight"
                  dropDownMenuHeader={
                    <DropdownHeaderContainer>
                      Phone Numbers
                    </DropdownHeaderContainer>
                  }
                  dropDownMenuBody={
                    <PhoneNumberDropdownContainer>
                      <div>
                        {data?.phoneNumbers.map((phoneNumber, index) => (
                          <DropdownContentItemContainer key={index}>
                            <DropdownContentItemTitle>
                              {phoneNumber.title}
                            </DropdownContentItemTitle>
                            <DropdownContentItemContent>
                              {phoneNumber.number}
                            </DropdownContentItemContent>
                          </DropdownContentItemContainer>
                        )) || null}
                      </div>
                      <MoreDetailsContainer
                        onClick={() => {
                          setShowPhoneNumberSelectModal(true);
                          setIsPhoneDropdownVisible(false);
                        }}
                      >
                        <TablePhoneIcon />
                      </MoreDetailsContainer>
                    </PhoneNumberDropdownContainer>
                  }
                >
                  <ExpandArrowContainer>
                    <ExpandDownArrow />
                  </ExpandArrowContainer>
                </Dropdown>
                <ProviderDataItemContainer>
                  <ProviderDataItemHeaderText>Role</ProviderDataItemHeaderText>
                  <ProviderDataItemContentText>
                    {startCase(get(data, "role", ""))}
                  </ProviderDataItemContentText>
                </ProviderDataItemContainer>
              </ProviderDataContainer>
              <Dropdown
                arrow
                borderradius="8px"
                visible={isMoreDetailsDropdownVisible}
                setVisible={onMoreDetailsDropdownVisibilityChange}
                placement="bottomRight"
                dropDownMenuHeader={
                  <DropdownHeaderContainer>Details</DropdownHeaderContainer>
                }
                dropDownMenuBody={
                  <DropdownContentContainer>
                    <DropdownContentItemContainer>
                      <DropdownContentItemTitle>
                        Office Address
                      </DropdownContentItemTitle>
                      <DropdownContentItemContent>
                        {`${get(data, "officeAddr1", "")}, ${get(
                          data,
                          "addressCity",
                          ""
                        )}, ${get(data, "state", "")} ${get(
                          data,
                          "addressZip",
                          ""
                        )}`}
                      </DropdownContentItemContent>
                    </DropdownContentItemContainer>
                    <DropdownContentItemContainer>
                      <DropdownContentItemTitle>
                        Organizations
                      </DropdownContentItemTitle>
                      {data?.orgs.map((org, index) => (
                        <DropdownContentItemContent key={index}>
                          {org}
                        </DropdownContentItemContent>
                      )) || null}
                    </DropdownContentItemContainer>
                  </DropdownContentContainer>
                }
              >
                <MoreDetailsContainer>
                  <StyledEyeOutlined />
                </MoreDetailsContainer>
              </Dropdown>
            </>
          )}
        </ProviderDetailsContainer>
        {showPhoneNumberSelectModal && (
          <AppModal
            visible={showPhoneNumberSelectModal}
            title="Select Phone Number"
            cancelText="Cancel"
            okText="Call"
            disablePrimaryButton={!meetingContext.pstnPhoneNumber}
            onCancel={() => {
              setShowPhoneNumberSelectModal(false);
              meetingContext.setPSTNPhoneNumber("");
            }}
            onOk={() => {
              meetingContext.setTargetUser(
                patientContext.patientData?.first_name +
                  " " +
                  patientContext.patientData?.last_name,
                String(patientContext.patientData?.id)
              );
              meetingContext.setCurrentPage(1);
              meetingContext.maximize(true);

              setShowPhoneNumberSelectModal(false);
            }}
          >
            <Radio.Group>
              <Space direction="vertical">
                {data?.phoneNumbers.map((item, index) => {
                  return (
                    <Radio
                      value={index}
                      key={index}
                      onClick={() =>
                        meetingContext.setPSTNPhoneNumber(
                          item.number.replaceAll("-", "")
                        )
                      }
                    >
                      {`${item.number} (${item.title})`}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </AppModal>
        )}
      </TopbarExtension>
      <SharedPatientsTitleContainer>
        <SharedPatientsTitle>{`Patients shared with ${get(
          data,
          "name",
          ""
        )}`}</SharedPatientsTitle>
        <SearchBox
          value={searchValue}
          onChange={onSearchValueChange}
          placeholder="Search"
        />
      </SharedPatientsTitleContainer>

      <Row gutter={[20, 0]}>
        <Col span={showMessages ? 16 : 24}>
          <AppCard cardHeight="inherit" cardWidth="100%">
            <Table<PrvProfilePatientData>
              columns={columns}
              dataSource={tableData}
              rowKey="patientId"
              loading={isLoading}
              pagination={false}
              rowClassName={record =>
                record.patientId === selectedPatient?.patientId &&
                record.username === record.username
                  ? "care-team-member-selected"
                  : ""
              }
            />
          </AppCard>
        </Col>
        <Col span={showMessages ? 8 : 0}>
          <PrvDetailsMessagesSection
            selectedPatient={selectedPatient}
            providerData={providerData}
            onCloseMessageContainer={onCloseMessageContainer}
          />
        </Col>
      </Row>
    </>
  );
};

const CircleIndicatorWrapper = styled.span`
  position: absolute;
  margin-left: 7px;
`;

const ActionsWrapper = styled.div`
  display: grid;
  place-items: right;
  width: 60px;
`;
