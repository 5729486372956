import React from "react";
import { MENU_ITEM_ICON_DISABLED_COLOR } from "./sideBar";

interface IconProps {
  readonly isDisabled?: boolean;
}

export const MyCurrentStatusIcon: React.FC<IconProps> = ({ isDisabled }) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="my-current-status-icon"
          transform="translate(8.000000, 6.000000)"
        >
          <g id="Rectangle-Copy-212">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#filter-2)"
              xlinkHref="#path-1"
            ></use>
            <use
              fill={isDisabled ? MENU_ITEM_ICON_DISABLED_COLOR : "#208CFF"}
              fillRule="evenodd"
              xlinkHref="#path-1"
            ></use>
          </g>
          <path
            d="M10.8747986,10.8712537 L10.3096085,11.8098751 C10.1829727,12.0201815 9.95542289,12.1487809 9.7099328,12.1487809 L4.82352941,12.1487809 C4.43693009,12.1487809 4.12352941,11.8353802 4.12352941,11.4487809 C4.12352941,11.0621816 4.43693009,10.7487809 4.82352941,10.7487809 L9.31432892,10.7487809 L10.2660036,9.16831753 C10.535945,8.72002101 11.1843823,8.71561417 11.4603918,9.16020036 L13.1813695,11.9322894 L13.8119026,11.0436936 C13.9431721,10.8586984 14.1559461,10.7487809 14.3827828,10.7487809 L15.7659193,10.7487809 C16.1525186,10.7487809 16.4659193,11.0621816 16.4659193,11.4487809 C16.4659193,11.8353802 16.1525186,12.1487809 15.7659193,12.1487809 L14.7443979,12.1487809 L13.7133306,13.6018403 C13.4236981,14.0100128 12.8117196,13.9911766 12.5477379,13.5659643 L10.8747986,10.8712537 Z"
            id="Path-18-Copy"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M7.37647059,11.8156492 L5.97647059,11.8156492 C5.97647059,8.2509902 8.97825596,4.98738903 12.8966664,5.12813567 C16.8079504,5.26862635 19.5388528,8.44385307 19.420182,11.9254713 C19.2977331,15.5179322 16.5854091,18.6238609 12.6953555,18.5700059 C9.83365237,18.5303877 7.71301099,17.0582739 6.42294882,14.2424802 C7.35560674,14.2342203 7.8753417,14.2304491 7.98215372,14.2311665 C9.05188497,16.1839585 10.6065709,17.140954 12.7147357,17.1701401 C15.7802503,17.2125799 17.9227839,14.759128 18.0209945,11.8777801 C18.1141768,9.14395452 15.9598078,6.63906449 12.8464117,6.52723341 C9.76064334,6.4163947 7.37647059,9.00851506 7.37647059,11.8156492 Z"
            id="Path-19-Copy"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  );
};
