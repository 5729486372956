import React from "react";

export const SendIcon: React.FC = () => {
  return (
    <svg
      width="17px"
      height="18px"
      viewBox="0 0 17 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Primary-Button-send"
          transform="translate(-13.000000, -13.000000)"
          fill="#FFFFFF"
        >
          <g id="Group">
            <polygon
              id="ion-android-send---Ionicons"
              transform="translate(24.125000, 19.500000) rotate(-45.000000) translate(-24.125000, -19.500000) "
              points="16 27 16 21.1796875 27.640625 19.5 16 17.8203125 16 12 32.25 19.5"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};
