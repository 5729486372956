import React from "react";

export const AddIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_105_3)">
        <path
          d="M15.945 9.32031H11.0703V14.195C11.0703 14.5015 10.8218 14.75 10.5153 14.75H9.98469C9.67817 14.75 9.42969 14.5015 9.42969 14.195V9.32031H4.555C4.24848 9.32031 4 9.07183 4 8.76531V8.23469C4 7.92817 4.24848 7.67969 4.555 7.67969H9.42969V2.805C9.42969 2.49848 9.67817 2.25 9.98469 2.25H10.5153C10.8218 2.25 11.0703 2.49848 11.0703 2.805V7.67969H15.945C16.2515 7.67969 16.5 7.92817 16.5 8.23469V8.76531C16.5 9.07183 16.2515 9.32031 15.945 9.32031Z"
          fill="black"
        />
      </g>
      <path
        d="M15.945 9.32031H11.0703V14.195C11.0703 14.5015 10.8218 14.75 10.5153 14.75H9.98469C9.67817 14.75 9.42969 14.5015 9.42969 14.195V9.32031H4.555C4.24848 9.32031 4 9.07183 4 8.76531V8.23469C4 7.92817 4.24848 7.67969 4.555 7.67969H9.42969V2.805C9.42969 2.49848 9.67817 2.25 9.98469 2.25H10.5153C10.8218 2.25 11.0703 2.49848 11.0703 2.805V7.67969H15.945C16.2515 7.67969 16.5 7.92817 16.5 8.23469V8.76531C16.5 9.07183 16.2515 9.32031 15.945 9.32031Z"
        fill="#208CFF"
        stroke="#208CFF"
        strokeWidth="0.55"
      />
      <defs>
        <filter
          id="filter0_d_105_3"
          x="0"
          y="0.25"
          width="20.5"
          height="20.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0235294 0 0 0 0 0.615686 0 0 0 0 0.870588 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_105_3"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_105_3"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
