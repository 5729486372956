import React from "react";

export const CKDStageIcon = () => {
  return (
    <svg
      width="70px"
      height="70px"
      viewBox="0 0 70 70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Patient-MyHealthStatus-MyCurrentStatus-V1.1-O2"
          transform="translate(-823.000000, -172.000000)"
        >
          <g id="Group-3-Copy-14" transform="translate(799.000000, 148.000000)">
            <g id="ckd-stage-icon" transform="translate(24.000000, 24.000000)">
              <path
                d="M35,-2.72848411e-12 L35,-2.72848411e-12 C54.3299662,-2.73203496e-12 70,15.6700338 70,35 L70,35 C70,54.3299662 54.3299662,70 35,70 L35,70 C15.6700338,70 2.36723813e-15,54.3299662 0,35 L0,35 C-2.36723813e-15,15.6700338 15.6700338,-2.72493325e-12 35,-2.72848411e-12 Z"
                id="Rectangle-Copy-204"
                fill="#EBF3FB"
              ></path>
              <g
                id="Group-9"
                transform="translate(14.000000, 12.000000)"
                stroke="#212B40"
                strokeWidth="1.55"
              >
                <rect
                  id="Rectangle"
                  fill="#7CBA5B"
                  x="0.775"
                  y="0.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-5"
                  fill="#7CBA5B"
                  x="0.775"
                  y="8.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-10"
                  fill="#FBEB98"
                  x="0.775"
                  y="16.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-15"
                  fill="#E0D123"
                  x="0.775"
                  y="24.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-20"
                  fill="#F27B6E"
                  x="0.775"
                  y="32.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-22"
                  fill="#F27B6E"
                  x="0.775"
                  y="40.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-2"
                  fill="#7CBA5B"
                  x="15.775"
                  y="0.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-6"
                  fill="#FBEB98"
                  x="15.775"
                  y="8.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-11"
                  fill="#E0D123"
                  x="15.775"
                  y="16.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-16"
                  fill="#F27B6E"
                  x="15.775"
                  y="24.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-21"
                  fill="#F27B6E"
                  x="15.775"
                  y="32.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-24"
                  fill="#F27B6E"
                  x="15.775"
                  y="40.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-3"
                  fill="#E0D123"
                  x="30.775"
                  y="0.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-8"
                  fill="#E0D123"
                  x="30.775"
                  y="8.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-14"
                  fill="#F27B6E"
                  x="30.775"
                  y="16.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-19"
                  fill="#F27B6E"
                  x="30.775"
                  y="24.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-23"
                  fill="#F27B6E"
                  x="30.775"
                  y="32.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
                <rect
                  id="Rectangle-Copy-25"
                  fill="#F27B6E"
                  x="30.775"
                  y="40.775"
                  width="11.45"
                  height="5.45"
                  rx="1"
                ></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
