import React from "react";

export const LyingDownIcon: React.FC = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 1280.000000 825.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.15, written by Peter Selinger 2001-2017
      </metadata>
      <g
        transform="translate(0.000000,825.000000) scale(0.100000,-0.100000)"
        fill="#212B40"
        stroke="none"
      >
        <path
          d="M555 8243 c-243 -32 -447 -215 -527 -473 l-23 -75 -3 -3847 -2 -3848
    670 0 670 0 0 973 0 972 5075 3 5075 2 2 -967 3 -968 653 -3 652 -2 -2 2997
    -3 2998 -28 88 c-15 49 -38 109 -51 135 -113 223 -327 354 -576 354 -249 0
    -463 -131 -576 -354 -13 -26 -36 -86 -51 -135 l-28 -88 -3 -1242 -3 -1243
    -5084 0 -5085 0 -3 2073 c-2 1871 -4 2078 -19 2138 -52 211 -170 365 -343 449
    -119 58 -259 80 -390 63z"
        />
        <path
          d="M2516 7384 c-231 -42 -437 -148 -609 -313 -218 -209 -341 -465 -366
    -760 -29 -350 91 -684 337 -935 228 -233 522 -356 852 -356 330 0 624 123 852
    356 182 185 289 403 333 677 10 61 7 266 -4 339 -61 379 -306 714 -649 887
    -87 44 -221 87 -326 106 -109 19 -313 18 -420 -1z"
        />
        <path
          d="M4493 6570 c-178 -32 -329 -144 -407 -305 -71 -145 -69 -125 -73
    -782 l-4 -592 -997 -3 c-1112 -4 -1036 1 -1192 -75 -95 -46 -203 -151 -248
    -239 -152 -302 -15 -649 305 -770 142 -54 118 -53 1494 -54 1239 0 1278 1
    1353 20 107 27 169 63 251 145 80 80 124 156 155 267 20 75 21 90 18 994 l-3
    919 -29 84 c-82 234 -262 377 -496 394 -41 3 -98 2 -127 -3z"
        />
        <path
          d="M5312 5160 l3 -1390 2943 0 2942 0 0 924 c0 987 -1 1003 -50 1156
    -128 397 -456 643 -919 690 -68 6 -913 10 -2512 10 l-2409 0 2 -1390z"
        />
      </g>
    </svg>
  );
};
