import React from "react";
import { useState } from "react";
import { PatientEducationHeader } from "./patientEducationHeader";
import { DiseasesDictionary } from "./diseasesDictionary";

export const PatientEducationPage: React.FC = () => {
  const [selectedKeyWords, setSelectedKeyWords] = useState<string[]>([]);
  return (
    <>
      <PatientEducationHeader selectedKeyWordFunction={setSelectedKeyWords} />
      {/* <DiseasesDictionary selectedKeyWord={selectedKeyWords} /> */}
    </>
  );
};
