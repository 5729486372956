import React, { useState } from "react";
import { TopbarExtension } from "components/topbarExtension";
import { ExtensionItems } from "./extensionItems";
import { ProvidersTabs } from "./providersTabs";

export const ProvidersPage: React.FC = () => {
  const [searchNameValue, setSearchNameValue] = useState("");
  const [searchSpecialtyValue, setSearchSpecialtyValue] = useState("");

  return (
    <>
      <TopbarExtension>
        <ExtensionItems
          setSearchNameValue={setSearchNameValue}
          setSearchSpecialtyValue={setSearchSpecialtyValue}
          searchNameValue={searchNameValue}
          searchSpecialtyValue={searchSpecialtyValue}
        />
      </TopbarExtension>
      <ProvidersTabs
        searchNameValue={searchNameValue}
        searchSpecialtyValue={searchSpecialtyValue}
      />
    </>
  );
};
