import React, { useContext, useLayoutEffect, useMemo } from "react";
import { message, Row, Space, Table, Tooltip } from "antd";
import styled from "styled-components";
import { ColumnsType } from "antd/lib/table";
import { camelCase, isEmpty, sortBy } from "lodash";
import { EditOutlined, EyeOutlined, HistoryOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import { useMutation, useQuery } from "react-query";

import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { Colors } from "helpers/colors";
import { MessageRow } from "components/dropDownBox/customStyles";
import { CircleIndicator } from "components/circleIndicator";
import { CopyIcon } from "svgIcons/copyIcon";
import { ActiveMedicationResponse, ActiveMedicineData } from "sharedTypes";
import { MedicationPages } from "./medication";
import { getAccessToken } from "helpers/utils";
import { UserRoles } from "constants/roles";
import { createApiClient } from "apiClient";

interface TableRow {
  readonly id: string;
  readonly medicationName: string;
  readonly medicationUnitStrength: string;
  readonly dose: string;
  readonly sig: string;
  readonly doseUnitCode: string;
  readonly additionalNote: string;
  readonly medicineId: string;
  readonly IsDuplicate: boolean;
}

interface ActiveMedicationList {
  readonly data: ActiveMedicationResponse | undefined;
  readonly onMedicationDetailsPageChange: (page: MedicationPages) => void;
  readonly onSelectedMedicationforHistoryChange: (
    value: ActiveMedicineData | null
  ) => void;
  readonly activeMedicationsLoading: boolean;
  readonly selectedReasons: string;
}

export const ActiveMedicationList: React.FC<ActiveMedicationList> = ({
  data,
  onMedicationDetailsPageChange,
  onSelectedMedicationforHistoryChange,
  activeMedicationsLoading,
  selectedReasons
}) => {
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const userId = sessionStorage.getItem("userId") || "";

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const { id: patientId } = useParams();

  const unreadNotifications = useMemo(() => {
    return notificationsContext.filterNotificationsByStatusAndPatient(
      notificationsContext.notifications?.medications || [],
      userIsPatient ? userId : patientId || "",
      "unread"
    );
  }, [notificationsContext.notifications?.medications]);

  const { refetch: getNotificationsList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        userIsPatient
          ? UserRoles.PATIENT
          : userIsCaregiver
          ? UserRoles.CAREGIVER
          : UserRoles.PROVIDER
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      }
    }
  );

  const { mutate: markNotification } = useMutation(
    "mark-notification",
    createApiClient().putNotifications,
    {
      onSuccess: () => {
        getNotificationsList();
      },
      onError: () => {
        message.error("There was a problem updating notifications.");
      }
    }
  );

  useLayoutEffect(() => {
    return () => {
      if (getAccessToken())
        unreadNotifications
          .filter(item => !item.desc.includes("discontinued"))
          .forEach(item => {
            markNotification({
              notificationId: item?.notification_id || "",
              body: { status: "read", type: "medications" }
            });
          });
    };
  }, [unreadNotifications]);

  const columns: ColumnsType<TableRow> = [
    {
      title: "Name",
      key: "medicationName",
      dataIndex: "medicationName",
      width: "33%",
      render: (medicationName: string, row) => {
        const unreadItem = unreadNotifications.find(
          item => String(item.item_id) === String(row.id)
        );

        return (
          <MessageRow
            style={{ fontWeight: "bold", marginLeft: "10px" }}
            fontSize={"14px"}
          >
            {unreadItem && (
              <CircleIndicatorWrapper>
                <CircleIndicator
                  outerColor={"#fbddb7"}
                  innerColor={"#f18e13"}
                />
              </CircleIndicatorWrapper>
            )}
            <b>{`${medicationName} ${row.medicationUnitStrength}`}</b>
          </MessageRow>
        );
      }
    },
    {
      title: "Dose",
      key: "dose",
      dataIndex: "dose",
      width: "10%",
      render: (dose: string, row) => {
        return <span>{`${dose} ${camelCase(row.doseUnitCode)}`}</span>;
      }
    },
    {
      title: "Sig",
      key: "sig",
      dataIndex: "sig",
      width: "20%",
      render: (sig: string, row: TableRow) => (
        <Container>
          <MessageUpperRow>
            <b>{sig}</b>
          </MessageUpperRow>
          {row.additionalNote && (
            <MessageLowerRow>{row.additionalNote}</MessageLowerRow>
          )}
        </Container>
      )
    },
    {
      title: "",
      key: "IsDuplicate",
      dataIndex: "IsDuplicate",
      width: "20%",
      render: (IsDuplicate: string) =>
        IsDuplicate && (
          <Tooltip title="Duplicate Medicine" placement="right" color="gray">
            <DuplicateContainer>
              <CopyIcon />
            </DuplicateContainer>
          </Tooltip>
        )
    },
    {
      key: "actions",
      dataIndex: "actions",
      render(_value: undefined, row: TableRow) {
        return {
          props: {
            onClick: (e: any) => {
              e.stopPropagation();
            }
          },
          children: (
            <div
              style={{
                display: "grid",
                placeItems: "right",
                paddingRight: "10px"
              }}
              onClick={e => e.stopPropagation()}
            >
              <Space size={20}>
                <ActionSpan>
                  <EyeOutlined
                    style={{ fontSize: "20px", color: Colors.LightGrey }}
                    onClick={() => {
                      onSelectedMedicationforHistoryChange(
                        data?.Medication.find(item => item.Id === row.id) ||
                          null
                      );
                      onMedicationDetailsPageChange(
                        MedicationPages.MedicineDetails
                      );
                    }}
                  />
                </ActionSpan>
                <ActionSpan>
                  <EditOutlined
                    style={{ fontSize: "20px", color: Colors.LightGrey }}
                    onClick={() => {
                      onSelectedMedicationforHistoryChange(
                        data?.Medication.find(item => item.Id === row.id) ||
                          null
                      );
                      onMedicationDetailsPageChange(
                        MedicationPages.EditMedication
                      );
                    }}
                  />
                </ActionSpan>
                <ActionSpan>
                  <HistoryOutlined
                    style={{ fontSize: "20px", color: Colors.LightGrey }}
                    onClick={() => {
                      onSelectedMedicationforHistoryChange(
                        data?.Medication.find(item => item.Id === row.id) ||
                          null
                      );
                      onMedicationDetailsPageChange(
                        MedicationPages.MedicationHistory
                      );
                    }}
                  />
                </ActionSpan>
              </Space>
            </div>
          )
        };
        // render: (_value: undefined, row: TableRow) =>
      }
    }
  ];

  const tableData: TableRow[] = useMemo(() => {
    if (!data || isEmpty(data)) return [];
    const activeMedicationTableData: TableRow[] = sortBy(data.Medication, [
      item => item.ProductShortName.toLowerCase()
    ]).map(medicine => ({
      id: medicine.Id,
      medicationName: medicine.ProductLongName,
      medicationUnitStrength: medicine.UnitStrength,
      dose: medicine.Quantity,
      doseUnitCode: medicine.UnitCode,
      sig: `${medicine.Sig} ${medicine.Prn === "Y" ? "| PRN" : ""}`,
      additionalNote: medicine.SigExtraNote,
      medicineId: medicine.ProductId,
      IsDuplicate: medicine.IsDuplicate === "1"
    }));
    return activeMedicationTableData;
  }, [data]);

  return (
    <Table<TableRow>
      columns={columns}
      dataSource={tableData}
      rowKey="id"
      pagination={false}
      scroll={{
        y: selectedReasons ? "calc(100vh - 370px)" : "calc(100vh - 290px)"
      }}
      loading={activeMedicationsLoading}
      onRow={row => {
        return {
          onClick: () => {
            onSelectedMedicationforHistoryChange(
              data?.Medication.find(item => item.Id === row.id) || null
            );
            onMedicationDetailsPageChange(MedicationPages.MedicineDetails);
          } // click row
        };
      }}
      data-testid="active-medications-table"
    />
  );
};

const ActionSpan = styled.span`
  cursor: pointer;
`;

const MessageUpperRow = styled(Row)`
  color: ${Colors.gothicBold};
  font-size: 12px;
`;
const Container = styled.div`
  padding: 10px 0px;
`;

const MessageLowerRow = styled(Row)`
  font-size: 12px;
`;
const DuplicateContainer = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${Colors.Red};
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-left: -15px;
`;

const CircleIndicatorWrapper = styled.span`
  position: absolute;
  top: 4px;
  left: -5px;
`;
