import moment from "moment";
import { lowerCaseRemoveSpace } from "helpers/utils";
import { ExtendedSymptomData } from "pages/symptoms/SymptomsModal";

export const achesAndPainMapper = ({
  filteredExtendedSymptomData,
  mapperType,
  mapperValue
}: {
  filteredExtendedSymptomData?: ExtendedSymptomData[] | null;
  mapperType?: string | undefined;
  mapperValue?: string | number | undefined;
}) => {
  const severity: [string, number, string][] = [];
  const frequency: [string, number, string][] = [];
  const duration: [string, number, string][] = [];

  if (mapperType) {
    let tooltipValue = "";
    if (lowerCaseRemoveSpace(mapperType) === "frequency") {
      switch (mapperValue) {
        case 1:
          tooltipValue = "Rarely (1-3 times)";
          break;
        case 4:
          tooltipValue = "Occasionally (4-7 times)";
          break;
        case 7:
          tooltipValue = "Frequently (8-15 times)";
          break;
        case 10:
          tooltipValue = "Nearly everyday";
          break;
      }
    } else if (lowerCaseRemoveSpace(mapperType) === "duration") {
      switch (mapperValue) {
        case 1:
          tooltipValue = "Less than 5 minutes";
          break;
        case 4:
          tooltipValue = "5-30 minutes";
          break;
        case 9:
          tooltipValue = "Several hours";
          break;
        case 10:
          tooltipValue = "Several days";
          break;
      }
    }
    return tooltipValue;
  }

  const sortedData = filteredExtendedSymptomData?.sort(
    (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
  );

  sortedData?.forEach(item => {
    item.data?.map(item2 => {
      if (lowerCaseRemoveSpace(item2?.key) === "severity") {
        severity.push([
          String(moment(item.timestamp).valueOf()),
          Number(item2.value),
          "Severity"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "frequency") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "rarely(1-3times)":
            value = 1;
            break;
          case "occasionally(4-7times)":
            value = 4;
            break;
          case "frequently(8-15times)":
            value = 7;
            break;
          case "nearlyeveryday":
            value = 10;
            break;
        }
        frequency.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Frequency"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "length") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "lessthan5minutes":
            value = 1;
            break;
          case "5-30minutes":
            value = 4;
            break;
          case "severalhours":
            value = 9;
            break;
          case "severaldays":
            value = 10;
            break;
        }
        duration.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Duration"
        ]);
      }
    });
  });

  return [severity, frequency, duration];
};
