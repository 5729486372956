import React from "react";

export const ChartIcon: React.FC = () => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Vital-BloodPressure-V1.1-(2)"
          transform="translate(-1309.000000, -226.000000)"
          fill="#212B40"
        >
          <g id="Group-2-Copy-5" transform="translate(1294.000000, 211.000000)">
            <path
              d="M25.32,19.5 L28.68,19.5 L28.68,28.68 L25.32,28.68 L25.32,19.5 Z M15.32,28.68 L15.32,22 L18.68,22 L18.68,28.68 L15.32,28.68 Z M20.32,28.68 L20.32,15.32 L23.68,15.32 L23.68,28.68 L20.32,28.68 Z"
              id="equalizer---material"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
