import React, { useContext, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import { useMutation } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { Platform, UserRoles } from "constants/roles";
import { NephrologyLogo } from "pages/symptoms/surveyForms/nephrologyLogo";
import { FormSubmitButton } from "pages/symptoms/surveyForms/FormSubmitButton";
import { FormClearButton } from "pages/symptoms/surveyForms/FormClearButton";

interface FatigueFormProps {
  setShowSurveyForm(show: boolean): void;
  selectedItems: string[];
  goToNextFormOrReturn(): void;
}

export const FatigueForm: React.FC<FatigueFormProps> = ({
  setShowSurveyForm,
  selectedItems,
  goToNextFormOrReturn
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [currentPage, setCurrentPage] = useState(1);
  const [showSubmit, setShowSubmit] = useState(false);

  const [form] = Form.useForm();

  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const userRole = sessionStorage.getItem("userRole");

  const { isLoading: isLoadingFatigueMutation, mutate: submitFatigueForm } =
    useMutation<string>(
      "add-bill",
      async () => {
        return await createApiClient().addFatigueFormData({
          patient_internal_id:
            userRole === UserRoles.PATIENT && userContext.userData
              ? userContext.userData?.internal_id
              : patientContext?.patientData?.id || "",
          fatigue: sessionStorage.getItem("hasHadFatigue") || "",
          level: sessionStorage.getItem("activitiesThatareLimited") || "",
          submitted_by: `${userContext.userData?.firstName} ${userContext.userData?.lastName}`
        });
      },
      {
        onSuccess: () => {
          message.success("Form Submitted Successfully");
          goToNextFormOrReturn();
        },
        onError: () => {
          message.error({
            content: (
              <span>
                There was a problem submitting this form, resubmit or{" "}
                <a onClick={() => goToNextFormOrReturn()}>
                  click here to continue to the next survey
                </a>
              </span>
            ),
            style: {
              bottom: "10px",
              position: "fixed",
              left: "30%"
            },
            duration: 5
          });
        }
      }
    );

  const onFinish = () => {
    //Values to be passed on to API from session storage
    if (sessionStorage.getItem("hasHadFatigue") === "No") {
      form.resetFields(["Activities that are limited"]);
      sessionStorage.setItem("activitiesThatareLimited", "");
      submitFatigueForm();
    } else if (currentPage === 1) {
      setCurrentPage(2);
    } else {
      submitFatigueForm();
    }
  };

  const onClear = () => {
    if (currentPage === 1) {
      form.resetFields(["Any fatigue in last month"]);
    } else {
      form.resetFields(["Activities that are limited"]);
    }
  };

  return (
    <div>
      <div style={{ display: platform === Platform.MOBILE ? "none" : "block" }}>
        <AppButton
          type={ButtonType.Primary}
          onClick={() => setShowSurveyForm(false)}
          buttonContent={
            <>
              <ArrowLeftOutlined onClick={() => setShowSurveyForm(false)} />{" "}
              Back
            </>
          }
        />
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Form
          name="fatigue"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          style={{ padding: "0px" }}
        >
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
              style={{ borderTop: "8px solid #4284f3" }}
            >
              <PageTitle>Survey - {selectedItems[0]}</PageTitle>
            </AppCard>
            {currentPage === 1 && (
              <>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      fontWeight: "600"
                    }}
                  >
                    Please do not rely upon this survey for immediate medical
                    attention. This survey may not be reviewed by your care team
                    immediately. Please call your doctor for any medical
                    problems. IF YOU THINK THIS IS A MEDICAL EMERGENCY, OR YOUR
                    SYMPTOMS ARE WORRISOME PLEASE CALL 911 OR GO TO THE NEAREST
                    EMERGENCY ROOM.
                  </Space>
                </AppCard>

                {/* <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      fontWeight: "600"
                    }}
                  >
                    <NephrologyLogo />
                  </Space>
                </AppCard> */}
                <Space direction="vertical" size={20}>
                  <AppCard
                    cardHeight="fit-content"
                    cardWidth={
                      platform === Platform.MOBILE
                        ? "calc(100vw - 60px)"
                        : "640px"
                    }
                  >
                    <Space
                      direction="vertical"
                      size={20}
                      style={{ width: "100%", padding: "10px" }}
                    >
                      <CardTitle>
                        Have you had any fatigue limiting your activity in the
                        last one month?
                        <AsteriskStyle> *</AsteriskStyle>
                      </CardTitle>
                      <FormItemStyle
                        name="Any fatigue in last month"
                        label=""
                        rules={[
                          {
                            required: true
                          }
                        ]}
                        initialValue={""}
                      >
                        <Radio.Group
                          value={sessionStorage.getItem("hasHadFatigue")}
                          onChange={e => {
                            if (e.target.value === "No") {
                              setShowSubmit(true);
                            } else {
                              setShowSubmit(false);
                            }
                            sessionStorage.setItem(
                              "hasHadFatigue",
                              e.target.value
                            );
                          }}
                        >
                          <Space direction="vertical">
                            <Radio value={"Yes"}>Yes</Radio>
                            <Radio value={"No"}>No</Radio>
                          </Space>
                        </Radio.Group>
                      </FormItemStyle>
                    </Space>
                  </AppCard>
                </Space>
              </>
            )}
            {currentPage === 2 && (
              <Space direction="vertical" size={20}>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <CardTitle>
                      What activities are limited because of fatigue?
                      <AsteriskStyle> *</AsteriskStyle>
                    </CardTitle>
                    <FormItemStyle
                      name="Activities that are limited"
                      label=""
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      initialValue={""}
                    >
                      <Radio.Group
                        value={sessionStorage.getItem(
                          "activitiesThatareLimited"
                        )}
                        onChange={e =>
                          sessionStorage.setItem(
                            "activitiesThatareLimited",
                            e.target.value
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Radio
                            value={
                              "Only vigorous activities (No limitation of normal activity)"
                            }
                          >
                            Only vigorous activities (No limitation of normal
                            activity)
                          </Radio>
                          <Radio
                            value={
                              "Walking briskly, or on incline (Slight limitation of ordinary activity)"
                            }
                          >
                            Walking briskly, or on incline (Slight limitation of
                            ordinary activity)
                          </Radio>
                          <Radio
                            value={
                              "Walking 20 - 100 feet on the level (Moderate limitation of LESS-THAN-ordinary activity)"
                            }
                          >
                            Walking 20 - 100 feet on the level (Moderate
                            limitation of LESS-THAN-ordinary activity)
                          </Radio>
                          <Radio
                            value={
                              "At rest or on minimal activity (E.g. dressing, getting out of bed)"
                            }
                          >
                            At rest or on minimal activity (E.g. dressing,
                            getting out of bed)
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </FormItemStyle>
                  </Space>
                </AppCard>
              </Space>
            )}
            <Row>
              <Col span={5}>
                <Form.Item>
                  <FormClearButton onClear={onClear} />
                </Form.Item>
              </Col>
              <Col span={19} style={{ textAlign: "right" }}>
                <Form.Item>
                  <Space>
                    {currentPage !== 1 && (
                      <AppButton
                        type={ButtonType.Primary}
                        buttonContent={"Back"}
                        onClick={() => setCurrentPage(1)}
                        style={{ width: "90px" }}
                      />
                    )}
                    <FormSubmitButton
                      type={ButtonType.Primary}
                      buttonContent={
                        currentPage !== 1 || showSubmit ? "Submit" : "Next"
                      }
                      htmlType="submit"
                      loading={isLoadingFatigueMutation}
                    />
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Form>
      </div>
    </div>
  );
};

const PageTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
