import React, { useState } from "react";
import styled from "styled-components";

import Col from "antd/lib/col";
import message from "antd/lib/message";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Form from "antd/lib/form";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import { AppTextInput } from "components/inputs";
import { Colors } from "helpers/colors";
import { CareGemIcon } from "./careGemIcon";
import { LoginBackgroundIcon } from "./loginbackground";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";

const { Item } = Form;

const ResetPasswordPage: React.FC = () => {
  const [userId, setUserId] = useState<string>("");
  const [showResetSuccess, setShowResetSuccess] = useState(false);
  const onInputChange = (inputValue: string) => setUserId(inputValue);

  const navigate = useNavigate();

  const {
    isLoading: isLoadingResetPassword,
    isRefetching: isRefetchingResetPassword,
    refetch: resetPassword
  } = useQuery(
    "reset-password",
    async () => {
      return await createApiClient().resetPassword(userId);
    },

    {
      onSuccess: data => {
        if (data.statusCode === 200) {
          setShowResetSuccess(true);
          setTimeout(() => navigate("/"), 5000);
          return;
        }
        if (data.statusCode === 400) {
          message.error("User does not exist");
          return;
        }
        message.error("There was a error resetting this ID");
      },
      onError: () => {
        setShowResetSuccess(true);
        setTimeout(() => navigate("/"), 5000);
      },
      enabled: false
    }
  );

  const onFinish = () => {
    resetPassword();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <LoginBackground>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "grid",
          placeItems: "center",
          overflow: "hidden"
        }}
      >
        <LoginBackgroundIcon />
      </div>
      <AppCard
        cardHeight={showResetSuccess ? "295px" : "555px"}
        cardWidth={"380px"}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "scroll"
        }}
      >
        {!showResetSuccess && (
          <BackArrowContainer onClick={() => navigate("/")}>
            <ArrowLeftOutlined />
          </BackArrowContainer>
        )}

        <Row gutter={[0, 24]} style={{ marginTop: "10%" }}>
          <Col style={{ textAlign: "center" }} span={24}>
            <CareGemIcon logoHeight={53} logoWidth={63} />
            <div
              style={{ fontSize: "18px", fontWeight: 1000 }}
              color={Colors.Black}
            >
              <b>CareGem</b>
            </div>
          </Col>
          {showResetSuccess ? (
            <ResetSuccessMessage>
              The reset password link will be sent to your registered email
              address.
              <br />
              <br />
              If you don&apos;t get the email, please check your userid or call
              the practice for further assistance.
            </ResetSuccessMessage>
          ) : (
            <>
              <Col style={{ textAlign: "center" }} span={24}>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bolder",
                    color: Colors.Black
                  }}
                >
                  <b>Reset Password</b>
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    display: "grid",
                    placeItems: "center",
                    textAlign: "center"
                  }}
                >
                  Enter the User ID with which you have registered
                </div>
              </Col>
              <FormWrapper>
                <Form
                  name="login form"
                  labelCol={{ span: 8 }}
                  initialValues={{ userId: "" }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  style={{ display: "grid", placeItems: "center" }}
                  autoComplete="off"
                >
                  <Row gutter={[0, 60]}>
                    <Col offset={2}>
                      <Item
                        name="userId"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your user ID."
                          }
                        ]}
                      >
                        <AppTextInput
                          style={{
                            width: "260px",
                            borderRadius: "8px"
                          }}
                          value={userId}
                          label="User ID"
                          placeholder="User ID"
                          onChange={onInputChange}
                          labelStyles={{ zIndex: 1000 }}
                          textStyles={{ height: "46px" }}
                        />
                      </Item>
                    </Col>
                    <Col offset={2} span={20}>
                      <Item>
                        <AppButton
                          type={ButtonType.Primary}
                          htmlType="submit"
                          buttonContent={<Space size={4}>Send Link</Space>}
                          style={{
                            width: "260px",
                            marginBottom: "-20px"
                          }}
                          loading={
                            isLoadingResetPassword || isRefetchingResetPassword
                          }
                        />
                      </Item>
                      <Row
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          display: "grid",
                          placeItems: "center"
                        }}
                      >
                        Please contact administrator for support at
                        support@caregem.com
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </FormWrapper>
            </>
          )}
        </Row>
      </AppCard>
    </LoginBackground>
  );
};

{
  //TODO: Add this for Password Reset
  /* <div style={{ marginLeft: "35%", marginTop: "10%" }}>
  <PasswordResetSuccessful />
  </div> */
}
export default ResetPasswordPage;

const FormWrapper = styled.div`
  padding-left: 15px;
`;

const LoginBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(173.57deg, #06bfe3 0%, #208cff 100%);
  box-shadow: 0 3px 8px 0 rgba(6, 157, 222, 0.5);
`;

const BackArrowContainer = styled.div`
  position: absolute;
  top: 10;
  left: 10;
  color: ${Colors.Grey};
  cursor: pointer;
  font-size: 15px;
`;

const ResetSuccessMessage = styled.div`
  font-family: Century Gothic Bold;
  font-size: 13px;
  padding: 5%;
  text-align: center;
  margin-top: -30px;
`;
