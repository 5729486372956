import React, { useContext } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { Colors } from "helpers/colors";
import styled from "styled-components";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { useNavigate } from "react-router";

interface PatientMessagesHeaderProps {
  setViewSelected(type: string): void;
  viewSelected: string;
}

export const PatientMessagesHeader: React.FC<PatientMessagesHeaderProps> = ({
  setViewSelected,
  viewSelected
}) => {
  const navigate = useNavigate();
  const patientContext = useContext<PatientContextState>(PatientContext);

  const onBackClick = () => navigate(-1);
  return (
    <RowStyled gutter={[20, 0]}>
      <Col span={2}>
        <AppButton
          type={ButtonType.Secondary}
          onClick={onBackClick}
          buttonContent="Back"
        />
      </Col>
      <TitleCol span={16}>{`Messages to ${
        patientContext.patientData?.name || ""
      }`}</TitleCol>
      <Col span={6}>
        <Row>
          {viewSelected !== "all" ? (
            <AllButton onClick={() => setViewSelected("all")}>All</AllButton>
          ) : (
            <AllButtonActive onClick={() => setViewSelected("all")}>
              All
            </AllButtonActive>
          )}
          {viewSelected !== "individual" ? (
            <IndividualButton onClick={() => setViewSelected("individual")}>
              Individual
            </IndividualButton>
          ) : (
            <IndividualButtonActive
              onClick={() => setViewSelected("individual")}
            >
              Individual
            </IndividualButtonActive>
          )}
        </Row>
      </Col>
    </RowStyled>
  );
};

const AllButtonActive = styled(Col)`
  display: grid;
  place-items: center;
  width: 120px;
  height: 43px;
  border-radius: 8px 0px 0px 8px;
  background-color: #edf5fd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  font-weight: 600;
  color: ${Colors.DodgerBlue};
  border: 1px solid ${Colors.DodgerBlue};
  z-index: 2;
  cursor: pointer;
`;

const IndividualButtonActive = styled(Col)`
  display: grid;
  place-items: center;
  width: 120px;
  height: 43px;
  border-radius: 0px 8px 8px 0px;
  background-color: #edf5fd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  font-weight: 600;
  color: ${Colors.DodgerBlue};
  border: 1px solid ${Colors.DodgerBlue};
  margin-left: -1px;
  cursor: pointer;
`;

const AllButton = styled(Col)`
  display: grid;
  place-items: center;
  width: 120px;
  height: 43px;
  border: 1px solid ${Colors.Grey};
  border-radius: 8px 0px 0px 8px;
  background-color: #edf5fd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;

  :hover {
    font-weight: 600;
    color: ${Colors.DodgerBlue};
    border: 1px solid ${Colors.DodgerBlue};
    z-index: 2;
  }
`;

const IndividualButton = styled(Col)`
  display: grid;
  place-items: center;
  width: 120px;
  height: 43px;
  border: 1px solid ${Colors.Grey};
  border-radius: 0px 8px 8px 0px;
  background-color: #edf5fd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  margin-left: -1px;
  cursor: pointer;

  :hover {
    font-weight: 600;
    color: ${Colors.DodgerBlue};
    border: 1px solid ${Colors.DodgerBlue};
  }
`;

const RowStyled = styled(Row)`
  padding-bottom: 12px;
`;

const TitleCol = styled(Col)`
  padding-top: 10px;
  padding-left: 20px !important;
  font-family: Century Gothic Bold;
`;
