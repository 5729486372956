import React from "react";

export const NotificationsIcon: React.FC = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-MyProfile-V1.1"
          transform="translate(-1100.000000, -103.000000)"
        >
          <g id="Group-4" transform="translate(1083.000000, 43.000000)">
            <g id="Group-2" transform="translate(17.000000, 60.000000)">
              <rect
                id="Rectangle-Copy-2"
                stroke="#212B40"
                strokeWidth="2"
                x="1"
                y="1"
                width="14"
                height="14"
                rx="4"
              ></rect>
              <path
                d="M7,4 L9,4 L9,9 L7,9 L7,4 Z M8.02053619,9.84723178 C8.29653368,9.84723178 8.53268292,9.94469669 8.728991,10.1396294 C8.92529907,10.3345622 9.02345164,10.5704273 9.02345164,10.8472318 C9.02345164,11.1201376 8.92529907,11.3550281 8.728991,11.5519101 C8.53268292,11.7487922 8.29653368,11.8472318 8.02053619,11.8472318 C7.74453869,11.8472318 7.50936126,11.7497669 7.31499683,11.5548341 C7.1206324,11.3599014 7.02345164,11.1240363 7.02345164,10.8472318 C7.02345164,10.5704273 7.1206324,10.3345622 7.31499683,10.1396294 C7.50936126,9.94469669 7.74453869,9.84723178 8.02053619,9.84723178 Z"
                id="!"
                fill="#202E44"
                transform="translate(8.011726, 7.923616) rotate(-180.000000) translate(-8.011726, -7.923616) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
