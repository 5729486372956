// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext } from "react";

import { StyledWrapper, StyledAudioGroup, StyledVideoGroup } from "./Styled";
import MicrophoneDevices from "./MicrophoneDevices";
import SpeakerDevices from "./SpeakerDevices";
import CameraDevices from "./CameraDevices";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";
import MeetingJoinDetails from "components/meetings/containers/MeetingJoinDetails";

const DeviceSelection = () => {
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

  return (
    <StyledWrapper pstnCall={meetingContext.pstnPhoneNumber ? true : false}>
      <StyledAudioGroup
        pstnCall={meetingContext.pstnPhoneNumber ? true : false}
      >
        <MicrophoneDevices />
        <SpeakerDevices />
        <MeetingJoinDetails />
      </StyledAudioGroup>
      {meetingContext.pstnPhoneNumber ? null : (
        <StyledVideoGroup>
          <CameraDevices />
        </StyledVideoGroup>
      )}
    </StyledWrapper>
  );
};

export default DeviceSelection;
