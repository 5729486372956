import React from "react";
import styled from "styled-components";

import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { DateFormat } from "helpers/dateFormat";
import { DateTimeFormat } from "helpers/dateTimeFormat";

import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import { MessageRow } from "components/dropDownBox/customStyles";
import { LabData } from "sharedTypes";

interface LatestLabRecordTypes {
  data: LabData[];
}

export const LatestLabRecord: React.FC<LatestLabRecordTypes> = ({ data }) => {
  if (data.length === 0) {
    return null;
  }

  const latestItem = data.reduce((first, second) =>
    Date.parse(first.date_tested) > Date.parse(second.date_tested)
      ? first
      : second
  );

  const latestItemAndSiblings = data
    .filter(item => latestItem.name === item.name)
    .sort((a, b) => Date.parse(b.date_tested) - Date.parse(a.date_tested));

  return (
    <AppCard
      cardHeight="inherit"
      cardWidth="100%"
      style={{ marginBottom: "20px" }}
    >
      <RowStyle>Alert Trends</RowStyle>
      <StyledRow>
        <DateTimeFormat date={Date.parse(data[0].date_tested)} />
      </StyledRow>
      <AppCard
        cardHeight={"inherit"}
        cardWidth={"100%"}
        title={
          <div>
            <MessageRow fontSize={"12px"} color={`${Colors.Grey}`}>
              {latestItem.name}
            </MessageRow>
            <Row>
              {latestItemAndSiblings.map((item, index) => (
                <span key={index}>
                  {index <= 3 && (
                    <Col key={index}>
                      <Space>
                        <MessageRow
                          fontSize={"14px"}
                          color={`${index === 0 ? Colors.Red : Colors.Black}`}
                        >
                          {item.value}
                        </MessageRow>
                        <MessageRow fontSize={"12px"} color={`${Colors.Grey}`}>
                          <DateFormat date={Date.parse(item.date_tested)} />
                        </MessageRow>
                        {index !== 3 && (
                          <Divider
                            type="vertical"
                            style={{
                              color: Colors.BabyBlue,
                              fontWeight: 900
                            }}
                          />
                        )}
                      </Space>
                    </Col>
                  )}
                </span>
              ))}
            </Row>
          </div>
        }
      >
        <div
          style={{
            margin: "20px -16px -20px -16px",
            padding: "10px",
            backgroundColor: Colors.AliceBlue,
            borderRadius: "0 0 10px 10px"
          }}
        >
          <Row justify="start">
            <Col span={12}>
              <MessageRow fontSize={"12px"} color={`${Colors.Grey}`}>
                {latestItemAndSiblings.length === 1 ? (
                  latestItem.name + " increased/decreased by:"
                ) : (
                  <>
                    {latestItem.name}{" "}
                    {Number(latestItemAndSiblings[0].value) >
                    Number(latestItemAndSiblings[1].value)
                      ? "Increased"
                      : "Decreased"}{" "}
                    by:
                  </>
                )}
              </MessageRow>
            </Col>
            <MessageRow fontSize={"12px"} color={`${Colors.Grey}`}>
              Expected baseline:
            </MessageRow>
          </Row>
          <Row justify="start">
            <Col span={12}>
              {latestItemAndSiblings.length === 1 ? (
                "-"
              ) : (
                <MessageRow
                  fontSize={"14px"}
                  color={`${Colors.Black}`}
                  style={{ fontWeight: 900 }}
                >
                  {(
                    Number(latestItemAndSiblings[0].value) -
                    Number(latestItemAndSiblings[1].value)
                  ).toFixed(2)}{" "}
                  mg/dl
                </MessageRow>
              )}
            </Col>
            <MessageRow
              fontSize={"14px"}
              color={`${Colors.Black}`}
              style={{ fontWeight: 900 }}
            >
              -
            </MessageRow>
          </Row>
        </div>
      </AppCard>
    </AppCard>
  );
};

const RowStyle = styled.div`
  font-size: 12px;
  font-weight: 600;
  background-color: ${Colors.BlackSqueeze};
  text-align: center;
  height: 36px;
  color: ${Colors.Grey};
  border-radius: 5px;
  padding-top: 10px;
`;

const StyledRow = styled.div`
  padding: 5px 0px;
`;
