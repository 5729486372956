// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { useContext, useEffect } from "react";

import {
  MeetingStatus,
  useNotificationDispatch,
  Severity,
  ActionType,
  useMeetingStatus,
  useLogger
} from "amazon-chime-sdk-component-library-react";

import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";

const useMeetingEndRedirect = () => {
  const logger = useLogger();
  const dispatch = useNotificationDispatch();
  const meetingStatus = useMeetingStatus();
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

  useEffect(() => {
    if (meetingStatus === MeetingStatus.Ended) {
      logger.info("[useMeetingEndRedirect] Meeting ended");
      dispatch({
        type: ActionType.ADD,
        payload: {
          severity: Severity.INFO,
          message: "The meeting was ended by another attendee",
          autoClose: true,
          replaceAll: true
        }
      });
      meetingContext.closeSession();
    }
  }, [meetingStatus]);
};

export default useMeetingEndRedirect;
