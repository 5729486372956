import React from "react";

export const SymptomsIcon: React.FC = () => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Provider-Global-navigation-V2.3"
          transform="translate(-13.000000, -235.000000)"
        >
          <g
            id="Symptoms-menu-Copy-33"
            transform="translate(14.000000, 235.000000)"
          >
            <g id="Symptoms-icon" transform="translate(7.000000, 6.000000)">
              <g id="Rectangle-Copy-212">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill="#7CBA5B"
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <path
                d="M16.9821473,16.6086298 C16.9821473,16.0983753 16.739169,15.6512952 16.3601228,15.3645807 L16.3601228,11.6324334 C16.3601228,11.1173194 15.9422,10.6993966 15.4270859,10.6993966 C14.9119718,10.6993966 14.4940491,11.1173194 14.4940491,11.6324334 L14.4940491,15.3645807 C14.1150029,15.6512952 13.8720246,16.0983753 13.8720246,16.6086298 C13.8720246,17.4687732 14.5669426,18.1636912 15.4270859,18.1636912 C16.2872293,18.1636912 16.9821473,17.4687732 16.9821473,16.6086298 Z M17.6041719,16.6086298 C17.6041719,17.8089428 16.6273989,18.7857158 15.4270859,18.7857158 C14.2267729,18.7857158 13.25,17.8089428 13.25,16.6086298 C13.25,16.0157627 13.4881188,15.4812103 13.8720246,15.0875854 L13.8720246,11.6324334 C13.8720246,10.7722901 14.5669426,10.0773721 15.4270859,10.0773721 C16.2872293,10.0773721 16.9821473,10.7722901 16.9821473,11.6324334 L16.9821473,15.0875854 C17.3660531,15.4812103 17.6041719,16.0157627 17.6041719,16.6086298 Z"
                id=""
                stroke="#FFFFFF"
                strokeWidth="0.4"
                fill="#FFFFFF"
              ></path>
              <path
                d="M16.3601228,16.6086298 C16.3601228,17.1237439 15.9422,17.5416667 15.4270859,17.5416667 C14.9119718,17.5416667 14.4940491,17.1237439 14.4940491,16.6086298 C14.4940491,16.2198645 14.7370274,15.8651161 15.1160737,15.7290482 L15.1160737,13.8095194 C15.1318422,13.5111616 15.2355129,13.3619827 15.4270859,13.3619827 C15.6186589,13.3619827 15.7223297,13.5111616 15.7380982,13.8095194 L15.7380982,15.7290482 C16.1171444,15.8651161 16.3601228,16.2198645 16.3601228,16.6086298 Z"
                id="Path"
                fill="#FFFFFF"
              ></path>
              <path
                d="M9.81344505,9.27611962 C9.01371938,7.49921086 7.8113064,6.68546181 6.12389316,6.74877449 L6.08139977,5.61623806 C8.24873666,5.53491838 9.86468462,6.62853275 10.8469307,8.81098319 C11.7408525,10.7971862 12.3372583,12.1603001 12.6383996,12.9059721 L12.8405638,13.4065616 L11.2458558,14.1422673 L11.2458558,17.8347155 L10.751525,17.8983381 C9.89928643,18.008025 9.15476574,17.9829723 8.52044773,17.8156336 C8.52051877,18.1474144 8.52003221,18.4912066 8.51898815,18.8470129 L7.38565969,18.8436873 C7.38760822,18.1796465 7.38760822,17.5578058 7.38566045,16.9781816 L7.3823177,15.9834322 L8.23968246,16.4878758 C8.68937356,16.7524584 9.31205129,16.8684722 10.1125225,16.821319 L10.1125225,13.4169946 L11.3809576,12.8318122 C11.050768,12.0497166 10.527873,10.8635056 9.81344505,9.27611962 Z"
                id="Path-3"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
