import React from "react";

export const ReportSymptomsIconActive = () => {
  return (
    <svg
      width="18px"
      height="22px"
      viewBox="0 0 18 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Patient-Global-navigation-V1.2-O3"
          transform="translate(-1038.000000, -19.000000)"
          fill="#208CFF"
        >
          <g id="header">
            <g
              id="icon-button-secondary-symptoms"
              transform="translate(1024.000000, 8.000000)"
            >
              <g id="notifications">
                <g
                  id="Symptoms-icon"
                  transform="translate(14.000000, 11.000000)"
                >
                  <path
                    d="M16.473221,17.4129448 C16.473221,16.647563 16.1087534,15.9769428 15.5401841,15.5468711 L15.5401841,9.94865015 C15.5401841,9.17597903 14.9133,8.54909491 14.1406289,8.54909491 C13.3679578,8.54909491 12.7410737,9.17597903 12.7410737,9.94865015 L12.7410737,15.5468711 C12.1725043,15.9769428 11.8080368,16.647563 11.8080368,17.4129448 C11.8080368,18.7031597 12.8504139,19.7455368 14.1406289,19.7455368 C15.4308439,19.7455368 16.473221,18.7031597 16.473221,17.4129448 Z M17.4062578,17.4129448 C17.4062578,19.2134143 15.9410984,20.6785737 14.1406289,20.6785737 C12.3401594,20.6785737 10.875,19.2134143 10.875,17.4129448 C10.875,16.523644 11.2321782,15.7218155 11.8080368,15.1313781 L11.8080368,9.94865015 C11.8080368,8.65843516 12.8504139,7.61605808 14.1406289,7.61605808 C15.4308439,7.61605808 16.473221,8.65843516 16.473221,9.94865015 L16.473221,15.1313781 C17.0490796,15.7218155 17.4062578,16.523644 17.4062578,17.4129448 Z"
                    id=""
                    stroke="#208CFF"
                    strokeWidth="0.7"
                  ></path>
                  <path
                    d="M15.5401841,17.4129448 C15.5401841,18.1856159 14.9133,18.8125 14.1406289,18.8125 C13.3679578,18.8125 12.7410737,18.1856159 12.7410737,17.4129448 C12.7410737,16.8297967 13.1055412,16.2976742 13.6741105,16.0935724 L13.6741105,13.214279 C13.6977632,12.7667424 13.8532694,12.5429741 14.1406289,12.5429741 C14.4279884,12.5429741 14.5834945,12.7667424 14.6071473,13.214279 L14.6071473,16.0935724 C15.1757166,16.2976742 15.5401841,16.8297967 15.5401841,17.4129448 Z"
                    id="Path"
                    stroke="#208CFF"
                    strokeWidth="0.3"
                  ></path>
                  <path
                    d="M5.72016757,6.41417942 C4.52057907,3.74881629 2.7169596,2.52819271 0.185839738,2.62316173 L0.122099649,0.92435709 C3.37310499,0.802377573 5.79702692,2.44279913 7.27039602,5.71647479 C8.61127876,8.69577928 9.50588744,10.7404501 9.95759938,11.8589582 L10.2608456,12.6098424 L7.8687837,13.713401 L7.8687837,19.2520733 L7.12728747,19.3475072 C5.84892965,19.5120374 4.73214861,19.4744584 3.78067159,19.2234505 C3.78077816,19.7211216 3.78004831,20.23681 3.77848222,20.7705193 L2.07848954,20.765531 C2.08141233,19.7694697 2.08141233,18.8367087 2.07849068,17.9672725 L2.07347654,16.4751482 L3.3595237,17.2318136 C4.03406034,17.6286875 4.96807694,17.8027083 6.1687837,17.7319785 L6.1687837,12.6254918 L8.07143645,11.7477183 C7.57615199,10.5745749 6.79180954,8.79525842 5.72016757,6.41417942 Z"
                    id="Path-3"
                    fillRule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
