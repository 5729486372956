import React from "react";
import { MENU_ITEM_ICON_DISABLED_COLOR } from "components/sideBar/sideBar";

interface IconProps {
  readonly isDisabled?: boolean;
}

export const NotificationsIcon: React.FC<IconProps> = ({ isDisabled }) => {
  return (
    <svg
      width="15px"
      height="18px"
      viewBox="0 0 15 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Notifications-V1.1"
          transform="translate(-1237.000000, -21.000000)"
          fill={isDisabled ? MENU_ITEM_ICON_DISABLED_COLOR : "#212B40"}
        >
          <g id="main-header-normal">
            <g id="header">
              <g
                id="notifications-icon-button"
                transform="translate(1222.000000, 8.000000)"
              >
                <g id="notifications">
                  <path
                    d="M26,26.332 L26,20.832 C26,20.0693295 25.8533348,19.3726698 25.56,18.742 C25.2666652,18.1113302 24.8486694,17.6200018 24.306,17.268 C23.7486639,16.8866648 23.0960037,16.696 22.348,16.696 C21.5999963,16.696 20.9473361,16.8866648 20.39,17.268 C19.8473306,17.6200018 19.4293348,18.1113302 19.136,18.742 C18.8426652,19.3726698 18.696,20.0693295 18.696,20.832 L18.696,26.332 L26,26.332 Z M27.848,25.386 L29.696,27.234 L29.696,28.136 L15,28.136 L15,27.234 L16.848,25.386 L16.848,20.832 C16.848,19.3946595 17.2073297,18.1553385 17.926,17.114 C18.659337,16.043328 19.6786601,15.3466683 20.984,15.024 L20.984,14.386 C20.984,14.0046648 21.1159987,13.6783347 21.38,13.407 C21.6440013,13.1356653 21.9666648,13 22.348,13 C22.7293352,13 23.0556653,13.1356653 23.327,13.407 C23.5983347,13.6783347 23.734,14.0046648 23.734,14.386 L23.734,15.024 C25.0246731,15.3466683 26.036663,16.0506612 26.77,17.136 C27.4886703,18.1773385 27.848,19.4093262 27.848,20.832 L27.848,25.386 Z M22.348,30.886 C21.8493308,30.886 21.4166685,30.7100018 21.05,30.358 C20.6833315,30.0059982 20.5,29.5806692 20.5,29.082 L24.196,29.082 C24.196,29.5806692 24.0126685,30.0059982 23.646,30.358 C23.2793315,30.7100018 22.8466692,30.886 22.348,30.886 Z"
                    id="notifications_none---material"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
