import React, { useContext, useEffect, useState } from "react";

import { MedicationsHeader } from "./medicationsHeader";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import SwitchMedicationComponent from "./switchMedicationPage";
import { useMutation, useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { UserRoles } from "constants/roles";
import { ActiveMedicineData, MedicationData } from "sharedTypes";
import message from "antd/lib/message";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { get, isArray } from "lodash";
import {
  MedicationContext,
  MedicationContextState
} from "contextApis/medicationContext";

export enum MedicationPages {
  MedicineDetails = "Medication Details",
  EditMedication = "Edit Medication",
  MedicationHistory = "Medication History",
  DiscontinuedMedication = "Discontinued Medication",
  AddMedicationForm = "Add Medications",
  ActiveMedications = "Active Medications"
}

export const MedicationPage: React.FC = () => {
  //TODO - Revise state usage, to be moved to context or not
  const { patientData } = useContext<PatientContextState>(PatientContext);
  const [medicationDetailsPage, setMedicaitonDetailsPage] =
    useState<MedicationPages>(MedicationPages.ActiveMedications);
  const [selectedMedicationForHistory, setSelectedMediciationforHistory] =
    useState<ActiveMedicineData | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [medicationAddedToggle, setMedicationAddedToggle] =
    useState<boolean>(false);
  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;
  const userId = sessionStorage.getItem("userId") || "";
  const checkboxMenuContext =
    useContext<MedicationContextState>(MedicationContext);

  const { mutate } = useMutation(
    "add-medication",
    createApiClient().saveMedication,
    {
      onSuccess: (_data, { isEdit }) => {
        setMedicationAddedToggle(!medicationAddedToggle);
        if (isEdit) {
          message.success("Successfully edited medication");
          return;
        }
        message.success("Successfully added medication");
      },
      onError: (_data, { isEdit }) => {
        if (isEdit) {
          message.error("Failed to edit medication");
          return;
        }
        message.error("Failed to add medication");
      }
    }
  );

  const { mutate: discontinueMedication } = useMutation(
    "discontinue-medication",
    createApiClient().discontinueMedication,
    {
      onSuccess: () => {
        setMedicationAddedToggle(!medicationAddedToggle);
        message.success("Successfully discontinued medication");
      },
      onError: () => {
        message.error("Failed to discontinue medication");
      }
    }
  );

  const { mutate: deleteMedication } = useMutation(
    "delete-medication",
    createApiClient().deleteMedication,
    {
      onSuccess: () => {
        setMedicationAddedToggle(!medicationAddedToggle);
        message.success("Successfully deleted medication");
      },
      onError: () => {
        message.error("Failed to delete medication");
      }
    }
  );

  const onSearchValueChange = (value: string) => setSearchValue(value);
  const [selectedReasons, setSelectedReasons] = useState("");

  const onSelectedMedicationforHistoryChange = (
    value: ActiveMedicineData | null
  ) => setSelectedMediciationforHistory(value);

  const onMedicationDetailsPageChange = (page: MedicationPages) =>
    setMedicaitonDetailsPage(page);

  const {
    isLoading: isLoadingPrescribedReasonsList,
    data: prescribedReasonsList,
    error: isPrescribedReasonsListError
  } = useQuery("medical-reasons-list", async () => {
    return await createApiClient().getMedicalReasonsList(true);
  });

  const {
    isLoading: isLoadingDiscontinuedReasons,
    data: discontinuedReasonsList,
    error: isDiscontinuedReasonsError
  } = useQuery("medical-discontinued-reasons-list", async () => {
    return await createApiClient().getMedicationDiscontinuedReasons();
  });

  const renderPrescribedReasons =
    !isPrescribedReasonsListError &&
    !isLoadingPrescribedReasonsList &&
    prescribedReasonsList &&
    isArray(prescribedReasonsList);

  const renderDiscontinuedReasons =
    !isDiscontinuedReasonsError &&
    !isLoadingDiscontinuedReasons &&
    discontinuedReasonsList &&
    isArray(discontinuedReasonsList);

  const showTopbarExtesion = !userIsPatient;

  useEffect(() => {
    //Set reasons dropdown menu to default values on load
    if (renderPrescribedReasons) {
      const newList = prescribedReasonsList.map((item, index) => {
        return {
          desc: item,
          code: String(index),
          detail_desc: "",
          checked: false
        };
      });
      const modifiedReasonsList = newList?.map(
        (item: {
          desc: string;
          code: string;
          checked: boolean;
          detail_desc: string;
        }) => {
          item.checked = false;
          return item;
        }
      );

      if (modifiedReasonsList) {
        const half = Math.floor(modifiedReasonsList.length / 2);

        const [leftSide, rightSide] = [
          modifiedReasonsList.slice(0, half + 1),
          modifiedReasonsList.slice(half + 1, modifiedReasonsList.length)
        ];

        checkboxMenuContext.setPrescribedMenuOneReasons({
          reasons: leftSide
        });
        checkboxMenuContext.setPrescribedMenuTwoReasons({
          reasons: rightSide
        });
      }
    }
    if (renderDiscontinuedReasons) {
      const newList = discontinuedReasonsList.map(item => {
        return {
          reason: item.discontinue_reason,
          id: item.id,
          checked: false
        };
      });
      const modifiedReasonsList = newList?.map(
        (item: { reason: string; id: string; checked: boolean }) => {
          item.checked = false;
          return item;
        }
      );

      if (modifiedReasonsList) {
        const half = Math.floor(modifiedReasonsList.length / 2);

        const [leftSide, rightSide] = [
          modifiedReasonsList.slice(0, half + 1),
          modifiedReasonsList.slice(half + 1, modifiedReasonsList.length)
        ];

        checkboxMenuContext.setDiscontinuedMenuOneReasons({
          reasons: leftSide
        });
        checkboxMenuContext.setDiscontinuedMenuTwoReasons({
          reasons: rightSide
        });
      }
    }
  }, [
    isLoadingPrescribedReasonsList,
    isLoadingDiscontinuedReasons,
    medicationDetailsPage
  ]);

  const onFormSubmit = (formData: MedicationData, isEdit?: boolean) => {
    mutate({
      patientId: userIsPatient ? userId : get(patientData, "id", ""),
      medicationData: formData,
      isEdit: isEdit
    });
    onMedicationDetailsPageChange(MedicationPages.ActiveMedications);
  };

  const onDiscontinueMedication = (
    patinetId: string | number,
    medicineId: string,
    reasons: { id: number | string; reason: string }[]
  ) => {
    discontinueMedication({
      patientId: patinetId,
      productId: medicineId,
      reasons: reasons
    });
    onMedicationDetailsPageChange(MedicationPages.ActiveMedications);
  };

  const onDeleteMedication = (
    patientId: string | number,
    medicineId: string
  ) => {
    deleteMedication({ patientId, productId: medicineId });
    onMedicationDetailsPageChange(MedicationPages.ActiveMedications);
  };

  return (
    <>
      {showTopbarExtesion && <CommonPatientExtensionItems />}
      <MedicationsHeader
        setMedicaitonDetailsPage={setMedicaitonDetailsPage}
        medicationDetailsPage={medicationDetailsPage}
        isLoadingReasonsList={isLoadingPrescribedReasonsList}
        isReasonsListError={isPrescribedReasonsListError}
        onSelectedMedicationforHistoryChange={
          onSelectedMedicationforHistoryChange
        }
        searchValue={searchValue}
        onSearchValueChange={onSearchValueChange}
        setSelectedReasons={setSelectedReasons}
        isLoadingDiscontinuedReasons={isLoadingDiscontinuedReasons}
        isDiscontinuedReasonsError={isDiscontinuedReasonsError}
      />
      <SwitchMedicationComponent
        medicationDetailsPage={medicationDetailsPage}
        onFormSubmit={onFormSubmit}
        onMedicationDetailsPageChange={onMedicationDetailsPageChange}
        isLoadingReasonsList={isLoadingPrescribedReasonsList}
        selectedMedicationForHistory={selectedMedicationForHistory}
        onSelectedMedicationforHistoryChange={
          onSelectedMedicationforHistoryChange
        }
        searchValue={searchValue}
        setSelectedReasons={setSelectedReasons}
        selectedReasons={selectedReasons}
        medicationAddedToggle={medicationAddedToggle}
        onDiscontinueMedication={onDiscontinueMedication}
        onDeleteMedication={onDeleteMedication}
      />
    </>
  );
};
