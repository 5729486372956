import { Space } from "antd";
import React, { ReactNode, useContext, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { ThemeProvider } from "styled-components";
import {
  MeetingModalContext,
  MeetingContextState
} from "contextApis/meetingModalContext";
import lightTheme from "helpers/meetingLightTheme";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import EndMeetingControl from "components/meetings/containers/EndMeetingControl";
import MeetingProviderWrapper from "components/meetings/containers/MeetingProviderWrapper";
import {
  AWSMeetingStateProvider,
  useAWSMeetingState
} from "components/meetings/providers/AWSMeetingStateProvider";
import { ReactPortal } from "components/portal/ReactPortal";
import "./modalStyles.css";

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  handleClose(): void;
}

const MeetingPortalModalBase: React.FC<ModalProps> = ({
  children,
  isOpen,
  handleClose
}) => {
  const nodeRef = useRef(null);
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const { setShowLeaveModal, showLeaveModal } = useAWSMeetingState();

  return (
    <ReactPortal wrapperId="react-portal-modal-container">
      <CSSTransition
        in={isOpen}
        timeout={{ appear: 0, exit: 300 }}
        unmountOnExit={meetingContext.targetUser.name ? false : true}
        classNames="modal"
        nodeRef={nodeRef}
      >
        <div className="modal" ref={nodeRef}>
          <div
            className="modal-content"
            style={{
              height:
                meetingContext.pstnPhoneNumber &&
                meetingContext.currentPage === 3
                  ? "70vh"
                  : meetingContext.pstnPhoneNumber &&
                    meetingContext.currentPage !== 1
                  ? "31vh"
                  : "100%"
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 1002
              }}
            >
              <Space>
                {meetingContext.currentPage === 2 && (
                  <AppButton
                    onClick={handleClose}
                    buttonContent="Minimize"
                    type={ButtonType.Primary}
                  />
                )}
                <AppButton
                  onClick={() => {
                    setShowLeaveModal(!showLeaveModal);
                    meetingContext.currentPage === 3 &&
                      meetingContext.maximize(false);
                  }}
                  buttonContent="Hide"
                  type={ButtonType.Primary}
                />
                {/* Render EndMeetingControl for use on device setup page to close session */}
                {meetingContext.currentPage === 1 && <EndMeetingControl />}
              </Space>
            </div>
            {children}
          </div>
        </div>
      </CSSTransition>
    </ReactPortal>
  );
};

export const MeetingPortalModal = () => {
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

  return (
    <>
      {meetingContext.targetUser.name && (
        <AppButton
          type={ButtonType.Primary}
          onClick={() => meetingContext.maximize(true)}
          buttonContent={
            <div>
              <div style={{ display: "inline-block" }}>
                <div>Meeting in Progress</div>
                <div style={{ fontSize: "11px" }}>Click to Expand</div>
              </div>
            </div>
          }
          style={{
            position: "fixed",
            bottom: "10px",
            right: "10px",
            zIndex: 1000,
            height: "fit-content"
          }}
        />
      )}
      <AWSMeetingStateProvider>
        <ThemeProvider theme={lightTheme}>
          <MeetingPortalModalBase
            handleClose={() => meetingContext.maximize(false)}
            isOpen={meetingContext.isMaximized}
          >
            <MeetingProviderWrapper />
          </MeetingPortalModalBase>
        </ThemeProvider>
      </AWSMeetingStateProvider>
    </>
  );
};
