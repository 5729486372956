import React, { useContext, useMemo } from "react";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import { UserMenu } from "components/userMenu";
import styled from "styled-components";
import { Colors } from "helpers/colors";
import { CaregiverRoutesRef } from "constants/routes";
import { AppButton } from "components/button";
import { MailOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { ButtonType } from "components/button/appButton";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { CircleIndicator } from "components/circleIndicator";
import { NotificationsDropdown } from "components/notificationsDropdown";
import { IsExpandedContext } from "contextApis/expandContext";

export const TopbarButtons: React.FC = () => {
  const navigate = useNavigate();

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const userId = sessionStorage.getItem("userId") || "";

  const unreadMessageNotifications = useMemo(() => {
    const unseenNotifications =
      notificationsContext.filterMessageNotificationsByStatus(
        notificationsContext.notifications?.messages || [],
        "unread"
      );
    return unseenNotifications;
  }, [notificationsContext.notifications?.messages]);

  const unreadMessages = unreadMessageNotifications.filter(
    item => String(userId) === String(item.notified_internal_id)
  );

  const handleAllMessagesButtonClick = () =>
    navigate(CaregiverRoutesRef.AllMessages);

  return (
    <ButtonsContainer size={30} direction="horizontal">
      <RowStyled gutter={[10, 0]}>
        <NotificationsDropdown />
        <MailButtonContainer
          isActive={window.location.pathname.includes(
            CaregiverRoutesRef.AllMessages
          )}
          onClick={handleAllMessagesButtonClick}
        >
          {/* {unreadMessages.length > 0 && (
            <CircleIndicatorWrapper>
              <CircleIndicator outerColor={"#fbddb7"} innerColor={"#f18e13"} />
            </CircleIndicatorWrapper>
          )} */}
          <AppButton buttonContent={<MailOutlined />} type={ButtonType.Link} />
        </MailButtonContainer>
        <UserMenu />
      </RowStyled>
    </ButtonsContainer>
  );
};

interface MailButtonContainerProps {
  readonly isActive?: boolean;
}

const MailButtonContainer = styled.div<MailButtonContainerProps>`
  border: ${props =>
    `2px solid ${props.isActive ? Colors.DodgerBlue : "transparent"}`};
  background-color: ${Colors.Lavender};
  border-radius: 8px;
  margin-right: 6px;
  padding-top: 5px;
  cursor: pointer;

  .ant-btn {
    color: ${Colors.Black};
    font-size: 20px;
    height: 24px;
  }
`;

const RowStyled = styled(Row)`
  margin-top: -15px;
  height: 45px;
`;

const ButtonsContainer = styled(Space)`
  position: fixed;
  top: 22px;
  right: 28px;
  z-index: 10;
`;

const CircleIndicatorWrapper = styled.span`
  position: absolute;
  margin-left: 27px;
`;
