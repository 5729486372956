import { ArrowLeftOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Form from "antd/lib/form";
import React, { useContext, useRef, useState, useEffect } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { BloodPressureSection } from "./bloodPressureSection";
import { HeartRateSection } from "./heartRateSection";
import { WeightSection } from "./weightSection";
import { Platform, UserRoles } from "constants/roles";
import { FormSubmitButton } from "pages/symptoms/surveyForms/FormSubmitButton";
import { FormClearButton } from "pages/symptoms/surveyForms/FormClearButton";
import moment from "moment";

interface VitalSignsFormProps {
  setShowSurveyForm(show: boolean): void;
  selectedItems: string[];
  goToNextFormOrReturn(): void;
}

export const VitalSignsForm: React.FC<VitalSignsFormProps> = ({
  setShowSurveyForm,
  selectedItems,
  goToNextFormOrReturn
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [currentPage, setCurrentPage] = useState(1);
  const [showSubmit, setShowSubmit] = useState(false);
  const bloodPressureTimeTaken = localStorage.getItem(
    "whatTimeWasBloodPressureTaken"
  );
  const weightScale = localStorage.getItem("selectScale");

  const [otherTempInput, setOtherTempInput] = useState("");
  const otherInputRef = useRef("");

  const [postureDuringBP, setPostureDuringBP] = useState(
    localStorage.getItem("postureDuringBP") || ""
  );

  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const [form] = Form.useForm();

  const userRole = sessionStorage.getItem("userRole");

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === "timeStamp" || event.key === "timeStampDate") {
        // Handle the change in local storage here
        const timeStamp = localStorage.getItem("timeStamp");
        const timeStampDate = localStorage.getItem("timeStampDate");

        const newDate = moment(timeStampDate);
        if (timeStamp === "AM") {
          localStorage.setItem(
            "dateYourBloodPressureWastaken",
            new Date(
              newDate.set({ hour: 9, minute: 0, second: 0 }).toString()
            ).toISOString()
          );
        } else {
          localStorage.setItem(
            "dateYourBloodPressureWastaken",
            new Date(
              newDate.set({ hour: 18, minute: 0, second: 0 }).toString()
            ).toISOString()
          );
        }
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const {
    isLoading: isLoadingVitalSignsMutation,
    mutate: submitVitalSignsSymptomForm
  } = useMutation<string>(
    "add-vital-signs",
    async () => {
      return await createApiClient().addVitalSignsFormData({
        patient_internal_id:
          userRole === UserRoles.PATIENT && userContext.userData
            ? userContext.userData?.internal_id
            : patientContext?.patientData?.id || "",
        bp_report:
          localStorage.getItem("reportYourBloodPressure") === ""
            ? null
            : localStorage.getItem("reportYourBloodPressure"),
        bp_taken_when:
          localStorage.getItem("whenWasBloodPressureTaken") === ""
            ? null
            : localStorage.getItem("whenWasBloodPressureTaken"),
        bp_taken_date:
          localStorage.getItem("dateYourBloodPressureWastaken") === ""
            ? null
            : localStorage.getItem("dateYourBloodPressureWastaken"),
        bp_taken_time:
          localStorage.getItem("whatTimeWasBloodPressureTaken") === ""
            ? null
            : localStorage.getItem("whatTimeWasBloodPressureTaken"),
        bp_posture:
          localStorage.getItem("postureDuringBP") === ""
            ? null
            : localStorage.getItem("postureDuringBP"),
        bp_comments:
          localStorage.getItem("reportOtherSymptoms") === ""
            ? null
            : localStorage.getItem("reportOtherSymptoms"),
        am_systolic_top:
          bloodPressureTimeTaken === "AM"
            ? localStorage.getItem("reportAMSystolicBPTop") === ""
              ? null
              : localStorage.getItem("reportAMSystolicBPTop")
            : null,
        am_diastolic_bottom:
          bloodPressureTimeTaken === "AM"
            ? localStorage.getItem("reportAMDiastolicBPBottom") === ""
              ? null
              : localStorage.getItem("reportAMDiastolicBPBottom")
            : null,
        pm_systolic_top:
          bloodPressureTimeTaken === "PM"
            ? localStorage.getItem("reportPMSystolicBPTop") === ""
              ? null
              : localStorage.getItem("reportPMSystolicBPTop")
            : null,
        pm_diastolic_bottom:
          bloodPressureTimeTaken === "PM"
            ? localStorage.getItem("reportPMDiastolicBPBottom") === ""
              ? null
              : localStorage.getItem("reportPMDiastolicBPBottom")
            : null,
        hr_report:
          localStorage.getItem("reportYourHeartRate") === ""
            ? null
            : localStorage.getItem("reportYourHeartRate"),
        hr_taken_when:
          localStorage.getItem("whenWasHeartRateTaken") === ""
            ? null
            : localStorage.getItem("whenWasHeartRateTaken"),
        hr_taken_date:
          localStorage.getItem("dateYourHeartRateWasTaken") === ""
            ? null
            : localStorage.getItem("dateYourHeartRateWasTaken"),
        heart_rate:
          localStorage.getItem("reportHeartRate") === ""
            ? null
            : localStorage.getItem("reportHeartRate"),
        weight_report:
          localStorage.getItem("reportYourWeight") === ""
            ? null
            : localStorage.getItem("reportYourWeight"),
        weight_taken_when:
          localStorage.getItem("whenWasWeightTaken") === ""
            ? null
            : localStorage.getItem("whenWasWeightTaken"),
        weight_taken_date:
          localStorage.getItem("dateYourWeightWasTaken") === ""
            ? null
            : localStorage.getItem("dateYourWeightWasTaken"),
        weight_scale:
          localStorage.getItem("selectScale") === ""
            ? null
            : localStorage.getItem("selectScale"),
        weight_pounds:
          weightScale === "Report in Pounds"
            ? localStorage.getItem("weight") === ""
              ? null
              : localStorage.getItem("weight")
            : null,
        weight_kilograms:
          weightScale === "Report in Kilograms"
            ? localStorage.getItem("weight") === ""
              ? null
              : localStorage.getItem("weight")
            : null,
        submitted_by: `${userContext.userData?.firstName} ${userContext.userData?.lastName}`
      });
    },
    {
      onSuccess: () => {
        message.success("Form Submitted Successfully");
        goToNextFormOrReturn();
      },
      onError: () => {
        message.error({
          content: (
            <span>
              There was a problem submitting this form, resubmit or{" "}
              <a onClick={() => goToNextFormOrReturn()}>
                click here to continue to the next survey
              </a>
            </span>
          ),
          style: {
            bottom: "10px",
            position: "fixed",
            left: "30%"
          },
          duration: 5
        });
      }
    }
  );

  const onFinish = () => {
    //Values to be passed on to API from session storage
    if (currentPage === 1) {
      if (localStorage.getItem("reportYourBloodPressure") === "No") {
        form.resetFields([
          "When was blood pressure taken",
          "What time was blood pressure taken",
          "When was your blood pressure taken(Date)",
          `Report ${bloodPressureTimeTaken} Systolic BP(Top Number)`,
          `Report ${bloodPressureTimeTaken} Diastolic BP(Bottom Number)`,
          "Posture during BP",
          "Report other symptoms"
        ]);
        localStorage.setItem("whenWasBloodPressureTaken", "");
        localStorage.setItem("dateYourBloodPressureWastaken", "");
        localStorage.setItem("whatTimeWasBloodPressureTaken", "");
        localStorage.setItem(
          `report${bloodPressureTimeTaken}SystolicBPTop`,
          ""
        );
        localStorage.setItem(
          `report${bloodPressureTimeTaken}DiastolicBPBottom`,
          ""
        );
        localStorage.setItem("reportOtherSymptoms", "");
        localStorage.setItem("postureDuringBP", "");
        setCurrentPage(6);
      } else {
        setCurrentPage(2);
      }
    } else if (currentPage === 2) {
      if (localStorage.getItem("whenWasBloodPressureTaken") === "Taken today") {
        localStorage.setItem("dateYourBloodPressureWastaken", "");
        form.resetFields(["When was your blood pressure taken(Date)"]);
        setCurrentPage(4);
      } else {
        localStorage.setItem("whatTimeWasBloodPressureTaken", "");
        form.resetFields(["What time was blood pressure taken"]);
        setCurrentPage(3);
      }
    } else if (currentPage === 3) {
      setCurrentPage(4);
    } else if (currentPage === 4) {
      setCurrentPage(5);
    } else if (currentPage === 5) {
      if (localStorage.getItem("reportOtherSymptoms")?.includes("Other")) {
        //To handle 'other' input from where is your pain section
        //Reading the symptoms value from local storage.
        const tempArray = localStorage
          .getItem("reportOtherSymptoms")
          ?.split(/,/);

        const index = tempArray?.indexOf("Other");

        if (index || index === 0) {
          tempArray?.splice(index, 1);

          if (tempArray && tempArray[0] === "other") {
            tempArray.shift();
          }
        }

        const finalReportOtherSymptoms =
          tempArray && tempArray.length > 0
            ? String(tempArray) + "," + otherInputRef.current
            : otherInputRef.current;

        localStorage.setItem("reportOtherSymptoms", finalReportOtherSymptoms);
      }
      setCurrentPage(6);
    } else if (currentPage === 6) {
      if (localStorage.getItem("reportYourHeartRate") === "No") {
        form.resetFields([
          "When did you take your heart rate",
          "When was your heart rate taken(Date)",
          "Report heart rate"
        ]);
        localStorage.setItem("whenWasHeartRateTaken", "");
        localStorage.setItem("dateYourHeartRateWasTaken", "");
        localStorage.setItem("reportHeartRate", "");
        setCurrentPage(10);
      } else {
        setCurrentPage(7);
      }
    } else if (currentPage === 7) {
      if (localStorage.getItem("whenWasHeartRateTaken") === "Taken today") {
        localStorage.setItem("dateYourHeartRateWasTaken", "");
        form.resetFields(["When was your heart rate taken(Date)"]);
        setCurrentPage(9);
      } else {
        localStorage.setItem("reportHeartRate", "");
        form.resetFields(["Report heart rate"]);
        setCurrentPage(8);
      }
    } else if (currentPage === 8) {
      setCurrentPage(9);
    } else if (currentPage === 9) {
      setCurrentPage(10);
    } else if (currentPage === 10) {
      if (localStorage.getItem("reportYourWeight") === "No") {
        form.resetFields([
          "When did you take your weight",
          "When was your weight taken(Date)",
          "Select scale",
          "weight"
        ]);
        localStorage.setItem("whenWasWeightTaken", "");
        localStorage.setItem("dateYourWeightWasTaken", "");
        localStorage.setItem("selectScale", "");
        localStorage.setItem("weight", "");
        submitVitalSignsSymptomForm();
      } else {
        setCurrentPage(11);
      }
    } else if (currentPage === 11) {
      if (localStorage.getItem("whenWasWeightTaken") === "Taken today") {
        localStorage.setItem("dateYourWeightWasTaken", "");
        form.resetFields(["When was your weight taken(Date)"]);
        setCurrentPage(13);
      } else {
        localStorage.setItem("", "");
        form.resetFields([""]);
        setCurrentPage(12);
      }
    } else if (currentPage === 12) {
      setCurrentPage(13);
    } else if (currentPage === 13) {
      setCurrentPage(14);
    } else {
      submitVitalSignsSymptomForm();
    }
  };

  const handleGoBack = () => {
    if (
      currentPage === 4 &&
      localStorage.getItem("whenWasBloodPressureTaken") === "Taken today"
    ) {
      setCurrentPage(2);
    } else if (
      currentPage === 6 &&
      localStorage.getItem("reportYourBloodPressure") === "No"
    ) {
      setCurrentPage(1);
    } else if (
      currentPage === 10 &&
      localStorage.getItem("reportYourHeartRate") === "No"
    ) {
      setCurrentPage(6);
    } else if (
      currentPage === 9 &&
      localStorage.getItem("whenWasHeartRateTaken") === "Taken today"
    ) {
      setCurrentPage(7);
    } else if (
      currentPage === 13 &&
      localStorage.getItem("whenWasWeightTaken") === "Taken today"
    ) {
      setCurrentPage(11);
    } else {
      setCurrentPage(currentPage !== 1 ? currentPage - 1 : 1);
    }
  };

  const onClear = () => {
    if (currentPage === 1) {
      form.resetFields(["Report your blood pressure"]);
    } else if (currentPage === 2) {
      form.resetFields(["When was blood pressure taken"]);
    } else if (currentPage === 3) {
      form.resetFields(["When was your blood pressure taken(Date)"]);
    } else if (currentPage === 4) {
      form.resetFields(["What time was blood pressure taken"]);
    } else if (currentPage === 5) {
      form.resetFields([
        `Report ${bloodPressureTimeTaken} Systolic BP(Top Number)`,
        `Report ${bloodPressureTimeTaken} Diastolic BP(Bottom Number)`,
        "Posture during BP",
        "Report other symptoms"
      ]);
      setPostureDuringBP("");
      otherInputRef.current === "";
      setOtherTempInput("");
    } else if (currentPage === 6) {
      form.resetFields(["Report your heart rate"]);
    } else if (currentPage === 7) {
      form.resetFields(["When did you take your heart rate"]);
    } else if (currentPage === 8) {
      form.resetFields(["When was your heart rate taken(Date)"]);
    } else if (currentPage === 9) {
      form.resetFields(["Report heart rate"]);
    } else if (currentPage === 10) {
      form.resetFields(["Report your weight"]);
    } else if (currentPage === 11) {
      form.resetFields(["When did you take your weight"]);
    } else if (currentPage === 12) {
      form.resetFields(["When was your weight taken(Date)"]);
    } else if (currentPage === 13) {
      form.resetFields(["Select scale"]);
    } else if (currentPage === 14) {
      form.resetFields(["weight"]);
    }
  };

  //Added scrollToFirstError attribute to form element

  return (
    <div>
      <div style={{ display: platform === Platform.MOBILE ? "none" : "block" }}>
        <AppButton
          type={ButtonType.Primary}
          onClick={() => setShowSurveyForm(false)}
          buttonContent={
            <>
              <ArrowLeftOutlined onClick={() => setShowSurveyForm(false)} />{" "}
              Back
            </>
          }
        />
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Form
          name="lightheadedness"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          style={{ padding: "0px" }}
        >
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
              style={{ borderTop: "8px solid #4284f3" }}
            >
              <PageTitle>Survey - {selectedItems[0]}</PageTitle>
            </AppCard>
            <BloodPressureSection
              currentPage={currentPage}
              bloodPressureTimeTaken={bloodPressureTimeTaken || ""}
              otherTempInput={otherTempInput}
              otherInputRef={otherInputRef}
              setOtherTempInput={setOtherTempInput}
              setPostureDuringBP={setPostureDuringBP}
              postureDuringBP={postureDuringBP}
            />
            <HeartRateSection currentPage={currentPage} />
            <WeightSection
              currentPage={currentPage}
              weightScale={weightScale || ""}
              setShowSubmit={setShowSubmit}
            />
            <Row>
              <Col span={5}>
                <Form.Item>
                  <FormClearButton onClear={onClear} />
                </Form.Item>
              </Col>
              <Col span={19} style={{ textAlign: "right" }}>
                <Form.Item>
                  <Space>
                    {currentPage !== 1 && (
                      <AppButton
                        type={ButtonType.Primary}
                        buttonContent={"Back"}
                        onClick={() => handleGoBack()}
                        style={{ width: "90px" }}
                      />
                    )}
                    <FormSubmitButton
                      type={ButtonType.Primary}
                      buttonContent={
                        currentPage === 14 || (showSubmit && currentPage === 10)
                          ? "Submit"
                          : "Next"
                      }
                      htmlType="submit"
                      loading={isLoadingVitalSignsMutation}
                    />
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Form>
      </div>
    </div>
  );
};

const PageTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
`;
