import React, { useContext, useEffect, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { UserContext, UserContextState } from "contextApis/userContext";
import { MessageContainer } from "components/messageContainer";
import { SelectedConversationUserInfo } from "./messages";
import {
  convertOneToOneChimeMessagesToCustomMessageData,
  MessageData
} from "components/messageContainer/messageContainer";
import { createApiClient } from "apiClient";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { message, Spin } from "antd";
import { LoadingContainer } from "./style";

interface MessageSectionProps {
  selectedConversationUserInfo: SelectedConversationUserInfo | null;
  onCloseMessageContainer: () => void;
}

interface OtherUserData {
  readonly name: string;
  readonly speciality: string;
  readonly subject: string;
  readonly degree: string;
  readonly picture: string;
}

export const MessageSection: React.FC<MessageSectionProps> = ({
  selectedConversationUserInfo,
  onCloseMessageContainer
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchChannelMessages
  } = useQuery(
    ["selected_channel_messages", selectedConversationUserInfo],
    async () => {
      return await createApiClient().listChannelMessages(
        selectedConversationUserInfo?.channel.channel_name || ""
      );
    },
    { enabled: selectedConversationUserInfo ? true : false }
  );

  useEffect(() => {
    if (selectedConversationUserInfo) {
      refetchChannelMessages();
    }
  }, [selectedConversationUserInfo]);

  const isChannelMessagesLoading = useMemo(
    () => isLoading || isFetching,
    [isFetching, isLoading]
  );

  const initialMessagesList: MessageData[] = useMemo(
    () =>
      convertOneToOneChimeMessagesToCustomMessageData(
        selectedConversationUserInfo,
        data?.messages || [],
        userContext.userData?.userName || ""
      ),
    [data]
  );

  const messagesListNextToken = useMemo(() => data?.next_token || null, [data]);

  const otheruserData: OtherUserData = useMemo(() => {
    if (selectedConversationUserInfo) {
      const loggedInUsername = userContext.userData?.userName || "";
      const prvUsername =
        selectedConversationUserInfo.channel.user2_uname === loggedInUsername
          ? selectedConversationUserInfo.channel.user1_uname
          : selectedConversationUserInfo.channel.user2_uname;
      const patUsername = selectedConversationUserInfo.channel.pat_uname;
      if (prvUsername) {
        return {
          name: `${
            selectedConversationUserInfo.userInfo[prvUsername].first_name || ""
          } ${
            selectedConversationUserInfo.userInfo[prvUsername].last_name || ""
          }`,
          speciality:
            selectedConversationUserInfo.userInfo[prvUsername].specialty || "",
          degree:
            selectedConversationUserInfo.userInfo[prvUsername].degree || "",
          picture: "",
          subject: `${
            selectedConversationUserInfo.userInfo[patUsername].first_name || ""
          } ${
            selectedConversationUserInfo.userInfo[patUsername].last_name || ""
          }`
        };
      }
      return {
        name: `${
          selectedConversationUserInfo.userInfo[patUsername].first_name || ""
        } ${
          selectedConversationUserInfo.userInfo[patUsername].last_name || ""
        }`,
        speciality: "",
        degree: "",
        picture: "",
        subject: ""
      };
    }
    return {
      name: "",
      speciality: "",
      subject: "",
      degree: "",
      picture: ""
    };
  }, [selectedConversationUserInfo]);

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const userId = sessionStorage.getItem("userId") || "";

  const { refetch: getNotificationsList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        "provider",
        "",
        ""
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      }
    }
  );

  const unreadMessageNotifications = useMemo(() => {
    const unseenNotifications =
      notificationsContext.filterMessageNotificationsByStatus(
        notificationsContext.notifications?.messages || [],
        "unread"
      );
    return unseenNotifications;
  }, [notificationsContext.notifications?.messages]);

  const { mutate: markNotification } = useMutation(
    "mark-notification",
    createApiClient().putNotifications,
    {
      onSuccess: () => {
        getNotificationsList();
      },
      onError: () => {
        message.error("There was a problem updating notifications.");
      }
    }
  );

  useEffect(() => {
    if (isLoading) return;
    if (selectedConversationUserInfo?.channel?.channel_name) {
      const unreadMessages = unreadMessageNotifications.filter(
        item =>
          selectedConversationUserInfo?.channel?.channel_name ===
          item.channel_name
      );

      unreadMessages.forEach(item => {
        markNotification({
          notificationId: item?.notification_id || "",
          body: { status: "read", type: "messages" }
        });
      });
    }
  }, [unreadMessageNotifications, isLoading]);

  const MESSAGE_CONTAINER_HEIGHT = "80%";

  return (
    <>
      {isChannelMessagesLoading ? (
        <LoadingContainer height={MESSAGE_CONTAINER_HEIGHT}>
          <Spin />
        </LoadingContainer>
      ) : (
        <MessageContainer
          messageItemWidth={MESSAGE_CONTAINER_HEIGHT}
          allMessagesFormat={false}
          sender={otheruserData.name}
          specialization={otheruserData.speciality}
          subject={otheruserData.subject}
          degree={otheruserData.degree}
          picture={otheruserData.picture}
          messageData={initialMessagesList}
          style={{
            width: "calc(100% + 18px)",
            borderRadius: "8px 0px 0px 8px",
            height: "calc(62vh + 112px)"
          }}
          nextToken={messagesListNextToken}
          selectedConversationUserInfo={selectedConversationUserInfo}
          onCloseMessageContainer={onCloseMessageContainer}
        />
      )}
    </>
  );
};
