import React from "react";

interface ArrowDownwardIconProps {
  color?: string;
}

export const ArrowDownwardIcon: React.FC<ArrowDownwardIconProps> = ({
  color = "#03A468"
}) => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-VD-Provider-MobileApp-RemoteVitals-V1.2"
          transform="translate(-304.000000, -357.000000)"
          fill={color}
        >
          <polygon
            id="arrow_upward---material-copy"
            transform="translate(311.000000, 364.000000) scale(1, -1) translate(-311.000000, -364.000000) "
            points="304.32 364 311 357.32 317.68 364 316.46 365.18 311.82 360.52 311.82 370.68 310.18 370.68 310.18 360.52 305.5 365.18"
          ></polygon>
        </g>
      </g>
    </svg>
  );
};
