import React from "react";
import { MENU_ITEM_ICON_DISABLED_COLOR } from "components/sideBar/sideBar";

interface IconProps {
  readonly isDisabled?: boolean;
}

export const EducationalTipsIcon: React.FC<IconProps> = ({ isDisabled }) => {
  return (
    <svg
      width="39px"
      height="36px"
      viewBox="0 0 39 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="LeftPannel-Patient-0"
          transform="translate(-14.000000, -306.000000)"
        >
          <g
            id="Fluid-MetricsFluid-Metrics-Copy-28"
            transform="translate(14.000000, 306.000000)"
          >
            <g
              id="educational-tips-icon"
              transform="translate(7.000000, 6.000000)"
            >
              <g id="Rectangle-Copy-210">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill={isDisabled ? MENU_ITEM_ICON_DISABLED_COLOR : "#F27B6E"}
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <path
                d="M6.30762373,9.75873305 L8.74082716,11.3133118 C9.87768091,10.9815298 10.8239159,10.8137489 11.5882353,10.8137489 C12.3533314,10.8137489 13.3691806,10.9823365 14.6483086,11.3163977 L16.9085602,9.77409063 L11.5921785,7.64621979 L6.30762373,9.75873305 Z"
                id="Rectangle-Copy-3"
                stroke="#FFFFFF"
                strokeWidth="1.2"
              ></path>
              <path
                d="M8.95826841,11.0285402 L8.95826841,12.5199447 C9.21286747,12.713961 9.40723914,12.9321537 9.53620062,13.1787777 C9.55824236,13.22093 9.64843263,13.3799984 9.67178197,13.4251223 C9.74027496,13.5574889 9.78784965,13.6873637 9.8195333,13.8424652 C10.4376634,13.7175719 11.067872,13.6470588 11.62716,13.6470588 C12.5151225,13.6470588 13.3973401,13.7828811 14.272148,14.0535457 L14.272148,11.0273118 C13.3856046,10.7119124 12.5032678,10.555039 11.6227467,10.555039 C10.7411565,10.555039 9.85375123,10.7122969 8.95826841,11.0285403 Z"
                id="Rectangle"
                stroke="#FFFFFF"
                strokeWidth="1.2"
              ></path>
              <polygon
                id="Path-6"
                fill="#FFFFFF"
                fillRule="nonzero"
                points="16.2 10.2941176 17.2 10.2941176 17.2 12.8819996 16.2 12.8819996"
              ></polygon>
              <circle
                id="Oval"
                fill="#FFFFFF"
                cx="16.5294118"
                cy="13.2588235"
                r="1"
              ></circle>
              <path
                d="M9.91861468,14.6141687 C11.460834,14.9202852 13.2478136,14.7842534 14.872148,14.16606 L14.872148,15.3254461 C12.989256,15.9550835 11.3604547,16.0779028 9.5507501,15.6938147 C9.10810849,15.5998693 9.49351422,14.5297901 9.91861468,14.6141687 Z"
                id="Path-7"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path
                d="M9.23529917,14.2941146 C9.23529917,13.3860252 8.4963277,12.6470538 7.58823838,12.6470538 C6.68014906,12.6470538 5.94117759,13.3860252 5.94117759,14.2941146 C5.94117759,15.2022039 6.68014906,15.9411753 7.58823838,15.9411753 C8.4963277,15.9411753 9.23529917,15.2022039 9.23529917,14.2941146 Z M11.1176544,17.3529417 C11.1176544,17.610295 10.9044188,17.8235305 10.6470656,17.8235305 C10.5220654,17.8235305 10.4007417,17.7720599 10.3161828,17.6838245 L9.05515189,16.42647 C8.62500432,16.7242645 8.11029783,16.8823529 7.58823838,16.8823529 C6.15808962,16.8823529 5,15.7242633 5,14.2941146 C5,12.8639658 6.15808962,11.7058762 7.58823838,11.7058762 C9.01838714,11.7058762 10.1764768,12.8639658 10.1764768,14.2941146 C10.1764768,14.816174 10.0183883,15.3308805 9.72059386,15.7610281 L10.9816248,17.022059 C11.0661837,17.1066179 11.1176544,17.2279416 11.1176544,17.3529417 Z"
                id=""
                stroke="#FFFFFF"
                strokeWidth="0.3"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
