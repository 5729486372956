import { DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import Form from "antd/lib/form";
import get from "lodash/get";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import { MessageRow } from "components/dropDownBox/customStyles";
import { AppModal } from "components/modal";
import { UserRoles } from "constants/roles";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContextState, UserContext } from "contextApis/userContext";
import { Colors } from "helpers/colors";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import { ActiveMedicineData, MedicationData } from "sharedTypes";
import { AddMedicationFormData } from "./AddMedicationForm";
import { DiscontinuedMedicationIcon } from "./discontinuedMedicationIcon";
import { MedicationDurationForm } from "./formInputs/medicationDurationForm";
import { MedicineReasonPrescribedInput } from "./formInputs/medicationReason";
import {
  MedicineDoseDetailProps,
  MedicineDoseDetailForm
} from "./formInputs/medicineDoseDetail";
import { MedicineInfoFromInput } from "./formInputs/medicineInfoFromInput";
import { MedicineSigDetailsInput } from "./formInputs/medicineSigDetails";
import {
  MedicationContext,
  MedicationContextState
} from "contextApis/medicationContext";
import { Checkbox } from "antd";
import { AppTextArea } from "components/inputs";

interface EditMedicationProps {
  readonly selectedMedication: ActiveMedicineData | null;
  readonly onFormSubmit: (formData: MedicationData, isEdit?: boolean) => void;
  readonly onDiscontinueMedication: (
    patientId: string | number,
    medicineId: string,
    reasons: { id: number | string; reason: string }[]
  ) => void;
  readonly onDeleteMedication: (
    patientId: string | number,
    medicine: string
  ) => void;
}

const EditMedication: React.FC<EditMedicationProps> = ({
  selectedMedication,
  onFormSubmit,
  onDiscontinueMedication,
  onDeleteMedication
}) => {
  const [form] = Form.useForm();
  const { userData } = useContext<UserContextState>(UserContext);
  const { patientData } = useContext<PatientContextState>(PatientContext);
  const [isDiscontinueMedicineModalOpen, setIsDiscontinueMedicineModalOpen] =
    useState<boolean>(false);
  const [isDeleteMedicineModalOpen, setIsDeleteMedicineModalOpen] =
    useState<boolean>(false);
  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;
  const userId = sessionStorage.getItem("userId") || "";
  const checkboxMenuContext =
    useContext<MedicationContextState>(MedicationContext);
  const [discontinueReasons, setDiscontinueReasons] = useState(
    checkboxMenuContext.discontinuedMenuOneReasons.reasons.concat(
      checkboxMenuContext.discontinuedMenuTwoReasons.reasons
    )
  );
  const [otherReasonChecked, setOtherReasonCheck] = useState(false);
  const [otherReasonValue, setOtherReasonValue] = useState("");

  const checkDose = (_: any, value: MedicineDoseDetailProps) => {
    if (value) {
      if (Number(value.dose) > 0) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Dose cannot be zero"));
    }
    return Promise.reject(new Error("Dose is required"));
  };

  const onDiscontinueButtonClick = () =>
    setIsDiscontinueMedicineModalOpen(true);

  const onDeleteButtonClick = () => setIsDeleteMedicineModalOpen(true);

  const onDiscontinueModalClose = () => {
    setIsDiscontinueMedicineModalOpen(false);
    setOtherReasonCheck(false);
    setOtherReasonValue("");
  };

  const onDeleteModalClose = () => setIsDeleteMedicineModalOpen(false);

  const onConfirmedDiscontinueMedfication = () => {
    onDiscontinueMedication(
      userIsPatient ? userId : get(patientData, "id", "").toString(),
      get(selectedMedication, "ProductId", ""),
      discontinueReasons
        .filter(item => item.checked)
        .map(item => {
          delete item.checked;
          if (String(item.id) === "999") {
            item.reason = otherReasonValue;
          }
          return item;
        })
    );
    setIsDiscontinueMedicineModalOpen(false);
  };

  const onConfirmedDeleteMedication = () => {
    onDeleteMedication(
      get(selectedMedication, "PatientInternalId", ""),
      get(selectedMedication, "Id", "")
    );
    setIsDiscontinueMedicineModalOpen(false);
  };

  const onFinish = (values: AddMedicationFormData) => {
    const formData: MedicationData = {
      id: get(selectedMedication, "Id", ""),
      CreatedBy: get(userData, "internal_id", ""),
      duration: `${get(values, "medicineDuration.durationNumber", "")} ${get(
        values,
        "medicineDuration.durationUnit",
        ""
      )}`,
      info_from: get(values, "medcineInfoFrom.infoFrom", ""),
      ingredient: get(selectedMedication, "Ingredient", []),
      medication_reasons: get(
        values,
        "medicineReasonPrescribed.reasonPrescribed",
        []
      ),
      PatientId: get(patientData, "id", "").toString(),
      PrescribedDate: moment().format("MM-DD-YYYY hh:mm:ss"),
      prn: get(values, "medicineDoseDetail.prn", false) ? "Y" : "N",
      product_id: get(selectedMedication, "ProductId", ""),
      product_long_name: get(selectedMedication, "ProductLongName", ""),
      product_short_name: get(selectedMedication, "ProductShortName", ""),
      quantity: get(values, "medicineDoseDetail.dose", ""),
      sig: get(values, "medicineDoseDetail.sig", ""),
      sig_extra_note: get(values, "medicineSigDetails.sigDetails", ""),
      status: "M",
      unit_code: get(values, "medicineDoseDetail.doseUnit", ""),
      unit_strength: get(selectedMedication, "UnitStrength", "")
    };
    onFormSubmit(formData, true);
  };

  useEffect(() => {
    const [durationNumber, durationUnit] = get(
      selectedMedication,
      "Duration",
      ""
    ).split(/\s+/);

    form.setFieldsValue({
      medicineName: get(selectedMedication, "ProductLongName", ""),
      medicineDoseDetail: {
        dose: get(selectedMedication, "Quantity", ""),
        doseUnit: get(selectedMedication, "UnitCode", ""),
        sig: get(selectedMedication, "Sig", ""),
        prn: get(selectedMedication, "Prn", "") === "Y" ? true : false
      },
      medicineSigDetails: {
        sigDetails: get(selectedMedication, "SigExtraNote", "")
      },
      medicineDuration: {
        durationNumber: durationNumber,
        durationUnit: durationUnit
      },
      medicineReasonPrescribed: {
        reasonPrescribed: get(selectedMedication, "MedicationReasons", [])
      },
      medcineInfoFrom: {
        infoFrom: get(selectedMedication, "InfoFrom", "")
      }
    });
  }, [selectedMedication]);

  const isMedicationLessThan24HoursOld =
    moment().valueOf() <
    moment(selectedMedication?.CreatedDate).valueOf() + 24 * 60 * 60 * 1000;

  return (
    <AppCard
      cardHeight="calc(100vh - 200px)"
      cardWidth="100%"
      style={{
        borderRadius: "0px 0px 8px 8px",
        marginTop: "-2px"
      }}
    >
      <FlexContainer>
        <FormContainer>
          <Form
            name="edit medication"
            form={form}
            onFinish={onFinish}
            initialValues={{
              medicineName: get(selectedMedication, "ProductLongName", ""),
              medicineDoseDetail: {
                dose: get(selectedMedication, "Quantity", ""),
                doseUnit: get(selectedMedication, "UnitCode", ""),
                sig: get(selectedMedication, "Sig", ""),
                prn: get(selectedMedication, "Prn", "") === "Y" ? true : false
              },
              medicineDuration: {
                durationNumber:
                  get(selectedMedication, "Duration", "").split(/\s+/)[0] || "",
                durationUnit:
                  get(selectedMedication, "Duration", "").split(/\s+/)[1] || ""
              },
              medicineSigDetails: {
                sigDetails: get(selectedMedication, "SigExtraNote", "")
              }
            }}
          >
            <OverflowContainer>
              <Form.Item
                name="medicineDoseDetail"
                rules={[{ validator: checkDose }]}
              >
                <MedicineDoseDetailForm
                  medicineName={
                    form?.getFieldValue("medicineName") ||
                    get(selectedMedication, "ProductLongName", "")
                  }
                  medicineAmount={
                    form?.getFieldValue("medicineAmount") || {
                      medicineAmount: get(
                        selectedMedication,
                        "UnitStrength",
                        ""
                      )
                    }
                  }
                  medicineUnit={selectedMedication?.UnitCode || ""}
                  editMedication
                />
              </Form.Item>
              <Form.Item name="medicineSigDetails">
                <MedicineSigDetailsInput />
              </Form.Item>
              <Form.Item name="medicineDuration">
                <MedicationDurationForm />
              </Form.Item>
              <Form.Item name="medicineReasonPrescribed">
                <MedicineReasonPrescribedInput />
              </Form.Item>
              <Form.Item name="medcineInfoFrom">
                <MedicineInfoFromInput />
              </Form.Item>
              <div>
                <Row style={{ marginTop: "20px" }}>
                  <Col span={8}>
                    <MessageRow fontSize={"12px"}>Entered On</MessageRow>
                    <DateTimeFormat
                      date={moment(
                        selectedMedication?.CreatedDate,
                        "YYYY-MM-DD"
                      ).valueOf()}
                    />
                  </Col>
                  <Divider type="vertical" style={{ height: "40px" }}></Divider>
                  <Col>
                    <MessageRow fontSize={"12px"}>Entered By</MessageRow>
                    <MessageRow fontSize={"14px"}>
                      {`${userData?.firstName || ""} ${
                        userData?.lastName || ""
                      }, ${userData?.degree || ""} | ${
                        userData?.specialty || ""
                      }`}
                    </MessageRow>
                  </Col>
                </Row>
              </div>
            </OverflowContainer>
          </Form>
        </FormContainer>
        <MedicationFooter>
          <Row style={{ marginTop: "20px" }}>
            <Col span={12}>
              <AppButton
                type={ButtonType.Skeleton}
                style={{ marginRight: "10px" }}
                buttonContent={
                  <Row>
                    <DiscontinuedMedicationIcon />
                    <ButtonText>
                      <b>Discontinue</b>
                    </ButtonText>
                  </Row>
                }
                onClick={onDiscontinueButtonClick}
              />
              <AppButton
                type={ButtonType.Skeleton}
                style={{
                  marginRight: "10px",
                  borderColor: isMedicationLessThan24HoursOld
                    ? Colors.Red
                    : Colors.Grey,
                  color: isMedicationLessThan24HoursOld
                    ? Colors.Red
                    : Colors.Grey
                }}
                buttonContent={
                  <Row>
                    <DeleteOutlined style={{ fontSize: "20px" }} />
                    <ButtonText>
                      <b>Delete</b>
                    </ButtonText>
                  </Row>
                }
                onClick={onDeleteButtonClick}
              />
            </Col>
            <Col style={{ textAlign: "right" }} span={12}>
              <AppButton
                type={ButtonType.Primary}
                buttonContent="Save"
                style={{ minWidth: 120 }}
                onClick={form.submit}
              />
            </Col>
          </Row>
        </MedicationFooter>
      </FlexContainer>
      {isDiscontinueMedicineModalOpen && (
        <AppModal
          visible={isDiscontinueMedicineModalOpen}
          title="Discontinue Medication"
          cancelText="Cancel"
          okText="Discontinue"
          onCancel={onDiscontinueModalClose}
          onOk={onConfirmedDiscontinueMedfication}
          disablePrimaryButton={
            otherReasonChecked && !otherReasonValue
              ? true
              : discontinueReasons.every(item => !item.checked)
              ? true
              : false
          }
          prompt={{
            icon: (
              <WarningOutlined style={{ color: "#F5A623", fontSize: "40px" }} />
            ),
            text: (
              <div>
                <div>{`Are you sure you want to discontinue - ${get(
                  selectedMedication,
                  "ProductShortName",
                  ""
                )}?`}</div>
                <Checkbox.Group
                  style={{
                    marginTop: "5px"
                  }}
                  onChange={e =>
                    setDiscontinueReasons(
                      discontinueReasons.map(item => {
                        if (e.includes(item.id)) {
                          item.checked = true;
                        } else {
                          item.checked = false;
                        }
                        return item;
                      })
                    )
                  }
                >
                  Reason to discontinue:
                  <Row
                    style={{
                      textAlign: "left",
                      position: "relative",
                      marginTop: "5px"
                    }}
                  >
                    <Col
                      span={24}
                      style={{ textAlign: "left", columnCount: 2 }}
                    >
                      {discontinueReasons.map(item => {
                        return (
                          <Checkbox
                            value={item.id}
                            key={item.id}
                            style={{ marginLeft: "8px" }}
                            onChange={() => {
                              if (String(item.id) === "999") {
                                setOtherReasonCheck(!otherReasonChecked);
                              }
                            }}
                          >
                            {item.reason}
                          </Checkbox>
                        );
                      })}
                    </Col>
                    {otherReasonChecked && (
                      <Col span={24} style={{ marginTop: "10px" }}>
                        <AppTextArea
                          label="Other Reason(s)"
                          value={otherReasonValue}
                          onChange={e => setOtherReasonValue(e)}
                        />
                      </Col>
                    )}
                  </Row>
                </Checkbox.Group>
              </div>
            )
          }}
          footer
        />
      )}
      <AppModal
        visible={isDeleteMedicineModalOpen}
        title="Delete Medication"
        cancelText={isMedicationLessThan24HoursOld ? `Cancel` : ""}
        okText={isMedicationLessThan24HoursOld ? `Delete` : "Close"}
        onCancel={onDeleteModalClose}
        onOk={() => {
          if (isMedicationLessThan24HoursOld) {
            onConfirmedDeleteMedication();
          } else {
            onDeleteModalClose();
          }
        }}
        prompt={{
          icon: (
            <WarningOutlined style={{ color: "#F5A623", fontSize: "40px" }} />
          ),
          text: isMedicationLessThan24HoursOld ? (
            <div>{`Are you sure you wish to delete ${get(
              selectedMedication,
              "ProductLongName",
              ""
            )} from the patient's active medication list?`}</div>
          ) : (
            "This medication was added more than 24 hours ago, it cannot be deleted."
          )
        }}
        footer
      />
      <AppModal
        visible={isDeleteMedicineModalOpen}
        title="Delete Medication"
        cancelText={isMedicationLessThan24HoursOld ? `Cancel` : ""}
        okText={isMedicationLessThan24HoursOld ? `Delete` : "Close"}
        onCancel={onDeleteModalClose}
        onOk={() => {
          if (isMedicationLessThan24HoursOld) {
            onConfirmedDeleteMedication();
          } else {
            onDeleteModalClose();
          }
        }}
        prompt={{
          icon: (
            <WarningOutlined style={{ color: "#F5A623", fontSize: "40px" }} />
          ),
          text: isMedicationLessThan24HoursOld ? (
            <div>{`Are you sure you wish to delete ${get(
              selectedMedication,
              "ProductLongName",
              ""
            )} from the patient's active medication list?`}</div>
          ) : (
            "This medication was added more than 24 hours ago, it cannot be deleted."
          )
        }}
        footer
      />
    </AppCard>
  );
};

export default EditMedication;

const MedicationFooter = styled.div`
  border-top: 1px solid ${Colors.Lavender};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
  margin-bottom: 0px;
`;

export const MedicationText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-top: 18px;
`;

export const Container = styled.div`
  margin-top: 20px;
`;
const FlexContainer = styled.div`
  height: calc(100vh - 230px);
`;
const FormContainer = styled.div`
  height: calc(100vh - 300px);
`;
const OverflowContainer = styled.div`
  height: calc(100vh - 310px);
  overflow: auto;
`;
const ButtonText = styled.span`
  margin-left: 5px;
`;
