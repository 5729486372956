import React, { useContext, useEffect, useRef, useState } from "react";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import styled, { keyframes } from "styled-components";
import { UserMenu } from "components/userMenu";
import { NotificationsDropdown } from "components/notificationsDropdown";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { Col } from "antd";
import { useNavigate } from "react-router";
import { ProvidersRoutesRef } from "constants/routes";
import { BpAletrsIcon } from "pages/bpAlerts/SVGIcons";
import { useIsExpanded } from "contextApis/expandContext";
import { Colors } from "helpers/colors";
import { CircleIndicator } from "components/circleIndicator";
import { NotificationsContext } from "contextApis/notificationsContext";

export const TopbarButtons: React.FC = () => {
  const navigate = useNavigate();
  const { isExpanded, setIsExpanded } = useIsExpanded();
  const { notifications } = useContext(NotificationsContext);
  const [filteredPatientData, setFilteredPatientData] = useState<any[]>([]);
  const buttonRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (notifications) {
      const filteredData = notifications?.remote_vitals?.filter(
        notification => notification?.alert_flag === "1"
      );
      setFilteredPatientData(filteredData);
    }
  }, [notifications]);
  // useEffect(() => {
  //   console.log(
  //     "Filtered Data: ",
  //     JSON.stringify(filteredPatientData, null, 2)
  //   );
  //   console.log(notifications);
  // }, [filteredPatientData]);

  return (
    <ButtonsContainer size={30} direction="horizontal">
      <RowStyled gutter={[10, 0]}>
        {/* <div ref={buttonRef}> */}
        <AppButton
          type={ButtonType.Secondary}
          style={{
            backgroundColor: "#EAF3FC",
            marginRight: "10px",
            border: "none",
            boxShadow: "none"
          }}
          buttonContent={
            <Row gutter={[10, 0]}>
              <Col style={{ paddingTop: "6px" }}>
                <BpAletrsIcon />
                {filteredPatientData?.map(patient => (
                  <div key={patient.patient_id}>
                    {patient?.alert_assist === "1" ? (
                      <div></div>
                    ) : (
                      <CircleIndicatorWrapper>
                        <CircleIndicator
                          outerColor={"#EEB5AD"}
                          innerColor={Colors.Red}
                        />
                      </CircleIndicatorWrapper>
                    )}
                  </div>
                ))}
              </Col>
            </Row>
          }
          onClick={() => {
            setIsExpanded(!isExpanded);
            if (isExpanded) {
              setIsExpanded(false);
            }
          }}
        />
        {/* </div> */}
        <AppButton
          type={ButtonType.Secondary}
          style={{
            backgroundColor: "#EAF3FC",
            marginRight: "10px",
            border: "none",
            boxShadow: "none"
          }}
          buttonContent={
            <Row gutter={[10, 0]}>
              <Col>
                <img
                  src={
                    "https://cdn.iconscout.com/icon/free/png-512/free-report-744-827064.png?f=webp&w=512"
                  }
                  alt="bookIcon"
                  width={20}
                />
              </Col>
            </Row>
          }
          onClick={() => navigate(ProvidersRoutesRef.SurveyReports)}
        />
        <NotificationsDropdown />
        <UserMenu />
      </RowStyled>
    </ButtonsContainer>
  );
};

const RowStyled = styled(Row)`
  margin-top: -15px;
  height: 45px;
`;

const ButtonsContainer = styled(Space)`
  position: fixed;
  top: 22px;
  right: 28px;
  z-index: 10;
`;

const blink = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.05;
  }
`;

const CircleIndicatorWrapper = styled.span`
  position: absolute;
  top: 5px;
  right: -5px;
  animation: ${blink} 1.3s infinite;
`;
