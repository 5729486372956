import React, { createContext, useState, ReactNode } from "react";

// Define the type for the context value
interface IsExpandedContextType {
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context with the type
export const IsExpandedContext = createContext<
  IsExpandedContextType | undefined
>(undefined);

// Create the provider component
export const IsExpandedProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <IsExpandedContext.Provider value={{ isExpanded, setIsExpanded }}>
      {children}
    </IsExpandedContext.Provider>
  );
};

// Custom hook for using the context
export const useIsExpanded = () => {
  const context = React.useContext(IsExpandedContext);
  if (context === undefined) {
    throw new Error("useIsExpanded must be used within an IsExpandedProvider");
  }
  return context;
};
