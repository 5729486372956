import React from "react";

export const CreatenineIcon = () => {
  return (
    <svg
      width="70px"
      height="70px"
      viewBox="0 0 70 70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Patient-MyHealthStatus-MyCurrentStatus-V1.1-O2"
          transform="translate(-551.000000, -172.000000)"
        >
          <g id="Group-3-Copy-13" transform="translate(527.000000, 148.000000)">
            <g id="creatinine-icon" transform="translate(24.000000, 24.000000)">
              <path
                d="M35,-2.72848411e-12 L35,-2.72848411e-12 C54.3299662,-2.73203496e-12 70,15.6700338 70,35 L70,35 C70,54.3299662 54.3299662,70 35,70 L35,70 C15.6700338,70 -7.27359038e-12,54.3299662 -7.27595761e-12,35 L-7.27595761e-12,35 C-7.27832485e-12,15.6700338 15.6700338,-2.72493325e-12 35,-2.72848411e-12 Z"
                id="Rectangle-Copy-204"
                fill="#EBF3FB"
              ></path>
              <g id="Group-7" transform="translate(13.000000, 9.000000)">
                <polygon
                  id="Path-21"
                  fill="#7CBA5B"
                  points="11.9874072 21 29.0220179 21 39.560933 46.3999966 39.560933 48.6356438 37.8254087 49.4446926 3.56277441 49.4446926 1.78757907 48.6356438 1.52514245 46.3999966"
                ></polygon>
                <circle
                  id="Oval"
                  fill="#212B40"
                  cx="15.1785714"
                  cy="33.9285714"
                  r="1"
                ></circle>
                <circle
                  id="Oval-Copy"
                  fill="#212B40"
                  cx="19.6428571"
                  cy="36.6071429"
                  r="1"
                ></circle>
                <circle
                  id="Oval-Copy-3"
                  fill="#212B40"
                  cx="29.4642857"
                  cy="39.2857143"
                  r="1"
                ></circle>
                <circle
                  id="Oval-Copy-4"
                  fill="#212B40"
                  cx="26.7857143"
                  cy="44.6428571"
                  r="1"
                ></circle>
                <circle
                  id="Oval-Copy-2"
                  fill="#212B40"
                  cx="16.0714286"
                  cy="41.9642857"
                  r="1"
                ></circle>
                <polygon
                  id="Rectangle"
                  fill="#FFFFFF"
                  points="13 2 28 2 28 18.0548763 29 21 12 21 13 18.5093851"
                ></polygon>
                <path
                  d="M12.2811653,21 L28.8673113,20.8068293 L31.1186582,26 C29.3525452,27.8938569 26.145953,28.1845569 21.4988818,26.8721 C16.8518106,25.5596431 12.6943673,26.2689431 9.02655192,29 L12.2811653,21 Z"
                  id="Path-3"
                  fill="#B3E897"
                ></path>
                <path
                  d="M13.25,2.42857143 L13.25,18.3727998 L1.55360612,46.1261892 C1.43704595,46.4027651 1.3769982,46.6998659 1.3769982,47 C1.3769982,48.2426407 2.38435752,49.25 3.6269982,49.25 L37.4448391,49.25 C37.74491,49.25 38.0419497,49.1899775 38.318477,49.0734649 C39.4636201,48.590968 40.0008009,47.271505 39.5183039,46.126362 L27.8214286,18.3653664 L27.8214286,2.42857143 L13.25,2.42857143 Z"
                  id="Rectangle"
                  stroke="#212B40"
                  strokeWidth="1.5"
                ></path>
                <rect
                  id="Rectangle"
                  stroke="#212B40"
                  strokeWidth="1.5"
                  x="10.8571429"
                  y="-0.75"
                  width="19.3571429"
                  height="3.17857143"
                  rx="1.58928571"
                ></rect>
                <path
                  d="M28.4177377,21.0178571 L17.5814327,21.0178571"
                  id="Path-20-Copy-4"
                  stroke="#212B40"
                  strokeWidth="1.55"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M27.0678663,7.5 L22,7.5"
                  id="Path-20"
                  stroke="#212B40"
                  strokeWidth="1.55"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M27.0678663,15.5 L22,15.5"
                  id="Path-20-Copy-2"
                  stroke="#212B40"
                  strokeWidth="1.55"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M27.0678663,11.5 L22,11.5"
                  id="Path-20-Copy"
                  stroke="#212B40"
                  strokeWidth="1.55"
                  strokeLinecap="round"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
