import { Colors } from "helpers/colors";
import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

interface SymptomsTitleTextProps {
  readonly padding?: string;
}

export const SymptomsTitleText = styled.div<SymptomsTitleTextProps>`
  font-size: 14px;
  font-weight: bold;
  padding-top: ${props => (props.padding ? props.padding : "18px")};
`;
export const OverflowContainer = styled.div`
  height: calc(100vh - 220px);
  overflow: auto;
  padding-right: 5px;
`;

export const AddSymptomsOverflowContainer = styled.div`
  height: calc(100vh - 200px);
  overflow: auto;
  padding-right: 5px;
`;
export const EmptyDataText = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;
export const ErrorMessage = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  color: red;
  opacity: 0.3;
  font-weight: bold;
`;
export const SkeletonFlexContainer = styled.div`
  display: flex;
  gap: 20px;
`;
export const SymptomsListItemHeaderText = styled.div`
  font-size: 12px;
  padding-bottom: 5px;
  position: relative;
`;
export const SymptomsListItemContainer = styled.div`
  margin-bottom: 15px;
`;
export const SymptomTitleListContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  min-height: 30px;
  cursor: pointer;
  flex-wrap: wrap;
`;
export const SymptomTitleText = styled.div`
  font-style: 14px;
  font-weight: bold;
  line-height: 17px;
  color: ${Colors.DodgerBlue};
`;
export const AddSymptomButtonContaner = styled.div`
  display: flex;
  align-items: center;
`;
export const AddSymptomButtonText = styled.span`
  margin-left: 10px;

  @media screen and (max-width: 1260px) {
    display: none;
  }
`;

export const SymptomsItemsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;
export const SymptomItemDesctiptionTextContainer = styled.div`
  font-size: 14px;
  padding-top: 5px;
`;
export const SymptomItemDetailsGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  padding-top: 5px;
`;
export const SymptomItemDetailKeyText = styled.div`
  font-style: 14px;
`;
export const SymptomItemDetailValueText = styled.div`
  font-style: 14px;
  font-weight: bold;
`;

interface SymptomGridItemProps {
  readonly spanLength: number;
}
export const SymptomGridItem = styled.div<SymptomGridItemProps>`
  grid-row: ${props => `span ${props.spanLength}`};
`;

export const ModalContentContainer = styled.div`
  height: 70vh;
  display: grid;
  grid-template-columns: 1fr 40%;
  grid-column-gap: 10px;
`;
export const SymptomModalDetailsListContainer = styled.div`
  border-left: ${`1px solid ${Colors.Lavender}`};
  padding-left: 15px;
  overflow: auto;
`;
interface SymptomModalDetailsListItemProps {
  readonly showHr: boolean;
}
export const SymptomModalDetailsListItem = styled.div<SymptomModalDetailsListItemProps>`
  border-bottom: ${props =>
    props.showHr ? `1px solid ${Colors.Lavender}` : "none"};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;
export const SymptomDetailChartContainer = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-template-rows: 1fr 1fr 1fr;
  overflow: auto;
`;
export const ChartTitleText = styled.div`
  font-size: 14px;
`;
export const SymptomDetailListItemHeader = styled.div`
  font-size: 12px;
  margin-top: 10px;
`;
export const AddSymptomFormTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
`;
export const AddSymptomFormSubtitleText = styled.div`
  font-size: 12px;
`;
interface AddSymptomFormItemProps {
  readonly selected: boolean;
}
export const AddSymptomFormItem = styled.div<AddSymptomFormItemProps>`
  border: ${props =>
    props.selected ? "none" : `1px solid ${Colors.DodgerBlue}`};
  border-radius: 8px;
  background-color: ${props =>
    props.selected ? Colors.DodgerBlue : Colors.AliceBlue};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  position: relative;
`;
export const TickContainer = styled.div`
  position: absolute;
  top: 0;
  background-color: white;
  height: 16px;
  width: 16px;
  right: 20px;
  display: flex;
`;
export const AddSymptomFormButtonContainer = styled.div`
  border-top: ${`1px solid ${Colors.AliceBlue}`};
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
`;
export const AddSymptomFormItemIconContainer = styled.div`
  left: 15px;
`;
export const AddSymptomFormItemTextContainer = styled.div`
  font-weight: bold;
`;

export const CustomBadgeContainer = styled.span`
  top: 22px;
  position: absolute;
  z-index: 10;
  left: -5px;
`;
