import { ArrowLeftOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Checkbox from "antd/lib/checkbox";
import message from "antd/lib/message";
import Radio from "antd/lib/radio";
import Form, { RuleObject } from "antd/lib/form";
import React, { useContext, useRef, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { NephrologyLogo } from "pages/symptoms/surveyForms/nephrologyLogo";
import { SurveyInput } from "pages/symptoms/surveyForms/surveyInput";
import wongBakerFaces from "./wongBakerFaces.png";
import { Platform, UserRoles } from "constants/roles";
import { FormSubmitButton } from "pages/symptoms/surveyForms/FormSubmitButton";
import { FormClearButton } from "pages/symptoms/surveyForms/FormClearButton";

interface AchesAndPainFormProps {
  setShowSurveyForm(show: boolean): void;
  selectedItems: string[];
  goToNextFormOrReturn(): void;
}

export const AchesAndPainForm: React.FC<AchesAndPainFormProps> = ({
  setShowSurveyForm,
  selectedItems,
  goToNextFormOrReturn
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [currentPage, setCurrentPage] = useState(1);
  const [otherTempInput, setOtherTempInput] = useState("");
  const otherInputRef = useRef("");
  const [showSubmit, setShowSubmit] = useState(false);

  const [form] = Form.useForm();

  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const validateWhereIsYourPain = (
    rules: RuleObject,
    value: string,
    callback: (error?: string) => void
  ) => {
    if (
      otherInputRef.current === "" &&
      localStorage.getItem("whereIsYourPain")?.includes("Other")
    ) {
      callback("Please enter value for 'Other' field.");
    } else {
      callback();
    }
  };

  const userRole = sessionStorage.getItem("userRole");

  const {
    isLoading: isLoadingAchesAndPainMutation,
    mutate: submitAchesAndPainForm
  } = useMutation<string>(
    "add-bill",
    async () => {
      return await createApiClient().addAchesAndPainsFormData({
        patient_internal_id:
          userRole === UserRoles.PATIENT && userContext.userData
            ? userContext.userData?.internal_id
            : patientContext?.patientData?.id || "",
        pain: localStorage.getItem("hasAchesOrPains") || "",
        level: localStorage.getItem("levelOfPain") || "0",
        location: localStorage.getItem("whereIsYourPain") || "",
        frequency: localStorage.getItem("frequencyOfPain") || "",
        length: localStorage.getItem("durationOfPain") || "",
        submitted_by: `${userContext.userData?.firstName} ${userContext.userData?.lastName}`
      });
    },
    {
      onSuccess: () => {
        message.success("Form Submitted Successfully");
        goToNextFormOrReturn();
      },
      onError: () => {
        message.error({
          content: (
            <span>
              There was a problem submitting this form, resubmit or{" "}
              <a onClick={() => goToNextFormOrReturn()}>
                click here to continue to the next survey
              </a>
            </span>
          ),
          style: {
            bottom: "10px",
            position: "fixed",
            left: "30%"
          },
          duration: 5
        });
      }
    }
  );

  const onFinish = () => {
    //Values to be passed on to API from session storage
    if (localStorage.getItem("hasAchesOrPains") === "No") {
      //Clear form and session values if no longer pertinent.
      form.resetFields([
        "Level of pain",
        "Where is your pain",
        "Frequency of pain",
        "Duration of pain"
      ]);
      localStorage.setItem("levelOfPain", "");
      localStorage.setItem("whereIsYourPain", "");
      localStorage.setItem("frequencyOfPain", "");
      localStorage.setItem("durationOfPain", "");
      submitAchesAndPainForm();
    } else if (currentPage === 1) {
      setCurrentPage(2);
    } else {
      if (localStorage.getItem("whereIsYourPain")?.includes("Other")) {
        //To handle 'other' input from where is your pain section
        const finalWhereisYourPain =
          localStorage.getItem("whereIsYourPain") + "," + otherInputRef.current;
        localStorage.setItem("whereIsYourPain", finalWhereisYourPain);
      }
      submitAchesAndPainForm();
    }
  };

  const onClear = () => {
    if (currentPage === 1) {
      form.resetFields(["Aches or pains"]);
    } else {
      form.resetFields([
        "Level of pain",
        "Where is your pain",
        "Frequency of pain",
        "Duration of pain"
      ]);
      otherInputRef.current === "";
      setOtherTempInput("");
    }
  };

  const handleFinishFailed = ({ errorFields }: any) => {
    form.scrollToField(errorFields[0].name);
  };

  //Added a scrollToFirstError prop to the form element.
  return (
    <div>
      <div style={{ display: platform === Platform.MOBILE ? "none" : "block" }}>
        <AppButton
          type={ButtonType.Primary}
          onClick={() => setShowSurveyForm(false)}
          buttonContent={
            <>
              <ArrowLeftOutlined /> Back
            </>
          }
        />
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Form
          name="achesAndPain"
          form={form}
          onFinish={onFinish}
          style={{ padding: "0px" }}
          scrollToFirstError
          onFinishFailed={handleFinishFailed}
        >
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
              style={{ borderTop: "8px solid #4284f3" }}
            >
              <PageTitle>Survey - {selectedItems[0]}</PageTitle>
            </AppCard>
            {currentPage === 1 && (
              <>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      fontWeight: "600"
                    }}
                  >
                    Please do not rely upon this survey for immediate medical
                    attention. This survey may not be reviewed by your care team
                    immediately. Please call your doctor for any medical
                    problems. IF YOU THINK THIS IS A MEDICAL EMERGENCY, OR YOUR
                    SYMPTOMS ARE WORRISOME PLEASE CALL 911 OR GO TO THE NEAREST
                    EMERGENCY ROOM.
                  </Space>
                </AppCard>

                {/* <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      fontWeight: "600"
                    }}
                  >
                    <NephrologyLogo />
                  </Space>
                </AppCard> */}
                <Space direction="vertical" size={20}>
                  <AppCard
                    cardHeight="fit-content"
                    cardWidth={
                      platform === Platform.MOBILE
                        ? "calc(100vw - 60px)"
                        : "640px"
                    }
                  >
                    <Space
                      direction="vertical"
                      size={20}
                      style={{ width: "100%", padding: "10px" }}
                    >
                      <CardTitle>
                        Have you had aches or pains, other than chest pain, in
                        the last month?
                        <AsteriskStyle> *</AsteriskStyle>
                      </CardTitle>
                      <FormItemStyle
                        name="Aches or pains"
                        label=""
                        rules={[
                          {
                            required: true
                          }
                        ]}
                        initialValue={""}
                      >
                        <Radio.Group
                          value={localStorage.getItem("hasAchesOrPains")}
                          onChange={e => {
                            if (e.target.value === "No") {
                              setShowSubmit(true);
                            } else {
                              setShowSubmit(false);
                            }
                            localStorage.setItem(
                              "hasAchesOrPains",
                              e.target.value
                            );
                          }}
                        >
                          <Space direction="vertical">
                            <Radio value={"Yes"}>Yes</Radio>
                            <Radio value={"No"}>No</Radio>
                          </Space>
                        </Radio.Group>
                      </FormItemStyle>
                    </Space>
                  </AppCard>
                </Space>
              </>
            )}
            {currentPage === 2 && (
              <Space direction="vertical" size={20}>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <CardTitle>
                      Indicate level of pain
                      <AsteriskStyle> *</AsteriskStyle>
                    </CardTitle>
                    <FormItemStyle
                      name="Level of pain"
                      label=""
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      initialValue={""}
                    >
                      <Radio.Group
                        value={localStorage.getItem("levelOfPain")}
                        onChange={e =>
                          localStorage.setItem("levelOfPain", e.target.value)
                        }
                      >
                        <Row
                          style={{
                            width: "calc(100% + 20px)",
                            paddingLeft:
                              platform === Platform.MOBILE ? "8px" : "25px"
                          }}
                        >
                          <Col>
                            <Radio value={"1"}>1</Radio>
                            <Radio value={"2"}>2</Radio>
                            <Radio value={"3"}>3</Radio>
                            <Radio value={"4"}>4</Radio>
                            <Radio value={"5"}>5</Radio>
                          </Col>
                          {platform === Platform.MOBILE ? (
                            <Col span={24}>
                              <Radio value={"6"}>6</Radio>
                              <Radio value={"7"}>7</Radio>
                              <Radio value={"8"}>8</Radio>
                              <Radio value={"9"}>9</Radio>
                              <Radio value={"10"}>10</Radio>
                            </Col>
                          ) : (
                            <>
                              <Radio value={"6"}>6</Radio>
                              <Radio value={"7"}>7</Radio>
                              <Radio value={"8"}>8</Radio>
                              <Radio value={"9"}>9</Radio>
                              <Radio value={"10"}>10</Radio>
                            </>
                          )}
                        </Row>
                      </Radio.Group>
                    </FormItemStyle>
                  </Space>
                </AppCard>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <img
                      src={wongBakerFaces}
                      height={platform === Platform.MOBILE ? "100px" : "225px"}
                    />
                  </Space>
                </AppCard>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <CardTitle>
                      Where is your pain?
                      <AsteriskStyle> *</AsteriskStyle>
                    </CardTitle>
                    <FormItemStyle
                      name="Where is your pain"
                      label=""
                      rules={[
                        {
                          required: true
                        },
                        {
                          validator: validateWhereIsYourPain,
                          validateTrigger: "submit"
                        }
                      ]}
                      initialValue={""}
                    >
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={e =>
                          localStorage.setItem("whereIsYourPain", e.toString())
                        }
                        value={sessionStorage
                          .getItem("whereIsYourPain")
                          ?.split(/,/)}
                      >
                        <Row gutter={[10, 10]}>
                          <Col span={24}>
                            <Checkbox value="Joints">Joints</Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value="Back">Back</Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value="Head">Head</Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value="Abdomen">Abdomen</Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value="Legs/Feet">Legs/Feet</Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value="Other">Other</Checkbox>
                            <SurveyInput
                              value={otherTempInput ?? otherInputRef}
                              placeholder={""}
                              onChange={e => {
                                setOtherTempInput(e.target.value);
                                otherInputRef.current = e.target.value;
                              }}
                              style={{ width: "70%", marginLeft: "30px" }}
                            />
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </FormItemStyle>
                  </Space>
                </AppCard>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <CardTitle>
                      How often have you had the pain in the last one month?
                      <AsteriskStyle> *</AsteriskStyle>
                    </CardTitle>
                    <FormItemStyle
                      name="Frequency of pain"
                      label=""
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      initialValue={""}
                    >
                      <Radio.Group
                        value={localStorage.getItem("frequencyOfPain")}
                        onChange={e =>
                          localStorage.setItem(
                            "frequencyOfPain",
                            e.target.value
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Radio value={"Rarely (1 - 3 times)"}>
                            Rarely (1 - 3 times)
                          </Radio>
                          <Radio value={"Occasionally (4 - 7 times)"}>
                            Occasionally (4 - 7 times)
                          </Radio>
                          <Radio value={"Frequently (8 - 15 times)"}>
                            Frequently (8 - 15 times)
                          </Radio>
                          <Radio value={"Nearly everyday"}>
                            Nearly everyday
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </FormItemStyle>
                  </Space>
                </AppCard>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <CardTitle>
                      How long did the pain last? (List longest episode)
                      <AsteriskStyle> *</AsteriskStyle>
                    </CardTitle>
                    <FormItemStyle
                      name="Duration of pain"
                      label=""
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      initialValue={""}
                    >
                      <Radio.Group
                        value={localStorage.getItem("durationOfPain")}
                        onChange={e =>
                          localStorage.setItem("durationOfPain", e.target.value)
                        }
                      >
                        <Space direction="vertical">
                          <Radio value={"Less than 5 minutes"}>
                            Less than 5 minutes
                          </Radio>
                          <Radio value={"5 - 30 minutes"}>5 - 30 minutes</Radio>
                          <Radio value={"Several hours"}>Several hours</Radio>
                          <Radio value={"Several days"}>Several days</Radio>
                        </Space>
                      </Radio.Group>
                    </FormItemStyle>
                  </Space>
                </AppCard>
              </Space>
            )}
            <Row>
              <Col span={5}>
                <Form.Item>
                  <FormClearButton onClear={onClear} />
                </Form.Item>
              </Col>
              <Col span={19} style={{ textAlign: "right" }}>
                <Form.Item>
                  <Space>
                    {currentPage !== 1 && (
                      <AppButton
                        type={ButtonType.Primary}
                        buttonContent={"Back"}
                        onClick={() => setCurrentPage(1)}
                        style={{ width: "90px" }}
                      />
                    )}
                    <FormSubmitButton
                      type={ButtonType.Primary}
                      buttonContent={
                        currentPage !== 1 || showSubmit ? "Submit" : "Next"
                      }
                      htmlType="submit"
                      loading={isLoadingAchesAndPainMutation}
                    />
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Form>
      </div>
    </div>
  );
};

const PageTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
