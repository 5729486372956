import React from "react";
import { MENU_ITEM_ICON_DISABLED_COLOR } from "components/sideBar/sideBar";

interface IconProps {
  readonly isDisabled?: boolean;
}

export const MedicalDataCareGuidelinesIcon: React.FC<IconProps> = ({
  isDisabled
}) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="LeftPannel-Patient-0"
          transform="translate(-13.000000, -249.000000)"
        >
          <g
            id="Care-Guideline-menu-Copy-37"
            transform="translate(14.000000, 250.000000)"
          >
            <g
              id="care-guideline-icon"
              transform="translate(7.000000, 5.000000)"
            >
              <g id="Rectangle-Copy-213">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill={isDisabled ? MENU_ITEM_ICON_DISABLED_COLOR : "#7CBA5B"}
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <path
                d="M10.35,11.2 L11.4,11.2 L11.4,12.25 C11.4,12.3328427 11.4671573,12.4 11.55,12.4 L12.45,12.4 C12.5328427,12.4 12.6,12.3328427 12.6,12.25 L12.6,11.2 L13.65,11.2 C13.7328427,11.2 13.8,11.1328427 13.8,11.05 L13.8,10.15 C13.8,10.0671573 13.7328427,10 13.65,10 L12.6,10 L12.6,8.95 C12.6,8.86715729 12.5328427,8.8 12.45,8.8 L11.55,8.8 C11.4671573,8.8 11.4,8.86715729 11.4,8.95 L11.4,10 L10.35,10 C10.2671573,10 10.2,10.0671573 10.2,10.15 L10.2,11.05 C10.2,11.1328427 10.2671573,11.2 10.35,11.2 Z M16.8,7.9 C16.8,7.40294373 16.3970563,7 15.9,7 L8.1,7 C7.60294373,7 7.2,7.40294373 7.2,7.9 L7.2,14.2 L16.8,14.2 L16.8,7.9 Z M15.6,13 L8.4,13 L8.4,8.2 L15.6,8.2 L15.6,13 Z M17.7,14.8 L13.15425,14.8 C13.15684,14.9622764 13.0925816,15.1184768 12.9765507,15.2319544 C12.8605199,15.345432 12.7029286,15.4061995 12.54075,15.4 L11.4,15.4 C11.0709131,15.388903 10.804567,15.1287358 10.78575,14.8 L6.3,14.8 C6.13431458,14.8 6,14.9343146 6,15.1 L6,15.4 C6,16.0627417 6.5372583,16.6 7.2,16.6 L16.8,16.6 C17.4627417,16.6 18,16.0627417 18,15.4 L18,15.1 C18,14.9343146 17.8656854,14.8 17.7,14.8 Z"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
