import React, { ReactNode, useEffect, useState } from "react";
import Menu from "antd/lib/menu";
import styled from "styled-components";
import { Colors } from "helpers/colors";
import { NotificationAlertLevels } from "contextApis/notificationsContext";
import { CircleIndicator } from "components/circleIndicator";

export interface SideBarMenuItems {
  readonly name: string;
  readonly icon: ReactNode;
  readonly route: string;
  alertLevel?: string;
}

interface MenuItemStyleProps {
  readonly menuItemBackgroundColor: string;
  readonly menuItemColor: string;
}

interface SideBarProps extends MenuItemStyleProps {
  readonly menuWidth?: string;
  readonly menuBackgroundColor?: string;
  readonly menuBorderRadius?: string;
  readonly menuPadding?: string;
  readonly menuMargin?: string;
  readonly menuItems: SideBarMenuItems[];
  readonly selectedKey: string;
  onSelect(selectedKey: string): void;
  readonly isMenuDisabled?: boolean;
}

export const DISABLED_MENU_ITEM_BACKGROUND_COLOR = "#ced1d6";

export const DISABLED_MENU_BACKGROUND_COLOR = "#f1f1f1";

export const DISABLED_MENU_COLOR = "#ffffff";

export const SideBarMenu: React.FC<SideBarProps> = ({
  menuWidth,
  menuBackgroundColor,
  menuBorderRadius,
  menuPadding,
  menuMargin,
  menuItemBackgroundColor,
  menuItemColor,
  menuItems,
  selectedKey,
  isMenuDisabled,
  onSelect
}) => {
  const [localMenuItems, setLocalMenuItems] = useState(menuItems);

  useEffect(() => {
    setLocalMenuItems(menuItems);
  }, [menuItems]);

  return (
    <StyledMenu
      menuitembackgroundcolor={
        isMenuDisabled
          ? DISABLED_MENU_ITEM_BACKGROUND_COLOR
          : menuItemBackgroundColor
      }
      menuitemcolor={isMenuDisabled ? DISABLED_MENU_COLOR : menuItemColor}
      style={{
        backgroundColor: isMenuDisabled
          ? DISABLED_MENU_BACKGROUND_COLOR
          : menuBackgroundColor,
        width: menuWidth,
        borderRadius: menuBorderRadius,
        padding: menuPadding,
        margin: menuMargin
      }}
      mode="inline"
      selectedKeys={[selectedKey]}
      onSelect={(event: any) => {
        onSelect(event.key);
      }}
    >
      {localMenuItems.map(item => {
        return (
          <Menu.Item key={item.route} icon={item.icon}>
            {item.name}{" "}
            {item.alertLevel === NotificationAlertLevels.HIGH && (
              <span
                style={{
                  position: "absolute",
                  right: 10,
                  top: 14
                }}
              >
                <CircleIndicator
                  outerColor={"#EEB5AD"}
                  innerColor={Colors.Red}
                />
              </span>
            )}
            {item.alertLevel === NotificationAlertLevels.MODERATE && (
              <span
                style={{
                  position: "absolute",
                  right: 10,
                  top: 14
                }}
              >
                <CircleIndicator
                  outerColor={"#fbddb7"}
                  innerColor={"#f18e13"}
                />
              </span>
            )}
          </Menu.Item>
        );
      })}
    </StyledMenu>
  );
};

const StyledMenu = styled(({ ...props }) => <Menu {...props} />)`
  .ant-menu-item::after {
    border-right: 3px solid ${props => props.menuitembackgroundcolor};
    border-radius: 0 21px 21px 0;
  }

  .ant-menu-item-selected {
    height: 36px;
    width: 204px;
    border-radius: 0 21px 21px 0;
    background-color: ${props => props.menuitembackgroundcolor} !important;
    color: ${props => props.menuitemcolor};
    font-weight: bold;
    ::after {
      color: ${props => props.menuitembackgroundcolor};
      background-color: ${props => props.menuitembackgroundcolor} !important;
      border-radius: 0 21px 21px 0;
    }
    :active {
      background-color: ${props => props.menuitembackgroundcolor} !important;
      border-radius: 0 21px 21px 0;
    }
    :hover {
      background-color: ${props => props.menuitembackgroundcolor} !important;
      border-radius: 0 21px 21px 0;
      color: ${Colors.White};
    }
  }

  .ant-menu-item {
    font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif !important;
    font-size: 14px;
    height: 36px;
    width: 204px;
    border-radius: 0 21px 21px 0;
    :hover {
      background-color: ${props => props.menuitembackgroundcolor} !important;
      border-radius: 0 21px 21px 0;
      color: ${Colors.White};
      font-weight: bold;
    }
  }
`;
