import React, { useState } from "react";
import Space from "antd/lib/space";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import moment from "moment";
import styled from "styled-components";

import { AppCard } from "components/card";
import { AppDatePicker } from "components/inputs";
import { Colors } from "helpers/colors";
import { SurveyNumberInput } from "pages/symptoms/surveyForms/surveyNumberInput";
import { Platform } from "constants/roles";
import { dateToUTC, disableFutureDate } from "helpers/utils";

interface HeartRateSectionProps {
  readonly currentPage: number;
}

export const HeartRateSection: React.FC<HeartRateSectionProps> = ({
  currentPage
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [heartRate, setHeartRate] = useState(
    localStorage.getItem(`reportHeartRate`) || ""
  );

  const checkHeartRate = (_: any, value: number) => {
    if (value) {
      if (value <= 150 && value >= 40) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          "Reading appears out of range. Please recheck and, if confirmed, call your doctor or go to ER immediately, especially if you have any symptoms."
        )
      );
    }
    return Promise.reject(new Error("This field is required"));
  };
  return (
    <>
      {currentPage === 6 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Heart Rate</DisclaimerTitle>
              PLEASE CALL YOUR DOCTOR OR CALL 911 AND GO TO NEAREST ER FOR ANY
              SERIOUS CHANGE IN YOUR VITAL SIGNS OUTSIDE YOUR USUAL RECOMMENDED
              RANGE, OR IF YOU HAVE ANY ASSOCIATED SYMPTOMS (such as: Chest
              Pain, Shortness of Breath, Palpitations, Weakness, Dizzyness,
              Altered Vision, Confusion or other neurologic symptoms)
            </Space>
          </AppCard>
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{ width: "100%", padding: "10px" }}
              >
                <CardTitle>
                  Do you want to report your heart rate?
                  <AsteriskStyle> *</AsteriskStyle>
                </CardTitle>
                <FormItemStyle
                  name="Report your heart rate"
                  label=""
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  initialValue={""}
                >
                  <Radio.Group
                    value={localStorage.getItem("reportYourHeartRate")}
                    onChange={e =>
                      localStorage.setItem(
                        "reportYourHeartRate",
                        e.target.value
                      )
                    }
                  >
                    <Space direction="vertical">
                      <Radio value={"Yes"}>Yes</Radio>
                      <Radio value={"No"}>No</Radio>
                    </Space>
                  </Radio.Group>
                </FormItemStyle>
              </Space>
            </AppCard>
          </Space>
        </Space>
      )}
      {currentPage === 7 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Heart Rate</DisclaimerTitle>
              <CardTitle>
                When did you take your heart rate?
                <AsteriskStyle> *</AsteriskStyle>
              </CardTitle>
              <FormItemStyle
                name="When did you take your heart rate"
                label=""
                rules={[
                  {
                    required: true
                  }
                ]}
                initialValue={""}
              >
                <Radio.Group
                  value={localStorage.getItem("whenWasHeartRateTaken")}
                  onChange={e =>
                    localStorage.setItem(
                      "whenWasHeartRateTaken",
                      e.target.value
                    )
                  }
                >
                  <Space direction="vertical">
                    <Radio value={"Taken today"}>Taken today</Radio>
                    <Radio value={"Taken before today"}>
                      Taken before today
                    </Radio>
                  </Space>
                </Radio.Group>
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
      {currentPage === 8 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Heart Rate</DisclaimerTitle>
              <CardTitle>
                When was your heart rate taken?
                <AsteriskStyle> *</AsteriskStyle>
              </CardTitle>
              <FormItemStyle
                name="When was your heart rate taken(Date)"
                label=""
                rules={[
                  {
                    required: true
                  }
                ]}
                initialValue={""}
              >
                <AppDatePicker
                  label="Heart Rate Taken on"
                  value={moment(
                    localStorage.getItem("dateYourHeartRateWasTaken")
                  )}
                  onChange={e => {
                    if (e) {
                      const selectedDate = moment(e).toISOString();
                      localStorage.setItem(
                        "dateYourHeartRateWasTaken",
                        selectedDate
                      );
                    }
                  }}
                  style={{ width: "250px" }}
                  disabledDate={disableFutureDate}
                />
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
      {currentPage === 9 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Heart Rate</DisclaimerTitle>
              <CardTitle>
                Report heart rate
                <AsteriskStyle> *</AsteriskStyle>
              </CardTitle>
              <FormItemStyle
                name={`Report heart rate`}
                label=""
                rules={[
                  {
                    required: true,
                    validator: checkHeartRate,
                    validateTrigger: "submit"
                  }
                ]}
                initialValue={""}
              >
                <SurveyNumberInput
                  value={heartRate}
                  placeholder="Your Answer"
                  decimalLimit={0}
                  onChange={e => {
                    setHeartRate(e as string);
                    localStorage.setItem(`reportHeartRate`, e as string);
                  }}
                  min={0}
                  style={{ width: "120px" }}
                />
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
    </>
  );
};

const DisclaimerTitle = styled.div`
  width: 100%;
  padding: 8px 8px 8px 20px;
  margin-left: -25px;
  background: ${Colors.DodgerBlue};
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 8px 8px 0px 0px;
  color: white;
  position: absolute;
  top: 0;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
