// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext, useEffect } from "react";
import {
  ControlBarButton,
  Phone,
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup
} from "amazon-chime-sdk-component-library-react";

import { StyledP } from "./Styled";
import { useAWSMeetingState } from "components/meetings/providers/AWSMeetingStateProvider";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { message, Spin } from "antd";

const EndMeetingControl: React.FC = () => {
  const { showLeaveModal, setShowLeaveModal } = useAWSMeetingState();
  const toggleModal = (): void => setShowLeaveModal(!showLeaveModal);
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const meetingID = sessionStorage.getItem("meetingID");

  const { refetch: endMeeting, isRefetching: endMeetingLoading } = useQuery(
    "end-meeting",
    async () => {
      return await createApiClient().endMeeting(meetingID ? meetingID : "");
    },
    {
      enabled: false,
      onSuccess: () => {
        meetingContext.closeSession();
      },
      onError: () => {
        message.error("There was a problem ending this meeting.");
        meetingContext.closeSession();
      }
    }
  );

  const leaveMeeting = async (): Promise<void> => {
    window.onbeforeunload = null;
    window.onunload = null;
    endMeeting();
  };

  // const endMeetingForAll = async (): Promise<void> => {
  //   endMeeting();
  // };
  //To be managed on a future date - active meeting should end on unload
  function confirmExit() {
    leaveMeeting();
    return "";
  }

  function clearSession() {
    leaveMeeting();
  }

  useEffect(() => {
    window.onbeforeunload = function () {
      return confirmExit();
    };
    window.onunload = function () {
      return clearSession();
    };
  }, []);

  return (
    <>
      {meetingContext.currentPage === 2 && (
        <ControlBarButton
          icon={<Phone />}
          onClick={toggleModal}
          label="Leave"
        />
      )}
      {showLeaveModal && (
        <Modal
          size="md"
          onClose={toggleModal}
          rootId="modal-root"
          style={{ zIndex: 1004 }}
        >
          <ModalHeader title="End Meeting" />
          <ModalBody>
            <StyledP>
              {/* {userRole === UserRoles.PATIENT
                ? "Are you sure you want to leave the meeting?"
                : "Leave meeting or you can end the meeting for all. The meeting cannot be used once it ends."} */}
              Leave meeting or you can end the meeting for all. The meeting
              cannot be used once it ends
            </StyledP>
          </ModalBody>
          {endMeetingLoading ? (
            <Spin />
          ) : (
            <ModalButtonGroup
              primaryButtons={[
                // userRole !== UserRoles.PATIENT ? (
                //   <ModalButton
                //     key="end-meeting-for-all"
                //     onClick={endMeetingForAll}
                //     variant="primary"
                //     label="End meeting for all"
                //     closesModal
                //   />
                // ) : (
                //   <></>
                // ),
                <ModalButton
                  key="leave-meeting"
                  onClick={leaveMeeting}
                  variant="primary"
                  label="Leave Meeting"
                  closesModal
                />,
                <ModalButton
                  key="cancel-meeting-ending"
                  variant="secondary"
                  label="Cancel"
                  closesModal
                />
              ]}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default EndMeetingControl;
