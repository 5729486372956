import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import CareGemConsentForm from "./Forms/careGemConsentForm";
import styled from "styled-components";
import { AppCard } from "components/card";
import { Alert, Checkbox, Skeleton, Space, Tooltip } from "antd";
import { Button, Modal } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { LoginBackgroundIcon } from "pages/login/loginbackground";
import { AppSelect } from "components/inputs";
import { useLocation, useNavigate } from "react-router";
import { createApiClient } from "apiClient";
import RpmConsentForm from "./Forms/rpmConsentForm";
import { UserContext, UserContextState } from "contextApis/userContext";
import DeviceAssignmentForm from "./Forms/deviceAssignment/deviceAssignmentForm";

interface ConsentPageProps {
  submitFunc: () => void;
  setPrivacyCheck: (value: number) => void;
  privacyCheck: number | undefined;
  consentData: any;
  consentError: any;
  setPdfValue: (value: string) => void;
  PdfValue: string;
  sign: any;
  setSign: (value: boolean) => void;
  consents: any;
  setShowBorder: (value: boolean) => void;
  showBorder: boolean;
  currentRoute: string;
  setSignerFilter: (value: any) => void;
  signerFilter: string;
  setUserInitial: (value: any) => void;
  userInitial: any;
  toggle: boolean;
  setToggle: (value: boolean) => void;
  setIsValidSignature: (value: boolean) => void;
  isValidSignature: boolean;
  errorSignatureText: string;
}

const ConsentForm: React.FC<ConsentPageProps> = ({
  submitFunc,
  setPrivacyCheck,
  privacyCheck,
  consentData,
  consentError,
  setPdfValue,
  PdfValue,
  sign,
  setSign,
  consents,
  setShowBorder,
  showBorder,
  currentRoute,
  setSignerFilter,
  signerFilter,
  setUserInitial,
  userInitial,
  toggle,
  setToggle,
  setIsValidSignature,
  isValidSignature,
  errorSignatureText
}) => {
  const navigate = useNavigate();

  const userContext = useContext<UserContextState>(UserContext);
  const userId = sessionStorage.getItem("userId");

  const [declineLoading, setDeclineLoading] = useState<boolean>(false);
  // const [relation, setRelation] = useState<string | null>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isbrochureOpen, setIsBrochureOpen] = useState<boolean>(false);
  const [isMobileBrochureOpen, setIsMobileBrochureOpen] =
    useState<boolean>(false);
  const [isCaregemPrivacyPolicyOpen, setIsCaregemPrivacyPolicyOpen] =
    useState<boolean>(false);
  const [isOrgPrivacyPolicyOpen, setIsOrgPrivacyPolicyOpen] =
    useState<boolean>(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState<boolean>(false);
  const [isDeclineOpen, setIsDeclineOpen] = useState<boolean>(false);
  const [isCaregemPolicyRead, setCaregemPolicyRead] = useState<boolean>(false);
  const [isOrgPolicyRead, setOrgPolicyRead] = useState<boolean>(false);

  const patientFullName = {
    firstName: consentData?.patient_details?.first_name ?? undefined,
    lastName: consentData?.patient_details?.last_name ?? undefined
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const consentToken = queryParams.get("consent_token") || undefined;

  const SignatureCanvasRef = useRef<SignatureCanvas | null>(null);

  const Clear = () => {
    setPdfValue("");
    SignatureCanvasRef.current?.clear();
  };

  const signerFilterOptions: {
    label: string;
    value: string;
  }[] = [
    {
      label: "Patient",
      value: "Patient"
    },
    {
      label: "Power of Attorney",
      value: "Power of Attorney"
    },
    {
      label: "Guardian",
      value: "Guardian"
    },
    {
      label: "Responsible party",
      value: "Responsible party"
    }
  ];

  useEffect(() => {
    if (isCaregemPolicyRead && isOrgPolicyRead) {
      setPrivacyCheck(1);
    }
  }, [isCaregemPolicyRead, isOrgPolicyRead]);

  const pdfUrls = {
    pdfOne: `${process.env["REACT_APP_ASSET_ENDPOINT"]}policy/CAREGEM-Brochure.pdf`,
    pdfTwo: `${process.env["REACT_APP_ASSET_ENDPOINT"]}policy/policy.pdf`,
    pdfThree: `${
      process.env["REACT_APP_ASSET_ENDPOINT"] +
      consentData?.org_details?.policy_path
    }`
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleBrochure = () => {
    setIsBrochureOpen(!isbrochureOpen);
  };

  const handleMobileBrochure = () => {
    setIsMobileBrochureOpen(!isMobileBrochureOpen);
  };

  const handlePrivacy = () => {
    setIsPrivacyOpen(!isPrivacyOpen);
    setPrivacyCheck(1);
    setShowBorder(false);
  };

  const handleCaregemPolicy = () => {
    setIsCaregemPrivacyPolicyOpen(!isCaregemPrivacyPolicyOpen);
    setCaregemPolicyRead(true);
  };

  const handleOrgPolicy = () => {
    setIsOrgPrivacyPolicyOpen(!isOrgPrivacyPolicyOpen);
    setOrgPolicyRead(true);
  };

  const handleConsentDecline = async () => {
    setIsDeclineOpen(!isDeclineOpen);
  };

  const handleDecline = async () => {
    if (currentRoute === "Login") {
      const consent_name = consents[0].consent_name;

      if (consentData?.rpm_status === "Delivered") {
        try {
          setDeclineLoading(true);
          const response =
            await createApiClient().getDeclineDeviceAcknowledgement(userId);
          if (response) {
            setDeclineLoading(false);
            userContext.onLogout();
            navigate("/");
          }
        } catch (error) {
          setDeclineLoading(false);
          console.log(error);
        }
      } else {
        try {
          setDeclineLoading(true);
          const response = await createApiClient().getDeclinedConsentAfterLogin(
            consent_name
          );
          if (response) {
            setDeclineLoading(false);
            userContext.onLogout();
            navigate("/");
          }
        } catch (error) {
          setDeclineLoading(false);
          console.log(error);
        }
      }
    } else {
      const consent_name = consents[0].consent_name;
      try {
        setDeclineLoading(true);
        const response = await createApiClient().getDeclinedConsent(
          consentToken,
          consent_name
        );
        if (response) {
          setDeclineLoading(false);
          setIsDeclineOpen(!isDeclineOpen);
          navigate("/");
        }
      } catch (error) {
        setDeclineLoading(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setSign(false);
    handleValidation();
  }, [PdfValue]);

  const careGemConsentForm = useMemo(() => {
    if (
      consents &&
      consents[0]?.consent_name === "caregem" &&
      consents[0]?.sign_date === null
    ) {
      return (
        <CareGemConsentForm
          key={consentData?.org_details?.name}
          orgName={consentData?.org_details?.name}
          consentName={consentData?.consents[0]?.consent_name}
          pName={`${patientFullName.firstName} ${patientFullName.lastName}`}
          pDob={consentData.patient_details.dob}
          sign={PdfValue}
          pEmail={consentData.patient_details.email}
          signer={signerFilter}
          clientLogo={consentData.org_details.logo}
          userInitial={userInitial}
        />
      );
    } else {
      return null;
    }
  }, [consents, PdfValue, signerFilter, userInitial]);

  const rpmConsentForm = useMemo(() => {
    if (
      consents &&
      consents[0]?.consent_name === "rpm" &&
      consents[0]?.sign_date === null
    ) {
      return (
        <RpmConsentForm
          key={consentData?.org_details?.name}
          orgName={consentData?.org_details?.name}
          consentName={consentData?.consents[1]?.consent_name}
          pName={`${patientFullName.firstName} ${patientFullName.lastName}`}
          pDob={consentData.patient_details.dob}
          sign={PdfValue}
          pEmail={consentData.patient_details.email}
          signer={signerFilter}
          clientLogo={consentData.org_details.logo}
          deviceId={consentData?.patient_details?.device_id}
          userInitial={userInitial}
        />
      );
    } else {
      return null;
    }
  }, [consents, PdfValue, signerFilter, userInitial]);

  const deviceAssignmentForm = useMemo(() => {
    if (
      consents &&
      consentData?.rpm_status === "Delivered" &&
      consents[0]?.consent_name === "device_acknowledgement" &&
      consents[0]?.sign_date === null
    ) {
      return (
        <DeviceAssignmentForm
          key={consentData?.org_details?.name}
          orgName={consentData?.org_details?.name}
          consentName={consentData?.consents[2]?.consent_name}
          pName={`${patientFullName?.firstName} ${patientFullName?.lastName}`}
          pDob={consentData?.patient_details.dob}
          sign={PdfValue}
          pEmail={consentData?.patient_details?.email}
          signer={signerFilter}
          clientLogo={consentData?.org_details?.logo}
          deviceId={consentData?.patient_details?.device_id}
          userInitial={userInitial}
        />
      );
    }
  }, [consents, PdfValue, signerFilter, userInitial]);

  const toggleSignature = () => {
    setToggle(!toggle);
    setUserInitial("");
    setPdfValue("");
  };

  const isSignatureValid = () => {
    if (SignatureCanvasRef.current) {
      const signatureDataURL = SignatureCanvasRef.current.toDataURL();
      if (signatureDataURL.length < 6000) {
        return false;
      }
      return true;
    }
    return false;
  };

  const handleValidation = () => {
    const isValid = isSignatureValid();
    if (isValid) {
      setIsValidSignature(true);
    } else {
      setIsValidSignature(false);
    }
  };

  return (
    <ConsentBackground>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "grid",
          placeItems: "center",
          overflow: "hidden"
        }}
      >
        <LoginBackgroundIcon />
      </div>
      <AppCard
        cardHeight={"95.5vh"}
        cardWidth={"95vw"}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "scroll",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          paddingBottom: "50px"
        }}
      >
        {consentError ? (
          <Space style={{ alignSelf: "center", marginTop: "40vh" }}>
            <Alert message="Failed to fetch data" type="error" showIcon />
          </Space>
        ) : (
          <Container>
            <Left>
              {!consentData ? (
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                    marginTop: 10
                  }}
                >
                  <Skeleton active paragraph={{ rows: 14 }} />
                </div>
              ) : (
                <>
                  {consents && consents[0]?.consent_name === "caregem" ? (
                    <Description>
                      Please review our Brochure and Privacy Policies by
                      clicking the links below and then proceed to fill the
                      required details for accepting CareGem Consent.
                    </Description>
                  ) : (
                    <>
                      {consentData?.rpm_status !== "Delivered" && (
                        <Description>
                          Please review Remote Patient Monitoring consent and
                          fill the required details before accepting.
                        </Description>
                      )}
                    </>
                  )}

                  {consents && consents[0]?.consent_name === "caregem" && (
                    <LinkContainer>
                      <LargeScreenLinks>
                        <BrochureLink onClick={handleBrochure}>
                          • CareGem Brochure
                        </BrochureLink>
                        <div
                          style={{
                            position: "relative"
                          }}
                        >
                          <PrivacyPoliciesLink
                            onClick={handlePrivacy}
                            style={{
                              border: showBorder ? "1px solid red" : "none",
                              borderRadius: 8,
                              padding: "2px 8px 2px 4px"
                            }}
                          >
                            • Privacy Policies{" "}
                          </PrivacyPoliciesLink>
                          {privacyCheck === 1 ? (
                            <Checkbox
                              checked
                              style={{
                                boxSizing: "border-box",
                                position: "absolute",
                                marginLeft: "120px",
                                marginTop: "-24px"
                              }}
                            />
                          ) : (
                            <Checkbox
                              disabled
                              style={{
                                boxSizing: "border-box",
                                position: "absolute",
                                marginLeft: "120px",
                                marginTop: "-24px"
                              }}
                            />
                          )}
                        </div>
                      </LargeScreenLinks>
                      <SmallScreenLinks>
                        <BrochureLink onClick={handleMobileBrochure}>
                          • CareGem Brochure
                        </BrochureLink>
                        <CaregemPolicyLink onClick={handleCaregemPolicy}>
                          • CareGem Privacy Policy
                        </CaregemPolicyLink>
                        <OrgPolicyLink onClick={handleOrgPolicy}>
                          • {consentData?.org_details?.name} Privacy Policy
                        </OrgPolicyLink>
                        <div
                          style={{
                            position: "relative"
                          }}
                        >
                          {privacyCheck === 1 ? (
                            <Checkbox
                              checked
                              style={{
                                boxSizing: "border-box",
                                marginLeft: "6px"
                              }}
                            />
                          ) : (
                            <Checkbox
                              disabled
                              style={{
                                boxSizing: "border-box",
                                marginLeft: "6px"
                              }}
                            />
                          )}
                        </div>
                      </SmallScreenLinks>
                    </LinkContainer>
                  )}
                  <Form>
                    {consentData?.rpm_status !== "Delivered" && (
                      <AppSelect
                        value={signerFilter}
                        label="Select Signer"
                        onChange={type => {
                          setSignerFilter(type);
                        }}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginTop: "20px"
                        }}
                        options={signerFilterOptions}
                        dropdownStyle={{ borderRadius: "8px" }}
                      />
                    )}
                    <div>
                      <TextToSignSwitch onClick={toggleSignature}>
                        {!toggle ? (
                          <Tooltip
                            placement="left"
                            title={
                              "Click here if you would like to type your name in the signature box."
                            }
                          >
                            <div>I want to type my name.</div>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            placement="left"
                            title={
                              "Click here if you would like to draw your signature in the signature box."
                            }
                          >
                            <div>I want to sign.</div>
                          </Tooltip>
                        )}
                      </TextToSignSwitch>
                    </div>
                    {!toggle ? (
                      <>
                        <div
                          className="signature-container"
                          style={{
                            borderColor: sign ? "red" : ""
                          }}
                        >
                          <div
                            style={{
                              marginLeft: "10px",
                              marginTop: "5px",
                              fontSize: 10,
                              fontWeight: 100,
                              color: "#979dab",
                              width: "96%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            {sign ? (
                              <span style={{ color: "red" }}>
                                {errorSignatureText}
                              </span>
                            ) : (
                              <span>Please draw your signature below.</span>
                            )}

                            {PdfValue ? (
                              <DeleteFilled
                                style={{
                                  color: "red",
                                  fontSize: "16px",
                                  zIndex: 9999
                                }}
                                onClick={Clear}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          <SignatureCanvas
                            ref={SignatureCanvasRef}
                            penColor="#1890ff"
                            canvasProps={{
                              className: "sigCanvas"
                            }}
                            onEnd={() =>
                              setPdfValue(
                                SignatureCanvasRef.current!.getTrimmedCanvas().toDataURL()
                              )
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="signature-container-text"
                          style={{
                            borderColor: sign ? "red" : ""
                          }}
                        >
                          <div
                            style={{
                              marginLeft: "10px",
                              marginTop: "5px",
                              fontSize: 10,
                              fontWeight: 100,
                              color: "#979dab",
                              width: "96%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            {sign ? (
                              <span style={{ color: "red" }}>
                                Signature is required.
                              </span>
                            ) : (
                              <span>Please write your name below.</span>
                            )}
                          </div>
                          <input
                            placeholder="Type..."
                            maxLength={30}
                            value={userInitial}
                            onChange={e => {
                              setUserInitial(e.target.value);
                            }}
                            style={{
                              width: "100%",
                              marginTop: 50,
                              height: 50,
                              outline: "none",
                              border: "none",
                              fontSize: 20,
                              textAlign: "center"
                            }}
                          />
                        </div>
                      </>
                    )}
                  </Form>
                  <BottomButtonContainer>
                    <LargeScreenButton>
                      <Button
                        type="primary"
                        danger
                        style={{
                          width: "49%",
                          borderRadius: 10,
                          margin: "0px 5px 0px 5px"
                        }}
                        onClick={handleConsentDecline}
                      >
                        {consents &&
                        consents[0]?.consent_name === "device_acknowledgement"
                          ? "Not Recived"
                          : "Decline"}
                      </Button>

                      <Button
                        type="primary"
                        style={{
                          width: "49%",
                          borderRadius: 10,
                          margin: "0px 5px 0px 5px"
                        }}
                        onClick={submitFunc}
                      >
                        {consents &&
                        consents[0]?.consent_name === "device_acknowledgement"
                          ? "Recived"
                          : "Accept"}
                      </Button>
                    </LargeScreenButton>
                    <SmallScreenButton>
                      <Button
                        type="primary"
                        ghost
                        style={{
                          width: "49%",
                          borderRadius: 10,
                          margin: "0px 5px 0px 5px"
                        }}
                        onClick={showModal}
                      >
                        Preview Consent
                      </Button>
                    </SmallScreenButton>
                  </BottomButtonContainer>
                </>
              )}
            </Left>
            <Right>
              {!consentData ? (
                <div
                  style={{
                    width: "100%",
                    marginTop: 10
                  }}
                >
                  <Skeleton active paragraph={{ rows: 14 }} />
                </div>
              ) : (
                <>
                  {careGemConsentForm}
                  {rpmConsentForm}
                  {consentData?.rpm_status === "Delivered" &&
                    deviceAssignmentForm}
                </>
              )}
            </Right>
          </Container>
        )}
      </AppCard>
      <Modal
        title="Consent Form"
        open={isModalOpen}
        style={{ top: 10, display: "grid", placeItems: "center" }}
        onCancel={handleCancel}
        footer={[
          <Button key="Decline" onClick={handleCancel} type="primary" danger>
            Decline
          </Button>,
          <Button key="Accept" onClick={handleOk} type="primary">
            Accept
          </Button>
        ]}
        width={"90%"}
      >
        {consentData ? (
          <div
            style={{
              width: "100%",
              overflow: "scroll"
            }}
          >
            {careGemConsentForm}
          </div>
        ) : (
          <>Please try to reload this page!</>
        )}
      </Modal>
      {/* Brochure Modal */}
      <Modal
        title="CareGem Brochure"
        open={isbrochureOpen}
        style={{ top: 10, display: "grid", placeItems: "center" }}
        onCancel={handleBrochure}
        width={"90%"}
        footer={null}
      >
        <iframe
          src={`${
            process.env["REACT_APP_ASSET_ENDPOINT"] +
            "policy/CAREGEM-Brochure.pdf#zoom=65&toolbar=0"
          }`}
          height={480}
          width={555}
          style={{ borderStyle: "none" }}
        />
      </Modal>
      {/* Mobie Brochure Modal */}
      <Modal
        title="CareGem Brochure"
        open={isMobileBrochureOpen}
        style={{
          top: 10,
          display: "grid",
          placeItems: "center",
          height: "100%"
        }}
        onCancel={handleMobileBrochure}
        width={"90%"}
        footer={null}
      >
        <PdfIframe pdfUrl={pdfUrls.pdfOne} />
      </Modal>
      {/* Caregem Privacy Policy Modal - Mobile Browser */}
      <Modal
        title="Caregem Privacy Policy"
        open={isCaregemPrivacyPolicyOpen}
        style={{
          top: 10,
          display: "grid",
          placeItems: "center",
          height: "90%"
        }}
        // style={{ top: 10, maxWidth: "auto", display: "flex" }}
        onCancel={handleCaregemPolicy}
        width={"90%"}
        footer={null}
      >
        <PdfIframe pdfUrl={pdfUrls.pdfTwo} />
      </Modal>
      {/* Org Privacy Policy Modal - Mobile Browser */}
      <Modal
        title={consentData?.org_details?.name + " Privacy Policy"}
        open={isOrgPrivacyPolicyOpen}
        style={{
          top: 10,
          display: "grid",
          placeItems: "center",
          height: "90%"
        }}
        onCancel={handleOrgPolicy}
        width={"90%"}
        footer={null}
      >
        <PdfIframe pdfUrl={pdfUrls.pdfThree} />
      </Modal>
      {/* Privacy Policies Modal */}
      <Modal
        title="Privacy Policies"
        open={isPrivacyOpen}
        style={{ top: 10, display: "grid", placeItems: "center" }}
        onCancel={handlePrivacy}
        width={"100%"}
        footer={null}
      >
        <PrivacyPolicyContainer>
          <iframe
            src={`${
              process.env["REACT_APP_ASSET_ENDPOINT"] +
              "policy/policy.pdf#zoom=62"
            }`}
            height={480}
            width={555}
            style={{ borderStyle: "none" }}
          />
          <iframe
            src={`${
              process.env["REACT_APP_ASSET_ENDPOINT"] +
              consentData?.org_details?.policy_path +
              "#zoom=96"
            }`}
            height={480}
            width={555}
            style={{ borderStyle: "none" }}
          />
        </PrivacyPolicyContainer>
      </Modal>
      <Modal
        closeIcon
        open={isDeclineOpen}
        style={{
          alignSelf: "center",
          top: "30%"
        }}
        width={"30%"}
        onCancel={handleConsentDecline}
        footer={[
          <Button key="No" type="primary" danger onClick={handleConsentDecline}>
            No
          </Button>,
          <Space key="Yes" style={{ marginLeft: 10 }}>
            {!declineLoading ? (
              <Button type="primary" onClick={handleDecline}>
                Yes
              </Button>
            ) : (
              <Button type="primary" loading>
                Yes
              </Button>
            )}
          </Space>
        ]}
      >
        <span style={{ fontWeight: "bold", marginTop: 20 }}>
          {patientFullName.firstName + " " + patientFullName.lastName}
        </span>{" "}
        are you sure you want to decline?
      </Modal>
    </ConsentBackground>
  );
};

const PdfIframe: React.FC<{ pdfUrl: string }> = ({ pdfUrl }) => {
  const [iframeTimeoutId, setIframeTimeoutId] = useState<
    NodeJS.Timeout | undefined
  >(undefined);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const intervalId = setInterval(updateIframeSrc, 1000 * 3);
    setIframeTimeoutId(intervalId);

    return () => clearInterval(intervalId);
  }, []);

  function iframeLoaded() {
    clearInterval(iframeTimeoutId);
  }

  function getIframeLink() {
    return `https://docs.google.com/gview?url=${pdfUrl}&embedded=true`;
  }

  function updateIframeSrc() {
    if (iframeRef.current) {
      iframeRef.current.src = getIframeLink();
    }
  }

  return (
    <iframe
      onLoad={iframeLoaded}
      onError={updateIframeSrc}
      ref={iframeRef}
      src={getIframeLink()}
      height={490}
      width="100%"
      style={{ borderStyle: "none", minHeight: "400px" }}
    />
  );
};

const ConsentBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(173.57deg, #06bfe3 0%, #208cff 100%);
  box-shadow: 0 3px 8px 0 rgba(6, 157, 222, 0.5);
`;

const BottomButtonContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Container = styled.div`
  width: 90vw;
  height: 85vh;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  @media (max-width: 960px) {
    flex-wrap: wrap;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Left = styled.div`
  width: 40%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    height: 100%;
  }
`;

const Right = styled.div`
  width: 60%;
  height: 100%;
  overflow-x: hidden;
  display: grid;
  margin-right: 40px;
  place-items: center;
  @media (max-width: 960px) {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 36px;
`;

const Description = styled.div`
  font-size: 14px;
  color: #7c7c7c;
`;

const LargeScreenButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 960px) {
    display: none;
  }
`;
const SmallScreenButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 960px) {
    display: none;
  }
`;

const LargeScreenLinks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 960px) {
    display: none;
  }
`;

const SmallScreenLinks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 960px) {
    display: none;
  }
`;

const Form = styled.div``;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 12px;
`;

const BrochureLink = styled.div`
  &:hover {
    color: #1890ff;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;

const CaregemPolicyLink = styled.div`
  &:hover {
    color: #1890ff;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;

const OrgPolicyLink = styled.div`
  margin-left: 4px;

  &:hover {
    color: #1890ff;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;

const PrivacyPoliciesLink = styled.div`
  &:hover {
    color: #1890ff;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const PrivacyPolicyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
`;

const TextToSignSwitch = styled.div`
  &:hover {
    color: #1890ff;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
  gap: 10px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 400;
  color: #1890ff;
`;

export default ConsentForm;
