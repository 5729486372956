import React from "react";

export const TablePersonIcon: React.FC = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15_2)">
        <mask
          id="mask0_15_2"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="-14"
          y="-14"
          width="44"
          height="44"
        >
          <path d="M30 -14H-14V30H30V-14Z" fill="white" />
        </mask>
        <g mask="url(#mask0_15_2)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.012 8.99551C8.98752 8.99551 10.075 9.14743 11.2744 9.45128C12.6177 9.78711 13.7052 10.2349 14.5367 10.7946C15.5283 11.4663 16.024 12.2099 16.024 13.0255V16.024H0V13.0255C0 12.2099 0.495748 11.4663 1.48726 10.7946C2.31885 10.2349 3.40629 9.78711 4.74963 9.45128C5.94904 9.14743 7.03648 8.99551 8.012 8.99551ZM8.012 0C8.73164 0 9.39531 0.179908 10.003 0.53973C10.6107 0.899552 11.0945 1.3913 11.4543 2.01499C11.8141 2.63869 11.994 3.31034 11.994 4.02999C11.994 4.74963 11.8141 5.41329 11.4543 6.02099C11.0945 6.62869 10.6107 7.11245 10.003 7.47227C9.39531 7.83209 8.73164 8.012 8.012 8.012C7.29235 8.012 6.62869 7.83209 6.02099 7.47227C5.41329 7.11245 4.92954 6.62869 4.56972 6.02099C4.2099 5.41329 4.02999 4.74963 4.02999 4.02999C4.02999 3.31034 4.2099 2.63869 4.56972 2.01499C4.92954 1.3913 5.41329 0.899552 6.02099 0.53973C6.62869 0.179908 7.29235 0 8.012 0ZM8.012 10.9146C7.10045 10.9146 6.13294 11.0505 5.10945 11.3223C4.22988 11.5622 3.47827 11.8501 2.85457 12.1859C2.23088 12.5218 1.91904 12.8016 1.91904 13.0255V14.105H14.105V13.0255C14.105 12.8016 13.7931 12.5218 13.1694 12.1859C12.5457 11.8501 11.7941 11.5622 10.9146 11.3223C9.89106 11.0505 8.92355 10.9146 8.012 10.9146ZM8.012 1.91904C7.42029 1.91904 6.92055 2.12294 6.51275 2.53074C6.10495 2.93854 5.90105 3.43828 5.90105 4.02999C5.90105 4.62169 6.10495 5.11344 6.51275 5.50525C6.92055 5.89706 7.42029 6.09296 8.012 6.09296C8.60371 6.09296 9.10345 5.89706 9.51125 5.50525C9.91905 5.11344 10.1229 4.62169 10.1229 4.02999C10.1229 3.43828 9.91905 2.93854 9.51125 2.53074C9.10345 2.12294 8.60371 1.91904 8.012 1.91904Z"
            fill="#979DAB"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_15_2">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
