import React from "react";
import Divider from "antd/lib/divider";
import { camelCase, get, startCase } from "lodash";
import moment from "moment";
import styled from "styled-components";
import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { ActiveMedicineData } from "sharedTypes";
import { MedicationText } from "./AddMedicationForm";

interface MedicationDetailsProps {
  readonly selectedMedication: ActiveMedicineData | null;
}

export const MedicationDetails: React.FC<MedicationDetailsProps> = ({
  selectedMedication
}) => {
  return (
    <AppCard cardHeight="100%" cardWidth="100%">
      <MedicationText>
        <b>{`${get(selectedMedication, "ProductLongName", "")} ${get(
          selectedMedication,
          "UnitStrength",
          ""
        )}`}</b>
      </MedicationText>
      <Divider style={{ margin: "15px 0" }} />
      <GridContainer>
        <GridItem borderRight>
          <div>Dose</div>
          <div>
            <DefaultGridText>
              {get(selectedMedication, "Quantity", "")}
            </DefaultGridText>
          </div>
        </GridItem>
        <GridItem borderRight>
          <div>Unit</div>
          <div>
            <DefaultGridText>
              {camelCase(get(selectedMedication, "UnitCode", ""))}
            </DefaultGridText>
          </div>
        </GridItem>
        <GridItem>
          <div>Sig</div>
          <div>
            <SigText>{get(selectedMedication, "Sig", "")}</SigText>
            <DefaultGridText normalText>
              {get(selectedMedication, "Prn", "") === "Y" ? "PRN" : ""}
            </DefaultGridText>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem>
          <div>Sig Details</div>
          <div>
            <DefaultGridText normalText>
              {get(selectedMedication, "SigExtraNote", "")}
            </DefaultGridText>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem>
          <div>Duration</div>
          <div>
            <DefaultGridText>
              {startCase(get(selectedMedication, "Duration", ""))}
            </DefaultGridText>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem>
          <div>Reasons Prescribed</div>
          <div>
            <DefaultGridText normalText>
              {get(selectedMedication, "MedicationReasons", []).join(", ")}
            </DefaultGridText>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem>
          <div>Info From</div>
          <div>
            <DefaultGridText normalText>
              {get(selectedMedication, "InfoFrom", "")}
            </DefaultGridText>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem borderRight>
          <div>Entered On</div>
          <div>
            <DefaultGridText>
              {get(selectedMedication, "CreatedDate", "")
                ? moment(
                    get(selectedMedication, "CreatedDate", ""),
                    "YYYY-MM-DD"
                  ).format("MMM DD YYYY")
                : ""}
            </DefaultGridText>
          </div>
        </GridItem>
        <GridItem>
          <div>Entered By</div>
          <div>
            <DefaultGridText>
              {get(selectedMedication, "CreatedBy", "")}
            </DefaultGridText>
          </div>
        </GridItem>
      </GridContainer>
    </AppCard>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 270px 270px 270px;
  padding-left: 10px;
  grid-column-gap: 15px;
  margin-top: 30px;
`;

interface GridItemProps {
  readonly borderRight?: boolean;
}

const GridItem = styled.div<GridItemProps>`
  border-right: ${props =>
    props.borderRight ? `1px solid ${Colors.Lavender}` : "none"};
`;

const SigText = styled.span`
  font-size: 14px;
  margin-right: 10px;
  font-weight: bold;
`;

interface DefaultGridTextProps {
  readonly normalText?: boolean;
}

const DefaultGridText = styled.span<DefaultGridTextProps>`
  font-size: 14px;
  font-weight: ${props => (props.normalText ? "normal" : "bold")};
`;
