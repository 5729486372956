import React from "react";
import { MENU_ITEM_ICON_DISABLED_COLOR } from "components/sideBar/sideBar";

interface IconProps {
  readonly isDisabled?: boolean;
}

export const MedicalDataImagesIcon: React.FC<IconProps> = ({ isDisabled }) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="LeftPannel-Patient-0"
          transform="translate(-13.000000, -158.000000)"
        >
          <g
            id="images-menu-copy-40"
            transform="translate(14.000000, 158.000000)"
          >
            <g id="image-icon-copy" transform="translate(7.000000, 6.000000)">
              <g id="Rectangle-Copy-209">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill={isDisabled ? MENU_ITEM_ICON_DISABLED_COLOR : "#208CFF"}
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <path
                d="M8.35290476,7.91130952 C8.10901845,7.91130952 7.91130952,8.10901845 7.91130952,8.35290476 L7.91130952,15.909 C7.91130952,16.1528863 8.10901845,16.3505952 8.35290476,16.3505952 L15.9090002,16.3505952 C16.0261759,16.3505953 16.1385476,16.3040247 16.2213724,16.2211379 C16.3041971,16.138251 16.3506833,16.0258444 16.3505952,15.9081905 L16.3505952,8.35290458 C16.3505953,8.23572882 16.3040247,8.12335711 16.2211379,8.04053241 C16.138251,7.95770771 16.0258444,7.91122144 15.9081905,7.91130952 L8.35290476,7.91130952 Z M8.35290476,6.63630952 L15.9077122,6.6363097 C16.3632047,6.635968 16.8001583,6.81667219 17.1223609,7.13863329 C17.4445636,7.46059438 17.6255954,7.89741237 17.6255952,8.35290476 L17.6255951,15.9077122 C17.6259368,16.3632047 17.4452326,16.8001583 17.1232715,17.1223609 C16.8013104,17.4445636 16.3644924,17.6255954 15.909,17.6255952 L8.35290476,17.6255952 C7.40485539,17.6255952 6.63630952,16.8570494 6.63630952,15.909 L6.63630952,8.35290476 C6.63630952,7.40485539 7.40485539,6.63630952 8.35290476,6.63630952 Z"
                id="Path_1236"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path
                d="M10.3427143,9.53035714 C9.89450891,9.53035714 9.53116667,9.89369939 9.53116667,10.3419048 C9.53116667,10.7901101 9.89450891,11.1534524 10.3427143,11.1534524 C10.7909197,11.1534524 11.1542619,10.7901101 11.1542619,10.3419048 C11.1542619,9.89369939 10.7909197,9.53035714 10.3427143,9.53035714 Z M10.3427143,12.4284524 C9.19034586,12.4284524 8.25616667,11.4942732 8.25616667,10.3419048 C8.25616667,9.18953633 9.19034586,8.25535714 10.3427143,8.25535714 C11.4950827,8.25535714 12.4292619,9.18953633 12.4292619,10.3419048 C12.4292619,11.4942732 11.4950827,12.4284524 10.3427143,12.4284524 Z"
                id="Path_1237"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path
                d="M14.4583273,11.5395571 L9.37461695,17.4056042 C9.14403311,17.6716728 8.74141677,17.7004389 8.47534817,17.469855 C8.20927958,17.2392712 8.1805135,16.8366549 8.41109734,16.5705863 L13.9765735,10.1486339 C14.2307776,9.85531009 14.6858979,9.85531293 14.9400983,10.1486399 L17.4698603,13.0677828 C17.7004408,13.3338542 17.6716697,13.7364702 17.4055982,13.9670507 C17.1395267,14.1976312 16.7369107,14.1688601 16.5063302,13.9027887 L14.4583273,11.5395571 Z"
                id="Path_1238"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
