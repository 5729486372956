import React, { useMemo, useState } from "react";
import { AppModal } from "components/modal";
import { ModalSeriesData } from "./fluidMetrics";
import { SeriesData } from "components/lineChart/lineChart";
import { Colors } from "helpers/colors";
import { AppLineChart } from "components/lineChart";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import {
  CardTitleText,
  CountItemCountText,
  CountItemDescriptionContainer,
  CountItemTitleContainer,
  ErrorMessage,
  FluidMetricsGridContainer,
  FluidMetricsGridItem,
  FluidMetricsResponsiveGridItem,
  FluidMetricsSubtitleContainer,
  FluidMetricsTitleText,
  NoDataText,
  OverflowContainer,
  StepContainer,
  StepDataContainer,
  StepLabelText,
  StepValueText,
  ViewMoreText
} from "./style";
import { AppCard } from "components/card";
import { get, isArray, isEmpty, sortBy } from "lodash";
import { Skeleton, Spin } from "antd";
import { ExpandAltOutlined } from "@ant-design/icons";
import { Dropdown } from "components/dropDownBox";
import {
  DropdownContentContainer,
  DropdownHeaderContainer
} from "pages/providerDetails/style";
import { FeedbackForm } from "./feedbackForm";
import { FluidMetricsResponse } from "sharedTypes";
import moment from "moment";

interface FluidMetricsContentInterface {
  readonly loading: boolean;
  readonly error: unknown;
  readonly data: FluidMetricsResponse[] | undefined;
}

const calculatePercentile = (data: number[], percentile: number) => {
  const sorted = data.sort((a: number, b: number) => a - b);
  if (sorted.length === 0) return 0;
  const pos = (sorted.length - 1) * percentile;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

export const FluidMetricsContent: React.FC<FluidMetricsContentInterface> = ({
  loading,
  error,
  data
}) => {
  const [isViewMoreDropdownVisible, setIsViewMoreDropdownVisible] =
    useState<boolean>(false);

  const onViewMoreDropdownVisibilityChange = (isVisible: boolean) =>
    setIsViewMoreDropdownVisible(isVisible);

  const [modalData, setModalData] = useState<ModalSeriesData>({
    data: [],
    title: "",
    colors: []
  });

  const countData = useMemo(() => {
    if (!data && !isArray(data)) return [];
    return [
      {
        title: "PW-TW > 1",
        count: get(data?.[0], "num_runs_pw_tw_more_1", ""),
        description: "Numbers of times PW MORE than TW by > 1KG in last 14 runs"
      },
      {
        title: "PW-TW < -1",
        count: get(data?.[0], "num_runs_pw_tw_less_1_minus", ""),
        description: "Numbers of times PW LESS than TW by > 1KG in last 14 runs"
      },
      {
        title: "IDWG > 5%",
        count: get(data?.[0], "num_runs_idwg_more_5_per", ""),
        description: "Number of times IDWG was more than 5% in last 14 runs"
      },
      {
        title: "Avg UFR",
        count: get(data?.[0], "avg_ufr_run_6rx", ""),
        description: "Average 6 Day UFR"
      },
      {
        title: "Pre Sys",
        count: get(data?.[0], "avg_3day_pre_sys_bin", ""),
        description: "Average 3 Day Pre Systolic BP Range"
      },
      {
        title: "Post Sys",
        count: get(data?.[0], "avg_3day_post_sys_bin", ""),
        description: "Average 3 Day Post Systolic BP Range"
      },
      {
        title: "BP Drops",
        count: get(
          data?.[0],
          "num_runs_bp_drop_more_equal_50_and_avg_bp_drop_less_90",
          ""
        ),
        description: "BP drop to less than 90 in over 50% of the runs"
      },
      {
        title: "UFR to Reach TW",
        count: get(data?.[0], "ufr_to_reach_tw", ""),
        description: "UFR to Reach TW"
      }
    ];
  }, [data]);

  const weightLineChartData: SeriesData[] = useMemo(() => {
    if (!data && !isArray(data)) return [];
    return [
      {
        name: "targetWt",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          item.target_weight
        ])
      },
      {
        name: "postWtKg",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          item.post_wt_kg
        ])
      }
    ];
  }, [data]);

  const preBpLineChartData: SeriesData[] = useMemo(() => {
    if (!data && !isArray(data)) return [];
    const eightySevenPercentile = calculatePercentile(
      data.map(item => item.pre_bp_systolic),
      0.87
    );
    const twelvePointFivePercentile = calculatePercentile(
      data.map(item => item.pre_bp_systolic),
      0.125
    );
    return [
      {
        name: "Threshold",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          Number(item.Threshold_Pre_BP)
        ])
      },
      {
        name: "Pre BP",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          item.pre_bp_systolic
        ])
      },
      {
        name: "87%tile",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          eightySevenPercentile
        ]),
        dashStyle: "dash"
      },
      {
        name: "12.5%tile",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          twelvePointFivePercentile
        ]),
        dashStyle: "dash"
      }
    ];
  }, [data]);

  const postBpLineChartData: SeriesData[] = useMemo(() => {
    if (!data && !isArray(data)) return [];
    const eightySevenPercentile = calculatePercentile(
      data.map(item => item.post_bp_systolic),
      0.87
    );
    const twelvePointFivePercentile = calculatePercentile(
      data.map(item => item.post_bp_systolic),
      0.125
    );
    return [
      {
        name: "Threshold",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          Number(item.Threshold_Post_BP)
        ])
      },
      {
        name: "Post BP",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          item.post_bp_systolic
        ])
      },
      {
        name: "87%tile",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          eightySevenPercentile
        ]),
        dashStyle: "dash"
      },
      {
        name: "12.5%tile",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          twelvePointFivePercentile
        ]),
        dashStyle: "dash"
      }
    ];
  }, [data]);

  const lowBpLineChartData: SeriesData[] = useMemo(() => {
    if (!data && !isArray(data)) return [];
    const eightySevenPercentile = calculatePercentile(
      data.map(item => item.lowest_bp_systolic),
      0.87
    );
    const twelvePointFivePercentile = calculatePercentile(
      data.map(item => item.lowest_bp_systolic),
      0.125
    );
    return [
      {
        name: "Threshold",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          Number(item.Threshold_Low_BP)
        ])
      },
      {
        name: "Lowest BP",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          item.lowest_bp_systolic
        ])
      },
      {
        name: "87%tile",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          eightySevenPercentile
        ]),
        dashStyle: "dash"
      },
      {
        name: "12.5%tile",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          twelvePointFivePercentile
        ]),
        dashStyle: "dash"
      }
    ];
  }, [data]);

  const actualUFRLineChartData: SeriesData[] = useMemo(() => {
    if (!data && !isArray(data)) return [];
    return [
      {
        name: "Threshold",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          Number(item.Threshold_UFR)
        ])
      },
      {
        name: "Actual UFR",
        data: sortBy(data, "run_date").map(item => [
          moment(item.run_date, "YYYY-MM-DD").valueOf(),
          item.actual_ufr
        ])
      }
    ];
  }, [data]);

  const showModal = (title: string, data: SeriesData[], colors: string[]) => {
    setModalData({ title: title, data: data, colors });
  };

  return (
    <>
      {modalData?.data.length > 0 && (
        <AppModal
          visible={modalData?.data.length > 0 ? true : false}
          title={
            modalData ? (
              <div>
                {modalData.data.length > 2 ? (
                  <div>
                    {modalData.title}{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: Colors.Grey,
                        marginLeft: "10px"
                      }}
                    >
                      [
                      {Math.round(
                        modalData.data[2].data[
                          modalData.data[2].data.length - 1
                        ][1]
                      )}{" "}
                      -{" "}
                      {Math.round(
                        modalData.data[3].data[
                          modalData.data[2].data.length - 1
                        ][1]
                      )}
                      ]
                    </span>
                  </div>
                ) : (
                  <div>{modalData.title}</div>
                )}
              </div>
            ) : (
              ""
            )
          }
          onCancel={() => setModalData({ data: [], title: "", colors: [] })}
          width={"80%"}
          footer={false}
        >
          <AppLineChart
            chartHeight="40%"
            multiLineChart
            seriesData={modalData ? [modalData.data[0], modalData.data[1]] : []}
            showLegend
            useTimestampforXaxis
            customColors={modalData.colors}
            customSeriesTooltip
          />
        </AppModal>
      )}
      <CommonPatientExtensionItems />
      <FluidMetricsTitleText>Fluid Metrics</FluidMetricsTitleText>
      <AppCard cardHeight="calc(100vh - 230px)" cardWidth="auto">
        {error && !isArray(data) ? (
          <ErrorMessage>
            There was an error in fetching Fluid Metrics
          </ErrorMessage>
        ) : isEmpty(data) && !loading ? (
          <NoDataText>No Data</NoDataText>
        ) : (
          <OverflowContainer data-testid="fluid-metrics-content">
            <FluidMetricsSubtitleContainer>
              <div>
                {`Assess Clinically For : `}
                <span style={{ fontSize: "18px", color: Colors.DodgerBlue }}>
                  {get(data?.[0], "step2_proposed_tw_reco", "")}
                </span>
              </div>
            </FluidMetricsSubtitleContainer>
            <FluidMetricsGridContainer>
              {countData.map((item, index) => (
                <FluidMetricsGridItem key={index}>
                  <AppCard cardHeight="100%" cardWidth="100%">
                    <CountItemTitleContainer>
                      {item.title}
                    </CountItemTitleContainer>
                    {loading ? (
                      <Skeleton
                        loading={loading}
                        active={loading}
                        avatar={false}
                        paragraph={{ rows: 1 }}
                      />
                    ) : (
                      <>
                        <CountItemCountText>{item.count}</CountItemCountText>
                        <CountItemDescriptionContainer title={item.description}>
                          {item.description}
                        </CountItemDescriptionContainer>
                      </>
                    )}
                  </AppCard>
                </FluidMetricsGridItem>
              ))}
              <FluidMetricsGridItem rowSpan={2} colSpan={4}>
                <AppCard
                  cardHeight="100%"
                  cardWidth="100%"
                  title={
                    <CardTitleText>
                      Target weight vs Post weight{" "}
                      <ExpandAltOutlined
                        style={{
                          float: "right",
                          fontSize: "20px",
                          color: Colors.DodgerBlue,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          showModal(
                            "Target weight vs Post weight",
                            weightLineChartData,
                            [Colors.DodgerBlue, Colors.Red]
                          )
                        }
                      />
                    </CardTitleText>
                  }
                >
                  {loading ? (
                    <Spin
                      style={{
                        display: "grid",
                        placeItems: "center",
                        height: "70%"
                      }}
                      spinning={loading}
                    />
                  ) : (
                    <AppLineChart
                      chartHeight="35%"
                      multiLineChart
                      seriesData={weightLineChartData}
                      showLegend
                      useTimestampforXaxis
                      customColors={[Colors.DodgerBlue, Colors.Red]}
                      customSeriesTooltip
                    />
                  )}
                </AppCard>
              </FluidMetricsGridItem>
              <FluidMetricsGridItem rowSpan={2} colSpan={2}>
                <AppCard
                  cardHeight="100%"
                  cardWidth="100%"
                  title={
                    <CardTitleText>Patient Reported Symptoms</CardTitleText>
                  }
                >
                  {loading ? (
                    <Spin
                      style={{
                        display: "grid",
                        placeItems: "center",
                        height: "70%"
                      }}
                      spinning={loading}
                    />
                  ) : (
                    <>
                      <div>No Symptoms to show</div>
                      <StepContainer>
                        <StepLabelText>Step 1</StepLabelText>
                        <StepValueText>Output</StepValueText>
                      </StepContainer>
                      <StepDataContainer>
                        {get(data?.[0], "step1_proposed_tw_reco", "")}
                      </StepDataContainer>
                      <Dropdown
                        arrow
                        borderradius="8px"
                        visible={isViewMoreDropdownVisible}
                        setVisible={onViewMoreDropdownVisibilityChange}
                        placement="bottomRight"
                        dropDownMenuHeader={
                          <DropdownHeaderContainer>
                            Lorem Ipsum
                          </DropdownHeaderContainer>
                        }
                        dropDownMenuBody={
                          <DropdownContentContainer style={{ maxWidth: 400 }}>
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. Optio animi facere quasi unde assumenda nisi
                            cupiditate adipisci numquam earum explicabo, rerum
                            eaque vel obcaecati cum reprehenderit consectetur
                            ducimus odio voluptatum!
                          </DropdownContentContainer>
                        }
                      >
                        <ViewMoreText>View More</ViewMoreText>
                      </Dropdown>
                    </>
                  )}
                </AppCard>
              </FluidMetricsGridItem>
              <FluidMetricsGridItem rowSpan={2} colSpan={2}>
                <AppCard
                  cardHeight="100%"
                  cardWidth="100%"
                  title={<CardTitleText>Feedback</CardTitleText>}
                >
                  <FeedbackForm />
                </AppCard>
              </FluidMetricsGridItem>

              <FluidMetricsResponsiveGridItem rowSpan={2} colSpan={2}>
                <AppCard
                  cardHeight="100%"
                  cardWidth="100%"
                  title={
                    <CardTitleText>
                      Pre BP (Systolic){" "}
                      <ExpandAltOutlined
                        style={{
                          float: "right",
                          fontSize: "20px",
                          color: Colors.DodgerBlue,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          showModal("Pre BP (Systolic)", preBpLineChartData, [
                            Colors.Red,
                            Colors.DodgerBlue,
                            Colors.LightGrey,
                            Colors.LightGrey
                          ])
                        }
                      />
                    </CardTitleText>
                  }
                >
                  {loading ? (
                    <Spin
                      style={{
                        display: "grid",
                        placeItems: "center",
                        height: "70%"
                      }}
                      spinning={loading}
                    />
                  ) : (
                    //Series Data will only be sent indices 0 and 1 since we do not want to show % values in this chart
                    <AppLineChart
                      chartHeight="65%"
                      multiLineChart
                      seriesData={[
                        preBpLineChartData[0],
                        preBpLineChartData[1]
                      ]}
                      showLegend
                      useTimestampforXaxis
                      customColors={[
                        Colors.Red,
                        Colors.DodgerBlue,
                        Colors.LightGrey,
                        Colors.LightGrey
                      ]}
                      // plotLines={preBpPlotLineData}
                      chartMarginRight={50}
                      customSeriesTooltip
                    />
                  )}
                </AppCard>
              </FluidMetricsResponsiveGridItem>
              <FluidMetricsResponsiveGridItem rowSpan={2} colSpan={2}>
                <AppCard
                  cardHeight="100%"
                  cardWidth="100%"
                  title={
                    <CardTitleText>
                      Post BP (Systolic){" "}
                      <ExpandAltOutlined
                        style={{
                          float: "right",
                          fontSize: "20px",
                          color: Colors.DodgerBlue,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          showModal("Post BP (Systolic)", postBpLineChartData, [
                            Colors.Red,
                            Colors.DodgerBlue,
                            Colors.LightGrey,
                            Colors.LightGrey
                          ])
                        }
                      />
                    </CardTitleText>
                  }
                >
                  {loading ? (
                    <Spin
                      style={{
                        display: "grid",
                        placeItems: "center",
                        height: "70%"
                      }}
                      spinning={loading}
                    />
                  ) : (
                    <AppLineChart
                      chartHeight="65%"
                      multiLineChart
                      seriesData={[
                        postBpLineChartData[0],
                        postBpLineChartData[1]
                      ]}
                      showLegend
                      useTimestampforXaxis
                      customColors={[
                        Colors.Red,
                        Colors.DodgerBlue,
                        Colors.LightGrey,
                        Colors.LightGrey
                      ]}
                      // plotLines={postBpPlotLineData}
                      chartMarginRight={50}
                      customSeriesTooltip
                    />
                  )}
                </AppCard>
              </FluidMetricsResponsiveGridItem>
              <FluidMetricsResponsiveGridItem rowSpan={2} colSpan={2}>
                <AppCard
                  cardHeight="100%"
                  cardWidth="100%"
                  title={
                    <CardTitleText>
                      Lowest BP (Systolic){" "}
                      <ExpandAltOutlined
                        style={{
                          float: "right",
                          fontSize: "20px",
                          color: Colors.DodgerBlue,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          showModal(
                            "Lowest BP (Systolic)",
                            lowBpLineChartData,
                            [
                              Colors.Red,
                              Colors.DodgerBlue,
                              Colors.LightGrey,
                              Colors.LightGrey
                            ]
                          )
                        }
                      />
                    </CardTitleText>
                  }
                >
                  {loading ? (
                    <Spin
                      style={{
                        display: "grid",
                        placeItems: "center",
                        height: "70%"
                      }}
                      spinning={loading}
                    />
                  ) : (
                    <AppLineChart
                      chartHeight="65%"
                      multiLineChart
                      seriesData={[
                        lowBpLineChartData[0],
                        lowBpLineChartData[1]
                      ]}
                      showLegend
                      useTimestampforXaxis
                      customColors={[
                        Colors.Red,
                        Colors.DodgerBlue,
                        Colors.LightGrey,
                        Colors.LightGrey
                      ]}
                      // plotLines={lowBpPlotLineData}
                      chartMarginRight={50}
                      customSeriesTooltip
                    />
                  )}
                </AppCard>
              </FluidMetricsResponsiveGridItem>
              <FluidMetricsResponsiveGridItem rowSpan={2} colSpan={2}>
                <AppCard
                  cardHeight="100%"
                  cardWidth="100%"
                  title={
                    <CardTitleText>
                      Actual UFR{" "}
                      <ExpandAltOutlined
                        style={{
                          float: "right",
                          fontSize: "20px",
                          color: Colors.DodgerBlue,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          showModal("Actual UFR", actualUFRLineChartData, [
                            Colors.Red,
                            Colors.DodgerBlue
                          ])
                        }
                      />
                    </CardTitleText>
                  }
                >
                  {loading ? (
                    <Spin
                      style={{
                        display: "grid",
                        placeItems: "center",
                        height: "70%"
                      }}
                      spinning={loading}
                    />
                  ) : (
                    <AppLineChart
                      chartHeight="65%"
                      multiLineChart
                      seriesData={actualUFRLineChartData}
                      showLegend
                      useTimestampforXaxis
                      customColors={[Colors.Red, Colors.DodgerBlue]}
                      customSeriesTooltip
                    />
                  )}
                </AppCard>
              </FluidMetricsResponsiveGridItem>
            </FluidMetricsGridContainer>
          </OverflowContainer>
        )}
      </AppCard>
    </>
  );
};
