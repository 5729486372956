import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { UserContext, UserContextState } from "contextApis/userContext";

interface CaregemAgreementProps {
  pName?: string | undefined | null;
  pEmail: string | undefined | null;
  clientLogo?: string | null | undefined;
}

const CaregemAgreementForm: React.FC<CaregemAgreementProps> = ({
  pName,
  pEmail,
  clientLogo
}) => {
  const userContext = useContext<UserContextState>(UserContext);

  const [patientName, SetPatientName] = useState(pName);
  //   const [patientDob, SetPatientDob] = useState(pDob);
  //   const [patientRealatedPersonName, setPatientRealatedPersonName] =
  // useState(signer);
  //   const [patientInitial, setPatientInitial] = useState(userInitial);
  // const [relation, setRelation] = useState<string | null>(relative);
  const [patientEmail, SetPatientEmail] = useState(pEmail);
  const [clientLogoCaregem, setClientLogoCaregem] = useState(
    process.env["REACT_APP_ASSET_ENDPOINT"] + "" + clientLogo
  );
  const [practiceLogoCaregem, setPracticeLogoCaregem] = useState(
    process.env["REACT_APP_ASSET_ENDPOINT"] + "logo/CareGemHealthLogo.png"
  );

  //   useEffect(() => {
  //     // userTypedSignature();
  //     setPatientInitial(userInitial);
  //   }, [userInitial]);

  //   useEffect(() => {
  //     // setRelation(relative);
  //     setPatientRealatedPersonName(signer);
  //   }, [signer]);

  const date = new Date();

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const formattedTodaysDate = `${month}/${day}/${year}`;

  //   useEffect(() => {
  //     if (sign) {
  //       userContext.setPdfByteString(sign);
  //     } else {
  //       userContext.setPdfByteString(patientInitial);
  //     }
  //     consentName && userContext.setConsentName(consentName);
  //     patientName && userContext.setSignerName(patientName);
  //     patientRealatedPersonName &&
  //       userContext.setSignerRelationship(patientRealatedPersonName);
  //   }, [sign, patientRealatedPersonName, patientInitial]);

  return (
    <div className="consesntContainer">
      <div className="form-container" id="divToExport">
        <div className="header">
          <img
            className="logo-left"
            src={clientLogoCaregem}
            alt="NephrologySpeaciallistsLogo"
            width={300}
          />
          <img
            className="logo-right"
            src={practiceLogoCaregem}
            alt="CareGemHealthLogo"
            width={180}
          />
        </div>
        <div className="consentFormHeader">
          <p style={{ fontWeight: 650, fontSize: 16 }}>
            CareGem Subscriber Agreement
          </p>

          <p className="paragraph">
            By accessing, downloading or using the CareGem platform
            (“Platform”), I and/or any person for whom I act on behalf of (as
            Power of Attorney, Guardian, or other Responsible party)
            (“Subscriber”), agree to the following:
          </p>
          <p className="paragraph">
            I have received and reviewed the document entitled Empowering
            Coordinated Healthcare by CareGem, and the CareGem Privacy Policy. I
            understand the information and instructions in the document, and
            have had the opportunity to have my questions answered about the
            CareGem program (‘Program’).
          </p>
          <p className="paragraph">
            I understand that use of the Platform is only intended to assist in,
            and not replace my current health treatment plan. I agree to
            continue to use the current systems available to me to access care,
            and not rely on the Program/Platform to deal with any urgent or
            non-urgent health-care needs. <br />I UNDERSTAND THAT THE PLATFORM
            IS NOT INTENDED FOR EMERGENCY USE AND IS NOT MONITORED 24/7. I WILL
            CALL 911 FOR IMMEDIATE MEDICAL EMERGENCIES.
          </p>
          <p className="paragraph">
            I understand that by enrolling in the Program, my personal health
            information will be shared with CareGem and its affiliate Althea.ai.
            The information collected about me may only be used in accordance
            with the current privacy policy of Respective Practices and CareGem.
            I understand that my information will be kept confidential and
            secure on a network compliant with HIPAA, and will only be made
            available to Program staff, and my health-care team: (i) for
            billing, treatment and health care operations; (ii) for improvement
            of the Program or Platform; (iii) evaluation of Program’s impact on
            my care; and (iv) for health research activities (through the use of
            de-identified data).
          </p>
          <p className="paragraph">
            I understand that by signing this consent I have reviewed and agree
            to the Privacy Policy, Terms and Conditions and End User License
            Agreement of CareGem, available at{" "}
            <a href="http://www.caregem.com/legal">www.caregem.com/legal</a>. I
            have also received and agree to the Privacy Policy of Practices.
          </p>
          <p className="paragraph">
            Use of CareGem involves SMS messages for alerts and collaboration. I
            agree to be responsible for Message and Data rates that may apply to
            SMS messages sent to my phone.
          </p>
          <p className="paragraph">
            I have read and understood the information above and consent to
            enroll in the Program.
          </p>

          <p className="paragraph">
            Date accepted:
            <span className="patientData" style={{ marginLeft: 17 }}>
              {formattedTodaysDate}
            </span>
            (mm/dd/yyyy)
          </p>
          <p className="paragraph">
            Subscriber Name:
            <span className="patientData" style={{ marginLeft: 17 }}>
              {patientName}
            </span>
          </p>
          <p className="paragraph">
            Subscriber Email address:
            <span className="patientData" style={{ marginLeft: 17 }}>
              {patientEmail}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CaregemAgreementForm;
