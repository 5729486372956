import Space from "antd/lib/space";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import React from "react";
import styled from "styled-components";

import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { NephrologyLogo } from "pages/symptoms/surveyForms/nephrologyLogo";
import { Platform } from "constants/roles";

interface DizzynessSectionProps {
  readonly currentPage: number;
}

export const DizzynessSection: React.FC<DizzynessSectionProps> = ({
  currentPage
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  return (
    <>
      {currentPage === 1 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                fontWeight: "600"
              }}
            >
              Please do not rely upon this survey for immediate medical
              attention. This survey may not be reviewed by your care team
              immediately. Please call your doctor for any medical problems. IF
              YOU THINK THIS IS A MEDICAL EMERGENCY, OR YOUR SYMPTOMS ARE
              WORRISOME PLEASE CALL 911 OR GO TO THE NEAREST EMERGENCY ROOM.
            </Space>
          </AppCard>

          {/* <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                fontWeight: "600"
              }}
            >
              <NephrologyLogo />
            </Space>
          </AppCard> */}
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{ width: "100%", padding: "10px" }}
              >
                <DisclaimerTitle>DIZZYNESS</DisclaimerTitle>
                <CardTitle>
                  Have you been feeling dizzy or lightheaded during dialysis
                  recently?
                  <AsteriskStyle> *</AsteriskStyle>
                </CardTitle>
                <FormItemStyle
                  name="Have you been feeling dizzy"
                  label=""
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  initialValue={""}
                >
                  <Radio.Group
                    value={localStorage.getItem("feelingDizzyRecently")}
                    onChange={e => {
                      localStorage.setItem(
                        "feelingDizzyRecently",
                        e.target.value
                      );
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={"NO"}>NO</Radio>
                      <Radio value={"Rarely (1 - 2 / Month)"}>
                        Rarely (1 - 2 / Month)
                      </Radio>
                      <Radio value={"Occasional (3 - 4 / Month)"}>
                        Occasional (3 - 4 / Month)
                      </Radio>
                      <Radio value={"Frequent (5 - 10 / Month)"}>
                        Frequent (5 - 10 / Month)
                      </Radio>
                      <Radio value={"Every dialysis (11 - 13 / Month)"}>
                        Every dialysis (11 - 13 / Month)
                      </Radio>
                    </Space>
                  </Radio.Group>
                </FormItemStyle>
              </Space>
            </AppCard>
          </Space>
        </Space>
      )}
      {currentPage === 2 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>SYMPTOM ALERT</DisclaimerTitle>
              <SymptomAlert>
                Please discuss your symptoms of dizzyness with your nurse or
                doctor
              </SymptomAlert>
            </Space>
          </AppCard>
        </Space>
      )}
    </>
  );
};

const DisclaimerTitle = styled.div`
  width: 100%;
  padding: 8px 8px 8px 20px;
  margin-left: -25px;
  background: ${Colors.DodgerBlue};
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 8px 8px 0px 0px;
  color: white;
  position: absolute;
  top: 0;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;

const SymptomAlert = styled.div`
  font-size: 16px;
  font-family: sans-serif;
  margin-top: 15px;
`;
