import Space from "antd/lib/space";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import React from "react";
import styled from "styled-components";

import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { Platform } from "constants/roles";

interface LegSwellingSectionSectionProps {
  readonly currentPage: number;
}

export const LegSwellingSectionSection: React.FC<
  LegSwellingSectionSectionProps
> = ({ currentPage }) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  return (
    <>
      {currentPage === 12 && (
        <Space direction="vertical" size={20}>
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{ width: "100%", padding: "10px" }}
              >
                <DisclaimerTitle>LEG SWELLING</DisclaimerTitle>
                <CardTitle>
                  Have you noticed any leg swelling during dialysis?
                  <AsteriskStyle> *</AsteriskStyle>
                </CardTitle>
                <FormItemStyle
                  name="Have you had any leg swelling"
                  label=""
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  initialValue={""}
                >
                  <Radio.Group
                    value={localStorage.getItem("haveYouHadAnyLegSwelling")}
                    onChange={e => {
                      localStorage.setItem(
                        "haveYouHadAnyLegSwelling",
                        e.target.value
                      );
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={"NONE"}>NONE</Radio>
                      <Radio value={"Mild up to ankles or shins only"}>
                        Mild up to ankles or shins only
                      </Radio>
                      <Radio value={"Moderate, up to knees"}>
                        Moderate, up to knees
                      </Radio>
                      <Radio value={"Severe, up to thighs"}>
                        Severe, up to thighs
                      </Radio>
                    </Space>
                  </Radio.Group>
                </FormItemStyle>
              </Space>
            </AppCard>
          </Space>
        </Space>
      )}
      {currentPage === 13 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>SYMPTOM ALERT</DisclaimerTitle>
              <SymptomAlert>
                Please discuss your symptoms of leg swelling with your nurse or
                doctor and dietician, regarding dietary sodium restriction, and
                other treatment changes
              </SymptomAlert>
            </Space>
          </AppCard>
        </Space>
      )}
    </>
  );
};

const DisclaimerTitle = styled.div`
  width: 100%;
  padding: 8px 8px 8px 20px;
  margin-left: -25px;
  background: ${Colors.DodgerBlue};
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 8px 8px 0px 0px;
  color: white;
  position: absolute;
  top: 0;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;

const SymptomAlert = styled.div`
  font-size: 16px;
  font-family: sans-serif;
  margin-top: 15px;
`;
