// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import {
  PrimaryButton,
  Flex,
  Label,
  useMeetingManager,
  Modal,
  ModalBody,
  ModalHeader,
  useAudioVideo
} from "amazon-chime-sdk-component-library-react";

import Card from "components/meetings/components/Card";
import { useAWSMeetingState } from "components/meetings/providers/AWSMeetingStateProvider";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";
import { AudioVideoFacade } from "amazon-chime-sdk-js";

const MeetingJoinDetails = () => {
  const meetingManager = useMeetingManager();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { meetingId, localUserName } = useAWSMeetingState();
  const audioVideo = useAudioVideo();
  const audioVideoRef = useRef<AudioVideoFacade | null>(audioVideo);
  const joinMeetingRef = useRef<boolean>(false);

  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

  const handleJoinMeeting = async () => {
    joinMeetingRef.current = true;
    setIsLoading(true);

    try {
      await meetingManager.start();
      setIsLoading(false);
      meetingContext.setCurrentPage(2);
    } catch (error: any) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  useLayoutEffect(() => {
    if (audioVideo) {
      audioVideoRef.current = audioVideo;
    }
    return () => {
      if (!joinMeetingRef.current) {
        audioVideoRef.current?.stopAudioInput();
        audioVideoRef.current?.stopVideoInput();
      }
    };
  }, [audioVideo]);

  return (
    <>
      <Flex
        container
        alignItems="flex-start"
        flexDirection="column"
        style={
          meetingContext.pstnPhoneNumber
            ? { textAlign: "center" }
            : {
                paddingRight: "0px",
                paddingTop: "30px",
                width: "650px"
              }
        }
      >
        <PrimaryButton
          label={isLoading ? "Loading..." : "Join meeting"}
          onClick={handleJoinMeeting}
        />
        <Label style={{ margin: ".75rem 0 0 0" }}>
          Joining meeting as <b>{localUserName}</b>
        </Label>
      </Flex>
      {error && (
        <Modal size="md" onClose={(): void => setError("")}>
          <ModalHeader title={`Meeting ID: ${meetingId}`} />
          <ModalBody>
            <Card
              title="Unable to join meeting"
              description="There was an issue in joining this meeting. Check your connectivity and try again."
              smallText={error}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default MeetingJoinDetails;
