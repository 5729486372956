import { Space } from "antd";
import { startCase } from "lodash";
import React from "react";
import { AppSelect } from "components/inputs";
import {
  notificationStatusFilterOptions,
  notificationTypeFilterOptions
} from "contextApis/notificationsContext";
import { FilterCol, RowStyled, TitleCol } from "./style";

interface NotificationsHeaderProps {
  readonly setStatusFilter: (status: string) => void;
  readonly statusFilter: string;
  readonly setTypeFilter: (status: string) => void;
  readonly typeFilter: string;
}

export const NotificationsHeader: React.FC<NotificationsHeaderProps> = ({
  statusFilter,
  setStatusFilter,
  setTypeFilter,
  typeFilter
}) => {
  const handleTypeFilterDisplayValue = (filterValue: string) => {
    const match = /[*?+^$_{}[\]().|\\]/.exec(filterValue); //Special character check
    if (match) {
      const tempValue = filterValue.replace("_", " ").split(/\s+/);
      tempValue[1] = startCase(tempValue[1]);
      return tempValue.join(" ");
    }
    return filterValue;
  };

  return (
    <RowStyled gutter={[20, 0]}>
      <TitleCol span={12}>Notifications</TitleCol>
      <FilterCol span={12}>
        <Space size={10}>
          <AppSelect
            value={
              !statusFilter
                ? "All"
                : statusFilter.charAt(0).toUpperCase() + statusFilter.slice(1)
            }
            label="Filter By"
            onChange={type => {
              setStatusFilter(type);
            }}
            style={{ marginRight: 5, width: "100px", textAlign: "left" }}
            options={notificationStatusFilterOptions}
            dropdownStyle={{ borderRadius: "8px" }}
          />
          <AppSelect
            value={
              !typeFilter
                ? "All"
                : handleTypeFilterDisplayValue(
                    typeFilter.charAt(0).toUpperCase() + typeFilter.slice(1)
                  )
            }
            label="Filter By"
            onChange={type => {
              setTypeFilter(type);
            }}
            style={{ marginRight: 5, width: "140px", textAlign: "left" }}
            options={notificationTypeFilterOptions}
            dropdownStyle={{ borderRadius: "8px" }}
          />
        </Space>
      </FilterCol>
    </RowStyled>
  );
};
