import React, { createContext, useContext, useState } from "react";

interface SurveyContextProps {
  surveyresponses: any;
  surveyName: string;
  surveyId: number;
  selectedSurvey?: any;
  setRespondData: (
    newResponses: any,
    newSurveyName: string,
    newSurveyId: number,
    newSelectedSurvey?: any
  ) => void;
}

const SurveyContext = createContext<SurveyContextProps>({
  surveyresponses: null,
  surveyName: "",
  surveyId: 0,
  selectedSurvey: null,
  setRespondData: () => null
});

export const useSurveyContext = () => {
  return useContext(SurveyContext);
};

export const SurveyProvider: React.FC = ({ children }) => {
  const [surveyresponses, setSurveyResponses] = useState<any>(null);
  const [surveyName, setSurveyName] = useState<string>("");
  const [surveyId, setSurveyId] = useState<number>(0);
  const [selectedSurvey, setSelectedSurvey] = useState<any>(null);

  const setRespondData = (
    newResponses: any,
    newSurveyName: string,
    newSurveyId: number,
    newSelectedSurvey: any
  ) => {
    setSurveyResponses(newResponses);
    setSurveyName(newSurveyName);
    setSurveyId(newSurveyId);
    setSelectedSurvey(newSelectedSurvey);
  };

  const contextValue = {
    surveyresponses,
    surveyName,
    surveyId,
    selectedSurvey,
    setRespondData
  };

  return (
    <SurveyContext.Provider value={contextValue}>
      {children}
    </SurveyContext.Provider>
  );
};
