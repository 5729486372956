import React, { useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import styled from "styled-components";

import { Colors } from "helpers/colors";
import { ChartIcon } from "./ChartIcon";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import SetThresholdModal from "./setThresholdModal";

interface TableFiltersProps {
  setTypeFilter(tab: string): void;
  readonly typeFilter: string;
  setShowVitalsCharts(type: string): void;
  readonly selectedTab: string;
  readonly loading: boolean;
}

export const TableFilters: React.FC<TableFiltersProps> = ({
  setTypeFilter,
  typeFilter,
  setShowVitalsCharts,
  selectedTab,
  loading
}) => {
  return (
    <RowStyled>
      <Col span={22}>
        <Row>
          {typeFilter !== "All" ? (
            <InactiveButton
              onClick={() => setTypeFilter("All")}
              style={{ borderRadius: "8px 0px 0px 8px" }}
            >
              All
            </InactiveButton>
          ) : (
            <ActiveButton
              onClick={() => setTypeFilter("All")}
              style={{ borderRadius: "8px 0px 0px 8px" }}
            >
              All
            </ActiveButton>
          )}
          {typeFilter !== "Remote" ? (
            <InactiveButton onClick={() => setTypeFilter("Remote")}>
              Remote
            </InactiveButton>
          ) : (
            <ActiveButton onClick={() => setTypeFilter("Remote")}>
              Remote
            </ActiveButton>
          )}
          {typeFilter !== "Manual" ? (
            <InactiveButton
              onClick={() => setTypeFilter("Manual")}
              style={{ borderRadius: "0px 8px 8px 0px" }}
            >
              Manual
            </InactiveButton>
          ) : (
            <ActiveButton
              onClick={() => setTypeFilter("manual")}
              style={{ borderRadius: "0px 8px 8px 0px" }}
            >
              Manual
            </ActiveButton>
          )}
        </Row>
      </Col>

      {!loading && (
        <Col
          span={2}
          style={{
            padding: "8px",
            display: "flex",
            position: "absolute",
            right: "100px"
          }}
        >
          <div>
            <SetThresholdModal></SetThresholdModal>
          </div>
          <AppButton
            type={ButtonType.Skeleton}
            buttonContent={<ChartIcon />}
            style={{ border: "1px solid black", marginTop: "-9px" }}
            onClick={() => setShowVitalsCharts(selectedTab)}
          />
        </Col>
      )}
    </RowStyled>
  );
};

const RowStyled = styled(Row)`
  margin-top: -4px;
  margin-bottom: 12px;
`;

const ActiveButton = styled(Col)`
  display: grid;
  place-items: center;
  width: 120px;
  height: 43px;
  background-color: #edf5fd;
  font-weight: 600;
  color: ${Colors.DodgerBlue};
  border: 1px solid ${Colors.DodgerBlue};
  z-index: 2;
  cursor: pointer;
  margin-right: -1px;
`;

const InactiveButton = styled(Col)`
  display: grid;
  place-items: center;
  width: 120px;
  height: 43px;
  border: 1px solid ${Colors.Grey};
  background-color: #edf5fd;
  cursor: pointer;
  margin-right: -1px;

  :hover {
    font-weight: 600;
    color: ${Colors.DodgerBlue};
    border: 1px solid ${Colors.DodgerBlue};
    z-index: 2;
  }
`;
