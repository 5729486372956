import React from "react";

interface BackArrowProps {
  readonly color?: string;
  readonly height?: string | number;
  readonly width?: string | number;
}

const BackArrow: React.FC<BackArrowProps> = ({
  height = "12px",
  width = "12px",
  color = "#979dab"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    fill={color}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
  </svg>
);

export default BackArrow;
