import * as React from "react";
import styled from "styled-components";
import AddAppointmentIcon from "./addNewAppointment.svg";

export const AddNewAppointmentIcon: React.FC = () => (
  <StyledImg src={AddAppointmentIcon} alt="add new appointment" />
);

const StyledImg = styled.img`
  margin-left: -5px;
`;
