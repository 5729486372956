// import React from "react";
// import styled from "styled-components";
// import { Colors } from "helpers/colors";

// export const TopbarExtensionComponent: React.FC = ({ children }) => {
//   return <TopbarExtensionStyled>{children}</TopbarExtensionStyled>;
// };

// const TopbarExtensionStyled = styled.div`
//   width: 100%;
//   height: 65px;
//   display: grid;
//   place-items: center;
//   position: fixed;
//   top: 61px;
//   left: 0;
//   background-color: ${Colors.White};
//   box-shadow: 0 5px 6px -1px rgba(0, 0, 0, 0.05);
//   z-index: 10;
// `;

import React from "react";
import styled from "styled-components";
import { Colors } from "helpers/colors";

export const TopbarExtensionComponent: React.FC = ({ children }) => {
  const epicLogin: any = sessionStorage.getItem("epicLogin");

  return (
    <TopbarExtensionStyled epicLogin={epicLogin}>
      {children}
    </TopbarExtensionStyled>
  );
};

interface TopbarExtensionStyledProps {
  epicLogin: any;
}

const TopbarExtensionStyled = styled.div<TopbarExtensionStyledProps>`
  width: 100%;
  height: 65px;
  display: grid;
  place-items: center;
  position: fixed;
  top: ${props => (props.epicLogin === "true" ? "0px" : "61px")};
  left: 0;
  background-color: ${Colors.White};
  box-shadow: 0 5px 6px -1px rgba(0, 0, 0, 0.05);
  z-index: 10;
`;
