import React, { useContext, useEffect, useState } from "react";
import "./bp.css";
import { useNavigate } from "react-router";
import { NotificationsContext } from "contextApis/notificationsContext";
import { ArrowUpwardIcon } from "../remoteVitals/ArrowUpwardIcon";
import { ArrowDownwardIcon } from "../remoteVitals/ArrowDownwardIcon";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";
import { EyeOutlined } from "@ant-design/icons";
import { createApiClient } from "apiClient";
import moment from "moment";
import { Colors } from "helpers/colors";
import { Empty } from "antd";
import { notification } from "antd";

const Bplist = () => {
  type ActionTakenType = {
    [key: number]: boolean;
  };

  const [actionTaken, setActionTaken] = useState<ActionTakenType>({});
  // const [filteredPatientData, setFilteredPatientData] = useState<any[]>([]);
  const [patientGroupId, setPatientGroupId] = useState<string>("");
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const { notifications } = useContext(NotificationsContext);
  const navigate = useNavigate();

  const [expireDateValidation, setExpireDateValidation] = useState<any>("");

  function convertNotificationDate(utcDateStr: any) {
    const [datePart, timePart] = utcDateStr.split(" ");
    const utcDate = new Date(`${datePart}T${timePart}:00Z`);
    const finalDate = utcDate;
    const parsedDate = moment(finalDate, "dd/mm/yyyy");
    const formattedDate = parsedDate.format("MMM, DD");
    return formattedDate;
  }

  function convertNotificationTime(utcDateStr: any) {
    const [datePart, timePart] = utcDateStr.split(" ");
    const utcDate = new Date(`${datePart}T${timePart}:00Z`);
    const finalTime = utcDate?.toLocaleString()?.substring(10);
    const parsedTime = moment(finalTime, "h:mm:ss A");
    const formattedTime = parsedTime.format("h:mm");

    return formattedTime;
  }

  // function convertNotificationDate(utcDateStr: any) {
  //   const [datePart, timePart] = utcDateStr.split(" ");
  //   const utcDate = new Date(`${datePart}T${timePart}:00Z`);
  //   const finalDate =
  //     utcDate?.toLocaleString()?.substring(0, 10)?.length >= 10
  //       ? utcDate?.toLocaleString()?.substring(0, 9)
  //       : utcDate?.toLocaleString()?.substring(0, 10);
  //   const parsedDate = moment(finalDate, "DD/MM/YYYY");
  //   const formattedDate = parsedDate.format("MMM, DD");
  //   return formattedDate;
  // }

  // function convertNotificationTime(utcDateStr: any) {
  //   const [datePart, timePart] = utcDateStr.split(" ");
  //   const utcDate = new Date(`${datePart}T${timePart}:00Z`);
  //   const finalTime = utcDate?.toLocaleString()?.substring(10);
  //   const parsedTime = moment(finalTime, "h:mm:ss A");
  //   const formattedTime = parsedTime.format("h:mm");

  //   return formattedTime;
  // }

  function getDateMinus48Hours() {
    const currentDate = new Date();
    const pastDate = new Date(currentDate.getTime() - 48 * 60 * 60 * 1000);
    setExpireDateValidation(moment(pastDate).format("YYYY-MM-DD hh:mm"));
  }

  useEffect(() => {
    getDateMinus48Hours();
  }, []);

  useEffect(() => {
    if (notifications) {
      const filteredData = notifications?.remote_vitals?.filter(
        notification => notification?.alert_flag === "1"
      );
      const currentDate = new Date();
      const pastDate = new Date(currentDate.getTime() - 48 * 60 * 60 * 1000);
      const filteredList = filteredData?.filter((item: any) => {
        const parsedDate = moment(item?.created_on);
        const epochMilliseconds = parsedDate.valueOf();
        const parsed48HrsDate = moment(pastDate);
        const epoch48hrsDate = parsed48HrsDate.valueOf();
        return epochMilliseconds > epoch48hrsDate;
      });
      setFilteredData(filteredList);
    }
  }, [notifications, expireDateValidation]);

  const [filteredData, setFilteredData] = useState<any[]>([]);

  const roundBpReading = (bpReading: string): string => {
    const [upper, lower] = bpReading.split("/");
    const roundedUpper = Math.round(parseFloat(upper)).toString();
    const roundedLower = Math.round(parseFloat(lower)).toString();
    return `${roundedUpper}/${roundedLower}`;
  };

  const handleCallClick = (id: number, groupId: string) => {
    setPatientGroupId(groupId);
    sessionStorage.setItem("alert_call", "true");
    sessionStorage.getItem("alert_call");
    sessionStorage.setItem("group_id", groupId);
    sessionStorage.getItem("groupId");
    clearNavigationPath();
    sessionStorage.setItem("patientId", String(id));
    // window.location.replace(`/patients/remote-vitals/${id}`);
    navigate(`/patients/remote-vitals/${id}`);
    setActionTaken(prevState => ({
      ...prevState,
      [id]: true
    }));
  };

  const AsestedData = {
    group_id: patientGroupId
  };

  const fetchAssested = async () => {
    if (patientGroupId === "") return;
    try {
      // console.log("Sending data:", AsestedData);
      const data = await createApiClient().setAlertAssisted(AsestedData);
      // console.log("API Response:", data);
      return data;
    } catch (error) {
      console.error("Error in fetchAssested :");
    }
  };

  useEffect(() => {
    fetchAssested();
  }, [patientGroupId]);

  const clearNavigationPath = () => {
    sessionStorage.removeItem("patientId");
    navigate("/");
  };

  return (
    <div className="data-container">
      {filteredData.length > 0 ? (
        filteredData?.map(patient => (
          <div
            className="data-row"
            key={patient.patient_id}
            onClick={() => {
              handleCallClick(patient.patient_id, patient?.group_id);
            }}
          >
            <div className="data-left">
              {patient.patient_name}
              <div className="data-item-name"></div>
            </div>
            <div
              style={{
                position: "relative",
                right: "-30px",
                fontSize: "12px",
                width: "65px",
                display: "felx",
                alignItems: "center",
                justifyContent: "center",
                fontStyle: "italic",
                fontWeight: "500"
              }}
            >
              <div style={{ marginLeft: "20px" }}>
                {patient?.created_on &&
                  convertNotificationDate(patient?.created_on)}
              </div>
              <div
                style={{
                  marginLeft: "33px",
                  color: `${Colors.Grey}`
                }}
              >
                {patient?.created_on &&
                  convertNotificationTime(patient?.created_on)}
              </div>
            </div>

            <div className="data-right">
              <div className="data-bp">
                {patient.alert_type === "High BP" ? (
                  <div className="data-item">
                    <div className="bp-indicator">
                      {/* <HighBPIndicator /> */}
                      <ArrowUpwardIcon color="#F31E13" />
                    </div>
                    <div className="bp-reading">
                      <div className="bp-HL">High</div>
                      <div>{roundBpReading(patient.alert_reading)}</div>
                    </div>
                  </div>
                ) : (
                  <div className="data-item">
                    <div className="bp-indicator">
                      {/* <LowBPIndicator /> */}
                      <ArrowDownwardIcon color="#F5A623" />
                    </div>
                    <div className="bp-reading">
                      <div className="bp-HL">Low</div>
                      <div>{roundBpReading(patient.alert_reading)}</div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="data-item user-icon-wrapper"
                style={{
                  background:
                    patient?.alert_assist === "1" ? "#4CAF50" : "#E5E5E5"
                }}
              >
                <EyeOutlined style={{ color: "black" }} />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "180px",
            color: "grey"
          }}
        >
          <Empty style={{ fontSize: "14px" }} />
        </div>
      )}
    </div>
  );
};

export default Bplist;
