import moment from "moment";
import { lowerCaseRemoveSpace } from "helpers/utils";
import { ExtendedSymptomData } from "pages/symptoms/SymptomsModal";

export const feverMapper = ({
  filteredExtendedSymptomData,
  mapperType,
  mapperValue
}: {
  filteredExtendedSymptomData: ExtendedSymptomData[] | null;
  mapperType?: string | undefined;
  mapperValue?: string | number | undefined;
}) => {
  const severity: [string, number, string][] = [];
  const frequency: [string, number, string][] = [];

  const sortedData = filteredExtendedSymptomData?.sort(
    (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
  );

  if (mapperType) {
    let tooltipValue = "";
    if (lowerCaseRemoveSpace(mapperType) === "severity") {
      switch (mapperValue) {
        case 1:
          tooltipValue = "Less than 101";
          break;
        case 4:
          tooltipValue = "101-103";
          break;
        case 7:
          tooltipValue = "More than 103";
          break;
        case 10:
          tooltipValue = "Fever with shaking chills, or rigors";
          break;
      }
    } else if (lowerCaseRemoveSpace(mapperType) === "frequency") {
      switch (mapperValue) {
        case 1:
          tooltipValue = "Once";
          break;
        case 5:
          tooltipValue = "2-4 times";
          break;
        case 10:
          tooltipValue = "5 or more times";
          break;
      }
    }
    return tooltipValue;
  }

  sortedData?.forEach(item => {
    item.data?.forEach(item2 => {
      if (lowerCaseRemoveSpace(item2?.key) === "severity") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "lessthan101":
            value = 1;
            break;
          case "101-103":
            value = 4;
            break;
          case "morethan103":
            value = 7;
            break;
          case "feverwithshakingchills,orrigors":
            value = 10;
            break;
        }
        severity.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Severity"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "frequency") {
        let value = 0;

        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "once":
            value = 1;
            break;
          case "2-4times":
            value = 5;
            break;
          case "5ormoretimes":
            value = 10;
            break;
        }
        frequency.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Frequency"
        ]);
      }
    });
  });

  return [severity, frequency];
};
