import React from "react";
import styled from "styled-components";
import DiscontinuedMedication from "./discontinuedMedication.svg";

export const DiscontinuedMedicationIcon: React.FC = () => (
  <StyledImg src={DiscontinuedMedication} alt="discontinued medicaiton" />
);

const StyledImg = styled.img`
  margin-left: -5px;
`;
