// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext } from "react";
import {
  AudioInputDevice,
  Device,
  VoiceFocusModelName,
  VoiceFocusTransformDevice
} from "amazon-chime-sdk-js";
import {
  BackgroundBlurProvider,
  BackgroundReplacementProvider,
  MeetingProvider,
  useVoiceFocus,
  VoiceFocusProvider
} from "amazon-chime-sdk-component-library-react";

import { NavigationProvider } from "components/meetings/providers/NavigationProvider";
import { DeviceSetup } from "components/meetings/views";
import { MeetingView } from "components/meetings/views/Meeting";
import MeetingEventObserver from "components/meetings/containers/MeetingEventObserver";
import { useAWSMeetingState } from "components/meetings/providers/AWSMeetingStateProvider";
import { VideoFiltersCpuUtilization } from "components/meetings/types";
import {
  MeetingModalContext,
  MeetingContextState
} from "contextApis/meetingModalContext";
import NoMeetingRedirect from "components/meetings/containers/NoMeetingRedirect";
import MeetingWithTwilio from "../MeetingWithTwilio";

const MeetingProviderWithDeviceReplacement: React.FC = ({ children }) => {
  const { addVoiceFocus } = useVoiceFocus();

  const onDeviceReplacement = (
    nextDevice: string,
    currentDevice: AudioInputDevice
  ): Promise<Device | VoiceFocusTransformDevice> => {
    if (currentDevice instanceof VoiceFocusTransformDevice) {
      return addVoiceFocus(nextDevice);
    }
    return Promise.resolve(nextDevice);
  };

  const meetingConfigValue = {
    onDeviceReplacement
  };

  return <MeetingProvider {...meetingConfigValue}>{children}</MeetingProvider>;
};

const MeetingProviderWrapper: React.FC = () => {
  const {
    isWebAudioEnabled,
    videoTransformCpuUtilization,
    imageBlob,
    joinInfo,
    meetingMode
  } = useAWSMeetingState();

  const isFilterEnabled =
    videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled;
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

  const getMeetingProviderWrapper = () => {
    return (
      <>
        <NavigationProvider>
          {meetingContext.currentPage === 1 && <DeviceSetup />}
          {meetingContext.currentPage === 2 && (
            <NoMeetingRedirect>
              <MeetingView mode={meetingMode} />
            </NoMeetingRedirect>
          )}
          {meetingContext.currentPage === 3 && <MeetingWithTwilio />}
        </NavigationProvider>
        <MeetingEventObserver />
      </>
    );
  };

  function voiceFocusName(name: string): VoiceFocusModelName {
    if (name && ["default", "ns_es"].includes(name)) {
      return name as VoiceFocusModelName;
    }
    return "default";
  }

  function getVoiceFocusSpecName(): VoiceFocusModelName {
    if (
      joinInfo &&
      joinInfo.Meeting?.MeetingFeatures?.Audio?.EchoReduction === "AVAILABLE"
    ) {
      return voiceFocusName("ns_es");
    }
    return voiceFocusName("default");
  }

  const vfConfigValue = {
    spec: { name: getVoiceFocusSpecName() },
    createMeetingResponse: joinInfo
  };

  const getMeetingProviderWrapperWithVF = (children: React.ReactNode) => {
    return (
      <VoiceFocusProvider {...vfConfigValue}>
        <MeetingProviderWithDeviceReplacement>
          {children}
        </MeetingProviderWithDeviceReplacement>
      </VoiceFocusProvider>
    );
  };

  const getWrapperWithVideoFilter = (children: React.ReactNode) => {
    let filterCPUUtilization = parseInt(videoTransformCpuUtilization, 10);
    if (!filterCPUUtilization) {
      filterCPUUtilization = 40;
    }

    return (
      <BackgroundBlurProvider options={{ filterCPUUtilization }}>
        <BackgroundReplacementProvider
          options={{ imageBlob, filterCPUUtilization }}
        >
          {children}
        </BackgroundReplacementProvider>
      </BackgroundBlurProvider>
    );
  };

  const getMeetingProvider = (children: React.ReactNode) => {
    return <MeetingProvider>{children}</MeetingProvider>;
  };

  const getMeetingProviderWithFeatures = (): React.ReactNode => {
    let children = getMeetingProviderWrapper();

    if (isFilterEnabled) {
      children = getWrapperWithVideoFilter(children);
    }
    if (isWebAudioEnabled) {
      children = getMeetingProviderWrapperWithVF(children);
    } else {
      children = getMeetingProvider(children);
    }
    return children;
  };

  return (
    <>
      {imageBlob === undefined ? (
        <div>Loading Assets</div>
      ) : (
        getMeetingProviderWithFeatures()
      )}
    </>
  );
};

export default MeetingProviderWrapper;
