import React, { useState } from "react";
import { AppCard } from "components/card";
import { Disclaimer } from "./components/disclaimer";
import { Eula } from "./components/eula";
import { Licenses } from "./components/licenses";
import { PrivacyPolicy } from "./components/privacy";
import { TermsAndConditions } from "./components/termsAndConditions";
import CaregemLogo from "./icons/caregemLogo";
import {
  InfoContainer,
  SidebarPlaceholder,
  TabInfoContentContainer,
  TabTitleText,
  TabInfoContainer,
  LicenseItemContainer,
  AboutLogoContainer,
  AboutLogoTextContainer,
  AboutLogoTextSubtitle,
  AboutLogoTextTitle
} from "./style";

enum SidebarPlaceholderValues {
  disclaimer = "Disclaimer",
  termsAndConditions = "Terms & Conditions",
  eula = "EULA",
  privacyPolicy = "Privacy Policy",
  licenses = "Licenses",
  about = "About"
}

const getTabBackground = (
  selectedTab: SidebarPlaceholderValues,
  tabOption: SidebarPlaceholderValues
) => (selectedTab === tabOption ? "skyblue" : "transparent");

const TabContentObject: Record<SidebarPlaceholderValues, JSX.Element> = {
  Disclaimer: (
    <AppCard cardWidth="100%" cardHeight="100%">
      <TabInfoContentContainer>
        <Disclaimer />
      </TabInfoContentContainer>
    </AppCard>
  ),
  "Terms & Conditions": (
    <AppCard cardWidth="100%" cardHeight="100%">
      <TabInfoContentContainer>
        <TermsAndConditions />
      </TabInfoContentContainer>
    </AppCard>
  ),
  EULA: (
    <AppCard cardWidth="100%" cardHeight="100%">
      <TabInfoContentContainer>
        <Eula />
      </TabInfoContentContainer>
    </AppCard>
  ),
  "Privacy Policy": (
    <AppCard cardWidth="100%" cardHeight="100%">
      <TabInfoContentContainer>
        <PrivacyPolicy />
      </TabInfoContentContainer>
    </AppCard>
  ),
  Licenses: (
    <AppCard cardWidth="100%" cardHeight="100%">
      <TabInfoContentContainer>
        <Licenses />
      </TabInfoContentContainer>
    </AppCard>
  ),
  About: (
    <AppCard cardWidth="100%" cardHeight="100%">
      <TabInfoContentContainer>
        <AppCard cardHeight="320px" cardWidth="320px">
          <LicenseItemContainer>
            <AboutLogoContainer>
              <CaregemLogo style={{ marginLeft: 30, paddingTop: 20 }} />
            </AboutLogoContainer>
            <AboutLogoTextContainer>
              <AboutLogoTextTitle>CareGEM</AboutLogoTextTitle>
              <AboutLogoTextSubtitle>Version v3.0.1</AboutLogoTextSubtitle>
            </AboutLogoTextContainer>
          </LicenseItemContainer>
        </AppCard>
      </TabInfoContentContainer>
    </AppCard>
  )
};

export const InfoPage: React.FC = () => {
  const [tab, setTab] = useState<SidebarPlaceholderValues>(
    SidebarPlaceholderValues.disclaimer
  );
  return (
    <>
      <InfoContainer>
        <SidebarPlaceholder>
          <div
            style={{
              padding: 10,
              backgroundColor: getTabBackground(
                tab,
                SidebarPlaceholderValues.disclaimer
              ),
              borderRadius: 6
            }}
            onClick={() => setTab(SidebarPlaceholderValues.disclaimer)}
          >
            {SidebarPlaceholderValues.disclaimer}
          </div>
          <div
            style={{
              padding: 10,
              backgroundColor: getTabBackground(
                tab,
                SidebarPlaceholderValues.termsAndConditions
              ),
              borderRadius: 6
            }}
            onClick={() => setTab(SidebarPlaceholderValues.termsAndConditions)}
          >
            {SidebarPlaceholderValues.termsAndConditions}
          </div>
          <div
            style={{
              padding: 10,
              backgroundColor: getTabBackground(
                tab,
                SidebarPlaceholderValues.eula
              ),
              borderRadius: 6
            }}
            onClick={() => setTab(SidebarPlaceholderValues.eula)}
          >
            {SidebarPlaceholderValues.eula}
          </div>
          <div
            style={{
              padding: 10,
              backgroundColor: getTabBackground(
                tab,
                SidebarPlaceholderValues.privacyPolicy
              ),
              borderRadius: 6
            }}
            onClick={() => setTab(SidebarPlaceholderValues.privacyPolicy)}
          >
            {SidebarPlaceholderValues.privacyPolicy}
          </div>
          <div
            style={{
              padding: 10,
              backgroundColor: getTabBackground(
                tab,
                SidebarPlaceholderValues.licenses
              ),
              borderRadius: 6
            }}
            onClick={() => setTab(SidebarPlaceholderValues.licenses)}
          >
            {SidebarPlaceholderValues.licenses}
          </div>
          <div
            style={{
              padding: 10,
              backgroundColor: getTabBackground(
                tab,
                SidebarPlaceholderValues.about
              ),
              borderRadius: 6
            }}
            onClick={() => setTab(SidebarPlaceholderValues.about)}
          >
            {SidebarPlaceholderValues.about}
          </div>
        </SidebarPlaceholder>
        <TabInfoContainer>
          <TabTitleText>{tab}</TabTitleText>
          {TabContentObject[tab]}
        </TabInfoContainer>
      </InfoContainer>
    </>
  );
};
