import Space from "antd/lib/space";
import Form from "antd/lib/form";
import React from "react";
import styled from "styled-components";

import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { SurveyInput } from "pages/symptoms/surveyForms/surveyInput";
import { Platform } from "constants/roles";

interface OtherSectionProps {
  readonly currentPage: number;
  setOtherInput(input: string): void;
  readonly otherInput: string;
}

export const OtherSection: React.FC<OtherSectionProps> = ({
  currentPage,
  otherInput,
  setOtherInput
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  return (
    <>
      {currentPage === 16 && (
        <Space direction="vertical" size={20}>
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{ width: "100%", padding: "10px" }}
              >
                <DisclaimerTitle>Other</DisclaimerTitle>
                <CardTitle>
                  Any other Symptoms
                  <AsteriskStyle> *</AsteriskStyle>
                </CardTitle>
                <FormItemStyle
                  name="Any other symptoms"
                  label=""
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  initialValue={""}
                >
                  <SurveyInput
                    value={otherInput}
                    placeholder={"Your Answer"}
                    onChange={e => {
                      setOtherInput(e.target.value);
                    }}
                    style={{ width: "70%" }}
                  />
                </FormItemStyle>
              </Space>
            </AppCard>
          </Space>
        </Space>
      )}
    </>
  );
};

const DisclaimerTitle = styled.div`
  width: 100%;
  padding: 8px 8px 8px 20px;
  margin-left: -25px;
  background: ${Colors.DodgerBlue};
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 8px 8px 0px 0px;
  color: white;
  position: absolute;
  top: 0;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
