import React, { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window"; // Import react-window for virtualization

import Space from "antd/lib/space";
import Checkbox from "antd/lib/checkbox";
import Menu from "antd/lib/menu";

import styled from "styled-components";
import { Colors } from "helpers/colors";
import { CheckboxMenuCommonInterface } from "sharedTypes";

interface CheckboxMenuProps {
  readonly menuItems: CheckboxMenuCommonInterface[];
  getCheckedItems(
    checkedItems: {
      desc: string;
      code: string;
      detail_desc: string;
      checked?: boolean;
    }[]
  ): void;
  readonly applied?: boolean;
  setApplied?(applied: boolean): void;
}

export const CheckboxMenuComponent: React.FC<CheckboxMenuProps> = ({
  menuItems,
  getCheckedItems,
  setApplied
}) => {
  const [checkedItems, setCheckedItems] = useState<
    CheckboxMenuCommonInterface[]
  >([...menuItems]);

  // Sync local state when menuItems prop changes, only if it has actually changed
  useEffect(() => {
    if (JSON.stringify(checkedItems) !== JSON.stringify(menuItems)) {
      setCheckedItems([...menuItems]);
    }
  }, [menuItems]);

  useEffect(() => {
    getCheckedItems(checkedItems);
  }, [checkedItems]);

  const onSelectMenuItem = (item: string) => {
    if (setApplied) {
      setApplied(false);
    }
    const updatedItems = checkedItems.map(checkedItem =>
      checkedItem.desc === item
        ? { ...checkedItem, checked: !checkedItem.checked }
        : checkedItem
    );
    setCheckedItems(updatedItems);
  };

  // Row renderer for react-window's FixedSizeList
  const renderRow = ({
    index,
    style
  }: {
    index: number;
    style: React.CSSProperties;
  }) => {
    const item = checkedItems[index];
    return (
      <Menu.Item
        key={index}
        style={style} // Set the style for each row to ensure proper virtualization
        onClick={() => {
          onSelectMenuItem(item.desc);
        }}
      >
        <Space size={10}>
          <CheckboxStyled checked={item.checked} />
          {item.checked ? (
            <span style={{ fontWeight: "600" }}>{item.desc}</span>
          ) : (
            item.desc
          )}
        </Space>
      </Menu.Item>
    );
  };

  return (
    <MenuStyled>
      {/* react-window FixedSizeList for virtualization */}
      <List
        height={300} // Height of the container
        itemCount={checkedItems.length} // Total items
        itemSize={40} // Height of each row
        width={"100%"} // Full width of the container
      >
        {renderRow}
      </List>
    </MenuStyled>
  );
};

const CheckboxStyled = styled(Checkbox)`
  pointer-events: none;
`;

const MenuStyled = styled(Menu)`
  width: 500px;
  min-height: 312px;
  padding: 15px 6px 12px 6px;
  color: ${Colors.Grey};
  border-right: none;

  .ant-menu-item {
    margin-top: -5px !important;
    :hover {
      border-radius: 10px;
      transition: 0s;
      color: ${Colors.Black};
      overflow: visible;
      height: auto;
    }
  }

  .ant-menu-item-selected {
    border-radius: 10px;
    transition: 0s;
    color: ${Colors.Black};
  }
`;
