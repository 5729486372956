import Radio, { RadioChangeEvent } from "antd/lib/radio";
import React, { useState } from "react";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppTextArea } from "components/inputs";
import {
  FeedbackCardButtonContainer,
  FeedbackCardContainer,
  FeedbackModalContainer,
  FeedbackModalTitleContainer,
  FeedbackOverflowContainer
} from "./style";

export const FeedbackForm: React.FC = () => {
  const [areChangesMade, setAreChangesMade] = useState<boolean>(true);
  const [reason, setReason] = useState<string>("");
  const [change, setChange] = useState<string>("");
  const [otherAction, setOtherAction] = useState<string>("");

  const onAreChangesMadeRadioChange = (event: RadioChangeEvent) => {
    setAreChangesMade(event.target.value);
    if (event.target.value) {
      setReason("");
      setChange("");
      setOtherAction("");
    }
  };

  const onReasonChange = (event: RadioChangeEvent) =>
    setReason(event.target.value);

  const onChangeValueChange = (event: RadioChangeEvent) =>
    setChange(event.target.value);

  const onOtherActionChange = (value: string) => setOtherAction(value);

  const onModalFormCancel = () => {
    setAreChangesMade(true);
    setReason("");
    setChange("");
    setOtherAction("");
  };

  return (
    <FeedbackCardContainer>
      <div>
        <div>Did you make the changes as suggested?</div>
        <Radio.Group
          value={areChangesMade}
          onChange={onAreChangesMadeRadioChange}
          style={{
            width: "100%",
            marginTop: 10,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            marginBottom: 10
          }}
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </div>
      <FeedbackCardButtonContainer>
        <AppButton
          buttonContent="Submit"
          style={{ height: 44, width: 100 }}
          type={ButtonType.Primary}
          onClick={() => null}
        />
      </FeedbackCardButtonContainer>
      {!areChangesMade && (
        <FeedbackModalContainer>
          <FeedbackModalTitleContainer>Feedback</FeedbackModalTitleContainer>
          <FeedbackOverflowContainer>
            <div>Did you make the changes as suggested?</div>
            <Radio.Group
              value={areChangesMade}
              onChange={onAreChangesMadeRadioChange}
              style={{
                width: "100%",
                marginTop: 10,
                display: "grid",
                rowGap: 5,
                gridTemplateColumns: "1fr 1fr",
                marginBottom: 10
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
            <div>
              Please select reason for not following system recommendation:
            </div>
            <Radio.Group
              value={reason}
              onChange={onReasonChange}
              style={{
                width: "100%",
                marginTop: 10,
                display: "grid",
                rowGap: 5,
                gridTemplateColumns: "1fr 1fr",
                marginBottom: 10
              }}
            >
              <Radio value="Patient Symptoms">Patient Symptoms</Radio>
              <Radio value="Clinical Judgement">Clinical Judgement</Radio>
              <Radio value="Patient Refusal">Patient Refusal</Radio>
            </Radio.Group>
            <div>What changes did you make?</div>
            <Radio.Group
              value={change}
              onChange={onChangeValueChange}
              style={{
                width: "100%",
                marginTop: 10,
                display: "grid",
                rowGap: 5,
                gridTemplateColumns: "1fr 1fr",
                marginBottom: 10
              }}
            >
              <Radio value="Increase Target Weight">
                Increase Target Weight
              </Radio>
              <Radio value="Descrease Target Weight">
                Descrease Target Weight
              </Radio>
              <Radio value="No Change in Target Weight">
                No Change in Target Weight
              </Radio>
              <Radio value="Increased UF / Dialysis Time">
                Increased UF / Dialysis Time
              </Radio>
              <Radio value="No Change to UF / Dialysis Time">
                No Change to UF / Dialysis Time
              </Radio>
            </Radio.Group>
            <AppTextArea
              label="Any other action or intervention?"
              labelStyles={{ position: "relative", top: 20 }}
              placeholder="Enter Text"
              value={otherAction}
              onChange={onOtherActionChange}
            />
          </FeedbackOverflowContainer>
          <FeedbackCardButtonContainer>
            <AppButton
              type={ButtonType.Secondary}
              buttonContent="Cancel"
              style={{ marginRight: 12, width: 120 }}
              onClick={onModalFormCancel}
            />
            <AppButton
              type={ButtonType.Primary}
              style={{ marginRight: 12, width: 120 }}
              buttonContent="Submit"
              onClick={() => null}
            />
          </FeedbackCardButtonContainer>
        </FeedbackModalContainer>
      )}
    </FeedbackCardContainer>
  );
};
