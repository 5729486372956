import React, { useContext, useEffect, useState } from "react";
import "./deviceAssignmentPage.css";
import { UserContext, UserContextState } from "contextApis/userContext";

interface DeviceAssignmentFormProps {
  pName: string;
  pDob: string | undefined;
  sign: string | undefined;
  // relative: string | null;
  pEmail: string | undefined;
  signer: string | null;
  clientLogo: string | null;
  consentName: string | null;
  orgName: string | null;
  deviceId: string | null;
  userInitial: string;
}

const DeviceAssignmentForm: React.FC<DeviceAssignmentFormProps> = ({
  pName,
  pDob,
  sign,
  // relative,
  pEmail,
  signer,
  clientLogo,
  consentName,
  orgName,
  deviceId,
  userInitial
}) => {
  const userContext = useContext<UserContextState>(UserContext);
  const [patientInitial, setPatientInitial] = useState(userInitial);

  const date = new Date();

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const formattedTodaysDate = `${month}/${day}/${year}`;

  useEffect(() => {
    if (sign) {
      userContext.setPdfByteString(sign);
    } else {
      userContext.setPdfByteString(patientInitial);
    }
    consentName && userContext.setConsentName(consentName);
    pName && userContext.setSignerName(pName);
  }, [sign, patientInitial, consentName]);

  return (
    <div className="form_container">
      <div className="header">
        <img
          className="logo-left"
          src={process.env["REACT_APP_ASSET_ENDPOINT"] + "" + clientLogo}
          alt="NephrologySpeaciallistsLogo"
          width={300}
        />
        <img
          className="logo-right"
          src={
            process.env["REACT_APP_ASSET_ENDPOINT"] +
            "logo/CareGemHealthLogo.png"
          }
          alt="CareGemHealthLogo"
          width={180}
        />
      </div>
      <div style={{ marginTop: 40 }}>
        <p style={{ fontWeight: 650, fontSize: 16 }}>
          RPM Device Acknowledgment
        </p>
        <p>
          I confirm I have received the device for the Remote Monitoring Program
          I have consented to on {formattedTodaysDate}. Device IMEI ID.{" "}
          {deviceId ? deviceId : "As Assigned"}
        </p>
      </div>
      <div className="leftAlign">
        <p className="paragraph">
          Signature of Patient / Authorized Person :
          <span className="patientData" style={{ marginLeft: 18 }}>
            {!sign ? (
              <>{userInitial}</>
            ) : (
              <img
                src={sign}
                width={70}
                height={35}
                style={{ marginBottom: 25 }}
                alt=""
              />
            )}
          </span>
        </p>
        <p className="paragraph" style={{ marginTop: sign ? -20 : "" }}>
          Patient Name:
          <span className="patientData" style={{ marginLeft: 18 }}>
            {pName}
          </span>
        </p>
        <p className="paragraph">
          Date:
          <span className="patientData" style={{ marginLeft: 18 }}>
            {formattedTodaysDate}
          </span>
          (MM/DD/YYYY)
        </p>
        <p className="paragraph">
          Consent taken digitally by CareGem on behalf of{" "}
          {orgName?.toUpperCase()}
        </p>
      </div>
    </div>
  );
};

export default DeviceAssignmentForm;
