import Skeleton from "antd/lib/skeleton";
import moment from "moment";
import React from "react";

import { AppAvatar } from "components/avatar";
import { AppCard } from "components/card";
import { startCase } from "helpers/utils";
import { PatientProfileData } from "sharedTypes";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import {
  AvatarContainer,
  AvatarNameText,
  AvatarTextContainer,
  ContactNumberContainer,
  ContactNumberGap,
  Divider,
  GridItem,
  GridItemGap,
  GridItemTitle,
  GridItemValue,
  PatientProfileTitleText,
  ProfileContentContainer,
  ProfileHeaderContainer
} from "./styles";

interface PatientProfilePageInterface {
  readonly data: PatientProfileData | undefined;
  readonly loading: boolean;
}

export const PatientProfilePage: React.FC<PatientProfilePageInterface> = ({
  data,
  loading
}) => {
  return (
    <>
      <CommonPatientExtensionItems />
      <PatientProfileTitleText>Patient Profile</PatientProfileTitleText>
      <AppCard cardWidth="100%" cardHeight="auto">
        <ProfileHeaderContainer>
          <AvatarContainer>
            <AppAvatar imageSrc={data?.picture || ""} size={80} />
            <AvatarTextContainer>
              {loading ? (
                <Skeleton
                  loading={loading}
                  active={loading}
                  avatar={false}
                  paragraph={false}
                  style={{ width: 100 }}
                />
              ) : (
                <AvatarNameText>{data?.name || ""}</AvatarNameText>
              )}
            </AvatarTextContainer>
          </AvatarContainer>
        </ProfileHeaderContainer>
        <Divider />
        <ProfileContentContainer data-testid="patient-profile-info">
          <GridItem>
            <GridItemTitle topPadding>Date of Birth</GridItemTitle>
            {loading ? (
              <Skeleton
                loading={loading}
                active={loading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              <GridItemValue bold>
                {data?.dob
                  ? moment(data?.dob, "MM-DD-YYYY").format("MMM DD, YYYY")
                  : ""}
              </GridItemValue>
            )}
            <GridItemGap />
            <GridItemTitle>Contact Number</GridItemTitle>
            <ContactNumberContainer>
              {loading ? (
                <Skeleton
                  loading={loading}
                  active={loading}
                  avatar={false}
                  paragraph={{ rows: 1 }}
                  style={{ width: 100 }}
                />
              ) : (
                data?.phoneNumbers.map((phoneNumber, index) => (
                  <div key={phoneNumber.number}>
                    <GridItemTitle>{phoneNumber.title}</GridItemTitle>
                    <GridItemValue>{phoneNumber.number}</GridItemValue>
                    {index !== data.phoneNumbers.length - 1 && (
                      <ContactNumberGap />
                    )}
                  </div>
                ))
              )}
            </ContactNumberContainer>
          </GridItem>
          <GridItem leftBorder>
            <GridItemTitle topPadding>Email Address</GridItemTitle>
            {loading ? (
              <Skeleton
                loading={loading}
                active={loading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              <GridItemValue bold>{data?.email || ""}</GridItemValue>
            )}
            <GridItemGap />
            <GridItemTitle>Home Address</GridItemTitle>
            {loading ? (
              <Skeleton
                loading={loading}
                active={loading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              <GridItemValue bold>
                {`${data?.home_addr_1 || ""}, ${data?.address_city || ""}, ${
                  data?.state || ""
                }, ${data?.address_zip || ""}`}
              </GridItemValue>
            )}
            <GridItemGap />
            <GridItemTitle>Role</GridItemTitle>
            {loading ? (
              <Skeleton
                loading={loading}
                active={loading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              <GridItemValue bold>{startCase(data?.role || "")}</GridItemValue>
            )}
            <GridItemGap />
            <GridItemTitle>Gender</GridItemTitle>
            {loading ? (
              <Skeleton
                loading={loading}
                active={loading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              <GridItemValue bold>
                {startCase(data?.gender || "")}
              </GridItemValue>
            )}
          </GridItem>
        </ProfileContentContainer>
      </AppCard>
    </>
  );
};
