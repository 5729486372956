// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from "react";

import {
  Navbar,
  NavbarHeader,
  NavbarItem,
  Attendees,
  Flex,
  ZoomIn,
  ZoomOut,
  useContentShareState
} from "amazon-chime-sdk-component-library-react";

import { useNavigation } from "components/meetings/providers/NavigationProvider";
import { useAWSMeetingState } from "components/meetings/providers/AWSMeetingStateProvider";
import { Layout } from "components/meetings/types";
import { useVideoTileGridControl } from "components/meetings/providers/VideoTileGridProvider";

const Navigation: React.FC = () => {
  const { toggleRoster, closeNavbar } = useNavigation();
  const { layout, priorityBasedPolicy } = useAWSMeetingState();
  const { sharingAttendeeId } = useContentShareState();
  const { zoomIn, zoomOut } = useVideoTileGridControl();

  return (
    <Navbar className="nav" flexDirection="column" container>
      <NavbarHeader title="Navigation" onClose={closeNavbar} />
      <Flex css="margin-top: 0rem;">
        <NavbarItem
          icon={<Attendees />}
          onClick={toggleRoster}
          label="Attendees"
        />
        {layout === Layout.Gallery && priorityBasedPolicy && (
          <>
            <NavbarItem
              icon={<ZoomIn />}
              onClick={zoomIn}
              label="Zoom In"
              disabled={!!sharingAttendeeId}
            />
            <NavbarItem icon={<ZoomOut />} onClick={zoomOut} label="Zoom Out" />
          </>
        )}
      </Flex>
    </Navbar>
  );
};

export default Navigation;
