import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Row,
  Spin,
  Table,
  Tooltip,
  message
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import { MessageRow } from "components/dropDownBox/customStyles";
import { AppSelect } from "components/inputs";
import { AppModal } from "components/modal";
import { SearchBox } from "components/searchBox";
import {
  CaregiverRoutesRef,
  PatientsRoutesRef,
  ProvidersRoutesRef
} from "constants/routes";
import { useSurveyContext } from "contextApis/surveyContext";
import { Colors } from "helpers/colors";
import { offset } from "highcharts";
import _ from "lodash";
import moment from "moment";
import { TitleCol } from "pages/allNotifications/style";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useNavigation } from "react-router";
import styled from "styled-components";
import { style } from "styled-system";

export const SurveyReportPage: React.FC = () => {
  const [surveyData, setSurveyData] = useState<any>(null);
  const [activationLevel, setActivationLevel] = useState("");
  const [treatmentActions, setTreatmentActions] = useState("");
  const [hasMouseEntered, setHasMouseEntered] = useState(false);
  const [PamModalOpen, setPamModalOpen] = useState<boolean>(false);
  const [tooltipContent, setTooltipContent] = useState<React.ReactNode | null>(
    null
  );
  const [isPamNotApplicable, seIsPamNotApplicable] = useState<any>(false);
  const [isAllAnswerSame, setISAllAnswerSame] = useState<any>(false);
  const [pamSurveys, setPamSurveys] = useState<any>();
  const [filteredPamSurveys, setFilteredPamSurveys] = useState<any>();
  const [pamSurveyScore, setPamSurveyScore] = useState<any>();
  const [surveyId, setSurveyId] = useState<any>();
  const [surveys, setSurveys] = useState<any>();
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [searchPatient, setSearchPatient] = useState<string>("");
  const [scrollBottom, setScrollBottom] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const scrollContainerRef = useRef(null);
  const [updatedList, setUpdatedList] = useState<any>([]);
  const [status, setStatus] = useState<string>("");
  const currentYear = new Date().getFullYear();
  const Years: any = [{ label: "All", value: "" }];

  const handleScroll = () => {
    const nativeElement: any = scrollContainerRef.current;
    const scrollPosition = Math.round(nativeElement.scrollTop);
    const isScrollBottom =
      nativeElement.clientHeight + scrollPosition + 50 >=
      nativeElement.scrollHeight;
    setScrollBottom(isScrollBottom);
    if (isScrollBottom) {
      console.log("Reached near the bottom of the container");
    }
  };

  const searchValue = "";
  const patientType = "";
  const selectedDiagnosesItemCodes = "";
  const vbcPlan = "Any";
  const loadUpdatedData = true;
  const pageNumbers = 0;
  const offset = 0;

  const {
    isLoading: isLoadingPatientList,
    data: patientData,
    error: isPatientListError,
    refetch: getPatientList,
    isFetching: isPatientListRefetchLoading
  } = useQuery<any>(
    ["all-patients-list"],
    async () => {
      return await createApiClient().getAllPatientsList(
        searchValue,
        patientType,
        selectedDiagnosesItemCodes,
        vbcPlan,
        loadUpdatedData,
        pageNumbers,
        offset
      );
    },
    {
      enabled: true
    }
  );

  const extractIds = (dataArray: any) => {
    return dataArray?.map((item: any) => item.id);
  };

  const [isMyPatient, setIsMyPatient] = useState<any[]>([]);

  useEffect(() => {
    const ids = extractIds(patientData);
    ids && setIsMyPatient(ids);
  }, [patientData]);

  const idsContain = (idToCheck: any): boolean => {
    return isMyPatient?.includes(idToCheck);
  };

  useEffect(() => {
    const scrollContainer: any = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [scrollContainerRef]);

  useEffect(() => {
    if (scrollBottom) {
      if (surveyList?.length === 0) return;
      setPageNumber((prev: any) => prev + 1);
    }
  }, [scrollBottom]);

  const tooltipInnerStyling = {
    borderRadius: "10px",
    backgroundColor: "white",
    color: "black",
    fontSize: 11
  };

  const [checkedItems, setCheckedItems] = useState<
    { q_id: number; action_response: string }[]
  >([]);

  const handleCellClick = (record: any, columnName: string) => {
    const isMyPatient = idsContain(String(record?.patient_internal_id));
    if (!isMyPatient) {
      // if (record?.score == null || record?.score == 0 || record?.score == -1)
      message.info(
        "Survey is not accessible as you are not part of Patients Network."
      );
      // return;
    } else {
      if (columnName !== "score") {
        // if (record.status === 1) return;
        handleRowClick(record);
      }
    }
  };

  const columns = [
    {
      dataIndex: "name",
      key: "name",
      title: "Name of Survey",
      width: "10%",
      onCell: (record: any) => {
        return {
          onClick: () => handleCellClick(record, "name")
        };
      },
      render: (name: string) => {
        return (
          <MessageRow
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              padding: "10px",
              cursor: "pointer"
            }}
            fontSize={"14px"}
          >
            {name}
          </MessageRow>
        );
      }
    },
    {
      dataIndex: "patient_name",
      key: "patient_name",
      title: "Patient Name",
      width: "20%",
      onCell: (record: any) => {
        return {
          onClick: () => handleCellClick(record, "patient_name")
        };
      },
      render: (patient_name: string) => {
        return (
          <MessageRow
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              padding: "10px",
              cursor: "pointer"
            }}
            fontSize={"14px"}
          >
            {patient_name}
          </MessageRow>
        );
      }
    },
    {
      dataIndex: "created_at",
      key: "dateAssigned",
      title: "Date Assigned",
      onCell: (record: any) => {
        return {
          onClick: () => handleCellClick(record, "created_at")
        };
      },
      render: (created_at: string) => {
        return (
          <MessageUpperRow
            style={{ fontWeight: "bold", marginLeft: "2px", cursor: "pointer" }}
          >
            {moment(created_at).format("MMM DD, YYYY")}
          </MessageUpperRow>
        );
      },
      width: "15%"
    },
    {
      dataIndex: "completed_at",
      key: "dateCompleted",
      title: "Date Completed",
      onCell: (record: any) => {
        return {
          onClick: () => handleCellClick(record, "completed_at")
        };
      },
      render: (completed_at: string) => {
        if (completed_at && completed_at != "0000-00-00 00:00:00") {
          return (
            <MessageUpperRow
              style={{
                fontWeight: "bold",
                marginLeft: "2px",
                cursor: "pointer"
              }}
            >
              {moment(completed_at).format("MMM DD, YYYY")}
            </MessageUpperRow>
          );
        }

        return <span style={{ cursor: "pointer" }}>N/A</span>;
      },
      width: "15%"
    },
    {
      dataIndex: "upcoming_pam",
      key: "upcomingDate",
      title: "Upcoming Date",
      onCell: (record: any) => {
        return {
          onClick: () => handleCellClick(record, "upcoming_pam")
        };
      },
      render: (upcoming_pam: string) => {
        if (upcoming_pam && upcoming_pam != "0000-00-00 00:00:00") {
          return (
            <MessageUpperRow
              style={{
                fontWeight: "bold",
                marginLeft: "2px",
                cursor: "pointer"
              }}
            >
              {moment(upcoming_pam).format("MMM DD, YYYY")}
            </MessageUpperRow>
          );
        }
        return <span style={{ cursor: "pointer" }}>N/A</span>;
      },
      width: "15%"
    },
    {
      dataIndex: "status",
      key: "status",
      title: "Status",
      width: "13%",
      onCell: (record: any) => {
        return {
          onClick: () => handleCellClick(record, "status")
        };
      },
      render: (status: number, record: any) => {
        const { review_date } = record;
        if (status === 1) {
          return (
            <StatusDiv style={{ cursor: "pointer" }}>
              <MessageUpperRow
                style={{ fontWeight: "bold", marginLeft: "2px" }}
              >
                Completed
              </MessageUpperRow>
              {sessionStorage.getItem("userRole") == "patient" &&
                review_date != null && (
                  <CheckOutlined
                    style={{ color: "#32CD32", fontSize: 15, paddingBottom: 3 }}
                  />
                )}
            </StatusDiv>
          );
        }
        if (status === 0) {
          return (
            <StatusDiv style={{ cursor: "pointer" }}>
              <MessageUpperRow
                style={{
                  fontWeight: "bold",
                  marginLeft: "2px",
                  cursor: "pointer"
                }}
              >
                Pending
              </MessageUpperRow>
            </StatusDiv>
          );
        }
        if (status === 2) {
          return (
            <StatusDiv style={{ cursor: "pointer" }}>
              <MessageUpperRow
                style={{
                  fontWeight: "bold",
                  marginLeft: "2px",
                  cursor: "pointer"
                }}
              >
                Draft
              </MessageUpperRow>
            </StatusDiv>
          );
        }
      }
    },
    {
      dataIndex: "score",
      key: "score",
      title: "Score",
      width: "10%",
      onCell: (record: any) => {
        return {
          onClick: () => handleCellClick(record, "score")
        };
      },
      render: (score: number, record: any) => {
        const isNotApplicable = record?.responses?.response?.filter(
          (item: any) => item.answer == "N/A"
        );
        const isSameAnswerSame = score === -1;

        const isApplicable = isNotApplicable?.length >= 3;
        // console.log("isNotApplicable", isNotApplicable?.length >= 3);

        const filterPam = record?.responses?.response?.filter(
          (item: any) => item.issue !== ""
        );
        const isPamSubmitted = filterPam?.filter(
          (item: any) => item.action_response === true
        );

        const isPamCompleted = isPamSubmitted?.length === filterPam?.length;

        const isPAMSurvey = record.name === "PAM";
        return (
          <>
            <MessageRow
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10%"
              }}
              fontSize={"14px"}
            >
              {score == null || score == 0 || score == -1 ? (
                "N/A"
              ) : (
                <div
                  onMouseEnter={() => {
                    handleRowHover(record);
                    setSurveyData(record);
                    setHasMouseEntered(true);
                  }}
                  onMouseLeave={() => {
                    setHasMouseEntered(false);
                  }}
                >
                  <Tooltip
                    title={tooltipContent}
                    placement="left"
                    arrowPointAtCenter={true}
                    overlayInnerStyle={tooltipInnerStyling}
                  >
                    {score}
                  </Tooltip>
                </div>
              )}

              {score !== null && isPAMSurvey && (
                <ActionSpan style={{ marginLeft: 12, zIndex: 999 }}>
                  <InfoCircleOutlined
                    style={{
                      fontSize: "18px",
                      marginTop: 1.8,
                      color: isSameAnswerSame
                        ? Colors.Red
                        : isPamCompleted
                        ? "rgb(124, 186, 91)"
                        : Colors.Red
                    }}
                    onClick={() => {
                      setPamSurveyScore(score);
                      setFilteredPamSurveys(filterPam);
                      setSurveyId(record.id);
                      setPamModalOpen(!PamModalOpen);
                      seIsPamNotApplicable(isApplicable);
                      setISAllAnswerSame(isSameAnswerSame);
                    }}
                  />
                </ActionSpan>
              )}
            </MessageRow>
          </>
        );
      }
    }
  ];

  const pamColumns: ColumnsType<any> = [
    {
      dataIndex: "issue",
      key: "issue",
      title: "Issue",
      width: "30%",
      render: (issue: string) => {
        return (
          <MessageRow style={{ marginLeft: "10px" }} fontSize={"14px"}>
            {issue}
          </MessageRow>
        );
      }
    },
    {
      dataIndex: "action_item",
      key: "action_item",
      title: "Action Items",
      width: "45%",
      render: (action_item: string) => {
        return (
          <MessageRow
            style={{ fontWeight: "bold", marginLeft: "10px" }}
            fontSize={"14px"}
          >
            {action_item}
          </MessageRow>
        );
      }
    },
    {
      dataIndex: "completed",
      key: "completed",
      title: "Completed",
      align: "center",
      width: "12%",
      render: (_: any, record: any) => {
        const { q_id, action_response } = record;
        const isChecked = checkedItems.some(
          item => item.q_id === q_id && item.action_response
        );
        return (
          <div style={{ alignItems: "center" }}>
            <Checkbox
              checked={action_response ? action_response : isChecked}
              disabled
              // onChange={e => handleCheckboxChange(e.target.checked, q_id)}
            />
          </div>
        );
      }
    },
    {
      dataIndex: "date",
      key: "date",
      title: "Date",
      align: "center",
      width: "20%",
      render: (_: any, record: any) => {
        if (record.action_update_time) {
          return (
            <div style={{ alignItems: "left" }}>
              {moment(record.action_update_time).format("MMM, DD  YYYY")}
            </div>
          );
        } else {
          return <div>- -</div>;
        }
      }
    }
  ];

  useEffect(() => {
    if (hasMouseEntered) {
      handleRowHover(surveyData);
    }
  }, [hasMouseEntered]);

  const handleRowHover = (record: any) => {
    let content: any;
    const score = record?.score;
    if (record?.name == "PAM") {
      if (score <= 47) {
        setActivationLevel("Level 1 – Not believing activation is important");
        setTreatmentActions("TBA");
      }
      if (score >= 47.1 && score <= 55.1) {
        setActivationLevel(
          "Level 2 – Lack of knowledge and confidence to take action"
        );
        setTreatmentActions("TBA");
      }
      if (score >= 55.2 && score <= 67.0) {
        setActivationLevel("Level 3 – Beginning to take action");
        setTreatmentActions("TBA");
      }
      if (score >= 67.1) {
        setActivationLevel("Level 4 - Taking action");
        setTreatmentActions("TBA");
      }
      content = (
        <div>
          <p>
            Activation Level: <b>{activationLevel}</b>
          </p>
          <p>
            Proposed Treatment Actions: <b>{treatmentActions}</b>
          </p>
        </div>
      );
    } else if (record?.name == "PHQ9") {
      if (score >= 0 && score <= 4) {
        setActivationLevel("None-minimal");
        setTreatmentActions("None");
      }
      if (score >= 5 && score <= 9) {
        setActivationLevel("Mild");
        setTreatmentActions("Watchful waiting; repeat PHQ-9 at follow-up");
      }
      if (score >= 10 && score <= 14) {
        setActivationLevel("Moderate");
        setTreatmentActions(
          "Treatment plan, considering counseling, follow-up and/or pharmacotherapy"
        );
      }
      if (score >= 15 && score <= 19) {
        setActivationLevel("Moderately Severe");
        setTreatmentActions(
          "Active treatment with pharmacotherapy and/or psychotherapy"
        );
      }
      if (score >= 20 && score <= 27) {
        setActivationLevel("Severe");
        setTreatmentActions(
          "Immediate initiation of pharmacotherapy and, if severe impairment or poor response to therapy, expedited referral to a mental health specialist for psychotherapy and/or collaborative management"
        );
      }
      content = (
        <div>
          <p>Scoring based on: Articles </p>
          <p>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1495268/</p>
          <p>
            Depression Severity: <b>{activationLevel}</b>
          </p>
          <p>
            Advice:{" "}
            <b>
              Physician should perform final diagnosis with clinical interview
              and mental status examination including assessment of patient’s
              level of distress and functional impairment.
            </b>
          </p>
          <p>
            Proposed Treatment Actions: <b>{treatmentActions}</b>
          </p>
        </div>
      );
    } else {
      content = "";
    }
    setTooltipContent(() => content);
  };

  const {
    isLoading: isLoadingSurveyList,
    data: surveyList,
    error: isSurveyListError,
    isFetching: isFetchingSurveyList,
    refetch: refetchSurveyList
  } = useQuery(
    ["surveyReport", pageNumber],
    async () => {
      return await createApiClient().getPamSurveyReport({
        limit: 20,
        offset: pageNumber,
        month: month,
        year: year,
        name: searchPatient,
        status: status
      });
    },
    {
      enabled: hasMoreData
    }
  );

  const isFilterApplied = month !== "" || searchPatient !== "" || status !== "";

  const [enableFilter, setIsFilterEnabled] = useState<boolean>();

  useEffect(() => {
    setIsFilterEnabled(isFilterApplied);
  }, [surveyList]);

  useEffect(() => {
    if (pageNumber > 1 && hasMoreData) {
      if (surveyList?.length === 0) return;
      refetchSurveyList();
    }
  }, [pageNumber]);

  useEffect(() => {
    setSurveys(surveyList);
    setPamSurveys(surveyList);
  }, [surveyList]);

  const Months: {
    label: string;
    value: string;
  }[] = [
    { label: "All", value: "" },
    {
      label: "January",
      value: "1"
    },
    {
      label: "February",
      value: "2"
    },
    {
      label: "March",
      value: "3"
    },
    {
      label: "April",
      value: "4"
    },
    {
      label: "May",
      value: "5"
    },
    {
      label: "June",
      value: "6"
    },
    {
      label: "July",
      value: "7"
    },
    {
      label: "August",
      value: "8"
    },
    {
      label: "September",
      value: "9"
    },
    {
      label: "October",
      value: "10"
    },
    {
      label: "November",
      value: "11"
    },
    {
      label: "December",
      value: "12"
    }
  ];

  const Status: {
    label: string;
    value: string;
  }[] = [
    { label: "All", value: "" },
    { label: "Completed", value: "1" },
    { label: "Pending", value: "0" },
    { label: "Draft", value: "2" }
  ];

  for (let i = 0; i < 5; i++) {
    Years.push({
      label: (currentYear - i).toString(),
      value: currentYear - i
    });
  }

  useEffect(() => {
    setYear(Years[1]?.value);
  }, []);

  useEffect(() => {
    if (surveys) {
      if (surveys?.length === 0) {
        setHasMoreData(false);
      } else if (pageNumber === 1) {
        setUpdatedList(surveys);
      } else {
        setUpdatedList((prev: any) => [...prev, ...surveys]);
      }
    }
  }, [surveys]);

  const searchFuction = () => {
    setHasMoreData(true);
    // if (pageNumber === 1 && surveyList.length === 0) {
    //   return;
    // }
    setPageNumber(1);
    refetchSurveyList();
  };

  const navigate = useNavigate();

  const handleRowClick = (record: any) => {
    sessionStorage.setItem("patientId", record.patient_internal_id);
    navigate(`/patients/surveys/${record.patient_internal_id}`);
  };

  return (
    <div
      style={{ height: "100vh", overflow: "auto", paddingTop: 2 }}
      ref={scrollContainerRef}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 12,
          userSelect: "none",
          cursor: "default"
        }}
        tabIndex={0}
        onKeyDown={e => (e.key === "Enter" ? searchFuction() : null)}
      >
        <TitleCol style={{ marginBottom: "-50px" }}>Survey Reports</TitleCol>
        <div style={{ display: "flex", gap: 12, zIndex: 1 }}>
          <SearchBox
            placeholder="Search by name"
            onChange={e => {
              setSearchPatient(e.target.value);
            }}
            value={searchPatient}
          />
          <AppSelect
            value={status === "" ? "All" : status}
            label="Status"
            style={{ marginRight: 5, minWidth: "196px" }}
            options={Status}
            onChange={(status: string) => setStatus(status)}
            dropdownStyle={{ borderRadius: "8px" }}
          />
          <AppSelect
            value={month === "" ? "All" : month}
            label="Month"
            style={{ marginRight: 5, minWidth: "196px" }}
            options={Months}
            onChange={(month: string) => setMonth(month)}
            dropdownStyle={{ borderRadius: "8px" }}
          />
          <AppSelect
            value={year === "" ? "All" : year}
            label="Years"
            style={{ marginRight: 5, minWidth: "196px" }}
            options={Years}
            onChange={(year: string) => setYear(year)}
            dropdownStyle={{ borderRadius: "8px" }}
          />
          <AppButton
            buttonContent="Search"
            type={ButtonType.Primary}
            style={{ boxShadow: "none" }}
            onClick={() => searchFuction()}
          />
        </div>
      </div>
      <AppCard
        cardHeight="fit-content"
        cardWidth="100%"
        style={{ zIndex: 99, userSelect: "none", cursor: "default" }}
      >
        {isFetchingSurveyList ? (
          <div
            style={{
              marginTop: "40vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: " 40vh"
            }}
          >
            <Spin size="default" />
          </div>
        ) : enableFilter && pageNumber === 1 && surveyList?.length === 0 ? (
          <p>No matching records found.</p>
        ) : (
          <Table
            columns={columns}
            dataSource={updatedList}
            rowKey="id"
            pagination={false}
            size="large"
          />
        )}
      </AppCard>
      <AppModal
        title="PAM SURVEY"
        visible={PamModalOpen}
        width="80%"
        footer={false}
        onCancel={() => {
          setPamModalOpen(!PamModalOpen);
        }}
      >
        <>
          <div
            style={{
              width: "100%"
            }}
          >
            {isPamNotApplicable === true || isAllAnswerSame === true ? null : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: 18,
                    fontWeight: "bold",
                    padding: " 0px 12px",
                    marginBottom: "16px"
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    Activation Level :{"  "}
                    <span style={{ color: "red" }}>
                      {" "}
                      {pamSurveyScore && pamSurveyScore <= 47 && (
                        <div style={{ alignItems: "center" }}>
                          <span>Level 1</span>{" "}
                          <span
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                          >
                            (Belives Activation Role Important)
                          </span>
                        </div>
                      )}
                    </span>
                    <span style={{ color: "red" }}>
                      {" "}
                      {pamSurveyScore &&
                        pamSurveyScore >= 47.1 &&
                        pamSurveyScore <= 55.1 && (
                          <div style={{ textAlign: "center" }}>
                            <span>Level 2</span>{" "}
                            <span
                              style={{ fontSize: "14px", fontWeight: "normal" }}
                            >
                              (Confidence and Knowledge to take Action)
                            </span>
                          </div>
                        )}
                    </span>
                    <span style={{ color: "orange" }}>
                      {" "}
                      {pamSurveyScore &&
                        pamSurveyScore >= 55.2 &&
                        pamSurveyScore <= 72.4 && (
                          <div style={{ textAlign: "center" }}>
                            <span>Level 3</span>{" "}
                            <span
                              style={{ fontSize: "14px", fontWeight: "normal" }}
                            >
                              (Taking Action)
                            </span>
                          </div>
                        )}
                    </span>
                    <span style={{ color: "green" }}>
                      {" "}
                      {pamSurveyScore && pamSurveyScore >= 72.4 && (
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <span>Level 4</span>{" "}
                          <span
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                          >
                            (Staying the Course under Stress)
                          </span>
                        </div>
                      )}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontSize: 14 }}>Score :</span>{" "}
                    {pamSurveyScore && pamSurveyScore}
                  </div>
                </div>
              </>
            )}
            {isPamNotApplicable ? (
              "To ensure reliability of scoring this survey is excluded as it has 3 or more N/A response"
            ) : isAllAnswerSame ? (
              "To ensure reliability of scoring this survey is excluded as response for all questions is same"
            ) : filteredPamSurveys && filteredPamSurveys !== 0 ? (
              <div style={{ height: "400px", overflowY: "auto" }}>
                <Table
                  columns={pamColumns}
                  dataSource={filteredPamSurveys}
                  rowKey="id"
                  pagination={false}
                />
              </div>
            ) : (
              <p>No matching records found.</p>
            )}
          </div>
        </>
      </AppModal>
    </div>
  );
};

const MessageUpperRow = styled(Row)`
  color: ${Colors.gothicBold};
  font-size: 12px;
  letter-spacing: 1px;
`;

const StatusDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 8px;
  justify-content: start;
`;

const ActionSpan = styled.span`
  cursor: pointer;
`;

const ButtonsCol = styled(Col)`
  text-align: center;
  padding: 10px 0px;
`;
