// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext, useEffect, useState } from "react";
import {
  Roster,
  RosterHeader,
  RosterGroup,
  useRosterState,
  RosterAttendeeType
} from "amazon-chime-sdk-component-library-react";

import { useNavigation } from "components/meetings/providers/NavigationProvider";
import RosterAttendeeWrapper from "components/meetings/components/RosterAttendeeWrapper";
import { UserContext, UserContextState } from "contextApis/userContext";
import {
  MeetingContextState,
  MeetingModalContext,
  sessionStatusValues
} from "contextApis/meetingModalContext";
import { useQuery } from "react-query";
import { message } from "antd";
import { createApiClient } from "apiClient";

const MeetingRoster = () => {
  const { roster } = useRosterState();
  const { closeRoster } = useNavigation();
  const userContext = useContext<UserContextState>(UserContext);
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const [bothPartiesAttendedCall, setBothPartiesAttendedCall] = useState(false);

  const meetingID = sessionStorage.getItem("meetingID");

  const { refetch: endMeeting } = useQuery(
    "end-meeting",
    async () => {
      return await createApiClient().endMeeting(meetingID ? meetingID : "");
    },
    {
      enabled: false,
      onSuccess: () => {
        meetingContext.closeSession();
      },
      onError: () => {
        message.error("There was a problem ending this meeting.");
        meetingContext.closeSession();
      }
    }
  );

  const attendees = Object.values(roster);

  useEffect(() => {
    if (attendees.length > 1) {
      setBothPartiesAttendedCall(true);
      //To refresh call records when both members have joined
      meetingContext.setSessionStatus(sessionStatusValues.STARTED);
    }

    if (bothPartiesAttendedCall && attendees.length < 2) {
      //Terminate meeting if one member leaves - this only triggers if both parties have attended the call
      endMeeting();
    }
  }, [attendees]);

  const attendeeItems = attendees.map((attendee: RosterAttendeeType) => {
    const { chimeAttendeeId } = attendee || {};
    const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

    if (attendee.externalUserId === userContext.userData?.internal_id) {
      attendee.name = "You";
    } else {
      attendee.name = meetingContext.targetUser.name;
    }

    return (
      <div style={{ color: "black" }} key={chimeAttendeeId}>
        <RosterAttendeeWrapper
          key={chimeAttendeeId}
          attendeeId={chimeAttendeeId}
        />
      </div>
    );
  });

  return (
    <Roster className="roster">
      <RosterHeader
        onClose={closeRoster}
        title="Present"
        badge={attendees.length}
      />
      <RosterGroup>{attendeeItems}</RosterGroup>
    </Roster>
  );
};

export default MeetingRoster;
