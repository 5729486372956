import React, { useState } from "react";
import { message } from "antd";
import { useMutation } from "react-query";
import { createApiClient } from "apiClient";
import { AppCard } from "components/card";
import { CircleIndicator } from "components/circleIndicator";
import { NotificationAlertLevels } from "contextApis/notificationsContext";
import { Colors } from "helpers/colors";
import { NotificationProps } from "sharedTypes";
import {
  CustomBadgeContainer,
  SymptomGridItem,
  SymptomItemDesctiptionTextContainer,
  SymptomItemDetailKeyText,
  SymptomItemDetailsGridContainer,
  SymptomItemDetailValueText,
  SymptomsItemsGridContainer,
  SymptomsListItemHeaderText,
  SymptomTitleListContainer,
  SymptomTitleText
} from "./styles";
import { Symptom, SymptomsListData } from "./SymptomsList";

interface SymptomsListItemProps {
  readonly symptomListItem: SymptomsListData;
  readonly onModalOpen: (selectedSymptom: string) => void;
  readonly highestRowSeverity: string;
  readonly rowSpecificNotifications: NotificationProps[];
  readonly getNotificationsList: () => void;
}

export const SymptomListItem: React.FC<SymptomsListItemProps> = ({
  symptomListItem,
  onModalOpen,
  highestRowSeverity,
  rowSpecificNotifications,
  getNotificationsList
}) => {
  const { mutate: markNotification } = useMutation(
    "mark-notification",
    createApiClient().putNotifications,
    {
      onError: () => {
        message.error("There was a problem updating notifications.");
      },
      onSuccess: () => {
        getNotificationsList();
      }
    }
  );

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onItemExpand = () => {
    symptomListItem.symptoms.forEach(item => {
      const unseenSymptom = rowSpecificNotifications.find(
        e => String(e.item_id) === String(item.id)
      );
      if (unseenSymptom) {
        markNotification({
          notificationId: unseenSymptom.notification_id,
          body: { status: "read", type: "symptoms" }
        });
      }
    });
    setIsExpanded(true);
  };

  const getSymptomDataLength = (symptom: Symptom) =>
    symptom.data ? symptom.data.length : 0;

  const handleModalOpen = (selectedSymptom: string) => () =>
    onModalOpen(selectedSymptom);

  return (
    <>
      <SymptomsListItemHeaderText>
        <CustomBadgeContainer>
          {highestRowSeverity === NotificationAlertLevels.HIGH && (
            <CircleIndicator outerColor={"#EEB5AD"} innerColor={Colors.Red} />
          )}
          {highestRowSeverity === NotificationAlertLevels.MODERATE && (
            <CircleIndicator outerColor={"#fbddb7"} innerColor={"#f18e13"} />
          )}
        </CustomBadgeContainer>
        {`${symptomListItem.timestamp} | By ${symptomListItem.reporterName}${
          symptomListItem.reporterRole
            ? `(${symptomListItem.reporterRole})`
            : ""
        }`}
      </SymptomsListItemHeaderText>
      {isExpanded ? (
        <SymptomsItemsGridContainer>
          {symptomListItem.symptoms.map((symptom, index) => (
            <SymptomGridItem
              spanLength={getSymptomDataLength(symptom) > 5 ? 3 : 1}
              key={index}
              onClick={handleModalOpen(symptom.name)}
            >
              <AppCard
                cardHeight={`${
                  getSymptomDataLength(symptom) > 1 ? "fit-content" : "100%"
                }`}
                cardWidth="100%"
                style={{ cursor: "pointer" }}
              >
                <SymptomTitleText>{symptom.name}</SymptomTitleText>
                {symptom.description ? (
                  <SymptomItemDesctiptionTextContainer>
                    {symptom.description}
                  </SymptomItemDesctiptionTextContainer>
                ) : (
                  <SymptomItemDetailsGridContainer>
                    {symptom.data?.map((dataItem, index) => (
                      <React.Fragment key={index}>
                        <SymptomItemDetailKeyText>{`${dataItem.key} :`}</SymptomItemDetailKeyText>
                        <SymptomItemDetailValueText>
                          {dataItem.value}
                        </SymptomItemDetailValueText>
                      </React.Fragment>
                    ))}
                  </SymptomItemDetailsGridContainer>
                )}
              </AppCard>
            </SymptomGridItem>
          ))}
        </SymptomsItemsGridContainer>
      ) : (
        <AppCard cardHeight="auto" cardWidth="100%">
          <SymptomTitleListContainer onClick={onItemExpand}>
            {symptomListItem.symptoms.map((symptom, index) => (
              <SymptomTitleText key={index}>{symptom.name}</SymptomTitleText>
            ))}
          </SymptomTitleListContainer>
        </AppCard>
      )}
    </>
  );
};
