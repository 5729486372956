import React, { useEffect, useState, useMemo } from "react";
import { get, isEmpty } from "lodash";
import styled from "styled-components";
import { AppSelect } from "components/inputs";
import { SelectOption } from "components/inputs/selectInput";
import { MedicationAutocompleteResponse } from "sharedTypes";

export interface MedicineAmountProps {
  readonly medicineAmount?: string;
}

interface MedicineAmountSelectProps {
  readonly value?: MedicineAmountProps;
  readonly onChange?: (value: MedicineAmountProps) => void;
  readonly medicineName: string;
  readonly selectedMedicine: MedicationAutocompleteResponse | null;
}

export const MedicineAmountSelect: React.FC<MedicineAmountSelectProps> = ({
  value,
  onChange,
  medicineName,
  selectedMedicine
}) => {
  const [medicineAmount, setMedicineAmount] = useState<string>(
    get(selectedMedicine, "strengthsAndForms[0]", "")
  );

  const onMedicineAmountChange = (amount: string) => {
    setMedicineAmount(amount);
    triggerChange({ medicineAmount: amount });
  };

  const triggerChange = (changedValue: MedicineAmountProps) => {
    onChange?.({
      medicineAmount,
      ...value,
      ...changedValue
    });
  };

  useEffect(() => {
    triggerChange({ medicineAmount: medicineAmount });
  }, []);

  const medicineStrengthOptions: SelectOption<string>[] = useMemo(() => {
    if (!selectedMedicine) return [];
    return selectedMedicine.strengthsAndForms.map(strength => ({
      label: strength,
      value: strength
    }));
  }, [selectedMedicine]);

  useEffect(() => {
    if (!selectedMedicine) {
      onMedicineAmountChange("");
      return;
    }
    if (isEmpty(medicineStrengthOptions)) return;
    onMedicineAmountChange(get(selectedMedicine, "strengthsAndForms[0]", ""));
  }, [selectedMedicine, medicineStrengthOptions]);

  return (
    <MedicationBody>
      <div style={{ display: medicineName ? "" : "none" }}>
        <AppSelect
          value={medicineAmount}
          label="mg"
          onChange={onMedicineAmountChange}
          style={{ marginRight: 5, width: "210px", marginTop: -5 }}
          options={medicineStrengthOptions}
          dropdownStyle={{ borderRadius: "8px" }}
        />
      </div>
    </MedicationBody>
  );
};
const MedicationBody = styled.div`
  display: grid;
  grid-template-columns: 30% 4% 10%;
  font-weight: 900;
`;
