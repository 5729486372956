import React, { useEffect, useState, useRef } from "react";
import { Modal, Input, Tooltip, message, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import styled from "styled-components";
import axios from "axios";
import { createApiClient } from "apiClient";
import TextArea from "antd/lib/input/TextArea";
import type { InputRef } from "antd";

interface IBloodPressure {
  lower_systolic: string;
  upper_systolic: string;
  lower_diastolic: string;
  upper_diastolic: string;
  patient_internal_id: number;
  remarks: string;
}
enum bp_status {
  statusOk = "Threshold stored for the patient"
}

const SetThresholdModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [bloodPressure, setBloodPressure] = useState<IBloodPressure>({
    lower_systolic: "",
    upper_systolic: "",
    lower_diastolic: "",
    upper_diastolic: "",
    patient_internal_id: Number(sessionStorage.getItem("patientId")) || 0,
    remarks: ""
  });

  const upperSystolicRef = useRef<InputRef>(null);
  const lowerSystolicRef = useRef<InputRef>(null);
  const upperDiastolicRef = useRef<InputRef>(null);
  const lowerDiastolicRef = useRef<InputRef>(null);
  const remarkRef = useRef<InputRef>(null);
  const croppedString: string = window.location.pathname.substring(0, 22);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setBloodPressure(prev => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    if (upperSystolicRef.current) {
      upperSystolicRef.current.focus();
    }
  }, [bloodPressure.upper_systolic]);

  useEffect(() => {
    if (lowerSystolicRef.current) {
      lowerSystolicRef.current.focus();
    }
  }, [bloodPressure.lower_systolic]);

  useEffect(() => {
    if (upperDiastolicRef.current) {
      upperDiastolicRef.current.focus();
    }
  }, [bloodPressure.upper_diastolic]);

  useEffect(() => {
    if (lowerDiastolicRef.current) {
      lowerDiastolicRef.current.focus();
    }
  }, [bloodPressure.lower_diastolic]);

  useEffect(() => {
    if (remarkRef.current) {
      remarkRef.current.focus();
    }
  }, [bloodPressure.remarks]);

  const data_body = {
    lower_systolic: bloodPressure.lower_systolic,
    upper_systolic: bloodPressure.upper_systolic,
    lower_diastolic: bloodPressure.lower_diastolic,
    upper_diastolic: bloodPressure.upper_diastolic,
    patient_internal_id: bloodPressure.patient_internal_id,
    remarks: bloodPressure.remarks
  };

  const fetchBPThreshold = async (patientId: string) => {
    const data = await createApiClient().getThresholdData(patientId);
    sessionStorage.setItem("Threshold_created_by", data?.threshold_set_by);
    return data;
  };

  useEffect(() => {
    const fetchdata = async () => {
      const patientId = sessionStorage.patientId;
      const data = await fetchBPThreshold(patientId);
      if (data) {
        setBloodPressure({
          lower_systolic: data.lower_systolic,
          upper_systolic: data.upper_systolic,
          lower_diastolic: data.lower_diastolic,
          upper_diastolic: data.upper_diastolic,
          patient_internal_id: patientId,
          remarks: data.remarks || ""
        });
      }
    };

    fetchdata();
  }, []);

  const handleUpdate = async () => {
    try {
      const threshold_data = await createApiClient().setThresholdData(
        data_body
      );

      if (bp_status.statusOk === threshold_data) {
        message.success("Updated successfully", 2);
      } else {
        message.error("Every Threshold point is required");
      }
    } catch {
      message.error("Every Threshold point is required");
    } finally {
      setIsModalVisible(false);
      setIsEditMode(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEditMode(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const CustomStyledModal = styled.div`
    .ant-modal-header {
      border-radius: 10px;
    }
    .ant-modal-content {
      border-radius: 10px;
      font-weight: bold;
    }
    .ant-modal-footer {
      display: flex;
      justify-content: flex-start;
    }
    .ant-modal-title {
      font-weight: 800;
      font-size: 14px;
    }
  `;

  return (
    <div>
      {croppedString === "/patients/remote-vital" ? (
        <AppButton
          type={ButtonType.Skeleton}
          buttonContent="Set Threshold"
          style={{
            minWidth: 120,
            right: "15px",
            border: "1px solid black",
            marginTop: "-9px",
            color: "black"
          }}
          onClick={showModal}
        />
      ) : (
        <div
          style={{
            minWidth: 120,
            right: "15px",
            marginTop: "-9px",
            color: "black"
          }}
        ></div>
      )}

      <Modal
        title="Set Threshold"
        open={isModalVisible}
        onOk={handleUpdate}
        okText="Update"
        onCancel={handleCancel}
        width={480}
        modalRender={modal => <CustomStyledModal>{modal}</CustomStyledModal>}
        footer={[
          <Button
            key="edit"
            type="primary"
            onClick={handleEdit}
            disabled={isEditMode}
          >
            Edit
          </Button>,
          <Button
            key="update"
            type="primary"
            style={{
              position: "absolute",
              right: "15px"
            }}
            onClick={handleUpdate}
            disabled={!isEditMode}
          >
            Update
          </Button>,
          <Button
            key="cancel"
            type="default"
            onClick={handleCancel}
            style={{
              position: "absolute",
              right: "110px"
            }}
          >
            Cancel
          </Button>
        ]}
        style={{
          fontWeight: "bold"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "70px"
            }}
          >
            <label style={{ marginLeft: "60px" }}>High</label>
            <label>Low</label>
          </div>
          <div>
            <label>Systolic</label>
            <Input
              ref={upperSystolicRef}
              name="upper_systolic"
              placeholder="high"
              style={{ marginLeft: "19px", width: "100px" }}
              value={bloodPressure.upper_systolic}
              onChange={handleChange}
              disabled={!isEditMode}
              max={300}
            />
            <Tooltip title="Highest limit is 240">
              {/* <InfoCircleOutlined
                style={{
                  position: "absolute",
                  right: "220px",
                  top: "120px",
                  color: "#C9C9C9"
                }}
              /> */}
            </Tooltip>

            <Input
              ref={lowerSystolicRef}
              name="lower_systolic"
              placeholder="low"
              style={{ marginLeft: "10px", width: "100px" }}
              value={bloodPressure.lower_systolic}
              onChange={handleChange}
              disabled={!isEditMode}
            />
            <Tooltip title="Lowest limit 60">
              {/* <InfoCircleOutlined
                style={{
                  position: "absolute",
                  right: "110px",
                  top: "120px",
                  color: "#C9C9C9"
                }}
              /> */}
            </Tooltip>
          </div>
          <div>
            <label>Diastolic</label>
            <Input
              ref={upperDiastolicRef}
              name="upper_diastolic"
              placeholder="high"
              style={{ marginLeft: "10px", width: "100px" }}
              value={bloodPressure.upper_diastolic}
              onChange={handleChange}
              disabled={!isEditMode}
            />
            <Tooltip title="Highest limit is 150">
              {/* <InfoCircleOutlined
                style={{
                  position: "absolute",
                  right: "220px",
                  top: "160px",
                  color: "#C9C9C9"
                }}
              /> */}
            </Tooltip>
            <Input
              ref={lowerDiastolicRef}
              name="lower_diastolic"
              placeholder="low"
              style={{ marginLeft: "10px", width: "100px" }}
              value={bloodPressure.lower_diastolic}
              onChange={handleChange}
              disabled={!isEditMode}
            />
            <Tooltip title="Lowest limit 30">
              {/* <InfoCircleOutlined
                style={{
                  position: "absolute",
                  right: "110px",
                  top: "168px",
                  transform: "translateY(-50%)",
                  color: "#C9C9C9"
                }}
              /> */}
            </Tooltip>
          </div>
          {isEditMode ? (
            <div>
              <label>Remarks</label>
              <Input
                ref={remarkRef}
                name="remarks"
                placeholder="remarks"
                style={{ marginLeft: "10px", width: "210px" }}
                value={bloodPressure.remarks}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </div>
          ) : (
            " "
          )}
        </div>
      </Modal>
    </div>
  );
};

export default SetThresholdModal;
