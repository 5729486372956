import moment from "moment";
import { lowerCaseRemoveSpace } from "helpers/utils";
import { ExtendedSymptomData } from "pages/symptoms/SymptomsModal";

export const appetiteImpairmentMapper = ({
  filteredExtendedSymptomData,
  mapperType,
  mapperValue
}: {
  filteredExtendedSymptomData: ExtendedSymptomData[] | null;
  mapperType?: string | undefined;
  mapperValue?: string | number | undefined;
}) => {
  const severity: [string, number, string][] = [];

  const sortedData = filteredExtendedSymptomData?.sort(
    (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
  );

  if (mapperType) {
    let tooltipValue = "";
    if (lowerCaseRemoveSpace(mapperType) === "severity") {
      switch (mapperValue) {
        case 1:
          tooltipValue =
            "Mildly reduced appetite (rarely missing meals, eating about ¾ of usual)";
          break;
        case 4:
          tooltipValue =
            "Moderately reduced appetite (frequently missing meals/missing at least one meal a day, eating about ½ of usual)";
          break;
        case 7:
          tooltipValue =
            "Severely reduced appetite (mostly missing meals/no desire for food, eating about ¼ of usual)";
          break;
        case 10:
          tooltipValue = "No appetite or intake of food";
          break;
      }
    }
    return tooltipValue;
  }

  sortedData?.forEach(item => {
    item.data?.map(item2 => {
      if (lowerCaseRemoveSpace(item2?.key) === "severity") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "mildlyreducedappetite(rarelymissingmeals,eatingabout¾ofusual)":
            value = 1;
            break;
          case "moderatelyreducedappetite(frequentlymissingmeals/missingatleastonemealaday,eatingabout½ofusual)":
            value = 4;
            break;
          case "severelyreducedappetite(mostlymissingmeals/nodesireforfood,eatingabout¼ofusual)":
            value = 7;
            break;
          case "noappetiteorintakeoffood":
            value = 10;
            break;
        }
        severity.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Severity"
        ]);
      }
    });
  });

  return [severity];
};
