// SVGIcons.js

import React from "react";
import PropTypes from "prop-types";

type UserIconProps = {
  isActive: boolean;
};

export const HighBPIndicator = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14.6667 4.66665L9.00004 10.3333L5.66671 6.99998L1.33337 11.3333M14.6667 4.66665L10.6667 4.66665M14.6667 4.66665V8.66665"
      stroke="#F18E13"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LowBPIndicator = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M1.33329 11.3334L6.99996 5.66669L10.3333 9.00002L14.6666 4.66669M1.33329 11.3334L5.33329 11.3334M1.33329 11.3334L1.33329 7.33335"
      stroke="#FC0000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CallIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M14.2587 10.804V12.804C14.2595 12.9896 14.2215 13.1734 14.1471 13.3435C14.0727 13.5137 13.9636 13.6664 13.8268 13.7919C13.69 13.9174 13.5285 14.013 13.3526 14.0724C13.1767 14.1319 12.9903 14.154 12.8054 14.1373C10.754 13.9144 8.78341 13.2134 7.05208 12.0906C5.4413 11.0671 4.07564 9.70142 3.05208 8.09064C1.9254 6.35144 1.22424 4.3713 1.00541 2.31064C0.988751 2.12628 1.01066 1.94048 1.06974 1.76505C1.12883 1.58963 1.22379 1.42843 1.34859 1.29172C1.47339 1.15501 1.62528 1.04578 1.79461 0.970985C1.96393 0.896193 2.14697 0.857478 2.33208 0.857303H4.33208C4.65562 0.854119 4.96927 0.968689 5.21459 1.17966C5.4599 1.39063 5.62013 1.6836 5.66541 2.00397C5.74983 2.64401 5.90638 3.27245 6.13208 3.8773C6.22177 4.11592 6.24119 4.37525 6.18802 4.62456C6.13485 4.87387 6.01132 5.10271 5.83208 5.28397L4.98541 6.13064C5.93445 7.79967 7.31638 9.1816 8.98541 10.1306L9.83208 9.28397C10.0133 9.10473 10.2422 8.9812 10.4915 8.92803C10.7408 8.87486 11.0001 8.89427 11.2387 8.98397C11.8436 9.20967 12.472 9.36622 13.1121 9.45064C13.4359 9.49632 13.7317 9.65944 13.9431 9.90897C14.1545 10.1585 14.2669 10.477 14.2587 10.804Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MessageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
  >
    <path
      d="M4.33333 5.16667H4.34M7 5.16667H7.00667M9.66667 5.16667H9.67333M13 8.5C13 8.85362 12.8595 9.19276 12.6095 9.44281C12.3594 9.69286 12.0203 9.83333 11.6667 9.83333H3.66667L1 12.5V1.83333C1 1.47971 1.14048 1.14057 1.39052 0.890524C1.64057 0.640476 1.97971 0.5 2.33333 0.5H11.6667C12.0203 0.5 12.3594 0.640476 12.6095 0.890524C12.8595 1.14057 13 1.47971 13 1.83333V8.5Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BpAletrsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="25"
    viewBox="0 0 54 48"
    fill="none"
  >
    <path
      d="M4.88276 24.1172H20.7053L21.965 21.5977L27.004 32.9355L32.043 15.2989L35.8223 24.1172H49.1001M44.6406 29.1562C48.3946 25.4777 52.1991 21.0686 52.1991 15.2989C52.1991 11.6238 50.7391 8.0991 48.1404 5.50035C45.5416 2.90161 42.017 1.44165 38.3418 1.44165C33.9075 1.44165 30.7833 2.7014 27.004 6.48066C23.2248 2.7014 20.1006 1.44165 15.6662 1.44165C11.9911 1.44165 8.46641 2.90161 5.86766 5.50035C3.26892 8.0991 1.80896 11.6238 1.80896 15.2989C1.80896 21.0938 5.58822 25.5029 9.36748 29.1562L27.004 46.7928L44.6406 29.1562Z"
      stroke="#212B40"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UserIcon: React.FC<UserIconProps> = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
  >
    <path
      d="M0.833374 12.5C0.833318 11.4736 1.12946 10.4689 1.68627 9.60659C2.24308 8.74429 3.03689 8.06098 3.97244 7.63868C4.90799 7.21637 5.94553 7.073 6.96055 7.22578C7.97556 7.37857 8.92493 7.821 9.69471 8.5M10.1667 11.1667L11.5 12.5L14.1667 9.83333M9.50004 3.83333C9.50004 5.67428 8.00766 7.16667 6.16671 7.16667C4.32576 7.16667 2.83337 5.67428 2.83337 3.83333C2.83337 1.99238 4.32576 0.5 6.16671 0.5C8.00766 0.5 9.50004 1.99238 9.50004 3.83333Z"
      stroke={isActive ? "white" : "black"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

UserIcon.propTypes = {
  isActive: PropTypes.bool.isRequired
};
