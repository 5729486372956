import moment from "moment";
import { lowerCaseRemoveSpace } from "helpers/utils";
import { ExtendedSymptomData } from "pages/symptoms/SymptomsModal";

export const weightChangeMapper = ({
  filteredExtendedSymptomData,
  mapperType,
  mapperValue
}: {
  filteredExtendedSymptomData: ExtendedSymptomData[] | null;
  mapperType?: string | undefined;
  mapperValue?: string | number | undefined;
}) => {
  const severity: [string, number, string][] = [];

  const sortedData = filteredExtendedSymptomData?.sort(
    (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
  );

  if (mapperType) {
    let tooltipValue = "";
    if (lowerCaseRemoveSpace(mapperType) === "amount") {
      switch (mapperValue) {
        case 1:
          tooltipValue = "1 kg or less/1 - 3 pounds";
          break;
        case 3:
          tooltipValue = "2 - 3 kg/3 - 6 pounds";
          break;
        case 5:
          tooltipValue = "4 - 5 kg/7 - 10 pounds";
          break;
        case 7:
          tooltipValue = "6 - 7 kg/11 - 15 pounds";
          break;
        case 8:
          tooltipValue = "8 - 10 kg/16 - 20 pounds";
          break;
        case 9:
          tooltipValue = "11 - 15 kg/20 - 30 pounds";
          break;
        case 10:
          tooltipValue = "More than 15 kg/More than 30 pounds";
          break;
      }
    }
    return tooltipValue;
  }

  sortedData?.forEach(item => {
    item.data?.map(item2 => {
      if (lowerCaseRemoveSpace(item2?.key) === "amount") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "1kgorless":
          case "1-3pounds":
            value = 1;
            break;
          case "2-3kg":
          case "3-6pounds":
            value = 3;
            break;
          case "4-5kg":
          case "7-10pounds":
            value = 5;
            break;
          case "6-7kg":
          case "11-15pounds":
            value = 7;
            break;
          case "8-10kg":
          case "16-20pounds":
            value = 8;
            break;
          case "11-15kg":
          case "20-30pounds":
            value = 9;
            break;
          case "morethan15kg":
          case "morethan30pounds":
            value = 10;
            break;
        }
        severity.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Amount"
        ]);
      }
    });
  });

  return [severity];
};
