import React, { useEffect, useState } from "react";
import { Moment } from "moment";
import { FormContainer } from "./styles";
import { AppDatePicker, AppTimePicker } from "components/inputs";
export interface AppointmentFormDateInputProps {
  readonly date?: Moment | null;
}

interface AppointmentFormProps {
  readonly value?: AppointmentFormDateInputProps;
  readonly onChange?: (value: AppointmentFormDateInputProps) => void;
  readonly clearForm: boolean;
  readonly selectedDate: Moment | null;
}

const className = ["disable-arrow1", "disable-arrow4"];

export const AppointmentFormDateInput: React.FC<AppointmentFormProps> = ({
  value,
  onChange,
  clearForm,
  selectedDate
}) => {
  const [date, setDate] = useState<Moment | null>(null);

  const triggerChange = (changedValue: AppointmentFormDateInputProps) => {
    onChange?.({
      date,
      ...value,
      ...changedValue
    });
  };
  const onDateChange = (newDate: Moment | null) => {
    if (newDate) {
      setDate(newDate);
      triggerChange({ date: newDate });
    }
  };

  useEffect(() => {
    setDate(null);
  }, [clearForm]);

  useEffect(() => {
    if (selectedDate) {
      setTimeout(() => {
        onDateChange(selectedDate);
      }, 0.1);
    }
  }, [selectedDate]);

  return (
    <FormContainer>
      <AppDatePicker
        label="Appointment Date"
        onChange={onDateChange}
        open
        dropdownClassName={className.join(" ")}
        value={date}
        customDateFormat="MMMM D, yyyy"
        placeholder="Select Date"
      />
      <AppTimePicker
        label="Appointment Time"
        open
        onChange={onDateChange}
        value={date}
        placeholder="Select Time"
        use12Hours
        customTimeFormat="h:mm a"
      />
    </FormContainer>
  );
};
