import React, { createContext, useState } from "react";
import { CheckboxMenuCommonInterface } from "sharedTypes";

export type PatientListContextState = {
  diagnosesMenuOne: CheckboxMenuCommonInterface[];
  setDiagnosesMenuOne: (reasons: CheckboxMenuCommonInterface[]) => void;
  diagnosesMenuTwo: CheckboxMenuCommonInterface[];
  setDiagnosesMenuTwo: (reasons: CheckboxMenuCommonInterface[]) => void;
};

const initialValue: PatientListContextState = {
  diagnosesMenuOne: [],
  setDiagnosesMenuOne: () => null,
  diagnosesMenuTwo: [],
  setDiagnosesMenuTwo: () => null
};

export const PatientListContext =
  createContext<PatientListContextState>(initialValue);

PatientListContext.displayName = "CheckboxMenuContext";

export const PatientListProvider: React.FC = ({ children }) => {
  const [diagnosesMenuOne, setDiagnosesMenuOne] = useState<
    CheckboxMenuCommonInterface[]
  >([]);
  const [diagnosesMenuTwo, setDiagnosesMenuTwo] = useState<
    CheckboxMenuCommonInterface[]
  >([]);

  return (
    <PatientListContext.Provider
      value={{
        diagnosesMenuOne,
        setDiagnosesMenuOne,
        diagnosesMenuTwo,
        setDiagnosesMenuTwo
      }}
    >
      {children}
    </PatientListContext.Provider>
  );
};
