import Space from "antd/lib/space";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import React from "react";
import styled from "styled-components";

import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { Platform } from "constants/roles";

interface PassingOutSectionProps {
  readonly currentPage: number;
}

export const PassingOutSection: React.FC<PassingOutSectionProps> = ({
  currentPage
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  return (
    <>
      {currentPage === 3 && (
        <Space direction="vertical" size={20}>
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{ width: "100%", padding: "10px" }}
              >
                <DisclaimerTitle>PASSING OUT</DisclaimerTitle>
                <CardTitle>
                  Have you passed out or fainted on dialysis recently?
                  <AsteriskStyle> *</AsteriskStyle>
                </CardTitle>
                <FormItemStyle
                  name="Have you passed out"
                  label=""
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  initialValue={""}
                >
                  <Radio.Group
                    value={localStorage.getItem("haveYouPassedOut")}
                    onChange={e => {
                      localStorage.setItem("haveYouPassedOut", e.target.value);
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={"No"}>No</Radio>
                      <Radio value={"Yes"}>Yes</Radio>
                    </Space>
                  </Radio.Group>
                </FormItemStyle>
              </Space>
            </AppCard>
          </Space>
        </Space>
      )}
      {currentPage === 4 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform == Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>SYMPTOM ALERT</DisclaimerTitle>
              <SymptomAlert>
                Please discuss your symptoms of passing out with your nurse or
                doctor
              </SymptomAlert>
            </Space>
          </AppCard>
        </Space>
      )}
    </>
  );
};

const DisclaimerTitle = styled.div`
  width: 100%;
  padding: 8px 8px 8px 20px;
  margin-left: -25px;
  background: ${Colors.DodgerBlue};
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 8px 8px 0px 0px;
  color: white;
  position: absolute;
  top: 0;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;

const SymptomAlert = styled.div`
  font-size: 16px;
  font-family: sans-serif;
  margin-top: 15px;
`;
