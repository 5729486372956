import React, { useEffect, useRef, useState } from "react";
import { Result } from "antd";
import { ButtonType } from "components/button/appButton";
import { AppButton } from "components/button";
import { AchesAndPainForm } from "pages/symptoms/surveyForms/achesAndPainForm/achesAndPainForm";
import { AppetiteImpairmentForm } from "pages/symptoms/surveyForms/appetiteImpairmentForm/appetiteImpairmentForm";
import { ChestPainForm } from "pages/symptoms/surveyForms/chestPainForm/chestPainForm";
import { DialysisSymptomsForm } from "pages/symptoms/surveyForms/dialysisSymptomsForm/dialysisSymptomsForm";
import { FallsForm } from "pages/symptoms/surveyForms/fallsForm/fallsForm";
import { FatigueForm } from "pages/symptoms/surveyForms/fatigueForm/fatigueForm";
import { FeverForm } from "pages/symptoms/surveyForms/feverForm/feverForm";
import { LegSwellingForm } from "pages/symptoms/surveyForms/legSwellingForm/legSwellingForm";
import { LightheadednessForm } from "pages/symptoms/surveyForms/lightheadednessForm/lightheadednessForm";
import { MoodImpairmentForm } from "pages/symptoms/surveyForms/moodImpairmentForm/moodImpairmentForm";
import { NauseaSurveyForm } from "pages/symptoms/surveyForms/nauseaSurveyForm/nauseaSurveyForm";
import { ShortnessOfBreathForm } from "pages/symptoms/surveyForms/shortnessOfBreathForm/shortnessOfBreathForm";
import { UlcersForm } from "pages/symptoms/surveyForms/ulcersForm/ulcersForm";
import { UrinarySymptomsForm } from "pages/symptoms/surveyForms/urinarySymptomsForm/urinarySymptomsForm";
import { VitalSignsForm } from "pages/symptoms/surveyForms/vitalSignsForm/vitalSignsForm";
import { WeightChangeForm } from "pages/symptoms/surveyForms/weightChangeForm/weightChangeForm";

interface SymptomFormsProps {
  readonly selectedSymptomList: string[];
}

export const SymptomForms: React.FC<SymptomFormsProps> = ({
  selectedSymptomList
}) => {
  const [selectedItems, setSelectedItems] =
    useState<string[]>(selectedSymptomList);
  const [showSurveyForm, setShowSurveyForm] = useState(
    selectedSymptomList.length ? true : false
  );
  const formTopRef = useRef<any>();

  useEffect(() => {
    formTopRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }, [selectedItems]);

  const goToNextFormOrReturn = () => {
    if (selectedItems.length > 1) {
      const [, ...updatedSelectedItems] = selectedItems;
      setSelectedItems(updatedSelectedItems || []);
    } else {
      setShowSurveyForm(false);
    }
  };

  const showRelevantForm = (currentForm: string) => {
    switch (currentForm) {
      case "Nausea":
        return (
          <NauseaSurveyForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Aches/Pain":
        return (
          <AchesAndPainForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Appetite Impairment":
        return (
          <AppetiteImpairmentForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Chest Pain":
        return (
          <ChestPainForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Falls":
        return (
          <FallsForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Fatigue":
        return (
          <FatigueForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Fever":
        return (
          <FeverForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Shortness of Breath":
        return (
          <ShortnessOfBreathForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Leg Swelling":
        return (
          <LegSwellingForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Weight Change":
        return (
          <WeightChangeForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Lightheadedness":
        return (
          <LightheadednessForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Mood Impairment":
        return (
          <MoodImpairmentForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Ulcers":
        return (
          <UlcersForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Vital Signs":
        return (
          <VitalSignsForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Urinary Symptoms":
        return (
          <UrinarySymptomsForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Dialysis Symptom":
        return (
          <DialysisSymptomsForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      default:
        return (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <AppButton
                type={ButtonType.Primary}
                onClick={() => setShowSurveyForm(false)}
                buttonContent={<>Back</>}
              />
            }
          />
        );
    }
  };

  return showSurveyForm ? (
    <>
      <div ref={formTopRef} />
      {showRelevantForm(selectedItems[0])}
    </>
  ) : (
    <>Successfully filled all forms</>
  );
};
