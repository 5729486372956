import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import AutoComplete from "antd/lib/auto-complete";
import Spin from "antd/lib/spin";
import { SearchBox } from "components/searchBox";
import { MedicationAutocompleteResponse } from "sharedTypes";

export interface MedicineNameProps {
  readonly medicineName?: string;
}

interface AutoCompleteOption {
  readonly label: string;
  readonly value: string;
}

interface MedicineNameAutoCompleteProps {
  readonly value?: MedicineNameProps;
  readonly onChange?: (value: MedicineNameProps) => void;
  readonly formValue: MedicineNameProps;
  readonly updateFormMedicineName: (value: string) => void;
  readonly onSelectedMedicationChange: (
    value: MedicationAutocompleteResponse | null
  ) => void;
  readonly updateMedicineNameOptions: (options: string[]) => void;
}

export const MedicineNameAutoComplete: React.FC<
  MedicineNameAutoCompleteProps
> = ({
  value,
  onChange,
  formValue,
  updateFormMedicineName,
  onSelectedMedicationChange,
  updateMedicineNameOptions
}) => {
  const [medicineName, setMedicineName] = useState<string>(
    formValue?.medicineName || ""
  );
  const [options, setOptions] = useState<AutoCompleteOption[]>([]);

  const { data, isLoading, isFetching, refetch } = useQuery(
    "medication-autocomplete",
    async () => {
      return createApiClient().getMedicineAutocomplete(medicineName, 100);
    },
    { enabled: false }
  );

  const onMedicineNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMedicineName(event.target.value);
  };

  const triggerChange = (changedValue: MedicineNameProps) => {
    if (changedValue.medicineName) {
      onChange?.({
        ...value,
        ...changedValue
      });
      return;
    }
    onChange?.(changedValue);
  };

  const onAutoCompleteValueSelect = (value: string) => {
    setMedicineName(value);
    updateFormMedicineName(value);
    const selectedMedicine =
      data?.find(item => item.productName === value) || null;
    onSelectedMedicationChange(selectedMedicine);
    // if (value) {
    //   nextStep();
    // }
  };

  useEffect(() => {
    if (!data) return;
    const autoCompleteOptions = data.map(item => ({
      label: item.productName,
      value: item.productName
    }));
    setOptions(autoCompleteOptions);
  }, [data]);

  useEffect(() => {
    if (!medicineName) {
      setOptions([]);
      updateFormMedicineName("");
      onSelectedMedicationChange(null);
      return;
    }
    const delaySearch = setTimeout(() => {
      if (
        medicineName &&
        !options.map(option => option.value).includes(medicineName)
      ) {
        refetch();
      }
    }, 500);

    triggerChange({
      medicineName: medicineName
    });
    return () => clearTimeout(delaySearch);
  }, [medicineName]);

  useEffect(() => {
    updateMedicineNameOptions(options.map(option => option.value));
  }, [options]);

  return (
    <AutoComplete
      notFoundContent={
        isLoading || isFetching ? (
          <Spin style={{ marginLeft: "50%" }} />
        ) : (
          "No entries found"
        )
      }
      options={options}
      onSelect={onAutoCompleteValueSelect}
    >
      <SearchBox
        placeholder="Medicine name"
        value={medicineName}
        onChange={onMedicineNameChange}
      />
    </AutoComplete>
  );
};
