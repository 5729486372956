import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PatientsRoutesRef, ProvidersRoutesRef } from "constants/routes";

import { CareGemLogo } from "./careGemLogo";

interface TopbarLogoProps {
  patients?: boolean;
}

export const TopbarLogo: React.FC<TopbarLogoProps> = ({ patients }) => {
  const navigate = useNavigate();

  return (
    <LogoContainerStyled
      onClick={() =>
        navigate(
          patients
            ? PatientsRoutesRef.MyMedicalData
            : ProvidersRoutesRef.Patients
        )
      }
    >
      <CareGemLogo />
    </LogoContainerStyled>
  );
};

const LogoContainerStyled = styled.div`
  top: 15px;
  left: 22px;
  position: fixed;
  cursor: pointer !important;
  z-index: 100;
`;
