import Skeleton from "antd/lib/skeleton";
import React from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { TabInfoContent } from "pages/info/style";
import DOMPurify from "dompurify";

export const Disclaimer: React.FC = () => {
  const { data, isLoading, isFetching } = useQuery("disclaimer", async () => {
    return await createApiClient().getDisclaimer();
  });

  const parsedData = new DOMParser().parseFromString(
    DOMPurify.sanitize(data),
    "text/html"
  );

  const disclaimerText =
    parsedData.body.getElementsByTagName("p").item(0)?.innerHTML || null;

  return isLoading || isFetching ? (
    <Skeleton
      active={isLoading || isFetching}
      loading={isLoading || isFetching}
      avatar={false}
      paragraph={{ rows: 10 }}
    />
  ) : (
    <TabInfoContent style={{ fontFamily: "Times, serif" }}>
      {disclaimerText}
    </TabInfoContent>
  );
};
