import React from "react";

export const AvgAMIcon: React.FC = () => {
  return (
    <svg
      width="70px"
      height="70px"
      viewBox="0 0 70 70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Patient-MyHealthStatus-MyCurrentStatus-MyBloodPressure-V1.1-O2"
          transform="translate(-551.000000, -172.000000)"
        >
          <g id="Group-3-Copy-13" transform="translate(527.000000, 148.000000)">
            <g
              id="average-am-bp-icon"
              transform="translate(24.000000, 24.000000)"
            >
              <path
                d="M35,-2.72848411e-12 L35,-2.72848411e-12 C54.3299662,-2.73203496e-12 70,15.6700338 70,35 L70,35 C70,54.3299662 54.3299662,70 35,70 L35,70 C15.6700338,70 -7.27359038e-12,54.3299662 -7.27595761e-12,35 L-7.27595761e-12,35 C-7.27832485e-12,15.6700338 15.6700338,-2.72493325e-12 35,-2.72848411e-12 Z"
                id="Rectangle-Copy-204"
                fill="#EBF3FB"
              ></path>
              <g
                id="Group-7-Copy"
                transform="translate(11.000000, 12.000000)"
                stroke="#212B40"
                strokeWidth="2"
              >
                <circle
                  id="Oval-Copy"
                  fill="#F1D22F"
                  cx="24"
                  cy="24"
                  r="15"
                ></circle>
                <path d="M48,24.5 L43,24.5" id="Path-31"></path>
                <path
                  d="M41.5814755,9.41852455 L36.5814755,9.41852455"
                  id="Path-31-Copy-6"
                  transform="translate(39.081475, 9.418525) rotate(-45.000000) translate(-39.081475, -9.418525) "
                ></path>
                <path
                  d="M26.5,46 L20.5,46"
                  id="Path-31-Copy-2"
                  transform="translate(23.500000, 46.000000) rotate(-270.000000) translate(-23.500000, -46.000000) "
                ></path>
                <path
                  d="M42.7279221,39.9350288 L36.7279221,39.9350288"
                  id="Path-31-Copy-7"
                  transform="translate(39.727922, 39.935029) rotate(-315.000000) translate(-39.727922, -39.935029) "
                ></path>
                <path d="M4.98549349,24.5 L0,24.5" id="Path-31-Copy"></path>
                <path
                  d="M11.404018,39.5814755 L6.41852455,39.5814755"
                  id="Path-31-Copy-8"
                  transform="translate(8.918525, 39.581475) rotate(-45.000000) translate(-8.918525, -39.581475) "
                ></path>
                <path
                  d="M25.9854935,2.5 L21,2.5"
                  id="Path-31-Copy-3"
                  transform="translate(23.500000, 2.500000) rotate(-270.000000) translate(-23.500000, -2.500000) "
                ></path>
                <path
                  d="M10.6969113,9.41852455 L5.71141777,9.41852455"
                  id="Path-31-Copy-9"
                  transform="translate(8.211418, 9.418525) rotate(-315.000000) translate(-8.211418, -9.418525) "
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
