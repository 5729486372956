import Row from "antd/lib/row";
import Col from "antd/lib/col";
import React, { useState } from "react";
import styled from "styled-components";
import { TopbarExtension } from "components/topbarExtension";
import { AllMessagesTable } from "./allMessagesTable";
import { ExtensionItems } from "./extensionItems";
import { MessageSection } from "./messageSection";
import { ChannelData, UserInfoMap } from "sharedTypes";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";

export enum Message {
  AllMessages = "All Messages",
  PatientLinkedMessages = "Patient Linked Messages",
  DirectMessages = "Direct Messages"
}
export interface SelectedConversationUserInfo {
  readonly userInfo: UserInfoMap;
  readonly channel: ChannelData;
}

const INCLUDE_PRV_CHATS = true;
const SHOW_LAST_MESSAGE = false;

export const MessagesPage: React.FC = () => {
  const [searchNameValue, setSearchNameValue] = useState("");
  const [searchSpecialtyValue, setSearchSpecialtyValue] = useState("");
  const [messageType, setMessageType] = useState<Message>(Message.AllMessages);
  const [selectedConversationUserInfo, setSelectedConversationUserInfo] =
    useState<SelectedConversationUserInfo | null>(null);
  const [showMessages, setShowMessages] = useState(false);

  const {
    isLoading: isLoadingAllUserChannelsList,
    data: allUserChannelsListData,
    error: isAllUserChannelsListError,
    isFetching: isAllUserChannelsListRefetchLoading
  } = useQuery("all-messages-list", async () => {
    return await createApiClient().getUserChannels(
      sessionStorage.getItem("userName") || "",
      INCLUDE_PRV_CHATS,
      SHOW_LAST_MESSAGE
    );
  });

  const onCloseMessageContainer = () => {
    setShowMessages(false);
    setSelectedConversationUserInfo(null);
  };

  return (
    <>
      <TopbarExtension>
        <ExtensionItems
          setSearchNameValue={setSearchNameValue}
          setSearchSpecialtyValue={setSearchSpecialtyValue}
          setMessageType={setMessageType}
          messageType={messageType}
        />
      </TopbarExtension>
      <Row gutter={[20, 0]}>
        <ColStyled span={showMessages ? 16 : 24}>
          <AllMessagesTable
            messageType={messageType}
            selectedConversationUserInfo={selectedConversationUserInfo}
            setSelectedConversationUserInfo={setSelectedConversationUserInfo}
            setShowMessages={setShowMessages}
            searchNameValue={searchNameValue}
            searchSpecialtyValue={searchSpecialtyValue}
            allUserChannelsListData={allUserChannelsListData}
            isLoadingAllUserChannelsList={isLoadingAllUserChannelsList}
            isAllUserChannelsListError={isAllUserChannelsListError}
            isAllUserChannelsListRefetchLoading={
              isAllUserChannelsListRefetchLoading
            }
          />
        </ColStyled>
        <ColStyled span={showMessages ? 8 : 0}>
          <MessageSection
            selectedConversationUserInfo={selectedConversationUserInfo}
            onCloseMessageContainer={onCloseMessageContainer}
          />
        </ColStyled>
      </Row>
    </>
  );
};

const ColStyled = styled(Col)`
  height: calc(100vh - 155px);
`;
