import React from "react";
import styled from "styled-components";
import { LabelContainer, LabelText } from "./textInput";
import DatePicker from "antd/lib/date-picker";
import { Moment } from "moment";
import "./picker.css";

interface SelectInputProps {
  readonly label: string;
  readonly value: Moment | null;
  readonly placeholder?: string;
  readonly customDateFormat?: string;
  readonly onChange: (value: Moment | null) => void;
  readonly style?: React.CSSProperties;
  readonly autoFocus?: boolean;
  readonly open?: boolean;
  readonly panelRender?: (originalPanel: React.ReactNode) => React.ReactNode;
  readonly dropdownClassName?: string;
  readonly disabledDate?: (date: Moment) => boolean;
  readonly picker?:
    | "time"
    | "date"
    | "week"
    | "month"
    | "quarter"
    | "year"
    | undefined;
  readonly disabled?: boolean;
}

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  input {
    padding-top: 10px;
  }
`;

export const DateInput: React.FC<SelectInputProps> = ({
  label,
  value,
  placeholder,
  customDateFormat,
  onChange,
  style,
  autoFocus,
  open,
  panelRender,
  dropdownClassName,
  disabledDate,
  picker,
  disabled
}) => {
  return (
    <div style={style} itemType={"datetime-local"}>
      <LabelContainer>
        <LabelText>{label}</LabelText>
      </LabelContainer>
      <input type="datetime-local" style={{ display: "none" }} />
      <StyledDatePicker
        value={value}
        open={open}
        panelRender={panelRender}
        dropdownClassName={dropdownClassName}
        autoFocus={autoFocus}
        format={customDateFormat}
        placeholder={placeholder}
        picker={picker}
        disabledDate={disabledDate}
        onChange={onChange}
        getPopupContainer={(triggerNode: HTMLElement) =>
          triggerNode.parentNode as HTMLElement
        }
        disabled={disabled}
        dropdownAlign={{
          overflow: {
            adjustX: 0,
            adjustY: 0
          }
        }}
      />
    </div>
  );
};
