import { message } from "antd";
import Spin from "antd/lib/spin";
import { sortBy } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { CommonErrorMessage } from "components/CommonErrorMessage";

import { MessageContainer } from "components/messageContainer";
import { MessageData } from "components/messageContainer/messageContainer";
import { PerspectiveTypes } from "components/messageItem/messageItem";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { AllChatsMessageData, UserInfoMap } from "sharedTypes";
import { SelectedConversationUserInfo } from "pages/messages/messages";

export const convertAllChatsToCustomMessageData = (
  allChatMessages: AllChatsMessageData[],
  userInfo: UserInfoMap,
  loggedInUsername: string
): MessageData[] => {
  return sortBy(
    allChatMessages.map<MessageData>(message => {
      if (message.regarding) {
        return {
          message_id: message.message_id,
          content: message.content,
          perspective:
            message.sender === loggedInUsername
              ? PerspectiveTypes.OtherProvider
              : PerspectiveTypes.Patient,
          picture: "",
          sender_first_name: userInfo[message.sender].first_name || "",
          sender_last_name: userInfo[message.sender].last_name,
          timestamp: moment(
            message.created_time,
            "yyyy/MM/DD, HH:mm:ss"
          ).valueOf(),
          sender_degree: userInfo[message.sender].degree || "",
          receiver_first_name: userInfo[message.receiver].first_name || "",
          receiver_last_name: userInfo[message.receiver].last_name,
          regarding_first_name: userInfo[message.regarding].first_name || "",
          regarding_last_name: userInfo[message.regarding].last_name || "",
          receiver_degree: userInfo[message.receiver].degree || ""
        };
      }
      return {
        message_id: message.message_id,
        content: message.content,
        perspective:
          message.sender === loggedInUsername
            ? PerspectiveTypes.OtherProvider
            : PerspectiveTypes.Patient,
        picture: "",
        sender_first_name: userInfo[message.sender].first_name || "",
        sender_last_name: userInfo[message.sender].last_name,
        timestamp: moment(
          message.created_time,
          "yyyy/MM/DD, HH:mm:ss"
        ).valueOf(),
        degree: userInfo[message.sender].degree || "",
        receiver_first_name: userInfo[message.receiver].first_name || "",
        receiver_last_name: userInfo[message.receiver].last_name
      };
    }),
    "timestamp"
  );
};

export const CaregiverAllMessagesPage: React.FC = () => {
  const userContext = useContext<UserContextState>(UserContext);
  const navigate = useNavigate();
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const unreadMessageNotifications = useMemo(() => {
    const unseenNotifications =
      notificationsContext.filterMessageNotificationsByStatus(
        notificationsContext.notifications?.messages || [],
        "unread"
      );
    return unseenNotifications.filter(
      item => String(userId) === String(item.notified_internal_id)
    );
  }, [notificationsContext.notifications?.messages]);

  const userId = sessionStorage.getItem("userId") || "";

  const { refetch: getNotificationsList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        "provider",
        "",
        ""
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      }
    }
  );

  const { mutate: markNotification } = useMutation(
    "mark-notification",
    createApiClient().putNotifications,
    {
      onSuccess: () => {
        getNotificationsList();
      },
      onError: () => {
        message.error("There was a problem updating notifications.");
      }
    }
  );

  const {
    isLoading: isAllChatsLoading,
    data: allChatsData,
    error: allChatsError,
    isFetching: isAllChatsFetching,
    refetch: getAllChats
  } = useQuery(
    "get_all_chats",
    async () => {
      return await createApiClient().getAllChats(
        userContext.userData?.userName || "",
        undefined,
        undefined,
        undefined,
        true
      );
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (userContext.userData) {
      getAllChats();
    }
  }, [userContext.userData]);

  const isLoading = useMemo(
    () => isAllChatsFetching || isAllChatsLoading,
    [isAllChatsFetching, isAllChatsLoading]
  );

  const paginationData = useMemo(() => {
    if (allChatsData) {
      if (
        allChatsData.pagination.some(
          paginationData => paginationData.next_token !== null
        )
      ) {
        return allChatsData.pagination;
      }
    }
    return null;
  }, [allChatsData]);

  const selectedConversationUserInfo: SelectedConversationUserInfo | null =
    useMemo(() => {
      if (allChatsData) {
        return {
          userInfo: allChatsData.user_info,
          channel: {
            channel_name: "",
            channel_arn: "",
            is_patient_enabled: 0,
            is_patient_linked_channel: 0,
            pat_uname: "",
            user1_uname: "",
            user2_uname: "",
            latest_message: "",
            latest_message_timestamp: ""
          }
        };
      }
      return null;
    }, [allChatsData]);

  const messageData: MessageData[] = useMemo(() => {
    if (allChatsData && userContext.userData) {
      return convertAllChatsToCustomMessageData(
        allChatsData.messages,
        allChatsData.user_info,
        userContext.userData.userName || ""
      );
    }
    return [];
  }, [allChatsData, userContext.userData]);

  const onBackClick = () => navigate(-1);

  useEffect(() => {
    if (isLoading) return;
    unreadMessageNotifications.forEach(item => {
      markNotification({
        notificationId: item?.notification_id || "",
        body: { status: "read", type: "messages" }
      });
    });
  }, [unreadMessageNotifications, isLoading]);

  return (
    <>
      <TitleContainer>
        <AppButton
          buttonContent="Back"
          type={ButtonType.Secondary}
          onClick={onBackClick}
          style={{ marginRight: 10 }}
        />
        <TitleText>Message Inbox</TitleText>
      </TitleContainer>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            placeItems: "center"
          }}
        >
          <Spin />
        </div>
      ) : allChatsError ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            placeItems: "center"
          }}
        >
          <CommonErrorMessage message="Failed to fetch conversation" />
        </div>
      ) : (
        <MessageContainer
          messageItemWidth="55%"
          noFooter
          allMessagesFormat={true}
          picture=""
          sender=""
          specialization=""
          subject=""
          messageData={messageData}
          style={{ height: "calc(62vh + 94px)" }}
          nextToken={null}
          pagination={paginationData}
          selectedConversationUserInfo={selectedConversationUserInfo}
          userIsPatient={true}
        />
      )}
    </>
  );
};

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 10px;
`;

const TitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-top: 10px;
  padding-left: 10px;
`;
