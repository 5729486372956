import React, { createContext, useState, useContext, ReactNode } from "react";

// Define the shape of the context state
interface DraftBillContextType {
  draftBillId: string | null;
  setDraftBillId: (id: string) => void;
}

// Create the context
const DraftBillContext = createContext<DraftBillContextType | undefined>(
  undefined
);

// Provider component
export const DraftBillProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [draftBillId, setDraftBillId] = useState<string | null>(null);

  return (
    <DraftBillContext.Provider value={{ draftBillId, setDraftBillId }}>
      {children}
    </DraftBillContext.Provider>
  );
};

// Custom hook to use the context
export const useDraftBillContext = () => {
  const context = useContext(DraftBillContext);
  if (!context) {
    throw new Error(
      "useDraftBillContext must be used within a DraftBillProvider"
    );
  }
  return context;
};
