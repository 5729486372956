import React, { createContext, useState } from "react";
import { lowerCaseRemoveSpaceRemoveSpecialCharacters } from "helpers/utils";
import {
  AllNotificationProps,
  MessageNotificationProps,
  NotificationListItem,
  NotificationProps
} from "sharedTypes";

export const notificationStatusFilterOptions: {
  label: string;
  value: string;
}[] = [
  {
    label: "All",
    value: ""
  },
  {
    label: "Unread",
    value: "unread"
  },
  {
    label: "Read",
    value: "read"
  }
];

export const notificationTypeFilterOptions: { label: string; value: string }[] =
  [
    {
      label: "All",
      value: ""
    },
    {
      label: "Symptoms",
      value: "symptoms"
    },
    {
      label: "Remote Vitals",
      value: "remote_vitals"
    },
    {
      label: "Medication",
      value: "medications"
    },
    {
      label: "Care Team",
      value: "care_team"
    },
    {
      label: "Messages",
      value: "messages"
    },
    {
      label: "Survey",
      value: "survey"
    }
  ];

export enum NotificationAlertLevels {
  HIGH = "high",
  MODERATE = "moderate",
  LOW = "low"
}

export type NotificationsContextState = {
  readonly notifications: AllNotificationProps | null;
  readonly setNotifications: (
    notifications: AllNotificationProps | null
  ) => void;
  readonly getAlertItems: (
    relevantNotifications: NotificationProps[],
    patientId?: string
  ) => { high: string[]; moderate: string[] } | null;
  readonly filterNotificationsByStatus: (
    relevantNotifications: NotificationProps[],
    status: string
  ) => NotificationProps[];
  readonly filterNotificationsByPatient: (
    relevantNotifications: NotificationProps[],
    patientId: string
  ) => NotificationProps[];
  readonly filterMessageNotificationsByStatus: (
    relevantNotifications: MessageNotificationProps[],
    status: string
  ) => MessageNotificationProps[];
  readonly filterMessageNotificationsByPatient: (
    relevantNotifications: MessageNotificationProps[],
    patientId: string
  ) => MessageNotificationProps[];
  readonly filterNotificationsByStatusAndPatient: (
    relevantNotifications: NotificationProps[],
    patientId: string,
    status: string
  ) => NotificationProps[];
  readonly filterMessageNotificationsByStatusAndID: (
    relevantNotifications: MessageNotificationProps[],
    patientId: string,
    status: string
  ) => MessageNotificationProps[];
  readonly filterMessageNotificationsByStatusAndChannelName: (
    relevantNotifications: MessageNotificationProps[],
    patientId: string,
    status: string
  ) => MessageNotificationProps[];
  readonly filterNotificationsListByStatusAndType: (
    relevantNotifications: NotificationListItem[],
    status: string,
    type: string
  ) => NotificationListItem[];
  readonly patientSurveyNotifications: number | null;
  readonly setPatientSurveyNotifications: (
    patientSurveyNotifications: number | null
  ) => void;
};

const initialValue: NotificationsContextState = {
  notifications: null,
  setNotifications: () => null,
  getAlertItems: () => null,
  filterNotificationsByStatus: () => [],
  filterNotificationsByPatient: () => [],
  filterNotificationsByStatusAndPatient: () => [],
  filterMessageNotificationsByStatus: () => [],
  filterMessageNotificationsByPatient: () => [],
  filterMessageNotificationsByStatusAndID: () => [],
  filterMessageNotificationsByStatusAndChannelName: () => [],
  filterNotificationsListByStatusAndType: () => [],
  patientSurveyNotifications: null,
  setPatientSurveyNotifications: () => null
};

export const NotificationsContext =
  createContext<NotificationsContextState>(initialValue);
NotificationsContext.displayName = "NotificationsContext";

export const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] =
    useState<AllNotificationProps | null>(null);

  const [patientSurveyNotifications, setPatientSurveyNotifications] = useState<
    number | null
  >(null);

  const getAlertItems = (
    relevantNotifications: NotificationProps[],
    patientId?: string
  ) => {
    const alertNotifications: { high: string[]; moderate: string[] } = {
      high: [],
      moderate: []
    };

    if (patientSurveyNotifications == null) {
      setPatientSurveyNotifications(
        Number(sessionStorage.getItem("surveyNotification"))
      );
    }

    if (patientSurveyNotifications && patientSurveyNotifications > 0) {
      alertNotifications.high.push(
        lowerCaseRemoveSpaceRemoveSpecialCharacters("Surveys")
      );
    }

    if (patientId) {
      relevantNotifications?.forEach(item => {
        if (
          item &&
          item.status !== "read" &&
          String(patientId) === String(item.patient_id)
        ) {
          if (item.severity === 2) {
            alertNotifications.high.push(
              lowerCaseRemoveSpaceRemoveSpecialCharacters(item.type)
            );
          }
          if (item.severity === 1) {
            alertNotifications.moderate.push(
              lowerCaseRemoveSpaceRemoveSpecialCharacters(item.type)
            );
          }
        }
      });
    } else {
      relevantNotifications?.forEach(item => {
        if (item.status !== "read") {
          if (item.severity === 2) {
            alertNotifications.high.push(
              lowerCaseRemoveSpaceRemoveSpecialCharacters(item.type)
            );
          }
          if (item.severity === 1) {
            alertNotifications.moderate.push(
              lowerCaseRemoveSpaceRemoveSpecialCharacters(item.type)
            );
          }
        }
      });
    }

    return alertNotifications;
  };

  const filterNotificationsByStatus = (
    relevantNotifications: NotificationProps[],
    status: string
  ) => {
    return relevantNotifications?.filter(item => item.status === status) || [];
  };

  const filterNotificationsByPatient = (
    relevantNotifications: NotificationProps[],
    patientId: string
  ) => {
    return (
      relevantNotifications?.filter(
        item => String(item.patient_id) === String(patientId)
      ) || []
    );
  };

  const filterMessageNotificationsByStatus = (
    relevantNotifications: MessageNotificationProps[],
    status: string
  ) => {
    return relevantNotifications?.filter(item => item.status === status) || [];
  };

  const filterMessageNotificationsByStatusAndChannelName = (
    relevantNotifications: MessageNotificationProps[],
    channel_name: string,
    status: string
  ) => {
    return (
      relevantNotifications?.filter(
        item => item.status === status && item.channel_name === channel_name
      ) || []
    );
  };

  const filterMessageNotificationsByPatient = (
    relevantNotifications: MessageNotificationProps[],
    patientId: string
  ) => {
    return (
      relevantNotifications?.filter(
        item =>
          (String(item.sender_internal_id) ||
            String(item.receiver_internal_id)) === String(patientId)
      ) || []
    );
  };

  const filterNotificationsByStatusAndPatient = (
    relevantNotifications: NotificationProps[],
    patientId: string,
    status: string
  ) => {
    const filteredNotifications = relevantNotifications?.filter(
      item => String(item.patient_id) === patientId && item.status === status
    );

    return filteredNotifications || [];
  };

  const filterMessageNotificationsByStatusAndID = (
    relevantNotifications: MessageNotificationProps[],
    id: string,
    status: string
  ) => {
    const filteredNotifications = relevantNotifications?.filter(
      item =>
        (String(item.sender_internal_id) === id ||
          String(item.receiver_internal_id) === id) &&
        item.status === status
    );
    return filteredNotifications || [];
  };

  const filterNotificationsListByStatusAndType = (
    relevantNotifications: NotificationListItem[],
    status: string,
    type: string
  ) => {
    if (status && type) {
      return relevantNotifications?.filter(
        item => item.status === status && item.type === type
      );
    }
    if (!status && type) {
      return relevantNotifications?.filter(item => item.type === type);
    }
    if (status && !type) {
      return relevantNotifications?.filter(item => item.status === status);
    }
    return relevantNotifications;
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        setNotifications,
        getAlertItems,
        filterNotificationsByStatus,
        filterNotificationsByPatient,
        filterMessageNotificationsByStatus,
        filterMessageNotificationsByPatient,
        filterNotificationsByStatusAndPatient,
        filterMessageNotificationsByStatusAndID,
        filterMessageNotificationsByStatusAndChannelName,
        filterNotificationsListByStatusAndType,
        patientSurveyNotifications,
        setPatientSurveyNotifications
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
