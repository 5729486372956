import Skeleton from "antd/lib/skeleton";
import React from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import DOMPurify from "dompurify";

export const PrivacyPolicy: React.FC = () => {
  const { data, isFetching, isLoading } = useQuery(
    "privacy policy",
    async () => {
      return await createApiClient().getPrivacyPolicy();
    }
  );

  const cleanHTMLData = DOMPurify.sanitize(data);

  return isFetching || isLoading ? (
    <Skeleton
      active={isFetching || isLoading}
      loading={isFetching || isLoading}
      avatar={false}
      paragraph={{ rows: 10 }}
    />
  ) : (
    <>
      <div dangerouslySetInnerHTML={{ __html: cleanHTMLData as string }} />
    </>
  );
};
