import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "./modal.css";
import Bplist from "pages/bpAlerts/bplist";
import { BpAletrsIcon } from "./SVGIcons";
import { useIsExpanded } from "contextApis/expandContext";

const BpAlertsComponent: HTMLElement | null =
  document.getElementById("modal-dom");

const Modal: React.FC = () => {
  const { isExpanded, setIsExpanded } = useIsExpanded();
  const [is_Expanded, set_IsExpanded] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);

  if (!BpAlertsComponent) {
    return null;
  }

  useEffect(() => {
    return set_IsExpanded(isExpanded);
  }, [isExpanded]);

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    set_IsExpanded(false);
    setIsExpanded(false);
  };

  return ReactDOM.createPortal(
    <div ref={modalRef} className={`Bp ${is_Expanded ? "expanded" : " "}`}>
      {is_Expanded && (
        <>
          <div className="heading-notification">
            <div className="notification">Notifications</div>

            <button className="close-btn" onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M5 12.5H19"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="bp-list">
            <Bplist />
          </div>
        </>
      )}
      {!is_Expanded && (
        <>
          <div className="bp-title">
            <BpAletrsIcon />
          </div>
        </>
      )}
    </div>,
    BpAlertsComponent
  );
};

export default Modal;
