import { Auth } from "aws-amplify";
import React, { createContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { UserData } from "sharedTypes";
import appConfig from "Config";

export type UserContextState = {
  readonly userData: UserData | undefined;
  readonly setLoggedInUserData: (userData: UserData) => void;
  readonly isAuthenticated: boolean;
  readonly onLogin: () => void;
  readonly onLogout: (path?: string) => void;
  readonly userIdentityId: string;
  readonly onUserIdentityIdChange: (id: string) => void;
  readonly chimeBearerArn: string;
  readonly fromConsent: boolean;
  readonly setFromConsentFlag: (flag: boolean) => void;
  readonly setPdfByteString: React.Dispatch<React.SetStateAction<string>>;
  readonly pdfByteString: string;
  readonly setSignerName: React.Dispatch<React.SetStateAction<string>>;
  readonly signer_name: string;
  readonly setSignerRelationship: React.Dispatch<React.SetStateAction<string>>;
  readonly signer_relationship: string;
  readonly setConsentName: React.Dispatch<React.SetStateAction<string>>;
  readonly consent_name: string;
  readonly setPatientSignature: React.Dispatch<React.SetStateAction<string>>;
  readonly patientSignature: string;
  readonly condition: boolean;
  readonly setCondition: React.Dispatch<React.SetStateAction<boolean>>;
  readonly patientInitialImageRef: React.MutableRefObject<HTMLImageElement | null>;
  readonly setIsMobile: (bol: boolean) => void;
  readonly isMobile: boolean;
  readonly setIsProviderModal: (flag: any) => void;
  readonly isProviderModal: any;
};

const initialValue: UserContextState = {
  userData: undefined,
  setLoggedInUserData: () => null,
  isAuthenticated: false,
  onLogin: () => null,
  onLogout: () => null,
  userIdentityId: "",
  onUserIdentityIdChange: () => null,
  chimeBearerArn: "",
  fromConsent: false,
  setFromConsentFlag: () => null,
  setPdfByteString: () => null,
  pdfByteString: "",
  setSignerName: () => null,
  signer_name: "",
  setSignerRelationship: () => null,
  signer_relationship: "",
  setConsentName: () => null,
  consent_name: "",
  setPatientSignature: () => null,
  patientSignature: "",
  condition: false,
  setCondition: () => null,
  patientInitialImageRef: React.createRef<HTMLImageElement>(),
  setIsMobile: () => null,
  isMobile: false,
  setIsProviderModal: () => null,
  isProviderModal: ""
};

export const UserContext = createContext<UserContextState>(initialValue);
UserContext.displayName = "UserContext";

export const UserProvider: React.FC = ({ children }) => {
  const storedValue = sessionStorage.getItem("isMobile");

  const isMoibleDevice = storedValue && JSON.parse(storedValue);

  const currentPath = window.location.pathname;

  const IfPathReportSymtoms = currentPath === "/mobile-symptom-forms";

  if (IfPathReportSymtoms === true) {
    sessionStorage.setItem("isMobile", String(false));
  }

  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [userIdentityId, setUserIdentityId] = useState<string>("");
  const [chimeBearerArn, setChimeBearerArn] = useState<string>("");
  const [fromConsent, setFromConsent] = useState<boolean>(false);
  const [pdfByteString, setPdfByteString] = useState<string>("");
  const [signer_name, setSignerName] = useState<string>("");
  const [signer_relationship, setSignerRelationship] = useState<string>("");
  const [consent_name, setConsentName] = useState<string>("");
  const [patientSignature, setPatientSignature] = useState<string>("");
  const [condition, setCondition] = useState<boolean>(false);
  const patientInitialImageRef = useRef<HTMLImageElement | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(
    IfPathReportSymtoms ? false : isMoibleDevice === null ? true : false
  );
  const [isProviderModal, setIsProviderModal] = useState<any>("");

  const onLogin = () => setAuthenticated(true);

  const onLogout = (path?: string) => {
    const rememberMe = sessionStorage.getItem("login_remember");
    const userName = sessionStorage.getItem("login_username");
    Auth.signOut();
    sessionStorage.clear();
    sessionStorage.setItem("login_remember", String(rememberMe));
    if (rememberMe) {
      sessionStorage.setItem("login_username", String(userName));
    }
    setAuthenticated(false);
    navigate(path || "/");
  };

  const setLoggedInUserData = (userData: UserData) => setUserData(userData);

  const onUserIdentityIdChange = (id: string) => {
    setUserIdentityId(id);
    setChimeBearerArn(`${appConfig.appInstanceArn}/user/${id}`);
  };

  const setFromConsentFlag = (flag: boolean) => setFromConsent(flag);
  const values = {
    userData,
    setLoggedInUserData,
    isAuthenticated,
    onLogin,
    onLogout,
    userIdentityId,
    onUserIdentityIdChange,
    chimeBearerArn,
    fromConsent,
    setFromConsentFlag,
    pdfByteString,
    setPdfByteString,
    setSignerName,
    signer_name,
    setSignerRelationship,
    signer_relationship,
    setConsentName,
    consent_name,
    setPatientSignature,
    patientSignature,
    setCondition,
    condition,
    patientInitialImageRef,
    setIsMobile,
    isMobile,
    setIsProviderModal,
    isProviderModal
  };

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
