import Skeleton from "antd/lib/skeleton";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { UserRoles } from "constants/roles";
import DOMPurify from "dompurify";

export const TermsAndConditions: React.FC = () => {
  const {
    data: providerTermsData,
    isFetching: provFetching,
    isLoading: provLoading
  } = useQuery("providerTerms", async () => {
    return await createApiClient().getProviderTerms();
  });

  const {
    data: patientTermsData,
    isFetching: patientFetching,
    isLoading: patientLoading
  } = useQuery("patientTerms", async () => {
    return await createApiClient().getPatientTerms();
  });

  const userRole = sessionStorage.getItem("userRole");

  const isTermsLoading = useMemo(
    () => patientFetching || patientLoading || provFetching || provLoading,
    [patientFetching, patientLoading, provFetching, provLoading]
  );

  const userIsCaregiverOrPatient =
    userRole === UserRoles.CAREGIVER ||
    userRole === UserRoles.CAREGIVERS ||
    userRole === UserRoles.PATIENT;

  const cleanPatientTermsData = DOMPurify.sanitize(patientTermsData);

  const cleanProviderTermsData = DOMPurify.sanitize(providerTermsData);

  return isTermsLoading ? (
    <Skeleton
      active={isTermsLoading}
      loading={isTermsLoading}
      avatar={false}
      paragraph={{ rows: 10 }}
    />
  ) : (
    <>
      {userIsCaregiverOrPatient ? (
        <div dangerouslySetInnerHTML={{ __html: cleanPatientTermsData }} />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: cleanProviderTermsData }} />
      )}
    </>
  );
};
