import moment from "moment";
import { lowerCaseRemoveSpace } from "helpers/utils";
import { ExtendedSymptomData } from "pages/symptoms/SymptomsModal";

export const nauseaMapper = ({
  filteredExtendedSymptomData,
  mapperType,
  mapperValue
}: {
  filteredExtendedSymptomData: ExtendedSymptomData[] | null;
  mapperType?: string | undefined;
  mapperValue?: string | number | undefined;
}) => {
  const severity: [string, number, string][] = [];
  const frequency: [string, number, string][] = [];

  const sortedData = filteredExtendedSymptomData?.sort(
    (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
  );

  if (mapperType) {
    let tooltipValue = "";
    if (lowerCaseRemoveSpace(mapperType) === "frequency") {
      switch (mapperValue) {
        case 1:
          tooltipValue = "Rarely (1-3 times)";
          break;
        case 4:
          tooltipValue = "Occasionally (4-7 times)";
          break;
        case 7:
          tooltipValue = "Frequently (8-15 times)";
          break;
        case 10:
          tooltipValue = "Nearly everyday";
          break;
      }
    } else if (lowerCaseRemoveSpace(mapperType) === "severity") {
      switch (mapperValue) {
        case 1:
          tooltipValue = "Mild nausea only";
          break;
        case 5:
          tooltipValue = "Moderate nausea, occasional vomiting";
          break;
        case 10:
          tooltipValue = "Severe nausea / retching / vomiting";
          break;
      }
    }
    return tooltipValue;
  }

  sortedData?.forEach(item => {
    item.data?.map(item2 => {
      if (lowerCaseRemoveSpace(item2?.key) === "frequency") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "rarely(1-3times)":
            value = 1;
            break;
          case "occasionally(4-7times)":
            value = 4;
            break;
          case "frequently(8-15times)":
            value = 7;
            break;
          case "nearlyeveryday":
            value = 10;
            break;
        }
        frequency.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Frequency"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "severity") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "mildnauseaonly":
            value = 1;
            break;
          case "moderatenausea,occasionalvomiting":
            value = 5;
            break;
          case "severenausea/retching/vomiting":
            value = 10;
            break;
        }
        severity.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Severity"
        ]);
      }
    });
  });

  return [severity, frequency];
};
