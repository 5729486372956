import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Col from "antd/lib/col";
import React, { useState } from "react";
import styled from "styled-components";

// import { AppButton } from "../../components/button";
// import { ButtonType } from "../../components/button/appButton";
import { AppSelect } from "components/inputs";
import { SearchBox } from "components/searchBox";
import { Message } from "./messages";
// import { AddMessageIcon } from "./addMessageIcon";

interface ExtensionItemsProps {
  setSearchNameValue(value: string): void;
  setSearchSpecialtyValue(value: string): void;
  setMessageType(message: Message): void;
  messageType: Message;
}

export const ExtensionItems: React.FC<ExtensionItemsProps> = ({
  setMessageType,
  messageType,
  setSearchNameValue,
  setSearchSpecialtyValue
}) => {
  const [filterType, setFilterType] = useState("Name");

  const filterOptions: { label: string; value: string }[] = [
    {
      label: "Name",
      value: "Name"
    },
    {
      label: "Specialty",
      value: "Specialty"
    }
  ];

  const messageOptions: { label: string; value: Message }[] = [
    {
      label: "All Messages",
      value: Message.AllMessages
    },
    {
      label: "Patient-Linked Messages",
      value: Message.PatientLinkedMessages
    },
    {
      label: "Direct Messages",
      value: Message.DirectMessages
    }
  ];

  const onTypeChange = (type: Message) => {
    setMessageType(type);
  };

  return (
    <RowStyled>
      <TitleCol span={4}>All Messages</TitleCol>
      <FiltersCol span={16}>
        <Space>
          <AppSelect
            value={filterType.charAt(0).toUpperCase() + filterType.slice(1)}
            label="Filter By"
            onChange={type => {
              setFilterType(type);
              setSearchSpecialtyValue("");
              setSearchNameValue("");
            }}
            style={{ marginRight: 5, width: "120px" }}
            options={filterOptions}
            dropdownStyle={{ borderRadius: "8px" }}
          />
          <SearchBox
            placeholder={`Search by ${filterType.toLowerCase()}`}
            onChange={e => {
              if (filterType === "Name") {
                setSearchSpecialtyValue("");
                setSearchNameValue(e.target.value);
              }
              if (filterType === "Specialty") {
                setSearchNameValue("");
                setSearchSpecialtyValue(e.target.value);
              }
            }}
          />
          <AppSelect
            value={messageType}
            label="Show"
            onChange={onTypeChange}
            style={{ marginRight: 5, width: "210px" }}
            options={messageOptions}
            dropdownStyle={{ borderRadius: "8px" }}
          />
        </Space>
      </FiltersCol>
      <AddMessageCol span={4}>
        {/* <AppButton
          type={ButtonType.Skeleton}
          style={{ width: "196px", height: "42px" }}
          buttonContent={
            <Row>
              <AddMessageIcon />
              <ButtonText>Add New Message</ButtonText>
            </Row>
          }
          onClick={() => "Do a thing"}
        /> */}
      </AddMessageCol>
    </RowStyled>
  );
};

const TitleCol = styled(Col)`
  padding: 20px;
  font-family: Century Gothic Bold;
`;

const FiltersCol = styled(Col)`
  padding: 10px;
`;

const AddMessageCol = styled(Col)`
  text-align: right;
  padding: 12px;
`;

// const ButtonText = styled.span`
//   padding-left: 5px;
//   margin-top: 2.5px;
// `;

const RowStyled = styled(Row)`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
`;
