import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Space from "antd/lib/space";
import React, { useEffect } from "react";
import styled from "styled-components";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";

import { Dropdown } from "components/dropDownBox";
import { AppSelect } from "components/inputs";
import { Colors } from "helpers/colors";
import { CareTeamCheckboxMenu } from "./careTeamCheckboxMenu";

interface CareTeamCheckboxDropdownProps {
  setShowDropdown(show: boolean): void;
  readonly showDropdown: boolean;
  readonly selectedCareTeamText: string;
  readonly allChecked: boolean;
  setAllChecked(checked: boolean): void;
  readonly applied: boolean;
  setApplied(applied: boolean): void;
  setMenuItems(
    obj: { desc: string; code: string; detail_desc: string; checked: boolean }[]
  ): void;
  menuItems: {
    desc: string;
    code: string;
    detail_desc: string;
    checked: boolean;
  }[];
}

export const CareTeamCheckboxDropdown: React.FC<
  CareTeamCheckboxDropdownProps
> = ({
  setShowDropdown,
  showDropdown,
  selectedCareTeamText,
  setAllChecked,
  allChecked,
  applied,
  setApplied,
  setMenuItems,
  menuItems
}) => {
  useEffect(() => {
    sessionStorage.setItem(
      "careTeamDropdownAllChecked",
      JSON.stringify(allChecked)
    );
  }, []);

  const applyFilters = () => {
    sessionStorage.setItem("careTeamCheckboxItems", JSON.stringify(menuItems));

    sessionStorage.setItem(
      "careTeamDropdownAllChecked",
      JSON.stringify(allChecked)
    );

    setApplied(true);
    setShowDropdown(false);
  };

  return (
    <Dropdown
      arrow
      borderradius="8px"
      trigger={["click"]}
      placement="bottomRight"
      dropDownMenuBody={
        <Row>
          <MenuCol>
            <CareTeamCheckboxMenu
              menuItems={menuItems}
              getCheckedItems={setMenuItems}
              applied={applied}
              showDropdown={showDropdown}
              setApplied={setApplied}
              allChecked={allChecked}
              setAllChecked={setAllChecked}
            />
          </MenuCol>
        </Row>
      }
      dropDownMenuFooter={
        <DiagnosisDropdownFooter>
          <Col
            style={{ textAlign: "right", width: "100%", paddingRight: "10px" }}
          >
            <Space>
              <AppButton
                type={ButtonType.Secondary}
                buttonContent="Cancel"
                onClick={() => setShowDropdown(false)}
                style={{ width: "120px" }}
              />
              <AppButton
                type={ButtonType.Primary}
                buttonContent="Apply"
                onClick={() => applyFilters()}
                style={{ width: "120px" }}
              />
            </Space>
          </Col>
        </DiagnosisDropdownFooter>
      }
      visible={showDropdown}
      setVisible={setShowDropdown}
    >
      <AppSelect
        value={selectedCareTeamText}
        label="Care Team"
        onChange={() => null}
        style={{
          marginRight: 5,
          width: "260px",
          pointerEvents: "none"
        }}
        options={[]}
      />
    </Dropdown>
  );
};

const MenuCol = styled(Col)`
  max-height: 312px;
  overflow: auto;
`;

const DiagnosisDropdownFooter = styled(Row)`
  background: ${Colors.White};
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid ${Colors.Lavender};
  height: 68px;
  font-size: 14px;
  margin-bottom: 3px;
  display: grid;
  place-items: center;
`;
