import React from "react";
import "./BillingAlertModal.css";
import { AppModal } from "components/modal";

export interface ModalPromptContentProps {
  readonly title: string;
  readonly visible: boolean;
  readonly closeModal: () => void;
}

export const BillingAlertModal: React.FC<ModalPromptContentProps> = ({
  title,
  visible,
  closeModal
}) => {
  return (
    <AppModal
      visible={visible}
      title={title}
      okText="OK"
      onCancel={closeModal}
      onOk={closeModal}
      footer
    >
      <div className="text">
        To activate billing, please contact your organization&apos;s system
        administrator for assistance.
      </div>
    </AppModal>
  );
};
