import Divider from "antd/lib/divider";
import Skeleton from "antd/lib/skeleton";
import { get } from "lodash";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { AppAvatar } from "components/avatar";
import { AppCard } from "components/card";
import { CommonRoutesRef } from "constants/routes";
import { UserContext, UserContextState } from "contextApis/userContext";
import { startCase } from "helpers/utils";
import { PatientProfileData } from "sharedTypes";
import {
  ProfileHeaderContainer,
  AvatarContainer,
  AvatarTextContainer,
  AvatarNameText,
  ResetPasswordLinkContainer,
  ResetPasswordLink,
  ProfileContentContainer,
  GridItem,
  GridItemTitle,
  GridItemValue,
  GridItemGap,
  ContactNumberContainer,
  ContactNumberGap
} from "./style";

export const MyProfileCaregiverComponent: React.FC = () => {
  const { userData, onLogout } = useContext<UserContextState>(UserContext);
  const onResetPasswordClick = () => {
    onLogout(`/${CommonRoutesRef.ForgotPassword}`);
  };

  const userId = sessionStorage.getItem("userId");

  const { data, isFetching, isLoading } = useQuery<PatientProfileData>(
    "patient-profile",
    async () => {
      return await createApiClient().getPatientProfile(
        userId ? userId : "",
        true
      );
    }
  );

  const phoneNumberArray = data?.phoneNumbers || userData?.phoneNumbers || [];

  return (
    <>
      <AppCard cardWidth="100%" cardHeight="auto">
        <ProfileHeaderContainer>
          <AvatarContainer>
            <AppAvatar imageSrc={get(userData, "picture[0]", "")} size={80} />
            <AvatarTextContainer>
              {isLoading || isFetching ? (
                <Skeleton
                  loading={isFetching || isLoading}
                  active={isFetching || isLoading}
                  avatar={false}
                  paragraph={false}
                  style={{ width: 100 }}
                />
              ) : (
                <AvatarNameText>{`${get(userData, "lastName", "")}, ${get(
                  userData,
                  "firstName",
                  ""
                )}`}</AvatarNameText>
              )}
            </AvatarTextContainer>
          </AvatarContainer>
          <ResetPasswordLinkContainer>
            <ResetPasswordLink onClick={onResetPasswordClick}>
              Reset Password
            </ResetPasswordLink>
          </ResetPasswordLinkContainer>
        </ProfileHeaderContainer>
        <Divider />
        <ProfileContentContainer>
          <GridItem>
            <GridItemTitle topPadding>Date of Birth</GridItemTitle>
            {isLoading || isFetching ? (
              <Skeleton
                loading={isFetching || isLoading}
                active={isFetching || isLoading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              <GridItemValue bold>
                {startCase(get(userData, "dob", ""))}
              </GridItemValue>
            )}
            <GridItemGap />
            <GridItemTitle>Email Address</GridItemTitle>
            {isLoading || isFetching ? (
              <Skeleton
                loading={isFetching || isLoading}
                active={isFetching || isLoading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              <GridItemValue bold>{get(userData, "email", "")}</GridItemValue>
            )}
            <GridItemGap />
            <GridItemTitle>Role</GridItemTitle>
            {isLoading || isFetching ? (
              <Skeleton
                loading={isFetching || isLoading}
                active={isFetching || isLoading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              <GridItemValue bold>
                {startCase(userData?.role || "")}
              </GridItemValue>
            )}
            <GridItemGap />
            <GridItemTitle>Gender</GridItemTitle>
            {isLoading || isFetching ? (
              <Skeleton
                loading={isFetching || isLoading}
                active={isFetching || isLoading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              <GridItemValue bold>
                {userData?.gender
                  ? userData.gender.charAt(0).toUpperCase() +
                    userData.gender.slice(1)
                  : ""}
              </GridItemValue>
            )}
          </GridItem>
          <GridItem leftBorder>
            <GridItemTitle topPadding>Home Address</GridItemTitle>
            {isFetching || isLoading ? (
              <Skeleton
                loading={isFetching || isLoading}
                active={isFetching || isLoading}
                avatar={false}
                paragraph={false}
                style={{ width: 150 }}
              />
            ) : (
              <>
                <GridItemValue bold>
                  {get(userData, "home_addr_1", "")}
                </GridItemValue>
                <GridItemValue bold>
                  {get(userData, "home_addr_2", "")}
                </GridItemValue>
                <GridItemValue bold>{`${get(userData, "address_city", "")}${
                  data ? "," : ""
                } ${get(userData, "state", "") || ""} ${get(
                  userData,
                  "zipCode",
                  ""
                )}`}</GridItemValue>
              </>
            )}
            <GridItemGap />
            <GridItemTitle>Contact Number</GridItemTitle>
            <ContactNumberContainer>
              {isLoading || isFetching ? (
                <Skeleton
                  loading={isFetching || isLoading}
                  active={isFetching || isLoading}
                  avatar={false}
                  paragraph={{ rows: 1 }}
                  style={{ width: 100 }}
                />
              ) : (
                phoneNumberArray.map((phoneNumber, index) => (
                  <div key={index}>
                    <GridItemTitle>{phoneNumber.title}</GridItemTitle>
                    <GridItemValue>{phoneNumber.number}</GridItemValue>
                    {index < phoneNumberArray.length - 1 && (
                      <ContactNumberGap />
                    )}
                  </div>
                ))
              )}
            </ContactNumberContainer>
          </GridItem>
        </ProfileContentContainer>
      </AppCard>
    </>
  );
};
