import React, { ReactNode, useEffect, useState } from "react";
import Tabs from "antd/lib/tabs";
import styled from "styled-components";
import { Colors } from "helpers/colors";
import { TabsPosition } from "antd/lib/tabs";

const { TabPane } = Tabs;

interface TabsProps {
  readonly tabsContent: {
    key: string | number;
    tabName: string;
    content?: ReactNode;
  }[];
  readonly defaultActiveKey: string;
  readonly centered?: boolean;
  readonly tabPosition: TabsPosition;
  readonly className?: string;
  getSelectedTab?(key: string): void;
  activeKey?: string;
}

export const TabsComponent: React.FC<TabsProps> = ({
  tabsContent,
  defaultActiveKey,
  centered,
  tabPosition,
  className,
  getSelectedTab,
  activeKey
}) => {
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }
  return (
    <>
      <TabsStyled
        defaultActiveKey={defaultActiveKey}
        centered={centered}
        tabPosition={tabPosition}
        className={className}
        onChange={key => (getSelectedTab ? getSelectedTab(key) : null)}
        activeKey={activeKey}
      >
        {tabsContent.map(tab => {
          return (
            <TabPane tab={tab.tabName} key={tab.key}>
              {tab.content ? tab.content : null}
            </TabPane>
          );
        })}
      </TabsStyled>
    </>
  );
};

const TabsStyled = styled(Tabs)`
  //Tab button style
  .ant-tabs-tab-btn {
    margin-bottom: 9px;
    color: ${Colors.gothicRegular};
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Colors.gothicBold};
    font-family: Century Gothic Bold;
    font-weight: 600;
  }
  .ant-tabs-tab {
    font-size: 14px;
  }
  //Animated bar style
  .ant-tabs-ink-bar {
    padding: 2.5px;
    border-radius: 5px;
    box-shadow: 0 8px 6px -6px #999;
    background-color: ${Colors.DodgerBlue};
  }

  .ant-tabs-content-holder {
    padding: 2px;
    border-radius: 0px 0xp 8px 8px;
  }
  .ant-tabs-content {
    padding-top: 0px;
  }

  .ant-tabs-nav {
    margin-bottom: -2px;

    .ant-tabs-nav-list {
      margin-left: 10px;
    }
  }

  .ant-tabs-nav::before {
    /* For the line to the right and close to the tabs */
    border-color: #cbd5e1;
  }
`;
