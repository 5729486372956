import React, { useMemo, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import styled from "styled-components";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { SymptomsListResponse } from "sharedTypes";
import { getSymptomListData } from "pages/symptoms/utils";
// import { BloodPressureAccordion } from "./bloodPressureAccordion";
import { LatestBPIcon } from "./latestBPIcon";
import { MyBloodPressureModal } from "./myBloodPressureModal";
import { AvgAMIcon } from "./avgAMIcon";
import { AvgPMIcon } from "./avgPMIcon";

interface SymptomDetail {
  readonly key: string;
  readonly value: string;
}

export interface Symptom {
  readonly name: string;
  readonly description?: string;
  readonly data?: SymptomDetail[];
}

export interface ExtendedSymptomData extends Symptom {
  readonly timestamp: string;
  readonly reporterName: string;
  readonly reporterRole: string;
}

export const MyBloodPressureContent: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string>("");

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  // const mockInfo = [
  //   {
  //     question: "Mock question 1?",
  //     answer:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
  //   },
  //   {
  //     question: "Mock question 2?",
  //     answer: "mock answer 2"
  //   },
  //   {
  //     question: "Mock question 3?",
  //     answer: "mock answer 3"
  //   }
  // ];

  const mockResponse: SymptomsListResponse[] = [
    {
      enter_date: "01/05/2022",
      flag_read: 0,
      id: 1003,
      info_blob: null,
      info_text:
        "BP\n------\nBP taken on: 1/5/2022 2:36:12\nWhen: AM\nPosture: Sit\nBP symptoms or comments: Dizzyness or Lightheadedness,\nSubmitted_by: Grace Carter [physician]",
      patient_id: 75,
      reportedBy: "Grace Carter [physician]"
    },
    {
      enter_date: "01/05/2022",
      flag_read: 0,
      id: 1003,
      info_blob: null,
      info_text:
        "BP\n------\nBP taken on: 1/5/2022 2:36:12\nWhen: AM\nPosture: Sit\nBP symptoms or comments: Dizzyness or Lightheadedness,\nSubmitted_by: Grace Carter [physician]",
      patient_id: 75,
      reportedBy: "Grace Carter [physician]"
    },
    {
      enter_date: "01/05/2022",
      flag_read: 0,
      id: 1003,
      info_blob: null,
      info_text:
        "BP\n------\nBP taken on: 1/5/2022 2:36:12\nWhen: AM\nPosture: Sit\nBP symptoms or comments: Dizzyness or Lightheadedness,\nSubmitted_by: Grace Carter [physician]",
      patient_id: 75,
      reportedBy: "Grace Carter [physician]"
    }
  ];

  const bpData = getSymptomListData(mockResponse);

  const mockExtendedData = useMemo(
    () =>
      bpData.reduce((acc: ExtendedSymptomData[], symptomItem) => {
        const symptomExtendedList: ExtendedSymptomData[] =
          symptomItem.symptoms.map(symptom => ({
            name: symptom.name,
            data: symptom.data,
            description: symptom.description,
            timestamp: symptomItem.timestamp,
            reporterName: symptomItem.reporterName,
            reporterRole: symptomItem.reporterRole
          }));
        return acc.concat(symptomExtendedList);
      }, []),
    [bpData]
  );

  return (
    <>
      <AppCard
        cardHeight="100%"
        cardWidth="100%"
        style={{
          borderRadius: "0px 0px 8px 8px",
          marginTop: "-2px"
        }}
      >
        {/* <AvgAMIcon /> */}
        <Row gutter={[20, 20]}>
          <Col span={6}>
            <>
              <AppCard cardHeight="fit-content" cardWidth="100%">
                <LatestBPIcon />
                <CardDataStyled>Latest BP</CardDataStyled>
                <CardDataStyled>-</CardDataStyled>
                <ViewMoreTrigger
                  buttonContent={"View More"}
                  type={ButtonType.Link}
                  onClick={() => {
                    setSelectedItem("Latest BP"); //This should match data identifier in string once available
                    setIsModalOpen(true);
                  }}
                  disabled
                  style={{ padding: "0px" }}
                />
              </AppCard>
            </>
          </Col>
          <Col span={6}>
            <>
              <AppCard cardHeight="fit-content" cardWidth="100%">
                <AvgAMIcon />
                <CardDataStyled>Average AM BP</CardDataStyled>
                <CardDataStyled>-</CardDataStyled>
                <ViewMoreTrigger
                  buttonContent={"View More"}
                  type={ButtonType.Link}
                  onClick={() => {
                    setSelectedItem("Average AM BP"); //This should match data identifier in string once available
                    setIsModalOpen(true);
                  }}
                  disabled
                  style={{ padding: "0px" }}
                />
              </AppCard>
            </>
          </Col>
          <Col span={6}>
            <>
              <AppCard cardHeight="fit-content" cardWidth="100%">
                <AvgPMIcon />
                <CardDataStyled>Average PM BP</CardDataStyled>
                <CardDataStyled>-</CardDataStyled>
                <ViewMoreTrigger
                  buttonContent={"View More"}
                  type={ButtonType.Link}
                  onClick={() => {
                    setSelectedItem("Average PM BP"); //This should match data identifier in string once available
                    setIsModalOpen(true);
                  }}
                  disabled
                  style={{ padding: "0px" }}
                />
              </AppCard>
            </>
          </Col>
        </Row>
        <div
          style={{
            height: "1px",
            background: Colors.Lavender,
            marginTop: "20px"
          }}
        />
        {/* <BloodPressureAccordion data={mockInfo} /> */}
      </AppCard>
      <MyBloodPressureModal
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        extendedItemData={mockExtendedData}
        selectedItem={selectedItem}
      />
    </>
  );
};

const ViewMoreTrigger = styled(AppButton)`
  color: ${Colors.DodgerBlue};
  font-size: 14px;
  font-family: Century Gothic Bold;
  cursor: pointer;
`;

const CardDataStyled = styled.div`
  font-size: 24px;
  font-family: Century Gothic Bold;
`;
