const PHQ9 = [
  {
    question: "Little interest or pleasure in doing things.",
    answer: ""
  },
  {
    question: " Feeling down, depressed, or hopeless.",
    answer: ""
  },
  {
    question: "Trouble falling or staying asleep, or sleeping too much.",
    answer: ""
  },
  {
    question: "Feeling tired or having little energy.",
    answer: ""
  },
  {
    question: "Poor appetite or overeating.",
    answer: ""
  },
  {
    question:
      "Feeling bad about yourself or that you are a failure or have let yourself or your family down.",
    answer: ""
  },
  {
    question:
      "Trouble concentrating on things, such as reading the newspaper or watching television.",
    answer: ""
  },
  {
    question:
      "Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or restless that you have been moving around a lot more than usual.",
    answer: ""
  },
  {
    question:
      "Thoughts that you would be better off dead, or of hurting yourself.",
    answer: ""
  }
];

export default PHQ9;
