import React from "react";

export const LoginBackgroundIcon: React.FC = () => {
  return (
    <svg
      width="100vw"
      height="100vh"
      viewBox="0 0 1366 768"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <defs>
        <linearGradient
          x1="41.3288455%"
          y1="0%"
          x2="52.6070599%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stopColor="#06BFE3" offset="0%"></stop>
          <stop stopColor="#208CFF" offset="100%"></stop>
        </linearGradient>
        <filter
          x="-1.0%"
          y="-1.4%"
          width="102.0%"
          height="103.5%"
          filterUnits="objectBoundingBox"
          id="filter-3"
        >
          <feOffset
            dx="0"
            dy="3"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.0235294118   0 0 0 0 0.615686275   0 0 0 0 0.870588235  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="CareGEM-Login-V1.1">
          <g id="bg" transform="translate(-225.000000, -130.000000)">
            <g id="Rectangle">
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-3)"
                xlinkHref="#path-2"
              ></use>
              <use
                fill="url(#linearGradient-1)"
                fillRule="evenodd"
                xlinkHref="#path-2"
              ></use>
            </g>
            <g
              id="Group-3"
              opacity="0.711495536"
              transform="translate(1056.000000, 150.000000)"
            >
              <circle
                id="Oval-Copy-33"
                fill="#C2DB7A"
                cx="242"
                cy="171"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-34"
                fill="#C2DB7A"
                cx="107"
                cy="320"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-35"
                fill="#C2DB7A"
                cx="125"
                cy="3"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-36"
                fill="#C2DB7A"
                cx="3"
                cy="409"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-37"
                fill="#C2DB7A"
                cx="308"
                cy="259"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-38"
                fill="#C2DB7A"
                cx="305"
                cy="334"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-39"
                fill="#C2DB7A"
                cx="142"
                cy="237"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-40"
                fill="#C2DB7A"
                cx="96"
                cy="145"
                r="3"
              ></circle>
              <polyline
                id="Path-11-Copy-2"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="96 145 141.888231 236.374306 242.251848 170.95938"
              ></polyline>
              <polyline
                id="Path-12-Copy-2"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="107 320.489578 141.703466 237 304.943822 334.335249"
              ></polyline>
              <polyline
                id="Path-13-Copy-2"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="308.142951 259.785141 142.192369 237 3 409.520255"
              ></polyline>
              <path
                d="M141.51228,236.783488 L125,3"
                id="Path-14-Copy-2"
                stroke="#C2DB7A"
                strokeWidth="0.5"
              ></path>
            </g>
            <g
              id="Group-3-Copy-2"
              opacity="0.711495536"
              transform="translate(1422.000000, 680.000000)"
            >
              <circle
                id="Oval-Copy-35"
                fill="#C2DB7A"
                cx="125"
                cy="3"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-40"
                fill="#C2DB7A"
                cx="96"
                cy="145"
                r="3"
              ></circle>
              <polyline
                id="Path-11-Copy-2"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="96 145 141.888231 236.374306 242.251848 170.95938"
              ></polyline>
              <path
                d="M141.51228,236.783488 L125,3"
                id="Path-14-Copy-2"
                stroke="#C2DB7A"
                strokeWidth="0.5"
              ></path>
            </g>
            <g
              id="Group-3-Copy"
              opacity="0.711495536"
              transform="translate(0.000000, 383.000000)"
            >
              <circle
                id="Oval-Copy-33"
                fill="#C2DB7A"
                cx="242"
                cy="171"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-37"
                fill="#C2DB7A"
                cx="308"
                cy="259"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-38"
                fill="#C2DB7A"
                cx="305"
                cy="334"
                r="3"
              ></circle>
              <polyline
                id="Path-11-Copy-2"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="96 145 141.888231 236.374306 242.251848 170.95938"
              ></polyline>
              <polyline
                id="Path-12-Copy-2"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="107 320.489578 141.703466 237 304.943822 334.335249"
              ></polyline>
              <polyline
                id="Path-13-Copy-2"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="308.142951 259.785141 142.192369 237 3 409.520255"
              ></polyline>
            </g>
            <g
              id="Group-2"
              opacity="0.711495536"
              transform="translate(486.399925, 257.137643) rotate(-138.000000) translate(-486.399925, -257.137643) translate(330.899925, 51.137643)"
            >
              <circle
                id="Oval-Copy-25"
                fill="#C2DB7A"
                cx="242"
                cy="171"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-26"
                fill="#C2DB7A"
                cx="107"
                cy="320"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-27"
                fill="#C2DB7A"
                cx="125"
                cy="3"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-28"
                fill="#C2DB7A"
                cx="3"
                cy="409"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-31"
                fill="#C2DB7A"
                cx="142"
                cy="237"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-32"
                fill="#C2DB7A"
                cx="96"
                cy="145"
                r="3"
              ></circle>
              <polyline
                id="Path-11-Copy"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="96 145 141.888231 236.374306 242.251848 170.95938"
              ></polyline>
              <polyline
                id="Path-12-Copy"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="107 319.489578 141.703466 236 304.943822 333.335249"
              ></polyline>
              <polyline
                id="Path-13-Copy"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="308.142951 258.785141 142.192369 236 3 408.520255"
              ></polyline>
              <path
                d="M141.51228,235.783488 L125,2"
                id="Path-14-Copy"
                stroke="#C2DB7A"
                strokeWidth="0.5"
              ></path>
            </g>
            <g
              id="Group-2-Copy"
              opacity="0.711495536"
              transform="translate(694.399925, 898.137643) rotate(-138.000000) translate(-694.399925, -898.137643) translate(538.899925, 692.137643)"
            >
              <circle
                id="Oval-Copy-25"
                fill="#C2DB7A"
                cx="242"
                cy="171"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-26"
                fill="#C2DB7A"
                cx="107"
                cy="320"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-28"
                fill="#C2DB7A"
                cx="3"
                cy="409"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-29"
                fill="#C2DB7A"
                cx="308"
                cy="259"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-30"
                fill="#C2DB7A"
                cx="305"
                cy="334"
                r="3"
              ></circle>
              <circle
                id="Oval-Copy-31"
                fill="#C2DB7A"
                cx="142"
                cy="237"
                r="3"
              ></circle>
              <polyline
                id="Path-11-Copy"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="96 145 141.888231 236.374306 242.251848 170.95938"
              ></polyline>
              <polyline
                id="Path-12-Copy"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="107 319.489578 141.703466 236 304.943822 333.335249"
              ></polyline>
              <polyline
                id="Path-13-Copy"
                stroke="#C2DB7A"
                strokeWidth="0.5"
                points="308.142951 258.785141 142.192369 236 3 408.520255"
              ></polyline>
              <path
                d="M141.51228,235.783488 L125,2"
                id="Path-14-Copy"
                stroke="#C2DB7A"
                strokeWidth="0.5"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
