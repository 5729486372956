import React from "react";
import { MENU_ITEM_ICON_DISABLED_COLOR } from "components/sideBar/sideBar";

interface IconProps {
  readonly isDisabled?: boolean;
}

export const PerformanceIcon: React.FC<IconProps> = ({ isDisabled }) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Provider-Global-navigation-V2.3"
          transform="translate(-13.000000, -635.000000)"
        >
          <g
            id="Care-Plan-menu-Copy-6"
            transform="translate(14.000000, 635.000000)"
          >
            <g
              id="Performance-&amp;-Uti…-icon"
              transform="translate(7.000000, 6.000000)"
            >
              <g id="Rectangle-Copy-213">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill={isDisabled ? MENU_ITEM_ICON_DISABLED_COLOR : "#9D62DE"}
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <path
                d="M12.2,18.4 C8.77583455,18.4 6,15.6241654 6,12.2 C6,8.77583455 8.77583455,6 12.2,6 C15.6241654,6 18.4,8.77583455 18.4,12.2 C18.4,15.6241654 15.6241654,18.4 12.2,18.4 Z M12.2,17.0222222 C14.8632398,17.0222222 17.0222222,14.8632398 17.0222222,12.2 C17.0222222,9.53676021 14.8632398,7.37777778 12.2,7.37777778 C9.53676021,7.37777778 7.37777778,9.53676021 7.37777778,12.2 C7.37777778,14.8632398 9.53676021,17.0222222 12.2,17.0222222 Z"
                id="Oval"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <polygon
                id="Path-6"
                fill="#FFFFFF"
                fillRule="nonzero"
                points="13.7980204 7.08603462 15.0461451 7.66952094 12.3136045 13.5146477 7.04916927 9.78618413 7.84547598 8.66183221 11.7639639 11.4370471"
              ></polygon>
              <polygon
                id="Path-6-Copy"
                fill="#FFFFFF"
                fillRule="nonzero"
                points="16.9088359 9.32585856 17.5824001 10.5277666 12.3255606 13.4737646 7.09188614 9.80497 7.88274519 8.6767796 12.4073731 11.8485341"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
