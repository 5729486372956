import React from "react";

export const AppointmentIcon = () => {
  return (
    <svg
      width="19px"
      height="19px"
      viewBox="0 0 19 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Patient-MyCareTeam-V1.2"
          transform="translate(-694.000000, -200.000000)"
        >
          <g
            id="bills-icon-copy-3"
            transform="translate(682.000000, 188.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="44" height="44"></rect>
            <path
              d="M16.0054598,28.0243681 L13.5976272,28.0075053 C13.2585623,28.0058504 12.9845703,27.7305179 12.9845703,27.391449 L12.9845703,26.6196654 C12.9845703,26.2794228 13.2603914,26.0036017 13.600634,26.0036017 L16.0054598,26.0065939 L16.0054598,23.5897242 C16.0054598,23.2494816 16.2812809,22.9736605 16.6215235,22.9736605 L17.3839363,22.9736605 C17.7241789,22.9736605 18,23.2494816 18,23.5897242 L18,26.0143108 L20.3811333,26.0174711 C20.7213759,26.0174711 20.997197,26.2932922 20.997197,26.6335348 L20.997197,27.4083124 C20.997197,27.748555 20.7213759,28.0243761 20.3811333,28.0243761 L20.377989,28.0243681 L18,28.0075053 L18,30.3578652 C18,30.6981078 17.7241789,30.9739289 17.3839363,30.9739289 L16.6215235,30.9739289 C16.2812809,30.9739289 16.0054598,30.6981078 16.0054598,30.3578652 L16.0054598,28.0243681 Z"
              id="Rectangle-Copy-5"
              fill="#979DAB"
            ></path>
            <path
              d="M23,14.8329507 C23,15.4774942 22.5522847,16 22,16 C21.4477153,16 21,15.4774942 21,14.8329507 L21,13.1670493 C21,12.5225058 21.4477153,12 22,12 C22.5522847,12 23,12.5225058 23,13.1670493 L23,14.8329507 Z"
              id="Path-11"
              fill="#979DAB"
            ></path>
            <path
              d="M18,14.8329507 C18,15.4774942 17.5522847,16 17,16 C16.4477153,16 16,15.4774942 16,14.8329507 L16,13.1670493 C16,12.5225058 16.4477153,12 17,12 C17.5522847,12 18,12.5225058 18,13.1670493 L18,14.8329507 Z"
              id="Path-11-Copy"
              fill="#979DAB"
            ></path>
            <path
              d="M28,14.8329507 C28,15.4774942 27.5522847,16 27,16 C26.4477153,16 26,15.4774942 26,14.8329507 L26,13.1670493 C26,12.5225058 26.4477153,12 27,12 C27.5522847,12 28,12.5225058 28,13.1670493 L28,14.8329507 Z"
              id="Path-11-Copy-2"
              fill="#979DAB"
            ></path>
            <path
              d="M15.3598049,20.5415152 L14.0245818,20.5614209 C13.8589148,20.5638906 13.722613,20.4315932 13.7201432,20.2659261 C13.720121,20.2644356 13.7201099,20.2629449 13.7201099,20.2614542 L13.7201099,17.4581258 C13.7201099,16.0971554 14.8233943,14.993871 16.1843646,14.993871 L28.3007737,14.993871 C29.661744,14.993871 30.7650284,16.0971554 30.7650284,17.4581258 L30.7650284,27.8088424 C30.7650284,29.1698127 29.661744,30.2730971 28.3007737,30.2730971 L23.5987366,30.2730971 C23.4330512,30.2730971 23.2987366,30.1387825 23.2987366,29.9730971 L23.2987366,28.5397006 C23.2987366,28.3740152 23.4330512,28.2397006 23.5987366,28.2397006 L28.7014067,28.2397006 L28.7014067,17.028234 L15.655333,17.028234 L15.655333,20.2415485 C15.655333,20.4054901 15.5237284,20.5390714 15.3598049,20.5415152 Z"
              id="Path-9"
              fill="#979DAB"
            ></path>
            <path
              d="M18,20.9082199 L18,19.4 C18,19.1790861 18.1790861,19 18.4,19 L19.9468804,19 C20.1677943,19 20.3468804,19.1790861 20.3468804,19.4 L20.3468804,20.9082199 C20.3468804,21.1291338 20.1677943,21.3082199 19.9468804,21.3082199 L18.4,21.3082199 C18.1790861,21.3082199 18,21.1291338 18,20.9082199 Z"
              id="Path"
              fill="#979DAB"
            ></path>
            <path
              d="M23.6722638,20.9339849 L23.6722638,19.425765 C23.6722638,19.2048511 23.8513499,19.025765 24.0722638,19.025765 L25.6191442,19.025765 C25.8400581,19.025765 26.0191442,19.2048511 26.0191442,19.425765 L26.0191442,20.9339849 C26.0191442,21.1548988 25.8400581,21.3339849 25.6191442,21.3339849 L24.0722638,21.3339849 C23.8513499,21.3339849 23.6722638,21.1548988 23.6722638,20.9339849 Z"
              id="Path-Copy"
              fill="#979DAB"
            ></path>
            <path
              d="M23.6479721,25.6061853 L23.6479721,24.0979654 C23.6479721,23.8770515 23.8270582,23.6979654 24.0479721,23.6979654 L25.5948525,23.6979654 C25.8157664,23.6979654 25.9948525,23.8770515 25.9948525,24.0979654 L25.9948525,25.6061853 C25.9948525,25.8270992 25.8157664,26.0061853 25.5948525,26.0061853 L24.0479721,26.0061853 C23.8270582,26.0061853 23.6479721,25.8270992 23.6479721,25.6061853 Z"
              id="Path-Copy-2"
              fill="#979DAB"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
