import React, { useContext, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import Menu from "antd/lib/menu";
import Dropdown from "antd/lib/dropdown";
import Space from "antd/lib/space";
import Col from "antd/lib/col";
import { UserMenuIcon } from "./userMenuIcon";
import styled from "styled-components";
import { UserIcon } from "./userIcon";
import { LogoutIcon } from "./logoutIcon";
import { Colors } from "helpers/colors";
import { NotificationsIcon } from "./notificationsIcon";
import { UserContext, UserContextState } from "contextApis/userContext";
import { AppModal } from "components/modal";
import { Info } from "pages/info";
import {
  MyProfile,
  MyProfileCaregiver,
  MyProfilePatient
} from "pages/myprofile";
import { UserRoles } from "constants/roles";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { message } from "antd";

export const UserMenuComponent: React.FC = () => {
  const userContext = useContext<UserContextState>(UserContext);
  const [showInfoProfileModal, setShowInfoProfileModal] = useState("");
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const meetingID = sessionStorage.getItem("meetingID");

  const { refetch: endMeeting } = useQuery(
    "end-meeting",
    async () => {
      return await createApiClient().endMeeting(meetingID ? meetingID : "");
    },
    {
      enabled: false,
      onSuccess: () => {
        meetingContext.closeSession();
      },
      onError: () => {
        message.error("There was a problem ending this meeting.");
        meetingContext.closeSession();
      }
    }
  );

  const onLogout = () => {
    if (meetingID) {
      endMeeting();
      meetingContext.closeSession();
    }
    userContext.onLogout();
  };
  const userRole = sessionStorage.getItem("userRole");

  const userIsPatient = userRole === UserRoles.PATIENT;
  const userIsCaregiver = userRole === UserRoles.CAREGIVER;
  const userIsProvider =
    userRole !== UserRoles.CAREGIVER && userRole !== UserRoles.PATIENT;

  const menu = (
    <MenuStyled>
      <UserName>
        {userContext.userData?.firstName} {userContext.userData?.lastName}
      </UserName>

      <Menu.Item onClick={() => setShowInfoProfileModal("profile")} key="1">
        <a>
          <UserIcon />
          My Profile
        </a>
      </Menu.Item>
      <Menu.Item onClick={() => setShowInfoProfileModal("info")} key="2">
        <a>
          <NotificationsIcon />
          Info
        </a>
      </Menu.Item>
      <Menu.Item key="3" onClick={onLogout}>
        <a>
          <LogoutIcon />
          Logout
        </a>
      </Menu.Item>
      {showInfoProfileModal && (
        <AppModal
          title=""
          visible={showInfoProfileModal ? true : false}
          width="100%"
          onCancel={() => setShowInfoProfileModal("")}
          footer={false}
        >
          {showInfoProfileModal === "info" && <Info />}
          {showInfoProfileModal === "profile" && userIsCaregiver && (
            <MyProfileCaregiver />
          )}
          {showInfoProfileModal === "profile" && userIsPatient && (
            <MyProfilePatient />
          )}
          {showInfoProfileModal === "profile" && userIsProvider && (
            <MyProfile />
          )}
        </AppModal>
      )}
    </MenuStyled>
  );

  return (
    <DropdownStyled
      overlay={menu}
      placement="bottomLeft"
      arrow
      trigger={["click"]}
    >
      <UserMenuCol>
        <Space size={8} direction="horizontal">
          <UserMenuIcon />
          <DownOutlined />
        </Space>
      </UserMenuCol>
    </DropdownStyled>
  );
};

const UserName = styled.div`
  padding-left: 11px;
  margin-bottom: 5px;
  font-weight: 600;
  background: ${Colors.AliceBlue};
  border-radius: 8px;
  text-align: center;
`;

const UserMenuCol = styled(Col)`
  background-color: ${Colors.AliceBlue};
  border-radius: 8px;
  width: 60px;
  padding-top: 4px;
  display: grid;
  place-items: center;
`;

const MenuStyled = styled(Menu)`
  width: 265px;
  border-radius: 10px;
  padding: 12px 6px 12px 6px;

  .ant-dropdown-menu-item {
    :hover {
      background-color: ${Colors.BabyBlue};
      border-radius: 10px;
      transition: 0s;
    }
  }

  svg {
    margin-bottom: -3px;
    margin-right: 10px;
  }
`;

const DropdownStyled = styled(Dropdown)`
  cursor: pointer;
`;
