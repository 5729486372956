import Skeleton from "antd/lib/skeleton";
import styled from "styled-components";
import { Colors } from "helpers/colors";

interface RiskProfileGridItemProps {
  readonly showRightBorder: boolean;
}

export const CallRecordSkeleton = styled(Skeleton)`
  .ant-skeleton-title {
    margin-top: 10px;
  }
`;

export const ErrorText = styled.div`
  color: ${Colors.Red};
  font-weight: bold;
  opacity: 0.6;
  display: grid;
  place-items: center;
  margin-top: 0.4rem;
`;
export const NoDataText = styled.div`
  display: grid;
  place-items: center;
  margin-top: 0.4rem;
  opacity: 0.6;
`;
export const RiskProfileTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 5px;
  padding-top: 18px;
`;
export const RiskProfileListItem = styled.div`
  margin-top: 15px;
`;
export const RiskTimestampText = styled.div`
  font-size: 12px;
`;
export const RiskProfileGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
`;
export const RiskProfileGridItem = styled.div<RiskProfileGridItemProps>`
  border-right: ${props =>
    props.showRightBorder ? `0.5px solid ${Colors.Lavender}` : "none"};
`;
export const RiskProfileItemValueText = styled.div`
  color: ${Colors.Red};
  font-weight: bold;
`;
