import React, { useState } from "react";
import Space from "antd/lib/space";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import moment from "moment";
import styled from "styled-components";

import { AppCard } from "components/card";
import { AppDatePicker } from "components/inputs";
import { Colors } from "helpers/colors";
import { SurveyNumberInput } from "pages/symptoms/surveyForms/surveyNumberInput";
import { Platform } from "constants/roles";
import { dateToUTC, disableFutureDate } from "helpers/utils";

interface WeightSectionProps {
  readonly currentPage: number;
  readonly weightScale: string;
  setShowSubmit(show: boolean): void;
}

export const WeightSection: React.FC<WeightSectionProps> = ({
  currentPage,
  weightScale,
  setShowSubmit
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [weight, setWeight] = useState(localStorage.getItem(`weight`) || "");

  const checkWeight = (_: any, value: number) => {
    if (value) {
      if (value <= 600 && value >= 30) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          "Reading appears out of range; Please recheck and reenter value between 30 - 600."
        )
      );
    }
    return Promise.reject(new Error("This field is required"));
  };

  return (
    <>
      {currentPage === 10 && (
        <Space direction="vertical" size={20}>
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{ width: "100%", padding: "10px" }}
              >
                <DisclaimerTitle>Weight</DisclaimerTitle>
                <CardTitle>
                  Do you want to report your weight?
                  <AsteriskStyle> *</AsteriskStyle>
                </CardTitle>
                <FormItemStyle
                  name="Report your weight"
                  label=""
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  initialValue={""}
                >
                  <Radio.Group
                    value={localStorage.getItem("reportYourWeight")}
                    onChange={e => {
                      if (e.target.value === "No") {
                        setShowSubmit(true);
                      } else {
                        setShowSubmit(false);
                      }
                      localStorage.setItem("reportYourWeight", e.target.value);
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={"Yes"}>Yes</Radio>
                      <Radio value={"No"}>No</Radio>
                    </Space>
                  </Radio.Group>
                </FormItemStyle>
              </Space>
            </AppCard>
          </Space>
        </Space>
      )}
      {currentPage === 11 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Weight</DisclaimerTitle>
              <CardTitle>
                When did you take your weight?
                <AsteriskStyle> *</AsteriskStyle>
              </CardTitle>
              <FormItemStyle
                name="When did you take your weight"
                label=""
                rules={[
                  {
                    required: true
                  }
                ]}
                initialValue={""}
              >
                <Radio.Group
                  value={localStorage.getItem("whenWasWeightTaken")}
                  onChange={e =>
                    localStorage.setItem("whenWasWeightTaken", e.target.value)
                  }
                >
                  <Space direction="vertical">
                    <Radio value={"Taken today"}>Taken today</Radio>
                    <Radio value={"Taken before today"}>
                      Taken before today
                    </Radio>
                  </Space>
                </Radio.Group>
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
      {currentPage === 12 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Weight</DisclaimerTitle>
              <CardTitle>
                When was your weight taken?
                <AsteriskStyle> *</AsteriskStyle>
              </CardTitle>
              <FormItemStyle
                name="When was your weight taken(Date)"
                label=""
                rules={[
                  {
                    required: true
                  }
                ]}
                initialValue={""}
              >
                <AppDatePicker
                  label="Weight Taken on"
                  value={moment(localStorage.getItem("dateYourWeightWasTaken"))}
                  onChange={e => {
                    if (e) {
                      const selectedDate = moment(e).toISOString();
                      localStorage.setItem(
                        "dateYourWeightWasTaken",
                        selectedDate
                      );
                    }
                  }}
                  style={{ width: "250px" }}
                  disabledDate={disableFutureDate}
                />
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
      {currentPage === 13 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Weight</DisclaimerTitle>
              <CardTitle>
                Please select your scale
                <AsteriskStyle> *</AsteriskStyle>
              </CardTitle>
              <FormItemStyle
                name={`Select scale`}
                label=""
                rules={[
                  {
                    required: true
                  }
                ]}
                initialValue={""}
              >
                <Radio.Group
                  value={localStorage.getItem("selectScale")}
                  onChange={e =>
                    localStorage.setItem("selectScale", e.target.value)
                  }
                >
                  <Space direction="vertical">
                    <Radio value={"Report in Pounds"}>Report in Pounds</Radio>
                    <Radio value={"Report in Kilograms"}>
                      Report in Kilograms
                    </Radio>
                  </Space>
                </Radio.Group>
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
      {currentPage === 14 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Weight</DisclaimerTitle>
              <CardTitle>
                Report your weight in {weightScale.split(/\s+/).splice(-1)[0]}
                <AsteriskStyle> *</AsteriskStyle>
              </CardTitle>
              <FormItemStyle
                name={`weight`}
                label=""
                rules={[
                  {
                    required: true,
                    validator: checkWeight,
                    validateTrigger: "submit"
                  }
                ]}
                initialValue={""}
              >
                <SurveyNumberInput
                  value={weight}
                  decimalLimit={2}
                  placeholder="Your Answer"
                  onChange={e => {
                    setWeight(e as string);
                    localStorage.setItem(`weight`, e as string);
                  }}
                  min={0}
                  style={{ width: "120px" }}
                />
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
    </>
  );
};

const DisclaimerTitle = styled.div`
  width: 100%;
  padding: 8px 8px 8px 20px;
  margin-left: -25px;
  background: ${Colors.DodgerBlue};
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 8px 8px 0px 0px;
  color: white;
  position: absolute;
  top: 0;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
