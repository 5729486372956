import React, { useContext, useMemo } from "react";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Table, { ColumnsType } from "antd/lib/table";
import { camelCase, get, isArray, sortBy } from "lodash";
import moment from "moment";
import { useQuery } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppCard } from "components/card";
import { MessageRow } from "components/dropDownBox/customStyles";
import {
  PatientContextState,
  PatientContext
} from "contextApis/patientContext";
import { Colors } from "helpers/colors";
import { ActiveMedicineData, MedicationHistoryResponse } from "sharedTypes";
import { MedicationText } from "./AddMedicationForm";
import { MedicationHistoryDropdown } from "./medicationHistoryDropdown";
import { UserContext, UserContextState } from "contextApis/userContext";
import { UserRoles } from "constants/roles";

interface MedicationHistoryTableProps {
  readonly selectedMedicationForHistory: ActiveMedicineData | null;
}

export const MedicationHistoryTable: React.FC<MedicationHistoryTableProps> = ({
  selectedMedicationForHistory
}) => {
  const patientContext = useContext<PatientContextState>(PatientContext);
  const userContext = useContext<UserContextState>(UserContext);

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const isApiEnabled = useMemo(() => {
    if (userIsPatient && userContext.userData) {
      if (userContext.userData.internal_id && selectedMedicationForHistory)
        return true;
    }
    if (!userIsPatient && patientContext.patientData) {
      if (patientContext.patientData.id && selectedMedicationForHistory)
        return true;
    }
    return false;
  }, [userIsPatient ? userContext.userData : patientContext.patientData]);

  const patientId = useMemo(() => {
    if (userIsPatient && userContext.userData) {
      if (userContext.userData.internal_id)
        return userContext.userData.internal_id.toString();
    }
    if (patientContext.patientData) {
      if (patientContext.patientData.id)
        return patientContext.patientData.id.toString();
    }
    return "";
  }, [patientContext.patientData]);

  const { isLoading, isFetching, data } = useQuery(
    "medication-history",
    async () => {
      return await createApiClient().getMedicationHistoryList(
        patientId,
        get(selectedMedicationForHistory, "ProductId", ""),
        true
      );
    },
    { enabled: isApiEnabled }
  );

  const columns: ColumnsType<MedicationHistoryResponse> = [
    {
      title: "Name",
      key: "ProductLongName",
      dataIndex: "ProductLongName",
      width: "25%",
      render: (ProductLongName: string, row) => {
        return (
          <MessageRow
            style={{ fontWeight: "bold", marginLeft: "10px" }}
            fontSize={"14px"}
          >
            <b>{`${ProductLongName} ${row.UnitStrength}`}</b>
          </MessageRow>
        );
      }
    },
    {
      title: "Start Date",
      key: "CreatedDate",
      dataIndex: "CreatedDate",
      render: (CreatedDate: number, row: MedicationHistoryResponse) => (
        <Container>
          <MessageUpperRow>
            <b>{moment(CreatedDate, "YYYY-MM-DD").format("DD MMM, YYYY")}</b>
          </MessageUpperRow>
          <MessageLowerRow>{row.CreatedBy}</MessageLowerRow>
        </Container>
      ),
      width: "20%"
    },
    {
      title: "End Date",
      key: "ModifiedDate",
      dataIndex: "ModifiedDate",
      render: (ModifiedDate: number, row: MedicationHistoryResponse) => (
        <Container>
          <MessageUpperRow>
            <b>{moment(ModifiedDate, "YYYY-MM-DD").format("DD MMM, YYYY")}</b>
          </MessageUpperRow>
          <MessageLowerRow>{row.ModifiedBy}</MessageLowerRow>
        </Container>
      ),
      width: "20%"
    },
    {
      title: "Dose",
      key: "Quantity",
      dataIndex: "Quantity",
      width: "10%",
      render: (Quantity: string, row: MedicationHistoryResponse) => {
        return <span>{`${Quantity} ${camelCase(row.UnitCode)}`}</span>;
      }
    },
    {
      title: "Sig",
      key: "Sig",
      dataIndex: "Sig",
      width: "20%",
      render: (Sig: string, row: MedicationHistoryResponse) => (
        <Container>
          <MessageUpperRow>
            <b>{`${Sig}${row.PRN === "Y" ? "| PRN" : ""}`}</b>
          </MessageUpperRow>
          <MessageLowerRow>{""}</MessageLowerRow>{" "}
          {/* TODO: Additional Not not present in Response */}
        </Container>
      )
    },
    {
      key: "actions",
      dataIndex: "actions",
      render: (_value: undefined, row: MedicationHistoryResponse) => (
        <div
          style={{ display: "grid", placeItems: "right", paddingRight: "10px" }}
        >
          <Space size={20}>
            <ActionSpan>
              <MedicationHistoryDropdown medicineItem={row} />
            </ActionSpan>
          </Space>
        </div>
      )
    }
  ];

  const tableData = useMemo(() => {
    if (data && isArray(data)) {
      return sortBy(data, "CreatedDate").reverse();
    }
    return [];
  }, [data]);

  return (
    <AppCard cardHeight="calc(100vh - 200px)" cardWidth="100%">
      <MedicationText>
        <b>{`${selectedMedicationForHistory?.ProductLongName || ""} ${
          selectedMedicationForHistory?.UnitStrength || ""
        }`}</b>
      </MedicationText>
      <Divider style={{ margin: "15px 0" }} />
      <Table<MedicationHistoryResponse>
        columns={columns}
        dataSource={tableData}
        rowKey="id"
        loading={isLoading || isFetching}
        pagination={false}
        scroll={{ y: "calc(100vh - 280px)" }}
      />
    </AppCard>
  );
};

const ActionSpan = styled.span`
  cursor: pointer;
`;

const MessageUpperRow = styled(Row)`
  color: ${Colors.gothicBold};
  font-size: 12px;
`;
const Container = styled.div`
  padding: 10px 0px;
`;

const MessageLowerRow = styled(Row)`
  font-size: 12px;
`;
