import React from "react";

export const LatestBPIcon: React.FC = () => {
  return (
    <svg
      width="70px"
      height="70px"
      viewBox="0 0 70 70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Patient-MyHealthStatus-MyCurrentStatus-MyBloodPressure-V1.1-O2"
          transform="translate(-279.000000, -172.000000)"
        >
          <g id="Group-3-Copy-11" transform="translate(255.000000, 148.000000)">
            <g id="latest-bp-icon" transform="translate(24.000000, 24.000000)">
              <path
                d="M35,-2.72848411e-12 L35,-2.72848411e-12 C54.3299662,-2.73203496e-12 70,15.6700338 70,35 L70,35 C70,54.3299662 54.3299662,70 35,70 L35,70 C15.6700338,70 7.27832485e-12,54.3299662 7.27595761e-12,35 L7.27595761e-12,35 C7.27359038e-12,15.6700338 15.6700338,-2.72493325e-12 35,-2.72848411e-12 Z"
                id="Rectangle-Copy-204"
                fill="#EBF3FB"
              ></path>
              <g
                id="Group-7"
                transform="translate(9.000000, 9.000000)"
                stroke="#212B40"
                strokeWidth="2"
              >
                <circle
                  id="Oval"
                  fill="#7CBA5B"
                  cx="17"
                  cy="17"
                  r="17"
                ></circle>
                <circle
                  id="Oval-Copy"
                  fill="#FFFFFF"
                  cx="17"
                  cy="17"
                  r="13"
                ></circle>
                <ellipse
                  id="Oval"
                  fill="#D8D8D8"
                  cx="48.5"
                  cy="23"
                  rx="5.5"
                  ry="9"
                ></ellipse>
                <path
                  d="M17.0829592,34.5 L17.0829592,47.75 C16.942814,53.2078328 19.336008,56.0173566 24.262541,56.1785714 C29.189074,56.3397862 31.6938677,53.5302624 31.7769223,47.75 C31.7769223,41.1056 34.4434123,37.8556 39.7763923,38 C47.7758624,38.2166 48.2449574,34.8892857 48.2449574,31.1946429"
                  id="Path-23"
                ></path>
                <path d="M29.9854935,17.5 L27,17.5" id="Path-31"></path>
                <path
                  d="M17.9927467,28.4927467 L15.0072533,28.4927467"
                  id="Path-31-Copy-2"
                  transform="translate(16.500000, 28.492747) rotate(-270.000000) translate(-16.500000, -28.492747) "
                ></path>
                <path d="M6.98549349,17.5 L4,17.5" id="Path-31-Copy"></path>
                <path
                  d="M17.9927467,5.49274675 L15.0072533,5.49274675"
                  id="Path-31-Copy-3"
                  transform="translate(16.500000, 5.492747) rotate(-270.000000) translate(-16.500000, -5.492747) "
                ></path>
                <path
                  d="M20.4927467,14 L12.5072533,14"
                  id="Path-31-Copy-5"
                  transform="translate(16.507253, 14.000000) rotate(-270.000000) translate(-16.507253, -14.000000) "
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
