import Row from "antd/lib/row";
import Col from "antd/lib/col";
import React from "react";
import styled from "styled-components";

export const ExtensionItems: React.FC = () => {
  return (
    <RowStyled>
      <TitleCol span={12}>All Notifications</TitleCol>
    </RowStyled>
  );
};

const TitleCol = styled(Col)`
  padding: 20px;
  font-family: Century Gothic Bold;
`;

const RowStyled = styled(Row)`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
`;
