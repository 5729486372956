import React from "react";
import Result from "antd/lib/result";
import { Routes, Route } from "react-router-dom";
import { PatientsRoutesRef } from "constants/routes";
import { PatientsLayout } from "layouts/patientsLayout";
import { Symptoms } from "pages/symptoms";
import { RemoteVitals } from "pages/remoteVitals";
import { MedicationsPage } from "pages/medications";
import { LaboratoryPage } from "pages/laboratory/laboratoryPage";
import { Careguidelines } from "pages/careguidelines";
import { MyCurrentStatus } from "pages/myCurrentStatus";
import { CareTeam } from "pages/careteam";
import { AddSymptomsForm } from "pages/symptoms/AddSymptomsForm";
import { PatientAllMessages } from "pages/patientAllMessages";
import { MedicationProvider } from "contextApis/medicationContext";
import { Images } from "pages/images";
import { CarePlan } from "pages/carePlan";
import { EducationalTips } from "pages/educationalTips";
import { MedicalDataEarnRewards } from "pages/medicalDataEarnRewards";
import { HealthStatusEarnRewards } from "pages/healthStatusEarnRewards";
import { ImproveMyRisk } from "pages/improveMyRisk";
import { LearningCenter } from "pages/learningCenter";
import { Appointment } from "pages/appointment";
import { AllNotifications } from "pages/allNotifications";
import { SurveysPage } from "pages/surveys/surveys";
import { RespondPage } from "pages/surveys/respond/respond";
import { SurveyProvider } from "contextApis/surveyContext";
import { PatientEducation } from "pages/patientEducation";
import { VCMeetPage } from "./VCMeetPage";

export const PatientRoutes: React.FC = () => {
  return (
    <div>
      <PatientsLayout>
        <Routes>
          <Route path={`/`} element={<Symptoms />} />
          <Route
            path={`/${PatientsRoutesRef.MyCurrentStatus}`}
            element={<MyCurrentStatus />}
          />
          <Route
            path={`/${PatientsRoutesRef.MyCurrentStatus}`}
            element={<MyCurrentStatus />}
          />
          <Route
            path={`/${PatientsRoutesRef.Symptoms}`}
            element={<Symptoms />}
          />
          <Route
            path={`/${PatientsRoutesRef.RemoteVitals}`}
            element={<RemoteVitals />}
          />
          <Route
            path={`/${PatientsRoutesRef.MyCareTeam}`}
            element={<CareTeam />}
          />
          <Route
            path={`/${PatientsRoutesRef.Medications}`}
            element={
              <MedicationProvider>
                <MedicationsPage />
              </MedicationProvider>
            }
          />
          <Route
            path={`${PatientsRoutesRef.PatientEducation}`}
            element={<PatientEducation />}
          />
          <Route
            path={`/${PatientsRoutesRef.Laboratory}`}
            element={<LaboratoryPage />}
          />
          <Route
            path={PatientsRoutesRef.ReportSymptoms}
            element={<AddSymptomsForm />}
          />
          <Route
            path={`/${PatientsRoutesRef.CareGuidelines}`}
            element={<Careguidelines />}
          />

          <Route
            path={PatientsRoutesRef.AllMessages}
            element={<PatientAllMessages />}
          />
          <Route path={PatientsRoutesRef.Images} element={<Images />} />
          <Route path={PatientsRoutesRef.CarePlan} element={<CarePlan />} />
          <Route
            path={PatientsRoutesRef.EducationalTips}
            element={<EducationalTips />}
          />
          <Route
            path={PatientsRoutesRef.EarnRewardsMyMedicalData}
            element={<MedicalDataEarnRewards />}
          />
          <Route
            path={PatientsRoutesRef.EarnRewards}
            element={<HealthStatusEarnRewards />}
          />
          <Route
            path={PatientsRoutesRef.ImproveMyRisk}
            element={<ImproveMyRisk />}
          />
          <Route
            path={PatientsRoutesRef.LearningCenter}
            element={<LearningCenter />}
          />
          <Route
            path={PatientsRoutesRef.Appointments}
            element={<Appointment />}
          />
          <Route
            path={`/${PatientsRoutesRef.AllNotifications}`}
            element={<AllNotifications />}
          />
          <Route
            path={`/${PatientsRoutesRef.Survey}/:id`}
            element={
              <SurveyProvider>
                <SurveysPage />
              </SurveyProvider>
            }
          />
          <Route
            path={`/${PatientsRoutesRef.Respond}/:id`}
            element={
              <SurveyProvider>
                <RespondPage />
              </SurveyProvider>
            }
          />
          <Route
            path={`/${PatientsRoutesRef.ViewForm}/:type/:id`}
            element={
              <SurveyProvider>
                <RespondPage />
              </SurveyProvider>
            }
          />
          <Route
            path="*"
            element={
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
              />
            }
          />
          <Route
            path={`/${PatientsRoutesRef.VCMeetPage}`}
            element={<VCMeetPage />}
          />
        </Routes>
      </PatientsLayout>
    </div>
  );
};
