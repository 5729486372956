import React, { useContext, useEffect, useMemo } from "react";
import Spin from "antd/lib/spin";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { MessageContainer } from "components/messageContainer";
import { MessageData } from "components/messageContainer/messageContainer";
import {
  PatientContextState,
  PatientContext
} from "contextApis/patientContext";
import { SelectedConversationUserInfo } from "pages/messages/messages";
import { convertAllChatsToCustomMessageData } from "pages/patientAllMessages/patientAllMessages";

export const CaregiverAllMessagesSection: React.FC = () => {
  const patientContext = useContext<PatientContextState>(PatientContext);

  const {
    isLoading: isAllChatsLoading,
    data: allChatsData,
    error: allChatsError,
    isFetching: isAllChatsFetching,
    refetch: getAllChats
  } = useQuery(
    "get_all_chats",
    async () => {
      return await createApiClient().getAllChats(
        patientContext.patientData?.username || "",
        undefined, //pagination should be initially undefined
        undefined, //maxResults should be initially undefined
        "provider"
      );
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (patientContext.patientData) {
      getAllChats();
    }
  }, [patientContext.patientData]);

  const isLoading = useMemo(
    () => isAllChatsFetching || isAllChatsLoading,
    [isAllChatsFetching, isAllChatsLoading]
  );

  const paginationData = useMemo(() => {
    if (allChatsData) {
      if (
        allChatsData.pagination.some(
          paginationData => paginationData.next_token !== null
        )
      ) {
        return allChatsData.pagination;
      }
    }
    return null;
  }, [allChatsData]);

  const selectedConversationUserInfo: SelectedConversationUserInfo | null =
    useMemo(() => {
      if (allChatsData) {
        return {
          userInfo: allChatsData.user_info,
          channel: {
            channel_name: "",
            channel_arn: "",
            is_patient_enabled: 0,
            is_patient_linked_channel: 0,
            pat_uname: "",
            user1_uname: "",
            user2_uname: "",
            latest_message: "",
            latest_message_timestamp: ""
          }
        };
      }
      return null;
    }, [allChatsData]);

  const messageData: MessageData[] = useMemo(() => {
    if (allChatsData && patientContext.patientData) {
      return convertAllChatsToCustomMessageData(
        allChatsData.messages,
        allChatsData.user_info,
        patientContext.patientData.username || ""
      );
    }
    return [];
  }, [allChatsData, patientContext.patientData]);
  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "calc(62vh + 70px)",
            display: "grid",
            placeItems: "center"
          }}
        >
          <Spin />
        </div>
      ) : allChatsError ? (
        <div
          style={{
            width: "100%",
            height: "calc(62vh + 70px)",
            display: "grid",
            placeItems: "center"
          }}
        >
          <CommonErrorMessage message="Failed to fetch conversation" />
        </div>
      ) : (
        <MessageContainer
          messageItemWidth="55%"
          noFooter
          allMessagesFormat={true}
          picture=""
          sender=""
          specialization=""
          subject=""
          messageData={messageData}
          style={{ height: "calc(62vh + 70px)" }}
          nextToken={null}
          pagination={paginationData}
          selectedConversationUserInfo={selectedConversationUserInfo}
          userIsPatient={true}
          allChatsUsername={patientContext.patientData?.username}
        />
      )}
    </>
  );
};
