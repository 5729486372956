import React, { useContext, useMemo, useState } from "react";
import { Spin } from "antd";
import moment from "moment";
import { useQuery } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";

import { NotificationsMenu } from "components/notificationsMenu";
import { TopbarExtension } from "components/topbarExtension";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { NotificationProps } from "sharedTypes";
import { ExtensionItems } from "./extensionItems";
import { UserRoles } from "constants/roles";
import { AllNotificationsHeader } from "./allNotificationsHeader";
import { allNotificationWrapperStyling } from "./style";

export const AllNotificationsPage: React.FC = () => {
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const [statusFilter, setStatusFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const [apiData, setApiData] = useState<any>();
  const [loadingAll, setLoadingAll] = useState<boolean>(false);

  const {
    refetch: getNotificationsList,
    isRefetching: isRefetchingGetNotificationsList,
    isLoading: isLoadingGetNotificationsList,
    data: notificationListData
  } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        userIsPatient
          ? UserRoles.PATIENT
          : userIsCaregiver
          ? UserRoles.CAREGIVER
          : UserRoles.PROVIDER
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
        setApiData(data);
      },
      enabled: false
    }
  );

  const userId = sessionStorage.getItem("userId") || "";

  const userNotificationItems = useMemo(() => {
    const userMessageNotificationItems =
      notificationsContext.notifications?.messages.map(item => {
        return {
          desc: item.desc,
          type: item.type,
          status: item.status,
          notification_id: item.notification_id,
          severity: item.severity,
          created_on: item.created_on,
          patient_id: item.patient_id
        };
      }) || [];

    let tempNotifications: NotificationProps[] = [];
    let surveyNotificaions: NotificationProps[] = [];

    if (notificationsContext.notifications && !userIsPatient) {
      tempNotifications =
        notificationsContext.notifications?.remote_vitals?.concat(
          notificationsContext.notifications?.symptoms,
          notificationsContext.notifications?.medications,
          notificationsContext.notifications?.care_team
        ) || [];
    } else {
      surveyNotificaions = notificationsContext?.notifications?.survey || [];
    }
    if (notificationsContext.notifications?.survey) {
      surveyNotificaions = notificationsContext.filterNotificationsByStatus(
        notificationsContext.notifications?.survey || [],
        "unread"
      );
    }

    return notificationsContext.filterNotificationsListByStatusAndType(
      tempNotifications
        .filter(item => item.created_on)
        .map(item => {
          return {
            desc: item.desc,
            type: item.type,
            status: item.status,
            notification_id: item.notification_id,
            severity: item.severity,
            created_on: item.created_on,
            patient_id: item.patient_id
          };
        })
        .concat(surveyNotificaions)
        .concat(userMessageNotificationItems)
        .sort(
          (a, b) =>
            moment(b.created_on).valueOf() - moment(a.created_on).valueOf()
        ),
      statusFilter,
      typeFilter
    );
  }, [notificationsContext.notifications, statusFilter, typeFilter]);

  return (
    <>
      <TopbarExtension>
        <ExtensionItems />
      </TopbarExtension>
      <AllNotificationsHeader
        setStatusFilter={setStatusFilter}
        setTypeFilter={setTypeFilter}
        statusFilter={statusFilter}
        typeFilter={typeFilter}
        getNotificationsList={getNotificationsList}
        apiData={notificationListData}
        setLoadingAll={setLoadingAll}
      />
      {isLoadingGetNotificationsList || loadingAll ? (
        <SpinStyled />
      ) : (
        <NotificationsMenu
          getNotificationsList={getNotificationsList}
          notifications={userNotificationItems}
          avatarColSpan={2}
          wrapperStyle={{
            ...allNotificationWrapperStyling,
            overflowX: "hidden"
          }}
          loading={isRefetchingGetNotificationsList}
        />
      )}
    </>
  );
};

const SpinStyled = styled(Spin)`
  margin-right: 10px;
  padding-top: 12px;
`;
