import React from "react";

export const MedicalDataMedicationIcon: React.FC = () => {
  return (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 25 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="medication-icon">
          <path
            d="M12.9705627,0.970562748 L12.9705627,0.970562748 C19.5979797,0.970562748 24.9705627,6.34314575 24.9705627,12.9705627 L24.9705627,12.9705627 C24.9705627,19.5979797 19.5979797,24.9705627 12.9705627,24.9705627 L12.9705627,24.9705627 C6.34314575,24.9705627 0.970562748,19.5979797 0.970562748,12.9705627 L0.970562748,12.9705627 C0.970562748,6.34314575 6.34314575,0.970562748 12.9705627,0.970562748 Z"
            id="Rectangle-Copy-207"
            fill="#208CFF"
            transform="translate(12.970563, 12.970563) rotate(-270.000000) translate(-12.970563, -12.970563) "
          ></path>
          <path
            d="M13.248723,19.2509286 C10.961453,19.2402227 10.0100893,17.0837522 10.0092899,15.9305757 L10.0012952,10.3306345 C9.95064401,8.56255234 11.3917574,7.08232855 13.2391294,7.00492862 C14.0286809,6.95916426 14.8018502,7.23372065 15.3689048,7.76122274 C16.0613681,8.45398797 16.4614557,9.36727471 16.4921535,10.3252816 L16.4993487,15.9221639 C16.4656668,16.8572363 16.077177,17.7485844 15.4064797,18.4296345 C14.840859,18.9723083 14.0672092,19.269198 13.2663113,19.2509286 L13.248723,19.2509286 Z M11.4903776,10.5345779 L11.5362913,15.8373859 C11.5388326,16.3941201 11.6753705,16.7987886 11.832434,17.0889746 C11.931586,17.3519668 12.6197672,17.842076 13.1623457,17.8050697 C13.722302,17.8492447 14.2279365,17.6393653 14.4036312,17.4713094 C14.9019744,16.9976335 15.1517838,16.4089142 15.1275074,15.9253498 L15.1330203,10.5848466 C15.174178,9.90344551 14.84714,9.32262772 14.4660416,8.95809881 C14.1807478,8.68520902 13.8170221,8.4180348 13.2858219,8.41309255 C12.2796626,8.45862929 11.4176656,9.33775791 11.4903776,10.5345779 Z"
            id="Path_830"
            fill="#FFFFFF"
            transform="translate(13.249674, 13.125862) rotate(-45.000000) translate(-13.249674, -13.125862) "
          ></path>
          <path
            d="M15.5432125,13.5866629 L11.1772238,13.568535 C10.9003964,13.4585517 10.7245224,13.3033046 10.7245224,12.8094529 C10.7245224,12.3156012 10.9208522,11.8873531 11.1976796,11.7773698 L15.5636683,11.7954977 C15.8404957,11.9054811 16.0368255,12.3337291 16.0368255,12.8275808 C16.0368255,13.3214325 15.8200399,13.4766796 15.5432125,13.5866629 Z"
            id="Path_831"
            fill="#FFFFFF"
            transform="translate(13.380674, 12.682016) rotate(-45.000000) translate(-13.380674, -12.682016) "
          ></path>
        </g>
      </g>
    </svg>
  );
};
