import React, { useContext, useEffect, useMemo, useState } from "react";
import Form from "antd/lib/form";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import styled from "styled-components";
import { Colors } from "helpers/colors";
import { MedicationPages } from "./medication";
import {
  MedicineNameAutoComplete,
  MedicineNameProps
} from "./formInputs/medicineNameAutocomplete";
import {
  MedicineAmountProps,
  MedicineAmountSelect
} from "./formInputs/medicineAmount";
import {
  MedicineDoseDetailForm,
  MedicineDoseDetailProps
} from "./formInputs/medicineDoseDetail";
import {
  MedicineSigDetailsInput,
  MedicineSigDetailsProps
} from "./formInputs/medicineSigDetails";
import {
  MedicationDurationForm,
  MedicationDurationProps
} from "./formInputs/medicationDurationForm";
import {
  MedicineReasonPrescribedInput,
  MedicineReasonProps
} from "./formInputs/medicationReason";
import {
  MedicineInfoFromInput,
  MedicineInfoFromProps
} from "./formInputs/medicineInfoFromInput";
import { UserContext, UserContextState } from "contextApis/userContext";
import { get, indexOf, isEmpty } from "lodash";
import { MedicationAutocompleteResponse, MedicationData } from "sharedTypes";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import moment from "moment";
import { AppModal } from "components/modal";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { WarningOutlined } from "@ant-design/icons";
import { UserRoles } from "constants/roles";

interface AddCallRecordProps {
  readonly onFormSubmit: (formData: MedicationData) => void;
  readonly onMedicationDetailsPageChange: (page: MedicationPages) => void;
}

export interface AddMedicationFormData {
  medicineName: string;
  medicineAmount: MedicineAmountProps;
  medicineDoseDetail: MedicineDoseDetailProps;
  medicineSigDetails: MedicineSigDetailsProps;
  medicineDuration: MedicationDurationProps;
  medicineReasonPrescribed: MedicineReasonProps;
  medcineInfoFrom: MedicineInfoFromProps;
}

export const AddMedicationForm: React.FC<AddCallRecordProps> = ({
  onFormSubmit,
  onMedicationDetailsPageChange
}) => {
  const [form] = Form.useForm();
  const { userData } = useContext<UserContextState>(UserContext);
  const { patientData } = useContext<PatientContextState>(PatientContext);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [medicineNameOptions, setMedicineNameOptions] = useState<string[]>([]);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);
  const [selectedMedicine, setSelectedMedicine] =
    useState<MedicationAutocompleteResponse | null>(null);
  const [isDuplicateMedicineModalOpen, setIsDuplicateMedicineModalOpen] =
    useState<boolean>(false);
  const [isMedicineDuplicate, setIsMedicineDuplicate] =
    useState<boolean>(false);
  const [existingMedicationName, setExistingMedicationName] =
    useState<string>("");
  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;
  const userId = sessionStorage.getItem("userId") || "";

  const onSelectedMedicationChange = (
    value: MedicationAutocompleteResponse | null
  ) => setSelectedMedicine(value);

  const getSelectedProductId = (
    selectedMedicine: MedicationAutocompleteResponse | null,
    medicineStrength: string
  ) => {
    if (!medicineStrength || !selectedMedicine) return "";
    const medicineIndex = indexOf(
      selectedMedicine.strengthsAndForms,
      medicineStrength
    );
    if (medicineIndex === -1) return "";
    return selectedMedicine.rxcuis[medicineIndex];
  };

  const medicineAmount: MedicineAmountProps =
    form?.getFieldValue("medicineAmount") || "";

  const selectedProductId = useMemo(() => {
    if (!medicineAmount || !selectedMedicine) return "";
    return getSelectedProductId(
      selectedMedicine,
      get(medicineAmount, "medicineAmount", "")
    );
  }, [medicineAmount, selectedMedicine]);

  const { data, refetch } = useQuery(
    "medication-dup-check",
    async () => {
      return createApiClient().checkMedicineDuplicate(
        userIsPatient
          ? get(userData, "internal_id", "")
          : get(patientData, "id", ""),
        selectedProductId
      );
    },
    {
      enabled:
        get(patientData, "id", "").toString() && selectedProductId
          ? true
          : false
    }
  );

  useEffect(() => {
    if (selectedProductId) {
      refetch();
    }
  }, [selectedProductId]);

  useEffect(() => {
    if (isEmpty(data?.Duplication)) return;
    setIsMedicineDuplicate(true);
    setExistingMedicationName(
      data?.Duplication.map(item => item.ProductName).join() || ""
    );
  }, [data]);

  const onFinish = (values: AddMedicationFormData) => {
    const medicationDurationNumber = get(
      values,
      "medicineDuration.durationNumber",
      ""
    );
    const medicationDurationUnit = get(
      values,
      "medicineDuration.durationUnit",
      ""
    );
    const formData: MedicationData = {
      CreatedBy: get(userData, "internal_id", ""),
      duration:
        medicationDurationNumber && medicationDurationUnit
          ? `${medicationDurationNumber} ${medicationDurationUnit}`
          : "",
      info_from: get(values, "medcineInfoFrom.infoFrom", ""),
      ingredient: get(data, "Medication.Ingredient", []),
      medication_reasons: get(
        values,
        "medicineReasonPrescribed.reasonPrescribed",
        []
      ),
      PatientId: userIsPatient ? userId : get(patientData, "id", "").toString(),
      PrescribedDate: moment().format("MM-DD-YYYY hh:mm:ss"),
      prn: get(values, "medicineDoseDetail.prn", false) ? "Y" : "N",
      product_id: getSelectedProductId(
        selectedMedicine,
        get(values, "medicineAmount.medicineAmount", "")
      ),
      product_long_name: values.medicineName,
      product_short_name: values.medicineName,
      quantity: get(values, "medicineDoseDetail.dose", ""),
      sig: get(values, "medicineDoseDetail.sig", ""),
      sig_extra_note: get(values, "medicineSigDetails.sigDetails", ""),
      status: "A",
      unit_code: get(values, "medicineDoseDetail.doseUnit", ""),
      unit_strength: get(values, "medicineAmount.medicineAmount", "")
    };
    onFormSubmit(formData);
    setClearForm(!clearForm);
  };

  const onReset = () => setClearForm(!clearForm);

  const onCancelAddMedication = () => {
    onMedicationDetailsPageChange(MedicationPages.ActiveMedications);
    setSelectedMedicine(null);
    onReset();
  };

  const onPrimaryButtonClick = () =>
    isMedicineDuplicate ? setIsDuplicateMedicineModalOpen(true) : form.submit();

  const onSecondaryButtonClick = () => onCancelAddMedication();

  const onAddDuplicateMedication = () => {
    form.submit();
    setIsDuplicateMedicineModalOpen(false);
  };

  const checkMedicineName = (_: any, value: MedicineNameProps) => {
    if (value) {
      return Promise.resolve();
    }
    setIsNextDisabled(true);
    return Promise.reject(new Error("Medicine Name is required"));
  };

  const checkDose = (_: any, value: MedicineDoseDetailProps) => {
    if (value) {
      if (Number(value.dose) > 0) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Dose cannot be zero"));
    }
    return Promise.reject(new Error("Dose is required"));
  };

  const updateFormMedicineName = (value: string) => {
    if (form) {
      if (!value) {
        setIsNextDisabled(true);
      }
      if (value) {
        form
          .validateFields(["medicineName"])
          .then((value: MedicineNameProps) => {
            if (medicineNameOptions.includes(value.medicineName || "")) {
              setIsNextDisabled(false);
            }
          });
      }
      form.setFieldsValue({
        medicineName: value
      });
    }
  };

  const updateMedicineNameOptions = (options: string[]) =>
    setMedicineNameOptions(options);

  const showMedicationForm = useMemo(() => {
    const medName = form?.getFieldValue("medicineName");
    if (medName) {
      if (medicineNameOptions.includes(medName)) {
        return true;
      }
    }
    return false;
  }, [medicineNameOptions, form.getFieldValue("medicineName")]);

  return (
    <AppCard
      cardHeight="calc(100vh - 210px)"
      cardWidth="100%"
      style={{
        borderRadius: "0px 0px 8px 8px",
        marginTop: "-2px"
      }}
    >
      <FlexContainer>
        <FormContainer>
          <Form name="add medication" form={form} onFinish={onFinish}>
            <OverflowContainer>
              <Form.Item
                name="medicineName"
                required={true}
                rules={[{ validator: checkMedicineName }]}
              >
                <div>
                  <MedicineNameAutoComplete
                    formValue={form?.getFieldValue("medicineName")}
                    updateFormMedicineName={updateFormMedicineName}
                    onSelectedMedicationChange={onSelectedMedicationChange}
                    updateMedicineNameOptions={updateMedicineNameOptions}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="medicineAmount"
                style={{ display: showMedicationForm ? "block" : "none" }}
              >
                <MedicineAmountSelect
                  medicineName={form?.getFieldValue("medicineName")}
                  selectedMedicine={selectedMedicine}
                />
              </Form.Item>
              <div
                style={{
                  display:
                    showMedicationForm && form?.getFieldValue("medicineAmount")
                      ? "block"
                      : "none"
                }}
              >
                <Form.Item
                  name="medicineDoseDetail"
                  rules={[{ validator: checkDose }]}
                >
                  <MedicineDoseDetailForm
                    medicineName={form?.getFieldValue("medicineName")}
                    medicineAmount={form?.getFieldValue("medicineAmount")}
                    medicineUnit={data?.Medication.UnitCode || ""}
                  />
                </Form.Item>
                <Form.Item name="medicineSigDetails">
                  <MedicineSigDetailsInput />
                </Form.Item>
                <Form.Item name="medicineDuration" required={true}>
                  <MedicationDurationForm />
                </Form.Item>
                <Form.Item name="medicineReasonPrescribed">
                  <MedicineReasonPrescribedInput />
                </Form.Item>
                <Form.Item name="medcineInfoFrom">
                  <MedicineInfoFromInput />
                </Form.Item>
              </div>
            </OverflowContainer>
          </Form>
        </FormContainer>
        <MedicationFooter>
          <ButtonContainer>
            <AppButton
              type={ButtonType.Secondary}
              onClick={onSecondaryButtonClick}
              buttonContent={"Cancel"}
              style={{ marginRight: 10, width: 120 }}
            />
            <AppButton
              type={ButtonType.Primary}
              disabled={isNextDisabled}
              onClick={onPrimaryButtonClick}
              buttonContent={"Submit"}
              style={{ width: 120 }}
            />
          </ButtonContainer>
        </MedicationFooter>
      </FlexContainer>
      <AppModal
        visible={isDuplicateMedicineModalOpen}
        title="Duplicate Medication"
        cancelText="No"
        okText="Yes"
        onCancel={onCancelAddMedication}
        onOk={onAddDuplicateMedication}
        prompt={{
          icon: (
            <WarningOutlined style={{ color: "#F5A623", fontSize: "40px" }} />
          ),
          text: (
            <>
              <div>{`${existingMedicationName} is already present in the patient's active medication list.`}</div>
              <div>Do you still wish to add the medication?</div>
            </>
          )
        }}
        footer
      />
    </AppCard>
  );
};

const MedicationFooter = styled.div`
  border-top: 1px solid ${Colors.Lavender};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
  margin-bottom: 0px;
`;

export const MedicationText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-top: 8px;
`;

export const Container = styled.div`
  margin-top: 20px;
`;
const FlexContainer = styled.div`
  height: calc(100vh - 230px);
`;
const FormContainer = styled.div`
  height: calc(100vh - 300px);
`;
const OverflowContainer = styled.div`
  height: calc(100vh - 310px);
  overflow: auto;
`;
