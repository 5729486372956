// import Col from "antd/lib/col";
import React from "react";
// import styled from "styled-components";
// import CareguidelinesRecords from "./careguidelinesRecords";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { UserRoles } from "constants/roles";
import { Result } from "antd";

export const CareguidelinesPage: React.FC = () => {
  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const showTopbarExtesion = !userIsPatient;

  return (
    <>
      {showTopbarExtesion && <CommonPatientExtensionItems />}
      {/* <TitleCol>Care Guidelines(Work In Progress)</TitleCol>
      <CareguidelinesRecords /> */}
      <Result
        status="404"
        title="Work In Progress"
        subTitle="Sorry, the page you visited is still in development."
      />
    </>
  );
};

// const TitleCol = styled(Col)`
//   padding: 10px;
//   font-family: Century Gothic Bold;
//   padding: 20px;
// `;
