import { Col, Row } from "antd";
import styled from "styled-components";

export const SpinWrapper = styled.div`
  width: 100%;
  height: 60vh;
  display: grid;
  place-items: center;
`;

export const RowStyled = styled(Row)`
  padding-bottom: 12px;
`;

export const TitleCol = styled(Col)`
  padding-top: 18px;
  padding-left: 20px !important;
  font-family: Century Gothic Bold;
`;

export const FilterCol = styled(Col)`
  right: 0px;
  text-align: right;
`;
