import React, { useContext, useEffect, useMemo, useState } from "react";
import { message } from "antd";
import Spin from "antd/lib/spin";
import { useMutation, useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { MessageContainer } from "components/messageContainer";
import {
  convertOneToOneChimeMessagesToCustomMessageData,
  MessageData
} from "components/messageContainer/messageContainer";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { UserContextState, UserContext } from "contextApis/userContext";
import { PrvProfilePatientData } from "sharedTypes";
import { SelectedConversationUserInfo } from "pages/messages/messages";
import { SelectedProviderData } from "./providerDetails";
import { LoadingContainer } from "./style";

interface PrvDetailsMessagesSectionProps {
  readonly selectedPatient: PrvProfilePatientData | null;
  readonly providerData: SelectedProviderData | null;
  readonly onCloseMessageContainer: () => void;
}

const MESSAGE_CONTAINER_HEIGHT = "calc(62vh + 94px)";

export const PrvDetailsMessagesSection: React.FC<
  PrvDetailsMessagesSectionProps
> = ({ selectedPatient, providerData, onCloseMessageContainer }) => {
  const userContext = useContext<UserContextState>(UserContext);
  const [selectedConversationUserInfo, setSelectedConversationUserInfo] =
    useState<SelectedConversationUserInfo | null>(null);
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const {
    isLoading: isChannelLoading,
    data: channelData,
    error: channelError,
    isFetching: isChannelFetching,
    refetch: getChannel
  } = useQuery(
    "prv_details_individual_channel",
    async () => {
      return await createApiClient().getChannel(
        selectedPatient?.username || "",
        userContext.userData?.userName || "",
        false,
        providerData?.username || ""
      );
    },
    {
      enabled: false
    }
  );

  const {
    data: messagesData,
    isLoading: isMessagesLoading,
    isFetching: isMessagesFetching
  } = useQuery(
    [
      "provider_details_selected_shared_patient_channel_messages",
      selectedConversationUserInfo
    ],
    async () => {
      return await createApiClient().listChannelMessages(
        selectedConversationUserInfo?.channel.channel_name || ""
      );
    },
    { enabled: selectedConversationUserInfo ? true : false }
  );

  useEffect(() => {
    const loggedInProviderData = userContext.userData;
    if (
      selectedPatient &&
      selectedPatient.username &&
      loggedInProviderData &&
      providerData &&
      providerData.username
    ) {
      getChannel();
    }
  }, [userContext.userData, selectedPatient, providerData]);

  useEffect(() => {
    const loggedInproviderData = userContext.userData;
    if (
      channelData &&
      selectedPatient &&
      providerData &&
      loggedInproviderData &&
      typeof channelData !== "string"
    ) {
      const [patientFirstName, patientLastName] =
        selectedPatient.patientName.split(/\s+/);
      const [prvFirstName, prvLastName] = providerData.name.split(/\s+/);

      const newSelectedUserInfo: SelectedConversationUserInfo = {
        channel: {
          channel_arn: channelData.channel_arn,
          channel_name: channelData.channel_name,
          is_patient_enabled: channelData.is_patient_enabled,
          is_patient_linked_channel: 0,
          latest_message: "",
          latest_message_timestamp: "",
          pat_uname: selectedPatient.username,
          user1_uname: userContext.userData?.userName || "",
          user2_uname: providerData.username
        },
        userInfo: {
          [loggedInproviderData.userName]: {
            address_city: loggedInproviderData.city,
            address_zip: loggedInproviderData.zipCode,
            cell: loggedInproviderData.cell,
            dea_number: "",
            degree: loggedInproviderData.degree,
            dob: "",
            drive_license_number: "",
            email: loggedInproviderData.email,
            external_id: "",
            first_name: loggedInproviderData.firstName,
            gender: "",
            home_addr_1: "",
            home_addr_2: "",
            home_tel: "",
            last_name: loggedInproviderData.lastName,
            npi: "",
            office_addr_1: "",
            office_addr_2: "",
            office_tel: "",
            role: loggedInproviderData.role,
            specialty: loggedInproviderData.specialty,
            ssn: "",
            state: "",
            username: loggedInproviderData.userName,
            year_grad_med_school: "",
            internal_id: loggedInproviderData.internal_id
          },
          [selectedPatient.username]: {
            address_city: "",
            address_zip: "",
            cell: "",
            dea_number: "",
            degree: "",
            dob: "",
            drive_license_number: "",
            email: "",
            external_id: "",
            first_name: patientFirstName,
            gender: "",
            home_addr_1: "",
            home_addr_2: "",
            home_tel: "",
            last_name: patientLastName,
            npi: "",
            office_addr_1: "",
            office_addr_2: "",
            office_tel: "",
            role: "patient",
            specialty: "",
            ssn: "",
            state: "",
            username: selectedPatient.username,
            year_grad_med_school: "",
            internal_id: selectedPatient.patientId.toString()
          },
          [providerData.username]: {
            address_city: providerData.addressCity,
            address_zip: providerData.addressZip,
            cell: "",
            dea_number: "",
            degree: providerData.degree,
            dob: "",
            drive_license_number: "",
            email: "",
            external_id: "",
            first_name: prvFirstName,
            gender: "",
            home_addr_1: "",
            home_addr_2: "",
            home_tel: "",
            last_name: prvLastName,
            npi: "",
            office_addr_1: "",
            office_addr_2: "",
            office_tel: "",
            role: providerData.role,
            specialty: providerData.specialty,
            ssn: "",
            state: "",
            username: providerData.username,
            year_grad_med_school: "",
            internal_id: providerData.internal_id
          }
        }
      };
      setSelectedConversationUserInfo(newSelectedUserInfo);
    }
  }, [channelData, selectedPatient, providerData, userContext.userData]);

  const userId = sessionStorage.getItem("userId") || "";

  const { refetch: getNotificationsList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        "provider",
        "",
        ""
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      }
    }
  );

  const unreadMessageNotifications = useMemo(() => {
    const unseenNotifications =
      notificationsContext.filterMessageNotificationsByStatus(
        notificationsContext.notifications?.messages || [],
        "unread"
      );
    return unseenNotifications.filter(
      item =>
        selectedConversationUserInfo?.channel?.channel_name ===
        item.channel_name
    );
  }, [
    notificationsContext.notifications?.messages,
    selectedConversationUserInfo
  ]);

  const { mutate: markNotification } = useMutation(
    "mark-notification",
    createApiClient().putNotifications,
    {
      onSuccess: () => {
        getNotificationsList();
      },
      onError: () => {
        message.error("There was a problem updating notifications.");
      }
    }
  );

  useEffect(() => {
    if (selectedConversationUserInfo?.channel?.channel_name) {
      unreadMessageNotifications.forEach(item => {
        markNotification({
          notificationId: item?.notification_id || "",
          body: { status: "read", type: "messages" }
        });
      });
    }
  }, [unreadMessageNotifications, selectedConversationUserInfo]);

  const isLoading = useMemo(
    () =>
      isChannelFetching ||
      isChannelLoading ||
      isMessagesFetching ||
      isMessagesLoading,
    [isChannelFetching, isChannelLoading, isMessagesLoading, isMessagesFetching]
  );

  const initialMessagesList: MessageData[] = useMemo(
    () =>
      convertOneToOneChimeMessagesToCustomMessageData(
        selectedConversationUserInfo,
        messagesData?.messages || [],
        userContext.userData?.userName || ""
      ),
    [messagesData]
  );

  const messagesListNextToken = useMemo(
    () => messagesData?.next_token || null,
    [messagesData]
  );

  return isLoading ? (
    <LoadingContainer height={MESSAGE_CONTAINER_HEIGHT}>
      <Spin />
    </LoadingContainer>
  ) : channelError ? (
    <LoadingContainer height={MESSAGE_CONTAINER_HEIGHT}>
      <CommonErrorMessage message="Failed to fetch conversation" />
    </LoadingContainer>
  ) : (
    <MessageContainer
      messageItemWidth="55%"
      selectedConversationUserInfo={selectedConversationUserInfo}
      picture={providerData?.picture || ""}
      sender={providerData?.name || ""}
      specialization={providerData?.specialty || ""}
      subject={selectedPatient?.patientName || ""}
      messageData={initialMessagesList}
      style={{ height: MESSAGE_CONTAINER_HEIGHT }}
      nextToken={messagesListNextToken}
      onCloseMessageContainer={onCloseMessageContainer}
    />
  );
};
