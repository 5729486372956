import React from "react";
import styled from "styled-components";

import { EyeOutlined } from "@ant-design/icons";
import { AppAvatar } from "components/avatar";
import { Colors } from "helpers/colors";
import { AppCard } from "components/card";
import { useNavigate } from "react-router-dom";
import { ProvidersRoutesRef } from "constants/routes";
import { FilterDisplay } from "components/filterDisplay";
import Table, { ColumnsType } from "antd/lib/table";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { ProvidersList } from "sharedTypes";

interface AllProvidersTableProps {
  readonly data: ProvidersList[] | undefined;
  readonly loading: boolean;
  readonly role: string;
  readonly error: boolean | unknown;
  readonly selectedTab: string;
  readonly searchSpecialtyValue: string;
  readonly searchNameValue: string;
}

export const AllProvidersTable: React.FC<AllProvidersTableProps> = ({
  data,
  loading,
  searchSpecialtyValue,
  searchNameValue,
  error,
  selectedTab
}) => {
  const navigate = useNavigate();

  const compareNames = (a: any, b: any) => {
    return a.last_name === b.last_name
      ? a.first_name.localeCompare(b.first_name)
      : a.last_name.localeCompare(b.last_name);
  };

  const columns: ColumnsType<ProvidersList> = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (name: string, row: ProvidersList) => {
        return (
          <AppAvatar
            imageSrc={row.picture}
            size="large"
            mainContent={`${row.last_name.trim()}, ${row.first_name.trim()} ${
              row.degree ? row.degree : ""
            }`}
            subText={`${row.specialty}`}
          />
        );
      },
      width: "35%",
      sorter: (a, b) => compareNames(a, b),
      showSorterTooltip: { title: "Sort alphabetically" },
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "ascend"
    },
    {
      title: "Group",
      key: "group",
      dataIndex: "group",
      width: "60%",
      render: (group: string) => <GroupCol>{group}</GroupCol>
    },
    {
      key: "actions",
      dataIndex: "actions",
      render: (_, record: ProvidersList) => (
        <EyeOutlined
          style={{ fontSize: "20px", color: Colors.LightGrey }}
          onClick={() =>
            navigate(
              `/${ProvidersRoutesRef.Providers}/${record.role}/${record.id}`
            )
          }
        />
      ),
      width: "5%"
    }
  ];
  return (
    <AppCard
      cardHeight="inherit"
      cardWidth="100%"
      style={{
        borderRadius: "0px 0px 8px 8px",
        marginTop: "-2px"
      }}
    >
      <FilterDisplay value1={searchNameValue} value2={searchSpecialtyValue} />
      {error ? (
        <CommonErrorMessage
          message={`There was an error fetching the ${selectedTab} list`}
        />
      ) : (
        <Table<ProvidersList>
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={false}
          loading={loading}
          scroll={{ y: "62vh" }}
          onRow={row => {
            return {
              onClick: () =>
                navigate(
                  `/${ProvidersRoutesRef.Providers}/${row.role}/${row.id}`
                )
            };
          }}
          data-testid="all-providers-table"
        />
      )}
    </AppCard>
  );
};

const GroupCol = styled.div`
  font-size: 12px;
  color: ${Colors.Black};
`;
