import React from "react";
import { UserRoles } from "constants/roles";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { RemoteVitalsTabs } from "./remoteVitalsTabs";

export const RemoteVitalsPage: React.FC = () => {
  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const showTopbarExtesion = !userIsPatient;
  return (
    <>
      {showTopbarExtesion && <CommonPatientExtensionItems />}
      <RemoteVitalsTabs />
    </>
  );
};
