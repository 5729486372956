import { FormInstance } from "antd";
import React, { useEffect, useState } from "react";
import { CallRecordNote } from "sharedTypes";
import Tick from "svgIcons/tick";
import { SelectedCallRecordDraft } from "pages/callRecords/callRecords";
import {
  DiscussionItemListContainer,
  DiscussionItemListItem,
  DiscussionItemsSubtitle,
  DiscussionItemsTitle,
  DiscussionListItemText,
  SelectedIconContainer
} from "pages/callRecords/style";

export interface CallRecordDiscussionItemsProps {
  readonly discussionItems?: CallRecordNote[];
}

interface DiscussionObject {
  [option: string]: boolean;
}

interface AddCallRecordFormProps {
  readonly value?: CallRecordDiscussionItemsProps;
  readonly onChange?: (value: CallRecordDiscussionItemsProps) => void;
  readonly discussionItemOptions: CallRecordNote[];
  readonly clearForm: boolean;
  readonly selectedDraft?: SelectedCallRecordDraft;
  readonly form?: FormInstance;
}

const getDiscussionItemOption = (
  discussionItemOptions: CallRecordNote[]
): DiscussionObject =>
  discussionItemOptions.reduce<DiscussionObject>((acc, option) => {
    acc[option.note_detail] = false;
    return acc;
  }, {});

export const CallRecordDiscussionItems: React.FC<AddCallRecordFormProps> = ({
  value,
  onChange,
  discussionItemOptions,
  clearForm,
  selectedDraft,
  form
}) => {
  const [discussionItems, setDiscussionItems] = useState<CallRecordNote[]>([]);
  const [discussionObject, setDiscussionObject] = useState<DiscussionObject>(
    {}
  );

  const onOptionSelectionChange =
    (option: string, isSelected: boolean) => () => {
      const newSelectionObject = { ...discussionObject };
      newSelectionObject[option] = isSelected;
      setDiscussionObject(newSelectionObject);
    };

  const triggerChange = (changedValue: CallRecordDiscussionItemsProps) => {
    onChange?.({
      discussionItems,
      ...value,
      ...changedValue
    });
  };

  useEffect(() => {
    const discussionItems = getDiscussionItemOption(discussionItemOptions);
    const initialItems = selectedDraft?.itemsDiscussed
      ? selectedDraft.itemsDiscussed.split(/<br>/)
      : [];

    Object.keys(discussionItems).forEach(item => {
      initialItems.forEach(initItem => {
        if (item === initItem) {
          discussionItems[item] = true;
        }
      });
    });

    setDiscussionObject(discussionItems);
  }, [discussionItemOptions, selectedDraft]);

  useEffect(() => {
    const selectedOptions = discussionItemOptions.filter(
      option => discussionObject[option.note_detail]
    );
    setDiscussionItems(selectedOptions);
    if (form) {
      form.setFieldsValue({
        discussionItems: { discussionItems: selectedOptions }
      });
    }
    triggerChange({ discussionItems: selectedOptions });
  }, [discussionObject]);

  useEffect(() => {
    if (clearForm) {
      setDiscussionItems([]);
      if (form) {
        form.setFieldsValue({ discussionItems: { discussionItems: [] } });
      }
      setDiscussionObject(getDiscussionItemOption(discussionItemOptions));
    }
  }, [clearForm]);

  return (
    <>
      <DiscussionItemsTitle>
        {"Discussed & reviewed items below with Patient/Family"}
      </DiscussionItemsTitle>
      <DiscussionItemsSubtitle>
        Select items from list below
      </DiscussionItemsSubtitle>
      <DiscussionItemListContainer>
        {Object.keys(discussionObject).map((key, index) => (
          <DiscussionItemListItem
            key={key}
            showHr={index !== Object.keys(discussionObject).length - 1}
            onClick={onOptionSelectionChange(key, !discussionObject[key])}
          >
            <DiscussionListItemText>{key}</DiscussionListItemText>
            <SelectedIconContainer selected={discussionObject[key]}>
              <Tick />
            </SelectedIconContainer>
          </DiscussionItemListItem>
        ))}
      </DiscussionItemListContainer>
    </>
  );
};
