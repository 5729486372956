import React from "react";
import styled from "styled-components";
import moment from "moment";

interface DateFormatProps {
  readonly date: number;
}

export const DateFormat: React.FC<DateFormatProps> = ({ date }) => {
  return (
    <DateFormatStyled>
      {moment(date).format("MMM")} {moment(date).format("D")}
      {", "} {moment(date).format("YYYY")}
    </DateFormatStyled>
  );
};

const DateFormatStyled = styled.span`
  letter-spacing: 0px;
`;
