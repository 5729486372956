import React, { useEffect, useState, useRef, useContext } from "react";
import Layout from "antd/lib/layout";
import styled from "styled-components";

import { Colors } from "helpers/colors";
import { PatientTopbar } from "components/patientTopBar";
import { TopbarLogo } from "components/providerTopBar/topbarLogo";
import { useLocation } from "react-router-dom";
import { CommonRoutesRef, PatientsRoutesRef } from "constants/routes";
import { TopbarButtons } from "components/patientTopBar/topbarButtons";
import { MyMedicalDataSidebar } from "components/sideBar/myMedicalDataSidebar";
import { MyHealthStatusSidebar } from "components/sideBar/myHealthStatusSidebar";
import ConsentPage from "pages/consent/consentPage";
import { useQuery, useMutation } from "react-query";
import { createApiClient } from "apiClient";
import { Space, Spin } from "antd";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import SearchIcon from "../svgIcons/searchicon.svg";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Form, Radio, Skeleton, Switch } from "antd";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";

export const PatientsLayout: React.FC = ({ children }) => {
  const patientContext = useContext<PatientContextState>(PatientContext);

  const [keyword, setKeyword] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const debounceTimeout = useRef<any>();

  const location = useLocation();

  const navigate = useNavigate();

  const {
    isLoading,
    data: consentCheck,
    error: consentCheckError,
    refetch: consentCheckRefetch
  } = useQuery("user-consent-check", async () => {
    return await createApiClient().getConsentAfterLogin();
  });

  // const {
  //   isLoading: iskeywordLoading,
  //   data: keywordResponse,
  //   error: keywordError,
  //   refetch: keywordRefetch
  // } = useQuery("keyword-search", async () => {
  //   return await createApiClient().getKeyWordSearch(debouncedValue);
  // });

  const [keywordResponse, setKeywordResponse] = useState<any>([]);
  const searchResultBoxRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchResultBoxRef.current &&
      !searchResultBoxRef.current.contains(event.target as Node)
    ) {
      setShowBoxResult(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const { mutate: keywordRefetch, isLoading: iskeywordLoading } = useMutation(
    "keyword-search",
    async () => {
      return await createApiClient().getKeyWordSearch(debouncedValue);
    },
    {
      onSuccess: (e: any) => {
        setKeywordResponse(e);
      },
      onError: (err, body) => {
        console.error(err);
      }
    }
  );

  const [showHealthStatusSidebar, setShowHealthStatusSidebar] = useState(false);
  const [isPatientEducation, setIsPatientEducation] = useState(false);

  const [condition, setCondition] = useState(false);

  const renderSidebar =
    !location.pathname.includes(PatientsRoutesRef.ReportSymptoms) &&
    !location.pathname.includes(PatientsRoutesRef.MyCareTeam) &&
    !location.pathname.includes(CommonRoutesRef.MyProfile) &&
    !location.pathname.includes(CommonRoutesRef.Info) &&
    !location.pathname.includes(PatientsRoutesRef.AllMessages) &&
    !location.pathname.includes(PatientsRoutesRef.AllNotifications) &&
    !location.pathname.includes(PatientsRoutesRef.PatientEducation);

  useEffect(() => {
    if (location.pathname.includes(PatientsRoutesRef.MyHealthStatus)) {
      setShowHealthStatusSidebar(true);
    } else {
      setShowHealthStatusSidebar(false);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes(PatientsRoutesRef.MyHealthStatus)) {
      setIsPatientEducation(true);
    } else {
      setIsPatientEducation(false);
    }
  }, [location]);

  useEffect(() => {
    const isSinged = consentCheck?.consents?.filter(
      (sign: any) => sign.sign_date === null
    );

    if (isSinged?.length === 0) {
      setCondition(false);
    } else {
      setCondition(true);
    }
  }, [consentCheck]);

  useEffect(() => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      setDebouncedValue(keyword);
      console.log("Debounced value:", debouncedValue);
    }, 1000);
    return () => clearTimeout(debounceTimeout.current);
  }, [keyword, debouncedValue]);

  useEffect(() => {
    console.log("keywordResponse", keywordResponse);
  }, [keywordResponse, iskeywordLoading]);

  const [showSearchBoxResult, setShowBoxResult] = useState(false);
  const [mergedDocuments, setMergedDocuments] = useState([]);

  useEffect(() => {
    // Function to merge search_document arrays
    const mergeSearchDocuments = (data: any) => {
      return data.reduce((acc: any, current: any) => {
        if (current.search_document && Array.isArray(current.search_document)) {
          acc = acc.concat(current.search_document);
        }
        return acc;
      }, []);
    };

    // Merge documents when the component mounts or data changes
    const merged = mergeSearchDocuments(keywordResponse);
    setMergedDocuments(merged);
  }, [keywordResponse]);

  useEffect(() => {
    if (keyword !== "" || debouncedValue !== "") {
      setShowBoxResult(true);
      keywordRefetch();
    } else {
      setShowBoxResult(false);
    }
  }, [debouncedValue]);

  const [result, setResult] = useState();

  useEffect(() => {
    console.log(result);
  }, [result]);

  return (
    <>
      {isLoading ? (
        <Layout
          style={{
            minHeight: "100vh",
            maxHeight: "100vh",
            display: "grid",
            placeItems: "center"
          }}
        >
          <Space>
            <Spin
              tip="Loading"
              size="large"
              style={{ display: "grid", placeItems: "center" }}
            ></Spin>
          </Space>
        </Layout>
      ) : (
        <>
          {condition ? (
            <Layout
              style={{
                minHeight: "100vh",
                maxHeight: "100vh",
                display: "grid",
                placeItems: "center"
              }}
            >
              <ConsentPage
                current_route="Login"
                consentCheckRefetch={consentCheckRefetch}
              />
            </Layout>
          ) : (
            <>
              <Layout
                style={{
                  minHeight: "100vh",
                  maxHeight: "100vh",
                  paddingTop: location.pathname.includes(
                    PatientsRoutesRef.AllNotifications
                  )
                    ? "125px"
                    : !location.pathname.includes(
                        PatientsRoutesRef.PatientEducation
                      )
                    ? "60px"
                    : "20px"
                }}
              >
                {location.pathname.includes(
                  PatientsRoutesRef.PatientEducation
                ) ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "12vw",
                        paddingRight: "1vw"
                      }}
                    >
                      <div>
                        <TopbarLogo patients />
                        <h2
                          style={{
                            fontFamily: "Century Gothic Bold"
                          }}
                        >
                          Diseases and Conditions Dictionary
                        </h2>
                      </div>

                      <div style={{ position: "relative" }}>
                        <div style={{ marginBottom: 15 }}>
                          <img
                            src={SearchIcon}
                            style={{
                              position: "absolute",
                              width: "14px",
                              marginTop: 9,
                              marginLeft: 6
                            }}
                          />
                          <SeachBar
                            placeholder={
                              "Enter a keyword to search diseases and conditions. For eg. Heart Attack"
                            }
                            onChange={e => setKeyword(e.target.value)}
                          />
                          <AppButton
                            style={{ height: "32px", fontSize: "10px" }}
                            type={ButtonType.Primary}
                            buttonContent={"Back"}
                            onClick={() => {
                              navigate("/my-medical-data/symptoms");
                            }}
                          />
                        </div>
                        {showSearchBoxResult && (
                          <SearchResultBox ref={searchResultBoxRef}>
                            {iskeywordLoading ? (
                              <>
                                <Skeleton.Input
                                  active
                                  size="small"
                                  block
                                  style={{ marginBottom: 10 }}
                                />
                                <Skeleton.Input active size="small" block />
                              </>
                            ) : (
                              <>
                                {" "}
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    display: "flex",
                                    gap: 10,
                                    alignItems: "flex-end",
                                    color: "#696969",
                                    marginBottom: "12px"
                                  }}
                                >
                                  <span>SEARCH RESULTS</span>
                                  <span
                                    style={{
                                      fontWeight: "100",
                                      fontSize: "11px",
                                      color: "#696969"
                                    }}
                                  >
                                    <i>
                                      {mergedDocuments?.length} Records Found
                                    </i>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    overflow: "auto"
                                  }}
                                >
                                  {mergedDocuments.map(
                                    (item: any, index: any) => {
                                      return (
                                        <SearchResultItems
                                          key={index}
                                          onClick={() => {
                                            patientContext.setPatientEducationResult(
                                              item
                                            );
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "12px",
                                              display: "flex",
                                              gap: 10,
                                              alignItems: "flex-end",
                                              color: "#696969"
                                            }}
                                          >
                                            {item?.category_title}/{item?.title}
                                          </span>
                                          <SearchResultHTML
                                            style={{ fontSize: "12px" }}
                                            dangerouslySetInnerHTML={{
                                              __html: item?.document
                                                ?.replace(/<[^>]+>|&nbsp;/g, "")
                                                ?.split(
                                                  new RegExp(
                                                    `(${keyword})`,
                                                    "gi"
                                                  )
                                                )
                                            }}
                                          ></SearchResultHTML>
                                        </SearchResultItems>
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            )}
                          </SearchResultBox>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <PatientTopbar />
                    <TopbarButtons />
                    <TopbarLogo patients />
                  </>
                )}

                {renderSidebar ? (
                  <LayoutStyled style={{ padding: "0px", overflowX: "hidden" }}>
                    {showHealthStatusSidebar ? (
                      <MyHealthStatusSidebar>{children}</MyHealthStatusSidebar>
                    ) : (
                      <MyMedicalDataSidebar>{children}</MyMedicalDataSidebar>
                    )}
                  </LayoutStyled>
                ) : (
                  <LayoutStyled>{children}</LayoutStyled>
                )}
              </Layout>
            </>
          )}
        </>
      )}
    </>
  );
};

const SearchResultBox = styled.div`
  padding: 8px 8px;
  margin-top: -10px;
  width: 33vw;
  background-color: #ffffff;
  position: fixed;
  z-index: 99;
  border: 1px solid #dadada;
  border-radius: 2px;
  max-height: 200px;
  overflow: auto;
`;

const SearchResultItems = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  padding: 5px;
  &:hover {
    background-color: #f4f4f4;
  }
`;

const SearchResultHTML = styled.div``;

const HighlightedText = styled.span`
  background-color: yellow;
  /* Add any other styles you want */
`;

const LayoutStyled = styled(Layout)`
  overflow-x: hidden;
  overflow: auto;
  padding: "50px 0px 0px 0px";
  background-color: ${location?.pathname?.includes(
    PatientsRoutesRef.PatientEducation
  )
    ? Colors.White
    : Colors.GhostWhite};
`;

const SeachBar = styled.input`
  padding: 2px 4px;
  padding-left: 24px;
  width: 33vw;
  border-radius: 80px;
  outline: none;
  border: 1px solid black;
  height: 28px;
  font-size: 0.6rem;
  margin-right: 38px;
`;
