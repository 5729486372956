import React, { createContext, useState } from "react";
import {
  MedicationReasonsDiscontinuedCheckboxMenu,
  MedicationReasonsPrescribedCheckboxMenu
} from "sharedTypes";

export type MedicationContextState = {
  discontinuedMenuOneReasons: MedicationReasonsDiscontinuedCheckboxMenu;
  setDiscontinuedMenuOneReasons: (
    reasons: MedicationReasonsDiscontinuedCheckboxMenu
  ) => void;
  discontinuedMenuTwoReasons: MedicationReasonsDiscontinuedCheckboxMenu;
  setDiscontinuedMenuTwoReasons: (
    reasons: MedicationReasonsDiscontinuedCheckboxMenu
  ) => void;
  prescribedMenuOneReasons: MedicationReasonsPrescribedCheckboxMenu;
  setPrescribedMenuOneReasons: (
    obj: MedicationReasonsPrescribedCheckboxMenu
  ) => void;
  prescribedMenuTwoReasons: MedicationReasonsPrescribedCheckboxMenu;
  setPrescribedMenuTwoReasons: (
    obj: MedicationReasonsPrescribedCheckboxMenu
  ) => void;
};

const initialValue: MedicationContextState = {
  discontinuedMenuOneReasons: {
    reasons: []
  },
  setDiscontinuedMenuOneReasons: () => null,
  discontinuedMenuTwoReasons: {
    reasons: []
  },
  setDiscontinuedMenuTwoReasons: () => null,
  prescribedMenuOneReasons: {
    reasons: []
  },
  setPrescribedMenuOneReasons: () => null,
  prescribedMenuTwoReasons: {
    reasons: []
  },
  setPrescribedMenuTwoReasons: () => null
};

export const MedicationContext =
  createContext<MedicationContextState>(initialValue);

MedicationContext.displayName = "CheckboxMenuContext";

export const MedicationProvider: React.FC = ({ children }) => {
  const [discontinuedMenuOneReasons, setDiscontinuedMenuOneReasons] =
    useState<MedicationReasonsDiscontinuedCheckboxMenu>({
      reasons: []
    });
  const [discontinuedMenuTwoReasons, setDiscontinuedMenuTwoReasons] =
    useState<MedicationReasonsDiscontinuedCheckboxMenu>({
      reasons: []
    });

  const [prescribedMenuOneReasons, setPrescribedMenuOneReasons] =
    useState<MedicationReasonsPrescribedCheckboxMenu>({
      reasons: []
    });
  const [prescribedMenuTwoReasons, setPrescribedMenuTwoReasons] =
    useState<MedicationReasonsPrescribedCheckboxMenu>({
      reasons: []
    });

  return (
    <MedicationContext.Provider
      value={{
        discontinuedMenuOneReasons,
        setDiscontinuedMenuOneReasons,
        discontinuedMenuTwoReasons,
        setDiscontinuedMenuTwoReasons,
        prescribedMenuOneReasons,
        setPrescribedMenuOneReasons,
        prescribedMenuTwoReasons,
        setPrescribedMenuTwoReasons
      }}
    >
      {children}
    </MedicationContext.Provider>
  );
};
