import React from "react";
import { createApiClient } from "apiClient";
import { useDebounce } from "helpers/customHooks";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { ProviderDetailsPage } from "./providerDetails";

export const ProviderDetails: React.FC = () => {
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounce(searchValue);

  const { data, isLoading, isFetching } = useQuery(
    ["selected-provider-profile", debouncedSearchValue],
    async () => {
      return await createApiClient().getProviderProfile(
        id || "",
        searchValue,
        true
      );
    },
    { enabled: id ? true : false }
  );

  return (
    <ProviderDetailsPage
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  );
};
