import moment from "moment";
import { startCase } from "helpers/utils";
import { SymptomsListResponse } from "sharedTypes";
import { Symptom, SymptomsListData } from "./SymptomsList";
import { orderBy } from "lodash";

interface ProcessedSymptomListObject {
  [key: string]: SymptomsListData;
}

const getSymptomReporterNameandRole = (symptomItem: SymptomsListResponse) => {
  const openBracketIndex = symptomItem.reportedBy.indexOf("[");
  const closedBracketIndex = symptomItem.reportedBy.indexOf("]");
  if (openBracketIndex !== -1 && closedBracketIndex !== -1) {
    const userName = symptomItem.reportedBy.slice(0, openBracketIndex);
    const userRole = symptomItem.reportedBy.slice(
      openBracketIndex + 1,
      closedBracketIndex
    );
    return [userName, userRole];
  }
  return [symptomItem.reportedBy, ""];
};

const getSymptomItemDataArray = (
  id: string | number,
  symptomName: string,
  symptomData: string
): Symptom[] => [
  {
    id: id,
    name: symptomName,
    data: symptomData.split(/\n/).map(symptom => {
      const [key, value] = symptom.split(/:\s+/);
      return { key: key, value: value };
    })
  }
];

export const getSymptomListData = (data: SymptomsListResponse[]) => {
  const processedData: ProcessedSymptomListObject = data.reduce(
    (acc: ProcessedSymptomListObject, symptomItem) => {
      const [userName, userRole] = getSymptomReporterNameandRole(symptomItem);
      const [symptomName, symptomData] =
        symptomItem.info_text.split(/\n\s*-{2,}\s*\n\s*/);

      const sortSymptomsArray = (symptoms: Symptom[]) =>
        orderBy(
          symptoms,
          [
            element =>
              moment(
                element?.data?.find(i => i?.key === "BP taken on")?.value,
                "MM/DD/YYYY HH:mm:ss"
              ).valueOf(),
            element =>
              moment(
                element?.data?.find(i => i?.key === "Weight taken on")?.value,
                "MM/DD/YYYY HH:mm:ss"
              ).valueOf()
          ],
          ["desc"]
        );

      const symptomItemDataArray = getSymptomItemDataArray(
        symptomItem.id ? String(symptomItem.id) : "",
        symptomName,
        symptomData
      );

      const existingSymptomItem =
        acc[
          `${moment(symptomItem.enter_date, "MM/DD/YYYY").format(
            "MMM DD, YYYY"
          )}_${userName}_${startCase(userRole)}`
        ];

      if (existingSymptomItem) {
        const { symptoms, ...rest } = existingSymptomItem;
        const newExistingSmptomsObject: SymptomsListData = {
          symptoms: sortSymptomsArray([...symptoms, ...symptomItemDataArray]),
          ...rest
        };
        acc[
          `${moment(symptomItem.enter_date, "MM/DD/YYYY").format(
            "MMM DD, YYYY"
          )}_${userName}_${startCase(userRole)}`
        ] = newExistingSmptomsObject;
        return acc;
      }

      acc[
        `${moment(symptomItem.enter_date, "MM/DD/YYYY").format(
          "MMM DD, YYYY"
        )}_${userName}_${startCase(userRole)}`
      ] = {
        timestamp: moment(symptomItem.enter_date, "MM/DD/YYYY").format(
          "MMM DD, YYYY"
        ),
        millisTimestamp: moment(symptomItem.enter_date, "MM/DD/YYYY").valueOf(),
        reporterName: userName,
        reporterRole: startCase(userRole),
        symptoms: sortSymptomsArray(symptomItemDataArray)
      };

      return acc;
    },
    {}
  );
  return Object.keys(processedData)
    .map(key => processedData[key])
    .sort((a, b) => {
      return b.millisTimestamp - a.millisTimestamp;
    });
};

export const getSymptomListData2 = (data: SymptomsListResponse[]) =>
  data
    .reduce((acc: SymptomsListData[], symptomItem) => {
      const [userName, userRole] = getSymptomReporterNameandRole(symptomItem);
      const [symptomName, symptomData] =
        symptomItem.info_text.split(/\n\s*-{2,}\s*\n\s*/);

      const symptomItemDataArray = getSymptomItemDataArray(
        symptomItem.id ? String(symptomItem.id) : "",
        symptomName,
        symptomData
      );

      const existingSymptomItem = acc.find(
        item =>
          item.timestamp ===
            moment(symptomItem.enter_date, "MM/DD/YYYY").format(
              "MMM DD, YYYY"
            ) &&
          item.reporterName === userName &&
          item.reporterRole === startCase(userRole)
      );
      const remainingAccItems = acc.filter(
        item =>
          item.timestamp !==
          moment(symptomItem.enter_date, "MM/DD/YYYY").format("MMM DD, YYYY")
      );

      if (existingSymptomItem) {
        const { symptoms, ...rest } = existingSymptomItem;
        return [
          { symptoms: [...symptoms, ...symptomItemDataArray], ...rest },
          ...remainingAccItems
        ];
      }
      return [
        {
          timestamp: moment(symptomItem.enter_date, "MM/DD/YYYY").format(
            "MMM DD, YYYY"
          ),
          millisTimestamp: moment(
            symptomItem.enter_date,
            "MM/DD/YYYY"
          ).valueOf(),
          reporterName: userName,
          reporterRole: startCase(userRole),
          symptoms: symptomItemDataArray
        },
        ...remainingAccItems
      ];
    }, [])
    .sort((a, b) => {
      return b.millisTimestamp - a.millisTimestamp;
    });
