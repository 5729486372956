export enum ProvidersRoutesRef {
  Patients = "patients",
  Providers = "providers",
  Messages = "messages",
  Dashboard = "dashboard",
  Billings = "billings",
  PatientMessages = "/patients/messages",
  AllNotifications = "/all-notifications",
  RemoteVitals = "/patients/remote-vitals",
  CallRecords = "/patients/call-records",
  Notifications = "/patients/notifications",
  RiskProfile = "/patients/risk-profile",
  PatientProfile = "/patients/patient-profile",
  CallLogs = "/patients/call-logs",
  CareTeam = "/patients/care-team",
  Laboratory = "/patients/laboratory",
  Symptoms = "/patients/symptoms",
  Careguidelines = "/patients/care-guidelines",
  Images = "/patients/images",
  Tasks = "/patients/tasks",
  Medications = "/patients/medications",
  FluidMetrics = "/patients/fluid-metrics",
  Performance = "/patients/performance",
  Billing = "/patients/billing",
  Appointment = "/patients/appointments",
  CarePlan = "/patients/care-plan",
  Survey = "/patients/surveys",
  Respond = "/patients/surveys/respond",
  ViewForm = "/patients/surveys/viewform",
  SurveyReports = "/survey-reports"
}

export enum PatientsRoutesRef {
  MyHealthStatus = "my-health-status",
  MyMedicalData = "my-medical-data",
  MyCareTeam = "my-care-team",
  MyCurrentStatus = "/my-health-status/my-current-status",
  LearningCenter = "/my-health-status/learning-center",
  ImproveMyRisk = "/my-health-status/improve-my-risk",
  EarnRewards = "/my-health-status/earn-rewards",
  Symptoms = "/my-medical-data/symptoms",
  RemoteVitals = "/my-medical-data/remote-vitals",
  Medications = "/my-medical-data/medications",
  Laboratory = "/my-medical-data/laboratory",
  Images = "/my-medical-data/images",
  CarePlan = "/my-medical-data/care-plan",
  CareGuidelines = "/my-medical-data/care-guidelines",
  EducationalTips = "/my-medical-data/educational-tips",
  EarnRewardsMyMedicalData = "/my-medical-data/earn-rewards",
  ReportSymptoms = "/report-symptoms",
  AllMessages = "/all-messages",
  Appointments = "/my-care-team/appointments",
  AllNotifications = "/all-notifications",
  Survey = "/my-health-status/surveys",
  Respond = "/my-health-status/surveys/respond",
  ViewForm = "/my-health-status/surveys/viewform",
  PatientEducation = "/my-medical-data/patient-education",
  VCMeetPage = "/VCMeetPage"
}

export enum CaregiverRoutesRef {
  Patients = "patients",
  MyHealthStatus = "health-status",
  MyMedicalData = "medical-data",
  MyCareTeam = "care-team",
  MyCurrentStatus = "/health-status/current-status",
  LearningCenter = "/health-status/learning-center",
  ImproveMyRisk = "/health-status/improve-my-risk",
  EarnRewards = "/health-status/earn-rewards",
  Symptoms = "/medical-data/symptoms",
  RemoteVitals = "/medical-data/remote-vitals",
  Medications = "/medical-data/medications",
  Laboratory = "/medical-data/laboratory",
  Images = "/medical-data/images",
  CarePlan = "/medical-data/care-plan",
  CareGuidelines = "/medical-data/care-guidelines",
  EducationalTips = "/medical-data/educational-tips",
  EarnRewardsMyMedicalData = "/medical-data/earn-rewards",
  ReportSymptoms = "/report-symptoms",
  AllMessages = "/all-messages",
  Appointments = "/care-team/appointments",
  AllNotifications = "/all-notifications",
  PatientMessages = "/medical-data/messages",
  Survey = "/health-status/surveys",
  Respond = "/health-status/surveys/respond",
  ViewForm = "/health-status/surveys/viewform"
}

export enum CommonRoutesRef {
  ForgotPassword = "forgotPassword",
  MyProfile = "/my_profile",
  Info = "/info"
}

export enum PublicRoutesRef {
  ForgotPassword = "forgotPassword",
  OauthLaunch = "/fhir/epic/launch",
  OauthRedirect = "/fhir/epic/redirect"
}

export enum MobileRoutesRef {
  MobileSymptomForms = "/mobile-symptom-forms"
}
