import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Checkbox from "antd/lib/checkbox";
import Radio from "antd/lib/radio";
import Form, { RuleObject } from "antd/lib/form";
import React, { MutableRefObject } from "react";
import styled from "styled-components";

import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { SurveyInput } from "pages/symptoms/surveyForms/surveyInput";
import { Platform } from "constants/roles";

interface ProcedureToRemoveKidneyStoneSectionProps {
  readonly currentPage: number;
  setOtherWhenWasKidneyStoneRemovedTempInput(input: string): void;
  readonly otherWhenWasKidneyStoneRemovedTempInput: string;
  readonly otherWhenWasKidneyStoneRemovedTempInputRef: MutableRefObject<string>;
  setShowSubmit(show: boolean): void;
}

export const ProcedureToRemoveKidneyStoneSection: React.FC<
  ProcedureToRemoveKidneyStoneSectionProps
> = ({
  currentPage,
  otherWhenWasKidneyStoneRemovedTempInputRef,
  otherWhenWasKidneyStoneRemovedTempInput,
  setOtherWhenWasKidneyStoneRemovedTempInput,
  setShowSubmit
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const validateWhenWasProcedurePerformed = (
    rules: RuleObject,
    value: string,
    callback: (error?: string) => void
  ) => {
    if (
      otherWhenWasKidneyStoneRemovedTempInputRef.current === "" &&
      localStorage.getItem("whenWasProcedurePerformed")?.includes("Other")
    ) {
      callback("Please enter value for 'Other' field.");
    } else {
      callback();
    }
  };

  return (
    <>
      {currentPage === 12 && (
        <Space direction="vertical" size={20}>
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{ width: "100%", padding: "10px" }}
              >
                <DisclaimerTitle>
                  PROCEDURES TO REMOVE KIDNEY STONE
                </DisclaimerTitle>
                <CardTitle>
                  Have you ever had a procedure to remove your stone?
                  <AsteriskStyle> *</AsteriskStyle>
                </CardTitle>
                <FormItemStyle
                  name="Have you ever had a procedure to remove your stone"
                  label=""
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  initialValue={""}
                >
                  <Radio.Group
                    value={localStorage.getItem(
                      "haveHadProcedureToRemoveStone"
                    )}
                    onChange={e => {
                      if (e.target.value === "No") {
                        setShowSubmit(true);
                      } else {
                        setShowSubmit(false);
                      }
                      localStorage.setItem(
                        "haveHadProcedureToRemoveStone",
                        e.target.value
                      );
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={"Yes"}>Yes</Radio>
                      <Radio value={"No"}>No</Radio>
                    </Space>
                  </Radio.Group>
                </FormItemStyle>
              </Space>
            </AppCard>
          </Space>
        </Space>
      )}
      {currentPage === 13 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Kidney Stone Symptoms</DisclaimerTitle>
              <CardTitle>When was this procedure performed?</CardTitle>

              <FormItemStyle
                name="When was procedure performed"
                label=""
                initialValue={""}
                rules={[
                  {
                    validator: validateWhenWasProcedurePerformed,
                    validateTrigger: "submit"
                  }
                ]}
              >
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={e =>
                    localStorage.setItem(
                      "whenWasProcedurePerformed",
                      e.toString()
                    )
                  }
                  value={sessionStorage
                    .getItem("whenWasProcedurePerformed")
                    ?.split(/,/)}
                >
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Checkbox value="In the last 2 weeks">
                        In the last 2 weeks
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="In the last 3 months">
                        In the last 3 months
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="In the last 3 - 6 months">
                        In the last 3 - 6 months
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="In the last 6 - 12 months">
                        In the last 6 - 12 months
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="More than 1 year ago">
                        More than 1 year ago
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Other">Other</Checkbox>
                      <SurveyInput
                        value={
                          otherWhenWasKidneyStoneRemovedTempInput ??
                          otherWhenWasKidneyStoneRemovedTempInputRef
                        }
                        placeholder={""}
                        onChange={e => {
                          setOtherWhenWasKidneyStoneRemovedTempInput(
                            e.target.value
                          );
                          otherWhenWasKidneyStoneRemovedTempInputRef.current =
                            e.target.value;
                        }}
                        style={{ width: "70%", marginLeft: "30px" }}
                      />
                    </Col>
                  </Row>
                </Checkbox.Group>
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
    </>
  );
};

const DisclaimerTitle = styled.div`
  width: 100%;
  padding: 8px 8px 8px 20px;
  margin-left: -25px;
  background: ${Colors.DodgerBlue};
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 8px 8px 0px 0px;
  color: white;
  position: absolute;
  top: 0;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
