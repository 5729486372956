import moment from "moment";
import { lowerCaseRemoveSpace } from "helpers/utils";
import { ExtendedSymptomData } from "pages/symptoms/SymptomsModal";

export const ulcersMapper = ({
  filteredExtendedSymptomData,
  mapperType,
  mapperValue
}: {
  filteredExtendedSymptomData: ExtendedSymptomData[] | null;
  mapperType?: string | undefined;
  mapperValue?: string | number | undefined;
}) => {
  const severity: [string, number, string][] = [];
  const frequency: [string, number, string][] = [];

  const sortedData = filteredExtendedSymptomData?.sort(
    (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
  );

  if (mapperType) {
    let tooltipValue = "";
    if (lowerCaseRemoveSpace(mapperType) === "size") {
      switch (mapperValue) {
        case 1:
          tooltipValue = "Small (less than 2 cm)";
          break;
        case 5:
          tooltipValue = "Medium (Between 2 cm and 5 cm)";
          break;
        case 10:
          tooltipValue = "Big (more than 5 cm)";
          break;
      }
    } else if (lowerCaseRemoveSpace(mapperType) === "appearance") {
      switch (mapperValue) {
        case 1:
          tooltipValue = "Dry";
          break;
        case 4:
          tooltipValue = "Moist";
          break;
        case 7:
          tooltipValue = "Draining";
          break;
        case 10:
          tooltipValue = "With an offensive odor";
          break;
      }
    }
    return tooltipValue;
  }

  sortedData?.forEach(item => {
    item.data?.map(item2 => {
      if (lowerCaseRemoveSpace(item2?.key) === "size") {
        let value = 0;
        switch (item2?.value?.toLowerCase().replace(/\s/g, "")) {
          case "small(lessthan2cm)":
            value = 1;
            break;
          case "medium(between2cmand5cm)":
            value = 5;
            break;
          case "big(morethan5cm)":
            value = 10;
            break;
        }
        severity.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Size"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "appearance") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "dry":
            value = 1;
            break;
          case "moist":
            value = 4;
            break;
          case "draining":
            value = 7;
            break;
          case "withanoffensiveodor":
            value = 10;
            break;
        }
        frequency.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Appearance"
        ]);
      }
    });
  });

  return [severity, frequency];
};
