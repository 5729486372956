import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { AppCard } from "components/card";
import { AppLineChart } from "components/lineChart";
import { AppModal } from "components/modal";
import { Colors } from "helpers/colors";

export interface SelectOption<T> {
  readonly label: string;
  readonly value: T;
  readonly disabled?: boolean;
}

interface ItemDetail {
  readonly key: string;
  readonly value: string;
}

export interface Item {
  readonly name: string;
  readonly description?: string;
  readonly data?: ItemDetail[];
}

interface BloodPressureModalProps {
  readonly isModalOpen: boolean;
  readonly onModalClose: () => void;
  readonly selectedItem: string;
  readonly extendedItemData: ExtendedItemData[];
}

export interface ExtendedItemData extends Item {
  readonly timestamp: string;
  readonly reporterName: string;
  readonly reporterRole: string;
}

export const MyBloodPressureModal: React.FC<BloodPressureModalProps> = ({
  isModalOpen,
  onModalClose,
  selectedItem,
  extendedItemData
}) => {
  const [selectedBPItem, setSelectedBPItem] = useState<string>("");

  const filteredExtendedSymptomData = useMemo(
    () => extendedItemData.filter(data => data.name === selectedBPItem),
    [selectedBPItem, extendedItemData]
  );

  const EmptyModalOKFunc = () => null;

  useEffect(() => {
    setSelectedBPItem(selectedItem);
  }, [selectedItem]);

  return (
    <AppModal
      footer={false}
      cancelText=""
      okText=""
      onCancel={onModalClose}
      onOk={EmptyModalOKFunc}
      visible={isModalOpen}
      width="70vw"
      title={<div>{selectedItem}</div>}
    >
      <ModalContentContainer>
        <SymptomDetailChartContainer>
          <AppCard
            cardHeight="100%"
            cardWidth="96%"
            title={<ChartTitleText>Duration</ChartTitleText>}
          >
            <AppLineChart
              chartName="Duration"
              chartHeight="90px"
              customColors={["#05348f"]}
              data={[
                ["1", 0],
                ["2", 8],
                ["3", 3],
                ["4", 4],
                ["5", 2]
              ]}
            />
          </AppCard>
          <AppCard
            cardHeight="100%"
            cardWidth="96%"
            title={<ChartTitleText>Frequency</ChartTitleText>}
          >
            <AppLineChart
              chartName="Frequency"
              chartHeight="90px"
              customColors={["#05348f"]}
              data={[
                ["1", 0],
                ["2", 8],
                ["3", 3],
                ["4", 4],
                ["5", 2]
              ]}
            />
          </AppCard>
          <AppCard
            cardHeight="100%"
            cardWidth="96%"
            title={<ChartTitleText>Severity</ChartTitleText>}
          >
            <AppLineChart
              chartName="Severity"
              chartHeight="90px"
              customColors={["#05348f"]}
              data={[
                ["1", 0],
                ["2", 8],
                ["3", 3],
                ["4", 4],
                ["5", 2]
              ]}
            />
          </AppCard>
        </SymptomDetailChartContainer>
        <SymptomModalDetailsListContainer>
          <SymptomsTitleText>Details</SymptomsTitleText>
          {filteredExtendedSymptomData.map((symptom, index) => (
            <SymptomModalDetailsListItem
              key={index}
              showHr={index !== filteredExtendedSymptomData.length - 1}
            >
              <SymptomDetailListItemHeader>{`${symptom.timestamp} | By ${symptom.reporterName} (${symptom.reporterRole})`}</SymptomDetailListItemHeader>

              {symptom.description ? (
                <SymptomItemDesctiptionTextContainer>
                  {symptom.description}
                </SymptomItemDesctiptionTextContainer>
              ) : (
                <SymptomItemDetailsGridContainer>
                  {symptom.data?.map(dataItem => (
                    <React.Fragment key={dataItem.key}>
                      <SymptomItemDetailKeyText>{`${dataItem.key} :`}</SymptomItemDetailKeyText>
                      <SymptomItemDetailValueText>
                        {dataItem.value}
                      </SymptomItemDetailValueText>
                    </React.Fragment>
                  ))}
                </SymptomItemDetailsGridContainer>
              )}
            </SymptomModalDetailsListItem>
          ))}
        </SymptomModalDetailsListContainer>
      </ModalContentContainer>
    </AppModal>
  );
};

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const SymptomsTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-top: 18px;
`;
export const OverflowContainer = styled.div`
  height: calc(100vh - 200px);
  overflow: auto;
`;
export const EmptyDataText = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;
export const ErrorMessage = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  color: red;
  opacity: 0.3;
  font-weight: bold;
`;
export const SkeletonFlexContainer = styled.div`
  display: flex;
  gap: 20px;
`;
export const SymptomsListItemHeaderText = styled.div`
  font-size: 12px;
  padding-bottom: 5px;
`;
export const SymptomsListItemContainer = styled.div`
  margin-bottom: 15px;
`;
export const SymptomTitleListContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  height: 30px;
  cursor: pointer;
`;
export const SymptomTitleText = styled.div`
  font-style: 14px;
  font-weight: bold;
  line-height: 17px;
  color: ${Colors.DodgerBlue};
`;
export const AddSymptomButtonContaner = styled.div`
  display: flex;
  align-items: center;
`;
export const AddSymptomButtonText = styled.span`
  margin-left: 10px;
`;

export const SymptomsItemsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fill, 100px);
  grid-gap: 10px 10px;
`;
export const SymptomItemDesctiptionTextContainer = styled.div`
  font-size: 14px;
  padding-top: 5px;
`;
export const SymptomItemDetailsGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  padding-top: 5px;
`;
export const SymptomItemDetailKeyText = styled.div`
  font-style: 14px;
`;
export const SymptomItemDetailValueText = styled.div`
  font-style: 14px;
  font-weight: bold;
`;

interface SymptomGridItemProps {
  readonly spanLength: number;
}
export const SymptomGridItem = styled.div<SymptomGridItemProps>`
  grid-row: ${props => `span ${props.spanLength}`};
`;

export const ModalContentContainer = styled.div`
  height: 70vh;
  display: grid;
  grid-template-columns: 1fr 40%;
  grid-column-gap: 10px;
`;
export const SymptomModalDetailsListContainer = styled.div`
  border-left: ${`1px solid ${Colors.Lavender}`};
  padding-left: 15px;
  overflow: auto;
`;
interface SymptomModalDetailsListItemProps {
  readonly showHr: boolean;
}
export const SymptomModalDetailsListItem = styled.div<SymptomModalDetailsListItemProps>`
  border-bottom: ${props =>
    props.showHr ? `1px solid ${Colors.Lavender}` : "none"};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;
export const SymptomDetailChartContainer = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-template-rows: 1fr 1fr 1fr;
  overflow: auto;
`;
export const ChartTitleText = styled.div`
  font-size: 14px;
`;
export const SymptomDetailListItemHeader = styled.div`
  font-size: 12px;
  margin-top: 10px;
`;
export const AddSymptomFormTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
`;
export const AddSymptomFormSubtitleText = styled.div`
  font-size: 12px;
`;
interface AddSymptomFormItemProps {
  readonly selected: boolean;
}
export const AddSymptomFormItem = styled.div<AddSymptomFormItemProps>`
  border: ${props =>
    props.selected ? "none" : `1px solid ${Colors.DodgerBlue}`};
  border-radius: 8px;
  background-color: ${props =>
    props.selected ? Colors.DodgerBlue : Colors.AliceBlue};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  position: relative;
`;
export const TickContainer = styled.div`
  position: absolute;
  top: 0;
  background-color: white;
  height: 16px;
  width: 16px;
  right: 20px;
  display: flex;
`;
export const AddSymptomFormButtonContainer = styled.div`
  border-top: ${`1px solid ${Colors.AliceBlue}`};
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
`;
export const AddSymptomFormItemIconContainer = styled.span`
  position: absolute;
  left: 15px;
`;
export const AddSymptomFormItemTextContainer = styled.span`
  font-weight: bold;
`;
