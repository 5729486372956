export const UserRoles = {
  PATIENT: "patient",
  CAREGIVER: "caregiver",
  CAREGIVERS: "caregivers",
  SUPER_ADMIN: "super_admin",
  CUSTOMER_ADMIN: "customer_admin",
  PROVIDER: "provider"
};

export const Platform = {
  MOBILE: "mobile"
};
