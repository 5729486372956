// import Row from "antd/lib/row";
// import Col from "antd/lib/col";
import React from "react";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
// import PerformanceDetails from "./performanceDetails";
// import { PerformanceHeader } from "./performanceHeader";
// import UtilizationDetails from "./utilizationDetails";
import { Result } from "antd";

export const PerformancePage: React.FC = () => {
  return (
    <>
      <CommonPatientExtensionItems />
      {/* <PerformanceHeader />
      <Row gutter={[15, 0]} justify="space-between">
        <Col span={12}>
          <PerformanceDetails />
        </Col>
        <Col span={12}>
          <UtilizationDetails />
        </Col>
      </Row> */}
      <Result
        status="404"
        title="Work In Progress"
        subTitle="Sorry, the page you visited is still in development."
      />
    </>
  );
};
