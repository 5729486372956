import React, { useContext, useEffect, useMemo, useState } from "react";
import { createApiClient } from "apiClient";
import Spin from "antd/lib/spin";
import { MessageContainer } from "components/messageContainer";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { useMutation, useQuery } from "react-query";
import { SelectedConversationUserInfo } from "pages/messages/messages";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { AllPatientsList, UserData } from "sharedTypes";
import {
  convertOneToOneChimeMessagesToCustomMessageData,
  MessageData
} from "components/messageContainer/messageContainer";
import { UserRoles } from "constants/roles";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { message } from "antd";

export const IndividualMessagesSection: React.FC = () => {
  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);
  const [selectedConversationUserInfo, setSelectedConversationUserInfo] =
    useState<SelectedConversationUserInfo | null>(null);
  const [patientData, setPatientData] = useState<AllPatientsList | null>(null);
  const [providerData, setProviderData] = useState<UserData | null>(null);
  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const {
    isLoading: isChannelLoading,
    data: channelData,
    error: channelError,
    isFetching: isChannelFetching,
    refetch: getChannel
  } = useQuery(
    "individual_channel",
    async () => {
      return await createApiClient().getChannel(
        patientData?.username || "",
        providerData?.userName || "",
        true
      );
    },
    {
      enabled: false
    }
  );

  const {
    data: messagesData,
    isLoading: isMessagesLoading,
    isFetching: isMessagesFetching
  } = useQuery(
    [
      "provider_patient_individual_channel_messages",
      selectedConversationUserInfo
    ],
    async () => {
      return await createApiClient().listChannelMessages(
        selectedConversationUserInfo?.channel.channel_name || ""
      );
    },
    { enabled: selectedConversationUserInfo ? true : false }
  );

  useEffect(() => {
    if (patientContext.patientData && userContext.userData) {
      setPatientData(patientContext.patientData);
      setProviderData(userContext.userData);
    }
  }, [userContext.userData, patientContext.patientData]);

  useEffect(() => {
    if (patientData && patientData.username && providerData) {
      getChannel();
    }
  }, [patientData, providerData]);

  useEffect(() => {
    if (
      channelData &&
      patientData &&
      providerData &&
      typeof channelData !== "string"
    ) {
      const newSelectedUserInfo: SelectedConversationUserInfo = {
        channel: {
          channel_arn: channelData.channel_arn,
          channel_name: channelData.channel_name,
          is_patient_enabled: channelData.is_patient_enabled,
          is_patient_linked_channel: 0,
          latest_message: "",
          latest_message_timestamp: "",
          pat_uname: patientData.username,
          user1_uname: providerData.userName,
          user2_uname: ""
        },
        userInfo: {
          [patientData.username]: {
            address_city: "",
            address_zip: "",
            cell: "",
            dea_number: "",
            degree: "",
            dob: patientData.dob,
            drive_license_number: "",
            email: "",
            external_id: "",
            first_name: patientData.first_name,
            gender: "",
            home_addr_1: "",
            home_addr_2: "",
            home_tel: "",
            last_name: patientData.last_name,
            npi: "",
            office_addr_1: "",
            office_addr_2: "",
            office_tel: "",
            role: "patient",
            specialty: "",
            ssn: "",
            state: "",
            username: patientData.username,
            year_grad_med_school: "",
            internal_id: patientData.internal_id
          },
          [providerData.userName]: {
            address_city: providerData.city,
            address_zip: providerData.zipCode,
            cell: providerData.cell,
            dea_number: "",
            degree: providerData.degree,
            dob: "",
            drive_license_number: "",
            email: providerData.email,
            external_id: "",
            first_name: providerData.firstName,
            gender: "",
            home_addr_1: "",
            home_addr_2: "",
            home_tel: "",
            last_name: providerData.lastName,
            npi: "",
            office_addr_1: "",
            office_addr_2: "",
            office_tel: "",
            role: providerData.role,
            specialty: providerData.specialty,
            ssn: "",
            state: "",
            username: providerData.userName,
            year_grad_med_school: "",
            internal_id: providerData.internal_id
          }
        }
      };
      setSelectedConversationUserInfo(newSelectedUserInfo);
    }
  }, [channelData, patientData, providerData]);

  const isLoading = useMemo(
    () =>
      isChannelFetching ||
      isChannelLoading ||
      isMessagesFetching ||
      isMessagesLoading,
    [isChannelFetching, isChannelLoading, isMessagesLoading, isMessagesFetching]
  );

  const initialMessagesList: MessageData[] = useMemo(
    () =>
      convertOneToOneChimeMessagesToCustomMessageData(
        selectedConversationUserInfo,
        messagesData?.messages || [],
        userContext.userData?.userName || ""
      ),
    [messagesData]
  );

  const messagesListNextToken = useMemo(
    () => messagesData?.next_token || null,
    [messagesData]
  );

  const { refetch: getNotificationsList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        "provider",
        "",
        ""
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      }
    }
  );

  const { mutate: markNotification } = useMutation(
    "mark-notification",
    createApiClient().putNotifications,
    {
      onSuccess: () => {
        getNotificationsList();
      },
      onError: () => {
        message.error("There was a problem updating notifications.");
      }
    }
  );

  const unreadMessageNotifications = useMemo(() => {
    const unseenNotifications =
      notificationsContext.filterMessageNotificationsByStatus(
        notificationsContext.notifications?.messages || [],
        "unread"
      );
    return unseenNotifications;
  }, [notificationsContext.notifications?.messages]);

  const userId = sessionStorage.getItem("userId") || "";

  useEffect(() => {
    if (isLoading) return;
    if (selectedConversationUserInfo?.channel?.channel_name) {
      const unreadMessages = unreadMessageNotifications.filter(
        item =>
          selectedConversationUserInfo?.channel?.channel_name ===
          item.channel_name
      );

      unreadMessages.forEach(item => {
        markNotification({
          notificationId: item?.notification_id || "",
          body: { status: "read", type: "messages" }
        });
      });
    }
  }, [unreadMessageNotifications, isLoading]);

  return isLoading ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "grid",
        placeItems: "center"
      }}
    >
      <Spin />
    </div>
  ) : channelError ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "grid",
        placeItems: "center"
      }}
    >
      <CommonErrorMessage message="Failed to fetch conversation" />
    </div>
  ) : (
    <MessageContainer
      messageItemWidth="55%"
      selectedConversationUserInfo={selectedConversationUserInfo}
      picture=""
      sender={patientData?.name || ""}
      specialization=""
      subject=""
      messageData={initialMessagesList}
      style={{ height: "calc(62vh + 94px)" }}
      nextToken={messagesListNextToken}
      customPlaceholderText="Enter your message (this will be shared with all providers)"
      userIsCaregiver={userIsCaregiver}
    />
  );
};
