import React, { useContext, useEffect, useMemo, useState } from "react";
import { createApiClient } from "apiClient";
import { MessageContainer } from "components/messageContainer";
import { CareTeamList } from "sharedTypes";
import { useMutation, useQuery } from "react-query";
import { UserContextState, UserContext } from "contextApis/userContext";
import { SelectedConversationUserInfo } from "pages/messages/messages";
import {
  convertOneToOneChimeMessagesToCustomMessageData,
  MessageData
} from "components/messageContainer/messageContainer";
import Spin from "antd/lib/spin";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { UserRoles } from "constants/roles";
import { AppCard } from "components/card";
import { CloseIcon } from "svgIcons/closeIcon";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";

interface MessagesSectionProps {
  selectedCareteamMember: CareTeamList | null;
  onCloseMessageContainer: () => void;
}

export const MessagesSection: React.FC<MessagesSectionProps> = ({
  selectedCareteamMember,
  onCloseMessageContainer
}) => {
  const userRole = sessionStorage.getItem("userRole");
  const userIsPatient = userRole === UserRoles.PATIENT;
  const userIsCaregiver = userRole === UserRoles.CAREGIVER;
  const userIsProvider =
    userRole !== UserRoles.CAREGIVER && userRole !== UserRoles.PATIENT;

  const userContext = useContext<UserContextState>(UserContext);
  const [selectedConversationUserInfo, setSelectedConversationUserInfo] =
    useState<SelectedConversationUserInfo | null>(null);
  const {
    isLoading: isChannelLoading,
    data: channelData,
    error: channelError,
    isFetching: isChannelFetching,
    refetch: getChannel
  } = useQuery(
    "messages_individual_channel",
    async () => {
      if (userIsPatient) {
        return await createApiClient().getChannel(
          userContext.userData?.userName || "",
          selectedCareteamMember?.user.username || "",
          true
        );
      }
      return await createApiClient().getChannel(
        selectedCareteamMember?.info.username || "",
        userContext.userData?.userName || "",
        false,
        selectedCareteamMember?.user.username || ""
      );
    },
    {
      enabled: false
    }
  );

  const {
    data: messagesData,
    isLoading: isMessagesLoading,
    isFetching: isMessagesFetching,
    refetch: refetchChannelMessages
  } = useQuery(
    [
      "selected_caretem_provider_channel_messages",
      selectedConversationUserInfo
    ],
    async () => {
      return await createApiClient().listChannelMessages(
        selectedConversationUserInfo?.channel.channel_name || ""
      );
    },
    { enabled: selectedConversationUserInfo ? true : false }
  );

  useEffect(() => {
    if (selectedCareteamMember && userContext.userData) {
      if (
        userContext.userData.userName &&
        selectedCareteamMember.user.username
      ) {
        getChannel();
      }
    }
  }, [selectedCareteamMember, userContext.userData]);

  useEffect(() => {
    const loggedInUserData = userContext.userData;
    if (
      channelData &&
      selectedCareteamMember &&
      loggedInUserData &&
      typeof channelData !== "string"
    ) {
      const selectedCareTeamMemberData = selectedCareteamMember.user;
      const selectedPatientData = selectedCareteamMember.info;
      const selectedConversation: SelectedConversationUserInfo = {
        channel: {
          channel_arn: channelData.channel_arn,
          channel_name: channelData.channel_name,
          is_patient_enabled: userIsCaregiver
            ? selectedCareTeamMemberData.role.toLowerCase() === "caregivers"
              ? 1
              : 0
            : userIsPatient
            ? channelData.is_patient_enabled
            : 1,
          is_patient_linked_channel: userIsPatient ? 0 : 1,
          latest_message: "",
          latest_message_timestamp: "",
          pat_uname: userIsPatient
            ? loggedInUserData.userName || ""
            : selectedCareteamMember.info.username,
          user1_uname: userIsPatient
            ? selectedCareTeamMemberData.username
            : loggedInUserData.userName || "",
          user2_uname:
            userIsCaregiver || userIsProvider
              ? selectedCareTeamMemberData.username
              : ""
        },
        userInfo: {
          [selectedPatientData.username]: {
            address_city: "",
            address_zip: "",
            cell: "",
            dea_number: "",
            degree: "",
            dob: "",
            drive_license_number: "",
            email: "",
            external_id: "",
            first_name: selectedPatientData.patientFirstName,
            gender: "",
            home_addr_1: "",
            home_addr_2: "",
            home_tel: "",
            last_name: selectedPatientData.patientFirstName,
            npi: "",
            office_addr_1: "",
            office_addr_2: "",
            office_tel: "",
            role: "patient",
            specialty: "",
            ssn: "",
            state: "",
            username: selectedPatientData.username,
            year_grad_med_school: "",
            internal_id: selectedPatientData.internal_id || ""
          },
          [loggedInUserData.userName]: {
            address_city: loggedInUserData.city,
            address_zip: loggedInUserData.zipCode,
            cell: loggedInUserData.cell,
            dea_number: "",
            degree: loggedInUserData.degree,
            dob: "",
            drive_license_number: "",
            email: loggedInUserData.email,
            external_id: "",
            first_name: loggedInUserData.firstName,
            gender: "",
            home_addr_1: "",
            home_addr_2: "",
            home_tel: "",
            last_name: loggedInUserData.lastName,
            npi: "",
            office_addr_1: "",
            office_addr_2: "",
            office_tel: "",
            role: loggedInUserData.role,
            specialty: loggedInUserData.specialty,
            ssn: "",
            state: "",
            username: loggedInUserData.userName,
            year_grad_med_school: "",
            internal_id: loggedInUserData.internal_id
          },
          [selectedCareTeamMemberData.username]: {
            address_city: "",
            address_zip: "",
            cell: "",
            dea_number: "",
            degree: selectedCareTeamMemberData.degree,
            dob: "",
            drive_license_number: "",
            email: "",
            external_id: "",
            first_name: selectedCareTeamMemberData.first_name,
            gender: "",
            home_addr_1: "",
            home_addr_2: "",
            home_tel: "",
            last_name: selectedCareTeamMemberData.last_name,
            npi: "",
            office_addr_1: "",
            office_addr_2: "",
            office_tel: "",
            role: selectedCareTeamMemberData.role,
            specialty: selectedCareTeamMemberData.specialty,
            ssn: "",
            state: "",
            username: selectedCareTeamMemberData.username,
            year_grad_med_school: "",
            internal_id: selectedCareTeamMemberData.provider_internal_id
          }
        }
      };
      setSelectedConversationUserInfo(selectedConversation);
    }
    return () => {
      setSelectedConversationUserInfo(null);
    };
  }, [channelData, userContext.userData && selectedCareteamMember]);

  useEffect(() => {
    if (selectedConversationUserInfo && !isLoading) {
      refetchChannelMessages();
    }
  }, [selectedConversationUserInfo]);

  const initialMessagesList: MessageData[] = useMemo(
    () =>
      convertOneToOneChimeMessagesToCustomMessageData(
        selectedConversationUserInfo,
        messagesData?.messages || [],
        userContext.userData?.userName || ""
      ),
    [messagesData]
  );

  const messagesListNextToken = useMemo(
    () => messagesData?.next_token || null,
    [messagesData]
  );

  const isLoading = useMemo(
    () =>
      isChannelFetching ||
      isChannelLoading ||
      isMessagesFetching ||
      isMessagesLoading,
    [isChannelFetching, isChannelLoading, isMessagesLoading, isMessagesFetching]
  );

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const unreadMessageNotifications = useMemo(() => {
    const unseenNotifications =
      notificationsContext.filterMessageNotificationsByStatus(
        notificationsContext.notifications?.messages || [],
        "unread"
      );
    return unseenNotifications;
  }, [notificationsContext.notifications?.messages]);

  const userId = sessionStorage.getItem("userId") || "";

  const { refetch: getNotificationsList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        "provider",
        "",
        ""
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      }
    }
  );

  const { mutate: markNotification } = useMutation(
    "mark-notification",
    createApiClient().putNotifications,
    {
      onSuccess: () => {
        getNotificationsList();
      }
    }
  );

  useEffect(() => {
    if (isLoading) return;
    if (selectedConversationUserInfo?.channel?.channel_name) {
      const unreadMessages = unreadMessageNotifications.filter(
        item =>
          selectedConversationUserInfo?.channel.channel_name ===
          item.channel_name
      );

      unreadMessages.forEach(item => {
        markNotification({
          notificationId: item?.notification_id || "",
          body: { status: "read", type: "messages" }
        });
      });
    }
  }, [unreadMessageNotifications, isLoading, selectedConversationUserInfo]);

  return isLoading ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "grid",
        placeItems: "center"
      }}
    >
      <Spin />
    </div>
  ) : channelError ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "grid",
        placeItems: "center"
      }}
    >
      <CommonErrorMessage message="Failed to fetch conversation" />
    </div>
  ) : typeof channelData === "string" ? (
    <AppCard cardHeight="100%" cardWidth="100%">
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          paddingTop: 10,
          paddingRight: 10,
          cursor: "pointer"
        }}
        onClick={onCloseMessageContainer}
      >
        <CloseIcon />
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "grid",
          placeItems: "center"
        }}
      >
        {channelData}
      </div>
    </AppCard>
  ) : (
    <MessageContainer
      messageItemWidth="80%"
      allMessagesFormat={false}
      picture={selectedCareteamMember?.user.picture || ""}
      sender={selectedCareteamMember?.user.name || ""}
      specialization={selectedCareteamMember?.user.specialty || "Caregiver"}
      subject={
        userIsPatient
          ? ""
          : `${selectedCareteamMember?.info.patientFirstName || ""} ${
              selectedCareteamMember?.info.patientLastName
            }`
      }
      messageData={initialMessagesList}
      style={{
        width: "calc(100% + 15px)",
        borderRadius: "8px 0px 0px 8px",
        height: "100%"
      }}
      nextToken={messagesListNextToken}
      selectedConversationUserInfo={selectedConversationUserInfo}
      userIsPatient={userIsPatient}
      userIsCaregiver={userIsCaregiver}
      noFooter={
        selectedConversationUserInfo?.channel.is_patient_enabled === 0 || false
      }
      onCloseMessageContainer={onCloseMessageContainer}
      customPlaceholderText={
        userIsPatient
          ? "Enter your message (this will be visible to all providers)"
          : ""
      }
    />
  );
};
