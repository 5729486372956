import moment from "moment";
import { lowerCaseRemoveSpace } from "helpers/utils";
import { ExtendedSymptomData } from "pages/symptoms/SymptomsModal";

export interface SeriesData {
  name: string;
  readonly data: [number | string, number][];
}

export const vitalSignsSeriesMapper = ({
  filteredExtendedSymptomData
}: {
  filteredExtendedSymptomData: ExtendedSymptomData[] | null;
  mapperType?: string | undefined;
  mapperValue?: string | number | undefined;
}) => {
  const am: SeriesData[] = [
    { name: "", data: [] },
    { name: "", data: [] }
  ];
  const pm: SeriesData[] = [
    { name: "", data: [] },
    { name: "", data: [] }
  ];

  const sortedData = filteredExtendedSymptomData?.sort((a, b) => {
    if (a.data && b.data) {
      const aBPTakenOn = a.data.filter(
        item => lowerCaseRemoveSpace(item.key) === "bptakenon"
      );
      const bBPTakenOn = b.data.filter(
        item => lowerCaseRemoveSpace(item.key) === "bptakenon"
      );
      if (aBPTakenOn[0] && bBPTakenOn[0]) {
        return (
          moment(aBPTakenOn[0].value).valueOf() -
          moment(bBPTakenOn[0].value).valueOf()
        );
      } else {
        return moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf();
      }
    }
    return moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf();
  });

  sortedData?.forEach(item => {
    const bpTakenOn = item.data?.find(
      item => lowerCaseRemoveSpace(item.key) === "bptakenon"
    );
    item.data?.map(item2 => {
      if (lowerCaseRemoveSpace(item2?.key) === "amsyst") {
        am[0].name = "AM Systolic";
        if (bpTakenOn) {
          am[0].data.push([
            String(moment(bpTakenOn.value).valueOf()),
            Number(item2.value)
          ]);
        }
      } else if (lowerCaseRemoveSpace(item2?.key) === "amdias") {
        am[1].name = "AM Diastolic";
        if (bpTakenOn) {
          am[1].data.push([
            String(moment(bpTakenOn.value).valueOf()),
            Number(item2.value)
          ]);
        }
      } else if (lowerCaseRemoveSpace(item2?.key) === "pmsyst") {
        pm[0].name = "PM Systolic";
        if (bpTakenOn) {
          pm[0].data.push([
            String(moment(bpTakenOn.value).valueOf()),
            Number(item2.value)
          ]);
        }
      } else if (lowerCaseRemoveSpace(item2?.key) === "pmdias") {
        pm[1].name = "PM Diastolic";
        if (bpTakenOn) {
          pm[1].data.push([
            String(moment(bpTakenOn.value).valueOf()),
            Number(item2.value)
          ]);
        }
      }
    });
  });

  return [am, pm];
};
