import ClientOAuth2, { Token } from "client-oauth2";
import qs from "qs";
import { v4 as uuid } from "uuid";

export default class hdOAuth {
  private scopes: string[];
  private redirectUri: string;
  private clientId: string;
  private clientSecret: string;
  private iss: string | undefined;
  private launch: string | undefined;
  private authorizeUrl: string | undefined;
  private tokenUrl: string | undefined;
  private state: string | undefined;
  private oauth: ClientOAuth2 | undefined;

  constructor() {
    this.scopes = [
      "launch",
      "patient/*.read",
      "openid profile",
      "online_access"
    ];
    const fullUrl = window.location.href;
    const fhir_redirect_uri = new URL(fullUrl).origin + "/fhir/epic/redirect";
    this.redirectUri = fhir_redirect_uri;
    this.clientId = process.env["REACT_APP_FHIR_CLIENT_ID"] || "";
    this.clientSecret = process.env["REACT_APP_FHIR_CLIENT_SECRET"] || "";
  }

  setProps(iss: any, launch: any, authorizeUrl: any, tokenUrl: any) {
    this.iss = iss;
    this.launch = launch;
    this.authorizeUrl = authorizeUrl;
    this.tokenUrl = tokenUrl;
    this.state = uuid();

    this.oauth = new ClientOAuth2({
      clientId: this.clientId,
      clientSecret: this.clientSecret,
      authorizationUri: authorizeUrl,
      accessTokenUri: tokenUrl,
      redirectUri: this.redirectUri,
      state: this.state,
      scopes: this.scopes
    });

    const stateToSave = JSON.stringify({ iss, authorizeUrl, tokenUrl, launch });
    sessionStorage.setItem(this.state, stateToSave);
  }

  getTokenRedirectUri(): string {
    if (!this.oauth) {
      throw new Error("OAuth configuration is missing.");
    }

    return this.oauth.code.getUri({
      query: { aud: this.iss || "", launch: this.launch || "" }
    });
  }

  async getAccessToken(uri: string): Promise<Token | null> {
    const querystring: any = qs.parse(uri);
    let params: any = sessionStorage.getItem(querystring.state || "");

    if (params === null) {
      return null;
    }

    params = JSON.parse(params);
    window.sessionStorage.removeItem(querystring.state || "");

    if (!params) {
      throw new Error("OAuth parameters are missing.");
    }

    this.iss = params.iss;
    this.authorizeUrl = params.authorizeUrl;
    this.tokenUrl = params.tokenUrl;

    this.oauth = new ClientOAuth2({
      clientId: this.clientId,
      clientSecret: this.clientSecret,
      authorizationUri: this.authorizeUrl,
      accessTokenUri: this.tokenUrl,
      redirectUri: this.redirectUri,
      scopes: this.scopes
    });

    return this.oauth.code.getToken(uri);
  }
}
