import React, { useContext, useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { MessagesSection } from "./messageSection";
import { CareTeamTable } from "./careTeamTable";
import { CareTeamHeader } from "./careTeamHeader";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { resetCareTeamDropdownValues } from "./resetCareTeamDropdownValues";
import { CareTeamProviderProfile } from "./careTeamProviderProfile";
import { useQuery } from "react-query";
import { CareTeamList, PatientProfile, UserNetworkList } from "sharedTypes";
import { createApiClient } from "apiClient";
import { UserRoles } from "constants/roles";
import { useParams } from "react-router";
import { useDebounce } from "helpers/customHooks";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";

export const CareTeamPage: React.FC = () => {
  const [showMessages, setShowMessages] = useState(false);
  const [filterMenuItems, setFilterMenuItems] = useState(
    JSON.parse(sessionStorage.getItem("careTeamCheckboxItems") || "[]")
  );
  const [applied, setApplied] = useState(false);
  const [providerType, setProviderType] = useState<string[]>([""]);
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const [selectedCareteamMember, setSelectedCareteamMember] =
    useState<CareTeamList | null>(null);

  const { id } = useParams();

  const [searchNameValue, setSearchNameValue] = useState("");
  const [searchSpecialtyValue, setSearchSpecialtyValue] = useState("");
  const [showProviderProfile, setShowProviderProfile] = useState(false);
  const [providerProfileData, setProviderProfileData] = useState<{
    first_name: string;
    last_name: string;
    role: string;
    specialty: string;
    group: string;
    organisations: { id: string; name: string }[];
    office_address: string;
    phoneNumbers: { title: string; number: string }[];
    degree: string;
  }>();

  const debouncedNameValue = useDebounce(searchNameValue);
  const debouncedSpecialtyValue = useDebounce(searchSpecialtyValue);

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const userId = sessionStorage.getItem("userId") || "";

  const {
    isLoading: isLoadingCareTeamList,
    data: careTeamData,
    error: isCareTeamListError,
    refetch: refetchCareTeamList,
    isFetching: isCareTeamListRefetchLoading,
    status: careTeamErrorStatus
  } = useQuery<PatientProfile>(
    [
      "care-team-list",
      debouncedNameValue,
      debouncedSpecialtyValue,
      providerType,
      notificationsContext.notifications?.care_team
    ],
    async () => {
      return await createApiClient().getCareTeamList(
        userIsPatient ? userId : id,
        searchNameValue,
        searchSpecialtyValue,
        providerType.join(","),
        true
      );
    }
  );

  const {
    isLoading: isLoadingPatientNetworkList,
    data: patientNetworkList,
    error: isPatientNetworkError,
    refetch: refetchPatientNetworkList,
    isFetching: isRefetchingPatientNetworkList
  } = useQuery<UserNetworkList[]>(
    "user-network-list",
    async () => {
      return await createApiClient().getUserNetworkList(
        careTeamData ? careTeamData?.dbId : "",
        "patient",
        true
      );
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (careTeamData && !userIsPatient) {
      refetchPatientNetworkList();
    }
  }, [careTeamData]);

  const onCloseMessageContainer = () => {
    setShowMessages(false);
    setSelectedCareteamMember(null);
  };

  useEffect(() => {
    resetCareTeamDropdownValues();
    setFilterMenuItems(
      JSON.parse(sessionStorage.getItem("careTeamCheckboxItems") || "[]")
    );
  }, []);

  useEffect(() => {
    setShowMessages(showMessages);
  }, [filterMenuItems]);

  const showTopbarExtesion = !userIsPatient;

  return (
    <>
      {showTopbarExtesion && <CommonPatientExtensionItems />}
      {showProviderProfile ? (
        <CareTeamProviderProfile
          setShowProviderProfile={setShowProviderProfile}
          providerProfileData={
            providerProfileData
              ? providerProfileData
              : {
                  first_name: "",
                  last_name: "",
                  role: "",
                  specialty: "",
                  group: "",
                  organisations: [{ id: "", name: "" }],
                  office_address: "",
                  phoneNumbers: [{ title: "", number: "" }],
                  degree: ""
                }
          }
        />
      ) : (
        <>
          <CareTeamHeader
            setMenuItems={setFilterMenuItems}
            menuItems={filterMenuItems}
            setSearchNameValue={setSearchNameValue}
            setSearchSpecialtyValue={setSearchSpecialtyValue}
            searchNameValue={searchNameValue}
            searchSpecialtyValue={searchSpecialtyValue}
            setApplied={setApplied}
            applied={applied}
            existingProviders={patientNetworkList ? patientNetworkList : []}
            dbId={careTeamData ? careTeamData.dbId : 0}
            refetchCareTeamList={refetchCareTeamList}
          />
          <Row gutter={[20, 0]}>
            <Col span={showMessages ? 16 : 24} style={{ height: "20vh" }}>
              <CareTeamTable
                toggleMessages={setShowMessages}
                showMessages={showMessages}
                searchNameValue={searchNameValue}
                searchSpecialtyValue={searchSpecialtyValue}
                filterMenuItems={filterMenuItems}
                setSelectedCareteamMember={setSelectedCareteamMember}
                selectedCareteamMember={selectedCareteamMember}
                providerTypeApplied={applied}
                setShowProviderProfile={setShowProviderProfile}
                setProviderProfileData={setProviderProfileData}
                isCareTeamListRefetchLoading={
                  isCareTeamListRefetchLoading || isRefetchingPatientNetworkList
                }
                isLoadingCareTeamList={
                  isLoadingCareTeamList || isLoadingPatientNetworkList
                }
                careTeamData={careTeamData ? careTeamData.connectedUsers : []}
                setProviderType={setProviderType}
                isCareTeamListError={
                  isCareTeamListError || isPatientNetworkError
                }
                status={careTeamErrorStatus}
              />
            </Col>
            <Col span={showMessages ? 8 : 0} style={{ height: "70vh" }}>
              <MessagesSection
                selectedCareteamMember={selectedCareteamMember}
                onCloseMessageContainer={onCloseMessageContainer}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
