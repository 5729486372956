import React, { useContext, useEffect, useRef, useState } from "react";
import Form from "antd/lib/form";
import message from "antd/lib/message";
import Spin from "antd/lib/spin";
import Tooltip from "antd/lib/tooltip";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import styled from "styled-components";
import moment, { Moment } from "moment";
import {
  CloseOutlined,
  DeleteOutlined,
  InfoCircleFilled,
  WarningOutlined
} from "@ant-design/icons";

import { Colors } from "helpers/colors";
import { AppDatePicker, AppSelect } from "components/inputs";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { BillDiagnosisCheckboxDropdown } from "./BillDiagnosisDropdown";
import { BillChargesCheckboxDropdown } from "./BillChargesDropdown";
import { AllBillsList, CheckboxMenuCommonInterface } from "sharedTypes";
import { useMutation, useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { UserContext, UserContextState } from "contextApis/userContext";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { get } from "lodash";
import { AppModal } from "components/modal";
import { BillingContext, BillingContextState } from "./billingContext";

interface EditBillProps {
  readonly selectedDraftData: AllBillsList;
  readonly showAddBill: boolean;
  readonly selectedDraftID: string | number;
  getBillingList(): void;
  setShowEditBill(show: boolean): void;
  readonly enableBilling: boolean;
}

export const EditBill: React.FC<EditBillProps> = ({
  selectedDraftData,
  showAddBill,
  selectedDraftID,
  getBillingList,
  setShowEditBill,
  enableBilling
}) => {
  const [form] = Form.useForm();
  const [showDiagnosesDropdown, setShowDiagnosesDropdown] = useState(false);
  const [showChargesDropdown, setShowChargesDropdown] = useState(false);
  const [diagnosesApplied, setDiagnosesApplied] = useState(false);
  const [chargesApplied, setChargesApplied] = useState(false);
  const [showCallDuration, setShowCallDuration] = useState(false);
  const [newMonthlyCallTime, setNewMonthlyCallTime] = useState<string | null>(
    ""
  );
  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);
  const billingContext = useContext<BillingContextState>(BillingContext);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const isDraftClicked = useRef(false);

  const { isLoading: isLoadingEditBillMutation, mutate: editBillMutation } =
    useMutation<string>(
      "add-bill",
      async () => {
        // Get the current value of "Date of Service"
        const dateOfService = form.getFieldValue("Date of Service");

        // Check if the value exists and format it accordingly
        const formattedDateOfService = dateOfService
          ? moment(dateOfService).toISOString()
          : moment(dateOfService).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

        return await createApiClient().editBill(selectedDraftData.id, {
          status: "Draft",
          date_of_service: formattedDateOfService,
          billing_diagnose_code: JSON.stringify(selectedDiagnoses),
          provider_name:
            get(userContext, "userData.firstName", "") +
            " " +
            get(userContext, "userData.lastName", ""),
          billing_charge_code: JSON.stringify(selectedCharges),
          patient_internal_id: patientContext.patientData?.id
            ? String(patientContext.patientData?.id)
            : "",
          provider_internal_id: get(userContext, "userData.internal_id", ""),
          patient_location: form.getFieldValue("Patient Location"),
          total_duration_billed: newMonthlyCallTime
            ? newMonthlyCallTime
            : selectedDraftData.currentMonthContactTime,
          provider_location: form.getFieldValue("Provider Location")
        });
      },
      {
        onSuccess: () => {
          getBillingList();
          message.success("Draft Updated Successfully!");
          setShowCallDuration(false);
          setShowEditBill(false);
        },
        onError: () => {
          message.error("There was a problem editing this draft");
        }
      }
    );

  const { isLoading: isLoadingDeleteBillMutation, mutate: deleteBillMutation } =
    useMutation<string>(
      "add-bill",
      async () => {
        return await createApiClient().deleteBill(selectedDraftData.id);
      },
      {
        onSuccess: () => {
          getBillingList();
          message.success("Draft Deleted Successfully!");
          setShowEditBill(false);
        },
        onError: () => {
          message.error("There was a problem deleting this draft");
        }
      }
    );

  const {
    isLoading: isLoadingChargesList,
    data: chargesData,
    error: isChargesListError
  } = useQuery(
    "charges-list",
    async () => {
      return await createApiClient().getChargesList(true);
    },
    {
      staleTime: 600000
    }
  );

  const {
    isLoading: isLoadingDiagnosesList,
    data: diagnosesData,
    error: isDiagnosesListError
  } = useQuery(
    "diagnoses-list",
    async () => {
      return await createApiClient().getDiagnosesList(
        true,
        patientContext.orgId
      );
    },
    {
      staleTime: 600000
    }
  );

  const {
    refetch: getTotalMonthlyCallTimeData,
    isRefetching: isRefetchLoadingTotalMonthlyCallTimeData,
    isLoading: isLoadingTotalMonthlyCallTimeData
  } = useQuery(
    "total-monthly-call-time",
    async () => {
      return await createApiClient().getTotalMonthlyCallTime(
        patientContext.patientData?.id
          ? String(patientContext.patientData?.id)
          : "",
        userContext.userData?.internal_id
          ? userContext.userData?.internal_id
          : "",
        moment(form.getFieldValue("Date of Service")).format("YYYY"),
        moment(form.getFieldValue("Date of Service")).format("MM"),
        true
      );
    },
    {
      onSuccess: value => {
        setShowCallDuration(true);
        setNewMonthlyCallTime(value.currentMonthTotal);
      },
      enabled: false,
      cacheTime: 0,
      staleTime: 0
    }
  );

  const [chargesNotSelected, setChargesNotSelected] = useState("");
  const [diagnosesNotSelected, setDiagnosesNotSelected] = useState("");

  const renderCharges = !isLoadingChargesList && !isChargesListError;
  const renderDiagnoses = !isLoadingDiagnosesList && !isDiagnosesListError;

  const convertUTCDateToLocal = (dateString: any) => {
    const localDateTime = moment.utc(dateString).local();
    const formattedDateTime = localDateTime.format("MM/DD//YYYY hh:mm:ss");
    return formattedDateTime;
  };

  useEffect(() => {
    //On switch to another item's date
    form.setFieldsValue({
      "Date of Service": moment(
        convertUTCDateToLocal(selectedDraftData?.dateOfService)
      )
    });
    form.setFieldsValue({
      "Provider Location": selectedDraftData?.providerLocation
    });
    form.setFieldsValue({
      "Patient Location": selectedDraftData?.patientLocation
    });
    // form.setFieldsValue({
    //   "Notes to Biller": selectedDraftData.notesToBiller
    // });
  }, [form, selectedDraftData]);

  useEffect(() => {
    let latestApprovedDiagnosesItems: any;
    if (selectedDraftData?.diagnoses.length > 0) {
      latestApprovedDiagnosesItems = selectedDraftData?.diagnoses?.map(
        item => item.code
      );
    }
    const latestApprovedChargesItems = selectedDraftData?.charges?.map(
      item => item.code
    );

    if (renderDiagnoses) {
      const diagnosesList = diagnosesData?.map(
        (item: CheckboxMenuCommonInterface) => {
          if (latestApprovedDiagnosesItems?.includes(item.code)) {
            item.checked = true;
          } else {
            item.checked = false;
          }
          return item;
        }
      );
      if (diagnosesList) {
        billingContext.setDiagnosesMenuItems(diagnosesList);
      }
    }

    if (renderCharges) {
      const chargesList = chargesData?.map(
        (item: CheckboxMenuCommonInterface) => {
          if (latestApprovedChargesItems?.includes(item.code)) {
            item.checked = true;
          } else {
            item.checked = false;
          }
          return item;
        }
      );

      if (chargesList) {
        billingContext.setChargesMenuItems(chargesList);
      }
    }
    getTotalMonthlyCallTimeData();
  }, [showAddBill, selectedDraftID]);

  const selectedDiagnoses = billingContext.diagnosesMenuItems.filter(
    (obj: CheckboxMenuCommonInterface) => obj.checked
  );

  const selectedCharges = billingContext.chargesMenuItems.filter(
    (obj: CheckboxMenuCommonInterface) => obj.checked
  );

  useEffect(() => {
    if (selectedCharges.length > 0) {
      setChargesNotSelected("");
    }
    if (selectedDiagnoses.length > 0) {
      setDiagnosesNotSelected("");
    }
  }, [selectedCharges, selectedDiagnoses]);

  const onDateChange = (value: Moment | null) => {
    form.setFieldsValue({ dateOfService: value });

    if (
      form.isFieldTouched("Date of Service") &&
      form.getFieldValue("Date of Service")
    ) {
      getTotalMonthlyCallTimeData();
    }
  };

  const onPatientLocationChange = (value: string) => {
    form.setFieldsValue({ patientLocation: value });
  };

  const onProviderLocationChange = (value: string) => {
    form.setFieldsValue({ prodiverLocation: value });
  };

  // const onTextAreaChange = (value: string) => {
  //   form.setFieldsValue({ notesToBiller: value });
  // };

  const onRepopulate = () => {
    form.resetFields();
    form.setFieldsValue({
      "Date of Service": moment()
    });
    getTotalMonthlyCallTimeData();

    const initialDiagnosesMenuItems = billingContext.diagnosesMenuItems.map(
      (obj: CheckboxMenuCommonInterface) => {
        let found = false;
        for (let i = 0; i < selectedDraftData.diagnoses.length; i++) {
          if (get(selectedDraftData, `diagnoses[${i}].code`, "") === obj.code) {
            found = true;
            break;
          }
        }
        if (found) {
          obj.checked = true;
        } else {
          obj.checked = false;
        }
        return obj;
      }
    );

    const initialChargesMenuItems = billingContext.chargesMenuItems.map(
      (obj: CheckboxMenuCommonInterface) => {
        let found = false;
        for (let i = 0; i < selectedDraftData.charges.length; i++) {
          if (get(selectedDraftData, `charges[${i}].code`, "") === obj.code) {
            found = true;
            break;
          }
        }
        if (found) {
          obj.checked = true;
        } else {
          obj.checked = false;
        }
        return obj;
      }
    );

    billingContext.setDiagnosesMenuItems(initialDiagnosesMenuItems);
    billingContext.setChargesMenuItems(initialChargesMenuItems);
  };

  const onFinish = () => {
    editBillMutation();
  };

  const locationOptions: { label: string; value: string }[] = [
    {
      label: "Home",
      value: "Home"
    },
    {
      label: "Office",
      value: "Office"
    }
  ];

  const minutesValue = !newMonthlyCallTime
    ? selectedDraftData &&
      Number(selectedDraftData?.currentMonthContactTime) / 60
    : Number(newMonthlyCallTime) / 60;

  useEffect(() => {
    return () => {
      setShowEditBill(false);
      sessionStorage.setItem("caregem_bill_reference", "");
      if (isDraftClicked.current === true) return;
      const confirmation = window.confirm(
        "Do you want to save this bill as a draft?"
      );

      if (confirmation) {
        editBillMutation();
      }
    };
  }, []);

  return (
    <ViewBillContainer>
      <ViewBillHeader>
        <Row>
          <Col span={12}>Edit Bill</Col>
          <Col span={12} style={{ textAlign: "right", paddingRight: "20px" }}>
            <CloseOutlined
              onClick={() => {
                isDraftClicked.current = true;
                setShowEditBill(false);
              }}
            />
          </Col>
        </Row>
      </ViewBillHeader>
      <Form
        name="call records"
        form={form}
        onFinish={() => {
          const diagnosesNotSelectedCheck = selectedDiagnoses.length === 0;
          const chargesNotSelectedCheck = selectedCharges.length === 0;

          if (!chargesNotSelectedCheck && !diagnosesNotSelectedCheck) {
            onFinish();
          }
        }}
      >
        <>
          <ViewBillBody>
            <Row gutter={[20, 0]}>
              <Col span={12}>
                <Form.Item
                  name="Date of Service"
                  label=""
                  rules={[{ required: true }]}
                  initialValue={moment
                    .utc(selectedDraftData?.dateOfService)
                    .local()}
                >
                  <AppDatePicker
                    label="Date of Service"
                    value={null}
                    customDateFormat="MMM DD, YYYY"
                    onChange={onDateChange}
                    disabledDate={function disabledDate(current) {
                      return (
                        (current && current < moment().subtract(6, "months")) ||
                        current > moment().endOf("day")
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                {isLoadingTotalMonthlyCallTimeData ||
                isRefetchLoadingTotalMonthlyCallTimeData ? (
                  <Spin style={{ marginTop: "10px" }} />
                ) : (
                  <>
                    {!newMonthlyCallTime ? (
                      <>
                        <div style={{ fontSize: "12px" }}>
                          <Tooltip title="As when draft was generated">
                            <InfoCircleFilled
                              style={{ color: Colors.DodgerBlue }}
                            />
                          </Tooltip>{" "}
                          Call Duration(Monthly){" "}
                        </div>
                        <div style={{ fontSize: "12px" }}>
                          <span style={{ fontFamily: "Century Gothic Bold" }}>
                            {minutesValue % 1 !== 0
                              ? minutesValue >= 0.1
                                ? minutesValue?.toFixed(1)
                                : minutesValue?.toFixed(2)
                              : minutesValue}{" "}
                          </span>{" "}
                          minutes
                        </div>
                      </>
                    ) : (
                      <>
                        {showCallDuration && (
                          <>
                            <div style={{ fontSize: "12px" }}>
                              Call Duration(Monthly)
                            </div>
                            <div style={{ fontSize: "12px" }}>
                              <span
                                style={{ fontFamily: "Century Gothic Bold" }}
                              >
                                {minutesValue % 1 !== 0
                                  ? minutesValue >= 0.1
                                    ? minutesValue?.toFixed(1)
                                    : minutesValue?.toFixed(2)
                                  : minutesValue}{" "}
                              </span>{" "}
                              minutes
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Col>
              <Col span={12}>
                <Form.Item
                  name="Patient Location"
                  label=""
                  rules={[{ required: true }]}
                  initialValue={selectedDraftData?.patientLocation}
                >
                  <AppSelect
                    label="Patient Location"
                    value={""}
                    placeholder="Select Location"
                    onChange={onPatientLocationChange}
                    options={locationOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="Provider Location"
                  label=""
                  rules={[{ required: true }]}
                  initialValue={selectedDraftData?.providerLocation}
                >
                  <AppSelect
                    label="Provider Location"
                    value={""}
                    placeholder="Select Location"
                    onChange={onProviderLocationChange}
                    options={locationOptions}
                  />
                </Form.Item>
              </Col>
              {renderDiagnoses && (
                <Col span={24}>
                  <Row>
                    <ListTitleCol span={12}>Diagnoses</ListTitleCol>
                    <AddCol span={12}>
                      <BillDiagnosisCheckboxDropdown
                        setShowDropdown={setShowDiagnosesDropdown}
                        showDropdown={showDiagnosesDropdown}
                        diagnosesApplied={diagnosesApplied}
                        setDiagnosesApplied={setDiagnosesApplied}
                      />
                    </AddCol>
                  </Row>
                  <ListItemWrapper>
                    {selectedDiagnoses.map(
                      (obj: CheckboxMenuCommonInterface) => {
                        return (
                          <ListItemRowStyle key={obj.code}>
                            <Col span={12}>{obj.desc}</Col>
                            <Col
                              span={12}
                              style={{ textAlign: "right" }}
                              onClick={() => {
                                let tempItems = [
                                  ...billingContext.diagnosesMenuItems
                                ];
                                tempItems = tempItems.map(temp => {
                                  if (obj.code === temp.code) {
                                    temp.checked = false;
                                  }
                                  return temp;
                                });
                                billingContext.setDiagnosesMenuItems(tempItems);
                              }}
                            >
                              <CloseOutlined />
                            </Col>
                          </ListItemRowStyle>
                        );
                      }
                    )}
                  </ListItemWrapper>
                  <CommonErrorMessage message={diagnosesNotSelected} />
                </Col>
              )}
              {renderCharges && (
                <Col span={24}>
                  <Row>
                    <ListTitleCol span={12}>Charges</ListTitleCol>
                    <AddCol span={12}>
                      <BillChargesCheckboxDropdown
                        setShowDropdown={setShowChargesDropdown}
                        showDropdown={showChargesDropdown}
                        applied={chargesApplied}
                        setApplied={setChargesApplied}
                      />
                    </AddCol>
                  </Row>
                  <ListItemWrapper>
                    {selectedCharges.map((obj: CheckboxMenuCommonInterface) => {
                      return (
                        <ListItemRowStyle key={obj.code}>
                          <Col span={24}>{obj.code}</Col>
                          <Col span={12}>{obj.desc}</Col>
                          <Col
                            span={12}
                            style={{ textAlign: "right" }}
                            onClick={() => {
                              let tempItems = [
                                ...billingContext.chargesMenuItems
                              ];
                              tempItems = tempItems.map(temp => {
                                if (obj.code === temp.code) {
                                  temp.checked = false;
                                }
                                return temp;
                              });
                              billingContext.setChargesMenuItems(tempItems);
                            }}
                          >
                            <CloseOutlined />
                          </Col>
                        </ListItemRowStyle>
                      );
                    })}
                  </ListItemWrapper>
                  <CommonErrorMessage message={chargesNotSelected} />
                </Col>
              )}
            </Row>
            {/* <NotesToBillerWrapper>
              <Form.Item
                name="Notes to Biller"
                initialValue={selectedDraftData.notesToBiller}
                rules={[{ required: true }]
              >
                <AppTextArea
                  value={"Test"}
                  label="Notes to biller"
                  placeholder="Enter Text"
                  onChange={onTextAreaChange}
                />
              </Form.Item>
            </NotesToBillerWrapper> */}
          </ViewBillBody>
          <ViewBillFooter>
            <AppButton
              type={ButtonType.Skeleton}
              buttonContent={<DeleteOutlined />}
              onClick={() => {
                isDraftClicked.current = true;
                setShowDeleteConfirmation(true);
              }}
              loading={isLoadingDeleteBillMutation}
              disabled={isLoadingEditBillMutation}
              style={{
                float: "left",
                borderColor: Colors.Black,
                color: Colors.Black,
                fontSize: "18px"
              }}
            />
            <Space size={10}>
              <Form.Item>
                <AppButton
                  type={ButtonType.Secondary}
                  buttonContent="Repopulate"
                  onClick={() => {
                    isDraftClicked.current = true;
                    onRepopulate;
                  }}
                  disabled={isLoadingDeleteBillMutation}
                />
              </Form.Item>
              <Form.Item>
                <AppButton
                  type={ButtonType.Primary}
                  buttonContent="Save as Draft"
                  loading={isLoadingEditBillMutation}
                  disabled={isLoadingDeleteBillMutation || !enableBilling}
                  htmlType="submit"
                  onClick={() => {
                    isDraftClicked.current = true;
                    const diagnosesNotSelectedCheck =
                      selectedDiagnoses.length === 0;
                    const chargesNotSelectedCheck =
                      selectedCharges.length === 0;

                    if (chargesNotSelectedCheck) {
                      setChargesNotSelected("Please select Charge(s)");
                    }
                    if (diagnosesNotSelectedCheck) {
                      setDiagnosesNotSelected("Please Select Diagnosis(es)");
                    }
                  }}
                />
              </Form.Item>
            </Space>
          </ViewBillFooter>
        </>
      </Form>
      <AppModal
        visible={showDeleteConfirmation}
        title="Confirm Deletion"
        cancelText="No"
        okText="Yes"
        onCancel={() => setShowDeleteConfirmation(false)}
        onOk={() => {
          deleteBillMutation();
          setShowDeleteConfirmation(false);
        }}
        prompt={{
          icon: (
            <WarningOutlined style={{ color: "#F5A623", fontSize: "40px" }} />
          ),
          text: (
            <>
              <div>Are you sure you want to delete this draft?</div>
            </>
          )
        }}
        footer
      />
    </ViewBillContainer>
  );
};

// const NotesToBillerWrapper = styled.div`
//   margin-top: 10px;
// `;

const ListItemWrapper = styled.div`
  .ant-row {
    border-bottom: 1px solid ${Colors.Lavender};

    :last-child {
      border-bottom: none;
    }
  }
`;

const ListItemRowStyle = styled(Row)`
  font-size: 12px;
  padding: 8px;
`;

const AddCol = styled(Col)`
  text-align: right;
  color: ${Colors.DodgerBlue};
  font-family: Century Gothic Bold;
  svg {
    margin-bottom: -7px;
    margin-right: 5px;
  }
`;

const ListTitleCol = styled(Col)`
  font-family: Century Gothic Bold;
  font-size: 12px;
`;

const ViewBillHeader = styled.div`
  height: 60px;
  padding-left: 25px;
  padding-top: 18px;
  font-family: Century Gothic Bold;
  font-size: 14px;
  border-bottom: 1px solid ${Colors.Lavender};
`;

const ViewBillBody = styled.div`
  height: 50px;
  padding: 20px;
  padding-top: 25px;
  border-bottom: 1px solid ${Colors.Lavender};
  height: calc(70vh - 130px);
  overflow: scroll;

  .notes-to-biller-title {
    margin-top: -10px;
  }
`;

const ViewBillFooter = styled.div`
  height: 70px;
  padding: 14px;
  text-align: right;
`;

const ViewBillContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 8px 8px;
  margin-top: -2px;
  background-color: ${Colors.White};
  max-height: 70vh;
  font-size: 12px;
`;
