import React from "react";
import styled from "styled-components";
import moment from "moment";

interface DateTimeFormatProps {
  readonly date: any;
  readonly hideTime?: boolean;
  readonly isModalFormat?: boolean;
}

export const DateTimeFormat: React.FC<DateTimeFormatProps> = ({
  date,
  hideTime,
  isModalFormat
}) => {
  return (
    <DateTimeFormatStyled>
      {date ? (
        <>
          {date ? (
            <>
              {moment(date).format(
                isModalFormat
                  ? "MMM, DD YYYY hh:mm A"
                  : hideTime
                  ? "MMM, DD YYYY"
                  : "MMM, DD YYYY | hh:mm A"
              )}
            </>
          ) : null}
        </>
      ) : null}
    </DateTimeFormatStyled>
  );
};

const DateTimeFormatStyled = styled.span`
  letter-spacing: 0px;
`;
