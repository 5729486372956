import React, { useContext, useEffect, useMemo, useState } from "react";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import orderBy from "lodash/orderBy";
import moment from "moment";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { UserRoles } from "constants/roles";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { AddSymptomsIcon } from "svgIcons/addSymptomsIcon";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { AddSymptomsForm } from "./AddSymptomsForm";
import {
  AddSymptomButtonContaner,
  AddSymptomButtonText,
  EmptyDataText,
  ErrorMessage,
  OverflowContainer,
  SymptomsTitleText,
  TitleContainer
} from "./styles";
import { SymptomsList, SymptomsListData } from "./SymptomsList";
import { ExtendedSymptomData, SymptomsModal } from "./SymptomsModal";
import { SymptomsSkeletonLoader } from "./SymptomsSkeletonLoader";
import { getSymptomListData } from "./utils";
import { dateToLocal } from "helpers/utils";
import { ModifySymptomsListResponse } from "sharedTypes";

const ONE_MONTH_BEFORE_CURRENT_DATE = moment()
  .subtract(1, "M")
  .startOf("D")
  .valueOf();

export const SymptomsPage: React.FC = () => {
  const navigate = useNavigate();
  const patientContext = useContext<PatientContextState>(PatientContext);

  const [symptomsData, setSymptomsData] = useState<SymptomsListData[]>([]);
  const [selectedSymptom, setSelectedSymptom] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAddSymptomFormOpen, setIsAddSymptomFormOpen] =
    useState<boolean>(false);
  const [timeRange, setTimeRange] = useState<string>("12");
  const { id: patientId } = useParams();

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const showTopbarExtesion = !userIsPatient || userIsCaregiver;

  const userId = sessionStorage.getItem("userId") || "";

  const { data, isLoading, isFetching, error, refetch } = useQuery(
    "patient-symptoms",
    async () => {
      return await createApiClient().getPatientSymptoms(
        userIsPatient ? userId : patientId || ""
      );
    },
    { enabled: userIsPatient || patientId ? true : false }
  );

  const onModalOpen = (selectedSymptom: string) => {
    setIsModalOpen(true);
    setSelectedSymptom(selectedSymptom);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setSelectedSymptom("");
    setTimeRange("12");
  };

  const onTimeRangeChange = (value: string) => setTimeRange(value);

  const onAddNewSymptomFormOpen = () => setIsAddSymptomFormOpen(true);
  const onAddNewSymptomFormClose = () => {
    setIsAddSymptomFormOpen(false);
    refetch();
  };

  const onBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (data) {
      data.forEach((item: ModifySymptomsListResponse) => {
        item.enter_date = dateToLocal(item.enter_date).format("MM/DD/YYYY");
      });
      const patientSymptomsData = isArray(data) ? getSymptomListData(data) : [];

      setSymptomsData(
        orderBy(
          patientSymptomsData,
          ["millisTimestamp", "reporterName"],
          "desc"
        )
      );
    }
  }, [data]);

  const getExtendedSymptomsData = (symptomsData: SymptomsListData[]) =>
    symptomsData.reduce((acc: ExtendedSymptomData[], symptomItem) => {
      const symptomExtendedList: ExtendedSymptomData[] =
        symptomItem.symptoms.map(symptom => ({
          id: symptom.id,
          name: symptom.name.trim(),
          data: symptom.data,
          description: symptom.description,
          timestamp: symptomItem.timestamp,
          reporterName: symptomItem.reporterName,
          reporterRole: symptomItem.reporterRole
        }));
      return acc.concat(symptomExtendedList);
    }, []);

  const extendedSymptomData = useMemo(() => {
    if (timeRange === "all") {
      return getExtendedSymptomsData(symptomsData);
    }
    return getExtendedSymptomsData(
      symptomsData.filter(
        symptomListItem =>
          symptomListItem.millisTimestamp >
          moment().subtract(Number(timeRange), "M").startOf("D").valueOf()
      )
    );
  }, [symptomsData, timeRange]);

  const lastMonthSymptoms = useMemo(
    () =>
      uniq(
        symptomsData
          .filter(
            symptomListItem =>
              symptomListItem.millisTimestamp > ONE_MONTH_BEFORE_CURRENT_DATE
          )
          .reduce((acc: string[], symptomListItem) => {
            const symptomsNames = symptomListItem.symptoms.map(symptom =>
              symptom.name.trim()
            );
            return acc.concat(symptomsNames);
          }, [])
      ),
    [symptomsData]
  );

  useEffect(() => {
    patientContext.setPatientLastMonthSymptoms(lastMonthSymptoms);
  }, [lastMonthSymptoms]);

  return (
    <>
      {showTopbarExtesion && <CommonPatientExtensionItems />}
      {isAddSymptomFormOpen ? (
        <AddSymptomsForm
          onAddNewSymptomFormClose={onAddNewSymptomFormClose}
          patientId={userIsPatient ? Number(userId) : Number(patientId)}
        />
      ) : (
        <>
          <TitleContainer>
            <div style={{ display: "flex", alignItems: "center" }}>
              {isEmpty(symptomsData) && !(isLoading || isFetching) && (
                <AppButton
                  type={ButtonType.Secondary}
                  onClick={onBackClick}
                  buttonContent="Back"
                  style={{ marginRight: "20px" }}
                />
              )}
              <SymptomsTitleText
                padding={
                  isEmpty(symptomsData) && !(isLoading || isFetching)
                    ? "0px"
                    : "18px"
                }
              >
                Symptoms reported
              </SymptomsTitleText>
            </div>
            {!userIsPatient && (
              <AppButton
                buttonContent={
                  <AddSymptomButtonContaner>
                    <AddSymptomsIcon />

                    <AddSymptomButtonText>
                      Add New Symptoms
                    </AddSymptomButtonText>
                  </AddSymptomButtonContaner>
                }
                onClick={onAddNewSymptomFormOpen}
                type={ButtonType.Skeleton}
              />
            )}
          </TitleContainer>
          <OverflowContainer>
            {isLoading || isFetching ? (
              <SymptomsSkeletonLoader isLoading={isLoading || isFetching} />
            ) : error ? (
              <ErrorMessage>
                There was a error in fetching patient symptoms
              </ErrorMessage>
            ) : symptomsData.length === 0 ? (
              <EmptyDataText>No Data</EmptyDataText>
            ) : (
              <SymptomsList data={symptomsData} onModalOpen={onModalOpen} />
            )}
          </OverflowContainer>
        </>
      )}
      {selectedSymptom && (
        <SymptomsModal
          isModalOpen={isModalOpen}
          onModalClose={onModalClose}
          selectedSymptom={selectedSymptom}
          extendedSymptomData={extendedSymptomData}
          timeRange={timeRange}
          onTimeRangeChange={onTimeRangeChange}
        />
      )}
    </>
  );
};
