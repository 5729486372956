import React, { useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import styled from "styled-components";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { Colors } from "helpers/colors";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { PatientDetail } from "./patientDetail";
import { TasksTable } from "./tasksTable";

export const TasksPage: React.FC = () => {
  const [socialParams, setSocialParams] = useState<boolean>(false);

  return (
    <>
      <CommonPatientExtensionItems />
      <Row gutter={[0, 0]}>
        <Col style={{ display: "grid", placeItems: "start" }}>
          <TitleCol>
            {socialParams ? `Social Determinants of Health Screen` : `Task`}
          </TitleCol>

          <Space style={{ padding: "0 0 20px 20px" }} color={Colors.Grey}>
            {socialParams && (
              <AppButton
                type={ButtonType.Link}
                style={{
                  color: "#8E94A0",
                  fontStyle: "normal",
                  fontSize: "11px"
                }}
                buttonContent={
                  <Space align="start" style={{ marginLeft: "-16px" }}>
                    <ArrowLeftOutlined />
                    <span style={{ marginLeft: "-5px" }}>Back to Tasks</span>
                  </Space>
                }
                onClick={() => setSocialParams(false)}
              />
            )}
          </Space>
        </Col>
      </Row>
      {socialParams ? (
        <PatientDetail />
      ) : (
        <TasksTable setSocialParams={setSocialParams} />
      )}
    </>
  );
};

const TitleCol = styled(Col)`
  font-family: Century Gothic Bold;
  padding: 20px 20px 0 20px;
`;
