import { DropDownProps } from "antd/lib/dropdown";
import React, { ReactNode, useEffect, useRef } from "react";
import { CSSProp } from "styled-components";
import { DropDownStyled, OverlayContainer, SpacerProps } from "./customStyles";

interface DropdownProps
  extends SpacerProps,
    Pick<DropDownProps, "trigger" | "arrow"> {
  readonly borderradius?: CSSProp;
  readonly dropDownMenuHeader?: ReactNode;
  readonly dropDownMenuBody: ReactNode;
  readonly dropDownMenuFooter?: ReactNode;
  readonly visible?: boolean;
  readonly onVisibleChange?: () => void;
  setVisible?(visible: boolean): void;
  readonly placement?:
    | "bottomCenter"
    | "topLeft"
    | "topCenter"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | undefined;
  readonly destroyPopupOnHide?: boolean;
  readonly overlayMaxWidth?: string;
}

export const DropDownComponent: React.FC<DropdownProps> = ({
  children,
  borderradius,
  dropDownMenuHeader,
  dropDownMenuBody,
  dropDownMenuFooter,
  trigger,
  arrow,
  visible,
  setVisible,
  onVisibleChange,
  destroyPopupOnHide,
  placement = "bottomCenter",
  overlayMaxWidth
}) => {
  const ref = useRef<any>();

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (
        visible &&
        setVisible &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setVisible(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  });

  return (
    <DropDownStyled
      cursor="pointer"
      onVisibleChange={onVisibleChange}
      arrow={arrow}
      visible={visible}
      destroyPopupOnHide={destroyPopupOnHide}
      overlayStyle={{ maxWidth: overlayMaxWidth }}
      overlay={
        <OverlayContainer borderradius={borderradius} ref={ref}>
          {dropDownMenuHeader}
          {dropDownMenuBody}
          {dropDownMenuFooter}
        </OverlayContainer>
      }
      borderradius={borderradius}
      placement={placement}
      trigger={trigger}
      overlayClassName="details-dropdown"
    >
      <span
        style={
          visible
            ? {
                pointerEvents: "none"
              }
            : {}
        }
        onClick={() => setVisible && setVisible(!visible)}
      >
        {children}
      </span>
    </DropDownStyled>
  );
};
