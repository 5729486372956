import React, { useContext, useMemo, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Spin } from "antd";
import { useQuery } from "react-query";

import { ProvidersRoutesRef } from "constants/routes";
import { Colors } from "helpers/colors";
import { Dropdown } from "components/dropDownBox";
import { NotificationsMenu } from "components/notificationsMenu";
import { NotificationButtonIcon } from "./NotificationButtonIcon";
import { NotificationsIcon } from "./notificationsIcon";
import { DISABLED_MENU_BACKGROUND_COLOR } from "components/sideBar/sideBarMenu";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { createApiClient } from "apiClient";
import { NotificationProps } from "sharedTypes";
import moment from "moment";
import { CircleIndicator } from "components/circleIndicator";
import { UserRoles } from "constants/roles";

export const NotificationsDropdownComponent: React.FC = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const navigate = useNavigate();

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const {
    refetch: getNotificationsList,
    isRefetching: isRefetchingGetNotificationsList,
    isLoading: isLoadingGetNotificationsList
  } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        userIsPatient
          ? UserRoles.PATIENT
          : userIsCaregiver
          ? UserRoles.CAREGIVER
          : UserRoles.PROVIDER
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      },
      enabled: false
    }
  );

  const userId = sessionStorage.getItem("userId") || "";

  const userNotificationItems = useMemo(() => {
    const userMessageNotificationItems = notificationsContext
      .filterMessageNotificationsByStatus(
        notificationsContext.notifications?.messages || [],
        "unread"
      )
      .map(item => {
        return {
          desc: item.desc,
          type: item.type,
          status: item.status,
          notification_id: item.notification_id,
          severity: item.severity,
          created_on: item.created_on,
          patient_id: item.patient_id
        };
      });

    let tempNotifications: NotificationProps[] = [];
    if (notificationsContext.notifications && !userIsPatient) {
      tempNotifications = notificationsContext.filterNotificationsByStatus(
        notificationsContext.notifications?.remote_vitals?.concat(
          notificationsContext.notifications?.symptoms,
          notificationsContext.notifications?.medications,
          notificationsContext.notifications?.care_team
        ) || [],
        "unread"
      );
    }

    let surveyNotificaions: NotificationProps[] = [];
    if (notificationsContext.notifications?.survey) {
      surveyNotificaions = notificationsContext.filterNotificationsByStatus(
        notificationsContext.notifications?.survey || [],
        "unread"
      );
    }

    return tempNotifications
      .filter(item => item.created_on)
      .map(item => {
        return {
          desc: item.desc,
          type: item.type,
          status: item.status,
          notification_id: item.notification_id,
          severity: item.severity,
          created_on: item.created_on,
          patient_id: item.patient_id
        };
      })
      .concat(surveyNotificaions)
      .concat(userMessageNotificationItems)
      .sort(
        (a, b) =>
          moment(b.created_on).valueOf() - moment(a.created_on).valueOf()
      )
      .sort((a, b) => b.severity - a.severity)
      .slice(0, 5);
  }, [notificationsContext.notifications]);

  const handleClickAllNotificationsIcon = () => {
    navigate(ProvidersRoutesRef.AllNotifications);
    setIsDropdownVisible(false);
  };

  return (
    <Dropdown
      arrow
      borderradius="8px"
      trigger={["click"]}
      placement="bottomRight"
      overlayMaxWidth={"800"}
      dropDownMenuHeader={
        <NotificationsDropdownHeader>
          <TitleCol span={12}>Notifications</TitleCol>
          <HeaderButtonCol span={12}>
            <span
              onClick={handleClickAllNotificationsIcon}
              style={{ cursor: "pointer" }}
            >
              <NotificationButtonIcon />
            </span>
          </HeaderButtonCol>
        </NotificationsDropdownHeader>
      }
      dropDownMenuBody={
        <div style={{ width: "700px" }}>
          <NotificationsMenu
            getNotificationsList={getNotificationsList}
            loading={isRefetchingGetNotificationsList}
            notifications={userNotificationItems}
            avatarColSpan={3}
            wrapperStyle={{
              padding: "0px",
              backgroundColor: Colors.GhostWhite,
              borderRadius: "0px 0px 8px 8px"
            }}
          />
        </div>
      }
      visible={isDropdownVisible}
      setVisible={setIsDropdownVisible}
    >
      {isLoadingGetNotificationsList ? (
        <SpinStyled />
      ) : (
        <NotificationCol>
          {userNotificationItems.find(item => item.severity === 2) ? (
            <CircleIndicatorWrapper>
              <CircleIndicator outerColor={"#EEB5AD"} innerColor={Colors.Red} />
            </CircleIndicatorWrapper>
          ) : userNotificationItems.find(item => item.severity === 1) ? (
            <CircleIndicatorWrapper>
              <CircleIndicator outerColor={"#fbddb7"} innerColor={"#f18e13"} />
            </CircleIndicatorWrapper>
          ) : (
            ""
          )}
          <NotificationsIcon />
        </NotificationCol>
      )}
    </Dropdown>
  );
};

const TitleCol = styled(Col)`
  padding: 20px;
  padding-left: 17px;
`;

const HeaderButtonCol = styled(Col)`
  text-align: right;

  svg {
    margin-top: -11px;
    margin-right: -10px;
  }
`;

const NotificationCol = styled(Col)`
  background-color: ${props =>
    props["aria-disabled"] ? DISABLED_MENU_BACKGROUND_COLOR : Colors.AliceBlue};
  border-radius: 8px;
  width: 45px;
  display: grid;
  place-items: center;
  margin-right: 10px;
  height: 100%;
`;

const NotificationsDropdownHeader = styled(Row)`
  background: ${Colors.White};
  border-radius: 8px 8px 0px 0px;
  height: 60px;
  font-family: Century Gothic Bold;
  border-bottom: 2px solid ${Colors.Lavender};
  font-size: 14px;
`;

const CircleIndicatorWrapper = styled.span`
  position: absolute;
  top: 9px;
  right: 7px;
`;

const SpinStyled = styled(Spin)`
  margin-right: 10px;
  padding-top: 12px;
`;
