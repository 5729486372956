import { ArrowLeftOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Form from "antd/lib/form";
import React, { useContext, useRef, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { KidneyStoneSection } from "./kidneyStoneSection";
import { PainSection } from "./painSection";
import { PassedAKidneyStoneSection } from "./passedAKidneyStoneSection";
import { ProcedureToRemoveKidneyStoneSection } from "./procedureToRemoveKidneyStoneSection";
import { UrinarySymptomsSection } from "./urinarySymptomsSection";
import { Platform, UserRoles } from "constants/roles";
import { FormSubmitButton } from "pages/symptoms/surveyForms/FormSubmitButton";
import { FormClearButton } from "pages/symptoms/surveyForms/FormClearButton";

interface UrinarySymptomsFormProps {
  setShowSurveyForm(show: boolean): void;
  selectedItems: string[];
  goToNextFormOrReturn(): void;
}

export const UrinarySymptomsForm: React.FC<UrinarySymptomsFormProps> = ({
  setShowSurveyForm,
  selectedItems,
  goToNextFormOrReturn
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [currentPage, setCurrentPage] = useState(1);
  const [showSubmit, setShowSubmit] = useState(false);

  const [otherPainTempInput, setOtherPainTempInput] = useState("");
  const otherPainInputRef = useRef("");

  const [
    otherKidneyStoneLastExperienceTempInput,
    setOtherKidneyStoneLastExperienceTempInput
  ] = useState("");
  const otherKidneyStoneLastExperienceTempInputRef = useRef("");

  const [
    otherDurationKnownAboutKidneyStoneTempInput,
    setOtherDurationKnownAboutKidneyStoneTempInput
  ] = useState("");
  const otherDurationKnownAboutTempInputRef = useRef("");

  const [
    otherWhenWasKidneyStoneRemovedTempInput,
    setOtherWhenWasKidneyStoneRemovedTempInput
  ] = useState("");
  const otherWhenWasKidneyStoneRemovedTempInputRef = useRef("");

  const [form] = Form.useForm();

  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const userRole = sessionStorage.getItem("userRole");

  const {
    isLoading: isLoadingUrinarySymptomMutation,
    mutate: submitUrinarySymptomForm
  } = useMutation<string>(
    "add-bill",
    async () => {
      return await createApiClient().addUrinaryFormData({
        patient_internal_id:
          userRole === UserRoles.PATIENT && userContext.userData
            ? userContext.userData?.internal_id
            : patientContext?.patientData?.id || "",
        report: localStorage.getItem("reportYourUrinarySymptoms") || "",
        symptons_1:
          localStorage.getItem("anyUrinarySymptoms") === ""
            ? null
            : localStorage.getItem("anyUrinarySymptoms"),
        symptons_2:
          localStorage.getItem("indicateUrinarySymptoms") === ""
            ? null
            : localStorage.getItem("indicateUrinarySymptoms"),
        symptons_3:
          localStorage.getItem("indicateUrinarySymptoms2") === ""
            ? null
            : localStorage.getItem("indicateUrinarySymptoms2"),
        pain:
          localStorage.getItem("indicateUrinarySymptoms2") === ""
            ? null
            : localStorage.getItem("indicateUrinarySymptoms2"),
        pain_where:
          localStorage.getItem("whereIsYourPain") === ""
            ? null
            : localStorage.getItem("whereIsYourPain"),
        kidney_stone:
          localStorage.getItem("hadAKidneyStone") === ""
            ? ""
            : localStorage.getItem("hadAKidneyStone"),
        kidney_stone_when:
          localStorage.getItem("lastExperienceOfKidneyStones") === ""
            ? null
            : otherKidneyStoneLastExperienceTempInputRef.current
            ? `${localStorage.getItem("lastExperienceOfKidneyStones")},${
                otherKidneyStoneLastExperienceTempInputRef.current
              }`
            : localStorage.getItem("lastExperienceOfKidneyStones"),
        kidney_stone_duration:
          localStorage.getItem("durationKnownAboutYourKidney") === ""
            ? null
            : otherDurationKnownAboutTempInputRef.current
            ? `${localStorage.getItem("durationKnownAboutYourKidney")},${
                otherDurationKnownAboutTempInputRef.current
              }`
            : localStorage.getItem("durationKnownAboutYourKidney"),
        kidney_stone_passed:
          localStorage.getItem("haveYouPassedAKidneyStone") === ""
            ? null
            : localStorage.getItem("haveYouPassedAKidneyStone"),
        kidney_stone_passed_when:
          localStorage.getItem("howLongAgoPassedStone") === ""
            ? null
            : localStorage.getItem("howLongAgoPassedStone"),
        kidney_stone_removed:
          localStorage.getItem("haveHadProcedureToRemoveStone") === ""
            ? null
            : localStorage.getItem("haveHadProcedureToRemoveStone"),
        kidney_stone_removed_when:
          localStorage.getItem("whenWasProcedurePerformed") === ""
            ? null
            : otherWhenWasKidneyStoneRemovedTempInputRef.current
            ? `${localStorage.getItem("whenWasProcedurePerformed")},${
                otherWhenWasKidneyStoneRemovedTempInputRef.current
              }`
            : localStorage.getItem("whenWasProcedurePerformed"),
        submitted_by: `${userContext.userData?.firstName} ${userContext.userData?.lastName}`
      });
    },
    {
      onSuccess: () => {
        message.success("Form Submitted Successfully");
        goToNextFormOrReturn();
      },
      onError: () => {
        message.error({
          content: (
            <span>
              There was a problem submitting this form, resubmit or{" "}
              <a onClick={() => goToNextFormOrReturn()}>
                click here to continue to the next survey
              </a>
            </span>
          ),
          style: {
            bottom: "10px",
            position: "fixed",
            left: "30%"
          },
          duration: 5
        });
      }
    }
  );

  const onFinish = () => {
    //Values to be passed on to API from session storage
    if (currentPage === 1) {
      if (localStorage.getItem("reportYourUrinarySymptoms") === "No") {
        form.resetFields([
          "Any urinary symptoms",
          "Indicate urinary symptoms",
          "Indicate urinary symptoms 2",
          "Have you had any pain",
          "Where is your pain",
          "Have you ever had a kidney stone",
          "Last experience of symptoms",
          "How long have you known about your kidney stone",
          "Have you ever passed a kidney stone",
          "How long ago did you pass your stone",
          "Have you ever had a procedure to remove your stone",
          "When was procedure performed"
        ]);
        localStorage.setItem("anyUrinarySymptoms", "");
        localStorage.setItem("indicateUrinarySymptoms", "");
        localStorage.setItem("indicateUrinarySymptoms2", "");
        localStorage.setItem("hadAnyPain", "");
        localStorage.setItem("whereIsYourPain", "");
        localStorage.setItem("hadAKidneyStone", "");
        localStorage.setItem("lastExperienceOfKidneyStones", "");
        localStorage.setItem("durationKnownAboutYourKidney", "");
        localStorage.setItem("haveYouPassedAKidneyStone", "");
        localStorage.setItem("howLongAgoPassedStone", "");
        localStorage.setItem("haveHadProcedureToRemoveStone", "");
        localStorage.setItem("whenWasProcedurePerformed", "");
        otherWhenWasKidneyStoneRemovedTempInputRef.current = "";
        setOtherWhenWasKidneyStoneRemovedTempInput("");
        otherKidneyStoneLastExperienceTempInputRef.current = "";
        setOtherKidneyStoneLastExperienceTempInput("");
        otherDurationKnownAboutTempInputRef.current = "";
        setOtherDurationKnownAboutKidneyStoneTempInput("");
        submitUrinarySymptomForm();
      } else {
        setCurrentPage(2);
      }
    } else if (currentPage === 2) {
      setCurrentPage(3);
    } else if (currentPage === 3) {
      setCurrentPage(4);
    } else if (currentPage === 4) {
      setCurrentPage(5);
    } else if (currentPage === 5) {
      if (localStorage.getItem("hadAnyPain") === "No") {
        form.resetFields(["Where is your pain"]);
        localStorage.setItem("whereIsYourPain", "");
        setOtherPainTempInput("");
        otherPainInputRef.current = "";
        setCurrentPage(7);
      } else {
        setCurrentPage(6);
      }
    } else if (currentPage === 6) {
      if (localStorage.getItem("whereIsYourPain")?.includes("Other")) {
        //To handle 'other' input from where is your pain section
        const finalOtherPainSymptoms =
          localStorage.getItem("whereIsYourPain") +
          "," +
          otherPainInputRef.current;
        localStorage.setItem("whereIsYourPain", finalOtherPainSymptoms);
      }
      setCurrentPage(7);
    } else if (currentPage === 7) {
      if (localStorage.getItem("hadAKidneyStone") === "No") {
        form.resetFields([
          "Last experience of symptoms",
          "How long have you known about your kidney stone"
        ]);
        localStorage.setItem("lastExperienceOfKidneyStones", "");
        localStorage.setItem("durationKnownAboutYourKidney", "");
        setOtherKidneyStoneLastExperienceTempInput("");
        setOtherDurationKnownAboutKidneyStoneTempInput("");
        otherKidneyStoneLastExperienceTempInputRef.current = "";
        otherDurationKnownAboutTempInputRef.current = "";
        submitUrinarySymptomForm();
      } else {
        setCurrentPage(8);
      }
    } else if (currentPage === 8) {
      if (
        sessionStorage
          .getItem("lastExperienceOfKidneyStones")
          ?.includes("Other")
      ) {
        //To handle 'other' input from where is your pain section
        const finalExperienceofKidneyStones =
          localStorage.getItem("lastExperienceOfKidneyStones") +
          "," +
          otherKidneyStoneLastExperienceTempInputRef.current;
        localStorage.setItem(
          "lastExperienceOfKidneyStones",
          finalExperienceofKidneyStones
        );
      }
      setCurrentPage(9);
    } else if (currentPage === 9) {
      if (
        sessionStorage
          .getItem("durationKnownAboutYourKidney")
          ?.includes("Other")
      ) {
        //To handle 'other' input from where is your pain section
        const finalDurationKnown =
          localStorage.getItem("durationKnownAboutYourKidney") +
          "," +
          otherDurationKnownAboutTempInputRef.current;
        localStorage.setItem(
          "durationKnownAboutYourKidney",
          finalDurationKnown
        );
      }
      setCurrentPage(10);
    } else if (currentPage === 10) {
      if (localStorage.getItem("haveYouPassedAKidneyStone") === "No") {
        form.resetFields(["How long ago did you pass your stone"]);
        localStorage.setItem("howLongAgoPassedStone", "");
        submitUrinarySymptomForm();
      } else {
        setCurrentPage(11);
      }
    } else if (currentPage === 11) {
      setCurrentPage(12);
    } else if (currentPage === 12) {
      if (localStorage.getItem("haveHadProcedureToRemoveStone") === "No") {
        form.resetFields(["When was procedure performed"]);
        localStorage.setItem("whenWasProcedurePerformed", "");
        submitUrinarySymptomForm();
      } else {
        setCurrentPage(13);
      }
    } else {
      submitUrinarySymptomForm();
    }
  };

  const handleGoBack = () => {
    if (currentPage === 7 && localStorage.getItem("hadAnyPain") === "No") {
      setCurrentPage(5);
    } else if (
      currentPage === 10 &&
      localStorage.getItem("hadAKidneyStone") === "No"
    ) {
      setCurrentPage(7);
    } else {
      setCurrentPage(currentPage !== 1 ? currentPage - 1 : 1);
    }
  };

  const onClear = () => {
    if (currentPage === 1) {
      form.resetFields(["Report your urinary symptoms"]);
    } else if (currentPage === 2) {
      form.resetFields(["Any urinary symptoms"]);
      localStorage.setItem("anyUrinarySymptoms", "");
    } else if (currentPage === 3) {
      form.resetFields(["Indicate urinary symptoms"]);
      localStorage.setItem("indicateUrinarySymptoms", "");
    } else if (currentPage === 4) {
      form.resetFields(["Indicate urinary symptoms 2"]);
      localStorage.setItem("indicateUrinarySymptoms2", "");
    } else if (currentPage === 5) {
      form.resetFields(["Have you had any pain"]);
      localStorage.setItem("hadAnyPain", "");
    } else if (currentPage === 6) {
      form.resetFields(["Where is your pain"]);
      localStorage.setItem("whereIsYourPain", "");
      otherPainInputRef.current = "";
      setOtherPainTempInput("");
    } else if (currentPage === 7) {
      form.resetFields(["Have you ever had a kidney stone"]);
      localStorage.setItem("hadAKidneyStone", "");
    } else if (currentPage === 8) {
      form.resetFields(["Last experience of symptoms"]);
      localStorage.setItem("lastExperienceOfKidneyStones", "");
      otherKidneyStoneLastExperienceTempInputRef.current = "";
      setOtherKidneyStoneLastExperienceTempInput("");
    } else if (currentPage === 9) {
      form.resetFields(["How long have you known about your kidney stone"]);
      localStorage.setItem("durationKnownAboutYourKidney", "");
      otherDurationKnownAboutTempInputRef.current = "";
      setOtherDurationKnownAboutKidneyStoneTempInput("");
    } else if (currentPage === 10) {
      form.resetFields(["Have you ever passed a kidney stone"]);
      localStorage.setItem("haveYouPassedAKidneyStone", "");
    } else if (currentPage === 11) {
      form.resetFields(["How long ago did you pass your stone"]);
      localStorage.setItem("howLongAgoPassedStone", "");
    } else if (currentPage === 12) {
      form.resetFields(["Have you ever had a procedure to remove your stone"]);
      localStorage.setItem("haveHadProcedureToRemoveStone", "");
    } else if (currentPage === 13) {
      form.resetFields(["When was procedure performed"]);
      localStorage.setItem("whenWasProcedurePerformed", "");
      setOtherWhenWasKidneyStoneRemovedTempInput("");
      otherWhenWasKidneyStoneRemovedTempInputRef.current = "";
    }
  };

  return (
    <div>
      <div style={{ display: platform === Platform.MOBILE ? "none" : "block" }}>
        <AppButton
          type={ButtonType.Primary}
          onClick={() => setShowSurveyForm(false)}
          buttonContent={
            <>
              <ArrowLeftOutlined onClick={() => setShowSurveyForm(false)} />{" "}
              Back
            </>
          }
        />
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Form
          name="urinarySymptoms"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          style={{ padding: "0px" }}
        >
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
              style={{ borderTop: "8px solid #4284f3" }}
            >
              <PageTitle>Survey - {selectedItems[0]}</PageTitle>
            </AppCard>
            <UrinarySymptomsSection
              currentPage={currentPage}
              setShowSubmit={setShowSubmit}
            />
            <PainSection
              currentPage={currentPage}
              setOtherPainTempInput={setOtherPainTempInput}
              otherPainInputRef={otherPainInputRef}
              otherPainTempInput={otherPainTempInput}
            />
            <KidneyStoneSection
              currentPage={currentPage}
              setShowSubmit={setShowSubmit}
              setOtherKidneyStoneLastExperienceTempInput={
                setOtherKidneyStoneLastExperienceTempInput
              }
              otherKidneyStoneLastExperienceTempInputRef={
                otherKidneyStoneLastExperienceTempInputRef
              }
              otherKidneyStoneLastExperienceTempInput={
                otherKidneyStoneLastExperienceTempInput
              }
              setOtherDurationKnownAboutKidneyStoneTempInput={
                setOtherDurationKnownAboutKidneyStoneTempInput
              }
              otherDurationKnownAboutKidneyStoneTempInput={
                otherDurationKnownAboutKidneyStoneTempInput
              }
              otherDurationKnownAboutTempInputRef={
                otherDurationKnownAboutTempInputRef
              }
            />
            <PassedAKidneyStoneSection
              currentPage={currentPage}
              form={form}
              setShowSubmit={setShowSubmit}
            />
            <ProcedureToRemoveKidneyStoneSection
              currentPage={currentPage}
              setShowSubmit={setShowSubmit}
              otherWhenWasKidneyStoneRemovedTempInputRef={
                otherWhenWasKidneyStoneRemovedTempInputRef
              }
              otherWhenWasKidneyStoneRemovedTempInput={
                otherWhenWasKidneyStoneRemovedTempInput
              }
              setOtherWhenWasKidneyStoneRemovedTempInput={
                setOtherWhenWasKidneyStoneRemovedTempInput
              }
            />
            <Row>
              <Col span={5}>
                <Form.Item>
                  <FormClearButton onClear={onClear} />
                </Form.Item>
              </Col>
              <Col span={19} style={{ textAlign: "right" }}>
                <Form.Item>
                  <Space>
                    {currentPage !== 1 && (
                      <AppButton
                        type={ButtonType.Primary}
                        buttonContent={"Back"}
                        onClick={() => handleGoBack()}
                        style={{ width: "90px" }}
                      />
                    )}
                    <FormSubmitButton
                      type={ButtonType.Primary}
                      buttonContent={
                        currentPage === 13 || showSubmit ? "Submit" : "Next"
                      }
                      htmlType="submit"
                      loading={isLoadingUrinarySymptomMutation}
                    />
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Form>
      </div>
    </div>
  );
};

const PageTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
`;
