import React from "react";
import styled from "styled-components";

interface CircleIndicatorProps {
  innerColor: string;
  outerColor: string;
}

export const CircleIndicator: React.FC<CircleIndicatorProps> = ({
  innerColor,
  outerColor
}) => {
  return (
    <OuterCircle style={{ backgroundColor: outerColor }}>
      <InnerCircle style={{ backgroundColor: innerColor }}></InnerCircle>
    </OuterCircle>
  );
};

const OuterCircle = styled.div`
  margin-top: -3px;
  margin-left: 5px;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  position: relative;
  /* 
  Child elements with absolute positioning will be 
  positioned relative to this div 
 */
`;

const InnerCircle = styled.div`
  position: absolute;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  /*
 Put top edge and left edge in the center
*/
  top: 50%;
  left: 50%;
  margin: -3px 0px 0px -3px;
  /* 
 Offset the position correctly with
 minus half of the width and minus half of the height 
*/
`;
