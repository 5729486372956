import React from "react";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { Platform } from "constants/roles";

interface FormClearButtonProps {
  readonly onClear: () => void;
}

export const FormClearButton: React.FC<FormClearButtonProps> = ({
  onClear
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  return (
    <AppButton
      type={ButtonType.Link}
      buttonContent={"Clear"}
      onClick={() => onClear()}
      style={{
        width: "90px",
        height: platform === Platform.MOBILE ? "44px" : "inherit"
      }}
    />
  );
};
