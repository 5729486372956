import React, { useContext, useEffect, useState } from "react";
import hdOAuth from "./oauth";
import { useQuery } from "react-query";
import { createApiClient } from "../../apiClient";
import { OAuthTokenResponse } from "../../sharedTypes.js";
import { UserContext, UserContextState } from "../../contextApis/userContext";
import Skeleton from "antd/lib/skeleton";
import { AppCard } from "components/card";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Result } from "antd";
import styled from "styled-components";
import { CareGemLogo } from "./careGemLogo";

const RedirectEpic: React.FC = () => {
  const [searchParams] = useSearchParams();
  const urlParams = new URLSearchParams(window.location.search);
  const userContext = useContext<UserContextState>(UserContext);
  const [loginCredsObj, setLoginCredsObj] = useState<OAuthTokenResponse>({
    access_token: "",
    id_token: "",
    patient: "",
    state: "",
    user: ""
  });
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [hasError, setHasError] = useState<boolean>(false);

  const oauth = new hdOAuth();

  const navigate = useNavigate();

  const {
    isLoading: isLoadingEpic,
    data: epicData,
    error: isEpicError,
    isFetching: isEpicLoading,
    refetch
  } = useQuery(
    "epictoken",
    async () => {
      const data = await createApiClient().postEpicTokenResponse(loginCredsObj);
      if (data == null) {
        setTitle("Error");
        setSubTitle(
          "Provider not mapped in Caregem (" + loginCredsObj?.user + ")."
        );
        setHasError(true);
        return;
      }
      return data;
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    const loginData = async () => {
      if (!urlParams.has("code")) {
        if (urlParams.has("error")) {
          const searchParams = new URL(window.location.href).searchParams;
          const errorDescription = searchParams.get("error_description");
          setTitle("Error");
          setSubTitle(errorDescription ? errorDescription : "Error");
          setHasError(true);
        } else {
          setTitle("Page Not Found");
          setSubTitle("Sorry, the page you visited does not exist.");
          setHasError(true);
        }
        return;
      }
      try {
        const accessTokenPromise = await oauth.getAccessToken(
          window.location.href
        );

        if (accessTokenPromise !== null) {
          const {
            accessToken,
            data: { state, id_token, patient, user, USER, PATIENT }
          } = accessTokenPromise;

          if (user == undefined && USER == undefined) {
            setTitle("Error");
            setSubTitle("Physician or User not found in Token.");
            setHasError(true);
            return;
          }

          // const numericPATIENT = parseInt(PATIENT?.replace(/\D/g, ""), 10);
          // const numericPatient = parseInt(patient?.replace(/\D/g, ""), 10);
          const newUser = user?.replaceAll("+", "");
          const newUSER = USER?.replaceAll("+", "");

          // if (numericPatient <= 0 && numericPATIENT <= 0) {
          //   setTitle("Error");
          //   setSubTitle("Patient not found in Token.");
          //   setHasError(true);
          //   return;
          // }

          setLoginCredsObj({
            ...loginCredsObj,
            access_token: accessToken,
            state,
            id_token,
            patient: PATIENT ? PATIENT : patient,
            user: USER ? newUSER : newUser
          });

          const accessTokenString = stringify(accessTokenPromise);
          sessionStorage.setItem("tokenData", accessTokenString);
        } else {
          setTitle("Error");
          setSubTitle("Invalid URL.");
          setHasError(true);
        }
      } catch (error) {
        setTitle("Error");
        setSubTitle("Client Authentication Failed.");
        setHasError(true);
        return;
      }
    };

    loginData();
  }, []);

  useEffect(() => {
    const allKeysFilled = Object.values(loginCredsObj).every(
      value => value !== ""
    );
    if (allKeysFilled) {
      refetch();
    }
  }, [loginCredsObj]);

  useEffect(() => {
    if (epicData) {
      sessionStorage.setItem("access_token", epicData?.token);
      sessionStorage.setItem("userRole", epicData?.userRole);
      sessionStorage.setItem("userIdentityId", epicData?.userIdentityId);
      sessionStorage.setItem(
        "billingPermission",
        epicData?.billing_permissions
      );
      sessionStorage.setItem("userName", epicData?.username);
      sessionStorage.setItem("epicLogin", "true");
      sessionStorage.setItem("surveyNotification", "");
      const redirectURI =
        "/patients/care-team/" + epicData?.patient_internal_id;
      const patientId = String(epicData?.patient_internal_id);
      sessionStorage.setItem("userId", String(epicData?.provider_id));
      sessionStorage.setItem("patientId", patientId);
      if (
        sessionStorage.getItem("userRole") != "physician" &&
        sessionStorage.getItem("userRole") != "nurse"
      ) {
        setTitle("Error");
        setSubTitle("You are not Authorized");
        setHasError(true);
      } else {
        userContext.onLogin();
        userContext.onUserIdentityIdChange(epicData?.userIdentityId);
        navigate(redirectURI);
      }
    }
  }, [epicData]);

  function stringify(obj: any) {
    let cache: any = [];
    const str = JSON.stringify(obj, function (key, value) {
      if (typeof value === "object" && value !== null) {
        if (cache.indexOf(value) !== -1) {
          return;
        }
        cache.push(value);
      }
      return value;
    });
    cache = null;
    return str;
  }

  return (
    <>
      <MainContainer>
        <div>
          <CareGemLogo />
        </div>
        <Divider />
      </MainContainer>
      {hasError ? (
        <>
          <Result status="403" title={title} subTitle={subTitle} />
        </>
      ) : (
        <AppCard cardHeight="100vh" cardWidth="100%">
          <Skeleton
            loading={isLoadingEpic || isEpicLoading}
            active={isLoadingEpic || isEpicLoading}
            paragraph={{ rows: 13, width: "100%" }}
            avatar={true}
          />
        </AppCard>
      )}
    </>
  );
};
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`;

const Divider = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  margin: 16px 0;
`;

export default RedirectEpic;
