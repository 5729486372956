import React from "react";
import Input from "antd/lib/input";
import styled, { CSSProperties } from "styled-components";

interface SurveyInputProps {
  value: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  placeholder: string;
  style?: CSSProperties;
}

export const SurveyInput: React.FC<SurveyInputProps> = ({
  value,
  onChange,
  placeholder,
  style
}) => {
  return (
    <InputStyled
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={style}
    />
  );
};

const InputStyled = styled(Input)`
  border-top: none;
  box-shadow: none !important;
  border-right: none;
  border-left: none;
`;
