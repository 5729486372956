import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import styled from "styled-components";
import { AppCard } from "components/card";
import { CKDStageIcon } from "./CKDStageIcon";
import { CreatenineIcon } from "./CreatenineIcon";
import { GFRIcon } from "./GFRIcon";
import { UrineProteinLevelIcon } from "./UrineProteinLevelIcon";
import { KidneyFunctionHeartRateInfoDropdown } from "pages/myCurrentStatus/kidneyFunctionHeartRateInfoDropdown";
import { Colors } from "helpers/colors";
import {
  urineProteinLevelQA,
  gfrQA,
  createnineQA,
  ckdQA
} from "./myKidneyFunctionQA";

export const MyKidneyFunctionContent: React.FC = () => {
  return (
    <AppCard
      cardHeight="100%"
      cardWidth="100%"
      style={{
        borderRadius: "0px 0px 8px 8px",
        marginTop: "-2px"
      }}
    >
      <Row gutter={[20, 20]}>
        <Col span={6}>
          <>
            <AppCard cardHeight="fit-content" cardWidth="100%">
              <GFRIcon />
              <CardDataStyled>GFR</CardDataStyled>
              <CardDataStyled>-</CardDataStyled>
            </AppCard>

            <FAQDropdownTrigger>
              <KidneyFunctionHeartRateInfoDropdown data={gfrQA}>
                What is GFR?
              </KidneyFunctionHeartRateInfoDropdown>
            </FAQDropdownTrigger>
          </>
        </Col>
        <Col span={6}>
          <>
            <AppCard cardHeight="fit-content" cardWidth="100%">
              <CreatenineIcon />
              <CardDataStyled>Creatinine</CardDataStyled>
              <CardDataStyled>-</CardDataStyled>
            </AppCard>
            <FAQDropdownTrigger>
              <KidneyFunctionHeartRateInfoDropdown data={createnineQA}>
                What is Creatinine?
              </KidneyFunctionHeartRateInfoDropdown>
            </FAQDropdownTrigger>
          </>
        </Col>
        <Col span={6}>
          <>
            <AppCard cardHeight="fit-content" cardWidth="100%">
              <CKDStageIcon />
              <CardDataStyled>CKD Stage</CardDataStyled>
              <CardDataStyled>-</CardDataStyled>
            </AppCard>
            <FAQDropdownTrigger>
              <KidneyFunctionHeartRateInfoDropdown data={ckdQA}>
                What is CKD stage?
              </KidneyFunctionHeartRateInfoDropdown>
            </FAQDropdownTrigger>
          </>
        </Col>
        <Col span={6}>
          <>
            <AppCard cardHeight="fit-content" cardWidth="100%">
              <UrineProteinLevelIcon />
              <CardDataStyled>Urine Protein Level</CardDataStyled>
              <CardDataStyled>-</CardDataStyled>
            </AppCard>
            <FAQDropdownTrigger>
              <KidneyFunctionHeartRateInfoDropdown data={urineProteinLevelQA}>
                What is Urine Protein Level?
              </KidneyFunctionHeartRateInfoDropdown>
            </FAQDropdownTrigger>
          </>
        </Col>
      </Row>
    </AppCard>
  );
};

const FAQDropdownTrigger = styled.div`
  color: ${Colors.DodgerBlue};
  font-size: 14px;
  font-family: Century Gothic Bold;
  padding: 20px;
`;

const CardDataStyled = styled.div`
  font-size: 24px;
  font-family: Century Gothic Bold;
`;
