import {
  CloseOutlined,
  DeleteOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { Col, message, Row, Spin } from "antd";
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import moment, { Moment } from "moment";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import { AppModal } from "components/modal";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";
import { Colors } from "helpers/colors";
import { CallRecordFormData, CallRecordNote } from "sharedTypes";
import { CallTypes, SelectedCallRecordDraft } from "./callRecords";
import { CallRecordDate } from "./formInputs/CallRecordDate";
import { CallRecordDiscussionItems } from "./formInputs/CallRecordDiscussionItems";
import { CallRecordDuration } from "./formInputs/CallRecordDuration";
import { CallRecordOtherIssues } from "./formInputs/CallRecordOtherIssues";
import {
  AddCallRecordGridContainer,
  AddCallRecordTitleText,
  ButtonContainer,
  DurationLabelText,
  ErrorMessage,
  OverflowCallRecordCardContainer
} from "./style";

interface EditCallRecordProps {
  readonly onFormSubmit: (formData: CallRecordFormData) => void;
  setShowAddEditCallRecords(type: string): void;
  readonly isLoadingPutCallRecord: boolean;
  readonly selectedDraft: SelectedCallRecordDraft;
  readonly changedDraft: boolean;
  setChangedDraft: (changed: boolean) => void;
  setSelectedDraft: (item: SelectedCallRecordDraft | undefined) => void;
  readonly disableForm: boolean;
  readonly isLoadingCallRecordNotes: boolean;
  readonly callRecordNotesData: CallRecordNote[];
  getCallRecords(): void;
}

interface FormSubmitData {
  readonly id: string | number;
  readonly date: Moment;
  readonly duration: string;
  readonly discussionItems: CallRecordNote[];
  readonly otherIssues: string;
}

export const EditCallRecord: React.FC<EditCallRecordProps> = ({
  onFormSubmit,
  setShowAddEditCallRecords,
  isLoadingPutCallRecord,
  selectedDraft,
  setChangedDraft,
  changedDraft,
  setSelectedDraft,
  disableForm,
  isLoadingCallRecordNotes,
  callRecordNotesData,
  getCallRecords
}) => {
  const [form] = Form.useForm();
  const [callRecordNotes, setCallRecordNotes] = useState<CallRecordNote[]>([]);
  const [submissionData, setSubmissionData] = useState<FormSubmitData>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [enableFinalize, setEnableFinalize] = useState<boolean>(false);
  const [saveAsDraft, setSaveAsDraft] = useState<boolean>(false);
  const submitClicked = useRef<boolean>();
  const cancelClicked = useRef<boolean>();
  const deleteClicked = useRef<boolean>();
  const isAutoSave = useRef<boolean>();
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

  const { id: patientId } = useParams();

  const { isLoading: isLoadingDeleteCallRecord, mutate: deleteCallRecord } =
    useMutation<string>(
      "delete-call-record",
      async () => {
        return await createApiClient().deleteCallRecord(
          patientId ? patientId : "",
          selectedDraft.id
        );
      },
      {
        onSuccess: () => {
          setShowAddEditCallRecords("");
          getCallRecords();
          message.success("Call Record Deleted Successfully!");
          setSelectedDraft(undefined);
        },
        onError: () => {
          message.error("There was a problem deleting this record");
        }
      }
    );

  const onValuesChange = (_changedValues: any, values: any) => {
    if (
      values.date.date &&
      values.duration.duration &&
      values.discussionItems.discussionItems &&
      values.discussionItems.discussionItems.length > 0
    ) {
      setEnableFinalize(true);
      return;
    }
    setEnableFinalize(false);
  };

  const handleOnFinish = (values: FormSubmitData) => {
    let isDraft = false;

    isDraft =
      !values.date ||
      !values.duration ||
      (values.discussionItems && values.discussionItems.length <= 0);

    const formData: CallRecordFormData = {
      id: values.id,
      date: moment(values.date) || moment(),
      duration: values.duration || "",
      discussionItems: values.discussionItems ? values.discussionItems : [],
      otherIssues: values.otherIssues || "",
      status:
        isDraft || isAutoSave.current || saveAsDraft ? "DRAFT" : "COMPLETED"
    };
    onFormSubmit(formData);
  };

  useEffect(() => {
    form.setFieldsValue({ id: selectedDraft.id });
  }, [selectedDraft]);

  useEffect(() => {
    if (callRecordNotesData) {
      setCallRecordNotes(callRecordNotesData);
    }
  }, [callRecordNotesData]);

  useLayoutEffect(() => {
    return () => {
      if (
        !submitClicked.current &&
        !cancelClicked.current &&
        !deleteClicked.current
      ) {
        // isAutoSave to ensure item is always saved as draft in auto save scenarios
        meetingContext.setCallRecordId("");
        isAutoSave.current = true;
        handleOnFinish({
          id: form.getFieldValue("id"),
          date: form.getFieldValue("date").date,
          duration: form.getFieldValue("duration").duration,
          discussionItems:
            form.getFieldValue("discussionItems").discussionItems,
          otherIssues: form.getFieldValue("otherIssues").otherIssues
        });
      }
    };
  }, []);

  useEffect(() => {
    if (changedDraft) {
      isAutoSave.current = true;
      handleOnFinish({
        id: form.getFieldValue("id"),
        date: form.getFieldValue("date").date,
        duration: form.getFieldValue("duration").duration,
        discussionItems: form.getFieldValue("discussionItems").discussionItems,
        otherIssues: form.getFieldValue("otherIssues").otherIssues
      });
    }
    if (
      form.getFieldValue("date").date &&
      form.getFieldValue("duration").duration &&
      form.getFieldValue("discussionItems").discussionItems &&
      form.getFieldValue("discussionItems").discussionItems > 0
    ) {
      setEnableFinalize(true);
    }
    setChangedDraft(false);
  }, [changedDraft]);

  const onFinish = () => {
    isAutoSave.current = false;
    submitClicked.current = true;
    const noDate = !form.getFieldValue("date").date;
    const noDuration = !form.getFieldValue("duration").duration;
    const noDiscussionItems =
      form.getFieldValue("discussionItems").discussionItems <= 0;
    const noOtherIssues = !form.getFieldValue("otherIssues").otherIssues;

    if (noDate && noDuration && noDiscussionItems && noOtherIssues) {
      setErrorMessage("The form is empty, please enter a value to proceed.");
      return;
    }
    setErrorMessage("");

    setSubmissionData({
      id: form.getFieldValue("id"),
      date: form.getFieldValue("date").date,
      duration: form.getFieldValue("duration").duration,
      discussionItems: form.getFieldValue("discussionItems").discussionItems,
      otherIssues: form.getFieldValue("otherIssues").otherIssues
    });
  };

  return (
    <AppCard cardHeight="100%" cardWidth="100%">
      <OverflowCallRecordCardContainer>
        <AddCallRecordTitleText>
          <Row>
            <Col span={12}>Edit Call Record</Col>
            <Col
              span={12}
              style={{
                textAlign: "right",
                paddingRight: "20px",
                pointerEvents: isLoadingPutCallRecord ? "none" : "auto"
              }}
            >
              <CloseOutlined
                onClick={() => {
                  setShowAddEditCallRecords("");
                  cancelClicked.current = true;
                  setSelectedDraft(undefined);
                }}
              />
            </Col>
          </Row>
          <Divider style={{ margin: "15px 0" }} />
        </AddCallRecordTitleText>
        <Form
          name="call records"
          form={form}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          <div>
            <Form.Item name="date" initialValue={selectedDraft.date}>
              <CallRecordDate
                clearForm={false}
                selectedDraft={selectedDraft}
                form={form}
                disabled={
                  disableForm || selectedDraft.callType !== CallTypes.MANUAL
                    ? true
                    : false
                }
              />
            </Form.Item>
          </div>
          <AddCallRecordGridContainer>
            <div>
              <Form.Item name="duration" initialValue={selectedDraft.duration}>
                <CallRecordDuration
                  clearForm={false}
                  selectedDraft={selectedDraft}
                  form={form}
                  disabled={
                    disableForm || selectedDraft.callType !== CallTypes.MANUAL
                      ? true
                      : false
                  }
                />
              </Form.Item>
            </div>
            <DurationLabelText>Minutes</DurationLabelText>
          </AddCallRecordGridContainer>
          <Form.Item
            name="discussionItems"
            initialValue={selectedDraft.itemsDiscussed}
          >
            {isLoadingCallRecordNotes ? (
              <Spin />
            ) : (
              <CallRecordDiscussionItems
                discussionItemOptions={callRecordNotes}
                clearForm={false}
                selectedDraft={selectedDraft}
                form={form}
              />
            )}
          </Form.Item>
          <Form.Item name="otherIssues" initialValue={selectedDraft.other}>
            <CallRecordOtherIssues
              clearForm={false}
              selectedDraft={selectedDraft}
              form={form}
              disabled={disableForm}
            />
          </Form.Item>
          <ErrorMessage style={{ marginTop: "-20px" }}>
            {errorMessage}
          </ErrorMessage>
          <Form.Item style={{ marginTop: "10px" }}>
            <AppButton
              type={ButtonType.Skeleton}
              buttonContent={<DeleteOutlined />}
              onClick={() => {
                setShowDeletePrompt(true);
              }}
              loading={isLoadingDeleteCallRecord}
              disabled={
                isLoadingPutCallRecord ||
                isLoadingCallRecordNotes ||
                disableForm
              }
              style={{
                float: "left",
                borderColor: Colors.Black,
                color: Colors.Black,
                fontSize: "18px",
                marginTop: "10px"
              }}
            />
            <ButtonContainer>
              <AppButton
                type={ButtonType.Secondary}
                onClick={() => {
                  form.submit();
                  setSaveAsDraft(true);
                }}
                buttonContent={"Save as Draft"}
                style={{ marginRight: 10, width: 120 }}
                disabled={
                  isLoadingPutCallRecord ||
                  isLoadingCallRecordNotes ||
                  isLoadingDeleteCallRecord ||
                  disableForm
                }
              />
              <AppButton
                type={ButtonType.Primary}
                onClick={() => {
                  form.submit();
                  setSaveAsDraft(false);
                }}
                buttonContent={"Finalize"}
                style={{ width: 120 }}
                loading={isLoadingPutCallRecord}
                disabled={
                  isLoadingCallRecordNotes ||
                  isLoadingDeleteCallRecord ||
                  disableForm ||
                  !enableFinalize
                }
              />
            </ButtonContainer>
          </Form.Item>
        </Form>
      </OverflowCallRecordCardContainer>
      <AppModal
        visible={submissionData ? true : false}
        title={saveAsDraft ? "Draft Confirmation" : "Finalize Submission"}
        cancelText="No"
        okText="Yes"
        onCancel={() => {
          setSubmissionData(undefined);
        }}
        onOk={() => {
          if (submissionData) {
            handleOnFinish(submissionData);
            setShowAddEditCallRecords("");
            setSubmissionData(undefined);
          }
          setSelectedDraft(undefined);
        }}
        prompt={{
          icon: (
            <InfoCircleOutlined
              style={{ color: "#F5A623", fontSize: "40px" }}
            />
          ),
          text: saveAsDraft ? (
            <div>Save as draft?</div>
          ) : (
            <div>
              You are going to create a call record that is non-editable, do you
              want to finalize?
            </div>
          )
        }}
        footer
      />
      <AppModal
        visible={showDeletePrompt}
        title="Delete Confirmation"
        cancelText="No"
        okText="Yes"
        onCancel={() => {
          setShowDeletePrompt(false);
        }}
        onOk={() => {
          deleteCallRecord();
          setShowDeletePrompt(false);
          deleteClicked.current = true;
        }}
        prompt={{
          icon: (
            <InfoCircleOutlined
              style={{ color: "#F5A623", fontSize: "40px" }}
            />
          ),
          text: <div>Are you sure you want to delete this draft?</div>
        }}
        footer
      />
    </AppCard>
  );
};
