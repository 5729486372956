import React from "react";

export const BloodPressureIcon: React.FC = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1140 5114 c-208 -27 -407 -101 -580 -217 -96 -64 -273 -241 -337
    -337 -95 -142 -163 -302 -199 -470 -26 -120 -26 -372 0 -495 22 -105 66 -235
    108 -320 84 -167 237 -350 387 -461 l81 -59 0 -204 c0 -128 4 -218 12 -243 16
    -54 88 -132 140 -153 24 -9 76 -19 116 -22 l72 -6 0 -98 c0 -109 8 -140 50
    -184 l28 -30 5 -305 c6 -329 16 -410 72 -575 157 -469 549 -812 1042 -912 78
    -16 148 -18 633 -18 606 0 613 1 814 66 485 159 839 574 921 1084 11 66 15
    178 15 416 l0 325 35 34 c19 19 40 50 45 70 5 19 10 139 10 266 l0 231 40 28
    c123 85 262 278 344 477 203 493 152 1149 -122 1570 -56 85 -162 201 -222 243
    -40 28 -40 28 -40 94 -1 100 -42 173 -113 200 -17 7 -112 11 -231 11 -189 0
    -204 -1 -246 -22 -69 -35 -90 -75 -90 -168 l0 -77 -77 -64 c-191 -159 -343
    -444 -403 -755 -48 -248 -48 -480 0 -728 64 -328 222 -619 421 -772 l59 -45 0
    -245 c0 -261 1 -266 52 -320 l30 -32 -4 -338 c-4 -311 -6 -345 -26 -411 -45
    -149 -103 -250 -207 -361 -107 -115 -247 -199 -410 -244 -78 -22 -92 -23 -590
    -23 -500 0 -512 0 -593 23 -249 68 -451 233 -558 457 -75 155 -77 172 -82 514
    l-3 304 30 31 c43 44 51 75 51 185 l0 98 73 6 c39 3 91 13 115 22 52 21 124
    99 140 153 8 25 12 115 12 243 l0 204 81 59 c150 111 303 294 387 461 42 85
    86 215 108 320 26 123 26 375 0 495 -55 256 -169 466 -355 651 -182 182 -382
    293 -631 350 -83 19 -331 33 -410 23z m286 -175 c740 -103 1172 -890 859
    -1566 -195 -422 -654 -684 -1112 -635 -626 66 -1078 634 -992 1248 84 605 647
    1036 1245 953z m3014 -82 c0 -60 5 -99 13 -109 6 -8 39 -34 73 -56 374 -249
    532 -968 339 -1549 -75 -224 -199 -404 -344 -500 -31 -20 -61 -44 -68 -52 -9
    -12 -12 -88 -13 -278 l0 -263 -170 0 -170 0 0 244 c0 150 -4 255 -11 274 -8
    22 -31 45 -79 77 -98 65 -197 181 -259 303 l-21 42 158 0 c152 0 159 1 179 23
    29 31 34 52 21 87 -18 53 -46 60 -247 60 l-180 0 -16 53 c-21 70 -55 268 -55
    322 l0 43 186 4 186 3 24 28 c31 37 31 81 -1 112 -24 25 -26 25 -210 25 l-185
    0 0 49 c0 59 33 255 55 329 l16 52 179 0 c98 0 190 4 205 10 33 13 59 69 46
    102 -18 48 -34 53 -203 58 l-159 5 53 88 c61 103 147 196 236 256 35 23 67 54
    73 67 5 14 9 61 9 105 l0 79 170 0 170 0 0 -93z m-3573 -2226 c154 -52 243
    -66 413 -66 170 0 259 14 413 66 49 16 90 29 93 29 2 0 4 -67 4 -149 0 -144
    -1 -151 -24 -178 l-24 -28 -462 0 -462 0 -24 28 c-23 27 -24 34 -24 178 0 82
    2 149 4 149 3 0 44 -13 93 -29z m583 -586 l0 -85 -170 0 -170 0 0 85 0 85 170
    0 170 0 0 -85z m2900 -472 c0 -342 -10 -434 -60 -585 -146 -434 -533 -752
    -985 -807 -141 -17 -967 -14 -1085 4 -128 19 -228 49 -329 96 -282 132 -505
    369 -615 655 -63 164 -76 254 -83 572 l-6 282 92 0 91 0 0 -272 c0 -227 3
    -288 19 -363 78 -379 366 -679 750 -783 96 -26 97 -26 571 -30 323 -2 505 0
    568 8 229 29 409 114 578 274 146 137 235 284 291 479 26 90 26 101 30 435 l5
    342 84 0 84 0 0 -307z"
        />
        <path
          d="M1135 4766 c-87 -14 -212 -54 -278 -88 -243 -123 -426 -359 -494
    -636 -22 -91 -22 -313 0 -404 41 -169 142 -354 241 -446 47 -43 72 -50 109
    -30 65 34 64 88 -4 165 -148 169 -217 390 -190 605 38 293 238 541 512 632
    147 49 274 55 425 21 69 -16 175 -57 171 -66 -1 -4 -65 -101 -142 -217 l-140
    -210 -62 1 c-123 1 -226 -81 -252 -203 -38 -175 124 -336 299 -299 161 35 247
    203 181 355 l-23 52 141 211 c78 116 145 211 150 211 17 0 121 -122 160 -188
    83 -140 123 -324 103 -476 -21 -164 -86 -310 -191 -429 -68 -77 -69 -131 -4
    -165 43 -23 66 -14 128 51 175 185 267 473 235 735 -25 207 -110 387 -256 541
    -67 72 -81 91 -81 119 0 62 -67 102 -124 72 -15 -9 -38 -3 -107 24 -161 65
    -352 88 -507 62z m202 -868 c51 -48 15 -138 -56 -138 -47 0 -81 32 -81 79 0
    72 84 109 137 59z"
        />
      </g>
    </svg>
  );
};
