import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Spin from "antd/lib/spin";
import Row from "antd/lib/row";
import { useQuery } from "react-query";
import moment from "moment";

import { AppTabs } from "components/tabs";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AddBillIcon } from "./AddBillIcon";
import { DraftTabList } from "./draftTabList";
import { createApiClient } from "apiClient";
import { ApprovedTabList } from "./approvedTabList";
import { UserContext, UserContextState } from "contextApis/userContext";
import { AllBillsList } from "sharedTypes";
import { useParams } from "react-router";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { AppCard } from "components/card";
import get from "lodash/get";
import { BillingAlertModal } from "./BillingAlertModal";
import { Colors } from "helpers/colors";
import { AppModal } from "components/modal";
import { Tabs } from "antd";
import { CallRecord, CallRecordList } from "pages/callRecords/CallRecordList";
import {
  MeetingContextState,
  MeetingModalContext,
  sessionStatusValues
} from "contextApis/meetingModalContext";
import { SelectedCallRecordDraft } from "pages/callRecords/callRecords";
import { customRound } from "helpers/utils";
import { RpmDetailTable } from "./rpmDetailTable";

enum BillingTypes {
  Draft = "draft",
  Approved = "approved"
}

const draft = "Draft";
const approved = "Approve";

export const BillingTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<BillingTypes>(
    BillingTypes.Draft
  );
  const [modifiedResponse, setModifiedResponse] = useState<AllBillsList[]>([]);

  const [showAddBill, setShowAddBill] = useState(false);
  const [showEditBill, setShowEditBill] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showBillingAlertModal, setBillingAlertModal] = useState(false);
  const userContext = useContext<UserContextState>(UserContext);
  const [callRecordList, setCallRecordList] = useState<CallRecord[]>();
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const [selectedDraft, setSelectedDraft] = useState<SelectedCallRecordDraft>();
  const [changedDraft, setChangedDraft] = useState(false);
  const [showAddEditCallRecords, setShowAddEditCallRecords] = useState("");
  const { id } = useParams();

  const getSelectedTab = (key: BillingTypes) => {
    setSelectedTab(key);
  };

  const [billableModal, setBillableModal] = useState<boolean>(false);

  const handleBillableToggle = () => {
    setBillableModal(!billableModal);
  };

  const providerId = get(userContext, "userData.internal_id", "");

  const {
    data: billingList,
    error: isBillingListError,
    refetch: getBillingList,
    isFetching: isBillingListRefetchLoading
  } = useQuery<AllBillsList[]>(
    "all-bills-list",
    async () => {
      return await createApiClient().getAllBillsList(
        id ? id : "",
        providerId,
        true
      );
    },
    {
      enabled: false,
      onSuccess: data => {
        if (data) {
          setLoading(false);
          setModifiedResponse(
            data.map<AllBillsList>(item => {
              const tempItem: any = item;
              tempItem.dateOfService = item.dateOfService;
              return tempItem;
            })
          );
        }
      }
    }
  );

  const {
    isLoading,
    error,
    isFetching: isFetchingCallRecords
  } = useQuery<CallRecord[]>(
    ["call-records", id],
    async () => {
      return await createApiClient().getCallRecords(id ? id : "", true);
    },
    {
      onSuccess: successData => {
        const tempData: {
          id: number;
          desc: string;
          notes: string;
          call_duration: number;
          prv_name: string;
          date_p: string;
          call_type: string;
          status: string;
          provider_internal_id: number | string;
        }[] = [...successData];

        tempData.map(item => {
          item.date_p = moment(moment.utc(item.date_p).toDate()).format(
            "MMMM DD, YYYY hh:mm A"
          );
          item.call_duration = customRound(item.call_duration);
          return item;
        });
        setCallRecordList(tempData);
        if (meetingContext.callRecordId) {
          meetingContext.setSessionStatus(sessionStatusValues.NOTSTARTED);
          const previouslySelectedDraft = tempData?.find(
            item => String(item.id) === meetingContext.callRecordId
          );
          const tempDraft = {
            id: previouslySelectedDraft?.id || 0,
            date: previouslySelectedDraft?.date_p || "",
            itemsDiscussed: previouslySelectedDraft?.desc || "",
            duration: previouslySelectedDraft?.call_duration || 0,
            caller: "",
            other: previouslySelectedDraft?.notes || "",
            callType: previouslySelectedDraft?.call_type || ""
          };
          meetingContext.setCallRecordId("");
          setSelectedDraft(tempDraft);
        }
      }
    }
  );

  const { data: callRecordNotesData, isLoading: isLoadingCallRecordNotes } =
    useQuery("call-record-notes", async () => {
      return await createApiClient().getCallRecordNotes();
    });

  useEffect(() => {
    if (providerId) {
      getBillingList();
    }
  }, [providerId]);

  const tabsContent = [
    {
      key: BillingTypes.Draft,
      tabName: "Draft Bills"
    },
    {
      key: BillingTypes.Approved,
      tabName: "Billing History"
    }
  ];

  useEffect(() => {
    if (selectedTab === "approved") {
      setShowEditBill(false);
      setShowAddBill(false);
    }
  }, [selectedTab]);

  const billingListDraftData = modifiedResponse?.filter(item => {
    return item.status === draft;
  });

  const billingListApprovedData = modifiedResponse?.filter(item => {
    return item.status === approved || draft;
  });

  const enableBilling =
    userContext.userData?.billing_permission === "Y" ? true : false;

  return (
    <>
      <div style={{ position: "relative" }}>
        <div>
          <AppTabs
            defaultActiveKey={selectedTab}
            tabsContent={tabsContent}
            tabPosition="top"
            getSelectedTab={getSelectedTab}
            activeKey={selectedTab}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 20
          }}
        >
          {billingList && (
            <>
              <AppButton
                type={ButtonType.Skeleton}
                style={
                  !enableBilling
                    ? {
                        backgroundColor: Colors.Gainsboro,
                        color: Colors.LightGrey,
                        borderColor: Colors.Gainsboro
                      }
                    : {}
                }
                buttonContent={
                  <ButtonRow onClick={handleBillableToggle}>
                    <AddBillIcon
                      fill={
                        !enableBilling ? Colors.LightGrey : Colors.DodgerBlue
                      }
                    />
                    <ButtonText>Billing Details</ButtonText>
                  </ButtonRow>
                }
              />
              <span style={{ marginLeft: 20 }}>
                <AppButton
                  type={ButtonType.Skeleton}
                  style={
                    !enableBilling
                      ? {
                          backgroundColor: Colors.Gainsboro,
                          color: Colors.LightGrey,
                          borderColor: Colors.Gainsboro
                        }
                      : {}
                  }
                  buttonContent={
                    <ButtonRow>
                      <AddBillIcon
                        fill={
                          !enableBilling ? Colors.LightGrey : Colors.DodgerBlue
                        }
                      />
                      <ButtonText>Add New Bills</ButtonText>
                    </ButtonRow>
                  }
                  onClick={() => {
                    if (enableBilling) {
                      setShowAddBill(true);
                      setShowEditBill(false);
                      setSelectedTab(BillingTypes.Draft);
                    } else {
                      setBillingAlertModal(true);
                    }
                  }}
                />
              </span>
            </>
          )}
        </div>
        {selectedTab === BillingTypes.Draft && (
          <>
            {isBillingListError ? (
              <CommonErrorMessage message="There was a problem loading the billing list" />
            ) : (
              <DraftTabList
                data={billingListDraftData ? billingListDraftData : []}
                showAddBill={showAddBill}
                setShowEditBill={setShowEditBill}
                showEditBill={showEditBill}
                setShowAddBill={setShowAddBill}
                getBillingList={getBillingList}
                loading={loading || isBillingListRefetchLoading}
                latestApprovedBill={
                  billingListApprovedData && billingListApprovedData[0]
                }
                enableBilling={enableBilling}
              />
            )}
          </>
        )}
        {selectedTab === BillingTypes.Approved && (
          <>
            {isBillingListError ? (
              <CommonErrorMessage message="There was a problem loading the billing list" />
            ) : loading || isBillingListRefetchLoading ? (
              <AppCard
                cardHeight="100%"
                cardWidth="100%"
                style={{
                  borderRadius: "0px 0px 8px 8px",
                  marginTop: "-2px",
                  display: "grid",
                  placeItems: "center"
                }}
              >
                <Spin />
              </AppCard>
            ) : (
              <ApprovedTabList
                data={billingListApprovedData ? billingListApprovedData : []}
              />
            )}
          </>
        )}
        {showBillingAlertModal && (
          <BillingAlertModal
            visible={showBillingAlertModal}
            title="You are not authorized for billing"
            closeModal={() => setBillingAlertModal(false)}
          />
        )}
      </div>
      <AppModal
        title="Billable   "
        visible={billableModal}
        width="85%"
        footer={false}
        onCancel={handleBillableToggle}
      >
        <Tabs defaultActiveKey="2" size="small" type="card">
          <Tabs.TabPane tab="Call Records" key="1">
            <CallRecordList
              setChangedDraft={setChangedDraft}
              data={callRecordList ? callRecordList : null}
              isLoading={
                isLoading ||
                isFetchingCallRecords ||
                isLoadingCallRecordNotes ||
                !userContext.userData
              }
              errorMessage={
                error
                  ? "There was an error in fetching the call record list"
                  : ""
              }
              setSelectedDraft={setSelectedDraft}
              setShowAddEditCallRecords={setShowAddEditCallRecords}
              selectedDraft={selectedDraft ? selectedDraft : null}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="RPM Details" key="2">
            <RpmDetailTable />
          </Tabs.TabPane>
        </Tabs>
      </AppModal>
    </>
  );
};

const ButtonRow = styled(Row)`
  svg {
    margin-top: 2px;
  }
`;

const ButtonText = styled.span`
  margin-left: 5px;
`;
