import React from "react";
import { AddMedicationForm } from "./AddMedicationForm";
import { DisContinuedMedication } from "./discontinuedMedication";

import { MedicationPages } from "./medication";
import { ActiveMedication } from "./activeMedication";
import { MedicationHistoryTable } from "./medicationHistoryTable";
import EditMedication from "./editMedication";
import { ActiveMedicineData, MedicationData } from "sharedTypes";
import { MedicationDetails } from "./medicationDetails";

interface SwitchCaseProps {
  medicationDetailsPage: MedicationPages;
  onFormSubmit: (formData: MedicationData, isEdit?: boolean) => void;
  onMedicationDetailsPageChange: (page: MedicationPages) => void;
  readonly isLoadingReasonsList: boolean;
  readonly selectedMedicationForHistory: ActiveMedicineData | null;
  readonly onSelectedMedicationforHistoryChange: (
    value: ActiveMedicineData | null
  ) => void;
  readonly searchValue: string;
  setSelectedReasons(reasons: string): void;
  readonly selectedReasons: string;
  readonly medicationAddedToggle: boolean;
  readonly onDiscontinueMedication: (
    patientId: string | number,
    medicineId: string,
    reasons: { id: number | string; reason: string }[]
  ) => void;
  readonly onDeleteMedication: (
    patientId: string | number,
    medicineId: string
  ) => void;
}

const SwitchMedicationComponent: React.FC<SwitchCaseProps> = ({
  medicationDetailsPage,
  onMedicationDetailsPageChange,
  onFormSubmit,
  selectedMedicationForHistory,
  onSelectedMedicationforHistoryChange,
  searchValue,
  setSelectedReasons,
  selectedReasons,
  medicationAddedToggle,
  onDiscontinueMedication,
  onDeleteMedication
}) => {
  switch (medicationDetailsPage) {
    case MedicationPages.DiscontinuedMedication:
      return <DisContinuedMedication searchValue={searchValue} />;

    case MedicationPages.AddMedicationForm:
      return (
        <AddMedicationForm
          onFormSubmit={onFormSubmit}
          onMedicationDetailsPageChange={onMedicationDetailsPageChange}
        />
      );

    case MedicationPages.MedicationHistory:
      return (
        <MedicationHistoryTable
          selectedMedicationForHistory={selectedMedicationForHistory}
        />
      );

    case MedicationPages.EditMedication:
      return (
        <EditMedication
          selectedMedication={selectedMedicationForHistory}
          onFormSubmit={onFormSubmit}
          onDiscontinueMedication={onDiscontinueMedication}
          onDeleteMedication={onDeleteMedication}
        />
      );
    case MedicationPages.MedicineDetails:
      return (
        <MedicationDetails selectedMedication={selectedMedicationForHistory} />
      );
    default:
      return (
        <ActiveMedication
          onMedicationDetailsPageChange={onMedicationDetailsPageChange}
          onSelectedMedicationforHistoryChange={
            onSelectedMedicationforHistoryChange
          }
          searchValue={searchValue}
          setSelectedReasons={setSelectedReasons}
          selectedReasons={selectedReasons}
          medicationAddedToggle={medicationAddedToggle}
        />
      );
  }
};

export default SwitchMedicationComponent;
