import { Button, Dropdown, MenuProps, Result, Space, Table } from "antd";
import React from "react";
import styled from "styled-components";
import { UserRoles } from "constants/roles";

import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { MessageRow } from "components/dropDownBox/customStyles";
import { AppCard } from "components/card";
import { DownOutlined } from "@ant-design/icons";
import { height } from "styled-system";

export const CarePlanPage: React.FC = () => {
  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const showTopbarExtesion = !userIsPatient;

  const dataSource = [
    {
      sno: "1",
      pi: "Attend smoking cessation workshop",
      dp: "Apr 01, 2023",
      cbo: "Indiana Care One - (800) 158-6764",
      options: "NO"
    },
    {
      sno: "2",
      pi: "Attend alcohol use cessation workshop",
      dp: "Apr 01, 2023",
      cbo: "Indiana Care One - (800) 158-6764",
      options: "NO"
    },
    {
      sno: "3",
      pi: "Attend smoking cessation workshop",
      dp: "May 01, 2023",
      cbo: "Indiana Care One - (800) 158-6764",
      options: "YES"
    },
    {
      sno: "4",
      pi: "Attend alcohol use cessation workshop",
      dp: "May 01, 2023",
      cbo: "Indiana Care One - (800) 158-6764",
      options: "YES"
    },
    {
      sno: "5",
      pi: "Attend smoking cessation workshop",
      dp: "Jun 01, 2023",
      cbo: "Indiana Care One - (800) 158-6764",
      options: "YES"
    },
    {
      sno: "6",
      pi: "Attend alcohol use cessation workshop",
      dp: "Jun 01, 2023",
      cbo: "Indiana Care One - (800) 158-6764",
      options: "YES"
    },
    {
      sno: "7",
      pi: "Attend smoking cessation workshop",
      dp: "Jun 01, 2023",
      cbo: "Indiana Care One - (800) 158-6764",
      options: "YES"
    },
    {
      sno: "8",
      pi: "Attend alcohol use cessation workshop",
      dp: "Jun 01, 2023",
      cbo: "Indiana Care One - (800) 158-6764",
      options: "YES"
    },
    {
      sno: "9",
      pi: "Attend smoking cessation workshop",
      dp: "Jun 01, 2023",
      cbo: "Indiana Care One - (800) 158-6764",
      options: "YES"
    }
  ];

  const items: MenuProps["items"] = [
    {
      label: "YES",
      key: "1"
    },
    {
      label: "NO",
      key: "2"
    }
  ];
  const menuProps = {
    items
  };
  const columns = [
    {
      dataIndex: "sno",
      key: "sno",
      title: "S.No",
      width: "10%"
    },
    {
      dataIndex: "pi",
      key: "pi",
      title: "Planned Intervention",
      width: "30%"
    },
    {
      dataIndex: "dp",
      key: "dp",
      title: "Planned Date Planned",
      width: "20%"
    },
    {
      dataIndex: "cbo",
      key: "cbo",
      title: "CBO Partner",
      width: "25%"
    },
    {
      dataIndex: "options",
      key: "options",
      title: "Action",
      width: "20%"
    }
  ];

  return (
    <>
      {showTopbarExtesion && <CommonPatientExtensionItems />}
      <PageTitle>Social Interventions</PageTitle>
      <div style={{ position: "relative" }}>
        <AppCard
          cardHeight="inherit"
          cardWidth="100%"
          style={{
            borderRadius: "0px 0px 8px 8px",
            marginTop: "16px"
          }}
        >
          <Table
            columns={columns}
            rowKey="id"
            pagination={false}
            dataSource={dataSource}
          />
        </AppCard>
      </div>
    </>
  );
};

export const PageTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 18px;
  padding-top: 18px;
`;
