import Skeleton from "antd/lib/skeleton";
import Divider from "antd/lib/divider";
import React, { useContext } from "react";
import { AppCard } from "components/card";
import {
  CallRecordDesctiptionContainer,
  CallRecordItemGridContainer,
  CallRecordItemHeaderContainer,
  CallRecordItemHeaderText,
  CallRecordProviderNameContainer,
  EmptyDataText,
  ErrorMessage,
  OverflowCallRecordCardContainer
} from "./style";
import { Colors } from "helpers/colors";
import { CallTypes } from "./callRecords";
import {
  MeetingContextState,
  MeetingModalContext,
  sessionStatusValues
} from "contextApis/meetingModalContext";

export interface CallRecord {
  readonly id: number;
  readonly desc: string;
  readonly notes: string;
  readonly call_duration: number;
  readonly prv_name: string;
  readonly date_p: string;
  readonly call_type: string;
  readonly status: string;
  readonly provider_internal_id: number | string;
}

interface CallRecordListProps {
  readonly data: CallRecord[] | null;
  readonly isLoading: boolean;
  readonly errorMessage: string;
  setSelectedDraft(
    item:
      | {
          id: number;
          date: string;
          itemsDiscussed: string;
          duration: number;
          caller: string;
          other: string;
          callType: string;
        }
      | undefined
  ): void;
  setShowAddEditCallRecords(type: string): void;
  readonly selectedDraft: {
    id: number;
    date: string;
    itemsDiscussed: string;
    duration: number;
    caller: string;
    other: string;
    callType: string;
  } | null;
  setChangedDraft: (changed: boolean) => void;
}

export const CallRecordList: React.FC<CallRecordListProps> = ({
  data,
  isLoading,
  errorMessage,
  setSelectedDraft,
  setShowAddEditCallRecords,
  selectedDraft,
  setChangedDraft
}) => {
  const sortingScheme = ["ACTIVE DRAFT", "DRAFT", "COMPLETED"];
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

  function compare(a: any, b: any) {
    const indexOfa = sortingScheme.indexOf(a.status);
    const indexOfb = sortingScheme.indexOf(b.status);

    if (indexOfa > indexOfb) {
      return 1;
    }
    if (indexOfa < indexOfb) {
      return -1;
    }
    return 0;
  }

  const checkActiveDraft = (
    call_duration: number,
    status: string,
    call_type: string
  ) => {
    if (
      !call_duration &&
      status === "DRAFT" &&
      call_type !== CallTypes.MANUAL
    ) {
      return true;
    }
    return false;
  };

  return (
    <AppCard cardHeight="100%" cardWidth="100%">
      <OverflowCallRecordCardContainer>
        {isLoading ? (
          <>
            <Skeleton loading={isLoading} active={isLoading} />
            <Divider />
            <Skeleton loading={isLoading} active={isLoading} />
          </>
        ) : errorMessage ? (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        ) : data && data.length <= 0 ? (
          <EmptyDataText>No Data</EmptyDataText>
        ) : (
          data?.sort(compare).map((record, index) => {
            const userIdMatch =
              sessionStorage.getItem("userId") ===
              String(record.provider_internal_id);

            const selectableForUser =
              (!selectedDraft || selectedDraft.id !== record.id) &&
              record.status !== "COMPLETED" &&
              userIdMatch;

            const minutesValue = record.call_duration / 60;
            const fullMinutes = Math.floor(minutesValue);
            const seconds = Math.round((minutesValue - fullMinutes) * 60);
            const formattedDuration =
              seconds > 0 ? `${fullMinutes}.${seconds}` : `${fullMinutes}`;

            return (
              <div
                key={index}
                data-testid="call-record-list-item"
                onClick={() => {
                  //If selected draft is already selected do not allow second click
                  if (selectableForUser) {
                    if (
                      meetingContext.sessionStatus ===
                      sessionStatusValues.STARTED
                    ) {
                      if (
                        checkActiveDraft(
                          record.call_duration,
                          record.status,
                          record.call_type
                        )
                      ) {
                        if (selectedDraft) {
                          meetingContext.setCallRecordId(String(record.id));
                        } else {
                          setSelectedDraft({
                            id: record.id,
                            date: record.date_p,
                            itemsDiscussed: record.desc,
                            duration: record.call_duration,
                            caller: record.prv_name,
                            other: record.notes,
                            callType: record.call_type
                          });
                        }
                        setShowAddEditCallRecords("edit");
                      }
                    } else {
                      //If selected draft exists, set changedDraft to enable auto save in EditCallRecord
                      //set callRecordId which will allow setSelectedDraft from getCallRecords call in callRecords.tsx onSuccess
                      if (selectedDraft) {
                        setChangedDraft(true);
                        meetingContext.setCallRecordId(String(record.id));
                      } else {
                        setSelectedDraft({
                          id: record.id,
                          date: record.date_p,
                          itemsDiscussed: record.desc,
                          duration: record.call_duration,
                          caller: record.prv_name,
                          other: record.notes,
                          callType: record.call_type
                        });
                      }
                      setShowAddEditCallRecords("edit");
                    }
                  }
                }}
                style={
                  selectedDraft?.id === record.id
                    ? {
                        background: Colors.BabyBlue,
                        borderRadius: "5px",
                        padding: "5px 5px 0px 5px"
                      }
                    : (meetingContext.sessionStatus ===
                        sessionStatusValues.STARTED &&
                        !checkActiveDraft(
                          record.call_duration,
                          record.status,
                          record.call_type
                        )) ||
                      !selectableForUser
                    ? {
                        background: "#E1E1E1",
                        borderRadius: "5px",
                        padding: "5px 5px 0px 5px",
                        pointerEvents: "none"
                      }
                    : {}
                }
              >
                <CallRecordItemHeaderContainer>
                  <CallRecordItemHeaderText>
                    {record.date_p}
                  </CallRecordItemHeaderText>
                  <CallRecordItemHeaderText>
                    {/* <span style={{ color: Colors.Mantis }}>
                      {editableForUser && "EDITABLE"}
                    </span>{" "} */}
                    <span style={{ color: Colors.DodgerBlue }}>
                      {checkActiveDraft(
                        record.call_duration,
                        record.status,
                        record.call_type
                      ) ? (
                        <span style={{ color: Colors.Mantis }}>
                          ACTIVE DRAFT
                        </span>
                      ) : record.status.toLowerCase().replace(/\s/g, "") ===
                        "draft" ? (
                        "DRAFT"
                      ) : (
                        ""
                      )}
                    </span>{" "}
                    {formattedDuration} min
                  </CallRecordItemHeaderText>
                </CallRecordItemHeaderContainer>
                <CallRecordItemGridContainer>
                  <div>
                    <div>Items Discussed:</div>
                    {record.desc.split(/<br>/).map((item, index) => (
                      <div key={index}>{item}</div>
                    ))}
                  </div>
                  <CallRecordDesctiptionContainer>
                    {record.notes}
                  </CallRecordDesctiptionContainer>
                </CallRecordItemGridContainer>
                <CallRecordProviderNameContainer>
                  {record.prv_name}
                </CallRecordProviderNameContainer>
                {index < data.length - 1 && <Divider />}
              </div>
            );
          })
        )}
      </OverflowCallRecordCardContainer>
    </AppCard>
  );
};
