import Space from "antd/lib/space";
import Skeleton from "antd/lib/skeleton";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppAvatar } from "components/avatar";

import { AppCard } from "components/card";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { AppSelect } from "components/inputs";
import { AppModal } from "components/modal";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { Colors } from "helpers/colors";
import { AllProviderList, UserNetworkList } from "sharedTypes";

import { isArray, isEmpty } from "lodash";
import { useDebounce } from "helpers/customHooks";
import { SearchBox } from "components/searchBox";

interface AppProviderModalProps {
  readonly visible: boolean;
  onCancel(): void;
  getSelectedProviders(selectedProviders: AllProviderList[]): void;
  readonly existingProviders: UserNetworkList[];
}

export const AddProviderModal: React.FC<AppProviderModalProps> = ({
  visible,
  onCancel,
  getSelectedProviders,
  existingProviders
}) => {
  const [selectedProviders, setSelectedProviders] = useState<AllProviderList[]>(
    []
  );

  const [showOneProviderWarning, setShowOneProviderWarning] = useState(false);
  const [searchNameValue, setSearchNameValue] = useState("");
  const [searchSpecialtyValue, setSearchSpecialtyValue] = useState("");
  const [filterType, setFilterType] = useState("Name");
  const [allProviders, setAllProviders] = useState<AllProviderList[]>();
  const patientContext = useContext<PatientContextState>(PatientContext);
  const debouncedNameValue = useDebounce(searchNameValue);
  const debouncedSpecialtyValue = useDebounce(searchSpecialtyValue);

  const { id } = useParams();

  useEffect(() => {
    if (selectedProviders.length > 0) {
      setShowOneProviderWarning(false);
    }
  }, [selectedProviders]);

  const {
    isLoading: isLoadingProvidersList,
    data: providersList,
    error: isProvidersListError,
    isFetching: isProvidersListRefetchLoading
  } = useQuery<AllProviderList[]>(
    ["all-providers-list", debouncedNameValue, debouncedSpecialtyValue],
    async () => {
      return await createApiClient().getAllProvidersList(
        id ? id : "",
        searchNameValue,
        searchSpecialtyValue,
        true
      );
    },
    {
      onSuccess: data => {
        if (!searchNameValue && !searchSpecialtyValue) {
          setAllProviders(data);
        }
      }
    }
  );

  const filterOptions: { label: string; value: string }[] = [
    {
      label: "Name",
      value: "Name"
    },
    {
      label: "Specialty",
      value: "Specialty"
    }
  ];

  const selected = (id: string | number) => {
    let found = false;
    for (let i = 0; i < selectedProviders.length; i++) {
      if (selectedProviders[i].id === id) {
        found = true;
        break;
      }
    }
    return found;
  };

  const addProvider = () => {
    if (selectedProviders.length === 0) {
      setShowOneProviderWarning(true);
    } else {
      setSelectedProviders([]);
      //existingProviders here refers to the table data which posseses the networks_id information

      let updatedProvidersList = allProviders?.filter(allProviderItem => {
        return existingProviders
          .map(item => item.internal_id)
          .includes(allProviderItem.internal_id);
      });

      existingProviders.forEach(item => {
        if (item.user_type === "caregiver") {
          updatedProvidersList?.push({
            activated: item.activated,
            alert_receiver: item.alert_receiver,
            degree: "",
            external_id: item.external_id,
            first_name: "",
            grp: "",
            id: item.id,
            internal_id: item.internal_id,
            last_name: "",
            name: item.name,
            networks_id: item.networks_id,
            username: item.username,
            picture: "",
            remote_monitoring: "",
            role: item.role,
            specialty: ""
          });
        }
      });

      updatedProvidersList =
        updatedProvidersList?.concat(selectedProviders) || [];

      const existingProviderIDs = existingProviders.map(item => item.id);

      updatedProvidersList.map(item => {
        if (existingProviderIDs.includes(item.id)) {
          item.networks_id =
            existingProviders[existingProviderIDs.indexOf(item.id)].networks_id;
        }
        return item;
      });

      getSelectedProviders(updatedProvidersList);
    }
  };

  const filteredProvidersList = isArray(providersList)
    ? providersList?.filter(item => {
        const x = !existingProviders.map(item => item.id).includes(item.id);
        return !existingProviders.map(item => item.id).includes(item.id);
      })
    : [];

  filteredProvidersList?.sort(function (currentProvider, nextProvider) {
    const [currentProviderFirstName, currentProviderLastName] =
      currentProvider.name.split(/\s+/);
    const [nextProviderFirstName, nextProviderLastName] =
      nextProvider.name.split(/\s+/);

    const result = currentProviderLastName.localeCompare(nextProviderLastName);

    return result !== 0
      ? result
      : currentProviderFirstName.localeCompare(nextProviderFirstName);
  });

  return (
    <AppModal
      visible={visible}
      title={`Add Provider(s) for ${
        patientContext.patientData?.first_name +
        " " +
        patientContext.patientData?.last_name
      }`}
      okText="Add"
      cancelText="Cancel"
      onCancel={() => {
        setSelectedProviders([]);
        onCancel();
      }}
      onOk={() => addProvider()}
      width="820px"
    >
      <div
        style={{
          paddingLeft: "10px",
          paddingRight: "10px"
        }}
      >
        <Space>
          <AppSelect
            value={filterType.charAt(0).toUpperCase() + filterType.slice(1)}
            label="Filter By"
            onChange={type => {
              setFilterType(type);
              setSearchSpecialtyValue("");
              setSearchNameValue("");
            }}
            style={{ marginRight: 5, width: "120px" }}
            options={filterOptions}
            dropdownStyle={{ borderRadius: "8px" }}
          />
          <SearchBox
            placeholder={`Search by ${filterType}`}
            value={searchSpecialtyValue || searchNameValue}
            onChange={e => {
              if (filterType === "Name") {
                setSearchSpecialtyValue("");
                setSearchNameValue(e.target.value);
              }
              if (filterType === "Specialty") {
                setSearchNameValue("");
                setSearchSpecialtyValue(e.target.value);
              }
            }}
          />
        </Space>
        <AppCard
          cardHeight="100%"
          cardWidth="100%"
          style={{ marginTop: "12px", height: "420px", overflowY: "scroll" }}
        >
          <RowStyled gutter={[30, 5]} tabIndex={0}>
            <Col span={24}>
              {showOneProviderWarning && (
                <CommonErrorMessage
                  style={{ padding: "0px" }}
                  message="Please select atleast one Provider"
                />
              )}
              <CardTitle>Select Provider</CardTitle>
            </Col>

            {/* Needs to be adapted once live data is coming in */}
            {isProvidersListError && (
              <CommonErrorMessage message="There was an error fetching the providers list" />
            )}
            {isLoadingProvidersList || isProvidersListRefetchLoading ? (
              <Skeleton />
            ) : (
              <>
                {isEmpty(filteredProvidersList) &&
                (searchNameValue || searchSpecialtyValue) ? (
                  <EmptyDataText>No Matching Data</EmptyDataText>
                ) : isEmpty(filteredProvidersList) ? (
                  <EmptyDataText>No Data</EmptyDataText>
                ) : (
                  filteredProvidersList?.map((data, index) => {
                    const providerDetails = { ...data };
                    if (
                      !providerDetails.first_name &&
                      !providerDetails.last_name
                    ) {
                      const [
                        currentProviderFirstName,
                        currentProviderLastName
                      ] = providerDetails.name.split(/\s+/);
                      providerDetails.first_name = currentProviderFirstName;
                      providerDetails.last_name = currentProviderLastName;
                    }
                    return (
                      <ProviderCol
                        span={8}
                        key={index}
                        tabIndex={index}
                        onClick={() => {
                          let temp = [...selectedProviders];
                          if (selected(providerDetails.id)) {
                            temp = temp.filter(function (item) {
                              return item.id !== data.id;
                            });
                            if (temp.length === 0) {
                              temp = [];
                            }
                          } else {
                            temp.push({
                              first_name: providerDetails.first_name,
                              activated: providerDetails.activated,
                              alert_receiver: providerDetails.alert_receiver,
                              degree: providerDetails.degree,
                              external_id: providerDetails.external_id,
                              grp: providerDetails.grp,
                              id: providerDetails.id,
                              internal_id: providerDetails.internal_id,
                              last_name: providerDetails.last_name,
                              name: providerDetails.name,
                              remote_monitoring:
                                providerDetails.remote_monitoring,
                              role: providerDetails.role,
                              specialty: providerDetails.specialty,
                              username: providerDetails.username,
                              picture: providerDetails.picture
                            });
                          }

                          setSelectedProviders(temp);
                        }}
                      >
                        <AvatarContainer
                          style={
                            selected(data.id)
                              ? { backgroundColor: Colors.WillowBrook }
                              : {}
                          }
                        >
                          <AppAvatar
                            imageSrc={data.picture}
                            size="large"
                            mainContent={
                              providerDetails.last_name.trim() +
                              ", " +
                              providerDetails.first_name.trim() +
                              " " +
                              providerDetails.degree
                            }
                            subText={providerDetails.specialty}
                            additionalNote={providerDetails.grp}
                            leftColWidth="15%"
                            rightColWidth="85%"
                          />
                        </AvatarContainer>
                      </ProviderCol>
                    );
                  })
                )}
              </>
            )}
          </RowStyled>
        </AppCard>
      </div>
    </AppModal>
  );
};

const AvatarContainer = styled.div`
  cursor: pointer;
  border-radius: 8px;
  outline: none;
  width: 100%;
  padding-left: 10px;

  :hover {
    background-color: ${Colors.BabyBlue};
  }
  :active {
    background-color: ${Colors.BabyBlue};
  }
  :focus {
    background-color: ${Colors.BabyBlue};
  }
`;

const ProviderCol = styled(Col)`
  :focus {
    outline: none;
  }
`;

const RowStyled = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
`;

const CardTitle = styled.div`
  font-size: 14px;
  font-family: Century Gothic Bold;
  margin-bottom: 10px;
`;

export const EmptyDataText = styled.div`
  display: grid;
  place-items: center;
  margin-top: 100px;
  width: 100%;
  font-size: 16px;
`;
