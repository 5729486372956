import React from "react";
import styled from "styled-components";
import { Colors } from "helpers/colors";
import { AppCard } from "components/card";
import { TableFilters } from "./tableFilters";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import Table, { ColumnsType } from "antd/lib/table";
import Space from "antd/lib/space";
import { FilterDisplay } from "components/filterDisplay";
import { ArrowUpwardIcon } from "./ArrowUpwardIcon";
import { ArrowDownwardIcon } from "./ArrowDownwardIcon";
import { WeightVitals } from "sharedTypes";
import moment from "moment";

interface WeightTableProps {
  readonly data: WeightVitals[];
  readonly loading: boolean;
  readonly filter: string;
  setTypeFilter(type: string): void;
  readonly typeFilter: string;
  setShowVitalsCharts(type: string): void;
  readonly selectedTab: string;
}

export const WeightTable: React.FC<WeightTableProps> = ({
  data,
  loading,
  filter,
  setTypeFilter,
  typeFilter,
  setShowVitalsCharts,
  selectedTab
}) => {
  const updatedData = data.map(val => {
    if (val.ch_lb && !val.ch_kg) {
      return {
        ...val,
        ch_kg: (+val.ch_lb / 2.205).toFixed(0)
      };
    } else if (val.ch_kg && !val.ch_lb) {
      return {
        ...val,
        ch_lb: (+val.ch_kg * 2.205).toFixed(0)
      };
    } else {
      return val;
    }
  });

  const sortedWeightData = updatedData.sort(
    (a, b) => Date.parse(b.weight_taken_on) - Date.parse(a.weight_taken_on)
  );

  const convertUTCDateToLocal = (dateString: any) => {
    const localDateTime = moment.utc(dateString).local();
    const formattedDateTime = localDateTime.format("MMM/DD/YYYY hh:mm A");
    return formattedDateTime;
  };

  const columns: ColumnsType<WeightVitals> = [
    {
      title: "Date Submitted",
      key: "reported_on",
      dataIndex: "reported_on",
      render: (date: number, record) => {
        return (
          <DateStyle>
            {date ? (
              <DateTimeFormat
                date={convertUTCDateToLocal(date)}
                hideTime={record.reported_by ? true : false}
              />
            ) : (
              <DateTimeFormat date={convertUTCDateToLocal(date)} />
            )}
          </DateStyle>
        );
      },
      width: "20%"
    },
    {
      title: "Date Taken",
      key: "weight_taken_on",
      dataIndex: "weight_taken_on",
      render: (date: number, record) => {
        return (
          <DateStyle>
            <DateTimeFormat
              date={convertUTCDateToLocal(date)}
              hideTime={record.reported_on ? true : false}
            />
          </DateStyle>
        );
      },
      width: "20%"
    },
    {
      title: "Pounds",
      key: "ch_lb",
      dataIndex: "ch_lb",
      width: "12%",
      render: (pounds: string) => <WeightColStyled>{pounds}</WeightColStyled>
    },
    {
      title: "Kg",
      key: "ch_kg",
      dataIndex: "ch_kg",
      width: "12%",
      render: (kg: string) => <WeightColStyled>{kg}</WeightColStyled>
    },
    {
      title: "Delta Kg",
      key: "deltaKg",
      dataIndex: "deltaKg",
      width: "40%",
      render: (deltaKg: string, _, index) => {
        if (index === updatedData.length - 1) {
          //Earliest Record
          return <GroupCol>{"-"}</GroupCol>;
        }

        const valueDifference =
          Number(updatedData[index].ch_kg) -
          Number(updatedData[index + 1].ch_kg);
        if (!updatedData[index + 1].ch_kg) {
          //Comparison value not available
          return <GroupCol>{"-"}</GroupCol>;
        }
        if (updatedData[index].ch_kg) {
          return (
            <GroupCol>
              <>
                {valueDifference === 0 ? null : valueDifference > 0 ? (
                  <ArrowUpwardIcon />
                ) : (
                  <ArrowDownwardIcon />
                )}
                {valueDifference.toFixed(2)}
              </>
            </GroupCol>
          );
        } else {
          //Value not available
          return "-";
        }
      }
    }
  ];
  return (
    <AppCard
      cardHeight="fit-content"
      cardWidth="100%"
      style={{
        borderRadius: "0px 0px 8px 8px",
        marginTop: "-2px"
      }}
    >
      <FilterWrapper>
        <FilterDisplay value1={`${filter}`} />
      </FilterWrapper>
      <TableFilters
        setTypeFilter={setTypeFilter}
        typeFilter={typeFilter}
        setShowVitalsCharts={setShowVitalsCharts}
        selectedTab={selectedTab}
        loading={loading}
      />
      <Table<WeightVitals>
        columns={columns}
        dataSource={sortedWeightData}
        rowKey={record => sortedWeightData.indexOf(record)}
        pagination={false}
        scroll={{ y: "43vh" }}
        size="middle"
        loading={loading}
        data-testid="weight-table"
      />
    </AppCard>
  );
};

const FilterWrapper = styled.div`
  margin-top: -15px;
`;

const WeightColStyled = styled.div`
  font-weight: 800;
  font-size: 14px;
`;

const DateStyle = styled.div`
  font-size: 12px;
  color: ${Colors.Grey};
`;

const GroupCol = styled(Space)`
  font-size: 12px;
  color: ${Colors.Black};
`;
