import React, { useEffect, useMemo, useState } from "react";
import { isArray, isEmpty, sortBy } from "lodash";
import moment from "moment";
import { AppCard } from "components/card";
import { AppSelect } from "components/inputs";
import { SelectOption } from "components/inputs/selectInput";
import { AppLineChart } from "components/lineChart";
import { SeriesData } from "components/lineChart/lineChart";
import { AppModal } from "components/modal";
import { Colors } from "helpers/colors";
import { lowerCaseRemoveSpace } from "helpers/utils";
import { amPMSeriesInterface } from "sharedTypes";
import {
  ChartTitleText,
  EmptyDataText,
  ModalContentContainer,
  SymptomDetailChartContainer,
  SymptomDetailListItemHeader,
  SymptomItemDesctiptionTextContainer,
  SymptomItemDetailKeyText,
  SymptomItemDetailsGridContainer,
  SymptomItemDetailValueText,
  SymptomModalDetailsListContainer,
  SymptomModalDetailsListItem,
  SymptomsTitleText
} from "./styles";
import { SymptomConstants } from "./symptomConstants";
import { achesAndPainMapper } from "./symptomsChartsMappers/achesAndPainMapper";
import { appetiteImpairmentMapper } from "./symptomsChartsMappers/appetiteImpairmentMapper";
import { chestPainMapper } from "./symptomsChartsMappers/chestPainMapper";
import { dialysisMapper } from "./symptomsChartsMappers/dialysisMapper";
import { fallsMapper } from "./symptomsChartsMappers/fallsMapper";
import { fatigueMapper } from "./symptomsChartsMappers/fatigueMapper";
import { feverMapper } from "./symptomsChartsMappers/feverMapper";
import { legSwellingMapper } from "./symptomsChartsMappers/legSwellingMapper";
import { lightheadednessMapper } from "./symptomsChartsMappers/lightheadednessMapper";
import { moodImpairmentMapper } from "./symptomsChartsMappers/moodImpairmentMapper";
import { nauseaMapper } from "./symptomsChartsMappers/nauseaMapper";
import { shortnessOfBreathMapper } from "./symptomsChartsMappers/shortnessOfBreathMapper";
import { ulcersMapper } from "./symptomsChartsMappers/ulcersMapper";
import { vitalSignsMapper } from "./symptomsChartsMappers/vitalSignsMapper";
import { vitalSignsSeriesMapper } from "./symptomsChartsMappers/vitalSignsSeriesMapper";
import { weightChangeMapper } from "./symptomsChartsMappers/weightChangeMapper";
import { Symptom } from "./SymptomsList";

interface SymptomsModalProps {
  readonly isModalOpen: boolean;
  readonly onModalClose: () => void;
  readonly selectedSymptom: string;
  readonly extendedSymptomData: ExtendedSymptomData[];
  readonly timeRange: string;
  readonly onTimeRangeChange: (value: string) => void;
}

export interface ExtendedSymptomData extends Symptom {
  readonly timestamp: string;
  readonly reporterName: string;
  readonly reporterRole: string;
}

const TIME_RANGE_OPTIONS: SelectOption<string>[] = [
  {
    label: "1 Month",
    value: "1"
  },
  {
    label: "3 Months",
    value: "3"
  },
  {
    label: "6 Months",
    value: "6"
  },
  {
    label: "1 Year",
    value: "12"
  },
  {
    label: "All",
    value: "all"
  }
];

export const SymptomsModal: React.FC<SymptomsModalProps> = ({
  isModalOpen,
  onModalClose,
  selectedSymptom,
  extendedSymptomData,
  timeRange,
  onTimeRangeChange
}) => {
  const [selectedSymptomItem, setSelectedSymptomItem] =
    useState<string>(selectedSymptom);
  const [showModalContent, setShowModalContent] = useState(false);

  const selectOptions: SelectOption<string>[] = useMemo(() => {
    const symptomNames = extendedSymptomData.map(dataItem =>
      dataItem.name.trim()
    );
    const uniqueSymptomNames = Array.from(new Set(symptomNames));
    return uniqueSymptomNames.map(name => ({ label: name, value: name }));
  }, [extendedSymptomData]);

  const filteredExtendedSymptomData = useMemo(
    () =>
      extendedSymptomData.filter(
        data => data.name.trim() === selectedSymptomItem.trim()
      ),
    [selectedSymptomItem, extendedSymptomData]
  );

  let mappedData: [string, number, string, string?][][] | string = [];
  let vitalSignsAmPMSeriesData: SeriesData[][];

  switch (selectedSymptomItem.toLowerCase().replace(/\s/g, "")) {
    case SymptomConstants.AchesPain:
      mappedData = achesAndPainMapper({
        filteredExtendedSymptomData: filteredExtendedSymptomData
      });
      break;
    case SymptomConstants.LegSwelling:
      mappedData = legSwellingMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.WeightChange:
      mappedData = weightChangeMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.Fatigue:
      mappedData = fatigueMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.ShortnessOfBreath:
      mappedData = shortnessOfBreathMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.ChestPain:
      mappedData = chestPainMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.Lightheadedness:
      mappedData = lightheadednessMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.Falls:
      mappedData = fallsMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.Fever:
      mappedData = feverMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.Ulcers:
      mappedData = ulcersMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.Nausea:
      mappedData = nauseaMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.MoodImpairment:
      mappedData = moodImpairmentMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.AppetiteImpairment:
      mappedData = appetiteImpairmentMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.Dialysis:
      mappedData = dialysisMapper({ filteredExtendedSymptomData });
      break;
    case SymptomConstants.VitalSigns:
      mappedData = vitalSignsMapper({ filteredExtendedSymptomData });
      vitalSignsAmPMSeriesData = vitalSignsSeriesMapper({
        filteredExtendedSymptomData
      });
      break;
    default:
      break;
  }

  const EmptyModalOKFunc = () => null;

  const onSelectedSymptomItemChange = (value: string) =>
    setSelectedSymptomItem(value);

  useEffect(() => {
    if (selectedSymptomItem) {
      setSelectedSymptomItem(selectedSymptomItem);
    }
    setShowModalContent(false);
    setTimeout(() => {
      setShowModalContent(true);
    }, 0.1);
  }, [selectedSymptomItem]);

  return (
    <AppModal
      footer={false}
      cancelText=""
      okText=""
      onCancel={onModalClose}
      onOk={EmptyModalOKFunc}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: 20
          }}
        >
          <AppSelect
            label="Symptoms"
            onChange={onSelectedSymptomItemChange}
            options={selectOptions}
            value={selectedSymptomItem}
            style={{
              width: 200,
              marginTop: -10,
              fontFamily: "Century Gothic"
            }}
          />
          <AppSelect
            label="Time Range"
            onChange={onTimeRangeChange}
            options={TIME_RANGE_OPTIONS}
            value={timeRange}
            style={{
              width: 200,
              marginTop: -10,
              fontFamily: "Century Gothic"
            }}
          />
        </div>
      }
      visible={isModalOpen}
      width="70vw"
    >
      {isEmpty(filteredExtendedSymptomData) ? (
        <EmptyDataText style={{ height: "70vh" }}>
          <div>No Data</div>
        </EmptyDataText>
      ) : (
        <ModalContentContainer
          style={
            selectedSymptomItem.toLowerCase().replace(/\s/g, "") ===
            SymptomConstants.UrinarySymptoms
              ? { gridTemplateColumns: "1fr 100%", paddingRight: "10px" }
              : {}
          }
        >
          {!showModalContent ? (
            <SymptomDetailChartContainer />
          ) : (
            <SymptomDetailChartContainer>
              {typeof mappedData !== "string" &&
                mappedData.map((items, index) => {
                  if (items[0]) {
                    if (items[0][2] === "AM Systolic") {
                      //The following map function is implemented to update the chart legend as per the current requirement
                      vitalSignsAmPMSeriesData[0].map(
                        (item: {
                          name: string;
                          data: [string | number, number][];
                        }) => {
                          item.name = item.name.replace("AM ", "");
                          return item;
                        }
                      );

                      const sysData = vitalSignsAmPMSeriesData[0].filter(
                        (item: amPMSeriesInterface) =>
                          lowerCaseRemoveSpace(item.name) === "systolic"
                      );

                      const sysValues = sysData[0].data.map(
                        (item: [string | number, number]) => item[1]
                      );

                      let maxYAxis = 240;

                      if (isArray(sysValues)) {
                        maxYAxis = Math.round(Math.max(...sysValues) / 10) * 10;
                      }

                      return (
                        <AppCard
                          cardHeight="100%"
                          cardWidth="96%"
                          title={
                            <ChartTitleText>AM Blood Pressure</ChartTitleText>
                          }
                          key={index}
                        >
                          <AppLineChart
                            chartName={items[0][2]}
                            chartHeight={maxYAxis > 200 ? "250px" : "230px"}
                            seriesData={vitalSignsAmPMSeriesData[0]}
                            selectedSymptom={selectedSymptomItem}
                            multiLineChart
                            showLegend
                            customSeriesTooltip
                            customColors={[Colors.DodgerBlue, Colors.Mantis]}
                            customXAxisDate
                            timeRange={timeRange}
                            tooltipOutside
                            chartMarginRight={5}
                            maxYAxis={maxYAxis}
                            minYAxis={0}
                            yTickInterval={25}
                            yEndOnTick={true}
                          />
                        </AppCard>
                      );
                    }
                    if (items[0][2] === "AM Diastolic") {
                      return null;
                    }
                    if (items[0][2] === "PM Systolic") {
                      vitalSignsAmPMSeriesData[1].map(
                        (item: {
                          name: string;
                          data: [string | number, number][];
                        }) => {
                          item.name = item.name.replace("PM ", "");
                          return item;
                        }
                      );

                      const sysData = vitalSignsAmPMSeriesData[1].filter(
                        (item: amPMSeriesInterface) =>
                          lowerCaseRemoveSpace(item.name) === "systolic"
                      );

                      const sysValues = sysData[0].data.map(
                        (item: [string | number, number]) => item[1]
                      );

                      let maxYAxis = 240;

                      if (isArray(sysValues)) {
                        maxYAxis = Math.round(Math.max(...sysValues) / 10) * 10;
                      }

                      return (
                        <AppCard
                          cardHeight="100%"
                          cardWidth="96%"
                          title={
                            <ChartTitleText>PM Blood Pressure</ChartTitleText>
                          }
                          key={index}
                        >
                          <AppLineChart
                            chartName={items[0][2]}
                            chartHeight={maxYAxis > 200 ? "250px" : "230px"}
                            seriesData={vitalSignsAmPMSeriesData[1]}
                            selectedSymptom={selectedSymptomItem}
                            multiLineChart
                            showLegend
                            customSeriesTooltip
                            customColors={[Colors.DodgerBlue, Colors.Mantis]}
                            customXAxisDate
                            timeRange={timeRange}
                            tooltipOutside
                            chartMarginRight={5}
                            maxYAxis={maxYAxis}
                            minYAxis={0}
                            yTickInterval={25}
                            yEndOnTick={true}
                          />
                        </AppCard>
                      );
                    }
                    if (items[0][2] === "PM Diastolic") {
                      return null;
                    }

                    const isVitalSigns =
                      selectedSymptomItem.toLowerCase().replace(/\s/g, "") ===
                      "vitalsigns";

                    const isWeight =
                      items[0][2].toLowerCase().replace(/\s/g, "") ===
                        "weight(kg)" ||
                      items[0][2].toLowerCase().replace(/\s/g, "") ===
                        "weight(lb)";
                    return (
                      <>
                        <AppCard
                          cardHeight="100%"
                          cardWidth="96%"
                          title={<ChartTitleText>{items[0][2]}</ChartTitleText>}
                          key={index}
                        >
                          <AppLineChart
                            chartName={items[0][2]}
                            chartHeight="140px"
                            customColors={["#05348f"]}
                            data={items}
                            mapperType={items[0][2]}
                            selectedSymptom={selectedSymptomItem}
                            customXAxisDate
                            timeRange={timeRange}
                            multiChoiceTooltip={items[0][3]}
                            maxYAxis={isVitalSigns ? null : 10}
                            minYAxis={0}
                            yTickInterval={
                              isWeight ? 50 : isVitalSigns ? null : 5
                            }
                            yEndOnTick={isWeight ? true : false}
                            tooltipOutside
                            chartMarginRight={5}
                          />
                        </AppCard>
                      </>
                    );
                  }
                })}
            </SymptomDetailChartContainer>
          )}
          <SymptomModalDetailsListContainer
            style={
              selectedSymptomItem.toLowerCase().replace(/\s/g, "") ===
              SymptomConstants.UrinarySymptoms
                ? { borderLeft: "none" }
                : {}
            }
          >
            <SymptomsTitleText>Details</SymptomsTitleText>
            {sortBy(filteredExtendedSymptomData, value =>
              moment(value.timestamp, "MMM DD, YYYY").valueOf()
            )
              .reverse()
              .map((symptom, index) => {
                const symDetailHeader = symptom.reporterRole
                  ? `${symptom.timestamp} | By ${symptom.reporterName} (${symptom.reporterRole})`
                  : `${symptom.timestamp} | By ${symptom.reporterName}`;
                return (
                  <SymptomModalDetailsListItem
                    key={index}
                    showHr={index !== filteredExtendedSymptomData.length - 1}
                  >
                    <SymptomDetailListItemHeader>{`${symDetailHeader}`}</SymptomDetailListItemHeader>
                    {symptom.description ? (
                      <SymptomItemDesctiptionTextContainer>
                        {symptom.description}
                      </SymptomItemDesctiptionTextContainer>
                    ) : (
                      <SymptomItemDetailsGridContainer>
                        {symptom.data?.map(dataItem => (
                          <React.Fragment key={dataItem.key}>
                            <SymptomItemDetailKeyText>{`${dataItem.key} :`}</SymptomItemDetailKeyText>
                            <SymptomItemDetailValueText>
                              {dataItem.value}
                            </SymptomItemDetailValueText>
                          </React.Fragment>
                        ))}
                      </SymptomItemDetailsGridContainer>
                    )}
                  </SymptomModalDetailsListItem>
                );
              })}
          </SymptomModalDetailsListContainer>
        </ModalContentContainer>
      )}
    </AppModal>
  );
};
