import React, { useEffect, useState } from "react";

import Checkbox from "antd/lib/checkbox";
import Space from "antd/lib/space";
import Menu from "antd/lib/menu";

import styled from "styled-components";
import { Colors } from "helpers/colors";

interface CheckboxMenuProps {
  readonly menuItems: {
    desc: string;
    code: string;
    detail_desc: string;
    checked: boolean;
  }[];
  getCheckedItems(
    checkedItems: {
      desc: string;
      code: string;
      detail_desc: string;
      checked: boolean;
    }[]
  ): void;
  readonly applied?: boolean;
  readonly showDropdown?: boolean;
  setApplied(applied: boolean): void;
  readonly allDiagnosisChecked?: boolean;
  setAllChecked(checked: boolean): void;
  readonly allChecked: boolean;
}

export const CareTeamCheckboxMenu: React.FC<CheckboxMenuProps> = ({
  menuItems,
  getCheckedItems,
  showDropdown,
  setApplied,
  setAllChecked,
  allChecked
}) => {
  const [checkedItems, setCheckedItems] = useState<
    { desc: string; code: string; detail_desc: string; checked: boolean }[]
  >([...menuItems]);

  useEffect(() => {
    //Initial assignment
    setCheckedItems(menuItems);
  }, [showDropdown, allChecked]);

  useEffect(() => {
    getCheckedItems(checkedItems);
  }, [checkedItems]);

  const onSelectMenuItem = (item: string) => {
    if (setApplied) {
      setApplied(false);
    }
    const checkedTemp = [...checkedItems];

    const objIndex = checkedItems.findIndex(obj => obj.desc === item);

    checkedTemp[objIndex].checked = !checkedTemp[objIndex].checked;

    if (!checkedTemp[objIndex].checked) {
      setAllChecked(false);
    }

    const allChecked = checkedItems.every(item => item.checked === true);

    if (allChecked && checkedTemp) {
      setAllChecked(true);
    }

    setCheckedItems(checkedTemp);
  };

  const handleSelectAllCheckbox = () => {
    setAllChecked(!allChecked);
    setApplied(false);

    let newMenuOneItems = [...checkedItems];
    if (!allChecked) {
      newMenuOneItems = newMenuOneItems.map(item => {
        item.checked = true;
        return item;
      });
    } else {
      newMenuOneItems = newMenuOneItems.map(item => {
        item.checked = false;
        return item;
      });
    }

    setCheckedItems(newMenuOneItems);
  };

  return (
    <MenuStyled>
      <Menu.Item
        key={"All Care Team"}
        onClick={() => {
          handleSelectAllCheckbox();
        }}
      >
        <Space size={10}>
          <CheckboxStyled checked={allChecked} />
          {allChecked ? (
            <span style={{ fontWeight: "600" }}>All Care Team</span>
          ) : (
            "All Care Team"
          )}
        </Space>
      </Menu.Item>
      {checkedItems.map((item, index) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => {
              onSelectMenuItem(item.desc);
            }}
          >
            <Space size={10}>
              <CheckboxStyled checked={item.checked} />
              {item.checked ? (
                <span style={{ fontWeight: "600" }}>{item.desc}</span>
              ) : (
                item.desc
              )}
            </Space>
          </Menu.Item>
        );
      })}
    </MenuStyled>
  );
};

const CheckboxStyled = styled(Checkbox)`
  pointer-events: none;
`;

const MenuStyled = styled(Menu)`
  width: 265px;
  min-height: 312px;
  padding: 15px 6px 12px 6px;
  color: ${Colors.Grey};
  border-right: none;
  background-color: ${Colors.GhostWhite};

  .ant-menu-item {
    margin-top: -5px !important;
    :hover {
      background-color: ${Colors.BabyBlue};
      border-radius: 10px;
      transition: 0s;
      color: ${Colors.Black};
    }
  }

  .ant-menu-item-selected {
    background-color: ${Colors.BabyBlue} !important;
    border-radius: 10px;
    transition: 0s;
    color: ${Colors.Black};
  }
`;
