import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import Spin from "antd/lib/spin";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { AppCard } from "components/card";
import { AppModal } from "components/modal";
import { Colors } from "helpers/colors";
import { dateToLocal } from "helpers/utils";
import { DeleteIcon } from "svgIcons/deleteIcon";
import { EditIcon } from "pages/billing/EditIcon";
import { AppointmentListData } from "./appointment";
import {
  AppointmentItemContainer,
  AppointmentHeaderContainer,
  TimestampText,
  ActionsContainer,
  ActionIconContainer,
  AppointmentDetailsContainer,
  DetailItemContainer,
  DetailItemHeaderText,
  DetailItemValueText,
  AppointmentErrorContainer,
  ErrorIconContainer,
  ErrorText
} from "./styles";

interface AppointmentListItemProps {
  readonly appointment: AppointmentListData;
  readonly userIsPatient: boolean;
  readonly userId: string;
  readonly onEdit: (item: AppointmentListData) => void;
  readonly onDelete: (item: AppointmentListData) => void;
  readonly deleteAppointmentLoading: boolean;
}

export const AppointmentListItem: React.FC<AppointmentListItemProps> = ({
  appointment,
  userIsPatient,
  userId,
  onDelete,
  onEdit,
  deleteAppointmentLoading
}) => {
  const onEditAppointment = (item: AppointmentListData) => () => {
    onEdit(item);
    if (userIsPatient) {
      const [firstName, lastName] = appointment.provider_name.split(/[\s]+/);
      sessionStorage.setItem(
        "selectedProviderName",
        String(firstName + " " + lastName)
      );
      sessionStorage.setItem(
        "selectedProviderInternalId",
        String(appointment.provider_internal_id)
      );
    }
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const showActionsContainer = useMemo(() => {
    if (
      moment(appointment.date_time, "MM/DD/YYYY HH:mm:ss").valueOf() <
      moment.now()
    ) {
      return false;
    }
    if (userIsPatient) {
      return true;
    }
    if (userId === appointment.provider_internal_id.toString()) {
      return true;
    }
    return false;
  }, [userIsPatient, appointment]);

  return (
    <>
      <AppCard
        cardHeight="110px"
        cardWidth="100%"
        style={{
          marginBottom: 10
        }}
      >
        <AppointmentItemContainer>
          <AppointmentHeaderContainer>
            <TimestampText>
              {moment(dateToLocal(appointment.date_time)).format(
                "MMM DD, YYYY | h:mm A"
              )}
            </TimestampText>
            <ActionsContainer
              style={{
                display: showActionsContainer ? "flex" : "none"
              }}
            >
              <ActionIconContainer onClick={onEditAppointment(appointment)}>
                <EditIcon />
              </ActionIconContainer>
              {deleteAppointmentLoading ? (
                <ActionIconContainer>
                  <Spin />
                </ActionIconContainer>
              ) : (
                <ActionIconContainer onClick={() => setShowDeleteModal(true)}>
                  <DeleteIcon />
                </ActionIconContainer>
              )}
            </ActionsContainer>
          </AppointmentHeaderContainer>
          <AppointmentDetailsContainer>
            {appointment.provider_name && (
              <DetailItemContainer showLeftBorder={false}>
                <DetailItemHeaderText>Provider Name</DetailItemHeaderText>
                <DetailItemValueText>
                  {appointment.provider_name}, {appointment.degree}
                </DetailItemValueText>
                <DetailItemValueText>
                  {appointment.specialty}
                </DetailItemValueText>
              </DetailItemContainer>
            )}
          </AppointmentDetailsContainer>
        </AppointmentItemContainer>
      </AppCard>
      <AppointmentErrorContainer>
        <ErrorIconContainer>
          <InfoCircleOutlined style={{ color: Colors.Red, fontSize: 12 }} />
        </ErrorIconContainer>
        <ErrorText>
          Please communicate with the clinic to actually set the appointment
        </ErrorText>
      </AppointmentErrorContainer>
      {showDeleteModal && (
        <AppModal
          visible={showDeleteModal}
          title="Delete Appointment"
          cancelText="Cancel"
          okText="Delete"
          onCancel={() => setShowDeleteModal(false)}
          onOk={() => {
            onDelete(appointment);
            setShowDeleteModal(false);
          }}
          prompt={{
            icon: (
              <WarningOutlined style={{ color: "#F5A623", fontSize: "40px" }} />
            ),
            text: <div>Are you sure you wish to delete this appointment?</div>
          }}
          footer
        />
      )}
    </>
  );
};
