import Divider from "antd/lib/divider";
import Skeleton from "antd/lib/skeleton";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { AppAvatar } from "components/avatar";
import { AppCard } from "components/card";
import { CommonRoutesRef } from "constants/routes";
import { UserContext, UserContextState } from "contextApis/userContext";
import { startCase } from "helpers/utils";
import {
  ProfileHeaderContainer,
  AvatarContainer,
  AvatarTextContainer,
  AvatarNameText,
  AvatarSpecialityText,
  ResetPasswordLinkContainer,
  ResetPasswordLink,
  ProfileContentContainer,
  GridItem,
  GridItemTitle,
  GridItemValue,
  GridItemGap,
  ContactNumberContainer,
  ContactNumberGap
} from "./style";

export const MyProfilePage: React.FC = () => {
  const { userData, onLogout } = useContext<UserContextState>(UserContext);
  const onResetPasswordClick = () => {
    onLogout(`/${CommonRoutesRef.ForgotPassword}`);
  };

  const { data, isLoading, isFetching } = useQuery(
    "provider-profile",
    async () => {
      return await createApiClient().getProviderProfile(
        userData?.internal_id || "",
        "",
        true
      );
    },
    { enabled: userData?.internal_id ? true : false }
  );

  const phoneNumberArray = data?.phoneNumbers || userData?.phoneNumbers || [];

  return (
    <>
      <AppCard cardWidth="100%" cardHeight="auto">
        <ProfileHeaderContainer>
          <AvatarContainer>
            <AppAvatar imageSrc={userData?.picture[0] || ""} size={80} />
            <AvatarTextContainer>
              {isLoading || isFetching ? (
                <Skeleton
                  loading={isFetching || isLoading}
                  active={isFetching || isLoading}
                  avatar={false}
                  paragraph={false}
                  style={{ width: 100 }}
                />
              ) : (
                <AvatarNameText>{`${userData?.lastName || ""}, ${
                  userData?.firstName || ""
                }`}</AvatarNameText>
              )}
              {isLoading || isFetching ? (
                <Skeleton
                  loading={isFetching || isLoading}
                  active={isFetching || isLoading}
                  avatar={false}
                  paragraph={false}
                  style={{ width: 30 }}
                />
              ) : (
                <AvatarSpecialityText>
                  {userData?.degree || ""}
                </AvatarSpecialityText>
              )}
            </AvatarTextContainer>
          </AvatarContainer>
          <ResetPasswordLinkContainer>
            <ResetPasswordLink onClick={onResetPasswordClick}>
              Reset Password
            </ResetPasswordLink>
          </ResetPasswordLinkContainer>
        </ProfileHeaderContainer>
        <Divider />
        <ProfileContentContainer>
          <GridItem>
            <GridItemTitle topPadding>Role</GridItemTitle>
            {isLoading || isFetching ? (
              <Skeleton
                loading={isFetching || isLoading}
                active={isFetching || isLoading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              <GridItemValue bold>
                {startCase(userData?.role || "")}
              </GridItemValue>
            )}
            <GridItemGap />
            <GridItemTitle>Speciality</GridItemTitle>
            {isLoading || isFetching ? (
              <Skeleton
                loading={isFetching || isLoading}
                active={isFetching || isLoading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              <GridItemValue bold>{userData?.specialty}</GridItemValue>
            )}
            <GridItemGap />
            <GridItemTitle>Group </GridItemTitle>
            {isLoading || isFetching ? (
              <Skeleton
                loading={isFetching || isLoading}
                active={isFetching || isLoading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              <GridItemValue bold>{startCase(data?.group || "")}</GridItemValue>
            )}
            <GridItemGap />
            <GridItemTitle>Organisation</GridItemTitle>
            {isLoading || isFetching ? (
              <Skeleton
                loading={isFetching || isLoading}
                active={isFetching || isLoading}
                avatar={false}
                paragraph={false}
                style={{ width: 100 }}
              />
            ) : (
              data?.orgs.map(org => (
                <GridItemValue bold key={org}>
                  {org}
                </GridItemValue>
              )) || null
            )}
          </GridItem>
          <GridItem leftBorder>
            <GridItemTitle topPadding>Office Address</GridItemTitle>
            {isFetching || isLoading ? (
              <Skeleton
                loading={isFetching || isLoading}
                active={isFetching || isLoading}
                avatar={false}
                paragraph={false}
                style={{ width: 150 }}
              />
            ) : (
              <>
                <GridItemValue bold>{data?.officeAddr1 || ""}</GridItemValue>
                <GridItemValue bold>{`${data?.addressCity || ""}, ${
                  data?.state || ""
                } ${data?.addressZip || ""}`}</GridItemValue>
              </>
            )}
            <GridItemGap />
            <GridItemTitle>Contact Number</GridItemTitle>
            <ContactNumberContainer>
              {isLoading || isFetching ? (
                <Skeleton
                  loading={isFetching || isLoading}
                  active={isFetching || isLoading}
                  avatar={false}
                  paragraph={{ rows: 1 }}
                  style={{ width: 100 }}
                />
              ) : (
                phoneNumberArray.map((phoneNumber, index) => (
                  <div key={index}>
                    <GridItemTitle>{phoneNumber.title}</GridItemTitle>
                    <GridItemValue>{phoneNumber.number}</GridItemValue>
                    {index < phoneNumberArray.length - 1 && (
                      <ContactNumberGap />
                    )}
                  </div>
                ))
              )}
            </ContactNumberContainer>
          </GridItem>
        </ProfileContentContainer>
      </AppCard>
    </>
  );
};
