import React from "react";
import styled, { CSSProperties } from "styled-components";
import { Colors } from "helpers/colors";

interface CommonErrorMessageProps {
  readonly message: string;
  readonly style?: CSSProperties;
}

export const CommonErrorMessage: React.FC<CommonErrorMessageProps> = ({
  message,
  style
}) => {
  return <ErrorStyle style={style}>{message}</ErrorStyle>;
};

const ErrorStyle = styled.div`
  padding: 5px;
  color: ${Colors.Red};
`;
