import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "components/dropDownBox";
import { Colors } from "helpers/colors";
import { DiscontinuedMedicationListInterface } from "sharedTypes";
import {
  DropdownContentContainer,
  DropdownHeaderContainer
} from "pages/providerDetails/style";

interface MedicationHistoryDropdownProps {
  readonly medicineItem: DiscontinuedMedicationListInterface;
}

export const DiscontinuedMedicationDropdown: React.FC<
  MedicationHistoryDropdownProps
> = ({ medicineItem }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onDropdownVisibilityChange = (value: boolean) => setIsOpen(value);

  return (
    <Dropdown
      arrow
      visible={isOpen}
      setVisible={onDropdownVisibilityChange}
      borderradius="8px"
      placement="bottomRight"
      dropDownMenuHeader={
        <DropdownHeaderContainer>View Details</DropdownHeaderContainer>
      }
      dropDownMenuBody={
        <DropdownContentContainer borderRadius="8px">
          <GridContainer marginBottom="20px">
            <GridItem rightBorder>
              <DropdownItemHeader>Started By</DropdownItemHeader>
              <DropdownItemContent bold>
                {medicineItem.CreatedBy}
              </DropdownItemContent>
            </GridItem>
            <GridItem>
              <DropdownItemHeader>Stopped By</DropdownItemHeader>
              <DropdownItemContent bold>
                {medicineItem.ModifiedBy}
              </DropdownItemContent>
            </GridItem>
          </GridContainer>
          <GridContainer marginBottom="20px">
            <GridItem rightBorder>
              <DropdownItemHeader>Start Date</DropdownItemHeader>
              <DropdownItemContent bold>
                {moment(medicineItem.CreatedDate, "YYYY-MM-DD").format(
                  "DD MMM, YYYY"
                )}
              </DropdownItemContent>
            </GridItem>
            <GridItem>
              <DropdownItemHeader>End Date</DropdownItemHeader>
              <DropdownItemContent bold>
                {moment(medicineItem.ModifiedDate, "YYYY-MM-DD").format(
                  "DD MMM, YYYY"
                )}
              </DropdownItemContent>
            </GridItem>
          </GridContainer>
          <ReasonPrescribedContainer>
            <DropdownItemHeader>Reason Prescribed</DropdownItemHeader>
            <DropdownItemContent>
              {medicineItem.MedicationReasons.join(", ")}
            </DropdownItemContent>
          </ReasonPrescribedContainer>
          <ReasonPrescribedContainer>
            <DropdownItemHeader>Reason Discontinued</DropdownItemHeader>

            <DropdownItemContent>
              {medicineItem.DiscontinueReasons.map((item, index) =>
                index === medicineItem.DiscontinueReasons.length - 1
                  ? item.Reason
                  : item.Reason + ","
              )}
            </DropdownItemContent>
          </ReasonPrescribedContainer>
          <div>
            <DropdownItemHeader>Information taken from</DropdownItemHeader>
            <DropdownItemContent>{medicineItem.InfoFrom}</DropdownItemContent>
          </div>
        </DropdownContentContainer>
      }
    >
      <EyeOutlined style={{ fontSize: "20px", color: Colors.LightGrey }} />
    </Dropdown>
  );
};

interface GridContainerProps {
  readonly marginBottom?: string;
}
const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "")};
`;

interface GridItemProps {
  readonly rightBorder?: boolean;
}

const GridItem = styled.div<GridItemProps>`
  border-right: ${props =>
    props.rightBorder ? `1px solid ${Colors.Lavender}` : ""};
`;
const DropdownItemHeader = styled.div`
  font-size: 12px;
`;

interface DropdownItemContentProps {
  readonly bold?: boolean;
}
const DropdownItemContent = styled.div<DropdownItemContentProps>`
  font-weight: ${props => (props.bold ? "bold" : "normal")};
`;

const ReasonPrescribedContainer = styled.div`
  margin-bottom: 20px;
`;
