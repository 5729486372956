import React from "react";

export const BNPValueIcon: React.FC = () => {
  return (
    <svg
      width="70px"
      height="70px"
      viewBox="0 0 70 70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <defs>
        <linearGradient
          x1="67.4819381%"
          y1="87.4094365%"
          x2="19.0836497%"
          y2="4.71260974%"
          id="linearGradient-1"
        >
          <stop stopColor="#F27B6E" offset="16.6004335%"></stop>
          <stop stopColor="#F1D22F" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Patient-MyHealthStatus-MyCurrentStatus-MyHeartHealth-V1.1-O1"
          transform="translate(-823.000000, -172.000000)"
        >
          <g id="Group-3-Copy-16" transform="translate(799.000000, 148.000000)">
            <g id="bnp-value-icon" transform="translate(24.000000, 24.000000)">
              <path
                d="M35,-2.72848411e-12 L35,-2.72848411e-12 C54.3299662,-2.73203496e-12 70,15.6700338 70,35 L70,35 C70,54.3299662 54.3299662,70 35,70 L35,70 C15.6700338,70 2.36723813e-15,54.3299662 0,35 L0,35 C-2.36723813e-15,15.6700338 15.6700338,-2.72493325e-12 35,-2.72848411e-12 Z"
                id="Rectangle-Copy-204"
                fill="#EBF3FB"
              ></path>
              <g
                id="Group-8"
                transform="translate(23.828556, 43.402699) rotate(-40.000000) translate(-23.828556, -43.402699) translate(16.328556, 21.902699)"
              >
                <g id="Group-7-Copy">
                  <polygon
                    id="Rectangle"
                    fill="#FFFFFF"
                    points="1.91459194 1.27541561 13.5659179 1.24254732 13.7142856 28.7179446 1.45279984 18.4293365"
                  ></polygon>
                  <path
                    d="M0.795918367,17.8571429 L13.6741113,28.7658224 L13.7830914,35.7369958 C13.8403014,39.3965708 10.9953902,42.4472429 7.34073111,42.645306 L7.34073111,42.645306 C3.91671867,42.8308692 0.990579431,40.2055875 0.805016181,36.781575 C0.798952083,36.6696803 0.795918367,36.557642 0.795918367,36.445583 L0.795918367,17.8571429 Z"
                    id="Rectangle-Copy"
                    fill="#F1D22F"
                  ></path>
                  <path
                    d="M1.50690934,23.0661574 C2.81672068,25.5804799 4.69708745,27.158295 7.14800965,27.7996026 C9.59893184,28.4409102 11.7863048,30.3744441 13.7101285,33.6002043 L13.6741113,28.7658224 L1.33287552,18.410296 L1.50690934,23.0661574 Z"
                    id="Path-5"
                    fill="#F3DF79"
                  ></path>
                  <path
                    d="M2.79591837,1.54591837 C2.10556243,1.54591837 1.54591837,2.10556243 1.54591837,2.79591837 L1.54591837,36.2653061 C1.54591837,39.5705613 4.22535708,42.25 7.53061224,42.25 C10.8358674,42.25 13.5153061,39.5705613 13.5153061,36.2653061 L13.5153061,2.79591837 C13.5153061,2.10556243 12.9556621,1.54591837 12.2653061,1.54591837 L2.79591837,1.54591837 Z"
                    id="Rectangle"
                    stroke="#212B40"
                    strokeWidth="1.5"
                  ></path>
                  <circle
                    id="Oval"
                    fill="#212B40"
                    cx="4.79461862"
                    cy="31.3861772"
                    r="1"
                  ></circle>
                  <circle
                    id="Oval-Copy"
                    fill="#212B40"
                    cx="8.55685131"
                    cy="30.9737609"
                    r="1"
                  ></circle>
                  <circle
                    id="Oval-Copy-2"
                    fill="#212B40"
                    cx="8.16751833"
                    cy="35.5377429"
                    r="1"
                  ></circle>
                </g>
              </g>
              <g
                id="Group-9"
                transform="translate(19.000000, 14.000000)"
                stroke="#212B40"
                strokeWidth="1.5"
              >
                <path
                  d="M8.17958967,5 C9.0550453,3.22 10.8340587,2.62666667 13.5166297,3.22 C15.6925049,0.335291159 18.3290615,-0.404708841 21.4262995,1 C23.5337428,-0.333333333 25.5633154,-0.333333333 27.5150172,1 C31.1244215,0.754111213 33.3714099,1.54472423 34.3087545,4 C37.2832872,4.10223023 39.263842,5.60071429 39.9318569,9.5 C43.0172963,10.9227536 43.8844929,13.256087 42.5334467,16.5 C43.7868864,19.5810862 42.9196898,21.934691 39.9318569,23.5608146 C38.6672622,29.0766063 35.4622279,31.7194898 30.3167541,31.4894651 L30.8010327,36 L27.1131142,36.0563492 C26.457327,31.7455823 24.561722,29.2299276 21.4262995,28.5093851 C16.7231656,27.4285714 13.3345024,27.6216292 11.4388975,23.5608146 C9.36920966,24.5574516 8.93834648,24.3092775 4.42120138,21.9854935 C-0.0959437238,19.6617095 -0.554158186,12.7985714 2.52624002,10.3992857 C2.75556177,6.83086457 4.64001166,5.03110267 8.17958967,5 Z"
                  id="Path-35"
                  fill="url(#linearGradient-1)"
                ></path>
                <path
                  d="M2.97191154,8.67857143 C2.24821026,12.1309524 3.73686337,14.3809524 7.43787087,15.4285714 C6.21360598,16.7448407 6.21360598,18.1019835 7.43787087,19.5"
                  id="Path-36"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M28.7660893,1 C26.2553631,1.66666667 25,3.33333333 25,6"
                  id="Path-37"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M16.9946693,6 C17.0792541,7.65589516 16.1572243,8.98922849 14.22858,10"
                  id="Path-37-Copy"
                  strokeLinecap="round"
                  transform="translate(15.614290, 8.000000) scale(-1, 1) translate(-15.614290, -8.000000) "
                ></path>
                <path
                  d="M39.1994018,25.7834 C39.859838,21.2658952 38.0421423,18.5892857 33.7463147,17.7535714"
                  id="Path-38"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M12.7078952,25.3992857 C10.7911341,22.2540476 11.2155018,19.8709524 13.9809982,18.25"
                  id="Path-39"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M7,10.3992857 C9.7877247,11.6992568 12.3877915,10.8994949 14.8002003,8"
                  id="Path-40"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M24,24.600298 C27.1451161,25.467365 30.0785162,24.9339323 32.8002003,23"
                  id="Path-40-Copy-2"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M19,12.3992857 C22.9376055,17.9759524 27.2012638,18.1739286 31.7909747,12.9932143"
                  id="Path-40-Copy"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M25.3917968,21.25 C25.6777721,18.7409524 24.7611764,16.9886905 22.6420098,15.9932143"
                  id="Path-41"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M16,15 C18.1918058,16.3739238 18.7397572,18.5146381 17.6438543,21.4221429"
                  id="Path-42"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M20,7 C23.436796,7.01652355 25.155194,8.84985688 25.155194,12.5"
                  id="Path-42-Copy"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M30,7 C34.7701293,7.02253211 37.155194,9.52253211 37.155194,14.5"
                  id="Path-42-Copy-2"
                  strokeLinecap="round"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
