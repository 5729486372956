import React, { useEffect, useMemo, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { AppSelect } from "components/inputs";
import { SelectOption } from "components/inputs/selectInput";

export interface MedicineInfoFromProps {
  readonly infoFrom?: string;
}

interface MedicineInfoFromInputProps {
  readonly value?: MedicineInfoFromProps;
  readonly onChange?: (value: MedicineInfoFromProps) => void;
}

export const MedicineInfoFromInput: React.FC<MedicineInfoFromInputProps> = ({
  value,
  onChange
}) => {
  const [infoFrom, setInfoFrom] = useState<string>("");

  const { data } = useQuery("medication-info-from", async () => {
    return createApiClient().getMedicineInfoFrom();
  });

  const infoFromOptions: SelectOption<string>[] = useMemo(() => {
    if (!data) return [];
    return data.map(item => ({ label: item, value: item }));
  }, [data]);

  const onInfoFromChange = (value: string) => {
    setInfoFrom(value);
    triggerChange({ infoFrom: value });
  };

  const triggerChange = (changedValue: MedicineInfoFromProps) => {
    onChange?.({
      infoFrom,
      ...value,
      ...changedValue
    });
  };

  useEffect(() => {
    triggerChange({ infoFrom: infoFrom });
  }, []);

  useEffect(() => {
    if (isEmpty(infoFromOptions)) {
      return;
    }
    if (!infoFrom) {
      onInfoFromChange(infoFromOptions[0].value);
    }
  }, [infoFromOptions]);

  useEffect(() => {
    setInfoFrom(get(value, "infoFrom", ""));
  }, [value]);

  return (
    <AppSelect
      value={infoFrom}
      label="Info From"
      onChange={onInfoFromChange}
      placeholder="Enter text here"
      style={{ marginRight: 5, width: "240px" }}
      options={infoFromOptions}
      dropdownStyle={{ borderRadius: "8px" }}
    />
  );
};
