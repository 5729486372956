import { Row, Space, Button, Popover } from "antd";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppSelect } from "components/inputs";
import { UserRoles } from "constants/roles";
import {
  notificationStatusFilterOptions,
  notificationTypeFilterOptions
} from "contextApis/notificationsContext";
import { FilterCol, TitleCol } from "./style";
import { useQuery } from "react-query";
import { createApiClient } from "../../apiClient";
import { NotificationItem } from "../../sharedTypes";
import { EllipsisOutlined } from "@ant-design/icons";
import "./allNotificationsHeader.css";

interface NotificationsHeaderProps {
  readonly setStatusFilter: (status: string) => void;
  readonly statusFilter: string;
  readonly setTypeFilter: (status: string) => void;
  readonly typeFilter: string;
  readonly getNotificationsList: () => void;
  readonly apiData: any;
  readonly setLoadingAll: (status: boolean) => void;
}

export const AllNotificationsHeader: React.FC<NotificationsHeaderProps> = ({
  statusFilter,
  setStatusFilter,
  setTypeFilter,
  typeFilter,
  getNotificationsList,
  apiData,
  setLoadingAll
}) => {
  const navigate = useNavigate();
  const [mark, setMark] = useState<string>("");
  const [allNotificationIds, setAllNotificationIds] =
    useState<NotificationItem[]>();

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const hideTypeFilter = userIsPatient;

  const handleTypeFilterDisplayValue = (filterValue: string) => {
    const match = /[*?+^$_{}[\]().|\\]/.exec(filterValue); //Special character check
    if (match) {
      const tempValue = filterValue.replace("_", " ").split(/\s+/);

      tempValue[1] = startCase(tempValue[1]);
      return tempValue.join(" ");
    }
    return filterValue;
  };

  const {
    isLoading: isLoadingPutAllNotifications,
    data: putAllNotificationsData,
    error: isPutAllNotificationsError,
    isFetching: isFetchingPutAllNotifications,
    refetch: refetchPutAllNotifications
  } = useQuery(
    "PutAllNotifications",
    async () => {
      if (allNotificationIds != undefined) {
        const status = mark;
        const notification_obj = allNotificationIds;
        const obj = { notification_obj, status };
        const data = await createApiClient().putAllNotifications(obj);
        getNotificationsList();
        setLoadingAll(false);
        setMark("");
        return data;
      }
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (apiData != undefined) {
      const notificationIds: NotificationItem[] = Object.values(apiData)
        .flat()
        .map((item: any) => ({
          notification_id: item.notification_id,
          type: item.type
        }));
      setAllNotificationIds(notificationIds);
    }
  }, [apiData, mark]);

  useEffect(() => {
    getNotificationsList();
    if (mark != "") {
      setLoadingAll(true);
      setTimeout(() => {
        refetchPutAllNotifications();
      }, 1000);
    }
  }, [mark]);

  const onBackClick = () => navigate(-1);

  const markOptions = () => {
    return (
      <>
        <span onClick={() => setMark("read")} style={{ cursor: "pointer" }}>
          Read All
        </span>
        <hr />
        <span onClick={() => setMark("unread")} style={{ cursor: "pointer" }}>
          Unread All
        </span>
      </>
    );
  };

  return (
    <Row gutter={[20, 0]}>
      <TitleCol span={12}>
        <AppButton
          type={ButtonType.Secondary}
          onClick={onBackClick}
          buttonContent="Back"
          style={{ width: "100px", marginBottom: "15px" }}
        />
      </TitleCol>
      <FilterCol span={12}>
        <Space size={10}>
          <AppSelect
            value={
              !statusFilter
                ? "All"
                : statusFilter.charAt(0).toUpperCase() + statusFilter.slice(1)
            }
            label="Filter By Status"
            onChange={type => {
              setStatusFilter(type);
            }}
            style={{ marginRight: 5, width: "100px", textAlign: "left" }}
            options={notificationStatusFilterOptions}
            dropdownStyle={{ borderRadius: "8px" }}
          />
          {!hideTypeFilter && (
            <AppSelect
              value={
                !typeFilter
                  ? "All"
                  : handleTypeFilterDisplayValue(
                      typeFilter.charAt(0).toUpperCase() + typeFilter.slice(1)
                    )
              }
              label="Filter By Type"
              onChange={type => {
                setTypeFilter(type);
              }}
              style={{ marginRight: 5, width: "140px", textAlign: "left" }}
              options={notificationTypeFilterOptions}
              dropdownStyle={{ borderRadius: "8px" }}
            />
          )}
          <Popover
            content={markOptions}
            trigger="hover"
            placement="bottomLeft"
            showArrow={false}
          >
            <Button style={{ height: "40px", borderRadius: "20%" }}>
              <EllipsisOutlined style={{ rotate: "90deg" }} />
            </Button>
          </Popover>
        </Space>
      </FilterCol>
    </Row>
  );
};
