import Col from "antd/lib/col";
import Row from "antd/lib/row";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "helpers/colors";
import TextArea from "antd/lib/input/TextArea";
import { divide } from "lodash";
import { useLocation } from "react-router-dom";
import { createApiClient } from "apiClient";

interface FilterDisplayProps {
  readonly value1?: string | number;
  readonly value2?: string | number;
  readonly value3?: string | number;
  readonly value4?: string | number;
  readonly list1?: string[] | number[];
  readonly list2?: string[] | number[];
  readonly list3?: string[] | number[];
  readonly list4?: string[] | number[];
  readonly ignoreNativeWrapperStyle?: boolean;
}

export const FilterDisplayComponent: React.FC<FilterDisplayProps> = ({
  value1,
  value2,
  value3,
  value4,
  list1,
  list2,
  list3,
  list4,
  ignoreNativeWrapperStyle
}) => {
  const showFilters =
    value1 ||
    value2 ||
    value3 ||
    value4 ||
    (list1 && list1?.length !== 0) ||
    (list2 && list2?.length !== 0) ||
    (list3 && list3?.length !== 0) ||
    (list4 && list4?.length !== 0);

  const location = useLocation();
  const PatientId = sessionStorage.getItem("patientId");
  const specificPath = `/patients/remote-vitals/${PatientId}`;

  const [data, setData] = useState<any>("");

  const fetchBPThreshold = async (patientId: string) => {
    const data = await createApiClient().getThresholdData(patientId);
    setData(data);
    return data;
  };

  useEffect(() => {
    if (location.pathname === specificPath) {
      fetchBPThreshold(PatientId ?? "");
    }
  }, []);

  return (
    <>
      {showFilters && (
        <div
          style={
            ignoreNativeWrapperStyle
              ? {}
              : { padding: "20px", marginTop: "-10px" }
          }
        >
          <Row gutter={[0, 5]}>
            <Title>Filters Applied</Title>
            {value1 && <FiterItem>{value1}</FiterItem>}
            {value2 && <FiterItem>{value2}</FiterItem>}
            {value3 && <FiterItem>{value3}</FiterItem>}
            {value4 && value4 != "Any" && <FiterItem>{value4}</FiterItem>}
            {list1 &&
              list1.map(item => {
                return <FiterItem key={item}>{item}</FiterItem>;
              })}
            {list2 &&
              list2.map(item => {
                return <FiterItem key={item}>{item}</FiterItem>;
              })}
            {list3 &&
              list3.map(item => {
                return <FiterItem key={item}>{item}</FiterItem>;
              })}
            {list4 &&
              list4.map(item => {
                return <FiterItem key={item}>{item}</FiterItem>;
              })}
            <Title
              style={{
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                right: "2%",
                fontSize: "14px"
              }}
            >
              {location.pathname === specificPath && (
                <>
                  <div style={{ color: `${Colors.Grey}` }}>
                    Threshold set by :{" "}
                    <span style={{ color: `${Colors.Black}` }}>
                      {data?.threshold_set_by ??
                        "Threshold set by your organization or physician."}
                    </span>
                  </div>
                </>
              )}
            </Title>
          </Row>
        </div>
      )}
    </>
  );
};

const FiterItem = styled(Col)`
  border: 1px solid ${Colors.Lavender};
  background-color: ${Colors.GhostWhite};
  padding: 5px;
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 15px;
  margin-right: 8px;
  width: fit-content;
  display: inline-block;
`;

const Title = styled.span`
  font-family: Century Gothic Bold;
  margin-right: 15px;
  margin-top: 5px;
`;
