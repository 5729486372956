// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext } from "react";
import {
  ControlBar,
  AudioInputVFControl,
  AudioInputControl,
  AudioOutputControl,
  ControlBarButton,
  useUserActivityState,
  Dots,
  VideoInputControl
} from "amazon-chime-sdk-component-library-react";

import EndMeetingControl from "components/meetings/containers/EndMeetingControl";
import { useNavigation } from "components/meetings/providers/NavigationProvider";
import { StyledControls } from "./Styled";
import { useAWSMeetingState } from "components/meetings/providers/AWSMeetingStateProvider";
import { VideoFiltersCpuUtilization } from "components/meetings/types";
import VideoInputTransformControl from "components/meetings/components/MeetingControls/VideoInputTransformControl";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";

const MeetingControls: React.FC = () => {
  const { toggleNavbar, closeRoster, showRoster } = useNavigation();
  const { isUserActive } = useUserActivityState();
  const { isWebAudioEnabled, videoTransformCpuUtilization } =
    useAWSMeetingState();
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

  const videoTransformsEnabled =
    videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled;

  const handleToggle = (): void => {
    if (showRoster) {
      closeRoster();
    }
    toggleNavbar();
  };

  return (
    <div style={{ width: "25%", marginRight: "35%", marginLeft: "38%" }}>
      <StyledControls
        className="controls"
        active={meetingContext.pstnPhoneNumber ? true : !!isUserActive}
        pstnCall={meetingContext.pstnPhoneNumber ? true : false}
      >
        <span style={{ color: "black" }}>
          <ControlBar
            className="controls-menu"
            layout="undocked-horizontal"
            showLabels
          >
            <ControlBarButton
              className="mobile-toggle"
              icon={<Dots />}
              onClick={handleToggle}
              label="Menu"
            />
            {isWebAudioEnabled ? (
              <AudioInputVFControl />
            ) : (
              <AudioInputControl />
            )}
            {meetingContext.pstnPhoneNumber ? null : videoTransformsEnabled ? (
              <VideoInputTransformControl />
            ) : (
              <VideoInputControl />
            )}
            <AudioOutputControl />
            <EndMeetingControl />
          </ControlBar>
        </span>
      </StyledControls>
    </div>
  );
};

export default MeetingControls;
