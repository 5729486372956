import React from "react";
import { MENU_ITEM_ICON_DISABLED_COLOR } from "components/sideBar/sideBar";

interface IconProps {
  readonly isDisabled?: boolean;
}

export const EarnRewardsIcon: React.FC<IconProps> = ({ isDisabled }) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="LeftPannel-Patient-0"
          transform="translate(-13.000000, -341.000000)"
        >
          <g
            id="Risk-Profile-icon-Copy-41"
            transform="translate(14.000000, 342.000000)"
          >
            <g id="earn-rewards-icon" transform="translate(7.000000, 5.000000)">
              <g id="Rectangle-Copy-211">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill={isDisabled ? MENU_ITEM_ICON_DISABLED_COLOR : "#F27B6E"}
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <path
                d="M15.1,16.6525 C12.9999372,16.6525 11.2975,14.9500628 11.2975,12.85 C11.2975,10.7499372 12.9999372,9.0475 15.1,9.0475 C17.2000628,9.0475 18.9025,10.7499372 18.9025,12.85 C18.9025,14.9500628 17.2000628,16.6525 15.1,16.6525 Z M15.1,15.5475 C16.5897881,15.5475 17.7975,14.3397881 17.7975,12.85 C17.7975,11.3602119 16.5897881,10.1525 15.1,10.1525 C13.6102119,10.1525 12.4025,11.3602119 12.4025,12.85 C12.4025,14.3397881 13.6102119,15.5475 15.1,15.5475 Z"
                id="Oval"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <rect
                id="Rectangle"
                fill="#FFFFFF"
                x="14.45"
                y="10.9"
                width="1.3"
                height="3.9"
                rx="0.5"
              ></rect>
              <rect
                id="Rectangle-Copy-9"
                fill="#FFFFFF"
                x="13.15"
                y="12.2"
                width="3.9"
                height="1.3"
                rx="0.5"
              ></rect>
              <path
                d="M10.55,10.8025 C8.09237827,10.8025 6.0975,10.1375406 6.0975,8.95 C6.0975,7.76245942 8.09237827,7.0975 10.55,7.0975 C13.0076217,7.0975 15.0025,7.76245942 15.0025,8.95 C15.0025,10.1375406 13.0076217,10.8025 10.55,10.8025 Z M10.55,9.6975 C11.5423557,9.6975 12.4667679,9.56716887 13.1330006,9.34509129 C13.6546791,9.17119847 13.8975,8.97983409 13.8975,8.95 C13.8975,8.92016591 13.6546791,8.72880153 13.1330006,8.55490871 C12.4667679,8.33283113 11.5423557,8.2025 10.55,8.2025 C9.55764431,8.2025 8.63323213,8.33283113 7.96699939,8.55490871 C7.44532092,8.72880153 7.2025,8.92016591 7.2025,8.95 C7.2025,8.97983409 7.44532092,9.17119847 7.96699939,9.34509129 C8.63323213,9.56716887 9.55764431,9.6975 10.55,9.6975 Z"
                id="Oval"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path
                d="M6.09749942,8.94999986 L7.2025,8.94999986 L7.2025001,10.8632915 C7.20206099,11.2357791 8.63939211,11.617959 11.5685447,11.617959 L11.5685447,12.722959 C7.93758271,12.722959 6.09588435,12.2332597 6.09750048,10.8626403 L6.09749942,8.94999986 Z"
                id="Path-2"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path
                d="M11.8356186,13.6176051 L11.8643814,14.7222307 C8.27952618,14.8155751 6.34403894,14.310384 6.10530545,12.9051797 L6.09750048,12.8126403 L6.0975,10.8999999 L7.2025,10.8999999 L7.20250047,12.7552304 C7.35584323,13.3125145 8.88537004,13.6944253 11.8356186,13.6176051 Z"
                id="Path-2-Copy"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path
                d="M6.09750048,12.85 L7.20250048,12.85 L7.20250048,14.7626402 L7.19771165,14.8352262 C7.19360982,14.8661773 7.1985472,14.8784067 7.24971557,14.9241442 C7.3596362,15.0223981 7.57617698,15.1245518 7.89681228,15.2130939 C8.62987163,15.4155252 9.80184776,15.5208347 11.3976975,15.5208362 C11.9917447,15.5222472 12.6246672,15.3874273 13.2981595,15.1122635 L13.7160858,16.1351822 C12.9144961,16.4626818 12.1402603,16.6276031 11.3963852,16.6258347 C7.76466261,16.6258347 5.9463368,16.1330575 6.09750048,14.7298939 L6.09750048,12.85 Z"
                id="Path-2-Copy-2"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
