import React, { useContext, useEffect, useState } from "react";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import styled from "styled-components";

import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AddProviderIcon } from "./AddProviderIcon";
import { SearchBox } from "components/searchBox";
import { AddProviderModal } from "./addProviderModal";
import { AppModal } from "components/modal";
import { WarningOutlined } from "@ant-design/icons";
import { CareTeamCheckboxDropdown } from "./careTeamCheckboxDropdown";
import { AppSelect } from "components/inputs";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { useMutation } from "react-query";
import { createApiClient } from "apiClient";
import { AllProviderList, UserNetworkList } from "sharedTypes";
import { Colors } from "helpers/colors";
import { UserRoles } from "constants/roles";

interface CareTeamHeaderProps {
  setSearchNameValue(value: string): void;
  setSearchSpecialtyValue(value: string): void;
  setMenuItems(
    obj: { desc: string; code: string; detail_desc: string; checked: boolean }[]
  ): void;
  readonly menuItems: {
    desc: string;
    code: string;
    detail_desc: string;
    checked: boolean;
  }[];
  setApplied(applied: boolean): void;
  readonly applied: boolean;
  readonly searchNameValue: string;
  readonly searchSpecialtyValue: string;
  readonly existingProviders: UserNetworkList[];
  refetchCareTeamList(): void;
  readonly dbId: number;
}

export const CareTeamHeader: React.FC<CareTeamHeaderProps> = ({
  setSearchNameValue,
  setSearchSpecialtyValue,
  setMenuItems,
  menuItems,
  setApplied,
  applied,
  searchNameValue,
  searchSpecialtyValue,
  existingProviders,
  refetchCareTeamList,
  dbId
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState<AllProviderList[]>(
    []
  );
  const patientContext = useContext<PatientContextState>(PatientContext);

  const careTeamSelectedItems = JSON.parse(
    sessionStorage.getItem("careTeamCheckboxItems") || "[]"
  );

  const [selectedCareTeamText, setSelectedCareTeamText] = useState([
    "All Care Team"
  ]);

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;
  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const [allCareTeamChecked, setAllCareTeamChecked] = useState(true);

  const [filterType, setFilterType] = useState("Name");

  const {
    isLoading: isLoadingAddProviderMutation,
    mutate: addProviderMutation
  } = useMutation<string>(
    "add-bill",
    async () => {
      return await createApiClient().addProviders(dbId, {
        users: selectedProviders
      });
    },
    {
      onSuccess: () => {
        message.success("Provider(s) Added Successfully!");
        setShowConfirmationModal(false);
        refetchCareTeamList();
      },
      onError: () => {
        message.error("There was a problem adding provider(s)");
      }
    }
  );

  const filterOptions: { label: string; value: string }[] = [
    {
      label: "Name",
      value: "Name"
    },
    {
      label: "Specialty",
      value: "Specialty"
    }
  ];

  useEffect(() => {
    if (!applied && !showDropdown) {
      setMenuItems(
        JSON.parse(sessionStorage.getItem("careTeamCheckboxItems") || "[]")
      );
      setAllCareTeamChecked(
        JSON.parse(sessionStorage.getItem("careTeamDropdownAllChecked") || "")
      );
    }

    let tempSelectedCareTeamText = [];

    tempSelectedCareTeamText = careTeamSelectedItems.filter(
      (obj: {
        desc: string;
        code: string;
        detail_desc: string;
        checked: boolean;
      }) => {
        if (obj.checked) {
          return obj.desc;
        }
        return null;
      }
    );

    tempSelectedCareTeamText = tempSelectedCareTeamText.map(
      (obj: {
        desc: string;
        code: string;
        detail_desc: string;
        checked: boolean;
      }) => obj.desc
    );

    const allSelectedFalse = careTeamSelectedItems.every(
      (obj: {
        desc: string;
        code: string;
        detail_desc: string;
        checked: boolean;
      }) => obj.checked === false
    );

    setSelectedCareTeamText(
      allCareTeamChecked
        ? ["All Care Team"]
        : allSelectedFalse
        ? ["Select Care Team"]
        : tempSelectedCareTeamText
    );
  }, [showDropdown, allCareTeamChecked]);

  const closeModal = () => {
    setShowModal(false);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const getSelectedProviders = (selectedProviders: AllProviderList[]) => {
    setSelectedProviders(selectedProviders);
    setShowModal(false);
    setShowConfirmationModal(true);
  };

  return (
    <RowStyled gutter={[20, 0]}>
      <TitleCol span={3}>Care Team</TitleCol>
      <Col span={17}>
        <Space size={20}>
          <CareTeamCheckboxDropdown
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
            selectedCareTeamText={selectedCareTeamText.join(", ")}
            allChecked={allCareTeamChecked}
            setAllChecked={setAllCareTeamChecked}
            applied={applied}
            setApplied={setApplied}
            setMenuItems={setMenuItems}
            menuItems={menuItems}
          />
          <AppSelect
            value={filterType.charAt(0).toUpperCase() + filterType.slice(1)}
            label="Filter By"
            onChange={type => {
              setFilterType(type);
              setSearchSpecialtyValue("");
              setSearchNameValue("");
            }}
            style={{ marginRight: 5, width: "120px" }}
            options={filterOptions}
            dropdownStyle={{ borderRadius: "8px" }}
          />
          <SearchBox
            placeholder={`Search by ${filterType.toLowerCase()}`}
            value={searchSpecialtyValue || searchNameValue}
            onChange={e => {
              if (filterType === "Name") {
                setSearchSpecialtyValue("");
                setSearchNameValue(e.target.value);
              }
              if (filterType === "Specialty") {
                setSearchNameValue("");
                setSearchSpecialtyValue(e.target.value);
              }
            }}
          />
        </Space>
      </Col>

      <EnableCol span={4}>
        {!userIsPatient && !userIsCaregiver && (
          <AppButton
            type={ButtonType.Skeleton}
            buttonContent={
              <Row>
                <AddProviderIcon />
                <ButtonText>Add Provider</ButtonText>
              </Row>
            }
            onClick={() => setShowModal(true)}
          />
        )}
      </EnableCol>
      {showModal && (
        <AddProviderModal
          visible={showModal}
          onCancel={closeModal}
          getSelectedProviders={getSelectedProviders}
          existingProviders={existingProviders}
        />
      )}
      <AppModal
        title="Add Provider Confirmation"
        visible={showConfirmationModal}
        onCancel={closeConfirmationModal}
        onOk={() => addProviderMutation()}
        footer={false}
        okText="Save"
        cancelText="Cancel"
        prompt={{
          icon: (
            <WarningOutlined style={{ color: "#F5A623", fontSize: "40px" }} />
          ),
          text: (
            <>
              <div>
                This will expose{" "}
                {patientContext.patientData?.first_name +
                  " " +
                  patientContext.patientData?.last_name +
                  "'s "}
                Protected Health Information to:
              </div>
              <div>
                {selectedProviders
                  .filter(provider => {
                    return !existingProviders
                      .map(item => item.id)
                      .includes(provider.id);
                  })
                  .map(provider => {
                    return (
                      <div key={provider.id} style={{ fontWeight: "600" }}>
                        {provider.name + " " + provider.degree}
                      </div>
                    );
                  })}
              </div>
              <div> Are you sure you want to proceed?`</div>
              <FooterStyle>
                <Space>
                  <AppButton
                    type={ButtonType.Secondary}
                    buttonContent="Cancel"
                    style={{ width: "120px" }}
                    onClick={() => setShowConfirmationModal(false)}
                  />
                  <AppButton
                    type={ButtonType.Primary}
                    buttonContent="Save"
                    style={{ width: "120px" }}
                    onClick={() => addProviderMutation()}
                    loading={isLoadingAddProviderMutation}
                  />
                </Space>
              </FooterStyle>
            </>
          )
        }}
      />
    </RowStyled>
  );
};

const FooterStyle = styled.div`
  border-top: 1px solid ${Colors.Lavender};
  margin-top: 15px;
  position: absolute;
  width: 100%;
  left: 0;
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  padding: 10px;
  text-align: right;
`;

const ButtonText = styled.span`
  margin-left: 5px;

  @media screen and (max-width: 1260px) {
    display: none;
  }
`;

const RowStyled = styled(Row)`
  padding-bottom: 12px;
`;

const TitleCol = styled(Col)`
  padding-top: 18px;
  padding-left: 20px !important;
  font-family: Century Gothic Bold;

  @media screen and (max-width: 1260px) {
    padding-right: 0 !important;
  }
`;

const EnableCol = styled(Col)`
  text-align: right;
`;
