import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { achesAndPainMapper } from "pages/symptoms/symptomsChartsMappers/achesAndPainMapper";
import moment from "moment";
import { legSwellingMapper } from "pages/symptoms/symptomsChartsMappers/legSwellingMapper";
import { weightChangeMapper } from "pages/symptoms/symptomsChartsMappers/weightChangeMapper";
import { fatigueMapper } from "pages/symptoms/symptomsChartsMappers/fatigueMapper";
import { shortnessOfBreathMapper } from "pages/symptoms/symptomsChartsMappers/shortnessOfBreathMapper";
import { lightheadednessMapper } from "pages/symptoms/symptomsChartsMappers/lightheadednessMapper";
import { fallsMapper } from "pages/symptoms/symptomsChartsMappers/fallsMapper";
import { feverMapper } from "pages/symptoms/symptomsChartsMappers/feverMapper";
import { ulcersMapper } from "pages/symptoms/symptomsChartsMappers/ulcersMapper";
import { nauseaMapper } from "pages/symptoms/symptomsChartsMappers/nauseaMapper";
import { SymptomConstants } from "pages/symptoms/symptomConstants";
import { moodImpairmentMapper } from "pages/symptoms/symptomsChartsMappers/moodImpairmentMapper";
import { appetiteImpairmentMapper } from "pages/symptoms/symptomsChartsMappers/appetiteImpairmentMapper";
import { dialysisMapper } from "pages/symptoms/symptomsChartsMappers/dialysisMapper";
import { chestPainMapper } from "pages/symptoms/symptomsChartsMappers/chestPainMapper";
import { Colors } from "helpers/colors";
import isArray from "lodash/isArray";

export interface SeriesData {
  readonly name: string;
  readonly data: [number | string, number][];
  marker?: { symbol: string; enabled: boolean };
}

export interface PlotLineData {
  readonly color: string;
  readonly label: string;
  readonly value: number;
  readonly x: number;
}

interface LineChartProps {
  readonly useTimestampforXaxis?: boolean;
  readonly chartName?: string;
  readonly chartHeight: string;
  readonly chartWidth?: string;
  readonly data?: [number | string, number, string?, string?][];
  readonly showLegend?: boolean;
  readonly multiLineChart?: boolean;
  readonly seriesData?: SeriesData[];
  readonly seperateTooltip?: boolean;
  readonly customColors?: string[];
  readonly legendContainerWidth?: string;
  readonly plotLines?: PlotLineData[];
  readonly chartMarginRight?: number;
  readonly tooltipOutside?: boolean;
  readonly mapperType?: string;
  readonly selectedSymptom?: string;
  readonly customSeriesTooltip?: boolean;
  readonly customXAxisDate?: boolean;
  readonly timeRange?: string;
  readonly customXaxisDateFormat?: string;
  readonly multiChoiceTooltip?: string;
  readonly maxYAxis?: number | null;
  readonly minYAxis?: number | null;
  readonly yTickInterval?: number | null;
  readonly yEndOnTick?: boolean;
}

export const LineChartComponent: React.FC<LineChartProps> = ({
  useTimestampforXaxis,
  chartName,
  data,
  chartHeight,
  chartWidth,
  showLegend,
  multiLineChart,
  seriesData,
  seperateTooltip,
  customColors,
  legendContainerWidth,
  plotLines,
  chartMarginRight,
  tooltipOutside,
  mapperType,
  selectedSymptom,
  customSeriesTooltip,
  customXAxisDate,
  timeRange,
  multiChoiceTooltip,
  customXaxisDateFormat,
  maxYAxis,
  minYAxis,
  yTickInterval,
  yEndOnTick
}) => {
  const isMarkerEnabled = (data: any) => {
    if (isArray(data)) {
      if (data.length === 1) return true;
    }
    return false;
  };

  const lineChartOptions = {
    chart: {
      height: chartHeight ? chartHeight : null,
      width: chartWidth ? chartWidth : null,
      marginRight: chartMarginRight ? chartMarginRight : 0
    },
    title: {
      text: null
    },
    xAxis: {
      type: useTimestampforXaxis ? "datetime" : "category",
      crosshair: {
        color: "transparent"
      },
      labels: customXaxisDateFormat
        ? {
            formatter: function (this: { value: string }) {
              return moment(Number(this.value)).format(customXaxisDateFormat);
            }
          }
        : customXAxisDate
        ? {
            formatter: function (this: { value: string }) {
              return timeRange === "all" || timeRange === "12"
                ? moment(Number(this.value)).format("MM/DD/YY")
                : moment(Number(this.value)).format("MM/DD");
            }
          }
        : {}
    },
    yAxis: {
      endOnTick: yEndOnTick,
      startOnTick: false,
      tickInterval: yTickInterval,
      max: maxYAxis ? maxYAxis : null,
      min: minYAxis || minYAxis === 0 ? minYAxis : null,
      title: false,
      plotLines: plotLines?.map(item => ({
        color: item.color,
        value: item.value,
        label: {
          align: "right",
          text: item.label,
          style: { color: item.color, zIndex: 10 },
          x: item.x,
          y: 4
        },
        width: 2,
        zIndex: 3,
        verticalAlign: "middle",
        useHTML: true
      }))
    },
    tooltip: mapperType
      ? {
          shared: seperateTooltip ? false : true,
          useHTML: true,
          outside: tooltipOutside ? true : false,
          formatter: mapperTypeTooltipFormatter,
          style: {
            zIndex: 1000
          }
        }
      : customSeriesTooltip
      ? {
          shared: seperateTooltip ? false : true,
          outside: tooltipOutside ? true : false,
          useHTML: true,
          formatter: customTooltipFormatter,
          style: {
            zIndex: 1000
          }
        }
      : {
          shared: seperateTooltip ? false : true,
          outside: tooltipOutside ? true : false,
          useHTML: true,
          style: {
            zIndex: 1000
          }
        },
    credits: {
      enabled: false
    },
    colors: customColors
      ? customColors
      : [
          "#7cb5ec",
          "#2b908f",
          "#f45b5b",
          "#90ed7d",
          "#f7a35c",
          "#8085e9",
          "#f15c80",
          "#e4d354",
          "#91e8e1",
          "#434348"
        ],
    legend: {
      enabled: showLegend ? true : false,
      useHTML: true,
      symbolPadding: 0,
      symbolWidth: 0,
      symbolHeight: 0,
      squareSymbol: false,
      itemMarginBottom: 5,
      width: legendContainerWidth ? legendContainerWidth : "",
      itemStyle: {
        fontSize: "10px"
      },
      labelFormatter: function (this: Highcharts.Point) {
        return `<span><span style="background-color:${this.color}; padding-right: 12px; margin-right: 10px; border-radius: 50%;" >&nbsp</span>${this.name}</span>`;
      }
    },
    series: multiLineChart
      ? seriesData?.map(item => ({
          ...item,
          marker: {
            symbol: "diamond",
            enabled: isMarkerEnabled(item.data)
          }
        }))
      : [
          {
            name: chartName,
            findNearestPointBy: "xy",
            data: data,
            marker: {
              symbol: "diamond",
              enabled: isMarkerEnabled(data)
            }
          }
        ]
  };

  function mapperTypeTooltipFormatter(
    this: Highcharts.TooltipFormatterContextObject
  ) {
    const points = this.points || [];
    const allXValuePoints =
      points?.reduce((acc: Highcharts.Point[], point) => {
        const seriesXPoints =
          point?.series.data.filter(
            pointListItem => pointListItem.x === this.x
          ) || [];
        return acc.concat(seriesXPoints);
      }, []) || [];
    switch (selectedSymptom?.toLowerCase().replace(/\s/g, "")) {
      case SymptomConstants.AchesPain:
        if (mapperType === "Severity") {
          return allXValuePoints
            .map(point => `<div>${point.y}</div> `)
            .join(" ");
        } else {
          return allXValuePoints
            .map(point => {
              const actualValue = achesAndPainMapper({
                filteredExtendedSymptomData: null,
                mapperType: mapperType,
                mapperValue: point.y
              });
              return `<div>${point.y}${
                actualValue ? ":" : ""
              } ${actualValue}</div> `;
            })
            .join(" ");
        }
      case SymptomConstants.LegSwelling: {
        return allXValuePoints
          .map(point => {
            const actualValue = legSwellingMapper({
              filteredExtendedSymptomData: null,
              mapperType: mapperType,
              mapperValue: point.y
            });
            return `<div>${point.y}${
              actualValue ? ":" : ""
            } ${actualValue}</div> `;
          })
          .join(" ");
      }
      case SymptomConstants.WeightChange: {
        return allXValuePoints
          .map(point => {
            const actualValue = weightChangeMapper({
              filteredExtendedSymptomData: null,
              mapperType: mapperType,
              mapperValue: point.y
            });
            return `<div>${point.y}${
              actualValue ? ":" : ""
            } ${actualValue}</div> `;
          })
          .join(" ");
      }
      case SymptomConstants.Fatigue: {
        return allXValuePoints
          .map(point => {
            const actualValue = fatigueMapper({
              filteredExtendedSymptomData: null,
              mapperType: mapperType,
              mapperValue: point.y
            });
            return `<div>${point.y}${
              actualValue ? ":" : ""
            } ${actualValue}</div> `;
          })
          .join(" ");
      }
      case SymptomConstants.ShortnessOfBreath: {
        return allXValuePoints
          .map(point => {
            const actualValue = shortnessOfBreathMapper({
              filteredExtendedSymptomData: null,
              mapperType: mapperType,
              mapperValue: point.y
            });
            return `<div>${point.y}${
              actualValue ? ":" : ""
            } ${actualValue}</div> `;
          })
          .join(" ");
      }
      case SymptomConstants.ChestPain: {
        if (mapperType === "Severity") {
          return allXValuePoints
            .map(point => `<div>${point.y}</div> `)
            .join(" ");
        } else {
          return allXValuePoints
            .map(point => {
              const actualValue = chestPainMapper({
                filteredExtendedSymptomData: null,
                mapperType: mapperType,
                mapperValue: point.y
              });
              return `<div>${point.y}${
                actualValue ? ":" : ""
              } ${actualValue}</div> `;
            })
            .join(" ");
        }
      }
      case SymptomConstants.Lightheadedness: {
        return allXValuePoints
          .map(point => {
            const actualValue = lightheadednessMapper({
              filteredExtendedSymptomData: null,
              mapperType: mapperType,
              mapperValue: point.y,
              multiChoiceTooltip: multiChoiceTooltip
            });
            return `<div>${point.y}${
              actualValue ? ":" : ""
            } ${actualValue}</div> `;
          })
          .join(" ");
      }
      case SymptomConstants.Falls: {
        return allXValuePoints
          .map(point => {
            const actualValue = fallsMapper({
              filteredExtendedSymptomData: null,
              mapperType: mapperType,
              mapperValue: point.y
            });
            return `<div>${point.y}${
              actualValue ? ":" : ""
            } ${actualValue}</div> `;
          })
          .join(" ");
      }
      case SymptomConstants.Fever: {
        return allXValuePoints
          .map(point => {
            const actualValue = feverMapper({
              filteredExtendedSymptomData: null,
              mapperType: mapperType,
              mapperValue: point.y
            });
            return `<div>${point.y}${
              actualValue ? ":" : ""
            } ${actualValue}</div> `;
          })
          .join(" ");
      }
      case SymptomConstants.Ulcers: {
        return allXValuePoints
          .map(point => {
            const actualValue = ulcersMapper({
              filteredExtendedSymptomData: null,
              mapperType: mapperType,
              mapperValue: point.y
            });
            return `<div>${point.y}${
              actualValue ? ":" : ""
            } ${actualValue}</div> `;
          })
          .join(" ");
      }
      case SymptomConstants.Nausea: {
        return allXValuePoints
          .map(point => {
            const actualValue = nauseaMapper({
              filteredExtendedSymptomData: null,
              mapperType: mapperType,
              mapperValue: point.y
            });
            return `<div>${point.y}${
              actualValue ? ":" : ""
            } ${actualValue}</div> `;
          })
          .join(" ");
      }
      case SymptomConstants.MoodImpairment: {
        return allXValuePoints
          .map(point => {
            const actualValue = moodImpairmentMapper({
              filteredExtendedSymptomData: null,
              mapperType: mapperType,
              mapperValue: point.y
            });
            return `<div>${point.y}${
              actualValue ? ":" : ""
            } ${actualValue}</div> `;
          })
          .join(" ");
      }
      case SymptomConstants.AppetiteImpairment: {
        return allXValuePoints
          .map(point => {
            const actualValue = appetiteImpairmentMapper({
              filteredExtendedSymptomData: null,
              mapperType: mapperType,
              mapperValue: point.y
            });
            return `<div>${point.y}${
              actualValue ? ":" : ""
            } ${actualValue}</div> `;
          })
          .join(" ");
      }
      case SymptomConstants.Dialysis: {
        return allXValuePoints
          .map(point => {
            const actualValue = dialysisMapper({
              filteredExtendedSymptomData: null,
              mapperType: mapperType,
              mapperValue: point.y
            });
            return `<div>${point.y}${
              actualValue ? ":" : ""
            } ${actualValue}</div> `;
          })
          .join(" ");
      }
      case SymptomConstants.VitalSigns: {
        return allXValuePoints.map(point => `<div>${point.y}</div> `);
      }
      default:
        return allXValuePoints.map(point => `<div>${point.y}</div> `);
    }
  }

  function customTooltipFormatter(
    this: Highcharts.TooltipFormatterContextObject
  ) {
    const points = this.points || [];
    const allXValuePoints =
      points?.reduce((acc: Highcharts.Point[], point) => {
        const seriesXPoints =
          point?.series.data.filter(
            pointListItem => pointListItem.x === this.x
          ) || [];
        return acc.concat(seriesXPoints);
      }, []) || [];

    const systolicValues = allXValuePoints.filter(
      item =>
        item.series.name.toLowerCase().trim() === "systolic" ||
        item.series.name.toLowerCase().trim() === "sys"
    );
    const diastolicValues = allXValuePoints.filter(
      item =>
        item.series.name.toLowerCase().trim() === "diastolic" ||
        item.series.name.toLowerCase().trim() === "dias"
    );
    if (
      selectedSymptom &&
      (selectedSymptom.toLowerCase().replace(/\s/g, "") === "vitalsigns" ||
        selectedSymptom.toLowerCase().replace(/\s/g, "") === "bloodpressure")
    ) {
      return `
    <div class="tooltipZindex">
       <div colspan="2" style="font-weight: 400; font-size: 10px">${moment(
         allXValuePoints && Number(allXValuePoints[0].name)
       ).format("dddd")}, ${moment(
        allXValuePoints && Number(allXValuePoints[0].name)
      ).format("MMM")}
        ${moment(allXValuePoints && Number(allXValuePoints[0].name)).format(
          "D"
        )} ${moment(allXValuePoints && Number(allXValuePoints[0].name)).format(
        "YYYY"
      )}</div>
         ${
           systolicValues &&
           systolicValues
             .map((point, index) => {
               return point.series.name === "87%tile" ||
                 point.series.name === "12.5%tile" ||
                 point.series.name === "Threshold"
                 ? null
                 : `<div style="column-count: 2;">
                      <div style="display: inline-block;">
                      ${`<span style="height: 5px; width: 5px; margin-right: 5px; margin-bottom: 2px; background-color: ${Colors.Black}; border-radius: 50%; display: inline-block">
                        </span><span style="font-weight: 800">
                          ${systolicValues[index]?.y}/${diastolicValues[index]?.y}
                        </span>`}
                      </div>
                    </div>`;
             })
             .join(" ")
         }
    </div>
    `;
    } else {
      return `
    <div class="tooltipZindex">
       <div colspan="2" style="font-weight: 400; font-size: 10px">${moment(
         allXValuePoints && Number(allXValuePoints[0].category)
       ).format("dddd")}, ${moment(
        allXValuePoints && Number(allXValuePoints[0].category)
      ).format("MMM")}
        ${moment(allXValuePoints && Number(allXValuePoints[0].category)).format(
          "D"
        )}</div>
         ${
           allXValuePoints &&
           allXValuePoints
             .map(point =>
               point.series.name === "87%tile" ||
               point.series.name === "12.5%tile" ||
               point.series.name === "Threshold"
                 ? null
                 : `<div><span style="height: 5px; width: 5px; margin-right: 5px; margin-bottom: 2px; background-color: ${point.color}; border-radius: 50%; display: inline-block"></span>${point.series.name}: <span style="font-weight: 800">${point.y}</span></div>`
             )
             .join(" ")
         }
    </div>
    `;
    }
  }

  return <HighchartsReact highcharts={Highcharts} options={lineChartOptions} />;
};
