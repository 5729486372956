import React from "react";

export const MedicationIcon: React.FC = () => {
  return (
    <svg
      width="40px"
      height="41px"
      viewBox="0 0 40 41"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <defs>
        <path
          d="M12.6783959,0.196426315 L12.6783959,0.196426315 C19.3058129,0.196426315 24.6783959,5.56900932 24.6783959,12.1964263 L24.6783959,12.1964263 C24.6783959,18.8238433 19.3058129,24.1964263 12.6783959,24.1964263 L12.6783959,24.1964263 C6.05097891,24.1964263 0.678395906,18.8238433 0.678395906,12.1964263 L0.678395906,12.1964263 C0.678395906,5.56900932 6.05097891,0.196426315 12.6783959,0.196426315 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Provider-Global-navigation-V2.3"
          transform="translate(-14.000000, -341.000000)"
        >
          <g
            id="medication-menu-copy-42"
            transform="translate(14.000000, 342.000000)"
          >
            <g id="medication-icon" transform="translate(1.000000, 0.000000)">
              <g
                id="Group_279"
                transform="translate(18.059077, 18.340804) rotate(-45.000000) translate(-18.059077, -18.340804) translate(5.559077, 5.840804)"
              >
                <g id="Rectangle-Copy-212">
                  <use
                    fill="black"
                    fillOpacity="1"
                    filter="url(#filter-2)"
                    xlinkHref="#path-1"
                  ></use>
                  <use
                    fill="#7CBA5B"
                    fillRule="evenodd"
                    xlinkHref="#path-1"
                  ></use>
                </g>
                <path
                  d="M12.500044,18.5067869 C10.2127739,18.496081 9.26141027,16.3396104 9.2606108,15.1864339 L9.25261615,9.58649276 C9.20196494,7.8184106 10.6430783,6.3381868 12.4904504,6.26078688 C13.2800018,6.21502252 14.0531711,6.4895789 14.6202258,7.017081 C15.312689,7.70984622 15.7127766,8.62313297 15.7434744,9.58113982 L15.7506696,15.1780222 C15.7169878,16.1130946 15.328498,17.0044427 14.6578006,17.6854928 C14.09218,18.2281666 13.3185302,18.5250563 12.5176322,18.5067869 L12.500044,18.5067869 Z M10.7416985,9.79043618 L10.7876123,15.0932442 C10.7901535,15.6499784 10.9266914,16.0546469 11.083755,16.3448329 C11.182907,16.607825 11.8710882,17.0979342 12.4136666,17.060928 C12.973623,17.105103 13.4792574,16.8952236 13.6549522,16.7271677 C14.1532954,16.2534917 14.4031047,15.6647725 14.3788283,15.1812081 L14.3843412,9.84070484 C14.4254989,9.15930377 14.098461,8.57848597 13.7173626,8.21395707 C13.4320687,7.94106728 13.068343,7.67389305 12.5371429,7.66895081 C11.5309836,7.71448755 10.6689866,8.59361616 10.7416985,9.79043618 Z"
                  id="Path_830"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M14.6181405,13.1637859 L10.2521518,13.1456579 C9.97532436,13.0356746 9.79945038,12.8804275 9.79945038,12.3865759 C9.79945038,11.8927242 9.99578017,11.4644761 10.2726076,11.3544928 L14.6385963,11.3726207 C14.9154237,11.482604 15.1117534,11.9108521 15.1117534,12.4047038 C15.1117534,12.8985555 14.8949678,13.0538025 14.6181405,13.1637859 Z"
                  id="Path_831"
                  fill="#FFFFFF"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
