import React from "react";

export const LogoutIcon: React.FC = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.26406 5.808C7.02406 5.808 7.74405 5.964 8.42406 6.276C9.08006 6.58 9.65405 7.006 10.1461 7.554C10.6381 8.102 11.0001 8.724 11.2321 9.42L10.0561 9.792C9.88806 9.264 9.61606 8.792 9.24006 8.376C8.86405 7.96 8.42406 7.636 7.92006 7.404C7.40005 7.164 6.84806 7.044 6.26406 7.044C5.78405 7.044 5.32006 7.128 4.87206 7.296C4.43205 7.456 4.03606 7.688 3.68406 7.992L5.50806 9.792H1.00806V5.292L2.78406 7.092C3.25606 6.684 3.78405 6.372 4.36806 6.156C4.97606 5.924 5.60805 5.808 6.26406 5.808Z"
        fill="black"
      />
      <path
        d="M6.26406 5.808C7.02406 5.808 7.74405 5.964 8.42406 6.276C9.08006 6.58 9.65405 7.006 10.1461 7.554C10.6381 8.102 11.0001 8.724 11.2321 9.42L10.0561 9.792C9.88806 9.264 9.61606 8.792 9.24006 8.376C8.86405 7.96 8.42406 7.636 7.92006 7.404C7.40005 7.164 6.84806 7.044 6.26406 7.044C5.78405 7.044 5.32006 7.128 4.87206 7.296C4.43205 7.456 4.03606 7.688 3.68406 7.992L5.50806 9.792H1.00806V5.292L2.78406 7.092C3.25606 6.684 3.78405 6.372 4.36806 6.156C4.97606 5.924 5.60805 5.808 6.26406 5.808Z"
        fill="#212B40"
      />
      <path
        d="M4.96368 4H2.96368V3C2.96368 1.34315 4.30683 0 5.96368 0H13.0071C14.6639 0 16.0071 1.34315 16.0071 3V13C16.0071 14.6569 14.6639 16 13.0071 16H5.96368C4.30683 16 2.96368 14.6569 2.96368 13V12H4.96368V13C4.96368 13.5523 5.4114 14 5.96368 14H13.0071C13.5594 14 14.0071 13.5523 14.0071 13V3C14.0071 2.44772 13.5594 2 13.0071 2H5.96368C5.4114 2 4.96368 2.44772 4.96368 3V4Z"
        fill="#212B40"
      />
    </svg>
  );
};
