import React from "react";
import Result from "antd/lib/result";
import { Routes, Route } from "react-router-dom";
import { CaregiverRoutesRef } from "constants/routes";
import { Symptoms } from "pages/symptoms";
import { RemoteVitals } from "pages/remoteVitals";
import { MedicationsPage } from "pages/medications";
import { LaboratoryPage } from "pages/laboratory/laboratoryPage";
import { Careguidelines } from "pages/careguidelines";
import { MyCurrentStatus } from "pages/myCurrentStatus";
import { CareTeam } from "pages/careteam";
import { AddSymptomsForm } from "pages/symptoms/AddSymptomsForm";
import { PatientAllMessages } from "pages/patientAllMessages";
import { MedicationProvider } from "contextApis/medicationContext";
import { Images } from "pages/images";
import { CarePlan } from "pages/carePlan";
import { EducationalTips } from "pages/educationalTips";
import { MedicalDataEarnRewards } from "pages/medicalDataEarnRewards";
import { HealthStatusEarnRewards } from "pages/healthStatusEarnRewards";
import { ImproveMyRisk } from "pages/improveMyRisk";
import { LearningCenter } from "pages/learningCenter";
import { Appointment } from "pages/appointment";
import { Patients } from "pages/patients";
import { CaregiversLayout } from "layouts/caregiversLayout";
import { PatientMessages } from "pages/patientMessages";
import { CaregiverAllMessages } from "pages/caregiverAllMessages";
import { PatientListProvider } from "contextApis/patientsListContext";
import { AllNotifications } from "pages/allNotifications";
import { SurveysPage } from "pages/surveys/surveys";
import { RespondPage } from "pages/surveys/respond/respond";
import { SurveyProvider } from "contextApis/surveyContext";

export const CaregiverRoutes: React.FC = () => {
  return (
    <div>
      <CaregiversLayout>
        <Routes>
          <Route path={`/`} element={<Symptoms />} />
          <Route
            path={`/${CaregiverRoutesRef.Patients}`}
            element={
              <PatientListProvider>
                <Patients />
              </PatientListProvider>
            }
          />
          <Route
            path={`/${CaregiverRoutesRef.MyCurrentStatus}/:id`}
            element={<MyCurrentStatus />}
          />
          <Route
            path={`/${CaregiverRoutesRef.MyCurrentStatus}/:id`}
            element={<MyCurrentStatus />}
          />
          <Route
            path={`/${CaregiverRoutesRef.Symptoms}/:id`}
            element={<Symptoms />}
          />
          <Route
            path={`/${CaregiverRoutesRef.RemoteVitals}/:id`}
            element={<RemoteVitals />}
          />
          <Route
            path={`/${CaregiverRoutesRef.MyCareTeam}/:id`}
            element={<CareTeam />}
          />
          <Route
            path={`/${CaregiverRoutesRef.Medications}/:id`}
            element={
              <MedicationProvider>
                <MedicationsPage />
              </MedicationProvider>
            }
          />
          <Route
            path={`/${CaregiverRoutesRef.Laboratory}/:id`}
            element={<LaboratoryPage />}
          />
          <Route
            path={`${CaregiverRoutesRef.ReportSymptoms}/:id`}
            element={<AddSymptomsForm />}
          />
          <Route
            path={`/${CaregiverRoutesRef.CareGuidelines}/:id`}
            element={<Careguidelines />}
          />

          <Route
            path={`${CaregiverRoutesRef.AllMessages}/:id`}
            element={<PatientAllMessages />}
          />
          <Route
            path={`${CaregiverRoutesRef.Images}/:id`}
            element={<Images />}
          />
          <Route
            path={`${CaregiverRoutesRef.CarePlan}/:id`}
            element={<CarePlan />}
          />
          <Route
            path={`${CaregiverRoutesRef.EducationalTips}/:id`}
            element={<EducationalTips />}
          />
          <Route
            path={`${CaregiverRoutesRef.EarnRewardsMyMedicalData}/:id`}
            element={<MedicalDataEarnRewards />}
          />
          <Route
            path={`${CaregiverRoutesRef.EarnRewards}/:id`}
            element={<HealthStatusEarnRewards />}
          />
          <Route
            path={`${CaregiverRoutesRef.ImproveMyRisk}/:id`}
            element={<ImproveMyRisk />}
          />
          <Route
            path={`${CaregiverRoutesRef.LearningCenter}/:id`}
            element={<LearningCenter />}
          />
          <Route
            path={`/${CaregiverRoutesRef.Appointments}/:id`}
            element={<Appointment />}
          />
          <Route
            path={`/${CaregiverRoutesRef.PatientMessages}/:id`}
            element={<PatientMessages />}
          />
          <Route
            path={`/${CaregiverRoutesRef.AllMessages}`}
            element={<CaregiverAllMessages />}
          />
          <Route
            path={`/${CaregiverRoutesRef.AllNotifications}`}
            element={<AllNotifications />}
          />
          <Route
            path="*"
            element={
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
              />
            }
          />
          <Route
            path={`/${CaregiverRoutesRef.Survey}/:id`}
            element={
              <SurveyProvider>
                <SurveysPage />
              </SurveyProvider>
            }
          />
          <Route
            path={`/${CaregiverRoutesRef.Respond}/:id`}
            element={
              <SurveyProvider>
                <RespondPage />
              </SurveyProvider>
            }
          />
          <Route
            path={`/${CaregiverRoutesRef.ViewForm}/:type/:id`}
            element={
              <SurveyProvider>
                <RespondPage />
              </SurveyProvider>
            }
          />
        </Routes>
      </CaregiversLayout>
    </div>
  );
};
