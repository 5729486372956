import React, { useEffect, useState } from "react";
import { AppTextInput } from "components/inputs";

export interface MedicineSigDetailsProps {
  readonly sigDetails?: string;
}

interface MedicineSigDetailsInputProps {
  readonly value?: MedicineSigDetailsProps;
  readonly onChange?: (value: MedicineSigDetailsProps) => void;
}

export const MedicineSigDetailsInput: React.FC<
  MedicineSigDetailsInputProps
> = ({ value, onChange }) => {
  const [sigDetails, setSigDetails] = useState<string>(value?.sigDetails || "");

  const onSigDetailsChange = (value: string) => {
    setSigDetails(value);
    triggerChange({ sigDetails: value });
  };

  const triggerChange = (changedValue: MedicineSigDetailsProps) => {
    onChange?.({
      sigDetails,
      ...value,
      ...changedValue
    });
  };

  useEffect(() => {
    triggerChange({ sigDetails: sigDetails });
  }, []);

  return (
    <AppTextInput
      style={{
        width: "490px",
        borderRadius: "8px",
        placeItems: "center"
      }}
      value={sigDetails}
      label="Sig Details"
      placeholder="Enter Sig Details"
      onChange={onSigDetailsChange}
      labelStyles={{ zIndex: 1000 }}
      textStyles={{ height: "46px" }}
    />
  );
};
