const SDOH = [
  {
    question:
      "Are you concerned that in the next two months you may not have stable housing that you own, rent, or stay in?",
    answer: ""
  },
  {
    question:
      "Are you concerned about not having access to food in the next 2 months?",
    answer: ""
  },
  {
    question:
      "Do you put off or neglect going to a doctor because of distance or transportation?",
    answer: ""
  },
  {
    question:
      "Does access to childcare make it difficult for you to work or study?",
    answer: ""
  },
  {
    question: "Do you have a job?",
    answer: ""
  },
  {
    question: "Are you able to pay your monthly bills regularly?",
    answer: ""
  },
  {
    question:
      "Do you feel physically and emotionally safe in the environment that you live in?",
    answer: ""
  }
];

export default SDOH;
