import React from "react";

export const MedicalDataSymptomsIcon: React.FC = () => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="LeftPannel-Patient-0"
          transform="translate(-13.000000, -15.000000)"
        >
          <g
            id="Symptoms-menu-Copy-32"
            transform="translate(14.000000, 14.000000)"
          >
            <g id="Symptoms-icon" transform="translate(7.000000, 7.000000)">
              <g id="Rectangle-Copy-205">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill="#208CFF"
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <path
                d="M16.9821473,15.6086298 C16.9821473,15.0983753 16.739169,14.6512952 16.3601228,14.3645807 L16.3601228,10.6324334 C16.3601228,10.1173194 15.9422,9.69939661 15.4270859,9.69939661 C14.9119718,9.69939661 14.4940491,10.1173194 14.4940491,10.6324334 L14.4940491,14.3645807 C14.1150029,14.6512952 13.8720246,15.0983753 13.8720246,15.6086298 C13.8720246,16.4687732 14.5669426,17.1636912 15.4270859,17.1636912 C16.2872293,17.1636912 16.9821473,16.4687732 16.9821473,15.6086298 Z M17.6041719,15.6086298 C17.6041719,16.8089428 16.6273989,17.7857158 15.4270859,17.7857158 C14.2267729,17.7857158 13.25,16.8089428 13.25,15.6086298 C13.25,15.0157627 13.4881188,14.4812103 13.8720246,14.0875854 L13.8720246,10.6324334 C13.8720246,9.77229011 14.5669426,9.07737205 15.4270859,9.07737205 C16.2872293,9.07737205 16.9821473,9.77229011 16.9821473,10.6324334 L16.9821473,14.0875854 C17.3660531,14.4812103 17.6041719,15.0157627 17.6041719,15.6086298 Z"
                id=""
                stroke="#FFFFFF"
                strokeWidth="0.4"
                fill="#FFFFFF"
              ></path>
              <path
                d="M16.3601228,15.6086298 C16.3601228,16.1237439 15.9422,16.5416667 15.4270859,16.5416667 C14.9119718,16.5416667 14.4940491,16.1237439 14.4940491,15.6086298 C14.4940491,15.2198645 14.7370274,14.8651161 15.1160737,14.7290482 L15.1160737,12.8095194 C15.1318422,12.5111616 15.2355129,12.3619827 15.4270859,12.3619827 C15.6186589,12.3619827 15.7223297,12.5111616 15.7380982,12.8095194 L15.7380982,14.7290482 C16.1171444,14.8651161 16.3601228,15.2198645 16.3601228,15.6086298 Z"
                id="Path"
                fill="#FFFFFF"
              ></path>
              <path
                d="M9.81344505,8.27611962 C9.01371938,6.49921086 7.8113064,5.68546181 6.12389316,5.74877449 L6.08139977,4.61623806 C8.24873666,4.53491838 9.86468462,5.62853275 10.8469307,7.81098319 C11.7408525,9.79718618 12.3372583,11.1603001 12.6383996,11.9059721 L12.8405638,12.4065616 L11.2458558,13.1422673 L11.2458558,16.8347155 L10.751525,16.8983381 C9.89928643,17.008025 9.15476574,16.9829723 8.52044773,16.8156336 C8.52051877,17.1474144 8.52003221,17.4912066 8.51898815,17.8470129 L7.38565969,17.8436873 C7.38760822,17.1796465 7.38760822,16.5578058 7.38566045,15.9781816 L7.3823177,14.9834322 L8.23968246,15.4878758 C8.68937356,15.7524584 9.31205129,15.8684722 10.1125225,15.821319 L10.1125225,12.4169946 L11.3809576,11.8318122 C11.050768,11.0497166 10.527873,9.86350561 9.81344505,8.27611962 Z"
                id="Path-3"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
