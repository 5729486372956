export const resetCareTeamDropdownValues = () => {
  let setFilters: any = {};

  try {
    const storedValue = localStorage?.getItem("disableComponent");
    if (storedValue) {
      setFilters = JSON.parse(storedValue);
    }
  } catch (error) {
    console.error("Error parsing disableComponent from localStorage:", error);
    setFilters = {};
  }

  const careTeamMenuItemsOne = [
    {
      desc: "Physicians",
      checked: true,
      value: "physician"
    },
    {
      desc: "Case Managers",
      checked: true,
      value: "case_manager"
    },
    {
      desc: "Dieticians",
      checked: true,
      value: "dietician"
    },
    {
      desc: "Nurses",
      checked: true,
      value: "nurse"
    },
    {
      desc: "Social Workers",
      checked: true,
      value: "social_worker"
    },
    {
      desc: "Care Givers",
      checked: true,
      value: "caregivers"
    },
    {
      desc: "Others",
      checked: true,
      value: "other"
    }
  ];

  const careTeamMenuItemsTwo = [
    {
      desc: "Physicians",
      checked: true,
      value: "physician"
    },
    {
      desc: "Case Managers",
      checked: true,
      value: "case_manager"
    },
    {
      desc: "Medical Assistance",
      checked: true,
      value: "nurse"
    },
    {
      desc: "Care Givers",
      checked: true,
      value: "caregivers"
    }
  ];

  const careTeamMenuItems =
    setFilters && setFilters?.vlan_base_care_plan === 1
      ? careTeamMenuItemsTwo
      : careTeamMenuItemsOne;

  sessionStorage.setItem(
    "careTeamCheckboxItems",
    JSON.stringify(careTeamMenuItems)
  );
};
