import React, { useContext, useEffect, useRef, useState } from "react";
import Spin from "antd/lib/spin";
import Result from "antd/lib/result";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { useNavigate } from "react-router";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import { PatientsRoutesRef } from "constants/routes";
import { Colors } from "helpers/colors";
import { SurveyLinkData } from "sharedTypes";
import { DialysisSymptomsIcon } from "svgIcons/dialysisSymptoms";
import Tick from "svgIcons/tick";
import { VitalSignsIcon } from "svgIcons/vitalSigns";
import {
  AddSymptomFormButtonContainer,
  AddSymptomFormItem,
  AddSymptomFormItemIconContainer,
  AddSymptomFormItemTextContainer,
  AddSymptomFormSubtitleText,
  AddSymptomFormTitleText,
  AddSymptomsOverflowContainer,
  TickContainer
} from "./styles";
import { UserRoles } from "constants/roles";
import { NauseaSurveyForm } from "./surveyForms/nauseaSurveyForm/nauseaSurveyForm";
import { AchesAndPainForm } from "./surveyForms/achesAndPainForm/achesAndPainForm";
import { AppetiteImpairmentForm } from "./surveyForms/appetiteImpairmentForm/appetiteImpairmentForm";
import { ChestPainForm } from "./surveyForms/chestPainForm/chestPainForm";
import { FallsForm } from "./surveyForms/fallsForm/fallsForm";
import { FatigueForm } from "./surveyForms/fatigueForm/fatigueForm";
import { FeverForm } from "./surveyForms/feverForm/feverForm";
import { indexOf, sortBy } from "lodash";
import { ShortnessOfBreathForm } from "./surveyForms/shortnessOfBreathForm/shortnessOfBreathForm";
import { LegSwellingForm } from "./surveyForms/legSwellingForm/legSwellingForm";
import { WeightChangeForm } from "./surveyForms/weightChangeForm/weightChangeForm";
import { LightheadednessForm } from "./surveyForms/lightheadednessForm/lightheadednessForm";
import { MoodImpairmentForm } from "./surveyForms/moodImpairmentForm/moodImpairmentForm";
import { UlcersForm } from "./surveyForms/ulcersForm/ulcersForm";
import { VitalSignsForm } from "./surveyForms/vitalSignsForm/vitalSignsForm";
import { UrinarySymptomsForm } from "./surveyForms/urinarySymptomsForm/urinarySymptomsForm";
import { DialysisSymptomsForm } from "./surveyForms/dialysisSymptomsForm/dialysisSymptomsForm";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";

interface AddSymptomsFormProps {
  readonly onAddNewSymptomFormClose?: () => void;
  readonly patientId?: number | null;
}

interface SymptomFormDataObject {
  [key: string]: boolean;
}

export const AddSymptomsForm: React.FC<AddSymptomsFormProps> = ({
  onAddNewSymptomFormClose,
  patientId
}) => {
  const patientContext = useContext<PatientContextState>(PatientContext);
  const [symptomFormDataObject, setSymptomFormDataObject] =
    useState<SymptomFormDataObject>({});
  const [symptomList, setSymptomList] = useState<SurveyLinkData[]>([]);
  const userId = sessionStorage.getItem("userId") || "";
  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;
  const [showSurveyForm, setShowSurveyForm] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([""]);
  const formTopRef = useRef<any>();

  const navigate = useNavigate();
  const { data, isFetching, isLoading } = useQuery(
    "survey-links",
    async () => {
      return createApiClient().getSurveyLinks(
        userIsPatient ? userId : patientId ? patientId : ""
      );
    },
    {
      enabled: userIsPatient
        ? userId
          ? true
          : false
        : patientId
        ? true
        : false
    }
  );

  const [selectedSymptoms, setSelectedSymptoms] = useState<SurveyLinkData[]>(
    []
  );

  const onSymptomSelectionChange =
    (symptom: string, isSelected: boolean) => () => {
      const newSymptomObject: SymptomFormDataObject = {
        ...symptomFormDataObject
      };
      newSymptomObject[symptom] = isSelected;
      setSymptomFormDataObject(newSymptomObject);
    };

  useEffect(() => {
    if (!showSurveyForm) {
      localStorage.clear();
    }

    if (data) {
      const newSymptomList: SurveyLinkData[] = data.map(item => ({
        ...item,
        icon:
          item.key === "Vital Signs" ? (
            <VitalSignsIcon />
          ) : item.key === "Dialysis Symptom" ? (
            <DialysisSymptomsIcon />
          ) : null
      }));
      const order = [
        "Shortness of Breath",
        "Leg Swelling",
        "Weight Change",
        "Fatigue",
        "Chest Pain",
        "Aches/Pain",
        "Lightheadedness",
        "Falls",
        "Fever",
        "Urinary Symptoms",
        "Ulcers",
        "Nausea",
        "Vital Signs",
        "Appetite Impairment",
        "Mood Impairment",
        "Dialysis Symptom"
      ];

      const sortedByExpectedOrder = sortBy(newSymptomList, function (obj) {
        return indexOf(order, obj.key);
      });

      setSymptomList(sortedByExpectedOrder);
      const lastMonthSymptoms = [
        ...patientContext.patientLastMonthSymptoms
      ].map(item => {
        return item.trim().toLowerCase();
      });

      const symptomDataObject = data.reduce(
        (acc: SymptomFormDataObject, symptom) => {
          acc[symptom.key] = lastMonthSymptoms.includes(
            symptom.key.trim().toLowerCase()
          )
            ? true
            : false;
          return acc;
        },
        {}
      );
      setSymptomFormDataObject(symptomDataObject);
    }
  }, [data, showSurveyForm]);

  useEffect(() => {
    setSelectedSymptoms(
      symptomList.filter(symptom => symptomFormDataObject[symptom.key])
    );
  }, [symptomFormDataObject]);

  const goToNextFormOrReturn = () => {
    if (selectedItems.length > 1) {
      const [, ...updatedSelectedItems] = selectedItems;
      setSelectedItems(updatedSelectedItems || []);
    } else {
      setShowSurveyForm(false);
      if (data) {
        setSymptomFormDataObject(
          data.reduce((acc: SymptomFormDataObject, symptom) => {
            acc[symptom.key] = false;
            return acc;
          }, {})
        );
      }
    }
  };

  const onNextButtonClick = () => {
    setShowSurveyForm(true);
    setSelectedItems(selectedSymptoms.map(item => item.key));

    // selectedSymptoms.forEach(item => window.open(item.link, "_blank"));
    // onAddNewSymptomFormClose?.();
  };

  const showRelevantForm = (currentForm: string) => {
    switch (currentForm) {
      case "Nausea":
        return (
          <NauseaSurveyForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Aches/Pain":
        return (
          <AchesAndPainForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Appetite Impairment":
        return (
          <AppetiteImpairmentForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Chest Pain":
        return (
          <ChestPainForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Falls":
        return (
          <FallsForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Fatigue":
        return (
          <FatigueForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Fever":
        return (
          <FeverForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Shortness of Breath":
        return (
          <ShortnessOfBreathForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Leg Swelling":
        return (
          <LegSwellingForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Weight Change":
        return (
          <WeightChangeForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Lightheadedness":
        return (
          <LightheadednessForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Mood Impairment":
        return (
          <MoodImpairmentForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Ulcers":
        return (
          <UlcersForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Vital Signs":
        return (
          <VitalSignsForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Urinary Symptoms":
        return (
          <UrinarySymptomsForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      case "Dialysis Symptom":
        return (
          <DialysisSymptomsForm
            setShowSurveyForm={setShowSurveyForm}
            selectedItems={selectedItems}
            goToNextFormOrReturn={goToNextFormOrReturn}
          />
        );
      default:
        return (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <AppButton
                type={ButtonType.Primary}
                onClick={() => setShowSurveyForm(false)}
                buttonContent={<>Back</>}
              />
            }
          />
        );
    }
  };

  useEffect(() => {
    formTopRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }, [selectedItems]);

  return (
    <>
      <div ref={formTopRef} />
      {showSurveyForm ? (
        showRelevantForm(selectedItems[0])
      ) : (
        <>
          <AddSymptomFormTitleText>Report symptoms</AddSymptomFormTitleText>
          <AddSymptomFormSubtitleText>
            Please select the symptoms you would like to report.
          </AddSymptomFormSubtitleText>
          <AppCard
            cardHeight={onAddNewSymptomFormClose ? "100%" : "100%"}
            cardWidth="100%"
            style={{ marginTop: 10 }}
          >
            <AddSymptomsOverflowContainer
              style={
                onAddNewSymptomFormClose
                  ? { height: "100%" }
                  : { height: "100%" }
              }
            >
              {isLoading && isFetching ? (
                <Spin
                  style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100%"
                  }}
                />
              ) : (
                <>
                  <Row gutter={[10, 10]}>
                    {symptomList.map(symptom => (
                      <Col xs={24} sm={12} md={8} lg={6} key={symptom.key}>
                        <AddSymptomFormItem
                          selected={symptomFormDataObject[symptom.key]}
                          onClick={onSymptomSelectionChange(
                            symptom.key,
                            !symptomFormDataObject[symptom.key]
                          )}
                          style={{ cursor: "pointer" }}
                        >
                          {symptomFormDataObject[symptom.key] && (
                            <TickContainer>
                              <Tick
                                height="20px"
                                width="20px"
                                color={`${Colors.DodgerBlue}`}
                              />
                            </TickContainer>
                          )}
                          <Space size={20}>
                            {symptom.icon !== null && (
                              <AddSymptomFormItemIconContainer>
                                {symptom.icon}
                              </AddSymptomFormItemIconContainer>
                            )}
                            <AddSymptomFormItemTextContainer>
                              {symptom.key}
                            </AddSymptomFormItemTextContainer>
                          </Space>
                        </AddSymptomFormItem>
                      </Col>
                    ))}
                  </Row>
                  <AddSymptomFormButtonContainer>
                    <AppButton
                      buttonContent="Back"
                      onClick={() =>
                        onAddNewSymptomFormClose
                          ? onAddNewSymptomFormClose()
                          : navigate(PatientsRoutesRef.Symptoms)
                      }
                      type={ButtonType.Secondary}
                      style={{ width: 120 }}
                    />
                    <AppButton
                      buttonContent="Next"
                      onClick={onNextButtonClick}
                      type={ButtonType.Primary}
                      disabled={selectedSymptoms.length <= 0}
                      style={{ width: 120, marginRight: 10, marginLeft: 12 }}
                    />
                  </AddSymptomFormButtonContainer>
                </>
              )}
            </AddSymptomsOverflowContainer>
          </AppCard>
        </>
      )}
    </>
  );
};
