import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import uniqBy from "lodash/uniqBy";
import get from "lodash/get";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppSelect, AppTextInput } from "components/inputs";
import { SelectOption } from "components/inputs/selectInput";

export interface MedicationDurationProps {
  readonly durationNumber: string;
  readonly durationUnit: string;
}

interface MedicationDurationFormProps {
  readonly value?: MedicationDurationProps;
  readonly onChange?: (value: MedicationDurationProps) => void;
}

export const MedicationDurationForm: React.FC<MedicationDurationFormProps> = ({
  value,
  onChange
}) => {
  const [durationNumber, setDurationNumber] = useState<string>(
    get(value, "durationNumber", "")
  );
  const [durationUnit, setDurationUnit] = useState<string>(
    get(value, "durationUnit", "")
  );
  const { data } = useQuery("medication-duration", async () => {
    return createApiClient().getMedicineDuration();
  });

  const durationUnitOptions: SelectOption<string>[] = useMemo(() => {
    if (!data) return [];
    return uniqBy(
      data.map(item => ({ label: item, value: item })),
      "value"
    );
  }, [data]);

  const onDurationNumberChange = (value: string) => {
    if (!isNaN(Number(value))) {
      setDurationNumber(value);
      triggerChange({
        durationNumber: value,
        durationUnit: durationUnit
      });
    }
  };

  const onDurationUnitChange = (value: string) => {
    setDurationUnit(value);
    triggerChange({
      durationNumber: durationNumber,
      durationUnit: value
    });
  };

  const onResetClick = () => {
    onDurationUnitChange("");
    onDurationNumberChange("");
    triggerChange({
      durationNumber: "",
      durationUnit: ""
    });
  };

  const triggerChange = (changedValue: MedicationDurationProps) => {
    onChange?.({
      ...value,
      ...changedValue
    });
  };

  return (
    <MedicationBody>
      <AppTextInput
        value={durationNumber}
        label="Number"
        placeholder="Enter Duration"
        onChange={onDurationNumberChange}
        style={{ width: "240px" }}
        textStyles={{ height: "46px" }}
      />

      <AppSelect
        value={durationUnit}
        label="Duration"
        onChange={onDurationUnitChange}
        style={{ marginRight: 5, width: "240px" }}
        options={durationUnitOptions}
        dropdownStyle={{ borderRadius: "8px" }}
        placeholder="Enter Unit"
      />

      <AppButton
        type={ButtonType.Link}
        buttonContent={"Reset"}
        onClick={onResetClick}
        style={{ display: "grid", placeItems: "center", height: "46px" }}
      />
    </MedicationBody>
  );
};

const MedicationBody = styled.div`
  display: flex;
  gap: 20px;
`;
