import React from "react";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Table, { ColumnsType } from "antd/lib/table";
import styled from "styled-components";
import { AppCard } from "components/card";
import { CircleIndicator } from "components/circleIndicator";
import { MessageRow } from "components/dropDownBox/customStyles";
import { Colors } from "helpers/colors";

interface TableRow {
  readonly taskname: string;
  readonly status: number;
}

interface TasksTableProps {
  readonly setSocialParams: React.Dispatch<React.SetStateAction<boolean>>;
}
interface Status {
  readonly innerColor: string;
  readonly outerColor: string;
  readonly status: string;
}
const Status: Status[] = [
  {
    outerColor: "#FBBBB8",
    innerColor: "#F31F13",
    status: "Not Started"
  },
  {
    innerColor: "#DD951F",
    outerColor: "#FCE5C6",
    status: "In Progress"
  },
  {
    innerColor: "#04A468",
    outerColor: "#B3E4D1",
    status: "Completed"
  }
];

export const TasksTable: React.FC<TasksTableProps> = ({ setSocialParams }) => {
  const tasksData = [
    {
      id: 1,
      taskname: "Social Determinants",
      status: 0
    },
    {
      id: 2,
      taskname: "Behavioral Health",
      status: 1
    },
    {
      id: 3,
      taskname: "Education",
      status: 1
    },
    {
      id: 4,
      taskname: "Risk Factors",
      status: 1
    },
    {
      id: 4,
      taskname: "Comorbidities",
      status: 2
    }
  ];

  const columns: ColumnsType<TableRow> = [
    {
      title: "Task Name",
      key: "taskname",
      dataIndex: "taskname",
      width: "33%",
      render: (taskname: number) => {
        return (
          <MessageRow
            style={{ fontWeight: "bold", marginLeft: "10px" }}
            fontSize={"14px"}
          >
            {taskname}
          </MessageRow>
        );
      }
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: "33%",
      render: (status: number) => (
        <Row gutter={[10, 0]}>
          <Col style={{ display: "grid", placeItems: "center" }}>
            <CircleIndicator
              outerColor={Status[status].outerColor}
              innerColor={Status[status].innerColor}
            />
          </Col>
          <Col style={{ fontSize: "13px" }}>{Status[status].status}</Col>
        </Row>
      )
    },

    {
      key: "actions",
      dataIndex: "actions",
      render: () => (
        <div
          style={{
            display: "grid",
            placeItems: "right",
            paddingRight: "10px",
            height: "44px",
            width: "44px"
          }}
        >
          <Space size={20}>
            <ActionSpan>
              <EyeOutlined
                style={{ fontSize: "20px", color: Colors.LightGrey }}
                onClick={() => setSocialParams(true)}
              />
            </ActionSpan>
            <ActionSpan>
              <EditOutlined
                style={{ fontSize: "20px", color: Colors.LightGrey }}
                onClick={() => setSocialParams(true)}
              />
            </ActionSpan>
          </Space>
        </div>
      ),
      width: "10%"
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      {/* <TasksDetailTable data={tasksData} /> */}
      <AppCard
        cardHeight="inherit"
        cardWidth="100%"
        style={{
          borderRadius: "0px 0px 8px 8px",
          marginTop: "-2px"
        }}
      >
        <Table<TableRow>
          columns={columns}
          dataSource={tasksData}
          rowKey="id"
          pagination={false}
          scroll={{ y: "55vh" }}
        />
      </AppCard>
    </div>
  );
};

const ActionSpan = styled.span`
  cursor: pointer;
`;
