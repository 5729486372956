import moment from "moment";
import { lowerCaseRemoveSpace } from "helpers/utils";
import { ExtendedSymptomData } from "pages/symptoms/SymptomsModal";

export const chestPainMapper = ({
  filteredExtendedSymptomData,
  mapperType,
  mapperValue
}: {
  filteredExtendedSymptomData: ExtendedSymptomData[] | null;
  mapperType?: string | undefined;
  mapperValue?: string | number | undefined;
}) => {
  const severity: [string, number, string][] = [];
  const frequency: [string, number, string][] = [];
  const duration: [string, number, string][] = [];

  const sortedData = filteredExtendedSymptomData?.sort(
    (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
  );

  if (mapperType) {
    let tooltipValue = "";
    if (lowerCaseRemoveSpace(mapperType) === "frequency") {
      switch (mapperValue) {
        case 1:
          tooltipValue = "1-2 times per month";
          break;
        case 4:
          tooltipValue = "3-5 times per month";
          break;
        case 7:
          tooltipValue = "6-10 times per month";
          break;
        case 10:
          tooltipValue = "More than 10 times per month";
          break;
      }
    } else if (lowerCaseRemoveSpace(mapperType) === "duration") {
      switch (mapperValue) {
        case 1:
          tooltipValue = "Less than 5 mins";
          break;
        case 4:
          tooltipValue = "5 to 15 mins";
          break;
        case 7:
          tooltipValue = "15 to 30 mins";
          break;
        case 10:
          tooltipValue = "More than 30 mins";
          break;
      }
    }
    return tooltipValue;
  }

  sortedData?.forEach(item => {
    item.data?.forEach(item2 => {
      if (lowerCaseRemoveSpace(item2?.key) === "severity") {
        severity.push([
          String(moment(item.timestamp).valueOf()),
          Number(item2.value),
          "Severity"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "frequency") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2.value)) {
          case "1-2timespermonth":
            value = 1;
            break;
          case "3-5timespermonth":
            value = 4;
            break;
          case "6-10timespermonth":
            value = 7;
            break;
          case "morethan10timespermonth":
            value = 10;
            break;
        }
        frequency.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Frequency"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "duration") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "lessthan5mins":
            value = 1;
            break;
          case "5to15mins":
            value = 4;
            break;
          case "15to30mins":
            value = 7;
            break;
          case "morethan30mins":
            value = 10;
            break;
        }
        duration.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Duration"
        ]);
      }
    });
  });

  return [severity, frequency, duration];
};
