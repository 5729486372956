import { Result } from "antd";
import React from "react";
import { UserRoles } from "constants/roles";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";

export const LearningCenterPage: React.FC = () => {
  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const showTopbarExtesion = userIsCaregiver;
  return (
    <>
      {showTopbarExtesion && <CommonPatientExtensionItems />}
      <Result
        status="404"
        title="Work In Progress"
        subTitle="Sorry, the page you visited is still in development."
      />
    </>
  );
};
