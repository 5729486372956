import React from "react";

export const LaboratoryIcon: React.FC = () => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Provider-Global-navigation-V2.3"
          transform="translate(-13.000000, -378.000000)"
        >
          <g
            id="Laboratory-menu-Copy-7"
            transform="translate(14.000000, 379.000000)"
          >
            <g id="Laboratory-icon" transform="translate(7.000000, 5.000000)">
              <g id="Rectangle-Copy-219">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill="#7CBA5B"
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <path
                d="M8.07014565,12.9893037 L7.44755,11.9310916 C8.78426632,11.1446389 10.2722723,11.3186241 11.8126422,12.3891163 C12.9600517,13.1865176 14.0617632,13.1865176 15.2506934,12.3843353 L15.9373985,13.4021145 C14.3311154,14.4858886 12.6783349,14.4858886 11.1119737,13.3973335 C9.93857286,12.5818693 8.95760544,12.4671697 8.07014565,12.9893037 Z"
                id="Path-4"
                fill="#FFFFFF"
              ></path>
              <path
                d="M13.5144444,12.7956667 C13.9699282,12.4884799 14.3221284,12.0965579 14.5710556,11.6198889 C14.8199827,11.1432198 14.9444444,10.6347805 14.9444444,10.0945556 C14.9444444,9.49077476 14.7961496,8.93466921 14.4995556,8.42622222 C14.2029615,7.91777524 13.800447,7.51526074 13.292,7.21866667 C12.783553,6.92207259 12.2274475,6.77377778 11.6236667,6.77377778 C11.0198859,6.77377778 10.4637803,6.92207259 9.95533333,7.21866667 C9.44688635,7.51526074 9.04437185,7.91777524 8.74777778,8.42622222 C8.4511837,8.93466921 8.30288889,9.49077476 8.30288889,10.0945556 C8.30288889,10.6347805 8.42735061,11.1432198 8.67627778,11.6198889 C8.92520495,12.0965579 9.27740513,12.4884799 9.73288889,12.7956667 C10.0365447,13.0004578 10.4626558,13.1328652 11.0112222,13.1928889 L11.0112222,16.1626667 L12.2361111,16.1626667 L12.2361111,13.1928889 C12.7846775,13.1328652 13.2107886,13.0004578 13.5144444,12.7956667 Z M11.6236667,5.47088889 C12.4604857,5.47088889 13.2390334,5.68273862 13.9593333,6.10644444 C14.6478553,6.51955762 15.1986646,7.07036693 15.6117778,7.75888889 C16.0354836,8.47918879 16.2473333,9.25773656 16.2473333,10.0945556 C16.2473333,10.8784113 16.0672611,11.6039967 15.7071111,12.2713333 C15.3575538,12.9068921 14.433756,13.6531197 13.539,13.8761111 L13.539,16.8458889 C13.539,17.0259639 13.4754451,17.1795549 13.3483333,17.3066667 C13.2212216,17.4337784 13.0676305,17.4973333 12.8875556,17.4973333 L10.3597778,17.4973333 C10.1797028,17.4973333 10.0261117,17.4337784 9.899,17.3066667 C9.77188825,17.1795549 9.70833333,17.0259639 9.70833333,16.8458889 L9.70833333,13.8761111 C8.83513921,13.606093 7.88977953,12.9068921 7.54022222,12.2713333 C7.18007227,11.6039967 7,10.8784113 7,10.0945556 C7,9.25773656 7.21184973,8.47918879 7.63555556,7.75888889 C8.04866873,7.07036693 8.59947804,6.51955762 9.288,6.10644444 C10.0082999,5.68273862 10.7868477,5.47088889 11.6236667,5.47088889 Z"
                id="lightbulb_outline---material"
                stroke="#FFFFFF"
                strokeWidth="0.1"
                fill="#FFFFFF"
                transform="translate(11.623667, 11.484111) rotate(-180.000000) translate(-11.623667, -11.484111) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
