import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { UserRoles } from "constants/roles";
import { ProvidersRoutesRef } from "constants/routes";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { Colors } from "helpers/colors";
import { ParentTabs } from "components/tabs/parentTabs";

export const TopbarComponent: React.FC = () => {
  const epicLogin: any = sessionStorage.getItem("epicLogin");
  const tabsContent = [
    {
      key: ProvidersRoutesRef.Patients,
      tabName: "Patients",
      onClickRedirectPath: ProvidersRoutesRef.Patients
    },
    {
      key: ProvidersRoutesRef.Providers,
      tabName: "Providers",
      onClickRedirectPath: ProvidersRoutesRef.Providers
    },
    {
      key: ProvidersRoutesRef.Messages,
      tabName: "Messages",
      onClickRedirectPath: ProvidersRoutesRef.Messages
    },
    {
      key: ProvidersRoutesRef.Dashboard,
      tabName: "Dashboard",
      onClickRedirectPath: ProvidersRoutesRef.Dashboard
    },
    {
      key: ProvidersRoutesRef.Billings,
      tabName: "Billings",
      onClickRedirectPath: ProvidersRoutesRef.Billings
    }
  ];

  const userId = sessionStorage.getItem("userId");

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const { refetch: getNotificationList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        UserRoles.PROVIDER
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      },
      refetchInterval: 60000
    }
  );

  useEffect(() => {
    getNotificationList();
  }, []);

  const {
    isLoading: isLoadingPatientSurveyNotification,
    data: patientSurveyNotification,
    error: isPatientSurveyNotificationError,
    isFetching: isFetchingPatientSurveyNotification,
    refetch: refetchPatientSurveyNotification
  } = useQuery(
    "PatientSurveyNotification",
    async () => {
      const data = await createApiClient().getPatientSurveyNotification(
        Number(sessionStorage.getItem("patientId"))
      );
      sessionStorage.setItem("surveyNotification", data);
      notificationsContext.setPatientSurveyNotifications(data ? data : null);
    },
    {
      enabled: true
    }
  );

  return (
    <>
      {epicLogin !== "true" ? (
        <TopbarStyled>
          <div
            style={{
              height: "1px",
              backgroundColor: Colors.Lavender,
              position: "fixed",
              top: "60px",
              width: "100%"
            }}
          />
          <div
            style={{
              height: "45px",
              width: "510px",
              backgroundColor: Colors.AliceBlue,
              position: "fixed",
              top: "7px",
              borderRadius: "8px",
              left: "50%",
              transform: "translate(-50%, 0)"
            }}
          />
          <ParentTabs
            defaultActiveKey={tabsContent[0].key}
            tabsContent={tabsContent}
          />
        </TopbarStyled>
      ) : (
        <></>
      )}
    </>
  );
};

const TopbarStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 65px;
  top: 0;
  left: 0;
  background-color: ${Colors.White};
  z-index: 10;
`;
