import React from "react";
import { Routes, Route } from "react-router-dom";
import { MobileRoutesRef } from "constants/routes";
import { MobileSymptomForms } from "pages/mobileSymptomForms";

export const MobileRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path={`/${MobileRoutesRef.MobileSymptomForms}`}
        element={<MobileSymptomForms />}
      />
    </Routes>
  );
};
