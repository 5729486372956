import {
  EyeOutlined,
  EditOutlined,
  CheckOutlined,
  UploadOutlined,
  ThunderboltOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Radio,
  Space,
  Spin,
  message,
  Checkbox
} from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { AppCard } from "components/card";
import { MessageRow } from "components/dropDownBox/customStyles";
import { Colors } from "helpers/colors";
import Row from "antd/lib/row";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  CaregiverRoutesRef,
  PatientsRoutesRef,
  ProvidersRoutesRef
} from "constants/routes";
import { useMutation, useQuery } from "react-query";
import { createApiClient } from "../../apiClient";
import { useSurveyContext } from "contextApis/surveyContext";
import { AppModal } from "components/modal";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import Tooltip from "antd/lib/tooltip";

interface Survey {
  readonly assigner_name: string;
  readonly completed_at: string;
  readonly created_at: string;
  readonly frequency: number;
  readonly name: string;
  readonly id: number;
  readonly response_type: string;
  readonly responses: any;
  readonly status: "Completed" | "Draft" | "Pending" | 0 | 1 | 2;
  readonly submitter_name: string;
  readonly updated_at: string;
  readonly review_date: string;
  readonly score: number;
}

// interface Responses {
//   questions: string;
//   answers: string;
// }

interface SurveysTableProps {
  surveyAssignedData: any;
}

const SurveysTable: React.FC<SurveysTableProps> = ({
  surveyAssignedData
}): JSX.Element => {
  const patientContext = useContext<PatientContextState>(PatientContext);
  const userContext = useContext<UserContextState>(UserContext);

  const fileInputRef = useRef<any>(null);

  const [base64String, setBase64String] = useState<string>("");
  const [fileType, setFileType] = useState<string>("");
  const [fileSize, setFileSize] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>("");
  const [surveyData, setSurveyData] = useState<any>(null);
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);
  const [tooltipContent, setTooltipContent] = useState<React.ReactNode | null>(
    null
  );
  const [activationLevel, setActivationLevel] = useState("");
  const [treatmentActions, setTreatmentActions] = useState("");
  const [hasMouseEntered, setHasMouseEntered] = useState(false);

  const [PamModalOpen, setPamModalOpen] = useState<boolean>(false);
  const [scrollBottom, setScrollBottom] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMoreData, setHasMoreData] = useState(true);

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollBottom) {
      setPageNumber(prev => prev + 1);
    }
  }, [scrollBottom]);

  const tooltipInnerStyling = {
    borderRadius: "10px",
    backgroundColor: "white",
    color: "black",
    fontSize: 11
  };

  const uploadFile = async (
    type: string,
    file: string,
    id: number,
    selectedDate: any
  ) => {
    //upload api and validation
    const dateSelected = moment(selectedDate).format("YYYY-MM-DD");
    if (!selectedDate && fileSize === 0) {
      message.error(
        "Please select a date and upload a file. Both fields are required"
      );
      return;
    }
    if (!selectedDate) {
      message.error("Please select a date.");
      return;
    }
    if (fileSize === 0) {
      message.error("Unsupported file type. Please select a JPG or PDF file.");
      return;
    }
    if (fileSize > 5000000) {
      message.warning("File size too big. Please select a file below 5MB.");
      return;
    }
    try {
      setLoading(true);
      await createApiClient()
        .uploadPatientSurvey(type, file, id, dateSelected)
        .then((res: any) => {
          if (res === "success") {
            message.success("File Uploaded Successfully");
            setModalOpen(!modalOpen);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            setSelectedFile("");
            setFileSize(0);
          }
        });
      setLoading(false);
      refetchSurveyList();
    } catch (error: any) {
      setLoading(false);
      message.error(error.message);
      setModalOpen(!modalOpen);
    }
  };

  const handleFileChange = (event: any) => {
    if (event.target.files.length === 0) {
      setFileSize(0);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
    const selectedFileInput = event.target.files[0];
    if (selectedFileInput) {
      setSelectedFile(selectedFileInput);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension === "jpg" || fileExtension === "pdf") {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          const base64 = event.target.result.split(",")[1];
          setBase64String(base64);
          setFileType(fileExtension);
          setFileSize(selectedFile.size);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        message.error(
          "Unsupported file type. Please select a JPG or PDF file."
        );
        setBase64String("");
        setFileSize(0);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }
    }
  }, [selectedFile]);

  useEffect(() => {
    if (hasMouseEntered) {
      handleRowHover(surveyData);
    }
  }, [hasMouseEntered]);

  const uploadModalFunction = () => {
    setModalOpen(!modalOpen);
    setBase64String("");
    setFileSize(0);
    setSelectedDate(moment(formattedDate));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const currentDate = new Date();

  const formattedDate = currentDate.toISOString().substr(0, 10);

  const [selectedDate, setSelectedDate] = useState<any>(moment(formattedDate));

  const disabledDate = (current: any) => {
    return current && current > moment().endOf("day");
  };

  const closeUploadModalFuction = () => {
    setBase64String("");
    setFileSize(0);
    setSelectedDate(moment(formattedDate));
    setModalOpen(!modalOpen);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const currentPatientId = useMemo(
    () => window.location.pathname.split(/\//).slice(-1)[0],
    [window.location.pathname]
  );

  const { setRespondData } = useSurveyContext();
  localStorage.setItem("firstLoad", "Yes");

  const {
    isLoading: isLoadingSurveyList,
    data: surveyList,
    error: isSurveyListError,
    isFetching: isFetchingSurveyList,
    refetch: refetchSurveyList
  } = useQuery(
    "patientSurveyList",
    async () => {
      // return await createApiClient().getPatientSurveyList(currentPatientId);
      // console.log("get", currentPatientId);
      const data = await createApiClient().getPatientSurveyList(
        currentPatientId
      );
      if (data == undefined || data?.length === 0) {
        sessionStorage.setItem("surveyNotification", "0");
        notificationsContext.setPatientSurveyNotifications(null);
      } else {
        refetchPatientSurveyNotification();
      }
      return data;
    },
    {
      enabled: true
    }
  );

  const {
    isLoading: pamPostSurveyLoading,
    data: isPamPostSurvey,
    error: isPamPostSurveyError,
    mutate: refetchPamPostSurvey
  } = useMutation(
    "pam-post-survey",
    async () => {
      return await createApiClient().postPamSurvey(
        currentPatientId,
        surveyId,
        checkedItems
      );
    },
    {
      onSuccess: (e: any) => {
        message.success("response submitted successfully.");
        setPamModalOpen(!PamModalOpen);
        refetchSurveyList();
      }
    }
  );

  const {
    isLoading: isLoadingPatientSurveyNotification,
    data: patientSurveyNotification,
    error: isPatientSurveyNotificationError,
    isFetching: isFetchingPatientSurveyNotification,
    refetch: refetchPatientSurveyNotification
  } = useQuery(
    "PatientSurveyNotification",
    async () => {
      const id =
        sessionStorage.getItem("userRole") === "patient"
          ? sessionStorage.getItem("userId")
          : sessionStorage.getItem("patientId");
      const data = await createApiClient().getPatientSurveyNotification(
        Number(id)
      );
      sessionStorage.setItem("surveyNotification", data);
      notificationsContext.setPatientSurveyNotifications(data ? data : null);
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (surveyAssignedData !== undefined) {
      refetchSurveyList();
    }
  }, [surveyAssignedData]);

  const navigate = useNavigate();

  const [surveys, setSurveys] = useState<Survey[] | null>();

  const [pamSurveys, setPamSurveys] = useState<any>();
  const [filteredPamSurveys, setFilteredPamSurveys] = useState<any>();
  const [pamSurveyScore, setPamSurveyScore] = useState<any>();
  const [surveyId, setSurveyId] = useState<any>();

  // useEffect(() => {
  //   pamSurveys && console.log("pamSurveys", pamSurveys[0]?.responses.response);
  //   const filterPam =
  //     pamSurveys &&
  //     pamSurveys[0]?.responses.response.filter(
  //       (item: any) => item.issue !== ""
  //     );
  //   setFilteredPamSurveys(filterPam);
  // }, [pamSurveys]);

  useEffect(() => {
    setSurveys(surveyList);
    setPamSurveys(surveyList);
  }, [surveyList]);

  const options = [
    "Disagree Strongly",
    "Disagree",
    "Agree",
    "Agree Strongly",
    "N/A"
  ];

  const getSurveyEditStatus = async (surveyId: any) => {
    try {
      await createApiClient()
        .getEditSurvey(currentPatientId, surveyId)
        .then((res: any) => {
          if (res?.message == "Session activated") {
            if (sessionStorage.getItem("userRole") == "caregiver") {
              navigate(CaregiverRoutesRef.Respond + "/" + currentPatientId);
            } else if (sessionStorage.getItem("userRole") == "patient") {
              navigate(PatientsRoutesRef.Respond + "/" + currentPatientId);
            } else {
              navigate(ProvidersRoutesRef.Respond + "/" + currentPatientId);
            }
          } else {
            message.warning(res?.message);
          }
        });
    } catch (error: any) {
      setLoading(false);
      message.error(error.message);
    }
  };

  function RadioOption({ value, answer }: any) {
    return (
      <Radio
        value={value}
        style={{
          pointerEvents: answer !== value ? "none" : "auto"
        }}
      >
        {value}
      </Radio>
    );
  }

  function digitalNavigation() {
    sessionStorage.getItem("userRole") === "caregiver"
      ? navigate(CaregiverRoutesRef.ViewForm + "/digital/" + currentPatientId)
      : sessionStorage.getItem("userRole") === "patient"
      ? navigate(PatientsRoutesRef.ViewForm + "/digital/" + currentPatientId)
      : navigate(ProvidersRoutesRef.ViewForm + "/digital/" + currentPatientId);
  }

  function scanNavigation() {
    sessionStorage.getItem("userRole") === "caregiver"
      ? navigate(CaregiverRoutesRef.ViewForm + "/scan/" + currentPatientId)
      : sessionStorage.getItem("userRole") === "patient"
      ? navigate(PatientsRoutesRef.ViewForm + "/scan/" + currentPatientId)
      : navigate(ProvidersRoutesRef.ViewForm + "/scan/" + currentPatientId);
  }

  const handleRowHover = (record: any) => {
    let content: any;
    const score = record?.score;
    if (record?.name == "PAM") {
      if (score <= 47) {
        setActivationLevel("Level 1 – Not believing activation is important");
        setTreatmentActions("TBA");
      }
      if (score >= 47.1 && score <= 55.1) {
        setActivationLevel(
          "Level 2 – Lack of knowledge and confidence to take action"
        );
        setTreatmentActions("TBA");
      }
      if (score >= 55.2 && score <= 67.0) {
        setActivationLevel("Level 3 – Beginning to take action");
        setTreatmentActions("TBA");
      }
      if (score >= 67.1) {
        setActivationLevel("Level 4 - Taking action");
        setTreatmentActions("TBA");
      }
      content = (
        <div>
          <p>
            Activation Level: <b>{activationLevel}</b>
          </p>
          <p>
            Proposed Treatment Actions: <b>{treatmentActions}</b>
          </p>
        </div>
      );
    } else if (record?.name == "PHQ9") {
      if (score >= 0 && score <= 4) {
        setActivationLevel("None-minimal");
        setTreatmentActions("None");
      }
      if (score >= 5 && score <= 9) {
        setActivationLevel("Mild");
        setTreatmentActions("Watchful waiting; repeat PHQ-9 at follow-up");
      }
      if (score >= 10 && score <= 14) {
        setActivationLevel("Moderate");
        setTreatmentActions(
          "Treatment plan, considering counseling, follow-up and/or pharmacotherapy"
        );
      }
      if (score >= 15 && score <= 19) {
        setActivationLevel("Moderately Severe");
        setTreatmentActions(
          "Active treatment with pharmacotherapy and/or psychotherapy"
        );
      }
      if (score >= 20 && score <= 27) {
        setActivationLevel("Severe");
        setTreatmentActions(
          "Immediate initiation of pharmacotherapy and, if severe impairment or poor response to therapy, expedited referral to a mental health specialist for psychotherapy and/or collaborative management"
        );
      }
      content = (
        <div>
          <p>Scoring based on: Articles </p>
          <p>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1495268/</p>
          <p>
            Depression Severity: <b>{activationLevel}</b>
          </p>
          <p>
            Advice:{" "}
            <b>
              Physician should perform final diagnosis with clinical interview
              and mental status examination including assessment of patient’s
              level of distress and functional impairment.
            </b>
          </p>
          <p>
            Proposed Treatment Actions: <b>{treatmentActions}</b>
          </p>
        </div>
      );
    } else {
      content = "";
    }
    setTooltipContent(() => content);
  };

  const [checkedItems, setCheckedItems] = useState<
    { q_id: number; action_response: string }[]
  >([]);

  // useEffect(() => {
  //   console.log("checkedItems", checkedItems);
  // }, [checkedItems]);

  const handleCheckboxChange = (isChecked: boolean, q_id: number) => {
    setCheckedItems((prevItems: any) => {
      const updatedItems = prevItems
        .map((item: any) => {
          if (item.q_id === q_id) {
            return { ...item, action_response: isChecked };
          }
          return item;
        })
        .filter((item: any) => item.action_response !== false);
      if (!updatedItems.some((item: any) => item.q_id === q_id)) {
        updatedItems.push({ q_id, action_response: isChecked });
      }
      return updatedItems.filter((item: any) => item.action_response !== false);
    });
  };

  const resetCheckedItems = () => {
    setCheckedItems([]);
  };

  const [isPamNotApplicable, seIsPamNotApplicable] = useState<any>(false);
  const [isAllAnswerSame, setISAllAnswerSame] = useState<any>(false);

  const pamColumns: ColumnsType<any> = [
    {
      dataIndex: "issue",
      key: "issue",
      title: "Issue",
      width: "30%",
      render: (issue: string) => {
        return (
          <MessageRow style={{ marginLeft: "10px" }} fontSize={"14px"}>
            {issue}
          </MessageRow>
        );
      }
    },
    {
      dataIndex: "action_item",
      key: "action_item",
      title: "Action Items",
      width: "45%",
      render: (action_item: string) => {
        return (
          <MessageRow
            style={{ fontWeight: "bold", marginLeft: "10px" }}
            fontSize={"14px"}
          >
            {action_item}
          </MessageRow>
        );
      }
    },
    {
      dataIndex: "completed",
      key: "completed",
      title: "Completed",
      align: "center",
      width: "12%",
      render: (_: any, record: any) => {
        const { q_id, action_response } = record;
        const isChecked = checkedItems.some(
          item => item.q_id === q_id && item.action_response
        );
        return (
          <div style={{ alignItems: "center" }}>
            <Checkbox
              checked={action_response ? action_response : isChecked}
              onChange={e => handleCheckboxChange(e.target.checked, q_id)}
            />
          </div>
        );
      }
    },
    {
      dataIndex: "date",
      key: "date",
      title: "Date",
      align: "center",
      width: "20%",
      render: (_: any, record: any) => {
        if (record.action_update_time) {
          return (
            <div style={{ alignItems: "left" }}>
              {moment(record.action_update_time).format("MMM, DD  YYYY")}
            </div>
          );
        } else {
          return <div>- -</div>;
        }
      }
    }
  ];

  let columns = [
    {
      dataIndex: "name",
      key: "name",
      title: "Name of Survey",
      width: "20%",
      render: (name: string) => {
        return (
          <MessageRow
            style={{ fontWeight: "bold", marginLeft: "10px" }}
            fontSize={"14px"}
          >
            {name}
          </MessageRow>
        );
      }
    },
    {
      dataIndex: "created_at",
      key: "dateAssigned",
      title: "Date Assigned",
      render: (created_at: string) => {
        return (
          <MessageUpperRow style={{ fontWeight: "bold", marginLeft: "2px" }}>
            {moment(created_at).format("MMM DD, YYYY")}
          </MessageUpperRow>
        );
      },
      width: "15%"
    },
    {
      dataIndex: "completed_at",
      key: "dateCompleted",
      title: "Date Completed",
      render: (completed_at: string) => {
        if (completed_at && completed_at != "0000-00-00 00:00:00") {
          return (
            <MessageUpperRow style={{ fontWeight: "bold", marginLeft: "2px" }}>
              {moment(completed_at).format("MMM DD, YYYY")}
            </MessageUpperRow>
          );
        }
        return "N/A";
      },
      width: "15%"
    },
    {
      dataIndex: "status",
      key: "status",
      title: "Status",
      width: "13%",
      render: (status: number, record: any) => {
        const { review_date } = record;
        if (status === 1) {
          return (
            <StatusDiv>
              <MessageUpperRow
                style={{ fontWeight: "bold", marginLeft: "2px" }}
              >
                Completed
              </MessageUpperRow>
              {sessionStorage.getItem("userRole") == "patient" &&
                review_date != null && (
                  <CheckOutlined
                    style={{ color: "#32CD32", fontSize: 15, paddingBottom: 3 }}
                  />
                )}
            </StatusDiv>
          );
        }
        if (status === 0) {
          return (
            <StatusDiv>
              <MessageUpperRow
                style={{ fontWeight: "bold", marginLeft: "2px" }}
              >
                Pending
              </MessageUpperRow>
            </StatusDiv>
          );
        }
        if (status === 2) {
          return (
            <StatusDiv>
              <MessageUpperRow
                style={{ fontWeight: "bold", marginLeft: "2px" }}
              >
                Draft
              </MessageUpperRow>
            </StatusDiv>
          );
        }
      }
    },
    {
      dataIndex: "status",
      key: "view",
      title:
        sessionStorage.getItem("userRole") == "patient" ? "View" : "Review",
      width: "8%",
      render: (status: number, responses: any, selectedSurvey: any) => {
        if (status === 1) {
          return (
            <div
              style={{
                display: "grid",
                placeItems: "right",
                paddingLeft: "5px",
                height: "44px",
                width: "44px"
              }}
            >
              <Space size={20}>
                <ActionSpan>
                  <EyeOutlined
                    style={{ fontSize: "20px", color: Colors.LightGrey }}
                    onClick={() => {
                      setSurveyData(surveyList[selectedSurvey]);
                      setRespondData(
                        responses.responses,
                        responses.name,
                        responses.id,
                        surveyList[selectedSurvey]
                      );
                      responses?.response_type === "Digital"
                        ? digitalNavigation()
                        : scanNavigation();
                    }}
                  />
                  {sessionStorage.getItem("userRole") != "patient" &&
                    responses.review_date != null && (
                      <CheckOutlined
                        style={{
                          color: "#32CD32",
                          fontSize: 15,
                          paddingLeft: 4
                        }}
                      />
                    )}
                </ActionSpan>
              </Space>
            </div>
          );
        }
        return (
          <MessageUpperRow style={{ fontWeight: "bold", marginLeft: "2px" }}>
            N/A
          </MessageUpperRow>
        );
      }
    },
    {
      dataIndex: "status",
      key: "upload",
      title: "Upload",
      width: "8%",
      render: (status: number, record: any, selectedSurvey: any) => {
        if (status === 0) {
          return (
            <div
              style={{
                display: "grid",
                placeItems: "right",
                paddingLeft: "5px",
                height: "44px",
                width: "44px"
              }}
            >
              <Space size={20}>
                <ActionSpan>
                  <UploadOutlined
                    style={{ fontSize: "20px", color: Colors.LightGrey }}
                    onClick={() => {
                      setSurveyData(surveyList[selectedSurvey]);
                      uploadModalFunction();
                    }}
                  />
                </ActionSpan>
                <AppModal
                  title="Upload Survey"
                  visible={modalOpen}
                  width="50%"
                  footer={false}
                  onCancel={uploadModalFunction}
                >
                  <ModalContentContainer>
                    <PopUpText>
                      Please scan and upload {surveyData?.name} Survey filled by
                      Patient{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {sessionStorage.getItem("userRole") != "patient" ? (
                          <b>{` ${" "} ${
                            patientContext.patientData?.first_name
                          } ${" "} ${
                            patientContext.patientData?.last_name
                          }${"."}`}</b>
                        ) : (
                          <b>{` ${" "} ${
                            userContext.userData?.firstName
                          } ${" "} ${userContext.userData?.lastName}${"."}`}</b>
                        )}
                      </span>{" "}
                      <br />
                      If there are multiple pages, please combine them in single
                      file. Supported formats for file upload are JPG and PDF.
                    </PopUpText>
                    <Divider />
                    <div
                      style={{
                        width: "90%",
                        marginBottom: 20,
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 20
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: -24,
                          gap: 5
                        }}
                      >
                        <label
                          style={{
                            fontSize: 11,
                            paddingLeft: 1.5
                          }}
                        >
                          Date survey was filled.
                        </label>
                        <DatePicker
                          style={{ height: 40 }}
                          value={selectedDate}
                          onChange={date => {
                            setSelectedDate(date);
                          }}
                          disabledDate={disabledDate}
                          format="MM/DD/YYYY"
                        />
                      </div>
                      <div
                        style={{
                          marginTop: -2,
                          width: "70%",
                          padding: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                          height: "41px",
                          border: `1px solid #d9d9d9`,
                          borderRadius: "2px"
                        }}
                      >
                        <input
                          type="file"
                          accept=".jpg,.pdf"
                          onChange={handleFileChange}
                          ref={fileInputRef}
                        />
                      </div>
                    </div>
                    <ButtonContainer>
                      <Button onClick={closeUploadModalFuction}>Close</Button>
                      <Button
                        onClick={() => {
                          uploadFile(
                            fileType,
                            base64String,
                            surveyData?.id,
                            selectedDate
                          );
                        }}
                        type="primary"
                        loading={loading ? loading : false}
                      >
                        Save
                      </Button>
                    </ButtonContainer>
                  </ModalContentContainer>
                </AppModal>
              </Space>
            </div>
          );
        }
        return (
          <MessageUpperRow style={{ fontWeight: "bold", marginLeft: "2px" }}>
            N/A
          </MessageUpperRow>
        );
      }
    },
    {
      dataIndex: "status",
      key: "respond",
      title: "Respond",
      width: "8%",
      render: (status: number, record: any, selectedSurvey: any) => {
        if (status === 0 || status === 2) {
          return (
            <div
              style={{
                display: "grid",
                placeItems: "right",
                paddingLeft: "5px",
                height: "44px",
                width: "44px"
              }}
            >
              <Space size={20}>
                <ActionSpan>
                  <EditOutlined
                    style={{ fontSize: "20px", color: Colors.LightGrey }}
                    onClick={() => {
                      if (
                        record.name != "PAM" &&
                        record.name != "PHQ9" &&
                        record.name != "SDoH"
                      ) {
                        message.error(record.name + " survey doesn't exist.");
                      } else {
                        setRespondData(
                          record.responses,
                          record.name,
                          record.id
                        );
                        getSurveyEditStatus(surveyList[selectedSurvey]?.id);

                        // if (
                        //   sessionStorage.getItem("userRole") == "caregiver"
                        // ) {
                        //   navigate(
                        //     CaregiverRoutesRef.Respond +
                        //       "/" +
                        //       currentPatientId
                        //   );
                        // } else if (
                        //   sessionStorage.getItem("userRole") == "patient"
                        // ) {
                        //   navigate(
                        //     PatientsRoutesRef.Respond + "/" + currentPatientId
                        //   );
                        // } else {
                        //   navigate(
                        //     ProvidersRoutesRef.Respond +
                        //       "/" +
                        //       currentPatientId
                        //   );
                        // }
                      }
                    }}
                  />
                </ActionSpan>
              </Space>
            </div>
          );
        }
        return (
          <MessageUpperRow style={{ fontWeight: "bold", marginLeft: "2px" }}>
            N/A
          </MessageUpperRow>
        );
      }
    },
    {
      dataIndex: "score",
      key: "score",
      title: "Score",
      width: "10%",
      render: (score: number, record: any) => {
        const isNotApplicable = record?.responses?.response?.filter(
          (item: any) => item.answer == "N/A"
        );
        const isSameAnswerSame = score === -1;

        const isApplicable = isNotApplicable?.length >= 3;
        // console.log("isNotApplicable", isNotApplicable?.length >= 3);

        const filterPam = record?.responses?.response?.filter(
          (item: any) => item.issue !== ""
        );
        const isPamSubmitted = filterPam?.filter(
          (item: any) => item.action_response === true
        );

        const isPamCompleted = isPamSubmitted?.length === filterPam?.length;

        const isPAMSurvey = record.name === "PAM";
        return (
          <>
            <MessageRow
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10%"
              }}
              fontSize={"14px"}
            >
              {score == null || score == 0 || score == -1 ? (
                "N/A"
              ) : (
                <div
                  onMouseEnter={() => {
                    handleRowHover(record);
                    setSurveyData(record);
                    setHasMouseEntered(true);
                  }}
                  onMouseLeave={() => {
                    setHasMouseEntered(false);
                  }}
                >
                  <Tooltip
                    title={tooltipContent}
                    placement="left"
                    arrowPointAtCenter={true}
                    overlayInnerStyle={tooltipInnerStyling}
                  >
                    {score}
                  </Tooltip>
                </div>
              )}

              {score !== null && isPAMSurvey && (
                <ActionSpan style={{ marginLeft: 12 }}>
                  <InfoCircleOutlined
                    style={{
                      fontSize: "18px",
                      marginTop: 1.8,
                      color: isSameAnswerSame
                        ? Colors.Red
                        : isPamCompleted
                        ? "rgb(124, 186, 91)"
                        : Colors.Red
                    }}
                    onClick={() => {
                      setPamSurveyScore(score);
                      setFilteredPamSurveys(filterPam);
                      setSurveyId(record.id);
                      setPamModalOpen(!PamModalOpen);
                      seIsPamNotApplicable(isApplicable);
                      setISAllAnswerSame(isSameAnswerSame);
                    }}
                  />
                </ActionSpan>
              )}
            </MessageRow>
          </>
        );
      }
    }
  ];

  if (sessionStorage.getItem("userRole") === "patient") {
    columns = columns.filter(col => col.key !== "score");
  }

  return (
    <div style={{ position: "relative" }} ref={scrollContainerRef}>
      <AppCard
        cardHeight="inherit"
        cardWidth="100%"
        style={{
          borderRadius: "0px 0px 8px 8px",
          marginTop: "16px"
        }}
      >
        {isLoadingSurveyList ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Spin />
          </div>
        ) : (
          <>
            {!isLoadingSurveyList && (
              <>
                {surveys && surveys.length !== 0 ? (
                  <Table
                    columns={columns}
                    dataSource={surveys}
                    rowKey="id"
                    pagination={false}
                  />
                ) : (
                  <p>No matching records found</p>
                )}
              </>
            )}
          </>
        )}
      </AppCard>
      <AppModal
        title="PAM SURVEY"
        visible={PamModalOpen}
        width="80%"
        footer={
          isPamNotApplicable === true || isAllAnswerSame === true ? false : true
        }
        onCancel={() => {
          setPamModalOpen(!PamModalOpen);
          resetCheckedItems();
        }}
        okText="Submit"
        onOk={refetchPamPostSurvey}
      >
        <>
          <div
            style={{
              width: "100%"
            }}
          >
            {isPamNotApplicable === true || isAllAnswerSame === true ? null : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: 18,
                    fontWeight: "bold",
                    padding: " 0px 12px",
                    marginBottom: "16px"
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    Activation Level :{"  "}
                    <span style={{ color: "red" }}>
                      {" "}
                      {pamSurveyScore && pamSurveyScore <= 47 && (
                        <div style={{ alignItems: "center" }}>
                          <span>Level 1</span>{" "}
                          <span
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                          >
                            (Belives Activation Role Important)
                          </span>
                        </div>
                      )}
                    </span>
                    <span style={{ color: "red" }}>
                      {" "}
                      {pamSurveyScore &&
                        pamSurveyScore >= 47.1 &&
                        pamSurveyScore <= 55.1 && (
                          <div style={{ textAlign: "center" }}>
                            <span>Level 2</span>{" "}
                            <span
                              style={{ fontSize: "14px", fontWeight: "normal" }}
                            >
                              (Confidence and Knowledge to take Action)
                            </span>
                          </div>
                        )}
                    </span>
                    <span style={{ color: "orange" }}>
                      {" "}
                      {pamSurveyScore &&
                        pamSurveyScore >= 55.2 &&
                        pamSurveyScore <= 72.4 && (
                          <div style={{ textAlign: "center" }}>
                            <span>Level 3</span>{" "}
                            <span
                              style={{ fontSize: "14px", fontWeight: "normal" }}
                            >
                              (Taking Action)
                            </span>
                          </div>
                        )}
                    </span>
                    <span style={{ color: "green" }}>
                      {" "}
                      {pamSurveyScore && pamSurveyScore >= 72.4 && (
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          <span>Level 4</span>{" "}
                          <span
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                          >
                            (Staying the Course under Stress)
                          </span>
                        </div>
                      )}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontSize: 14 }}>Score :</span>{" "}
                    {pamSurveyScore && pamSurveyScore}
                  </div>
                </div>
              </>
            )}

            {isPamNotApplicable ? (
              "To ensure reliability of scoring this survey is excluded as it has 3 or more N/A response"
            ) : isAllAnswerSame ? (
              "To ensure reliability of scoring this survey is excluded as response for all questions is same"
            ) : filteredPamSurveys && filteredPamSurveys !== 0 ? (
              <div style={{ height: "400px", overflowY: "auto" }}>
                <Table
                  columns={pamColumns}
                  dataSource={filteredPamSurveys}
                  rowKey="id"
                  pagination={false}
                />
              </div>
            ) : (
              <p>No matching records found.</p>
            )}
          </div>
        </>
      </AppModal>
    </div>
  );
};

const ActionSpan = styled.span`
  cursor: pointer;
`;

const StatusDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 8px;
  justify-content: start;
`;

const MessageUpperRow = styled(Row)`
  color: ${Colors.gothicBold};
  font-size: 12px;
  letter-spacing: 1px;
`;

const ModalContentContainer = styled.div`
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
`;

const PopUpText = styled.div`
  padding: 5px 30px 0px 30px;
  color: ${Colors.gothicBold};
  font-weight: 400;
`;

const ButtonContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  margin-right: 15px;
`;

export default SurveysTable;
