// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext, useState, useEffect } from "react";
import {
  VideoTileGrid,
  UserActivityProvider
} from "amazon-chime-sdk-component-library-react";

import { StyledLayout, StyledContent, TimerContent } from "./Styled";
import NavigationControl from "components/meetings/containers/Navigation/NavigationControl";
import { useNavigation } from "components/meetings/providers/NavigationProvider";
import MeetingControls from "components/meetings/containers/MeetingControls";
import useMeetingEndRedirect from "components/meetings/hooks/useMeetingEndRedirect";
import DynamicMeetingControls from "components/meetings/containers/DynamicMeetingControls";
import { MeetingMode } from "components/meetings/types";
import { VideoTileGridProvider } from "components/meetings/providers/VideoTileGridProvider";
import { DataMessagesProvider } from "components/meetings/providers/DataMessagesProvider";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";

export const MeetingView = (props: { mode: MeetingMode }) => {
  useMeetingEndRedirect();
  const { showNavbar, showRoster, showChat } = useNavigation();
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const { mode } = props;

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => (prevSeconds + 1) % 60);
      if (seconds === 59) {
        setMinutes(prevMinutes => (prevMinutes + 1) % 60);
        if (minutes === 59) {
          setHours(prevHours => prevHours + 1);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds, minutes]);

  return (
    <UserActivityProvider>
      <DataMessagesProvider>
        <VideoTileGridProvider>
          <StyledLayout
            showNav={showNavbar}
            showRoster={showRoster}
            showChat={showChat}
            pstnCall={meetingContext.pstnPhoneNumber ? true : false}
          >
            <StyledContent>
              <VideoTileGrid
                layout={"standard"}
                className="videos"
                noRemoteVideoView={<></>}
              />
              {mode === MeetingMode.Spectator ? (
                <DynamicMeetingControls />
              ) : (
                <>
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      right: "50%",
                      top: `${meetingContext.pstnPhoneNumber ? "20%" : "77%"}`,
                      // transform: "translate(-20%, 2000%)",
                      fontSize: "10px",
                      fontWeight: "bolder"
                      // textAlign: "center",
                      // alignItems: "center",
                      // justifyContent: "center",
                      // marginTop: "20%",
                      // width: "100%"
                    }}
                  >
                    <h1>{`${hours.toString().padStart(2, "0")}:${minutes
                      .toString()
                      .padStart(2, "0")}:${seconds
                      .toString()
                      .padStart(2, "0")}`}</h1>
                    {/* <MeetingControls /> */}
                  </div>
                  <MeetingControls />
                </>
              )}
            </StyledContent>
            <NavigationControl />
          </StyledLayout>
        </VideoTileGridProvider>
      </DataMessagesProvider>
    </UserActivityProvider>
  );
};

export default MeetingView;
