// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import styled from "styled-components";

interface StyledWrapperProps {
  pstnCall: boolean;
}

interface StyledAudioGroupProps {
  pstnCall: boolean;
}

export const title = `
  text-transform: uppercase;
  font-size: 1rem !important;
  margin-bottom: 1.00rem;
`;

export const StyledPreviewGroup = styled.div`
  margin-bottom: 1.5rem;
`;

export const StyledWrapper = styled.div<StyledWrapperProps>`
  position: relative;
  display: ${props => (props.pstnCall ? "block" : "flex")};
  flex-direction: column;
  width: 100%;
  max-width: 65rem;
  height: 430px;
  padding: 1rem 0 3rem 0;

  > * {
    flex-basis: auto;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    padding: 5px 0 6rem 0;

    > * {
      flex-basis: 50%;
    }

    @media (max-height: 800px) {
      padding: 5px 0 2rem;
    }
  }
`;

export const StyledAudioGroup = styled.div<StyledAudioGroupProps>`
  padding: 0 3rem 0 0;
  border-right: ${props =>
    props.pstnCall ? "none" : "0.125rem solid #e6e6e6"};

  @media (max-width: 900px) {
    padding: 2rem 0 2rem 0;
    border-right: unset;
  }
`;

export const StyledVideoGroup = styled.div`
  padding: 0 0 0 3rem;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

export const StyledInputGroup = styled.div`
  margin-bottom: 1rem;
`;
