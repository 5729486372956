import { Button, Modal, Space, Spin, message } from "antd";
import { UserContextState, UserContext } from "contextApis/userContext";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import ConsentForm from "./consentForm";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";

interface LoginPageProps {
  current_route: string;
  consentCheckRefetch: () => void;
}

const ConsentPage: React.FC<LoginPageProps> = ({
  current_route,
  consentCheckRefetch
}): JSX.Element => {
  const userContext = useContext<UserContextState>(UserContext);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const consentToken = queryParams.get("consent_token") || undefined;

  const [submitLoading, setSubmitLoading] = useState(false);
  const [PdfValue, setPdfValue] = useState("");
  const [userInitial, setUserInitial] = useState<any>();
  const [sign, setSign] = useState<boolean>(false);
  const [consents, setConsents] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [signerFilter, setSignerFilter] = useState<string>("Patient");
  const [toggle, setToggle] = useState<boolean>(false);
  const [isValidSignature, setIsValidSignature] = useState<boolean>(false);
  const [errorSignatureText, setErrorSignatureText] = useState<string>("");
  const [privacyChecked, setIsPrivacyChecked] = useState(0);
  const [showBorder, setShowBorder] = useState(false);
  const [searchParams] = useSearchParams();
  const [registerClick, setRegisterClick] = useState(true);

  const {
    isLoading,
    data: consentData,
    error: consentDataError,
    refetch
  } = useQuery("user-consent-data", async () => {
    if (current_route === "Login") {
      return await createApiClient().getConsentAfterLogin();
    } else {
      return await createApiClient().getConsent(consentToken);
    }
  });

  const DeviceAckConsent = consentData?.rpm_status === "Delivered";

  useLayoutEffect(() => {
    const checkSigned = () => {
      const Consents = consentData?.consents?.filter(
        (signed: any) => signed.sign_date === null
      );
      setConsents(Consents);
    };
    checkSigned();
  }, [consentData]);

  useEffect(() => {
    if (current_route !== "Login") {
      if (isLoading == false && consents?.length === 0) {
        userContext.setFromConsentFlag(true);
        const username = searchParams.get("username") || "";
        const code = searchParams.get("code") || "";
        const consent_token = searchParams.get("consent_token") || "";
        const queryParams = new URLSearchParams();
        queryParams.append("username", username);
        queryParams.append("code", code);
        queryParams.append("consent_token", consent_token);
        const urlWithQueryParams = `/?${queryParams.toString()}`;
        navigate(urlWithQueryParams);
      }
    }
  }, [consents]);

  useEffect(() => {
    if (consents && consents[0]?.consent_name === "rpm") {
      setIsPrivacyChecked(1);
    }

    if (consents && consents[0]?.consent_name === "device_acknowledgement") {
      setIsPrivacyChecked(1);
    }
  }, [consents]);

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
    setRegisterClick(false);
  };

  const handleSubmit = async () => {
    if (PdfValue !== "" && isValidSignature === false) {
      setSign(true);
      setErrorSignatureText("Please draw a valid signature.");
      return;
    } else {
      setSign(false);
    }

    if (!toggle && privacyChecked === 0 && PdfValue === "") {
      setErrorSignatureText("Signature is required.");
      setShowBorder(true);
      setSign(true);
      return;
    } else if (!toggle && privacyChecked === 0) {
      setShowBorder(true);
      return;
    } else if (!toggle && PdfValue === "") {
      setErrorSignatureText("Signature is required.");
      setSign(true);
      return;
    } else {
      setSign(false);
    }

    if (toggle) {
      const regex = /^[a-zA-Z]{2}/;

      const isValid = regex.test(userInitial);

      if (Number(userInitial.length) < 2) {
        message.error("Please type atleast 2 character.");
        return;
      }
      if (!isValid) {
        message.error("Please enter character only.");
        return;
      }
    }

    if (toggle && privacyChecked === 0 && userInitial === "") {
      setShowBorder(true);
      setSign(true);
      return;
    } else if (toggle && privacyChecked === 0) {
      setShowBorder(true);
      return;
    } else if (toggle && userInitial === "") {
      setSign(true);
      return;
    } else {
      setSign(false);
    }

    const consent_token = consentToken;
    const signer_name = userContext.signer_name;
    const signer_relationship = userContext.signer_relationship;
    const consent_name = userContext.consent_name;
    const pdffiledata = userContext.pdfByteString;
    const sign_type = toggle ? "text" : "image";

    if (current_route === "Login") {
      try {
        setSubmitLoading(true);
        const consentUploadAfterLogin =
          await createApiClient().postConsentAfterLogin(
            consent_name,
            pdffiledata,
            signer_relationship,
            sign_type
          );
        if (consentUploadAfterLogin) {
          setSubmitLoading(false);
          setShowBorder(false);
          setIsPrivacyChecked(0);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        setSubmitLoading(true);
        const consentUpload = await createApiClient().postConsentData(
          consent_token,
          signer_name,
          signer_relationship,
          consent_name,
          pdffiledata,
          sign_type
        );

        if (consentUpload) {
          setSubmitLoading(false);
          setShowBorder(false);
          setIsPrivacyChecked(0);

          if (consents?.length === 1 && current_route === "Login") {
            createApiClient().getValidateLoginMail(consent_token);
          }

          if (consents?.length === 0) {
            userContext.setFromConsentFlag(true);
            const username = searchParams.get("username") || "";
            const code = searchParams.get("code") || "";
            const consent_token = searchParams.get("consent_token") || "";
            const queryParams = new URLSearchParams();
            queryParams.append("username", username);
            queryParams.append("code", code);
            queryParams.append("consent_token", consent_token);
            const urlWithQueryParams = `/?${queryParams.toString()}`;

            navigate(urlWithQueryParams);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    refetch();
    consentCheckRefetch();
  };

  return (
    <ConsentBackground>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "grid",
          placeItems: "center",
          overflow: "hidden"
        }}
      >
        {current_route === "Login" ? (
          isLoading || submitLoading ? (
            <Space>
              <Spin
                tip="Loading..."
                size="large"
                style={{ display: "grid", placeItems: "center" }}
              >
                <Content />
              </Spin>
            </Space>
          ) : (
            <ConsentForm
              submitFunc={handleSubmit}
              setPrivacyCheck={setIsPrivacyChecked}
              privacyCheck={privacyChecked}
              consentData={consentData ?? null}
              consentError={consentDataError}
              setPdfValue={setPdfValue}
              PdfValue={PdfValue}
              sign={sign}
              setSign={setSign}
              consents={consents}
              setShowBorder={setShowBorder}
              showBorder={showBorder}
              currentRoute={current_route}
              setSignerFilter={setSignerFilter}
              signerFilter={signerFilter}
              setUserInitial={setUserInitial}
              userInitial={userInitial}
              toggle={toggle}
              setToggle={setToggle}
              setIsValidSignature={setIsValidSignature}
              isValidSignature={isValidSignature}
              errorSignatureText={errorSignatureText}
            />
          )
        ) : isLoading || submitLoading ? (
          <Space>
            <Spin
              tip="Loading..."
              size="large"
              style={{ display: "grid", placeItems: "center" }}
            >
              <Content />
            </Spin>
          </Space>
        ) : (
          <ConsentForm
            submitFunc={handleSubmit}
            setPrivacyCheck={setIsPrivacyChecked}
            privacyCheck={privacyChecked}
            consentData={consentData ?? null}
            consentError={consentDataError}
            setPdfValue={setPdfValue}
            PdfValue={PdfValue}
            sign={sign}
            setSign={setSign}
            consents={consents}
            setShowBorder={setShowBorder}
            showBorder={showBorder}
            currentRoute={current_route}
            setSignerFilter={setSignerFilter}
            signerFilter={signerFilter}
            setUserInitial={setUserInitial}
            userInitial={userInitial}
            toggle={toggle}
            setToggle={setToggle}
            setIsValidSignature={setIsValidSignature}
            isValidSignature={isValidSignature}
            errorSignatureText={errorSignatureText}
          />
        )}
      </div>

      {!DeviceAckConsent && (
        <>
          {current_route === "Login"
            ? !isLoading &&
              registerClick && (
                <Modal
                  closeIcon
                  open={isModalOpen}
                  centered
                  width={"45%"}
                  footer={[
                    <Space key="Yes" style={{ marginLeft: 10 }}>
                      <Button type="primary" onClick={handleModal}>
                        OK
                      </Button>
                    </Space>
                  ]}
                >
                  {consents &&
                  consents.length === 1 &&
                  consents[0]?.consent_name === "caregem" ? (
                    <>
                      <p>
                        <span
                          style={{ fontWeight: "bold", marginTop: 20 }}
                        ></span>
                        Hello{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {userContext.userData?.name}{" "}
                        </span>
                        , Username:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {userContext.userData?.userName}{" "}
                        </span>
                      </p>
                      <p>
                        Welcome to CareGem, the{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {consentData?.org_details?.name}{" "}
                        </span>
                        platform to better manage your health.
                      </p>
                      <p>
                        CareGem allows your health care team to better
                        coordinate your care even if you don’t use a smartphone
                        or computer.
                      </p>
                      <p>
                        Please sign the updated consent and review the CareGem
                        Brochure and our privacy policies.
                      </p>
                    </>
                  ) : null}
                  {consents &&
                  consents.length === 1 &&
                  consents[0]?.consent_name === "rpm" ? (
                    <>
                      <p>
                        <span
                          style={{ fontWeight: "bold", marginTop: 20 }}
                        ></span>
                        Hello{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {userContext.userData?.name}{" "}
                        </span>
                        , Username:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {userContext.userData?.userName}{" "}
                        </span>
                      </p>
                      <p>
                        Welcome to{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {consentData?.org_details?.name},{" "}
                        </span>
                        CareGem platform for remote monitoring.
                      </p>
                      <p>
                        CareGem allows your health care team to better
                        coordinate and monitor your care even if don’t use a
                        smartphone or computer.
                      </p>
                      <p>Please sign the remote monitoring consent.</p>
                    </>
                  ) : null}
                  {consents &&
                  consents.length === 2 &&
                  consents[0]?.consent_name === "caregem" &&
                  consents[1]?.consent_name === "rpm" ? (
                    <>
                      <p>
                        <span
                          style={{ fontWeight: "bold", marginTop: 20 }}
                        ></span>
                        Hello{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {userContext.userData?.name}{" "}
                        </span>
                        , Username:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {userContext.userData?.userName}{" "}
                        </span>
                      </p>
                      <p>
                        Welcome to{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {consentData?.org_details?.name}{" "}
                        </span>
                        , CareGem platform for remote monitoring.
                      </p>
                      <p>
                        CareGem allows your health care team to better
                        coordinate and monitor your care even if don’t use a
                        smartphone or computer.
                      </p>
                      <p>
                        Please sign both the remote monitoring and CareGem
                        consents, and review the CareGem Brochure and our
                        privacy policies.
                      </p>
                    </>
                  ) : null}
                </Modal>
              )
            : null}
        </>
      )}
    </ConsentBackground>
  );
};

const ConsentBackground = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  padding: 100px;
  border-radius: 4px;
`;

export default ConsentPage;
