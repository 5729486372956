import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import { AppModal } from "components/modal";
import { CallRecordFormData, CallRecordNote } from "sharedTypes";
import {
  CallRecordDate,
  CallRecordDateProps
} from "./formInputs/CallRecordDate";
import {
  CallRecordDiscussionItems,
  CallRecordDiscussionItemsProps
} from "./formInputs/CallRecordDiscussionItems";
import {
  CallRecordDuration,
  CallRecordDurationProps
} from "./formInputs/CallRecordDuration";
import {
  CallRecordOtherIssues,
  CallRecordOtherIssuesProps
} from "./formInputs/CallRecordOtherIssues";
import {
  AddCallRecordGridContainer,
  AddCallRecordTitleText,
  ButtonContainer,
  DurationLabelText,
  ErrorMessage,
  OverflowCallRecordCardContainer
} from "./style";
import { Button, Modal, Space, Spin, message } from "antd";

interface AddCallRecordProps {
  readonly onFormSubmit: (formData: CallRecordFormData) => void;
  setShowAddEditCallRecords(type: string): void;
  readonly isLoadingPostCallRecord: boolean;
}

interface FormSubmitData {
  readonly date: CallRecordDateProps;
  readonly duration: CallRecordDurationProps;
  readonly discussionItems: CallRecordDiscussionItemsProps;
  readonly otherIssues: CallRecordOtherIssuesProps;
}

export const AddCallRecord: React.FC<AddCallRecordProps> = ({
  onFormSubmit,
  setShowAddEditCallRecords,
  isLoadingPostCallRecord
}) => {
  const [form] = Form.useForm();
  // const [clearForm, setClearForm] = useState<boolean>(false);
  const [callRecordNotes, setCallRecordNotes] = useState<CallRecordNote[]>([]);
  const [enableFinalize, setEnableFinalize] = useState<boolean>(false);
  const [saveAsDraft, setSaveAsDraft] = useState<boolean>(true);

  const [submissionData, setSubmissionData] = useState<FormSubmitData>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [values, setValues] = useState<any>();
  const [otherIssues, setOtherIssue] = useState<boolean>(true);

  const { data } = useQuery("call-record-notes", async () => {
    return await createApiClient().getCallRecordNotes();
  });

  const valuesRef = useRef(null);
  const draftButtonRef = useRef(false);

  useEffect(() => {
    valuesRef.current = values;
    console.log("valuesRef.current", valuesRef.current);
  }, [values]);

  const onValuesChange = (_changedValues: any, values: any) => {
    setValues(values);
    if (values) {
      if (
        values.date?.date &&
        values.duration?.duration &&
        values.discussionItems?.discussionItems &&
        values.discussionItems.discussionItems?.length > 0
      ) {
        setEnableFinalize(true);
        return;
      }
    }
    setEnableFinalize(false);
  };

  useEffect(() => {
    if (data) {
      setCallRecordNotes(data);
    }
  }, [data]);

  // useEffect(() => {
  //   // console.log("submissionData", values);
  //   const noDate = !values?.date;
  //   const noDuration = !values?.duration || !values?.duration?.duration;
  //   const noDiscussionItems =
  //     !values?.discussionItems?.discussionItems ||
  //     values?.discussionItems?.discussionItems?.length <= 0;
  //   const noOtherIssues =
  //     !values?.otherIssues || !values?.otherIssues?.otherIssues;
  //   console.log(
  //     "noDate",
  //     noDate,
  //     "noDuration",
  //     noDuration,
  //     "noDiscussionItems",
  //     noDiscussionItems,
  //     "noOtherIssues",
  //     otherIssues
  //   );
  //   // Check if all required fields are not empty
  //   setSaveAsDraft(true);
  //   if (!noDate && !noDuration && !noDiscussionItems && !otherIssues) {
  //     // All required fields are present, so make the API call
  //     // Call your API here
  //     console.log("Call your API here");
  //     onFinish(values);
  //   } else {
  //     // At least one required field is empty, do not make the API call
  //     console.log("stop api call");
  //   }
  // }, [values, otherIssues]);

  // const onLeave = (e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => {
  //   if (e) {
  //     console.log("Mouse is out yahoooo", e);
  //     setOtherIssue(false);
  //   }
  // };

  const onFinish = (values: FormSubmitData) => {
    const isDraft =
      !values?.date ||
      !values?.duration ||
      (values?.discussionItems?.discussionItems &&
        values?.discussionItems?.discussionItems?.length <= 0);

    const formData: CallRecordFormData = {
      date: (values?.date && values?.date?.date) || moment(),
      duration: (values?.duration && values?.duration?.duration) || "",
      discussionItems:
        (values?.discussionItems && values?.discussionItems?.discussionItems) ||
        [],
      otherIssues:
        (values?.otherIssues && values?.otherIssues?.otherIssues) || "",
      status: isDraft || saveAsDraft ? "DRAFT" : "COMPLETED"
    };
    onFormSubmit(formData);
    // setClearForm(!clearForm);
  };

  useEffect(() => {
    return () => {
      if (draftButtonRef.current === true) return;
      draftButtonRef.current === false;
      const onFinish = (values: FormSubmitData) => {
        const isDraft =
          !values?.date ||
          !values?.duration ||
          (values?.discussionItems?.discussionItems &&
            values?.discussionItems?.discussionItems?.length <= 0);

        const formData: CallRecordFormData = {
          date: (values?.date && values?.date?.date) || moment(),
          duration: (values?.duration && values?.duration?.duration) || "",
          discussionItems:
            (values?.discussionItems &&
              values?.discussionItems?.discussionItems) ||
            [],
          otherIssues:
            (values?.otherIssues && values?.otherIssues?.otherIssues) || "",
          status: isDraft || saveAsDraft ? "DRAFT" : "COMPLETED"
        };
        onFormSubmit(formData);
        // setClearForm(!clearForm);
      };
      const values: any = valuesRef.current;
      const noDate = !values?.date;
      const noDuration = !values?.duration || !values?.duration?.duration;
      const noDiscussionItems =
        !values?.discussionItems?.discussionItems ||
        values?.discussionItems?.discussionItems?.length <= 0;
      const noOtherIssues =
        !values?.otherIssues || !values?.otherIssues?.otherIssues;
      // if (!noDate && !noDuration) {
      //   const confirmation = window.confirm(
      //     "Do you want to save call record as draft."
      //   );
      //   if (confirmation) {
      //     onFinish(values);
      //   }
      // }
    };
  }, []);

  return (
    <>
      <AppCard cardHeight="100%" cardWidth="70%">
        <OverflowCallRecordCardContainer>
          <AddCallRecordTitleText>
            <Row>
              <Col span={12}>Add Call Record</Col>
              <Col
                span={12}
                style={{
                  textAlign: "right",
                  paddingRight: "20px",
                  pointerEvents: isLoadingPostCallRecord ? "none" : "auto"
                }}
              >
                <CloseOutlined onClick={() => setShowAddEditCallRecords("")} />
              </Col>
            </Row>
            <Divider style={{ margin: "15px 0" }} />
          </AddCallRecordTitleText>
          <Form
            name="call records"
            form={form}
            onValuesChange={onValuesChange}
            onFinish={values => {
              const noDate = !values.date;
              const noDuration = !values.duration || !values.duration.duration;
              const noDiscussionItems =
                !values.discussionItems.discussionItems ||
                values.discussionItems.discussionItems.length <= 0;
              const noOtherIssues =
                !values.otherIssues || !values.otherIssues.otherIssues;

              if (noDate && noDuration && noDiscussionItems && noOtherIssues) {
                setErrorMessage(
                  "The form is empty, please enter a value to proceed."
                );
                return;
              }
              setErrorMessage("");

              setSubmissionData(values);
            }}
          >
            <div>
              <Form.Item name="date">
                <CallRecordDate clearForm={false} />
              </Form.Item>
            </div>
            <AddCallRecordGridContainer>
              <div>
                <Form.Item name="duration">
                  <CallRecordDuration clearForm={false} />
                </Form.Item>
              </div>
              <DurationLabelText>Minutes</DurationLabelText>
            </AddCallRecordGridContainer>
            <Form.Item name="discussionItems">
              <CallRecordDiscussionItems
                discussionItemOptions={callRecordNotes}
                clearForm={false}
              />
            </Form.Item>
            <Form.Item name="otherIssues">
              <CallRecordOtherIssues
                clearForm={false}
                // handleMouseLeave={onLeave}
              />
            </Form.Item>
            <ErrorMessage style={{ marginTop: "-20px" }}>
              {errorMessage}
            </ErrorMessage>
            <Form.Item>
              <ButtonContainer>
                <AppButton
                  type={ButtonType.Secondary}
                  onClick={() => {
                    draftButtonRef.current = true;
                    form.submit();
                    setSaveAsDraft(true);
                  }}
                  buttonContent={"Save as Draft"}
                  style={{ marginRight: 10, width: 120 }}
                  disabled={isLoadingPostCallRecord}
                />
                <AppButton
                  type={ButtonType.Primary}
                  onClick={() => {
                    form.submit();
                    setSaveAsDraft(false);
                  }}
                  buttonContent={"Finalize"}
                  style={{ width: 120 }}
                  loading={isLoadingPostCallRecord}
                  disabled={!enableFinalize}
                />
              </ButtonContainer>
            </Form.Item>
          </Form>
        </OverflowCallRecordCardContainer>
        <AppModal
          visible={submissionData ? true : false}
          title={saveAsDraft ? "Draft Confirmation" : "Finalize Submission"}
          cancelText="No"
          okText="Yes"
          onCancel={() => {
            setSubmissionData(undefined);
          }}
          onOk={() => {
            if (submissionData) {
              onFinish(submissionData);
              setShowAddEditCallRecords("");
              setSubmissionData(undefined);
            }
          }}
          prompt={{
            icon: (
              <InfoCircleOutlined
                style={{ color: "#F5A623", fontSize: "40px" }}
              />
            ),
            text: saveAsDraft ? (
              <div>Save as draft?</div>
            ) : (
              <div>
                You are going to create a call record that is non-editable, do
                you want to finalize?
              </div>
            )
          }}
          footer
        />
      </AppCard>
    </>
  );
};
