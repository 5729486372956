import Select from "antd/lib/select";
import React from "react";
import styled from "styled-components";
import { LabelContainer, LabelText } from "./textInput";
import "./select.css";
import { SelectOption } from "./selectInput";

interface MultiSelectInputProps<T> {
  readonly label: string;
  readonly value: T[] | null;
  readonly placeholder?: string;
  readonly options: SelectOption<T>[];
  readonly onChange: (value: T[]) => void;
  readonly style?: React.CSSProperties;
  readonly dropdownStyle?: React.CSSProperties;
}

const StyledSelect = styled(Select)`
  width: 100%;
`;

export const MultiSelectInput = <T extends string>({
  label,
  value,
  placeholder,
  options,
  onChange,
  style,
  dropdownStyle
}: MultiSelectInputProps<T>) => {
  const onSelectChange = (value: unknown) => onChange(value as T[]);

  return (
    <div style={style}>
      <LabelContainer>
        <LabelText>{label}</LabelText>
      </LabelContainer>
      <StyledSelect
        value={value}
        mode="multiple"
        onChange={onSelectChange}
        placeholder={placeholder}
        dropdownStyle={dropdownStyle}
        onMouseEnter={() =>
          Array.from(
            document.getElementsByClassName("ant-select-selection-item")
          ).forEach(element => {
            element.setAttribute("title", "");
          })
        }
      >
        {options.map(option => (
          <Select.Option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            title=""
          >
            {option.label}
          </Select.Option>
        ))}
      </StyledSelect>
    </div>
  );
};
