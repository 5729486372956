import React from "react";

interface TickProps {
  readonly height?: string | number;
  readonly width?: string | number;
  readonly color?: string;
}

const Tick: React.FC<TickProps> = ({
  height = "24px",
  width = "27px",
  color = "#208CFF"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_17_3)">
        <path
          d="M11.3951 11.2813L17.8486 5L18.712 5.83845L11.3951 12.944L8 9.64703L8.84875 8.80857L11.3951 11.2813Z"
          fill="black"
        />
      </g>
      <path
        d="M11.3951 11.2813L17.8486 5L18.712 5.83845L11.3951 12.944L8 9.64703L8.84875 8.80857L11.3951 11.2813Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <defs>
        <filter
          id="filter0_d_17_3"
          x="0"
          y="0"
          width="26.712"
          height="23.944"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0235294 0 0 0 0 0.615686 0 0 0 0 0.870588 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17_3"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_17_3"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default Tick;
