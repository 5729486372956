import React from "react";

export const FilterIcon: React.FC = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-ActiveMedication-V1.3"
          transform="translate(-791.000000, -155.000000)"
          fill="#212B40"
        >
          <g id="Group" transform="translate(777.000000, 141.000000)">
            <g id="Group-10" transform="translate(14.000000, 14.000000)">
              <polygon
                id="Rectangle"
                points="2.0783717 14 3.93583704 14 3.93583704 16 2.0783717 16"
              ></polygon>
              <polygon
                id="Rectangle-Copy-16"
                points="2.0783717 -8.8817842e-16 3.93583704 -8.8817842e-16 3.93583704 10 2.08700883 10"
              ></polygon>
              <rect
                id="Rectangle-Copy-13"
                x="7.08339044"
                y="6"
                width="1.84085583"
                height="10"
              ></rect>
              <polygon
                id="Rectangle-Copy-21"
                points="7.08339044 0 8.92424627 0 8.92424627 2 7.08339044 2"
              ></polygon>
              <polygon
                id="Rectangle-Copy-15"
                points="12.1337479 12 13.9026526 12 13.9026526 16 12.1337479 16"
              ></polygon>
              <polygon
                id="Rectangle-Copy-22"
                points="12.1337479 0 13.9026526 0 13.9026526 8 12.1337479 8"
              ></polygon>
              <path
                d="M3,14.85 C1.42598846,14.85 0.15,13.5740115 0.15,12 C0.15,10.4259885 1.42598846,9.15 3,9.15 C4.57401154,9.15 5.85,10.4259885 5.85,12 C5.85,13.5740115 4.57401154,14.85 3,14.85 Z M3,13.15 C3.63512746,13.15 4.15,12.6351275 4.15,12 C4.15,11.3648725 3.63512746,10.85 3,10.85 C2.36487254,10.85 1.85,11.3648725 1.85,12 C1.85,12.6351275 2.36487254,13.15 3,13.15 Z"
                id="Oval"
                fillRule="nonzero"
              ></path>
              <path
                d="M8,6.85 C6.42598846,6.85 5.15,5.57401154 5.15,4 C5.15,2.42598846 6.42598846,1.15 8,1.15 C9.57401154,1.15 10.85,2.42598846 10.85,4 C10.85,5.57401154 9.57401154,6.85 8,6.85 Z M8,5.15 C8.63512746,5.15 9.15,4.63512746 9.15,4 C9.15,3.36487254 8.63512746,2.85 8,2.85 C7.36487254,2.85 6.85,3.36487254 6.85,4 C6.85,4.63512746 7.36487254,5.15 8,5.15 Z"
                id="Oval-Copy"
                fillRule="nonzero"
              ></path>
              <path
                d="M13,12.85 C11.4259885,12.85 10.15,11.5740115 10.15,10 C10.15,8.42598846 11.4259885,7.15 13,7.15 C14.5740115,7.15 15.85,8.42598846 15.85,10 C15.85,11.5740115 14.5740115,12.85 13,12.85 Z M13,11.15 C13.6351275,11.15 14.15,10.6351275 14.15,10 C14.15,9.36487254 13.6351275,8.85 13,8.85 C12.3648725,8.85 11.85,9.36487254 11.85,10 C11.85,10.6351275 12.3648725,11.15 13,11.15 Z"
                id="Oval-Copy-2"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
