import moment from "moment";
import { WeightVitals } from "sharedTypes";

export const weightMapper = ({ data }: { data: WeightVitals[] }) => {
  const weightKg: [string, number, string][] = [];
  const weightLb: [string, number, string][] = [];

  const sortedData = data?.sort(
    (a, b) =>
      moment(a.weight_taken_on, "MM/DD/YYYY HH:mm:ss").valueOf() -
      moment(b.weight_taken_on, "MM/DD/YYYY HH:mm:ss").valueOf()
  );

  sortedData?.forEach(item => {
    if (item.ch_kg) {
      weightKg.push([
        String(moment(item.weight_taken_on, "MM/DD/YYYY HH:mm:ss").valueOf()),
        Number(item.ch_kg),
        "Weight (Kg)"
      ]);
    } else if (item.ch_lb) {
      weightLb.push([
        String(moment(item.weight_taken_on, "MM/DD/YYYY HH:mm:ss").valueOf()),
        Number(item.ch_lb),
        "Weight (Lb)"
      ]);
    }
  });

  return [weightKg, weightLb];
};
