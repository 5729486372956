import React from "react";

export const UrineProteinLevelIcon = () => {
  return (
    <svg
      width="70px"
      height="70px"
      viewBox="0 0 70 70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Patient-MyHealthStatus-MyCurrentStatus-V1.1-O2"
          transform="translate(-1095.000000, -172.000000)"
        >
          <g
            id="Group-3-Copy-15"
            transform="translate(1071.000000, 148.000000)"
          >
            <g
              id="urine-protein-level-icon"
              transform="translate(24.000000, 24.000000)"
            >
              <path
                d="M35,-2.72848411e-12 L35,-2.72848411e-12 C54.3299662,-2.73203496e-12 70,15.6700338 70,35 L70,35 C70,54.3299662 54.3299662,70 35,70 L35,70 C15.6700338,70 2.36723813e-15,54.3299662 0,35 L0,35 C-2.36723813e-15,15.6700338 15.6700338,-2.72493325e-12 35,-2.72848411e-12 Z"
                id="Rectangle-Copy-204"
                fill="#EBF3FB"
              ></path>
              <g id="Group-8" transform="translate(13.000000, 12.000000)">
                <g id="Group-54" transform="translate(10.000000, 11.000000)">
                  <path
                    d="M4.6932,22.6025 L6.4392,32.7645 C6.4392,32.7645 5.8222,36.4645 9.8312,36.4645 L28.0672,36.4645 C28.0672,36.4645 31.6492,37.4485 32.4592,32.1765 C33.2682,26.9055 34.1452,22.4225 34.1452,22.4225 L4.6932,22.6025 Z"
                    id="Fill-24"
                    fill="#F3DF79"
                  ></path>
                  <path
                    d="M38.4322,8.6802 L0.2552,8.6802 L0.2552,5.0112 C0.2552,2.5612 2.2472,0.5692 4.6952,0.5692 L33.9922,0.5692 C36.4402,0.5692 38.4322,2.5612 38.4322,5.0112 L38.4322,8.6802 Z"
                    id="Path"
                    fill="#212B40"
                  ></path>
                  <path
                    d="M1.7352,7.2002 L36.9522,7.2002 L36.9522,5.0112 C36.9522,3.3782 35.6242,2.0502 33.9922,2.0502 L4.6952,2.0502 C3.0632,2.0502 1.7352,3.3782 1.7352,5.0112 L1.7352,7.2002 Z"
                    id="Path"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    d="M9.8482,37.3154 C7.5302,37.3154 5.6392,35.4464 5.6072,33.1344 L1.6642,8.1604 L3.1262,7.9294 L7.0862,33.0154 L7.0862,33.0744 C7.0862,34.5964 8.3262,35.8354 9.8482,35.8354 L28.8152,35.8354 C30.3382,35.8354 31.5772,34.5964 31.5772,33.0744 L31.5862,32.9594 L35.5612,7.7834 L37.0232,8.0134 L33.0562,33.1344 C33.0242,35.4454 31.1342,37.3154 28.8152,37.3154 L9.8482,37.3154 Z"
                    id="Fill-28"
                    fill="#212B40"
                  ></path>
                  <path
                    d="M30.3133,12.8721 C29.9063,12.8721 29.5753,12.5431 29.5733,12.1361 C29.5713,11.7261 29.9003,11.3931 30.3083,11.3921 L35.5073,11.3631 L35.5113,11.3631 C35.9183,11.3631 36.2493,11.6911 36.2513,12.0991 C36.2533,12.5081 35.9243,12.8411 35.5153,12.8441 L30.3173,12.8721 L30.3133,12.8721 Z"
                    id="Fill-30"
                    fill="#212B40"
                  ></path>
                  <path
                    d="M30.3133,16.8809 C29.9063,16.8809 29.5763,16.5529 29.5733,16.1459 C29.5703,15.7369 29.8993,15.4029 30.3073,15.4009 L34.3943,15.3719 L34.3993,15.3719 C34.8053,15.3719 35.1363,15.6999 35.1393,16.1079 C35.1423,16.5159 34.8123,16.8499 34.4043,16.8529 L30.3183,16.8809 L30.3133,16.8809 Z"
                    id="Fill-32"
                    fill="#212B40"
                  ></path>
                  <path
                    d="M30.3133,21.1982 C29.9073,21.1982 29.5763,20.8702 29.5733,20.4642 C29.5703,20.0542 29.8983,19.7202 30.3073,19.7182 L34.0843,19.6892 L34.0903,19.6892 C34.4973,19.6892 34.8283,20.0162 34.8313,20.4242 C34.8343,20.8332 34.5043,21.1662 34.0963,21.1702 L30.3193,21.1982 L30.3133,21.1982 Z"
                    id="Fill-34"
                    fill="#212B40"
                  ></path>
                  <path
                    d="M30.3133,25.207 C29.9073,25.207 29.5773,24.88 29.5733,24.474 C29.5693,24.064 29.8973,23.73 30.3063,23.727 L33.4163,23.698 L33.4223,23.698 C33.8283,23.698 34.1593,24.025 34.1623,24.432 C34.1663,24.841 33.8373,25.175 33.4293,25.179 L30.3203,25.207 L30.3133,25.207 Z"
                    id="Fill-36"
                    fill="#212B40"
                  ></path>
                  <path
                    d="M30.3123,29.5244 C29.9083,29.5244 29.5783,29.1984 29.5733,28.7934 C29.5683,28.3834 29.8953,28.0484 30.3043,28.0444 L32.7803,28.0154 L32.7893,28.0154 C33.1933,28.0154 33.5243,28.3404 33.5283,28.7474 C33.5333,29.1564 33.2063,29.4914 32.7963,29.4954 L30.3213,29.5244 L30.3123,29.5244 Z"
                    id="Fill-38"
                    fill="#212B40"
                  ></path>
                  <path
                    d="M30.0047,33.5313 C29.5997,33.5313 29.2697,33.2063 29.2647,32.8003 C29.2597,32.3913 29.5877,32.0553 29.9957,32.0523 L32.1807,32.0263 L32.1897,32.0263 C32.5937,32.0263 32.9247,32.3513 32.9287,32.7583 C32.9337,33.1673 32.6067,33.5023 32.1967,33.5063 L30.0137,33.5313 L30.0047,33.5313 Z"
                    id="Fill-40"
                    fill="#212B40"
                  ></path>
                  <path
                    d="M25.7127,23.3281 L5.2057,23.3281 C4.7967,23.3281 4.4657,22.9971 4.4657,22.5881 C4.4657,22.1801 4.7967,21.8481 5.2057,21.8481 L25.7127,21.8481 C26.1217,21.8481 26.4527,22.1801 26.4527,22.5881 C26.4527,22.9971 26.1217,23.3281 25.7127,23.3281"
                    id="Fill-42"
                    fill="#212B40"
                  ></path>
                </g>
                <g id="Group-7-Copy">
                  <polygon
                    id="Rectangle"
                    fill="#FFFFFF"
                    points="2 2 17 2 17 21.0548763 2 21.5093851"
                  ></polygon>
                  <path
                    d="M2,21 L17,21 L17,41.5 C17,45.6421356 13.6421356,49 9.5,49 L9.5,49 C5.35786438,49 2,45.6421356 2,41.5 L2,21 Z"
                    id="Rectangle-Copy"
                    fill="#F1D22F"
                  ></path>
                  <path
                    d="M2.7917854,26.6386631 C4.62718057,25.7795267 6.80568521,25.7795267 9.32729933,26.6386631 C11.8489134,27.4977996 14.1745223,27.7814002 16.3041258,27.4894651 L16.3041258,21.0178571 L2.7917854,21 L2.7917854,26.6386631 Z"
                    id="Path-5"
                    fill="#F3DF79"
                  ></path>
                  <path
                    d="M2.75,2.75 L2.75,41.5 C2.75,45.2279221 5.77207794,48.25 9.5,48.25 C13.2279221,48.25 16.25,45.2279221 16.25,41.5 L16.25,2.75 L2.75,2.75 Z"
                    id="Rectangle"
                    stroke="#212B40"
                    strokeWidth="1.5"
                  ></path>
                  <circle
                    id="Oval"
                    fill="#212B40"
                    cx="6.17857143"
                    cy="32.9285714"
                    r="1"
                  ></circle>
                  <circle
                    id="Oval-Copy"
                    fill="#212B40"
                    cx="10.6428571"
                    cy="35.6071429"
                    r="1"
                  ></circle>
                  <circle
                    id="Oval-Copy-2"
                    fill="#212B40"
                    cx="7.07142857"
                    cy="40.9642857"
                    r="1"
                  ></circle>
                  <rect
                    id="Rectangle"
                    stroke="#212B40"
                    strokeWidth="1.5"
                    x="-0.142857143"
                    y="-0.75"
                    width="19.3571429"
                    height="3.17857143"
                    rx="1.58928571"
                  ></rect>
                  <path
                    d="M16.0678663,21.0178571 L6.58143271,21.0178571"
                    id="Path-20-Copy-4"
                    stroke="#212B40"
                    strokeWidth="1.55"
                    strokeLinecap="round"
                  ></path>
                  <path
                    d="M16.0678663,7.5 L11,7.5"
                    id="Path-20"
                    stroke="#212B40"
                    strokeWidth="1.55"
                    strokeLinecap="round"
                  ></path>
                  <path
                    d="M16.0678663,15.5 L11,15.5"
                    id="Path-20-Copy-2"
                    stroke="#212B40"
                    strokeWidth="1.55"
                    strokeLinecap="round"
                  ></path>
                  <path
                    d="M16.0678663,11.5 L11,11.5"
                    id="Path-20-Copy"
                    stroke="#212B40"
                    strokeWidth="1.55"
                    strokeLinecap="round"
                  ></path>
                </g>
                <path
                  d="M21.5,45.9642857 L40.5,7.5"
                  id="Line"
                  stroke="#212B40"
                  strokeWidth="2"
                  strokeLinecap="round"
                ></path>
                <circle id="Oval" fill="#212B40" cx="41" cy="7" r="3"></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
