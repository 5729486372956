import { Skeleton } from "antd";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useEffect } from "react";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";

interface DiseasesAnswerProps {
  answers: any;
  isSelectedAnswerLoading: any;
  applyQuickink: any;
}

export const DiseasesAnswer: React.FC<DiseasesAnswerProps> = ({
  answers,
  isSelectedAnswerLoading,
  applyQuickink
}) => {
  const patientContext = useContext<PatientContextState>(PatientContext);
  const [searchText, setSearchText] = useState<any>();

  useEffect(() => {
    if (patientContext)
      if (patientContext.patientEducationResult !== null) {
        setSearchText(patientContext?.patientEducationResult?.document);
      }
  }, [patientContext.patientEducationResult]);

  useEffect(() => {
    searchAndScrollToSection(searchText);
  }, [searchText]);

  useEffect(() => {
    const assignIDsToH1 = () => {
      const answerDocument = document.getElementById("answer-document");
      if (answerDocument) {
        const h1Tags = answerDocument.getElementsByTagName("h1");
        for (let i = 0; i < h1Tags.length; i++) {
          h1Tags[i].id = `h1-${i + 1}`;
        }
      }
    };
    assignIDsToH1();
  }, [answers]);

  const searchAndScrollToSection = (searchText: any) => {
    const paragraphs = document.getElementsByTagName("p");
    for (let i = 0; i < paragraphs.length; i++) {
      const paragraph: any = paragraphs[i];
      if (paragraph.textContent.includes(searchText)) {
        paragraph.style.backgroundColor = "yellow";
        paragraph.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      }
    }
  };

  useEffect(() => {
    if (applyQuickink) {
      scrollToText(applyQuickink);
    }
  }, [applyQuickink]);

  const scrollToText = (text: string) => {
    const answerDocument = document.getElementById("answer-document");
    if (answerDocument) {
      const paragraphs = Array.from(answerDocument.getElementsByTagName("p"));
      const headings = Array.from(answerDocument.getElementsByTagName("h1"));
      const spans = Array.from(answerDocument.getElementsByTagName("span"));
      const anchor = Array.from(answerDocument.getElementsByTagName("a"));
      const elements = [...paragraphs, ...headings, ...spans, ...anchor];
      for (const element of elements) {
        if (element.textContent && element.textContent.includes(text)) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        }
      }
    }
  };

  return (
    <DiseasesAnswerContainer>
      {!isSelectedAnswerLoading ? (
        <>
          <AnswerSummaryTitle>
            {answers && answers[0]?.title}
          </AnswerSummaryTitle>
          <AnswerDocument
            id="answer-document"
            dangerouslySetInnerHTML={{
              __html: answers && answers[0]?.document
            }}
          ></AnswerDocument>
          <AnswerSummary>{answers && answers[0]?.summary}</AnswerSummary>
        </>
      ) : (
        <Skeleton active paragraph={{ rows: 14 }} />
      )}
    </DiseasesAnswerContainer>
  );
};

const DiseasesAnswerContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  width: 60vw;
  padding: 0px 12px 12px 12px;
`;

const AnswerSummary = styled.div`
  margin-top: 12px;
  font-size: 12px;
  padding: 4px 8px;
`;

const AnswerSummaryTitle = styled.div`
  background: #eaf3fc;
  padding: 4px 8px;
  color: #1f8cff;
  font-weight: 700;
`;

const AnswerDocument = styled.div`
  margin-top: 12px;
  font-size: 12px;
  padding: 4px 8px;

  p {
    font-weight: 100;
    font-size: 14px !important;
  }
  h1 {
    font-size: 18px !important;
    font-weight: 600 !important;
    letter-spacing: 0.8px !important;
    margin-bottom: 8px !important;
    font-family: Century Gothic !important;
    word-spacing: 1px !important;
  }
`;
