import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from "react";
import { message, Radio, Tooltip } from "antd";
import Space from "antd/lib/space";
import Table, { ColumnsType } from "antd/lib/table";
import startCase from "lodash/startCase";
import { useNavigate } from "react-router";
import styled from "styled-components";

import { AppAvatar } from "components/avatar";
import { AppCard } from "components/card";
import { CircleIndicator } from "components/circleIndicator";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { FilterDisplay } from "components/filterDisplay";
import { AppModal } from "components/modal";
import { UserRoles } from "constants/roles";
import { CaregiverRoutesRef, PatientsRoutesRef } from "constants/routes";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { Colors } from "helpers/colors";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import { CareTeamList } from "sharedTypes";
import { AppointmentIcon } from "svgIcons/appointmentIcon";
import { TableMessageIcon } from "svgIcons/tableMessageIcon";
import { TablePhoneIcon } from "svgIcons/tablePhoneIcon";
import { TablePersonIcon } from "svgIcons/tableUserIcon";
import "./careTeamTable.css";
import { useMutation, useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { getAccessToken, maskAlternateNumbers } from "helpers/utils";

interface CareTeamTablProps {
  toggleMessages(show: boolean): void;
  readonly showMessages: boolean;
  readonly filterMenuItems: {
    desc: string;
    code: string;
    detail_desc: string;
    checked: boolean;
    value: string;
  }[];
  selectedCareteamMember: CareTeamList | null;
  setSelectedCareteamMember(item: CareTeamList): void;
  readonly providerTypeApplied: boolean;
  setShowProviderProfile(show: boolean): void;
  readonly searchNameValue: string;
  readonly searchSpecialtyValue: string;
  setProviderType(type: string[]): void;
  readonly isCareTeamListError: unknown;
  readonly careTeamData: CareTeamList[];
  readonly isLoadingCareTeamList: boolean;
  readonly isCareTeamListRefetchLoading: boolean;
  setProviderProfileData({
    first_name,
    last_name,
    role,
    specialty,
    group,
    organisations,
    office_address,
    phoneNumbers
  }: {
    first_name: string;
    last_name: string;
    role: string;
    specialty: string;
    group: string;
    organisations: { id: string; name: string }[];
    office_address: string;
    phoneNumbers: { title: string; number: string }[];
    degree: string;
  }): void;
  status: string;
}

export const CareTeamTable: React.FC<CareTeamTablProps> = ({
  toggleMessages,
  showMessages,
  filterMenuItems,
  selectedCareteamMember,
  setSelectedCareteamMember,
  providerTypeApplied,
  setShowProviderProfile,
  searchNameValue,
  searchSpecialtyValue,
  setProviderType,
  isCareTeamListError,
  careTeamData,
  isLoadingCareTeamList,
  isCareTeamListRefetchLoading,
  setProviderProfileData,
  status
}) => {
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const unreadMessageNotifications = useMemo(() => {
    const unseenNotifications =
      notificationsContext.filterMessageNotificationsByStatus(
        notificationsContext.notifications?.messages || [],
        "unread"
      );
    return unseenNotifications;
  }, [notificationsContext.notifications?.messages]);

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;
  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;
  const patientId = sessionStorage.getItem("patientId");

  const userId = sessionStorage.getItem("userId") || "";

  const relevantRoutesRef = userIsCaregiver
    ? CaregiverRoutesRef
    : PatientsRoutesRef;

  const showAppointmentButton = userIsCaregiver || userIsPatient;

  const navigate = useNavigate();

  const [showPhoneNumberSelectModal, setShowPhoneNumberSelectModal] =
    useState(false);

  useEffect(() => {
    if (providerTypeApplied) {
      const filterItems: string[] = [];
      filterMenuItems.forEach(item => {
        if (item.checked) {
          filterItems.push(item.value);
        }
      });
      setProviderType(filterItems);
    }
  }, [providerTypeApplied]);

  const { refetch: getNotificationsList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        userIsPatient
          ? UserRoles.PATIENT
          : userIsCaregiver
          ? UserRoles.CAREGIVER
          : UserRoles.PROVIDER
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      }
    }
  );

  const { mutate: markNotification } = useMutation(
    "mark-notification",
    createApiClient().putNotifications,
    {
      onSuccess: () => {
        getNotificationsList();
      },
      onError: () => {
        message.error("There was a problem updating notifications.");
      }
    }
  );

  const unreadNotifications = useMemo(() => {
    const careteamNotificatonsExceptSurvey =
      notificationsContext.notifications?.care_team?.filter((item: any) => {
        return item.type !== "careteam-survey";
      });
    return notificationsContext.filterNotificationsByStatusAndPatient(
      careteamNotificatonsExceptSurvey || [],
      userIsPatient ? userId : patientId || "",
      "unread"
    );
  }, [notificationsContext.notifications?.care_team]);

  useLayoutEffect(() => {
    return () => {
      if (getAccessToken()) {
        unreadNotifications.forEach(item => {
          markNotification({
            notificationId: item?.notification_id || "",
            body: { status: "read", type: "care_team" }
          });
        });
      }
    };
  }, [unreadNotifications]);

  const compareNames = (a: any, b: any) => {
    return a.user.last_name === b.user.last_name
      ? a.user.first_name.localeCompare(b.user.first_name)
      : a.user.last_name.localeCompare(b.user.last_name);
  };

  const columns: ColumnsType<CareTeamList> = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (name: string, row: CareTeamList) => {
        const unreadItem = unreadNotifications.find(
          item => String(item.item_id) === String(row.user.provider_internal_id)
        );

        return (
          <>
            {unreadItem && (
              <CircleIndicatorWrapperAvatar>
                <CircleIndicator
                  outerColor={"#fbddb7"}
                  innerColor={"#f18e13"}
                />
              </CircleIndicatorWrapperAvatar>
            )}
            <AppAvatar
              imageSrc={row.user.picture}
              size="large"
              mainContent={`${row.user.last_name}, ${row.user.first_name} ${
                row.user.degree ? row.user.degree : ""
              }`}
              subText={
                row.user.specialty || startCase(row.user.role.replace("s", ""))
              }
            />
          </>
        );
      },
      width: showMessages ? "55%" : "45%",
      sorter: (a, b) => compareNames(a, b),
      showSorterTooltip: { title: "" },
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "ascend"
    },
    {
      title: "Recent Message",
      key: "reacentMessage",
      dataIndex: "",
      render: (_, row: CareTeamList) => {
        const message = row.user.latest_message || "";
        const words = message.split(" ");
        const truncatedMessage =
          words.length > 12 ? `${words.slice(0, 12).join(" ")}...` : message;

        return (
          <RowStyle>
            <i style={{ color: Colors.Grey }}>{truncatedMessage}</i>
          </RowStyle>
        );
      },
      width: showMessages ? "27%" : "40%"
    },
    {
      key: "actions",
      dataIndex: "actions",
      render: (_, row: CareTeamList) => {
        const unreadMessages = unreadMessageNotifications.filter(
          item =>
            item.channel_name.includes(
              patientContext?.patientData?.username || ""
            ) &&
            item.channel_name.includes(userContext?.userData?.userName || "") &&
            item.channel_name.includes(row.user.username || "")
        );

        return (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              paddingRight: "10px"
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Space size={20}>
              {showAppointmentButton &&
                row.user.role.toLowerCase().trim() !== UserRoles.CAREGIVERS && (
                  <Tooltip
                    placement="top"
                    title={`Book Appointment with Provider`}
                  >
                    <ActionSpan
                      onClick={() => {
                        if (userIsCaregiver) {
                          navigate(
                            relevantRoutesRef.Appointments + "/" + patientId
                          );
                        } else {
                          navigate(relevantRoutesRef.Appointments);
                        }
                        sessionStorage.setItem(
                          "selectedProviderName",
                          String(row.user.first_name + " " + row.user.last_name)
                        );
                        sessionStorage.setItem(
                          "selectedProviderInternalId",
                          String(row.user.provider_internal_id)
                        );
                      }}
                    >
                      <AppointmentIcon />
                    </ActionSpan>
                  </Tooltip>
                )}

              <Tooltip placement="top" title={"Chat with Provider"}>
                <ActionSpan
                  onClick={() => {
                    toggleMessages(true);
                    setSelectedCareteamMember(row);
                  }}
                >
                  {unreadMessages.length > 0 && (
                    <CircleIndicatorWrapper>
                      <CircleIndicator
                        outerColor={"#fbddb7"}
                        innerColor={"#f18e13"}
                      />
                    </CircleIndicatorWrapper>
                  )}
                  <TableMessageIcon />
                </ActionSpan>
              </Tooltip>

              <Tooltip placement="top" title={"Call Provider"}>
                <ActionSpan
                  onClick={() => {
                    if (meetingContext.targetUser.name) {
                      return;
                    } else {
                      setSelectedCareteamMember(row);
                      setShowPhoneNumberSelectModal(true);
                    }
                  }}
                  style={{
                    cursor: meetingContext.targetUser.name
                      ? "not-allowed"
                      : "pointer"
                  }}
                >
                  <TablePhoneIcon />
                </ActionSpan>
              </Tooltip>

              <Tooltip placement="top" title={"View Provider Details"}>
                <ActionSpan
                  onClick={() => {
                    setShowProviderProfile(true);
                    setProviderProfileData({
                      first_name: row.user.first_name,
                      last_name: row.user.last_name,
                      role: row.user.role,
                      specialty: row.user.specialty,
                      group: row.user.group,
                      organisations: row.user.organizations,
                      office_address: row.user.office_address,
                      phoneNumbers: row.user.phoneNumbers,
                      degree: row.user.degree
                    });
                  }}
                >
                  <TablePersonIcon />
                </ActionSpan>
              </Tooltip>
            </Space>
          </div>
        );
      }
    }
  ];

  const menuItemSelectedList = JSON.parse(
    sessionStorage.getItem("careTeamCheckboxItems") || "[]"
  ).filter(
    (obj: {
      desc: string;
      code: string;
      detail_desc: string;
      checked: boolean;
    }) => {
      return obj.checked;
    }
  );

  const callOnlyOffice =
    userIsPatient &&
    selectedCareteamMember?.user.role !== UserRoles.CAREGIVER &&
    selectedCareteamMember?.user.role !== UserRoles.CAREGIVERS;

  const officeNumber = selectedCareteamMember?.user?.phoneNumbers.find(
    item => item.title.toLowerCase().trim() === "office" || "home"
  );

  const singleNumber =
    selectedCareteamMember &&
    selectedCareteamMember.user.phoneNumbers.length <= 1;

  const isOfficeNumberSameAsCell =
    !singleNumber &&
    selectedCareteamMember?.user.phoneNumbers[0].number ===
      selectedCareteamMember?.user.phoneNumbers[1].number;

  console.log("status 405", status);

  return (
    <AppCard cardHeight="fit-content" cardWidth="100%">
      <FilterDisplay
        value1={searchNameValue}
        value2={searchSpecialtyValue}
        list1={menuItemSelectedList.map(
          (obj: {
            desc: string;
            code: string;
            detail_desc: string;
            checked: boolean;
          }) => obj.desc
        )}
      />
      {isCareTeamListError ? (
        status === "error" ? (
          <CommonErrorMessage message="No matching records found" />
        ) : (
          <CommonErrorMessage message="There was an error fetching the care team list" />
        )
      ) : (
        <>
          <Table<CareTeamList>
            data-testid="care-team-table"
            columns={columns}
            dataSource={careTeamData}
            rowKey={(record: CareTeamList) => record.user.id}
            rowClassName={record =>
              record.user.username === selectedCareteamMember?.user.username &&
              record.user.id === selectedCareteamMember?.user.id
                ? "care-team-member-selected"
                : ""
            }
            pagination={false}
            scroll={{ y: showMessages ? "47vh" : "51vh" }}
            onRow={row => {
              return {
                onClick: () => {
                  if (userIsCaregiver && row.user.role === "caregivers") return;
                  toggleMessages(true);
                  setSelectedCareteamMember(row);
                } // click row
              };
            }}
            loading={isLoadingCareTeamList || isCareTeamListRefetchLoading}
          />
          {showPhoneNumberSelectModal && (
            <AppModal
              visible={showPhoneNumberSelectModal}
              title={
                userIsPatient || isOfficeNumberSameAsCell || singleNumber
                  ? "Call Confirmation"
                  : "Select Phone Number"
              }
              cancelText="Cancel"
              okText="Call"
              onCancel={() => {
                setShowPhoneNumberSelectModal(false);
                meetingContext.setPSTNPhoneNumber("");
              }}
              disablePrimaryButton={
                !meetingContext.pstnPhoneNumber &&
                !callOnlyOffice &&
                !isOfficeNumberSameAsCell &&
                !singleNumber
              }
              onOk={() => {
                if (userIsPatient) {
                  meetingContext.setCurrentPage(1);
                } else {
                  meetingContext.setCurrentPage(3);
                }
                userContext.setIsProviderModal(true);
                meetingContext.maximize(true);
                setShowPhoneNumberSelectModal(false);
                meetingContext.setTargetUser(
                  selectedCareteamMember
                    ? selectedCareteamMember.user.name
                    : "",
                  String(selectedCareteamMember?.user.id)
                );
                if (
                  (callOnlyOffice ||
                    isOfficeNumberSameAsCell ||
                    singleNumber) &&
                  officeNumber
                ) {
                  meetingContext.setPSTNPhoneNumber(
                    officeNumber.number.replaceAll("-", "")
                  );
                }
              }}
            >
              {callOnlyOffice ? (
                <OnlyOfficePhoneNumberView direction="vertical">
                  <div>
                    {officeNumber
                      ? userIsPatient
                        ? maskAlternateNumbers(officeNumber?.number)
                        : officeNumber?.number
                      : "No office number found"}
                  </div>
                </OnlyOfficePhoneNumberView>
              ) : singleNumber ? (
                <OnlyOfficePhoneNumberView direction="vertical">
                  <div>
                    {selectedCareteamMember?.user &&
                    selectedCareteamMember?.user.phoneNumbers[0]
                      ? userIsPatient
                        ? maskAlternateNumbers(
                            selectedCareteamMember?.user.phoneNumbers[0].number
                          )
                        : selectedCareteamMember?.user.phoneNumbers[0].number
                      : "No number found"}
                  </div>
                </OnlyOfficePhoneNumberView>
              ) : isOfficeNumberSameAsCell ? (
                <OnlyOfficePhoneNumberView direction="vertical">
                  <div>Cell and Office</div>
                  <div>
                    {officeNumber
                      ? userIsPatient
                        ? maskAlternateNumbers(officeNumber?.number)
                        : officeNumber?.number
                      : "No office number found"}
                  </div>
                </OnlyOfficePhoneNumberView>
              ) : (
                <Radio.Group>
                  <Space direction="vertical">
                    {selectedCareteamMember?.user.phoneNumbers.map(
                      (item, index) => {
                        return (
                          <Radio
                            value={index}
                            key={index}
                            onClick={() => {
                              meetingContext.setPSTNPhoneNumber(
                                item.number.replaceAll("-", "")
                              );
                            }}
                          >
                            {`${
                              userIsPatient
                                ? maskAlternateNumbers(item.number)
                                : item.number
                            } (${item.title})`}
                          </Radio>
                        );
                      }
                    )}
                  </Space>
                </Radio.Group>
              )}
            </AppModal>
          )}
        </>
      )}
    </AppCard>
  );
};

const RowStyle = styled.div`
  font-size: 12px;
  color: ${Colors.Black};
`;

const ActionSpan = styled.span`
  cursor: pointer;
`;

const OnlyOfficePhoneNumberView = styled(Space)`
  text-align: center;
  font-weight: bold;
  width: 100%;
`;

const CircleIndicatorWrapperAvatar = styled.span`
  position: absolute;
  margin-left: -10px;
  margin-top: -2px;
`;

const CircleIndicatorWrapper = styled.span`
  position: absolute;
  margin-left: 7px;
`;
