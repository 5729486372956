import React from "react";
import styled from "styled-components";
import arrowup from "../../Images/arrowup.svg";
import { useEffect, useState } from "react";

interface QuickLinksProps {
  answers: any;
  setQuickLink: (flag: any) => void;
  applyQuickink: any;
}

export const QuickLink: React.FC<QuickLinksProps> = ({
  answers,
  setQuickLink,
  applyQuickink
}) => {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setQuickLink("");
    }, 1000);
  }, [applyQuickink]);

  return (
    <QuickLinkContainer>
      <QuickLinkTitle>QUICK LINKS</QuickLinkTitle>
      <QuickLinkWrapper>
        {answers[0]?.tags?.map((item: any, index: any) => (
          <QuickLinks key={index} onClick={() => setQuickLink(item)}>
            <img src={arrowup} style={{ marginRight: "3px" }} />
            {item}
          </QuickLinks>
        ))}
      </QuickLinkWrapper>

      <QuestionsTitle>RELATED QUESTION</QuestionsTitle>
      <RelatedQuestionWrapper>
        {answers[0]?.question?.map((item: any, index: any) => (
          <RelatedQuestion
            key={index}
            onClick={() => scrollToSection(`h1-${index + 1}`)}
          >
            {item}
          </RelatedQuestion>
        ))}
      </RelatedQuestionWrapper>
    </QuickLinkContainer>
  );
};

const QuickLinkContainer = styled.div`
  width: 24vw;
  border-right: 1px solid #dadada;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
`;

const QuickLinkTitle = styled.div`
  font-size: 11px;
  color: #696969;
  font-weight: bold;
`;

const QuickLinkWrapper = styled.div`
  margin-top: 5px;
`;

const QuickLinks = styled.div`
  float: left;
  font-size: 8px;
  padding: 1px 5px;
  border: 1px solid #696969;
  border-radius: 20px;
  margin-right: 5px;
  margin-bottom: 5px;

  &:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }
`;

const QuestionsTitle = styled.div`
  font-size: 11px;
  color: #696969;
  font-weight: bold;
  margin-top: 20px;
`;

const RelatedQuestionWrapper = styled.div`
  overflow-y: scroll;
  scrollbar-width: none;
`;

const RelatedQuestion = styled.div`
  font-size: 10.5px;
  padding: 6px 4px;
  border-bottom: 1px solid #dadada;

  &:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }
`;
