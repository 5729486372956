import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import styled, { CSSProperties } from "styled-components";
import Col from "antd/lib/col";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import { Colors } from "helpers/colors";
import { AppAvatar } from "components/avatar";
import { MessageItem } from "components/messageItem";
import { PerspectiveTypes } from "components/messageItem/messageItem";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { SendIcon } from "./SendIcon";
import { SelectedConversationUserInfo } from "pages/messages/messages";
import moment from "moment";
import { useQuery, useMutation } from "react-query";
import { UserContext, UserContextState } from "contextApis/userContext";
import Spin from "antd/lib/spin";
import { AppSwitch } from "components/switch";
import { createApiClient } from "apiClient";
import message from "antd/lib/message";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import { CloseIcon } from "svgIcons/closeIcon";
import {
  AllChatsPaginationData,
  ChannelMessageData,
  ChannelUserInfo,
  MessageType,
  UserInfoMap
} from "sharedTypes";
import { convertAllChatsToCustomMessageData } from "pages/patientAllMessages/patientAllMessages";
import { useLocation } from "react-router";
import appConfig from "Config";
import uniqBy from "lodash/uniqBy";

const EMPTY_CHANNEL_USER_DATA: ChannelUserInfo = {
  address_city: "",
  address_zip: "",
  cell: "",
  dea_number: "",
  degree: "",
  dob: "",
  drive_license_number: "",
  email: "",
  external_id: "",
  first_name: "",
  gender: "",
  home_addr_1: "",
  home_addr_2: "",
  home_tel: "",
  last_name: "",
  npi: "",
  office_addr_1: "",
  office_addr_2: "",
  office_tel: "",
  role: "",
  specialty: "",
  ssn: "",
  state: "",
  username: "",
  year_grad_med_school: "",
  internal_id: ""
};

const { TextArea } = Input;

const CHIME_MESSAGE_TIME_FORMAT = "YYYY/MM/DD, HH:mm:ss";

const WEBSOCKET_BASE_URL = appConfig.websocketURL;

export interface MessageData {
  readonly content: string;
  readonly picture: string;
  readonly timestamp: number;
  readonly sender_first_name: string;
  readonly sender_last_name: string;
  readonly sender_degree?: string;
  readonly receiver_first_name?: string;
  readonly receiver_last_name?: string;
  readonly receiver_degree?: string;
  readonly perspective: PerspectiveTypes;
  readonly regarding_first_name?: string;
  readonly regarding_last_name?: string;
  readonly message_id?: string;
  readonly reciver_name?: string;
}

interface SendMessageBody {
  readonly action: "sendMessage";
  readonly channel_name: string;
  readonly profile: string;
  readonly content: string;
  readonly receiver_key: string;
  readonly route_key: string;
}

interface MessageContainerProps {
  readonly messageItemWidth: string;
  readonly allMessagesFormat?: boolean;
  readonly sender: string;
  readonly specialization: string;
  readonly degree?: string;
  readonly subject: string;
  readonly style?: CSSProperties;
  readonly picture: string;
  readonly messageData: MessageData[];
  readonly noFooter?: boolean;
  readonly nextToken: string | null;
  readonly selectedConversationUserInfo?: SelectedConversationUserInfo | null;
  readonly customPlaceholderText?: string;
  readonly userIsPatient?: boolean;
  readonly userIsCaregiver?: boolean;
  readonly onCloseMessageContainer?: () => void;
  readonly pagination?: AllChatsPaginationData[] | null;
  readonly oldChatHistoryPage?: boolean;
  readonly allChatsUsername?: string;
}

interface WebsocketMessageObject {
  readonly content: string;
  readonly channel_name: string;
  readonly sender: string;
  readonly receiver_name: string;
  readonly sender_name: string;
}

const getOtherUsername = (channelName: string, loggedInUsername: string) => {
  if (!loggedInUsername || !channelName) {
    return "";
  }
  const splitUsernames = channelName.split(/_/);

  if (splitUsernames.length === 2) {
    return splitUsernames.find(name => name !== loggedInUsername) || "";
  }
  if (splitUsernames.length === 3) {
    const filteredUsernames = splitUsernames.slice(-2);
    return filteredUsernames.find(name => name !== loggedInUsername) || "";
  }
  return "";
};

const getOtherUserData = (
  userIsPatient: boolean,
  patientUsername: string,
  user1Username: string,
  user2Username: string,
  loggedInUsername: string,
  userInfo: UserInfoMap
): ChannelUserInfo => {
  if (!patientUsername || !user1Username) {
    return EMPTY_CHANNEL_USER_DATA;
  }
  if (userIsPatient) {
    return userInfo[user1Username];
  }
  if (!user2Username) {
    return userInfo[patientUsername];
  }
  if (user1Username === loggedInUsername) {
    return userInfo[user2Username];
  }
  return userInfo[user1Username];
};

const convertOldHistoryMessageDataToCustomMessageData = (
  messages: MessageType[],
  otherUserData: ChannelUserInfo,
  internalIdToUserDataMap: Record<string, ChannelUserInfo>
) => {
  if (!messages || !otherUserData) {
    return [];
  }
  return messages.map<MessageData>(messageItem => ({
    content: messageItem.content,
    perspective:
      otherUserData.internal_id.toString() === messageItem.senderId.toString()
        ? PerspectiveTypes.Patient
        : PerspectiveTypes.Provider,
    picture: "",
    sender_first_name:
      internalIdToUserDataMap[messageItem.senderId]?.first_name || "",
    sender_last_name:
      internalIdToUserDataMap[messageItem.senderId]?.last_name || "",
    timestamp: messageItem.timestamp,
    sender_degree: internalIdToUserDataMap[messageItem.senderId]?.degree || ""
  }));
};

const convertMessageToCustomMessageData = (
  message: ChannelMessageData,
  selectedConversationUserInfo: SelectedConversationUserInfo,
  youPerspectiveUsername: string
) => {
  const chimeMessageSenderUsername = message.Sender || "";
  return {
    content: message.latest_message || "",
    perspective:
      chimeMessageSenderUsername === youPerspectiveUsername
        ? PerspectiveTypes.Provider
        : PerspectiveTypes.Patient,
    picture: "",
    sender_first_name:
      selectedConversationUserInfo?.userInfo[chimeMessageSenderUsername]
        ?.first_name || "",
    sender_last_name:
      selectedConversationUserInfo?.userInfo[chimeMessageSenderUsername]
        ?.last_name || "",
    timestamp: moment(
      moment.utc(message.latest_message_timestamp, CHIME_MESSAGE_TIME_FORMAT)
    ).valueOf(),
    sender_degree:
      selectedConversationUserInfo?.userInfo[chimeMessageSenderUsername]
        ?.degree || "",
    reciver_name: message.Reciver
  };
};

export const convertOneToOneChimeMessagesToCustomMessageData = (
  selectedConversationUserInfo: SelectedConversationUserInfo | null,
  channelMessages: ChannelMessageData[],
  youPerspectiveUsername: string
): MessageData[] => {
  if (
    selectedConversationUserInfo &&
    channelMessages &&
    youPerspectiveUsername
  ) {
    return channelMessages
      .sort(
        (a, b) =>
          moment(
            a.latest_message_timestamp,
            CHIME_MESSAGE_TIME_FORMAT
          ).valueOf() -
          moment(
            b.latest_message_timestamp,
            CHIME_MESSAGE_TIME_FORMAT
          ).valueOf()
      )
      .map(message =>
        convertMessageToCustomMessageData(
          message,
          selectedConversationUserInfo,
          youPerspectiveUsername
        )
      );
  }
  return [];
};

export const MessageContainerComponent: React.FC<MessageContainerProps> = ({
  messageItemWidth,
  allMessagesFormat,
  sender,
  specialization,
  degree,
  subject,
  messageData,
  style,
  picture,
  noFooter,
  nextToken,
  selectedConversationUserInfo,
  customPlaceholderText,
  userIsPatient,
  userIsCaregiver,
  onCloseMessageContainer,
  pagination,
  oldChatHistoryPage,
  allChatsUsername
}) => {
  const socket = useRef<WebSocket | null>(null);
  const name = useRef<string>();
  const [oldMessagesStartIndex, setOldMessagesStartIndex] = useState<number>(0);
  const oldMessagesStartItemRef = useRef<HTMLDivElement>(null);
  const loadOldChatHistoryClickedRef = useRef<boolean>(false);
  const loadOldMessagesClickRef = useRef<boolean>(false);
  const userContext = useContext<UserContextState>(UserContext);
  const [isLoggedInUserPartOfChannel, setIsLoggedInUserPartOfChannel] =
    useState<boolean>(false);
  const [isPatientEnabled, setIsPatientEnabled] = useState<boolean>(
    selectedConversationUserInfo?.channel.is_patient_enabled === 1
  );
  const [messageList, setMessageList] = useState<MessageData[]>(messageData);
  const messageListRef = useRef(messageList);
  const [textMessage, setTextMessage] = useState<string>("");
  const [messageListNextToken, setMessageListToken] = useState<string | null>(
    nextToken
  );
  const [messageListPagination, setMessageListPagination] = useState<
    AllChatsPaginationData[] | null
  >(pagination ? pagination : null);
  const messagesEndRef = useRef<any>(null);
  const location = useLocation();
  const sendMessageRef = useRef(false);

  const patientUsername = useMemo(() => {
    if (!selectedConversationUserInfo) {
      return "";
    }
    return selectedConversationUserInfo.channel.pat_uname;
  }, [selectedConversationUserInfo]);

  const user1Username = useMemo(() => {
    if (!selectedConversationUserInfo) {
      return "";
    }
    return selectedConversationUserInfo.channel.user1_uname;
  }, [selectedConversationUserInfo]);

  const user2Username = useMemo(() => {
    if (!selectedConversationUserInfo) {
      return "";
    }
    return selectedConversationUserInfo.channel.user2_uname;
  }, [selectedConversationUserInfo]);

  const loggedInUsername = useMemo(() => {
    if (!userContext.userData) {
      return "";
    }
    return userContext.userData.userName;
  }, [userContext.userData]);

  const otherUserData = useMemo(() => {
    if (
      !selectedConversationUserInfo ||
      !loggedInUsername ||
      !patientUsername ||
      !user1Username
    ) {
      return EMPTY_CHANNEL_USER_DATA;
    }
    return getOtherUserData(
      !!userIsPatient,
      patientUsername,
      user1Username,
      user2Username,
      loggedInUsername,
      selectedConversationUserInfo.userInfo
    );
  }, [
    userIsPatient,
    selectedConversationUserInfo,
    patientUsername,
    loggedInUsername,
    user1Username,
    user2Username
  ]);

  const internalIdToUserDataMap = useMemo(() => {
    if (!selectedConversationUserInfo) {
      return {};
    }
    const userInfoObject = selectedConversationUserInfo.userInfo;
    return Object.keys(userInfoObject).reduce(
      (
        internalIdUserInfoMap: Record<string, ChannelUserInfo>,
        username: string
      ) => {
        internalIdUserInfoMap[userInfoObject[username].internal_id] =
          userInfoObject[username];
        return internalIdUserInfoMap;
      },
      {}
    );
  }, [selectedConversationUserInfo]);

  useEffect(() => {
    messageListRef.current = messageList;
  });

  useEffect(() => {
    if (selectedConversationUserInfo) {
      if (isEmpty(messageData) && otherUserData.internal_id) {
        getOldChatHistory();
      }
    }
  }, [messageData, selectedConversationUserInfo]);

  const {
    isLoading: isOlderAllChatsLoading,
    data: olderAllChatsData,
    error: olderAllChatsError,
    isFetching: isOlderAllChatsFetching,
    refetch: getOlderAllChats
  } = useQuery(
    "get_older_all_chats",
    async () => {
      return await createApiClient().getAllChats(
        allChatsUsername || userContext.userData?.userName || "",
        messageListPagination
      );
    },
    {
      enabled: false
    }
  );

  const {
    data,
    isLoading,
    isFetching,
    error,
    refetch: getOlderMessages
  } = useQuery(
    "get_older_messages",
    async () => {
      return await createApiClient().listChannelMessages(
        selectedConversationUserInfo?.channel.channel_name || "",
        messageListNextToken
      );
    },
    { enabled: false }
  );

  const {
    data: oldChatHistoryMessageData,
    isLoading: isOldChatHistoryLoading,
    isFetching: isOldChatHistoryFetching,
    refetch: getOldChatHistory
  } = useQuery(
    "old-message-history",
    async () => {
      return await createApiClient().getMessageHistory(
        otherUserData.internal_id,
        user2Username
          ? selectedConversationUserInfo?.userInfo[patientUsername].internal_id
          : ""
      );
    },
    {
      enabled: false
    }
  );

  const {
    mutate: updatePatientParticipation,
    isLoading: isPatientParticipationUpdating
  } = useMutation(
    "update_patient_participation",
    async (body: {
      pat_uname: string;
      user_uname: string;
      isPatientEnabled: boolean;
    }) => {
      return await createApiClient().updatePatientParticipation(
        body.pat_uname,
        body.user_uname,
        body.isPatientEnabled
      );
    },
    {
      onSuccess: () => {
        message.success("Successfully updated patient participation");
      },
      onError: (err, body) => {
        message.error("Failed to update patient participation");
        setIsPatientEnabled(!body.isPatientEnabled);
      }
    }
  );

  const { mutate: sendMessage, isLoading: isSendingMessage } = useMutation(
    "send_channel_message",
    async (body: SendMessageBody) => {
      return sendChannelMessage(body);
    },
    {
      onSuccess: async () => {
        if (selectedConversationUserInfo) {
          const newMessage: ChannelMessageData = {
            latest_message: textMessage,
            latest_message_timestamp: moment
              .utc()
              .format(CHIME_MESSAGE_TIME_FORMAT),
            Sender: userContext.userData?.userName || ""
          };
          if (userContext.userData) {
            const newCustomMessageData = convertMessageToCustomMessageData(
              newMessage,
              selectedConversationUserInfo,
              userContext.userData?.userName || ""
            );
            const newMessageList = [...messageListRef.current];

            setMessageList([...newMessageList, newCustomMessageData]);
            scrollToBottom();
          }
          setTextMessage("");
          sendMessageRef.current = false;
        }
      },
      onError: () => {
        message.error("Failed to send message");
      }
    }
  );

  const isOlderMessagesLoading = useMemo(
    () => isFetching || isLoading,
    [isFetching, isLoading]
  );

  const isOlderAllMessagesLoading = useMemo(
    () => isOlderAllChatsFetching || isOlderAllChatsLoading,
    [isOlderAllChatsFetching, isOlderAllChatsLoading]
  );

  const isOldChatHistoryMessagesLoading = useMemo(
    () => isOldChatHistoryLoading || isOldChatHistoryFetching,
    [isOldChatHistoryLoading, isOldChatHistoryFetching]
  );

  const scrollToBottom = () => {
    setTimeout(
      () =>
        messagesEndRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end"
        }),
      100
    );
  };

  const onSwitchToggle = (identifier: string | number, state: boolean) => {
    setIsPatientEnabled(state);
    updatePatientParticipation({
      pat_uname: selectedConversationUserInfo?.channel.pat_uname || "",
      user_uname: selectedConversationUserInfo?.channel.user1_uname || "",
      isPatientEnabled: state
    });
  };

  const onTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setTextMessage(event.target.value);

  const onKeyboardEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    if (
      event.key === "Enter" &&
      // (event.ctrlKey || event.metaKey) && // uncomment this line to have ctrl+enter send the message
      !isEmpty(textMessage.replaceAll("\n", "").trim()) &&
      selectedConversationUserInfo &&
      !allMessagesFormat
    ) {
      if (!sendMessageRef.current) {
        sendMessageRef.current = true;
        const splitName = name.current ? name.current.split(/_/) : "";
        const newName = splitName[1] + "_" + splitName[0];
        sendMessage({
          action: "sendMessage",
          channel_name: selectedConversationUserInfo.channel.channel_name,
          content: textMessage,
          profile: userContext.userIdentityId,
          receiver_key: newName,
          route_key: name.current || ""
        });
      }
    }
  };

  const onSendMessage = () => {
    if (
      selectedConversationUserInfo &&
      !isEmpty(textMessage.replaceAll("\n", "").trim()) &&
      !allMessagesFormat
    ) {
      const splitName = name.current ? name.current.split(/_/) : "";

      const newName = splitName[1] + "_" + splitName[0];
      sendMessage({
        action: "sendMessage",
        channel_name: selectedConversationUserInfo.channel.channel_name,
        content: textMessage,
        profile: userContext.userIdentityId,
        receiver_key: newName,
        route_key: name.current || ""
      });
    }
  };

  const onConnect = useCallback(
    (localName: string) => {
      name.current = localName ? localName : "";
      if (socket.current?.readyState !== WebSocket.OPEN) {
        socket.current = new WebSocket(
          `${WEBSOCKET_BASE_URL}?routeKey=${localName}`
        );
        socket.current.addEventListener("message", event => {
          onSocketMessage(event.data);
        });
      }
    },
    [
      userContext.isAuthenticated,
      userContext.userIdentityId,
      userContext.userData,
      allMessagesFormat,
      selectedConversationUserInfo
    ]
  );

  const onDisconnect = useCallback(() => {
    socket.current?.close();
  }, []);

  const onSocketMessage = (dataStr: string) => {
    const data: WebsocketMessageObject = JSON.parse(dataStr);
    if (
      selectedConversationUserInfo &&
      data.channel_name === selectedConversationUserInfo.channel.channel_name
    ) {
      const newMessage: ChannelMessageData = {
        latest_message: data.content,
        latest_message_timestamp: moment
          .utc()
          .format(CHIME_MESSAGE_TIME_FORMAT),
        Sender: data.sender,
        Reciver: data.sender_name
      };
      if (selectedConversationUserInfo && userContext.userData) {
        const newCustomMessageData = convertMessageToCustomMessageData(
          newMessage,
          selectedConversationUserInfo,
          userContext.userData.userName || ""
        );
        const newMessageList = [...messageListRef.current];

        setMessageList([...newMessageList, newCustomMessageData]);
        scrollToBottom();
      }
    }
  };

  const sendChannelMessage = useCallback(
    (body: SendMessageBody) => socket.current?.send(JSON.stringify(body)),
    []
  );

  const onLoadOldChatHistoryClick = () => {
    loadOldChatHistoryClickedRef.current = true;
    getOldChatHistory();
  };

  useEffect(() => {
    if (
      !oldChatHistoryPage &&
      !allMessagesFormat &&
      userContext.isAuthenticated &&
      userContext.userIdentityId &&
      userContext.userData &&
      selectedConversationUserInfo
    ) {
      const loggedInUsername = userContext.userData.userName;
      const otherUsername = getOtherUsername(
        selectedConversationUserInfo.channel.channel_name,
        loggedInUsername
      );
      if (otherUsername && loggedInUsername) {
        onConnect(`${loggedInUsername}_${otherUsername}`);
      }
    }

    return () => {
      onDisconnect();
    };
  }, [
    userContext.isAuthenticated,
    userContext.userIdentityId,
    userContext.userData,
    allMessagesFormat,
    selectedConversationUserInfo
  ]);

  useEffect(() => {
    if (data) {
      const olderMessagesData = convertOneToOneChimeMessagesToCustomMessageData(
        selectedConversationUserInfo || null,
        data.messages || [],
        userContext.userData?.userName || ""
      );
      const existingMessageList = [...messageList];
      setOldMessagesStartIndex(olderMessagesData.length);
      setMessageList([...olderMessagesData, ...existingMessageList]);
      setMessageListToken(data.next_token || null);
    }
  }, [data]);

  useEffect(() => {
    if (olderAllChatsData) {
      const olderMessagesData = convertAllChatsToCustomMessageData(
        olderAllChatsData.messages,
        olderAllChatsData.user_info,
        allChatsUsername || userContext.userData?.userName || ""
      );
      const existingMessageList = [...messageList];
      setOldMessagesStartIndex(olderMessagesData.length);
      const allMessages = uniqBy(
        [...olderMessagesData, ...existingMessageList],
        "message_id"
      );
      setMessageList(sortBy(allMessages, "timestamp"));
      setMessageListPagination(olderAllChatsData.pagination);
    }
  }, [olderAllChatsData]);

  useEffect(() => {
    if (oldChatHistoryMessageData) {
      const oldHistoryMessages =
        convertOldHistoryMessageDataToCustomMessageData(
          oldChatHistoryMessageData,
          otherUserData,
          internalIdToUserDataMap
        );
      const existingMessageList = [...messageList];
      setOldMessagesStartIndex(oldHistoryMessages.length);
      setMessageList(
        sortBy([...oldHistoryMessages, ...existingMessageList], "timestamp")
      );
    }
  }, [oldChatHistoryMessageData]);

  useEffect(() => {
    if (error) {
      message.error("Failed to fetch older messages");
    }
  }, [error]);

  useEffect(() => {
    if (olderAllChatsError) {
      message.error("Failed to fetch older messages");
    }
  }, [olderAllChatsError]);

  const onGetOlderMessagesClick = () => {
    loadOldMessagesClickRef.current = true;
    if (allMessagesFormat) {
      getOlderAllChats();
      return;
    }
    getOlderMessages();
  };

  const avatarMainContent = (
    <>
      {sender} {degree ? `| ${degree}` : ""}
      <HeaderSubjectStyle>
        {subject ? `| Re: ${subject}` : ""}
      </HeaderSubjectStyle>
    </>
  );

  const regularMessagesTextAreaSpan = {
    textAreaSpan: 21,
    buttonSpan: 3
  };

  const allMessagesTextAreaSpan = {
    textAreaSpan: 23,
    buttonSpan: 1
  };

  useEffect(() => {
    if (!selectedConversationUserInfo || !userContext.userData) {
      return;
    }
    setIsLoggedInUserPartOfChannel(
      selectedConversationUserInfo.channel.channel_name.includes(
        userContext.userData.userName
      )
    );
  }, [selectedConversationUserInfo, userContext.userData]);

  useEffect(() => {
    setMessageListToken(nextToken);
  }, [nextToken]);

  const showEnablePatientToggle = useMemo(
    () =>
      !selectedConversationUserInfo?.channel.user2_uname &&
      !noFooter &&
      !userIsPatient &&
      !userIsCaregiver,
    [selectedConversationUserInfo, noFooter, userIsPatient, userIsCaregiver]
  );

  const showLoadOlderMessagesButton = useMemo(
    () =>
      (messageListNextToken && !isOlderMessagesLoading) ||
      (messageListPagination?.some(item => item.next_token !== null) &&
        !isOlderAllMessagesLoading),
    [
      messageListNextToken,
      isOlderMessagesLoading,
      messageListPagination,
      isOlderAllMessagesLoading
    ]
  );

  const showLoadOldChatHistoryLink = useMemo(
    () =>
      !isEmpty(messageListRef.current) &&
      !oldChatHistoryPage &&
      messageListNextToken === null &&
      !isOlderMessagesLoading &&
      isLoggedInUserPartOfChannel &&
      !loadOldChatHistoryClickedRef.current &&
      !isOldChatHistoryMessagesLoading,
    [
      oldChatHistoryPage,
      messageListNextToken,
      isOlderMessagesLoading,
      isLoggedInUserPartOfChannel,
      messageListRef.current,
      isOldChatHistoryMessagesLoading
    ]
  );

  const showOldMessagesLoader = useMemo(
    () =>
      isOlderMessagesLoading ||
      isOlderAllMessagesLoading ||
      isOldChatHistoryMessagesLoading,
    [
      isOlderMessagesLoading,
      isOlderAllMessagesLoading,
      isOldChatHistoryMessagesLoading
    ]
  );

  useEffect(() => {
    if (
      (loadOldMessagesClickRef.current ||
        loadOldChatHistoryClickedRef.current) &&
      !showOldMessagesLoader
    ) {
      oldMessagesStartItemRef.current?.scrollIntoView({ block: "center" });
      return;
    }
  }, [messageListRef.current, oldMessagesStartIndex, showOldMessagesLoader]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  return (
    <MessageContainerStyled style={style}>
      {!allMessagesFormat && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "white",
            borderRadius: "8px",
            borderBottom: `1px solid ${Colors.Lavender}`
          }}
        >
          <HeaderStyle>
            <AppAvatar
              imageSrc={picture}
              size="large"
              mainContent={avatarMainContent}
              subText={specialization}
            />
            {showEnablePatientToggle && (
              <Space
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "5px"
                }}
              >
                {!location.pathname.includes("care-team") && (
                  <Space>
                    Enable messages from patient
                    <AppSwitch
                      tooltip="Enable"
                      altTooltip="Disable"
                      onSwitchToggle={onSwitchToggle}
                      checked={isPatientEnabled}
                      identifier="1"
                      color={Colors.DodgerBlue}
                      loading={isPatientParticipationUpdating}
                    />
                  </Space>
                )}
              </Space>
            )}
          </HeaderStyle>
          <>
            {onCloseMessageContainer && (
              <div
                style={{
                  marginTop: "10px",
                  marginRight: "10px",
                  cursor: "pointer"
                }}
                onClick={onCloseMessageContainer}
              >
                <CloseIcon />
              </div>
            )}
          </>
        </div>
      )}
      <BodyStyle
        style={
          allMessagesFormat
            ? {
                height: `${noFooter ? "100%" : "410px"}`,
                borderRadius: "8px 8px 0px 0px"
              }
            : noFooter
            ? { height: "calc(100% - 60px)", borderRadius: "8px" }
            : {}
        }
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {showOldMessagesLoader && <Spin />}
          {showLoadOlderMessagesButton && (
            <AppButton
              type={ButtonType.Secondary}
              onClick={onGetOlderMessagesClick}
              buttonContent={"Load Older Messages"}
            />
          )}
          {showLoadOldChatHistoryLink && (
            <AppButton
              buttonContent="Load Old Chat History"
              type={ButtonType.Link}
              onClick={onLoadOldChatHistoryClick}
            />
          )}
          {!showLoadOldChatHistoryLink &&
            !showOldMessagesLoader &&
            !showLoadOlderMessagesButton && (
              <NoFurterMessagesTextContainer>
                No further history to display
              </NoFurterMessagesTextContainer>
            )}
        </div>
        {isEmpty(messageList) && (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              height: oldChatHistoryPage ? "100%" : "calc(100% - 30px)"
            }}
          >
            No Messages Found
          </div>
        )}
        {messageList.map((messageItemData, index) => {
          if (index === oldMessagesStartIndex) {
            return (
              <React.Fragment key={index}>
                <MessageItem
                  width={messageItemWidth}
                  data={messageItemData}
                  allMessagesFormat={allMessagesFormat}
                  key={index}
                />
                <div ref={oldMessagesStartItemRef} />
              </React.Fragment>
            );
          }
          return (
            <MessageItem
              width={messageItemWidth}
              data={messageItemData}
              allMessagesFormat={allMessagesFormat}
              key={index}
            />
          );
        })}
        <div ref={messagesEndRef} />
      </BodyStyle>
      {noFooter ? null : (
        <FooterStyle>
          <TextAreaWrapper>
            <Col
              span={
                allMessagesFormat
                  ? allMessagesTextAreaSpan.textAreaSpan
                  : regularMessagesTextAreaSpan.textAreaSpan
              }
            >
              <TextAreaStyled
                placeholder={
                  customPlaceholderText
                    ? customPlaceholderText
                    : "Enter your message"
                }
                bordered={false}
                rows={4}
                value={textMessage}
                onPressEnter={onKeyboardEnter}
                onChange={onTextAreaChange}
              />
            </Col>
            <Col
              span={
                allMessagesFormat
                  ? allMessagesTextAreaSpan.buttonSpan
                  : regularMessagesTextAreaSpan.buttonSpan
              }
            >
              <AppButton
                type={ButtonType.Primary}
                buttonContent={
                  <ButtonContentStyle>
                    <SendIcon />
                    {allMessagesFormat && "Send"}
                  </ButtonContentStyle>
                }
                loading={isSendingMessage}
                onClick={onSendMessage}
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5
                }}
              />
            </Col>
          </TextAreaWrapper>
        </FooterStyle>
      )}
    </MessageContainerStyled>
  );
};

const ButtonContentStyle = styled(Space)`
  svg {
    margin-top: 6px;
  }
`;

const TextAreaStyled = styled(TextArea)`
  resize: none;
  padding: 4px 10px 4px 10px;
  width: 95%;
`;

const TextAreaWrapper = styled(Row)`
  border: 1px solid ${Colors.Lavender};
  border-radius: 8px;
  padding: 4px;
  height: 99%;
`;

const FooterStyle = styled.div`
  padding: 10px;
  border-radius: 0px 0px 8px 8px;
  background: ${Colors.White};
  height: 125px;
`;

const BodyStyle = styled.div`
  padding: 12px;
  background: ${Colors.White};
  border-radius: 0px 0px 0px 0px;
  border-bottom: 1px solid ${Colors.Lavender};
  overflow-y: scroll;
  height: calc(100% - 185px);
`;

const HeaderStyle = styled.div`
  padding: 9px 10px 10px 20px;
  background: ${Colors.White};
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid ${Colors.Lavender};
`;

const MessageContainerStyled = styled.div`
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  height: calc(62vh + 126px);
  .ant-avatar-lg.ant-avatar-icon {
    @media (max-width: 1250px) {
      display: none;
    }
  }
`;

const HeaderSubjectStyle = styled.span`
  font-size: 12px;
  color: ${Colors.gothicRegular};
  margin-top: 2px;
  font-family: Century Gothic;
`;

const NoFurterMessagesTextContainer = styled.div`
  border: 1px solid ${Colors.AliceBlue};
  padding: 10px;
  color: ${Colors.DodgerBlue};
  border-radius: 10px;
`;
