import React, { useContext, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Form from "antd/lib/form";
import styled from "styled-components";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import { CrampingSection } from "./crampingSection";
import { HeadacheSection } from "./headacheSection";
import { DizzynessSection } from "./dizzynessSection";
import { PassingOutSection } from "./passingOutSection";
import { NauseaVomitingSection } from "./nauseaVomitingSection";
import { ChestPainSection } from "./chestPainSection";
import { ShortnessOfBreathSection } from "./shortnessOfBreathSection";
import { LegSwellingSectionSection } from "./legSwellingSection";
import { WeightGainSection } from "./weightGainSection";
import { OtherSection } from "./otherSection";
import { UserContext, UserContextState } from "contextApis/userContext";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { useMutation } from "react-query";
import { createApiClient } from "apiClient";
import { Platform, UserRoles } from "constants/roles";
import { FormSubmitButton } from "pages/symptoms/surveyForms/FormSubmitButton";
import { FormClearButton } from "pages/symptoms/surveyForms/FormClearButton";

interface DialysisSymptomsFormProps {
  setShowSurveyForm(show: boolean): void;
  selectedItems: string[];
  goToNextFormOrReturn(): void;
}

export const DialysisSymptomsForm: React.FC<DialysisSymptomsFormProps> = ({
  setShowSurveyForm,
  selectedItems,
  goToNextFormOrReturn
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [currentPage, setCurrentPage] = useState(1);
  const [otherInput, setOtherInput] = useState("");

  const hideClearButtonPages = [2, 4, 9, 11, 13, 15];

  const [form] = Form.useForm();

  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const userRole = sessionStorage.getItem("userRole");

  const {
    isLoading: isLoadingDialysisSymptomMutation,
    mutate: submitDialysisSymptomForm
  } = useMutation<string>(
    "add-dialysis-form",
    async () => {
      return await createApiClient().addDialysisFormData({
        patient_internal_id:
          userRole === UserRoles.PATIENT && userContext.userData
            ? userContext.userData?.internal_id
            : patientContext?.patientData?.id || "",
        dizzy:
          localStorage.getItem("feelingDizzyRecently") === ""
            ? null
            : localStorage.getItem("feelingDizzyRecently"),
        passedout:
          localStorage.getItem("haveYouPassedOut") === ""
            ? null
            : localStorage.getItem("haveYouPassedOut"),
        cramping:
          localStorage.getItem("haveYouHadCramping") === ""
            ? null
            : localStorage.getItem("haveYouHadCramping"),
        headaches:
          localStorage.getItem("haveYouHadHeadAches") === ""
            ? null
            : localStorage.getItem("haveYouHadHeadAches"),
        nausea:
          localStorage.getItem("haveYouHadNauseaOrVomiting") === ""
            ? null
            : localStorage.getItem("haveYouHadNauseaOrVomiting"),
        chestpain:
          localStorage.getItem("haveYouHadAnyChestPain") === ""
            ? null
            : localStorage.getItem("haveYouHadAnyChestPain"),
        swelling:
          localStorage.getItem("haveYouHadAnyLegSwelling") === ""
            ? null
            : localStorage.getItem("haveYouHadAnyLegSwelling"),
        breath:
          localStorage.getItem("haveYouHadShortnessOfBreath") === ""
            ? null
            : localStorage.getItem("haveYouHadShortnessOfBreath"),
        weight:
          localStorage.getItem("howMuchWeightDoYouGain") === ""
            ? null
            : localStorage.getItem("howMuchWeightDoYouGain"),
        other: otherInput === "" ? null : otherInput,
        submitted_by: `${userContext.userData?.firstName} ${userContext.userData?.lastName}`
      });
    },
    {
      onSuccess: () => {
        message.success("Form Submitted Successfully");
        goToNextFormOrReturn();
      },
      onError: () => {
        message.error({
          content: (
            <span>
              There was a problem submitting this form, resubmit or{" "}
              <a onClick={() => goToNextFormOrReturn()}>
                click here to continue to the next survey
              </a>
            </span>
          ),
          style: {
            bottom: "10px",
            position: "fixed",
            left: "30%"
          },
          duration: 5
        });
      }
    }
  );

  //Alert variables moved into onFinish and handleGoBack due to values not being updated between re-renders
  const onFinish = () => {
    //Values to be passed on to API from session storage
    const dizzynessSymptomAlert =
      localStorage.getItem("feelingDizzyRecently")?.includes("Frequent") ||
      sessionStorage
        .getItem("feelingDizzyRecently")
        ?.includes("Every dialysis");

    const passedOutAlert = localStorage.getItem("haveYouPassedOut") === "Yes";

    const chestPainAlert =
      sessionStorage
        .getItem("haveYouHadAnyChestPain")
        ?.includes("Occasional") ||
      localStorage.getItem("haveYouHadAnyChestPain")?.includes("Frequent") ||
      sessionStorage
        .getItem("haveYouHadAnyChestPain")
        ?.includes("Every dialysis");

    const shortnessOfBreathAlert =
      sessionStorage
        .getItem("haveYouHadShortnessOfBreath")
        ?.includes("Occasional") ||
      sessionStorage
        .getItem("haveYouHadShortnessOfBreath")
        ?.includes("Frequent") ||
      sessionStorage
        .getItem("haveYouHadShortnessOfBreath")
        ?.includes("Every dialysis");

    const legSwellingAlert =
      sessionStorage
        .getItem("haveYouHadAnyLegSwelling")
        ?.includes("Moderate") ||
      localStorage.getItem("haveYouHadAnyLegSwelling")?.includes("Severe");

    const weightSwellingAlert =
      localStorage.getItem("howMuchWeightDoYouGain") ===
      "More than 5 kilograms";

    if (currentPage === 1) {
      if (dizzynessSymptomAlert) {
        setCurrentPage(2);
      } else {
        setCurrentPage(3);
      }
    } else if (currentPage === 2) {
      setCurrentPage(3);
    } else if (currentPage === 3) {
      if (passedOutAlert) {
        setCurrentPage(4);
      } else {
        setCurrentPage(5);
      }
    } else if (currentPage === 4) {
      setCurrentPage(5);
    } else if (currentPage === 5) {
      setCurrentPage(6);
    } else if (currentPage === 6) {
      setCurrentPage(7);
    } else if (currentPage === 7) {
      setCurrentPage(8);
    } else if (currentPage === 8) {
      if (chestPainAlert) {
        setCurrentPage(9);
      } else {
        setCurrentPage(10);
      }
    } else if (currentPage === 9) {
      setCurrentPage(10);
    } else if (currentPage === 10) {
      if (shortnessOfBreathAlert) {
        setCurrentPage(11);
      } else {
        setCurrentPage(12);
      }
    } else if (currentPage === 11) {
      setCurrentPage(12);
    } else if (currentPage === 12) {
      if (legSwellingAlert) {
        setCurrentPage(13);
      } else {
        setCurrentPage(14);
      }
    } else if (currentPage === 13) {
      setCurrentPage(14);
    } else if (currentPage === 14) {
      if (weightSwellingAlert) {
        setCurrentPage(15);
      } else {
        setCurrentPage(16);
      }
    } else if (currentPage === 15) {
      setCurrentPage(16);
    } else {
      submitDialysisSymptomForm();
    }
  };

  const handleGoBack = () => {
    const dizzynessSymptomAlert =
      localStorage.getItem("feelingDizzyRecently")?.includes("Frequent") ||
      sessionStorage
        .getItem("feelingDizzyRecently")
        ?.includes("Every dialysis");

    const passedOutAlert = localStorage.getItem("haveYouPassedOut") === "Yes";

    const chestPainAlert =
      sessionStorage
        .getItem("haveYouHadAnyChestPain")
        ?.includes("Occasional") ||
      localStorage.getItem("haveYouHadAnyChestPain")?.includes("Frequent") ||
      sessionStorage
        .getItem("haveYouHadAnyChestPain")
        ?.includes("Every dialysis");

    const shortnessOfBreathAlert =
      sessionStorage
        .getItem("haveYouHadShortnessOfBreath")
        ?.includes("Occasional") ||
      sessionStorage
        .getItem("haveYouHadShortnessOfBreath")
        ?.includes("Frequent") ||
      sessionStorage
        .getItem("haveYouHadShortnessOfBreath")
        ?.includes("Every dialysis");

    const legSwellingAlert =
      sessionStorage
        .getItem("haveYouHadAnyLegSwelling")
        ?.includes("Moderate") ||
      localStorage.getItem("haveYouHadAnyLegSwelling")?.includes("Severe");

    const weightSwellingAlert =
      localStorage.getItem("howMuchWeightDoYouGain") ===
      "More than 5 kilograms";

    if (currentPage === 3) {
      if (dizzynessSymptomAlert) {
        setCurrentPage(2);
      } else {
        setCurrentPage(1);
      }
    } else if (currentPage === 5) {
      if (passedOutAlert) {
        setCurrentPage(4);
      } else {
        setCurrentPage(3);
      }
    } else if (currentPage === 10) {
      if (chestPainAlert) {
        setCurrentPage(9);
      } else {
        setCurrentPage(8);
      }
    } else if (currentPage === 12) {
      if (shortnessOfBreathAlert) {
        setCurrentPage(11);
      } else {
        setCurrentPage(10);
      }
    } else if (currentPage === 14) {
      if (legSwellingAlert) {
        setCurrentPage(13);
      } else {
        setCurrentPage(12);
      }
    } else if (currentPage === 16) {
      if (weightSwellingAlert) {
        setCurrentPage(15);
      } else {
        setCurrentPage(14);
      }
    } else {
      setCurrentPage(currentPage !== 1 ? currentPage - 1 : 1);
    }
  };

  const onClear = () => {
    if (currentPage === 1) {
      form.resetFields(["Have you been feeling dizzy"]);
      localStorage.setItem("feelingDizzyRecently", "");
    } else if (currentPage === 3) {
      form.resetFields(["Have you passed out"]);
      localStorage.setItem("haveYouPassedOut", "");
    } else if (currentPage === 5) {
      form.resetFields(["Have you had cramping"]);
      localStorage.setItem("haveYouHadCramping", "");
    } else if (currentPage === 6) {
      form.resetFields(["Have you had headaches recently"]);
      localStorage.setItem("haveYouHadHeadAches", "");
    } else if (currentPage === 7) {
      form.resetFields(["Have you had nausea or vomiting"]);
      localStorage.setItem("haveYouHadNauseaOrVomiting", "");
    } else if (currentPage === 8) {
      form.resetFields(["Have you had any chest pain"]);
      localStorage.setItem("haveYouHadAnyChestPain", "");
    } else if (currentPage === 10) {
      form.resetFields(["Have you had any shortness of breath"]);
      localStorage.setItem("haveYouHadShortnessOfBreath", "");
    } else if (currentPage === 12) {
      form.resetFields(["Have you had any leg swelling"]);
      localStorage.setItem("haveYouHadAnyLegSwelling", "");
    } else if (currentPage === 14) {
      form.resetFields(["How much weight do you gain"]);
      localStorage.setItem("howMuchWeightDoYouGain", "");
    } else if (currentPage === 16) {
      form.resetFields(["Any other symptoms"]);
      setOtherInput("");
    }
  };

  return (
    <div>
      <div style={{ display: platform === Platform.MOBILE ? "none" : "block" }}>
        <AppButton
          type={ButtonType.Primary}
          onClick={() => setShowSurveyForm(false)}
          buttonContent={
            <>
              <ArrowLeftOutlined onClick={() => setShowSurveyForm(false)} />{" "}
              Back
            </>
          }
        />
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Form
          name="urinarySymptoms"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          style={{ padding: "0px" }}
        >
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
              style={{ borderTop: "8px solid #4284f3" }}
            >
              <PageTitle>Survey - {selectedItems[0]}</PageTitle>
            </AppCard>
            <DizzynessSection currentPage={currentPage} />
            <PassingOutSection currentPage={currentPage} />
            <CrampingSection currentPage={currentPage} />
            <HeadacheSection currentPage={currentPage} />
            <NauseaVomitingSection currentPage={currentPage} />
            <ChestPainSection currentPage={currentPage} />
            <ShortnessOfBreathSection currentPage={currentPage} />
            <LegSwellingSectionSection currentPage={currentPage} />
            <WeightGainSection currentPage={currentPage} />
            <OtherSection
              currentPage={currentPage}
              setOtherInput={setOtherInput}
              otherInput={otherInput}
            />
            <Row style={{ minHeight: "44px" }}>
              <Col span={5} style={{ minHeight: "44px" }}>
                <Form.Item>
                  {!hideClearButtonPages.includes(currentPage) && (
                    <Space>
                      <FormClearButton onClear={onClear} />
                    </Space>
                  )}
                </Form.Item>
              </Col>
              <Col
                span={19}
                style={{
                  textAlign: "right"
                }}
              >
                <Form.Item>
                  <Space>
                    {currentPage !== 1 && (
                      <AppButton
                        type={ButtonType.Primary}
                        buttonContent={"Back"}
                        onClick={() => handleGoBack()}
                        style={{ width: "90px" }}
                      />
                    )}
                    <FormSubmitButton
                      type={ButtonType.Primary}
                      buttonContent={currentPage === 16 ? "Submit" : "Next"}
                      htmlType="submit"
                      loading={isLoadingDialysisSymptomMutation}
                    />
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Form>
      </div>
    </div>
  );
};

const PageTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
`;
