import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { UserRoles } from "constants/roles";
import { CaregiverRoutesRef } from "constants/routes";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { Colors } from "helpers/colors";
import { ParentTabs } from "components/tabs/parentTabs";

export const TopbarComponent = () => {
  const userId = sessionStorage.getItem("userId");

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const { refetch: getNotificationList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        UserRoles.CAREGIVER
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      },
      refetchInterval: 60000
    }
  );

  useEffect(() => {
    getNotificationList();
  }, []);

  const {
    isLoading: isLoadingPatientSurveyNotification,
    data: patientSurveyNotification,
    error: isPatientSurveyNotificationError,
    isFetching: isFetchingPatientSurveyNotification,
    refetch: refetchPatientSurveyNotification
  } = useQuery(
    "PatientSurveyNotification",
    async () => {
      const data = await createApiClient().getPatientSurveyNotification(
        Number(sessionStorage.getItem("patientId"))
      );
      sessionStorage.setItem("surveyNotification", data);
      notificationsContext.setPatientSurveyNotifications(data ? data : null);
    },
    {
      enabled: true
    }
  );

  const tabsContent = [
    {
      key: CaregiverRoutesRef.MyHealthStatus,
      tabName: "Health Status",
      onClickRedirectPath: CaregiverRoutesRef.MyCurrentStatus
    },
    {
      key: CaregiverRoutesRef.MyMedicalData,
      tabName: "Medical Data",
      onClickRedirectPath: CaregiverRoutesRef.RemoteVitals
    },
    {
      key: CaregiverRoutesRef.MyCareTeam,
      tabName: "Care Team",
      onClickRedirectPath: CaregiverRoutesRef.MyCareTeam
    }
  ];

  const renderTabs = !(
    location.pathname.includes(CaregiverRoutesRef.Patients) ||
    location.pathname.includes(CaregiverRoutesRef.AllMessages) ||
    location.pathname.includes(CaregiverRoutesRef.AllNotifications)
  );

  return (
    <TopbarStyled>
      {renderTabs && (
        <>
          <div
            style={{
              height: "45px",
              width: "470px",
              backgroundColor: Colors.AliceBlue,
              position: "fixed",
              top: "7px",
              borderRadius: "8px",
              left: "50%",
              transform: "translate(-50%, 0)"
            }}
          />
          <ParentTabs
            defaultActiveKey={tabsContent[0].key}
            tabsContent={tabsContent}
          />
        </>
      )}
    </TopbarStyled>
  );
};

const TopbarStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 61px;
  border-bottom: 1px solid ${Colors.Lavender};
  box-shadow: 0 5px 6px -1px rgba(0, 0, 0, 0.05);
  top: 0;
  left: 0;
  background-color: ${Colors.White};
  z-index: 10;
`;
