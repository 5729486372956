import React, { useContext, useEffect, useState } from "react";
import HeartIcon from "../../Images/heartIcon.png";
import styled from "styled-components";
import { useMutation, useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { Skeleton } from "antd";

interface DiseasesCategoryProps {
  question: any;
  setSelectedItemId: (flag: number) => void;
  titleId: any;
}

export const DiseasesCategory: React.FC<DiseasesCategoryProps> = ({
  question,
  setSelectedItemId,
  titleId
}) => {
  const [category, setCategory] = useState<any>();
  const [itemId, setItemId] = useState<any>();
  const { mutate: isPatientAddingData, isLoading: isPatientAddingDataLoading } =
    useMutation(
      "get_single_category",
      async () => {
        return await createApiClient().getSingleCategory(question?.id);
      },
      {
        onSuccess: (e: any) => {
          setCategory(e);
        },
        onError: (err, body) => {
          console.error(err);
        }
      }
    );

  useEffect(() => {
    setItemId(titleId);
    isPatientAddingData();
  }, [titleId]);

  useEffect(() => {
    isPatientAddingData();
    console.log("category", category);
    setItemId(question?.id);
  }, [question?.id]);

  return (
    <DiseasesCategoryContainer>
      {/* Header Icon */}
      <IconContainer>
        <HeaderIcon src={HeartIcon} alt="HeartIcon" />
      </IconContainer>
      <HeaderTitle>{question?.title}</HeaderTitle>
      {isPatientAddingDataLoading ? (
        <Skeleton active paragraph={{ rows: 10, width: "100%" }} />
      ) : (
        <CategoriesList>
          {category?.map((item: any, index: any) => (
            <CategoriesListItem
              onClick={() => setSelectedItemId(item.id)}
              key={index}
            >
              {item?.title}
            </CategoriesListItem>
          ))}
        </CategoriesList>
      )}
    </DiseasesCategoryContainer>
  );
};

const DiseasesCategoryContainer = styled.div`
  width: 16vw;
  background-color: #f4f4f4;
  padding: 12px;
  margin-top: -16px;
`;

const IconContainer = styled.div`
  width: 66px;
  height: 66px;
  border-radius: 50%;
  border: 1px solid #dadada;
  display: grid;
  place-items: center;
`;

const HeaderIcon = styled.img`
  width: 50px;
`;

const HeaderTitle = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1rem;
  line-height: 24px;
  font-family: Century Gothic Bold;
`;

const CategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
`;

const CategoriesListItem = styled.div`
  font-size: 0.7rem;
  padding: 6px 2px;

  &:hover {
    background-color: #eaeaea;
    cursor: pointer;
    border-radius: 4px;
    font-weight: bold;
    border: 1px solid #1f8cff;
  }
`;
