import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Environments } from "constants/environments";
import { UserRoles } from "constants/roles";

import {
  CaregiverRoutesRef,
  CommonRoutesRef,
  PatientsRoutesRef
} from "constants/routes";
import { Colors } from "helpers/colors";
import { MyHealthEarnRewardsIcon } from "./icons/myHealthEarnRewardsIcon";
import { MyHealthImproveMyRiskIcon } from "./icons/myHealthImproveMyRiskIcon";
import { MyHealthLearningCenterIcon } from "./icons/myHealthLearningCenterIcon";
import { MyCurrentStatusIcon } from "./MyCurrentStatusIcon";
import SideBarComponent from "./sideBar";
import { SideBarMenu, SideBarMenuItems } from "./sideBarMenu";
import { PatientSurveyIcon } from "./icons/patientSurveyIcon";
import { UserContext, UserContextState } from "contextApis/userContext";
import {
  NotificationAlertLevels,
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { lowerCaseRemoveSpaceRemoveSpecialCharacters } from "helpers/utils";

export const MyHealthStatusSidebar: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const userContext = useContext<UserContextState>(UserContext);
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const relevantRoutesRef = userIsCaregiver
    ? CaregiverRoutesRef
    : PatientsRoutesRef;

  const patientId = sessionStorage.getItem("patientId");
  const userId = sessionStorage.getItem("userId");

  const [selectedItem, setSelectedItem] = useState<string>(
    relevantRoutesRef.MyCurrentStatus
  );

  const myCurrentStatusItem: SideBarMenuItems[] = [
    {
      name: "My Current Status",
      icon: (
        <IconPositioning>
          <MyCurrentStatusIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.MyCurrentStatus
    },
    {
      name: "Surveys",
      icon: (
        <IconPositioning>
          <PatientSurveyIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.Survey
    }
  ];

  const learningCenterItem: SideBarMenuItems[] = [
    {
      name: "Learning Center",
      icon: (
        <IconPositioning>
          <MyHealthLearningCenterIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.LearningCenter
    }
  ];

  const improveMyRiskItem: SideBarMenuItems[] = [
    {
      name: "Improve My Risk",
      icon: (
        <IconPositioning>
          <MyHealthImproveMyRiskIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.ImproveMyRisk
    }
  ];

  const earnRewardsItem: SideBarMenuItems[] = [
    {
      name: "Earn Rewards",
      icon: (
        <IconPositioning>
          <MyHealthEarnRewardsIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.EarnRewards
    }
  ];

  const [renderSidebarMenu, setRenderSidebarMenu] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState(myCurrentStatusItem);

  const onSelect = (tempSelectedItem: string) => {
    const tempItem = userIsCaregiver
      ? tempSelectedItem + "/" + patientId
      : tempSelectedItem.includes("surveys")
      ? tempSelectedItem + "/" + userId
      : tempSelectedItem;

    setSelectedItem(tempSelectedItem);
    navigate(tempItem);
  };

  useEffect(() => {
    if (
      userContext.userData &&
      notificationsContext.notifications &&
      notificationsContext.notifications.survey
    ) {
      const alertItems = notificationsContext.getAlertItems(
        notificationsContext.notifications?.survey || [],
        userContext.userData?.internal_id
          ? String(userContext.userData?.internal_id)
          : ""
      );
      myCurrentStatusItem.forEach(item => {
        if (
          alertItems?.high.includes(
            lowerCaseRemoveSpaceRemoveSpecialCharacters(item.name)
          )
        ) {
          item.alertLevel = NotificationAlertLevels.HIGH;
        } else if (
          alertItems?.moderate.includes(
            lowerCaseRemoveSpaceRemoveSpecialCharacters(item.name)
          )
        ) {
          item.alertLevel = NotificationAlertLevels.MODERATE;
        } else {
          item.alertLevel = "";
        }
      });
      setRenderSidebarMenu(false);
      setTimeout(() => {
        setRenderSidebarMenu(true);
      }, 0.1);
      setMenuItems(myCurrentStatusItem);
    } else {
      setRenderSidebarMenu(true);
    }
  }, [
    notificationsContext.notifications,
    userContext.userData?.internal_id,
    notificationsContext.patientSurveyNotifications
  ]);

  const isProductionOrDevelopment =
    process.env.REACT_APP_ENV === Environments.PRODUCTION ||
    process.env.REACT_APP_ENV === Environments.DEVELOPMENT;

  return (
    <SideBarComponent
      customContent={
        <div style={{ display: "flex", height: "100vh" }}>
          <SideBarContainer>
            {renderSidebarMenu && (
              <SideBarMenu
                menuItemBackgroundColor={Colors.DodgerBlue}
                menuItemColor={Colors.White}
                menuWidth="209px"
                menuBorderRadius="10px"
                menuMargin="10px 0 10px 8px"
                menuItems={menuItems}
                menuBackgroundColor={Colors.Polar}
                selectedKey={selectedItem}
                onSelect={onSelect}
              />
            )}

            {!isProductionOrDevelopment && (
              <SideBarMenu
                menuItemBackgroundColor={Colors.Mantis}
                menuItemColor={Colors.White}
                menuWidth="209px"
                menuBorderRadius="10px"
                menuMargin="0 0 10px 8px"
                menuItems={learningCenterItem}
                menuBackgroundColor={Colors.WillowBrook}
                selectedKey={selectedItem}
                onSelect={onSelect}
              />
            )}
            {!isProductionOrDevelopment && (
              <SideBarMenu
                menuItemBackgroundColor={Colors.Froly}
                menuItemColor={Colors.White}
                menuWidth="209px"
                menuBorderRadius="10px"
                menuMargin="0 0 10px 8px"
                menuItems={improveMyRiskItem}
                menuBackgroundColor={Colors.Chablis}
                selectedKey={selectedItem}
                onSelect={onSelect}
              />
            )}
            {!isProductionOrDevelopment && (
              <SideBarMenu
                menuItemBackgroundColor={Colors.MediumPurple}
                menuItemColor={Colors.White}
                menuWidth="209px"
                menuBorderRadius="10px"
                menuMargin="0 0 10px 8px"
                menuItems={earnRewardsItem}
                menuBackgroundColor={Colors.Mangolia}
                selectedKey={selectedItem}
                onSelect={onSelect}
              />
            )}
          </SideBarContainer>

          <ContentContainer>{children}</ContentContainer>
        </div>
      }
    />
  );
};

const ContentContainer = styled.div`
  width: calc(100vw - 237px);
  padding: 15px 8px 18px 18px;
`;

const SideBarContainer = styled.div`
  width: 227px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
`;

const IconPositioning = styled.span`
  margin-top: 17px;
  margin-right: -10px;
`;
