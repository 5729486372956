import React from "react";
import { Colors } from "helpers/colors";

interface CallButtonProps {
  readonly isActive: boolean;
}

export const CallButton: React.FC<CallButtonProps> = ({ isActive }) => {
  return (
    <svg
      width="44px"
      height="44px"
      viewBox="0 0 44 44"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <defs>
        <filter
          x="-28.4%"
          y="-28.4%"
          width="156.8%"
          height="156.8%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feMorphology
            radius="0.5"
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          ></feMorphology>
          <feOffset
            dx="0"
            dy="2"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="shadowInner"
          ></feMorphology>
          <feOffset
            dx="0"
            dy="2"
            in="shadowInner"
            result="shadowInner"
          ></feOffset>
          <feComposite
            in="shadowOffsetOuter1"
            in2="shadowInner"
            operator="out"
            result="shadowOffsetOuter1"
          ></feComposite>
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g
        id="Asset-Artboard-Page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="call-icon-button">
          <g id="Rectangle_203-6-Copy-2" filter="url(#filter-1)">
            <rect
              id="Rectangle"
              stroke={isActive ? Colors.DodgerBlue : "#B5D264"}
              fill={isActive ? Colors.AliceBlue : "#EFF4DF"}
              x="0.5"
              y="0.5"
              width="43"
              height="43"
              rx="9.5"
            ></rect>
            <path
              d="M17.05,21.178 C17.72467,22.4686731 18.5826615,23.6346615 19.624,24.676 C20.6653385,25.7173385 21.8313269,26.57533 23.122,27.25 L25.146,25.226 C25.2633339,25.0939993 25.4099991,25.0060002 25.586,24.962 C25.7620009,24.9179998 25.9306659,24.9326663 26.092,25.006 C27.0600048,25.343335 28.1453273,25.512 29.348,25.512 C29.5973346,25.512 29.8099991,25.5999991 29.986,25.776 C30.1620009,25.9520009 30.25,26.1646654 30.25,26.414 L30.25,29.648 C30.25,29.8973346 30.1620009,30.1099991 29.986,30.286 C29.8099991,30.4620009 29.5973346,30.55 29.348,30.55 C27.2359894,30.55 25.2120097,30.1393374 23.276,29.318 C21.413324,28.525996 19.7633405,27.4113405 18.326,25.974 C16.8886595,24.5366595 15.774004,22.886676 14.982,21.024 C14.1606626,19.0879903 13.75,17.0640106 13.75,14.952 C13.75,14.7026654 13.8379991,14.4900009 14.014,14.314 C14.1900009,14.1379991 14.4026654,14.05 14.652,14.05 L17.886,14.05 C18.1353346,14.05 18.3479991,14.1379991 18.524,14.314 C18.7000009,14.4900009 18.788,14.7026654 18.788,14.952 C18.788,16.1546727 18.956665,17.2399952 19.294,18.208 C19.3380002,18.3693341 19.3453335,18.5379991 19.316,18.714 C19.2866665,18.8900009 19.2060007,19.0366661 19.074,19.154 L17.05,21.178 Z"
              id="call---material"
              fill="#212B40"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
