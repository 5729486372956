import { CloseOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";

import { Dropdown } from "components/dropDownBox";
import { Colors } from "helpers/colors";
import { AddIcon } from "./AddIcon";
import { BillCheckboxMenu } from "./billCheckboxMenu";
import { BillingContext, BillingContextState } from "./billingContext";
import { CheckboxMenuCommonInterface } from "sharedTypes";

interface BillDiagnosisCheckboxDropdownProps {
  readonly setShowDropdown: (show: boolean) => void;
  readonly showDropdown: boolean;
  readonly diagnosesApplied: boolean;
  readonly setDiagnosesApplied: (diagnosesApplied: boolean) => void;
}

export const BillDiagnosisCheckboxDropdown: React.FC<
  BillDiagnosisCheckboxDropdownProps
> = ({
  setShowDropdown,
  showDropdown,
  diagnosesApplied,
  setDiagnosesApplied
}) => {
  const billingContext = useContext<BillingContextState>(BillingContext);

  const setFinalDiagnosesItems = (
    checkedItems: CheckboxMenuCommonInterface[]
  ) => {
    billingContext.setDiagnosesMenuItems(checkedItems);
  };

  const addItems = () => {
    setDiagnosesApplied(true);
    setShowDropdown(false);
  };

  const onDropdownCancel = () => {
    setShowDropdown(false);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [diagnoseItem, setDiagnoseItem] = useState<any>(
    billingContext?.diagnosesMenuItems || []
  );

  useEffect(() => {
    setDiagnoseItem(billingContext?.diagnosesMenuItems);
  }, [billingContext?.diagnosesMenuItems]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    const filteredDiagnoseItem = billingContext?.diagnosesMenuItems?.filter(
      item =>
        item.desc.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.detail_desc.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setDiagnoseItem(filteredDiagnoseItem);
  };

  return (
    <Dropdown
      borderradius="8px"
      trigger={["click"]}
      placement="bottomRight"
      destroyPopupOnHide
      dropDownMenuHeader={
        <DropdownHeader>
          <HeaderTitleCol span={12} style={{ display: "flex", gap: 10 }}>
            Diagnoses{" "}
            <input
              placeholder="Search Diagnoses"
              style={{
                marginTop: -4,
                outline: "none",
                borderRadius: "10px",
                border: "1px solid rgb(226, 237, 243)",
                padding: "4px 11px"
              }}
              value={searchQuery}
              onChange={handleSearch}
            />
          </HeaderTitleCol>
          <HeaderButtonCol span={12}>
            <CloseOutlined onClick={onDropdownCancel} />
          </HeaderButtonCol>
        </DropdownHeader>
      }
      dropDownMenuBody={
        <Row>
          <MenuCol>
            <BillCheckboxMenu
              currentItems={diagnoseItem}
              setFinalItems={setFinalDiagnosesItems}
              applied={diagnosesApplied}
              setApplied={setDiagnosesApplied}
              // searchQuery={searchQuery}
              isDiagnoses
            />
          </MenuCol>
        </Row>
      }
      dropDownMenuFooter={
        <DiagnosisDropdownFooter>
          <Col
            style={{ textAlign: "right", width: "100%", paddingRight: "10px" }}
          >
            <Space>
              <AppButton
                type={ButtonType.Secondary}
                buttonContent="Cancel"
                onClick={onDropdownCancel}
                style={{ width: "120px" }}
              />
              <AppButton
                type={ButtonType.Primary}
                buttonContent="Add"
                onClick={addItems}
                style={{ width: "120px" }}
              />
            </Space>
          </Col>
        </DiagnosisDropdownFooter>
      }
      visible={showDropdown}
      setVisible={setShowDropdown}
    >
      <AddIcon />
      Add
    </Dropdown>
  );
};

const HeaderTitleCol = styled(Col)`
  padding: 19px;
`;

const HeaderButtonCol = styled(Col)`
  text-align: right;
  padding: 19px;
`;

const DropdownHeader = styled(Row)`
  font-size: 14px;
  font-family: Century Gothic Bold;
  height: 60px;
  border-bottom: 1px solid ${Colors.Lavender};
  background: ${Colors.White};
  border-radius: 8px 8px 0px 0px;
`;

const MenuCol = styled(Col)`
  max-height: 312px;
  overflow: auto;
`;

const DiagnosisDropdownFooter = styled(Row)`
  background: ${Colors.White};
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid ${Colors.Lavender};
  height: 68px;
  font-size: 14px;
  margin-bottom: 3px;
  display: grid;
  place-items: center;
`;
