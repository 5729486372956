import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

import { CaregiverRoutesRef, PatientsRoutesRef } from "constants/routes";
import { Colors } from "helpers/colors";
import { MedicalDataLaboratoryIcon } from "./icons/medicalDataLaboratoryIcon";
import { MedicalDataMedicationIcon } from "./icons/medicalDataMedicationsIcon";
import { MedicalDataSymptomsIcon } from "./icons/medicalDataSymptomsIcon";
import { MedicalDataVitalsIcon } from "./icons/medicalDataVitalsIcon";
import SideBarComponent from "./sideBar";
import { SideBarMenu, SideBarMenuItems } from "./sideBarMenu";
import { EducationalTipsIcon } from "./icons/educationalTipsIcon";
import { EarnRewardsIcon } from "./icons/earnRewardsIcon";
import { MedicalDataImagesIcon } from "./icons/medicalDataImagesIcon";
import { MedicalDataCarePlanIcon } from "./icons/medicalDataCarePlanIcon";
import { MedicalDataCareGuidelinesIcon } from "./icons/medicalDataCareGuidelinesIcon";
import { UserRoles } from "constants/roles";
import { Environments } from "constants/environments";
import {
  NotificationAlertLevels,
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { createApiClient } from "apiClient";
import { useQuery } from "react-query";

export const MyMedicalDataSidebar: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const relevantRoutesRef = userIsCaregiver
    ? CaregiverRoutesRef
    : PatientsRoutesRef;

  const patientId = sessionStorage.getItem("patientId");

  const [selectedItem, setSelectedItem] = useState<string>(
    relevantRoutesRef.Symptoms
  );

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const patientContext = useContext<PatientContextState>(PatientContext);

  const patientMedicalDataItems: SideBarMenuItems[] = [
    {
      name: "Symptoms",
      icon: (
        <IconPositioning>
          <MedicalDataSymptomsIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.Symptoms
    },
    {
      name: "Remote Vitals",
      icon: (
        <IconPositioning>
          <MedicalDataVitalsIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.RemoteVitals
    },
    {
      name: "Medications",
      icon: (
        <IconPositioning style={{ marginRight: "-1px", marginLeft: "6px" }}>
          <MedicalDataMedicationIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.Medications
    },
    {
      name: "Laboratory",
      icon: (
        <IconPositioning>
          <MedicalDataLaboratoryIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.Laboratory
    },
    {
      name: "Images",
      icon: <MedicalDataImagesIcon />,
      route: relevantRoutesRef.Images
    }
  ];

  const EducateAndEarnItems: SideBarMenuItems[] = [
    {
      name: "Educational Tips",
      icon: (
        <IconPositioning>
          <EducationalTipsIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.EducationalTips
    },
    {
      name: "Earn Rewards",
      icon: (
        <IconPositioning>
          <EarnRewardsIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.EarnRewardsMyMedicalData
    }
  ];

  const CareItems: SideBarMenuItems[] = [
    {
      name: "Care Plan",
      icon: (
        <IconPositioning>
          <MedicalDataCarePlanIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.CarePlan
    },
    {
      name: "Care Guidelines",
      icon: (
        <IconPositioning>
          <MedicalDataCareGuidelinesIcon />
        </IconPositioning>
      ),
      route: relevantRoutesRef.CareGuidelines
    }
  ];

  const [renderMedicalMenu, setRenderMedicalMenu] = useState<boolean>(false);
  const [medicalItems, setMedicalItems] = useState(patientMedicalDataItems);

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  useEffect(() => {
    if (patientContext.patientData && notificationsContext.notifications) {
      //Static setting for patient login notifications to not be shown until required
      let alertItems: { high: string[]; moderate: string[] } | null = {
        high: [""],
        moderate: [""]
      };

      if (userIsCaregiver) {
        alertItems = notificationsContext.getAlertItems(
          notificationsContext.notifications.symptoms.concat(
            notificationsContext.notifications.remote_vitals,
            notificationsContext.notifications.medications
          ) || [],
          patientContext.patientData?.id
            ? String(patientContext.patientData?.id)
            : ""
        );
      }

      patientMedicalDataItems.forEach(item => {
        if (alertItems?.high.includes(item.name.toLowerCase())) {
          item.alertLevel = NotificationAlertLevels.HIGH;
        } else if (alertItems?.moderate.includes(item.name.toLowerCase())) {
          item.alertLevel = NotificationAlertLevels.MODERATE;
        } else {
          item.alertLevel = "";
        }
      });

      setRenderMedicalMenu(false);
      setTimeout(() => {
        setRenderMedicalMenu(true);
      }, 0.1);
      setMedicalItems(patientMedicalDataItems);
    }
  }, [notificationsContext.notifications, patientContext.patientData?.id]);

  const onSelect = (tempSelectedItem: string) => {
    const tempItem = userIsCaregiver
      ? tempSelectedItem + "/" + patientId
      : tempSelectedItem;

    setSelectedItem(tempSelectedItem);
    navigate(tempItem);
  };

  const isProductionOrDevelopment =
    process.env.REACT_APP_ENV === Environments.PRODUCTION ||
    process.env.REACT_APP_ENV === Environments.DEVELOPMENT;

  const {
    data: dataDisableComponent,
    isLoading: isLoadingDisableComponent,
    refetch: refetchDisableComponent
  } = useQuery(
    "disableComponent",
    async () => {
      if (patientContext.orgId) {
        return createApiClient().getDisableContent(patientContext.orgId);
      } else {
        throw new Error("orgId is missing");
      }
    },
    {
      enabled: !!patientContext.orgId
    }
  );

  useEffect(() => {
    if (patientContext.orgId) {
      refetchDisableComponent();
    }
  }, [patientContext.orgId]);

  const itemsToHideOne: string[] = [
    relevantRoutesRef.CareGuidelines,
    relevantRoutesRef.CarePlan,
    relevantRoutesRef.EducationalTips,
    relevantRoutesRef.EarnRewardsMyMedicalData,
    relevantRoutesRef.Laboratory,
    relevantRoutesRef.Images,
    relevantRoutesRef.LearningCenter,
    relevantRoutesRef.ImproveMyRisk,
    relevantRoutesRef.EarnRewards
  ];

  const itemsToHideTwo: string[] = [
    relevantRoutesRef.CareGuidelines,
    relevantRoutesRef.CarePlan,
    relevantRoutesRef.EducationalTips,
    relevantRoutesRef.EarnRewardsMyMedicalData,
    relevantRoutesRef.Laboratory,
    relevantRoutesRef.Images,
    relevantRoutesRef.LearningCenter,
    relevantRoutesRef.ImproveMyRisk,
    relevantRoutesRef.EarnRewards,
    relevantRoutesRef.Symptoms,
    relevantRoutesRef.ReportSymptoms
  ];

  return (
    <SideBarComponent
      customContent={
        <div style={{ display: "flex", height: "100vh" }}>
          <SideBarContainer>
            {(renderMedicalMenu || userIsPatient) && (
              <SideBarMenu
                menuItemBackgroundColor={Colors.DodgerBlue}
                menuItemColor={Colors.White}
                menuWidth="209px"
                menuBorderRadius="10px"
                menuMargin="10px 0 10px 8px"
                menuItems={
                  isProductionOrDevelopment
                    ? medicalItems.filter(item =>
                        dataDisableComponent?.symptom_reporting !== 1
                          ? !itemsToHideOne.includes(item.route)
                          : !itemsToHideTwo.includes(item.route)
                      )
                    : medicalItems
                }
                menuBackgroundColor={Colors.Polar}
                selectedKey={selectedItem}
                onSelect={onSelect}
              />
            )}
            <SideBarMenu
              menuItemBackgroundColor={Colors.Mantis}
              menuItemColor={Colors.White}
              menuWidth="209px"
              menuBorderRadius="10px"
              menuMargin="0 0 10px 8px"
              menuItems={
                isProductionOrDevelopment
                  ? CareItems.filter(item =>
                      dataDisableComponent?.symptom_reporting !== 1
                        ? !itemsToHideOne.includes(item.route)
                        : !itemsToHideTwo.includes(item.route)
                    )
                  : CareItems
              }
              menuBackgroundColor={Colors.WillowBrook}
              selectedKey={selectedItem}
              onSelect={onSelect}
            />
            <SideBarMenu
              menuItemBackgroundColor={Colors.Froly}
              menuItemColor={Colors.White}
              menuWidth="209px"
              menuBorderRadius="10px"
              menuMargin="0 0 10px 8px"
              menuItems={
                isProductionOrDevelopment
                  ? EducateAndEarnItems.filter(item =>
                      dataDisableComponent?.symptom_reporting !== 1
                        ? !itemsToHideOne.includes(item.route)
                        : !itemsToHideTwo.includes(item.route)
                    )
                  : EducateAndEarnItems
              }
              menuBackgroundColor={Colors.Chablis}
              selectedKey={selectedItem}
              onSelect={onSelect}
            />
          </SideBarContainer>
          <ContentContainer>{children}</ContentContainer>
        </div>
      }
    />
  );
};

const ContentContainer = styled.div`
  width: calc(100vw - 237px);
  padding: 15px 8px 18px 18px;
`;

const SideBarContainer = styled.div`
  width: 227px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
`;

const IconPositioning = styled.span`
  margin-top: 17px;
  margin-right: -10px;
`;
