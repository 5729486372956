import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import hdOAuth from "./oauth";
import { createApiClient } from "../../apiClient";
import { useQuery } from "react-query";
import { Result, Spin } from "antd";
import styled from "styled-components";
import message from "antd/lib/message";
import { CareGemLogo } from "./careGemLogo";
import { UserContext, UserContextState } from "../../contextApis/userContext";

const LaunchEpic: React.FC = () => {
  if (sessionStorage.getItem("epicLogin")) {
    const userContext = useContext<UserContextState>(UserContext);
    userContext.onLogout();
    location.reload();
  }
  const [searchParams] = useSearchParams();
  const urlParams = new URLSearchParams(window.location.search);
  const issQuery = searchParams.get("iss") as string;
  const launchQuery = searchParams.get("launch") as string;
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (!urlParams.has("launch") || !urlParams.has("iss")) {
      navigate("/");
      message.warning("Invalid URL.");
    }
  }, []);

  const {
    isLoading: isLoadingFhirMeta,
    data: fhirMetaData,
    error: isFhirMetaError,
    isFetching: isFhirMetaLoading
  } = useQuery("fetchMetadata", async () => {
    return await createApiClient().getFhirMetaData(issQuery);
  });

  useEffect(() => {
    if (isFhirMetaError) {
      setTitle("Error");
      setSubTitle("Invalid ISS URL.");
      setHasError(true);
      return;
    }
  }, [isFhirMetaError]);

  useEffect(() => {
    if (!isFhirMetaLoading && !isFhirMetaError) {
      if (fhirMetaData?.rest == undefined) {
        setTitle("Error");
        setSubTitle("Invalid ISS URL.");
        setHasError(true);
        return;
      } else {
        const auth =
          fhirMetaData?.rest[0]?.security.extension[0]?.extension[0]?.valueUri;
        const token =
          fhirMetaData?.rest[0]?.security.extension[0]?.extension[1]?.valueUri;
        const oauth = new hdOAuth();
        oauth.setProps(issQuery, launchQuery, auth, token);
        const redirectURI = oauth.getTokenRedirectUri();
        window.location.href = redirectURI;
      }
    }
  }, [fhirMetaData]);

  return (
    <>
      <MainContainer>
        <div>
          <CareGemLogo />
        </div>
        <Divider />
        {hasError ? (
          <>
            <Result status="403" title={title} subTitle={subTitle} />
          </>
        ) : (
          <ContentContainer>
            {isLoadingFhirMeta && (
              <div className="loader-container">
                <Spin tip="Loading" size="large" />
              </div>
            )}
            {isLoadingFhirMeta && (
              <div className="text-container">
                <Loadtext>Fetching Token...</Loadtext>
              </div>
            )}
          </ContentContainer>
        )}
      </MainContainer>
    </>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`;

const Divider = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  margin: 16px 0;
`;

const Loadtext = styled.div`
  position: fixed;
  left: 46%;
  padding-top: 2%;
`;

export default LaunchEpic;
