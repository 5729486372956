import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { AppMultiSelect } from "components/inputs";
import { SelectOption } from "components/inputs/selectInput";
import get from "lodash/get";

export interface MedicineReasonProps {
  readonly reasonPrescribed?: string[];
}

interface MedicineReasonInputProps {
  readonly value?: MedicineReasonProps;
  readonly onChange?: (value: MedicineReasonProps) => void;
}

export const MedicineReasonPrescribedInput: React.FC<
  MedicineReasonInputProps
> = ({ value, onChange }) => {
  const [reasonPrescribed, setReasonPrescribed] = useState<string[]>([]);

  const { data } = useQuery("medication-reasons", async () => {
    return createApiClient().getMedicalReasonsList();
  });

  const reasonOptions: SelectOption<string>[] = useMemo(() => {
    if (!data) return [];
    return data.map(item => ({ label: item, value: item }));
  }, [data]);

  const onReasonPrescribedChange = (value: string[]) => {
    if (reasonPrescribed.length < 3 || value.length < 3) {
      setReasonPrescribed(value);
      triggerChange({ reasonPrescribed: value });
    }
  };

  const triggerChange = (changedValue: MedicineReasonProps) => {
    onChange?.({
      reasonPrescribed,
      ...value,
      ...changedValue
    });
  };

  useEffect(() => {
    triggerChange({ reasonPrescribed: reasonPrescribed });
  }, []);

  useEffect(() => {
    setReasonPrescribed(get(value, "reasonPrescribed", []));
  }, [value]);

  return (
    <AppMultiSelect
      style={{
        width: "490px",
        borderRadius: "8px",
        placeItems: "center"
      }}
      value={reasonPrescribed}
      label="Reason Prescribed"
      placeholder="Enter Reason Prescribed"
      onChange={onReasonPrescribedChange}
      options={reasonOptions}
    />
  );
};
