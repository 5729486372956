import Card from "antd/lib/card";
import React from "react";
import styled, { CSSProperties } from "styled-components";

interface CardComponentProps {
  readonly title?: string | JSX.Element;
  readonly cardHeight: string;
  readonly cardWidth: string;
  readonly showHrbelowTitle?: boolean;
  readonly style?: CSSProperties;
}

interface StyledComponentProps {
  readonly title?: string | JSX.Element;
  readonly cardheight: string;
  readonly cardwidth: string;
  readonly showhrbelowtitle: string;
  readonly style?: CSSProperties;
}

const StyledCard = styled(Card)<StyledComponentProps>`
  width: ${props => props.cardwidth || "200px"};
  height: ${props => props.cardheight || "200px"};
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  .ant-card-head {
    min-height: 40px;
    height: 30px;
    border-bottom: ${props => props.showhrbelowtitle};
    padding: 0px 15px 0px 15px;
  }
  .ant-table-head-wrapper {
    min-height: 40px;
    height: 30px;
    display: flex;
  }
  .ant-card-head-title {
    padding: 15px 0px 10px 0px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }
  .ant-card-body {
    padding: ${props => (props.title ? "10px 15px 15px 15px" : "15px")};
    font-size: 12px;
    height: 100%;
  }
`;

export const CardComponent: React.FC<CardComponentProps> = ({
  cardHeight,
  cardWidth,
  title,
  showHrbelowTitle,
  children,
  style
}) => {
  return (
    <StyledCard
      cardheight={cardHeight}
      cardwidth={cardWidth}
      title={title}
      showhrbelowtitle={showHrbelowTitle ? "1.5px solid #d0d6e3" : "none"}
      style={style}
    >
      {children}
    </StyledCard>
  );
};
