import React, { MutableRefObject } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Form, FormInstance, Space } from "antd";
import styled from "styled-components";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppTextInput } from "components/inputs";
import { CredentialProps } from "sharedTypes";
import { PasswordInput } from "components/inputs/textInput";

interface EmailVerificationFormInterface {
  readonly form: FormInstance;
  readonly onFinish: (params: CredentialProps) => void;
  readonly cognitoUser: CognitoUser | undefined;
  readonly onFinishFailed: (errorInfo: any) => void;
  readonly isLoading: boolean;
  readonly isRefetching: boolean;
  readonly onClickConfirm: () => void;
  readonly urlParamsRef: MutableRefObject<{
    username: string;
    action: string;
    code: string;
  }>;
}

const { Item } = Form;

export const EmailVerificationForm: React.FC<
  EmailVerificationFormInterface
> = ({
  form,
  onFinish,
  cognitoUser,
  onFinishFailed,
  isLoading,
  isRefetching,
  onClickConfirm,
  urlParamsRef
}) => {
  return (
    <FormWrapper style={{ paddingTop: "30px" }}>
      <Form
        name="new password form"
        form={form}
        onFinish={() => {
          if (
            form.getFieldValue("newPassword") ===
            form.getFieldValue("confirmNewPassword")
          ) {
            onFinish({
              username: cognitoUser?.getUsername() || "",
              password: form.getFieldValue("newPassword"),
              remember: false
            });
          }
        }}
        onFinishFailed={onFinishFailed}
        style={{ textAlign: "left" }}
      >
        <Item name="username" initialValue={urlParamsRef.current.username}>
          <AppTextInput
            style={{
              width: "240px",
              borderRadius: "8px",
              placeItems: "center"
            }}
            value={""}
            label="Username"
            placeholder="Username"
            labelStyles={{ zIndex: 1000 }}
            textStyles={{ height: "46px" }}
            disabled
          />
        </Item>
        <Item
          name="verify_email_password"
          rules={[
            {
              required: true,
              message: "Please enter your password"
            }
          ]}
        >
          <PasswordInput
            style={{
              width: "240px",
              borderRadius: "8px",
              placeItems: "center"
            }}
            value={""}
            label="Password"
            placeholder="Password"
            labelStyles={{ zIndex: 1000 }}
            iconRender={visible =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            textStyles={{ height: "46px" }}
          />
        </Item>
        <Item name="code" initialValue={urlParamsRef.current.code}>
          <AppTextInput
            style={{
              width: "240px",
              borderRadius: "8px",
              placeItems: "center"
            }}
            value={""}
            label="Code"
            placeholder="Code"
            labelStyles={{ zIndex: 1000 }}
            textStyles={{ height: "46px" }}
            disabled
          />
        </Item>

        <Form.Item wrapperCol={{ offset: 0, span: 10 }}>
          <AppButton
            type={ButtonType.Primary}
            htmlType="submit"
            loading={isLoading || isRefetching}
            buttonContent={<Space size={4}>Confirm</Space>}
            style={{
              width: "240px",
              marginBottom: "-40%"
            }}
            onClick={onClickConfirm}
          />
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  padding-left: 55px;
  .new-password {
    .ant-form-item-explain-error {
      font-size: 11px;
    }
  }
`;
