import React, { ReactNode } from "react";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import styled from "styled-components";

export interface ModalPromptContentProps {
  readonly icon: SVGElement | AntdIconProps;
  readonly text: ReactNode;
}

export const ModalPromptContent: React.FC<ModalPromptContentProps> = ({
  icon,
  text
}) => {
  return (
    <Wrapper>
      <div>{icon}</div>
      <TextItem>{text}</TextItem>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  place-items: center;
`;

const TextItem = styled.div`
  padding-top: 5px;
  text-align: center;
`;
