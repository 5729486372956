import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import { Colors } from "helpers/colors";
import { BillingIcon } from "./icons/billingIcon";
import { CallLogIcon } from "./icons/callLogIcon";
import { CallRecordsIcon } from "./icons/callRecordsIcon";
import { CareGuidelinesIcon } from "./icons/careGuidelinesIcon";
import { FluidMetricsIcon } from "./icons/fluidMetricsIcon";
import { LaboratoryIcon } from "./icons/laboratoryIcon";
import { MedicationIcon } from "./icons/medicationIcon";
import { PatientProfileIcon } from "./icons/patientProfileIcon";
import { PerformanceIcon } from "./icons/performanceIcon";
import { RiskProfileIcon } from "./icons/riskProfileIcon";
import { SymptomsIcon } from "./icons/symptomsIcon";
import { VitalsIcon } from "./icons/vitalsIcon";
import { SideBarMenu, SideBarMenuItems } from "./sideBarMenu";
import { CareTeamIcon } from "./icons/careTeamIcon";
import { ProvidersRoutesRef } from "constants/routes";
import { CarePlanIcon } from "./icons/carePlanIcon";
import { TasksIcon } from "./icons/tasksIcon";
import { NotificationsIcon } from "./icons/notificationsIcon";
import { ImagesIcon } from "./icons/imagesIcon";
import {
  NotificationAlertLevels,
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { Environments } from "constants/environments";
import { lowerCaseRemoveSpaceRemoveSpecialCharacters } from "helpers/utils";

export const MENU_ITEM_ICON_DISABLED_COLOR = "#c1c1c1";

const careTeamItemsList: SideBarMenuItems[] = [
  {
    name: "Care Team",
    icon: <CareTeamIcon />,
    route: ProvidersRoutesRef.CareTeam
  }
];

const sideBarMenuItemsList: SideBarMenuItems[] = [
  {
    name: "Call Records",
    icon: <CallRecordsIcon />,
    route: ProvidersRoutesRef.CallRecords
  },
  {
    name: "Symptoms",
    icon: <SymptomsIcon />,
    route: ProvidersRoutesRef.Symptoms
  },
  {
    name: "Remote Vitals",
    icon: <VitalsIcon />,
    route: ProvidersRoutesRef.RemoteVitals
  },
  {
    name: "Fluid Metrics",
    icon: <FluidMetricsIcon />,
    route: ProvidersRoutesRef.FluidMetrics
  },
  {
    name: "Medications",
    icon: <MedicationIcon />,
    route: ProvidersRoutesRef.Medications
  },
  {
    name: "Laboratory",
    icon: <LaboratoryIcon />,
    route: ProvidersRoutesRef.Laboratory
  },
  {
    name: "Risk Profile",
    icon: <RiskProfileIcon />,
    route: ProvidersRoutesRef.RiskProfile
  }
];

const taskItemsList: SideBarMenuItems[] = [
  {
    name: "Tasks",
    icon: <TasksIcon />,
    route: ProvidersRoutesRef.Tasks
  },
  {
    name: "Surveys",
    icon: <TasksIcon />,
    route: ProvidersRoutesRef.Survey
  },
  {
    name: "Notifications",
    icon: <NotificationsIcon />,
    route: ProvidersRoutesRef.Notifications
  },
  {
    name: "Care Plan",
    icon: <CarePlanIcon />,
    route: ProvidersRoutesRef.CarePlan
  }
];

const CareGuidelines: SideBarMenuItems[] = [
  {
    name: "Care Guidelines",
    icon: <CareGuidelinesIcon />,
    route: ProvidersRoutesRef.Careguidelines
  },
  {
    name: "Performance",
    icon: <PerformanceIcon />,
    route: ProvidersRoutesRef.Performance
  }
];

const PatientProfile: SideBarMenuItems[] = [
  {
    name: "Patient Profile",
    icon: <PatientProfileIcon />,
    route: ProvidersRoutesRef.PatientProfile
  },
  {
    name: "Images",
    icon: <ImagesIcon />,
    route: ProvidersRoutesRef.Images
  }
];

const CallLog: SideBarMenuItems[] = [
  {
    name: "Call Log",
    icon: <CallLogIcon />,
    route: ProvidersRoutesRef.CallLogs
  },
  {
    name: "Billing",
    icon: <BillingIcon />,
    route: ProvidersRoutesRef.Billing
  }
];

interface SideBarProp {
  readonly customContent?: ReactNode;
}

const SideBarComponent: React.FC<SideBarProp> = ({
  customContent,
  children
}) => {
  const currentLocation = window.location.pathname.split(/\//);
  currentLocation.pop();

  const [selectedItem, setSelectedItem] = useState<string>(
    Object.values(ProvidersRoutesRef).find(item =>
      item.includes(currentLocation.join("/"))
    ) || ProvidersRoutesRef.CareTeam
  );

  const patientContext = useContext<PatientContextState>(PatientContext);

  const [renderSidebarMenu, setRenderSidebarMenu] = useState<boolean>(false);

  const [careTeamItems, setCareTeamItems] =
    useState<SideBarMenuItems[]>(careTeamItemsList);

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const [enableSideBarMenu, setEnableSideBarMenu] = useState<any>(() => {
    const storedValue = localStorage?.getItem("disableComponent");
    return storedValue ? JSON.parse(storedValue) : {};
  });

  const filteredSidebarColumns = sideBarMenuItemsList.filter((column: any) => {
    if (
      column.name === "Fluid Metrics" &&
      enableSideBarMenu.Fluid_Metrics === 1
    ) {
      return false;
    }
    if (column.name === "Laboratory" && enableSideBarMenu.Labs === 1) {
      return false;
    }
    if (
      column.name === "Symptoms" &&
      enableSideBarMenu.symptom_reporting === 1
    ) {
      return false;
    }

    if (
      column.name === "Risk Profile" &&
      enableSideBarMenu.risk_profile === 1
    ) {
      return false;
    }

    return true;
  });

  const filteredTaskItemsList = taskItemsList.filter((column: any) => {
    if (column.name === "Tasks" && enableSideBarMenu.tasks === 1) {
      return false;
    }
    return true;
  });

  const [menuItems, setMenuItems] = useState(filteredSidebarColumns);
  const [taskItems, setTaskItems] = useState<SideBarMenuItems[]>(
    filteredTaskItemsList
  );

  useEffect(() => {
    if (
      patientContext.patientData &&
      notificationsContext.notifications &&
      notificationsContext.notifications.symptoms
    ) {
      const alertItems = notificationsContext.getAlertItems(
        notificationsContext.notifications.symptoms.concat(
          notificationsContext.notifications.remote_vitals,
          notificationsContext.notifications.medications,
          notificationsContext.notifications.care_team
        ) || [],
        patientContext.patientData?.id
          ? String(patientContext.patientData?.id)
          : ""
      );

      const messageAlertItems =
        notificationsContext.filterMessageNotificationsByStatusAndID(
          notificationsContext?.notifications?.messages,
          patientContext.patientData?.id
            ? String(patientContext.patientData?.id)
            : "",
          "unread"
        );

      const otherAlertItemsExist =
        alertItems &&
        (alertItems?.high.length > 0 || alertItems?.moderate.length > 0);

      // taskItemsList.forEach(item => {
      //   if (
      //     item.name === "Notifications" &&
      //     (messageAlertItems.length > 0 || otherAlertItemsExist)
      //   ) {
      //     item.alertLevel = NotificationAlertLevels.MODERATE;
      //   } else {
      //     item.alertLevel = "";
      //   }
      // });

      filteredTaskItemsList.forEach(item => {
        if (
          alertItems?.high.includes(
            lowerCaseRemoveSpaceRemoveSpecialCharacters(item.name)
          )
        ) {
          item.alertLevel = NotificationAlertLevels.HIGH;
        } else if (
          alertItems?.moderate.includes(
            lowerCaseRemoveSpaceRemoveSpecialCharacters(item.name)
          )
        ) {
          item.alertLevel = NotificationAlertLevels.MODERATE;
        } else {
          item.alertLevel = "";
        }
      });

      careTeamItemsList.forEach(item => {
        if (alertItems?.moderate.includes("careteam")) {
          item.alertLevel = NotificationAlertLevels.MODERATE;
        } else {
          item.alertLevel = "";
        }
      });

      filteredSidebarColumns.forEach(item => {
        if (
          alertItems?.high.includes(
            lowerCaseRemoveSpaceRemoveSpecialCharacters(item.name)
          )
        ) {
          item.alertLevel = NotificationAlertLevels.HIGH;
        } else if (
          alertItems?.moderate.includes(
            lowerCaseRemoveSpaceRemoveSpecialCharacters(item.name)
          )
        ) {
          item.alertLevel = NotificationAlertLevels.MODERATE;
        } else {
          item.alertLevel = "";
        }
      });

      setRenderSidebarMenu(false);
      setTimeout(() => {
        setRenderSidebarMenu(true);
      }, 0.1);
      setMenuItems(filteredSidebarColumns);
      setTaskItems(filteredTaskItemsList);
      setCareTeamItems(careTeamItemsList);
    } else {
      setRenderSidebarMenu(true);
    }
  }, [
    notificationsContext.notifications,
    patientContext.patientData?.id,
    notificationsContext.patientSurveyNotifications
  ]);

  const sidebarTopRef = useRef<any>();

  const navigate = useNavigate();

  const location = useLocation();

  const currentPatientId = useMemo(
    () => window.location.pathname.split(/\//).slice(-1)[0],
    [window.location.pathname]
  );

  const onSelect = (selectedItem: string) => {
    setSelectedItem(selectedItem);
    navigate(selectedItem + "/" + currentPatientId);
  };

  useEffect(() => {
    if (!location.pathname.includes(ProvidersRoutesRef.Survey)) {
      if (location.pathname.includes(ProvidersRoutesRef.PatientMessages)) {
        setSelectedItem("n/a");
      } else {
        setSelectedItem(
          Object.values(ProvidersRoutesRef).find(item =>
            item.includes(currentLocation.join("/"))
          ) || ProvidersRoutesRef.CareTeam
        );
      }
    }
  }, [location]);

  const isProductionOrDevelopment =
    process.env.REACT_APP_ENV === Environments.PRODUCTION ||
    process.env.REACT_APP_ENV === Environments.DEVELOPMENT;

  const itemsToHide: string[] = [
    ProvidersRoutesRef.Images,
    ProvidersRoutesRef.Performance,
    ProvidersRoutesRef.Careguidelines,
    ProvidersRoutesRef.CarePlan,
    // ProvidersRoutesRef.Tasks,
    ProvidersRoutesRef.RiskProfile,
    ProvidersRoutesRef.Laboratory
  ];

  return (
    <>
      {customContent ? (
        customContent
      ) : (
        <div style={{ display: "flex" }}>
          <div style={{ width: "227px" }} />
          <SideBarContainer>
            <div ref={sidebarTopRef} />
            <SideBarMenu
              menuItemBackgroundColor={Colors.DodgerBlue}
              menuItemColor={Colors.White}
              menuWidth="209px"
              menuBorderRadius="10px"
              menuPadding="6px 0 6px 0"
              menuMargin="10px 0 10px 8px"
              menuItems={
                isProductionOrDevelopment
                  ? careTeamItems.filter(
                      item => !itemsToHide.includes(item.route)
                    )
                  : careTeamItems
              }
              menuBackgroundColor={Colors.Polar}
              selectedKey={selectedItem}
              onSelect={onSelect}
            />
            {renderSidebarMenu && (
              <SideBarMenu
                menuItemBackgroundColor={Colors.Mantis}
                menuItemColor={Colors.White}
                menuWidth="209px"
                menuBorderRadius="10px"
                menuMargin="0 0 10px 8px"
                menuItems={
                  isProductionOrDevelopment
                    ? menuItems.filter(
                        item => !itemsToHide.includes(item.route)
                      )
                    : menuItems
                }
                menuBackgroundColor={Colors.WillowBrook}
                selectedKey={selectedItem}
                onSelect={onSelect}
              />
            )}
            <SideBarMenu
              menuItemBackgroundColor={Colors.Froly}
              menuItemColor={Colors.White}
              menuWidth="209px"
              menuMargin="0 0 10px 8px"
              menuBorderRadius="10px"
              menuItems={
                isProductionOrDevelopment
                  ? taskItems.filter(item => !itemsToHide.includes(item.route))
                  : taskItems
              }
              menuBackgroundColor={Colors.Chablis}
              selectedKey={selectedItem}
              onSelect={onSelect}
            />
            {!isProductionOrDevelopment && (
              <SideBarMenu
                menuItemBackgroundColor={Colors.MediumPurple}
                menuItemColor={Colors.White}
                menuWidth="209px"
                menuMargin="0 0 10px 8px"
                menuBorderRadius="10px"
                menuItems={
                  isProductionOrDevelopment
                    ? CareGuidelines.filter(
                        item => !itemsToHide.includes(item.route)
                      )
                    : CareGuidelines
                }
                menuBackgroundColor={Colors.Mangolia}
                selectedKey={selectedItem}
                onSelect={onSelect}
              />
            )}
            <SideBarMenu
              menuItemBackgroundColor={Colors.GoldenDream}
              menuItemColor={Colors.White}
              menuWidth="209px"
              menuMargin="0 0 10px 8px"
              menuBorderRadius="10px"
              menuItems={
                isProductionOrDevelopment
                  ? PatientProfile.filter(
                      item => !itemsToHide.includes(item.route)
                    )
                  : PatientProfile
              }
              menuBackgroundColor={Colors.CitrineWhite}
              selectedKey={selectedItem}
              onSelect={onSelect}
            />
            <SideBarMenu
              menuItemBackgroundColor={Colors.TurquoiseBlue}
              menuItemColor={Colors.White}
              menuWidth="209px"
              menuMargin="0 0 10px 8px"
              menuBorderRadius="10px"
              menuItems={
                isProductionOrDevelopment
                  ? CallLog.filter(item => !itemsToHide.includes(item.route))
                  : CallLog
              }
              menuBackgroundColor={Colors.Selago}
              selectedKey={selectedItem}
              onSelect={onSelect}
            />
          </SideBarContainer>
          <ContentContainer>{children}</ContentContainer>
        </div>
      )}
    </>
  );
};

const SideBarContainer = styled.div`
  width: 227px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  height: calc(100vh - 120px);

  overflow: scroll;
  position: fixed;
`;

const ContentContainer = styled.div`
  width: calc(100vw - 237px);
  padding: 15px 8px 18px 18px;
`;

export default SideBarComponent;
