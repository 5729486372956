import styled from "styled-components";

export const CallLogsTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 18px;
`;
export const OverflowCallLogsCardContainer = styled.div`
  height: calc(100vh - 250px);
  overflow: auto;
`;
export const EmptyDataText = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;
export const ErrorMessage = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  color: red;
  opacity: 0.3;
  font-weight: bold;
`;
