// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext, useEffect } from "react";
import {
  DeviceLabels,
  Heading,
  MeetingManagerJoinOptions,
  useMeetingManager
} from "amazon-chime-sdk-component-library-react";
import MeetingJoinDetails from "components/meetings/containers/MeetingJoinDetails";
import { StyledLayout } from "./Styled";
import DeviceSelection from "components/meetings/components/DeviceSelection";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import meetingConfig from "components/meetings/meetingConfig";
import { useAWSMeetingState } from "components/meetings/providers/AWSMeetingStateProvider";
import { MeetingMode } from "components/meetings/types";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { message, notification, Spin } from "antd";
import { JoinMeetingInfo } from "sharedTypes";
import { UserRoles } from "constants/roles";

const DeviceSetup: React.FC = () => {
  const {
    meetingMode,
    enableSimulcast,
    priorityBasedPolicy,
    keepLastFrameWhenPaused,
    isWebAudioEnabled,
    setMeetingMode,
    setRegion,
    setMeetingId,
    setLocalUserName,
    setJoinInfo,
    setShowLeaveModal
  } = useAWSMeetingState();
  const meetingManager = useMeetingManager();

  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const userId = sessionStorage.getItem("userId");

  const {
    data: meetingData,
    refetch: createMeeting,
    isRefetching: createMeetingLoading
  } = useQuery(
    "create-meeting-info",
    async () => {
      return await createApiClient().createMeeting(
        userId ? userId : "",
        meetingContext.targetUser.id ? meetingContext.targetUser.id : "",
        meetingContext.pstnPhoneNumber
      );
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      onSuccess: data => {
        const isPSTNCall = meetingContext.pstnPhoneNumber;
        handleJoinMeeting(data);
        if (!isPSTNCall) {
          message.info(
            "SMS has been sent to the user, awaiting user to join",
            5
          );
        }
      },
      onError: (error: Error) => {
        const errorString = error.message || "";
        if (errorString === "Error while pstn calling") {
          message.error("Selected phone number is invalid");
        } else {
          message.error("There was a problem creating this meeting.");
        }
        meetingContext.closeSession();
        setShowLeaveModal(false);
        meetingContext.updateFetchCallRecords(false);
      }
    }
  );

  const {
    data: joinMeetingData,
    refetch: joinMeeting,
    isRefetching: joinMeetingLoading
  } = useQuery(
    "join-meeting-info",
    async () => {
      return await createApiClient().joinMeeting(
        meetingContext.joinMeetingID,
        userContext.userData?.internal_id
          ? String(userContext.userData?.internal_id)
          : ""
      );
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      onSuccess: data => {
        handleJoinMeeting(data);
        notification.destroy();
      },
      onError: () => {
        message.error("There was a problem joining this meeting.");
        meetingContext.closeSession();
      }
    }
  );

  const handleJoinMeeting = async (data: JoinMeetingInfo) => {
    try {
      setJoinInfo(data);

      if (meetingContext.currentPage === 1) {
        setMeetingId(data ? data.Meeting.MeetingId : "");
        setLocalUserName(userContext.userData ? userContext.userData.name : "");
        setRegion(data ? data.Meeting.MediaRegion : "");
        sessionStorage.setItem("meetingID", data.Meeting.MeetingId);
      }

      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        data?.Meeting,
        data?.Attendee
      );
      if (
        meetingConfig.postLogger &&
        meetingSessionConfiguration.meetingId &&
        meetingSessionConfiguration.credentials &&
        meetingSessionConfiguration.credentials.attendeeId
      ) {
        const existingMetadata = meetingConfig.postLogger.metadata;
        meetingConfig.postLogger.metadata = {
          ...existingMetadata,
          meetingId: meetingSessionConfiguration.meetingId,
          attendeeId: meetingSessionConfiguration.credentials.attendeeId
        };
      }

      meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers =
        enableSimulcast;
      if (priorityBasedPolicy) {
        meetingSessionConfiguration.videoDownlinkBandwidthPolicy =
          priorityBasedPolicy;
      }
      meetingSessionConfiguration.keepLastFrameWhenPaused =
        keepLastFrameWhenPaused;
      const options: MeetingManagerJoinOptions = {
        deviceLabels:
          meetingMode === MeetingMode.Spectator
            ? DeviceLabels.None
            : DeviceLabels.AudioAndVideo,
        enableWebAudio: isWebAudioEnabled
      };

      await meetingManager.join(meetingSessionConfiguration, options);

      //TODO - Revise usage of MeetingMode once we have the API in place
      if (meetingMode === MeetingMode.Spectator) {
        await meetingManager.start();
      } else {
        setMeetingMode(MeetingMode.Attendee);
      }
    } catch (error) {
      message.error("There was a problem configuring this meeting");
    }
  };

  const userRole = sessionStorage.getItem("userRole");

  useEffect(() => {
    if (userRole === UserRoles.PATIENT) {
      if (meetingContext.pstnPhoneNumber) {
        createMeeting();
      } else {
        joinMeeting();
      }
    } else {
      if (patientContext.patientData?.id) {
        createMeeting();
      }
    }
  }, [patientContext.patientData]);

  return (
    <StyledLayout>
      {(!createMeetingLoading && meetingData) ||
      (!joinMeetingLoading && joinMeetingData) ? (
        <>
          <Heading tag="h1" level={3} css="margin-bottom: 20px;">
            Device settings
          </Heading>
          <DeviceSelection />
        </>
      ) : (
        <Spin />
      )}
    </StyledLayout>
  );
};

export default DeviceSetup;
