import React from "react";

export const HeartRateIcon: React.FC = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1310 4853 c-217 -23 -469 -122 -663 -260 -107 -77 -267 -237 -344
-346 -335 -475 -394 -1103 -158 -1711 98 -254 275 -555 467 -796 406 -507
1025 -1000 1794 -1428 147 -82 161 -82 308 0 906 503 1581 1079 1986 1694 461
700 545 1436 233 2046 -75 148 -160 264 -278 383 -263 261 -580 406 -930 422
-405 19 -786 -156 -1095 -504 l-72 -81 -48 56 c-129 156 -324 313 -489 397
-205 103 -481 153 -711 128z m375 -323 c192 -51 355 -148 510 -304 55 -56 128
-142 162 -191 113 -164 151 -195 228 -181 47 9 79 38 137 125 200 298 430 475
718 553 118 31 356 32 472 0 335 -91 594 -304 755 -618 105 -204 146 -385 145
-634 -3 -922 -774 -1871 -2159 -2659 l-93 -53 -92 53 c-886 504 -1530 1080
-1872 1672 -31 54 -56 99 -56 102 0 2 102 5 228 7 299 4 269 -15 405 254 l102
204 260 -521 c197 -394 267 -527 290 -544 64 -49 151 -41 202 19 15 18 140
258 278 534 137 276 252 502 255 502 3 0 48 -86 100 -190 52 -105 107 -202
121 -216 14 -13 41 -29 60 -34 20 -6 265 -10 614 -10 l581 0 44 23 c55 28 81
63 87 118 5 51 -18 102 -61 134 -27 19 -44 20 -579 25 l-552 5 -145 287 c-80
158 -156 298 -169 312 -52 55 -151 54 -203 -1 -14 -16 -142 -260 -283 -543
l-257 -515 -267 530 c-293 583 -283 568 -376 568 -88 0 -95 -8 -263 -341
l-152 -302 -225 0 -225 0 -11 28 c-21 54 -59 209 -75 307 -24 146 -22 391 4
521 94 471 411 835 837 963 106 32 178 40 315 36 85 -3 151 -11 205 -25z"
        />
      </g>
    </svg>
  );
};
