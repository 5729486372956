import Divider from "antd/lib/divider";
import Switch from "antd/lib/switch";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppTextInput, AppSelect } from "components/inputs";
import { SelectOption } from "components/inputs/selectInput";
import { MedicationText } from "pages/medications/AddMedicationForm";
import { MedicineAmountProps } from "./medicineAmount";

export interface MedicineDoseDetailProps {
  readonly dose?: string;
  readonly doseUnit?: string;
  readonly sig?: string;
  readonly prn?: boolean;
}

interface MedicineDoseDetailFormProps {
  readonly value?: MedicineDoseDetailProps;
  readonly onChange?: (value: MedicineDoseDetailProps) => void;
  readonly medicineName: string;
  readonly medicineAmount: MedicineAmountProps;
  readonly medicineUnit: string;
  readonly editMedication?: boolean;
}

export const MedicineDoseDetailForm: React.FC<MedicineDoseDetailFormProps> = ({
  value,
  onChange,
  medicineName,
  medicineAmount,
  medicineUnit,
  editMedication
}) => {
  const [dose, setDose] = useState<string>(get(value, "dose", "1"));
  const [doseUnit, setDoseUnit] = useState<string>(get(value, "doseUnit", ""));
  const [sig, setSig] = useState<string>(get(value, "sig", ""));
  const [prn, setPrn] = useState<boolean>(get(value, "prn", false));

  const { data: unitData } = useQuery("medication-units", async () => {
    return createApiClient().getMedicineUnits();
  });
  const { data: sigData } = useQuery("medication-sig", async () => {
    return createApiClient().getMedicineSig();
  });

  const onDoseChange = (value: string) => {
    if (!isNaN(Number(value))) setDose(value);
  };

  const onDoseUnitChange = (value: string) => setDoseUnit(value);

  const onSigChange = (value: string) => setSig(value);

  const triggerChange = (changedValue: MedicineDoseDetailProps) => {
    onChange?.({
      ...value,
      ...changedValue
    });
  };

  const onPrnChange = (checked: boolean) => setPrn(checked);

  const unitOptions: SelectOption<string>[] = useMemo(() => {
    if (!unitData) return [];
    return unitData.map(item => ({ label: item, value: item }));
  }, [unitData]);

  const sigOptions: SelectOption<string>[] = useMemo(() => {
    if (!sigData) return [];
    return sigData.map(item => ({ label: item, value: item }));
  }, [sigData]);

  useEffect(() => {
    triggerChange({
      dose: dose,
      doseUnit: doseUnit,
      sig: sig,
      prn: prn
    });
  }, [dose, doseUnit, sig, prn]);

  useEffect(() => {
    if (isEmpty(unitOptions)) {
      return;
    }
    if (medicineUnit) {
      const option = unitOptions.find(
        option => option.value.toLowerCase() === medicineUnit.toLowerCase()
      );
      setDoseUnit(option?.value || unitOptions[0].value);
      return;
    }
    onDoseUnitChange(unitOptions[0].value);
  }, [unitOptions, medicineUnit]);

  useEffect(() => {
    if (isEmpty(sigOptions)) {
      return;
    }
    if (!sig) {
      onSigChange(sigOptions[0].value);
    }
  }, [sigOptions]);

  return (
    <>
      {editMedication && (
        <>
          <MedicationText>
            <b>{`${medicineName} ${medicineAmount?.medicineAmount}`}</b>
          </MedicationText>
          <Divider style={{ margin: "15px 0" }} />
        </>
      )}

      <MedicationBody>
        <AppTextInput
          style={{
            width: "240px",
            borderRadius: "8px",
            placeItems: "center"
          }}
          value={dose}
          label="Dose"
          placeholder="Dose"
          onChange={onDoseChange}
          labelStyles={{ zIndex: 1000 }}
          textStyles={{ height: "46px" }}
        />
        <AppSelect
          value={doseUnit}
          label="Unit"
          onChange={onDoseUnitChange}
          style={{ marginRight: 5, width: "240px" }}
          options={unitOptions}
          dropdownStyle={{ borderRadius: "8px" }}
        />

        <AppSelect
          value={sig}
          label="Sig"
          onChange={onSigChange}
          style={{ marginRight: 5, width: "240px" }}
          options={sigOptions}
          dropdownStyle={{ borderRadius: "8px" }}
        />
        <PRNContainer>
          <Switch checked={prn} onChange={onPrnChange} />
          <PRNLabel>PRN</PRNLabel>
        </PRNContainer>
      </MedicationBody>
    </>
  );
};

const MedicationBody = styled.div`
  display: flex;
  gap: 20px;
`;

const PRNContainer = styled.div`
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PRNLabel = styled.span`
  margin-left: 10px;
`;
