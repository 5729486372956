import React, { useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import get from "lodash/get";
import moment from "moment";
import { Dropdown } from "components/dropDownBox";
import { Colors } from "helpers/colors";
import { MedicationHistoryResponse } from "sharedTypes";
import {
  DropdownContentContainer,
  DropdownHeaderContainer
} from "pages/providerDetails/style";

interface MedicationHistoryDropdownProps {
  readonly medicineItem: MedicationHistoryResponse;
}

export const MedicationHistoryDropdown: React.FC<
  MedicationHistoryDropdownProps
> = ({ medicineItem }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onDropdownVisibilityChange = (value: boolean) => setIsOpen(value);

  return (
    <Dropdown
      arrow
      visible={isOpen}
      setVisible={onDropdownVisibilityChange}
      borderradius="8px"
      placement="bottomRight"
      dropDownMenuHeader={
        <DropdownHeaderContainer>View Details</DropdownHeaderContainer>
      }
      dropDownMenuBody={
        <DropdownContentContainer borderRadius="8px">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 10,
              marginBottom: 20
            }}
          >
            <div
              style={{
                borderRight: `1px solid ${Colors.Lavender}`
              }}
            >
              <div
                style={{
                  fontSize: 12
                }}
              >
                Ended By
              </div>
              <div>
                <b>{medicineItem.CreatedBy}</b>
              </div>
            </div>
            <div>
              <div style={{ fontSize: 12 }}>Stopped By</div>
              <div>
                <b>{medicineItem.ModifiedBy}</b>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 10
            }}
          >
            <div
              style={{
                borderRight: `1px solid ${Colors.Lavender}`
              }}
            >
              <div
                style={{
                  fontSize: 12
                }}
              >
                Start Date
              </div>
              <div>
                <b>
                  {moment(medicineItem.CreatedDate, "YYYY-MM-DD").format(
                    "DD MMM, YYYY"
                  )}
                </b>
              </div>
            </div>
            <div>
              <div style={{ fontSize: 12 }}>End Date</div>
              <div>
                <b>
                  {moment(medicineItem.ModifiedDate, "YYYY-MM-DD").format(
                    "DD MMM, YYYY"
                  )}
                </b>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 12 }}>Reason Prescribed</div>
            <div>{get(medicineItem, "MedicationReasons", []).join(", ")}</div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 12 }}>Reason Discontinued</div>
            <div>
              {medicineItem.DiscontinueReasons.map((item, index) =>
                index === medicineItem.DiscontinueReasons.length - 1
                  ? item.Reason
                  : item.Reason + ", "
              )}
            </div>
          </div>
          <div>
            <div style={{ fontSize: 12 }}>Information taken from</div>
            <div>{get(medicineItem, "InfoFrom", "")}</div>
          </div>
        </DropdownContentContainer>
      }
    >
      <EyeOutlined style={{ fontSize: "20px", color: Colors.LightGrey }} />
    </Dropdown>
  );
};
