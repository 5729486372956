import styled from "styled-components";
import { Colors } from "helpers/colors";

export const CallRecordsTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 18px;
  padding-top: 18px;
  width: 100%;
  position: relative;
`;
export const CallRecordsFlexContainer = styled.div`
  display: flex;
  gap: 20px;
`;
export const OverflowCallRecordCardContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
  padding-right: 3px;
`;
export const EmptyDataText = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;
export const ErrorMessage = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  color: red;
  opacity: 0.3;
  font-weight: bold;
`;
export const CallRecordItemHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10;
`;
export const CallRecordItemHeaderText = styled.div`
  font-weight: bold;
`;
export const CallRecordItemGridContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr;
`;
export const CallRecordDesctiptionContainer = styled.div`
  font-size: 14px;
  margin-left: 30px;
`;
export const CallRecordProviderNameContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
`;

export const AddCallRecordTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 2;
`;
export const AddCallRecordGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
`;
export const DurationLabelText = styled.div`
  display: flex;
  padding-top: 10px;
  font-size: 14px;
`;
export const DiscussionItemsTitle = styled.div`
  font-weight: bold;
  font-size: 10px;
`;
export const DiscussionItemsSubtitle = styled.div`
  font-size: 10px;
  color: ${Colors.gothicBoldLight};
`;
export const DiscussionItemListContainer = styled.div`
  margin-top: 10px;
`;
interface DiscussionItemListItemProps {
  readonly showHr: boolean;
}
export const DiscussionItemListItem = styled.div<DiscussionItemListItemProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 5px 10px 5px 10px;
  border-bottom: ${props =>
    props.showHr ? `1px solid ${Colors.Lavender}` : "none"};
`;
export const DiscussionListItemText = styled.div`
  font-size: 12px;
  cursor: pointer;
`;
interface SelectedIconContainerProps {
  readonly selected: boolean;
}
export const SelectedIconContainer = styled.div<SelectedIconContainerProps>`
  display: ${props => (props.selected ? "block" : "none")};

  svg {
    margin-top: 12px;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;
