import React, { useContext, useLayoutEffect, useMemo } from "react";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Table, { ColumnsType } from "antd/lib/table";
import { camelCase, get, sortBy } from "lodash";
import moment from "moment";
import styled from "styled-components";

import { MessageRow } from "components/dropDownBox/customStyles";
import { Colors } from "helpers/colors";
import {
  DiscontinuedMedicationListInterface,
  DiscontinuedMedicationListResponse
} from "sharedTypes";
import { DiscontinuedMedicationDropdown } from "./discontinuedMedicationDropdown";
import { CircleIndicator } from "components/circleIndicator";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { getAccessToken } from "helpers/utils";
import { useParams } from "react-router";
import { UserRoles } from "constants/roles";
import {
  MedicationContext,
  MedicationContextState
} from "contextApis/medicationContext";
import { useMutation, useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { message } from "antd";

interface DiscountinuedMedicationTableProps {
  readonly discontinuedData: DiscontinuedMedicationListResponse | undefined;
  readonly discontinuedMedicationsLoading: boolean;
}

export const DiscontinuedMedicationList: React.FC<
  DiscountinuedMedicationTableProps
> = ({ discontinuedData, discontinuedMedicationsLoading }) => {
  const { id: patientId } = useParams();

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const checkboxMenuContext =
    useContext<MedicationContextState>(MedicationContext);

  const userId = sessionStorage.getItem("userId") || "";

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const unreadNotifications = useMemo(() => {
    return notificationsContext.filterNotificationsByStatusAndPatient(
      notificationsContext.notifications?.medications || [],
      userIsPatient ? userId : patientId || "",
      "unread"
    );
  }, [notificationsContext.notifications?.medications]);

  const { refetch: getNotificationsList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        userIsPatient
          ? UserRoles.PATIENT
          : userIsCaregiver
          ? UserRoles.CAREGIVER
          : UserRoles.PROVIDER
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      }
    }
  );

  const { mutate: markNotification } = useMutation(
    "mark-notification",
    createApiClient().putNotifications,
    {
      onSuccess: () => {
        getNotificationsList();
      },
      onError: () => {
        message.error("There was a problem updating notifications.");
      }
    }
  );

  useLayoutEffect(() => {
    return () => {
      if (getAccessToken()) {
        unreadNotifications
          .filter(item => item.desc.includes("discontinued"))
          .forEach(item => {
            markNotification({
              notificationId: item?.notification_id || "",
              body: { status: "read", type: "medications" }
            });
          });
      }
    };
  }, [unreadNotifications]);

  const columns: ColumnsType<DiscontinuedMedicationListInterface> = [
    {
      title: "Name",
      key: "ProductLongName",
      dataIndex: "ProductLongName",
      width: "25%",
      render: (ProductLongName: string, row) => {
        const unreadItem = unreadNotifications.find(
          item => String(item.item_id) === String(row.Id)
        );

        return (
          <MessageRow
            style={{ fontWeight: "bold", marginLeft: "10px" }}
            fontSize={"14px"}
          >
            {unreadItem && (
              <CircleIndicatorWrapper>
                <CircleIndicator
                  outerColor={"#fbddb7"}
                  innerColor={"#f18e13"}
                />
              </CircleIndicatorWrapper>
            )}
            <b>{`${ProductLongName} ${row.UnitStrength}`}</b>
          </MessageRow>
        );
      }
    },
    {
      title: "Start Date",
      key: "CreatedDate",
      dataIndex: "CreatedDate",
      render: (
        CreatedDate: number,
        row: DiscontinuedMedicationListInterface
      ) => (
        <Container>
          <MessageUpperRow>
            <b>{moment(CreatedDate, "YYYY-MM-DD").format("DD MMM, YYYY")}</b>
          </MessageUpperRow>
          <MessageLowerRow>{row.CreatedBy}</MessageLowerRow>
        </Container>
      ),
      width: "20%"
    },
    {
      title: "End Date",
      key: "ModifiedDate",
      dataIndex: "ModifiedDate",
      render: (
        ModifiedDate: number,
        row: DiscontinuedMedicationListInterface
      ) => (
        <Container>
          <MessageUpperRow>
            <b>{moment(ModifiedDate, "YYYY-MM-DD").format("DD MMM, YYYY")}</b>
          </MessageUpperRow>
          <MessageLowerRow>{row.ModifiedBy}</MessageLowerRow>
        </Container>
      ),
      width: "20%"
    },
    {
      title: "Dose",
      key: "Quantity",
      dataIndex: "Quantity",
      width: "10%",
      render: (Quantity: string, row: DiscontinuedMedicationListInterface) => {
        return <span>{`${Quantity} ${camelCase(row.UnitCode)}`}</span>;
      }
    },
    {
      title: "Sig",
      key: "Sig",
      dataIndex: "Sig",
      width: "20%",
      render: (Sig: string, row: DiscontinuedMedicationListInterface) => (
        <Container>
          <MessageUpperRow>
            <b>{`${Sig} ${row.Prn === "Y" ? "| PRN" : ""}`}</b>
          </MessageUpperRow>
          <MessageLowerRow>{row.SigExtraNote}</MessageLowerRow>
        </Container>
      )
    },
    {
      key: "actions",
      dataIndex: "actions",
      render: (value: undefined, row: DiscontinuedMedicationListInterface) => (
        <div
          style={{ display: "grid", placeItems: "right", paddingRight: "10px" }}
        >
          <Space size={20}>
            <ActionSpan>
              <DiscontinuedMedicationDropdown medicineItem={row} />
            </ActionSpan>
          </Space>
        </div>
      )
    }
  ];

  const discontinuedReasonsExist =
    checkboxMenuContext.discontinuedMenuOneReasons.reasons.some(
      item => item.checked
    ) ||
    checkboxMenuContext.discontinuedMenuTwoReasons.reasons.some(
      item => item.checked
    );

  const prescribedReasonExists =
    checkboxMenuContext.prescribedMenuOneReasons.reasons.some(
      item => item.checked
    ) ||
    checkboxMenuContext.prescribedMenuTwoReasons.reasons.some(
      item => item.checked
    );

  return (
    <Table<DiscontinuedMedicationListInterface>
      columns={columns}
      dataSource={sortBy(get(discontinuedData, "Medication", []), [
        item => item.ProductShortName.toLowerCase()
      ])}
      rowKey={record =>
        sortBy(
          get(discontinuedData, "Medication", []),
          "ProductShortName"
        ).indexOf(record)
      }
      loading={discontinuedMedicationsLoading}
      pagination={false}
      scroll={{
        y:
          discontinuedReasonsExist && prescribedReasonExists
            ? "calc(100vh - 430px)"
            : discontinuedReasonsExist || prescribedReasonExists
            ? "calc(100vh - 350px)"
            : "calc(100vh - 300px)"
      }}
      data-testid="discontinued-medications-list"
    />
  );
};

const ActionSpan = styled.span`
  cursor: pointer;
`;

const MessageUpperRow = styled(Row)`
  color: ${Colors.gothicBold};
  font-size: 12px;
`;
const Container = styled.div`
  padding: 10px 0px;
`;

const MessageLowerRow = styled(Row)`
  font-size: 12px;
`;

const CircleIndicatorWrapper = styled.span`
  position: absolute;
  top: 4px;
  left: -5px;
`;
