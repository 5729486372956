import { Col } from "antd";
import styled from "styled-components";

export const SpinWrapper = styled.div`
  width: 100%;
  height: 60vh;
  display: grid;
  place-items: center;
`;

export const TitleCol = styled(Col)`
  padding-left: 20px !important;
  font-family: Century Gothic Bold;
`;

export const FilterCol = styled(Col)`
  right: 0px;
  text-align: right;
`;

export const allNotificationWrapperStyling = {
  padding: "5px 0px 5px 0px",
  boxShadow: "0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  maxHeight: "100vh"
};
