import Space from "antd/lib/space";
import Radio from "antd/lib/radio";
import Form, { FormInstance } from "antd/lib/form";
import React from "react";
import styled from "styled-components";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";

import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { Platform } from "constants/roles";

interface PassedAKidneyStoneSectionProps {
  readonly currentPage: number;
  readonly form: FormInstance;
  setShowSubmit(show: boolean): void;
}

export const PassedAKidneyStoneSection: React.FC<
  PassedAKidneyStoneSectionProps
> = ({ currentPage, form, setShowSubmit }) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  return (
    <>
      {currentPage === 10 && (
        <Space direction="vertical" size={20}>
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{ width: "100%", padding: "10px" }}
              >
                <DisclaimerTitle>KIDNEY STONE</DisclaimerTitle>
                <CardTitle>
                  Have you ever passed a kidney stone?
                  <AsteriskStyle> *</AsteriskStyle>
                </CardTitle>
                <FormItemStyle
                  name="Have you ever passed a kidney stone"
                  label=""
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  initialValue={""}
                >
                  <Radio.Group
                    value={localStorage.getItem("haveYouPassedAKidneyStone")}
                    onChange={e => {
                      if (e.target.value === "No") {
                        setShowSubmit(true);
                      } else {
                        setShowSubmit(false);
                      }
                      localStorage.setItem(
                        "haveYouPassedAKidneyStone",
                        e.target.value
                      );
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={"Yes"}>Yes</Radio>
                      <Radio value={"No"}>No</Radio>
                    </Space>
                  </Radio.Group>
                </FormItemStyle>
              </Space>
            </AppCard>
          </Space>
        </Space>
      )}
      {currentPage === 11 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>PASSAGE OF KIDNEY STONES</DisclaimerTitle>
              <CardTitle>How long ago did you pass your stone?</CardTitle>
              <FormItemStyle
                name="How long ago did you pass your stone"
                label=""
                initialValue={""}
              >
                <Radio.Group
                  value={localStorage.getItem("howLongAgoPassedStone")}
                  onChange={e =>
                    localStorage.setItem(
                      "howLongAgoPassedStone",
                      e.target.value
                    )
                  }
                >
                  <Space direction="vertical">
                    <Radio value={"In the last 2 weeks"}>
                      In the last 2 weeks
                    </Radio>
                    <Radio value={"In the last 3 months"}>
                      In the last 3 months
                    </Radio>
                    <Radio value={"In the last 3 - 6 months"}>
                      In the last 3 - 6 months
                    </Radio>
                    <Radio value={"More than 6 months ago"}>
                      More than 6 months ago
                    </Radio>
                  </Space>
                </Radio.Group>
              </FormItemStyle>
              <ClearSelectionContainer>
                <AppButton
                  buttonContent={"Clear Selection"}
                  type={ButtonType.Link}
                  onClick={() => {
                    form.setFieldsValue({
                      "How long ago did you pass your stone": ""
                    });
                    localStorage.setItem("howLongAgoPassedStone", "");
                  }}
                />
              </ClearSelectionContainer>
            </Space>
          </AppCard>
        </Space>
      )}
    </>
  );
};

const DisclaimerTitle = styled.div`
  width: 100%;
  padding: 8px 8px 8px 20px;
  margin-left: -25px;
  background: ${Colors.DodgerBlue};
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 8px 8px 0px 0px;
  color: white;
  position: absolute;
  top: 0;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;

const ClearSelectionContainer = styled.div`
  float: right;
  margin-bottom: -80px;
  margin-top: -25px;
`;
