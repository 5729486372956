const appConfig = {
  apiGatewayInvokeUrl: "",
  cognitoUserPoolId: process.env["REACT_APP_USER_POOL_ID"],
  cognitoAppClientId: process.env["REACT_APP_CLIENT_ID"],
  cognitoIdentityPoolId: process.env["REACT_APP_IDENTITY_POOL_ID"],
  appInstanceArn: process.env["REACT_APP_AWS_CHIME_APP_INSTANCE_ARN"],
  region: process.env["REACT_APP_AWS_REGION"],
  websocketURL: process.env["REACT_APP_WEBSOCKET_BASE_URL"]
};

export default appConfig;
