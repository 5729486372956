import React, { ReactNode } from "react";
import Modal from "antd/lib/modal";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { Colors } from "helpers/colors";
import styled from "styled-components";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import { ModalPromptContent } from "./ModalPromptContent";

interface AppModalProps {
  readonly visible: boolean;
  readonly title: React.ReactNode;
  readonly width?: string | number;
  readonly style?: any;
  readonly footer?: boolean;
  okText?: string;
  cancelText?: string;
  onCancel?(): void;
  onOk?(): void;
  readonly prompt?: {
    icon: SVGElement | AntdIconProps;
    text: ReactNode;
  };
  className?: string;
  disableSecondaryButton?: boolean;
  disablePrimaryButton?: boolean;
  closable?: boolean;
}

export const AppModalComponent: React.FC<AppModalProps> = ({
  visible,
  title,
  width,
  children,
  okText,
  cancelText,
  onOk,
  onCancel,
  prompt,
  className,
  footer = true,
  disableSecondaryButton,
  disablePrimaryButton,
  closable = true,
  style
}) => {
  return (
    <ModalStyled
      style={style}
      title={title}
      visible={visible}
      width={width}
      onCancel={onCancel}
      centered
      closable={closable}
      className={className}
      footer={
        footer
          ? [
              <AppButton
                key="1"
                type={ButtonType.Secondary}
                buttonContent={cancelText}
                onClick={onCancel}
                style={{ width: "fit-content", minWidth: "120px" }}
                disabled={disableSecondaryButton}
              />,
              <AppButton
                key="2"
                type={ButtonType.Primary}
                buttonContent={okText}
                onClick={onOk}
                style={{ width: "fit-content", minWidth: "120px" }}
                disabled={disablePrimaryButton}
              />
            ]
          : false
      }
    >
      {prompt ? (
        <ModalPromptContent icon={prompt.icon} text={prompt.text} />
      ) : (
        children
      )}
    </ModalStyled>
  );
};

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    overflow: auto;
  }

  .ant-modal-header {
    border-radius: 8px;
    height: 60px;

    .ant-modal-title {
      padding: 5px 5px 5px 5px;
      margin-left: -5px;
      font-size: 14px;
      font-family: Century Gothic Bold;
    }
  }

  .ant-modal-close {
    top: 5px;
  }

  .ant-modal-body {
    box-shadow: inset 0 5px 7px -7px rgba(0, 0, 0, 0.4);
    border-bottom: 1px solid ${Colors.Lavender};
    padding: 10px;
    overflow: auto;
  }
`;
