import React, { useContext, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import { useMutation } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { NephrologyLogo } from "pages/symptoms/surveyForms/nephrologyLogo";
import { Platform, UserRoles } from "constants/roles";
import { FormSubmitButton } from "pages/symptoms/surveyForms/FormSubmitButton";
import { FormClearButton } from "pages/symptoms/surveyForms/FormClearButton";

interface WeightChangeFormProps {
  setShowSurveyForm(show: boolean): void;
  selectedItems: string[];
  goToNextFormOrReturn(): void;
}

export const WeightChangeForm: React.FC<WeightChangeFormProps> = ({
  setShowSurveyForm,
  selectedItems,
  goToNextFormOrReturn
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [currentPage, setCurrentPage] = useState(1);

  const [form] = Form.useForm();

  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const [showSubmit, setShowSubmit] = useState(false);

  const userRole = sessionStorage.getItem("userRole");

  const userId = sessionStorage.getItem("userId") || "";

  const {
    isLoading: isLoadingWeightChangeMutation,
    mutate: submitWeightChangeForm
  } = useMutation<string>(
    "add-bill",
    async () => {
      return await createApiClient().addWeightChangeFormData({
        patient_internal_id:
          userRole === UserRoles.PATIENT && userContext.userData
            ? userContext.userData?.internal_id
            : patientContext?.patientData?.id || "",
        report: localStorage.getItem("reportChangeInWeight") || "",
        period: localStorage.getItem("weightChangeOverPeriod") || "",
        gained_lost: localStorage.getItem("gainedOrLostWeight") || "",
        lb_kg: localStorage.getItem("poundsOrKilograms") || "",
        change_in_lb: localStorage.getItem("weightChangeInPounds") || "",
        change_in_kg: localStorage.getItem("weightChangeInKilograms") || "",
        submitted_by: `${userContext.userData?.firstName} ${userContext.userData?.lastName}`
      });
    },
    {
      onSuccess: () => {
        message.success("Form Submitted Successfully");
        goToNextFormOrReturn();
      },
      onError: () => {
        message.error({
          content: (
            <span>
              There was a problem submitting this form, resubmit or{" "}
              <a onClick={() => goToNextFormOrReturn()}>
                click here to continue to the next survey
              </a>
            </span>
          ),
          style: {
            bottom: "10px",
            position: "fixed",
            left: "30%"
          },
          duration: 5
        });
      }
    }
  );

  const onFinish = () => {
    //Values to be passed on to API from session storage
    if (localStorage.getItem("reportChangeInWeight") === "No") {
      form.resetFields([
        "Change over period",
        "Gained or lost weight",
        "Pounds or kilograms",
        "Weight change in pounds",
        "Weight change in kilograms"
      ]);
      localStorage.setItem("weightChangeOverPeriod", "");
      localStorage.setItem("gainedOrLostWeight", "");
      localStorage.setItem("poundsOrKilograms", "");
      localStorage.setItem("weightChangeInPounds", "");
      localStorage.setItem("weightChangeInKilograms", "");
      submitWeightChangeForm();
    } else if (currentPage === 1) {
      setCurrentPage(2);
    } else if (currentPage === 2) {
      if (localStorage.getItem("poundsOrKilograms") === "Pounds (lb)") {
        localStorage.setItem("weightChangeInKilograms", "");
      } else {
        localStorage.setItem("weightChangeInPounds", "");
      }
      setCurrentPage(3);
    } else {
      submitWeightChangeForm();
    }
  };

  const onClear = () => {
    if (currentPage === 1) {
      form.resetFields(["Do you want to report weight change"]);
    } else if (currentPage === 2) {
      form.resetFields([
        "Change over period",
        "Gained or lost weight",
        "Pounds or kilograms"
      ]);
    } else {
      form.resetFields([
        "Weight change in pounds",
        "Weight change in kilograms"
      ]);
    }
  };

  return (
    <div>
      <div style={{ display: platform === Platform.MOBILE ? "none" : "block" }}>
        <AppButton
          type={ButtonType.Primary}
          onClick={() => setShowSurveyForm(false)}
          buttonContent={
            <>
              <ArrowLeftOutlined onClick={() => setShowSurveyForm(false)} />{" "}
              Back
            </>
          }
        />
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Form
          name="weightChange"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          style={{ padding: "0px" }}
        >
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
              style={{ borderTop: "8px solid #4284f3" }}
            >
              <PageTitle>Survey - {selectedItems[0]}</PageTitle>
            </AppCard>
            {currentPage === 1 && (
              <>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      fontWeight: "600"
                    }}
                  >
                    Please do not rely upon this survey for immediate medical
                    attention. This survey may not be reviewed by your care team
                    immediately. Please call your doctor for any medical
                    problems. IF YOU THINK THIS IS A MEDICAL EMERGENCY, OR YOUR
                    SYMPTOMS ARE WORRISOME PLEASE CALL 911 OR GO TO THE NEAREST
                    EMERGENCY ROOM.
                  </Space>
                </AppCard>

                {/* <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      fontWeight: "600"
                    }}
                  >
                    <NephrologyLogo />
                  </Space>
                </AppCard> */}
                <Space direction="vertical" size={20}>
                  <AppCard
                    cardHeight="fit-content"
                    cardWidth={
                      platform === Platform.MOBILE
                        ? "calc(100vw - 60px)"
                        : "640px"
                    }
                  >
                    <Space
                      direction="vertical"
                      size={20}
                      style={{ width: "100%", padding: "10px" }}
                    >
                      <CardTitle>
                        Do you want to report a change in your weight?
                        <AsteriskStyle> *</AsteriskStyle>
                      </CardTitle>
                      <FormItemStyle
                        name="Do you want to report weight change"
                        label=""
                        rules={[
                          {
                            required: true
                          }
                        ]}
                        initialValue={""}
                      >
                        <Radio.Group
                          value={localStorage.getItem("reportChangeInWeight")}
                          onChange={e => {
                            if (e.target.value === "No") {
                              setShowSubmit(true);
                            } else {
                              setShowSubmit(false);
                            }
                            localStorage.setItem(
                              "reportChangeInWeight",
                              e.target.value
                            );
                          }}
                        >
                          <Space direction="vertical">
                            <Radio value={"Yes"}>Yes</Radio>
                            <Radio value={"No"}>No</Radio>
                          </Space>
                        </Radio.Group>
                      </FormItemStyle>
                    </Space>
                  </AppCard>
                </Space>
              </>
            )}
            {currentPage === 2 && (
              <Space direction="vertical" size={20}>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <CardTitle>
                      Over what period of time has your weight changed?
                      <AsteriskStyle> *</AsteriskStyle>
                    </CardTitle>
                    <FormItemStyle
                      name="Change over period"
                      label=""
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      initialValue={""}
                    >
                      <Radio.Group
                        value={localStorage.getItem("weightChangeOverPeriod")}
                        onChange={e =>
                          localStorage.setItem(
                            "weightChangeOverPeriod",
                            e.target.value
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Radio value={"1 - 2 Weeks"}>1 - 2 Weeks</Radio>
                          <Radio value={"2 - 4 Weeks"}>2 - 4 Weeks</Radio>
                          <Radio value={"1 - 3 Months"}>1 - 3 Months</Radio>
                          <Radio value={"4 - 6 Months"}>4 - 6 Months</Radio>
                        </Space>
                      </Radio.Group>
                    </FormItemStyle>
                  </Space>
                </AppCard>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <CardTitle>
                      Have you gained or lost weight?
                      <AsteriskStyle> *</AsteriskStyle>
                    </CardTitle>
                    <FormItemStyle
                      name="Gained or lost weight"
                      label=""
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      initialValue={""}
                    >
                      <Radio.Group
                        value={localStorage.getItem("gainedOrLostWeight")}
                        onChange={e =>
                          localStorage.setItem(
                            "gainedOrLostWeight",
                            e.target.value
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Radio value={"Gained"}>Gained</Radio>
                          <Radio value={"Lost"}>Lost</Radio>
                        </Space>
                      </Radio.Group>
                    </FormItemStyle>
                  </Space>
                </AppCard>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <CardTitle>
                      Please indicate if you are reporting your weight change in
                      Pounds or Kilograms
                      <AsteriskStyle> *</AsteriskStyle>
                    </CardTitle>
                    <FormItemStyle
                      name="Pounds or kilograms"
                      label=""
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      initialValue={""}
                    >
                      <Radio.Group
                        value={localStorage.getItem("poundsOrKilograms")}
                        onChange={e =>
                          localStorage.setItem(
                            "poundsOrKilograms",
                            e.target.value
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Radio value={"Pounds (lb)"}>Pounds (lb)</Radio>
                          <Radio value={"Kilograms (Kg)"}>Kilograms (Kg)</Radio>
                        </Space>
                      </Radio.Group>
                    </FormItemStyle>
                  </Space>
                </AppCard>
              </Space>
            )}
            {currentPage === 3 && (
              <Space direction="vertical" size={20}>
                {localStorage.getItem("poundsOrKilograms") === "Pounds (lb)" ? (
                  <AppCard
                    cardHeight="fit-content"
                    cardWidth={
                      platform === Platform.MOBILE
                        ? "calc(100vw - 60px)"
                        : "640px"
                    }
                  >
                    <Space
                      direction="vertical"
                      size={20}
                      style={{ width: "100%", padding: "10px" }}
                    >
                      <CardTitle>
                        How much weight (in pounds) have you gained or lost?
                        <AsteriskStyle> *</AsteriskStyle>
                      </CardTitle>
                      <FormItemStyle
                        name="Weight change in pounds"
                        label=""
                        rules={[
                          {
                            required: true
                          }
                        ]}
                        initialValue={""}
                      >
                        <Radio.Group
                          value={localStorage.getItem("weightChangeInPounds")}
                          onChange={e =>
                            localStorage.setItem(
                              "weightChangeInPounds",
                              e.target.value
                            )
                          }
                        >
                          <Space direction="vertical">
                            <Radio value={"1 - 3 pounds"}>1 - 3 pounds</Radio>
                            <Radio value={"3 - 6 pounds"}>3 - 6 pounds</Radio>
                            <Radio value={"7 - 10 pounds"}>7 - 10 pounds</Radio>
                            <Radio value={"11 - 15 pounds"}>
                              11 - 15 pounds
                            </Radio>
                            <Radio value={"16 - 20 pounds"}>
                              16 - 20 pounds
                            </Radio>
                            <Radio value={"20 - 30 pounds"}>
                              20 - 30 pounds
                            </Radio>
                            <Radio value={"More than 30 pounds"}>
                              More than 30 pounds
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </FormItemStyle>
                    </Space>
                  </AppCard>
                ) : (
                  <AppCard
                    cardHeight="fit-content"
                    cardWidth={
                      platform === Platform.MOBILE
                        ? "calc(100vw - 60px)"
                        : "640px"
                    }
                  >
                    <Space
                      direction="vertical"
                      size={20}
                      style={{ width: "100%", padding: "10px" }}
                    >
                      <CardTitle>
                        How much weight (in kg) have you gained or lost?
                        <AsteriskStyle> *</AsteriskStyle>
                      </CardTitle>
                      <FormItemStyle
                        name="Weight change in kilograms"
                        label=""
                        rules={[
                          {
                            required: true
                          }
                        ]}
                        initialValue={""}
                      >
                        <Radio.Group
                          value={localStorage.getItem(
                            "weightChangeInKilograms"
                          )}
                          onChange={e =>
                            localStorage.setItem(
                              "weightChangeInKilograms",
                              e.target.value
                            )
                          }
                        >
                          <Space direction="vertical">
                            <Radio value={"1 kg or less"}>1 kg or less</Radio>
                            <Radio value={"2 - 3 kg"}>2 - 3 kg</Radio>
                            <Radio value={"4 - 5 kg"}>4 - 5 kg</Radio>
                            <Radio value={"6 - 7 kg"}>6 - 7 kg</Radio>
                            <Radio value={"8 - 10 kg"}>8 - 10 kg</Radio>
                            <Radio value={"11 - 15 kg"}>11 - 15 kg</Radio>
                            <Radio value={"More than 15 kg"}>
                              More than 15 kg
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </FormItemStyle>
                    </Space>
                  </AppCard>
                )}
              </Space>
            )}
            <Row>
              <Col span={5}>
                <Form.Item>
                  <FormClearButton onClear={onClear} />
                </Form.Item>
              </Col>
              <Col span={19} style={{ textAlign: "right" }}>
                <Form.Item>
                  <Space>
                    {currentPage !== 1 && (
                      <AppButton
                        type={ButtonType.Primary}
                        buttonContent={"Back"}
                        onClick={() =>
                          setCurrentPage(
                            currentPage !== 1 ? currentPage - 1 : 1
                          )
                        }
                        style={{ width: "90px" }}
                      />
                    )}
                    <FormSubmitButton
                      type={ButtonType.Primary}
                      buttonContent={
                        currentPage === 3 || showSubmit ? "Submit" : "Next"
                      }
                      htmlType="submit"
                      loading={isLoadingWeightChangeMutation}
                    />
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Form>
      </div>
    </div>
  );
};

const PageTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
