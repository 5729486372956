import { UserRoles } from "constants/roles";
import { UserContext, UserContextState } from "contextApis/userContext";
import { get } from "lodash";
import React, { useContext } from "react";
import { AppointmentListData } from "./appointment";
import { AppointmentListItem } from "./appointmentListItem";

interface AppointmentListInterface {
  readonly appointmentList: AppointmentListData[];
  readonly onEditAppointment: (appointment: AppointmentListData) => void;
  readonly onDeleteAppointment: (appointment: AppointmentListData) => void;
  readonly deleteAppointmentLoading: boolean;
}

export const AppointmentList: React.FC<AppointmentListInterface> = ({
  appointmentList,
  onEditAppointment,
  onDeleteAppointment,
  deleteAppointmentLoading
}) => {
  const { userData } = useContext<UserContextState>(UserContext);

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const userId = sessionStorage.getItem("userId") || "";

  return (
    <>
      {appointmentList.map((appointment, index) => (
        <div key={index} data-testid="appointment-list-item">
          <AppointmentListItem
            appointment={appointment}
            userIsPatient={userIsPatient}
            userId={userIsPatient ? userId : get(userData, "internal_id", "")}
            onEdit={onEditAppointment}
            onDelete={onDeleteAppointment}
            deleteAppointmentLoading={deleteAppointmentLoading}
          />
        </div>
      ))}
    </>
  );
};
