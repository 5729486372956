import React, { useContext, useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { AppDatePicker, AppTimePicker } from "components/inputs";
import { AddCallRecordGridContainer } from "pages/callRecords/style";
import { SelectedCallRecordDraft } from "pages/callRecords/callRecords";
import { FormInstance } from "antd";
import range from "lodash/range";
import { DateTime } from "luxon";
import { MeetingContext } from "amazon-chime-sdk-component-library-react/lib/providers/MeetingProvider";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";

export interface CallRecordDateProps {
  readonly date?: Moment | null;
}

interface AddCallRecordFormProps {
  readonly value?: CallRecordDateProps;
  readonly onChange?: (value: CallRecordDateProps) => void;
  readonly clearForm: boolean;
  readonly selectedDraft?: SelectedCallRecordDraft;
  readonly form?: FormInstance;
  readonly disabled?: boolean;
}

export const CallRecordDate: React.FC<AddCallRecordFormProps> = ({
  value,
  onChange,
  clearForm,
  selectedDraft,
  form,
  disabled
}) => {
  const [date, setDate] = useState<Moment | null>(null);
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

  const triggerChange = (changedValue: CallRecordDateProps) => {
    console.log("triggerChange", changedValue);

    onChange?.({
      date,
      ...value,
      ...changedValue
    });
  };

  const onDateChange = (newDate: Moment | null) => {
    console.log("onDateChange", newDate);

    if (newDate) {
      setDate(newDate);
      if (form) {
        form.setFieldsValue({ date: { date: newDate } });
      }
      triggerChange({ date: newDate });
    }
  };

  console.log("callrecdate");

  const disabledDate = (current: Moment) => {
    // Can not select days after today
    return current && current > moment().endOf("day");
  };

  const disabledHours = () => {
    const CURRENT_DATE = Number(moment().format("DD"));
    const selectedDate = Number(date?.format("DD") || "0");
    const CURRENT_HOUR = Number(moment().format("HH"));
    if (selectedDate === CURRENT_DATE) {
      return range(0, 24)
        .splice(4, 20)
        .filter(hour => hour > CURRENT_HOUR);
    }
    return [];
  };
  const disabledMinutes = () => {
    const CURRENT_DATE = Number(moment().format("DD"));
    const selectedDate = Number(date?.format("DD") || "0");
    const CURRENT_MINUTES = Number(moment().format("mm"));
    const CURRENT_HOUR = Number(moment().format("HH"));
    const selectedHour = Number(date?.format("HH") || "0");
    if (selectedHour === CURRENT_HOUR && selectedDate === CURRENT_DATE) {
      return range(0, 60).filter(minute => minute > CURRENT_MINUTES);
    }
    return [];
  };

  const convertDateToLocal = (value: string | Date) => {
    return moment.utc(value).local();
  };

  useEffect(() => {
    if (selectedDraft) {
      if (form) {
        form.setFieldsValue({
          date: { date: selectedDraft.date }
        });
      }
      meetingContext.fetchCallRecords
        ? setDate(convertDateToLocal(selectedDraft.date))
        : setDate(moment(selectedDraft.date));
    }
  }, [selectedDraft]);

  useEffect(() => {
    if (clearForm) {
      if (form) {
        form.setFieldsValue({ date: { date: null } });
      }
      setDate(null);
    }
  }, [clearForm]);

  return (
    <AddCallRecordGridContainer>
      <AppDatePicker
        label="Date"
        value={date}
        onChange={onDateChange}
        disabledDate={disabledDate}
        customDateFormat="MMM DD, YYYY"
        disabled={disabled}
      />
      <AppTimePicker
        label="Time"
        value={date}
        // value={convertDateToLocal(date).format("MMM DD, YYYY HH:mm")}
        onChange={onDateChange}
        disabledHours={disabledHours}
        disabledMinutes={disabledMinutes}
        placeholder="Select Time"
        customTimeFormat="hh:mm a"
        disabled={disabled}
      />
    </AddCallRecordGridContainer>
  );
};
