import { CloseOutlined } from "@ant-design/icons";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import React from "react";
import styled from "styled-components";

import { Colors } from "helpers/colors";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import { AllBillsList } from "sharedTypes";
import { dateToLocal } from "helpers/utils";
import moment from "moment";

interface ViewBillProps {
  readonly data: AllBillsList;
  setSelectedItem(item: string): void;
}

export const ViewBill: React.FC<ViewBillProps> = ({
  data,
  setSelectedItem
}) => {
  const minutesValue = Number(data.currentMonthContactTime) / 60;

  const convertDateToLocal = (value: string | Date, format?: string) => {
    return moment.utc(value, format).local();
  };

  return (
    <ViewBillContainer>
      <ViewBillHeader>
        <Row>
          <Col span={12}>View Bill</Col>
          <Col span={12} style={{ textAlign: "right", paddingRight: "20px" }}>
            <CloseOutlined onClick={() => setSelectedItem("")} />
          </Col>
        </Row>
      </ViewBillHeader>
      <ViewBillBody>
        <Row gutter={[20, 20]}>
          <Col span={14}>
            <LightTitle>Date of Service</LightTitle>
            <HeavyContent>
              <DateTimeFormat
                date={convertDateToLocal(data.dateOfService, "MMM/DD/YYYY")}
                hideTime
              />
            </HeavyContent>
          </Col>
          <RightHandCol span={10}>
            <LightTitle>Call Duration</LightTitle>
            <HeavyContent>
              {minutesValue % 1 !== 0
                ? minutesValue >= 0.1
                  ? minutesValue.toFixed(1)
                  : minutesValue.toFixed(2)
                : minutesValue}{" "}
              minutes
            </HeavyContent>
          </RightHandCol>
          <Col span={14}>
            <LightTitle>Patient Location</LightTitle>
            <HeavyContent>{data.patientLocation}</HeavyContent>
          </Col>
          <RightHandCol span={10}>
            <LightTitle>Provider Location</LightTitle>
            <HeavyContent>{data.providerLocation}</HeavyContent>
          </RightHandCol>
          <ListCol span={24}>
            <ListTitle className="diagonses-title">Diagnoses</ListTitle>
            {data?.diagnoses
              ? data?.diagnoses?.map((item, index) => {
                  return <DiagnosisRow key={index}>{item.desc}</DiagnosisRow>;
                })
              : ""}
          </ListCol>
          <ListCol span={24}>
            <ListTitle className="charges-title">Charges</ListTitle>
            {data.charges.map((item, index) => {
              return (
                <DiagnosisRow key={index}>
                  <Col span={24}>{item.code}</Col>
                  <Col span={24}>{item.desc}</Col>
                </DiagnosisRow>
              );
            })}
          </ListCol>
          {/* <Col span={24}>
            <LightTitle className="notes-to-biller-title">
              Notes to Biller
            </LightTitle>
            <NotesToBiller>{data.notesToBiller}</NotesToBiller>
          </Col> */}
        </Row>
      </ViewBillBody>
      {/* <ViewBillFooter>
        <AppButton
          type={ButtonType.Primary}
          buttonContent="Contact Biller"
          onClick={() => console.log("contact biller")}
        />
      </ViewBillFooter> */}
    </ViewBillContainer>
  );
};

// const NotesToBiller = styled.div`
//   font-size: 14px;
// `;

const DiagnosisRow = styled(Row)`
  font-size: 14px;
`;

const ListCol = styled(Col)`
  .ant-row {
    padding: 10px;
    border-bottom: 1px solid ${Colors.Lavender};
    :last-child {
      border-bottom: none;
    }
  }

  .charges-title {
    margin-top: -10px;
  }
`;

const ListTitle = styled.div`
  font-family: Century Gothic Bold;
  font-size: 12px;
`;

const RightHandCol = styled(Col)`
  border-left: 1px solid ${Colors.Lavender};
`;

const LightTitle = styled.div`
  color: ${Colors.Grey};
`;

const HeavyContent = styled.div`
  font-family: Century Gothic Bold;
  font-size: 14px;
`;

const ViewBillHeader = styled.div`
  height: 60px;
  padding-left: 25px;
  padding-top: 18px;
  font-family: Century Gothic Bold;
  font-size: 14px;
  border-bottom: 1px solid ${Colors.Lavender};
`;

const ViewBillBody = styled.div`
  height: 50px;
  padding: 20px;
  padding-top: 25px;
  border-bottom: 1px solid ${Colors.Lavender};
  height: calc(70vh - 130px);
  overflow: scroll;

  .notes-to-biller-title {
    margin-top: -10px;
  }
`;

// const ViewBillFooter = styled.div`
//   height: 70px;
//   padding: 14px;
//   text-align: right;
// `;

const ViewBillContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 8px 8px;
  margin-top: -2px;
  background-color: ${Colors.White};
  max-height: 70vh;
  font-size: 12px;
`;
