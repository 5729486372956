import Space from "antd/lib/space";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import React from "react";
import styled from "styled-components";

import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { Platform } from "constants/roles";

interface HeadacheSectionProps {
  readonly currentPage: number;
}

export const HeadacheSection: React.FC<HeadacheSectionProps> = ({
  currentPage
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  return (
    <>
      {currentPage === 6 && (
        <Space direction="vertical" size={20}>
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{ width: "100%", padding: "10px" }}
              >
                <DisclaimerTitle>HEADACHE</DisclaimerTitle>
                <CardTitle>
                  Have you had any headaches during dialysis recently?
                  <AsteriskStyle> *</AsteriskStyle>
                </CardTitle>
                <FormItemStyle
                  name="Have you had headaches recently"
                  label=""
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  initialValue={""}
                >
                  <Radio.Group
                    value={localStorage.getItem("haveYouHadHeadAches")}
                    onChange={e => {
                      localStorage.setItem(
                        "haveYouHadHeadAches",
                        e.target.value
                      );
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={"NO"}>NO</Radio>
                      <Radio value={"Rarely (1 - 2 / Month)"}>
                        Rarely (1 - 2 / Month)
                      </Radio>
                      <Radio value={"Occasional (3 - 4 / Month)"}>
                        Occasional (3 - 4 / Month)
                      </Radio>
                      <Radio value={"Frequent (5 - 10 / Month)"}>
                        Frequent (5 - 10 / Month)
                      </Radio>
                      <Radio value={"Every dialysis (11 - 13 / Month)"}>
                        Every dialysis (11 - 13 / Month)
                      </Radio>
                    </Space>
                  </Radio.Group>
                </FormItemStyle>
              </Space>
            </AppCard>
          </Space>
        </Space>
      )}
    </>
  );
};

const DisclaimerTitle = styled.div`
  width: 100%;
  padding: 8px 8px 8px 20px;
  margin-left: -25px;
  background: ${Colors.DodgerBlue};
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 8px 8px 0px 0px;
  color: white;
  position: absolute;
  top: 0;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
