import React from "react";
import Skeleton from "antd/lib/skeleton";
import { AppCard } from "components/card";
import { SkeletonFlexContainer } from "./styles";

interface SymptomsSkeletonLoaderProps {
  readonly isLoading: boolean;
}

export const SymptomsSkeletonLoader: React.FC<SymptomsSkeletonLoaderProps> = ({
  isLoading
}) => {
  return (
    <>
      <Skeleton
        loading={isLoading}
        active={isLoading}
        avatar={false}
        paragraph={{ rows: 0 }}
      />
      <SkeletonFlexContainer>
        <AppCard cardHeight="auto" cardWidth="100%">
          <Skeleton
            loading={isLoading}
            active={isLoading}
            avatar={false}
            paragraph={false}
          />
        </AppCard>
      </SkeletonFlexContainer>
    </>
  );
};
