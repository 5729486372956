import React, { useContext, useEffect, useMemo } from "react";
import { get } from "lodash";
import { useQuery } from "react-query";
import styled from "styled-components";

import { createApiClient } from "apiClient";
import { AppCard } from "components/card";
import { FilterDisplay } from "components/filterDisplay";
import { UserRoles } from "constants/roles";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { useDebounce } from "helpers/customHooks";
import { ActiveMedicineData } from "sharedTypes";
import { MedicationPages } from "./medication";
import {
  MedicationContext,
  MedicationContextState
} from "contextApis/medicationContext";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { ActiveMedicationList } from "./activeMedicationList";

interface MedicationTableProps {
  onMedicationDetailsPageChange: (page: MedicationPages) => void;
  readonly onSelectedMedicationforHistoryChange: (
    value: ActiveMedicineData | null
  ) => void;
  readonly searchValue: string;
  setSelectedReasons(reasons: string): void;
  readonly selectedReasons: string;
  readonly medicationAddedToggle: boolean;
}

export const ActiveMedication: React.FC<MedicationTableProps> = ({
  onMedicationDetailsPageChange,
  onSelectedMedicationforHistoryChange,
  searchValue,
  setSelectedReasons,
  selectedReasons,
  medicationAddedToggle
}) => {
  const patientContext = useContext<PatientContextState>(PatientContext);
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);
  const debouncedSearchValue = useDebounce(searchValue);

  const checkboxMenuContext =
    useContext<MedicationContextState>(MedicationContext);

  const isApiEnabled = useMemo(() => {
    if (patientContext.patientData) {
      if (patientContext.patientData.id) return true;
    }
    return false;
  }, [patientContext.patientData]);

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const userId = sessionStorage.getItem("userId") || "";

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    [
      "active-medication",
      debouncedSearchValue,
      selectedReasons,
      notificationsContext.notifications?.medications
    ],
    async () => {
      return await createApiClient().getActiveMedicationList(
        userIsPatient ? userId : get(patientContext, "patientData.id", ""),
        searchValue,
        selectedReasons,
        true
      );
    },
    { enabled: userIsPatient || isApiEnabled ? true : false }
  );

  useEffect(() => {
    if (patientContext.patientData || userIsPatient) {
      const selectedItemsOne =
        checkboxMenuContext.prescribedMenuOneReasons.reasons
          .filter(item => item.checked)
          .map(
            (obj: {
              desc: string;
              code: string;
              detail_desc: string;
              checked?: boolean;
            }) => obj.desc
          );

      const selectedItemsTwo =
        checkboxMenuContext.prescribedMenuTwoReasons.reasons
          .filter(item => item.checked)
          .map(
            (obj: {
              desc: string;
              code: string;
              detail_desc: string;
              checked?: boolean;
            }) => obj.desc
          );

      const selectedReasonsTemp = selectedItemsOne.concat(selectedItemsTwo);

      setSelectedReasons(selectedReasonsTemp.join(","));
      setTimeout(() => refetch(), 500);
    }
  }, [
    patientContext,
    checkboxMenuContext.prescribedMenuTwoReasons,
    checkboxMenuContext.prescribedMenuOneReasons
  ]);

  useEffect(() => {
    refetch();
  }, [medicationAddedToggle]);

  const activeMedicationsLoading =
    isLoading || isFetching || (!userIsPatient && !patientContext.patientData);

  return (
    <AppCard
      cardHeight="calc(100vh - 220px)"
      cardWidth="100%"
      style={{
        borderRadius: "0px 0px 8px 8px",
        marginTop: "-2px"
      }}
    >
      <FilterDisplay
        list1={checkboxMenuContext.prescribedMenuOneReasons.reasons
          .filter(item => item.checked)
          .map(
            (obj: {
              desc: string;
              code: string;
              detail_desc: string;
              checked?: boolean;
            }) => obj.desc
          )}
        list2={checkboxMenuContext.prescribedMenuTwoReasons.reasons
          .filter(item => item.checked)
          .map(
            (obj: {
              desc: string;
              code: string;
              detail_desc: string;
              checked?: boolean;
            }) => obj.desc
          )}
      />
      {error ? (
        <ErrorMessage>
          There was an error in fetching Active Medication
        </ErrorMessage>
      ) : (
        <ActiveMedicationList
          data={data}
          onMedicationDetailsPageChange={onMedicationDetailsPageChange}
          onSelectedMedicationforHistoryChange={
            onSelectedMedicationforHistoryChange
          }
          activeMedicationsLoading={activeMedicationsLoading}
          selectedReasons={selectedReasons}
        />
      )}
    </AppCard>
  );
};

const ErrorMessage = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  font-size: 22px;
  color: red;
  opacity: 0.3;
`;
