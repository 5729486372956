import { DateInput } from "./dateInput";
import { MultiSelectInput } from "./multiSelectInput";
import { SelectInput } from "./selectInput";
import { TextAreaInput, TextInput, PasswordInput } from "./textInput";
import { TimeInput } from "./timeInput";

export const AppTextInput = TextInput;

export const AppSelect = SelectInput;
export const AppMultiSelect = MultiSelectInput;

export const AppDatePicker = DateInput;

export const AppTextArea = TextAreaInput;

export const AppPasswordInput = PasswordInput;
export const AppTimePicker = TimeInput;
