import Row from "antd/lib/row";
import Col from "antd/lib/col";
import React from "react";
import styled from "styled-components";
import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { BNPValueIcon } from "./BNPValueIcon";
import { EjectionFractionIcon } from "./ejectionFractionIcon";
import { HeartRateIcon } from "./heartRateIcon";
import { LipidsIcon } from "./lipidsIcon";
import { KidneyFunctionHeartRateInfoDropdown } from "pages/myCurrentStatus/kidneyFunctionHeartRateInfoDropdown";
import {
  bnpValueQA,
  ejectionFractionQA,
  heartRateQA,
  lipidsQA
} from "pages/myCurrentStatus/myKidneyFunction/myHeartHealthQA";

export const MyHeartHealthContent: React.FC = () => {
  return (
    <AppCard
      cardHeight="100%"
      cardWidth="100%"
      style={{
        borderRadius: "0px 0px 8px 8px",
        marginTop: "-2px"
      }}
    >
      <Row gutter={[20, 20]}>
        <Col span={6}>
          <>
            <AppCard cardHeight="fit-content" cardWidth="100%">
              <HeartRateIcon />
              <CardDataStyled>Heart Rate</CardDataStyled>
              <CardDataStyled>-</CardDataStyled>
            </AppCard>

            <FAQDropdownTrigger>
              <KidneyFunctionHeartRateInfoDropdown data={heartRateQA}>
                What is heart rate?
              </KidneyFunctionHeartRateInfoDropdown>
            </FAQDropdownTrigger>
          </>
        </Col>
        <Col span={6}>
          <>
            <AppCard cardHeight="fit-content" cardWidth="100%">
              <EjectionFractionIcon />
              <CardDataStyled>Ejection Fraction</CardDataStyled>
              <CardDataStyled>-</CardDataStyled>
            </AppCard>
            <FAQDropdownTrigger>
              <KidneyFunctionHeartRateInfoDropdown data={ejectionFractionQA}>
                What is ejection fraction?
              </KidneyFunctionHeartRateInfoDropdown>
            </FAQDropdownTrigger>
          </>
        </Col>
        <Col span={6}>
          <>
            <AppCard cardHeight="fit-content" cardWidth="100%">
              <BNPValueIcon />
              <CardDataStyled>BNP Value</CardDataStyled>
              <CardDataStyled>-</CardDataStyled>
            </AppCard>
            <FAQDropdownTrigger>
              <KidneyFunctionHeartRateInfoDropdown data={bnpValueQA}>
                What is BNP value?
              </KidneyFunctionHeartRateInfoDropdown>
            </FAQDropdownTrigger>
          </>
        </Col>
        <Col span={6}>
          <>
            <AppCard cardHeight="fit-content" cardWidth="100%">
              <LipidsIcon />
              <CardDataStyled>Lipids</CardDataStyled>
              <CardDataStyled>-</CardDataStyled>
            </AppCard>
            <FAQDropdownTrigger>
              <KidneyFunctionHeartRateInfoDropdown data={lipidsQA}>
                What are Lipids?
              </KidneyFunctionHeartRateInfoDropdown>
            </FAQDropdownTrigger>
          </>
        </Col>
      </Row>
    </AppCard>
  );
};

const FAQDropdownTrigger = styled.div`
  color: ${Colors.DodgerBlue};
  font-size: 14px;
  font-family: Century Gothic Bold;
  padding: 20px;
`;

const CardDataStyled = styled.div`
  font-size: 24px;
  font-family: Century Gothic Bold;
`;
