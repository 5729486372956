import React, { useContext, useState } from "react";

import { AppTabs } from "components/tabs";
import { MyKidneyFunction } from "./myKidneyFunction";
import { MyHeartHealth } from "./myHeartHealth";
import { MyBloodPressure } from "./myBloodPressure";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { UserRoles } from "constants/roles";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { createApiClient } from "apiClient";
import { useQuery } from "react-query";

enum CurrentStatusTypes {
  MyKidneyFunction = "my-kidney-function",
  MyBloodPressure = "my-blood-pressure",
  MyHeartHealth = "my-heart-health"
}

export const MyCurrentStatusPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<CurrentStatusTypes>(
    CurrentStatusTypes.MyKidneyFunction
  );

  const patientContext = useContext<PatientContextState>(PatientContext);

  const {
    data: dataDisableComponent,
    isLoading: isLoadingDisableComponent,
    refetch: refetchDisableComponent
  } = useQuery(
    "disableComponent",
    async () => {
      if (patientContext.orgId) {
        return createApiClient().getDisableContent(patientContext.orgId);
      } else {
        throw new Error("orgId is missing");
      }
    },
    {
      enabled: !!patientContext.orgId
    }
  );

  const getSelectedTab = (key: CurrentStatusTypes) => {
    setSelectedTab(key);
  };

  const tabsContentOne = [
    {
      key: CurrentStatusTypes.MyKidneyFunction,
      tabName: "My Kidney Function"
    },
    {
      key: CurrentStatusTypes.MyBloodPressure,
      tabName: "My Blood Pressure"
    },
    {
      key: CurrentStatusTypes.MyHeartHealth,
      tabName: "My Heart Health"
    }
  ];

  const tabsContentTwo = [
    {
      key: CurrentStatusTypes.MyBloodPressure,
      tabName: "My Blood Pressure"
    },
    {
      key: CurrentStatusTypes.MyHeartHealth,
      tabName: "My Heart Health"
    }
  ];

  const tabsContent =
    dataDisableComponent?.Kidney_tab === 1 ? tabsContentTwo : tabsContentOne;

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const showTopbarExtesion = userIsCaregiver;

  return (
    <div style={{ position: "relative", height: "80vh" }}>
      {showTopbarExtesion && <CommonPatientExtensionItems />}
      <div>
        <AppTabs
          defaultActiveKey={selectedTab}
          tabsContent={tabsContent}
          tabPosition="top"
          getSelectedTab={getSelectedTab}
        />
      </div>

      <>
        {selectedTab === CurrentStatusTypes.MyKidneyFunction && (
          <MyKidneyFunction />
        )}
        {selectedTab === CurrentStatusTypes.MyBloodPressure && (
          <MyBloodPressure />
        )}
        {selectedTab === CurrentStatusTypes.MyHeartHealth && <MyHeartHealth />}
      </>
    </div>
  );
};
