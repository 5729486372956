import Input from "antd/lib/input";
import React from "react";
import styled from "styled-components";
import { useEffect } from "react";

interface TextInputProps {
  readonly label: string;
  readonly value?: string;
  readonly placeholder?: string;
  readonly onChange?: (value: string) => void;
  readonly style?: React.CSSProperties;
  readonly labelStyles?: React.CSSProperties;
  textStyles?: React.CSSProperties;
  readonly labelZIndex?: number;
  readonly disabled?: boolean;
  readonly handleMouseLeave?: (
    e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>
  ) => void;
}

interface PasswordInputProps extends TextInputProps {
  iconRender?: (visible: boolean) => React.ReactNode;
}

const StyledInput = styled(Input)`
  border-radius: 8px;
  height: 40px;
  padding: 18px 5px 8px 10px;
`;

const StyledTextArea = styled(Input.TextArea)`
  border-radius: 8px;
  padding-top: 15px;
`;

export const LabelContainer = styled.div`
  position: absolute;
  z-index: 1;
`;

export const LabelText = styled.div`
  font-size: 10px;
  color: #979dab;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 3px;
`;

// export const StyledSuffix = styled.div`
//   height: 44px;
//   width: 44px;
//   padding: 8px 10px 8px 20px;
// `;
export const TextInput: React.FC<TextInputProps> = ({
  label,
  value,
  placeholder,
  onChange,
  style,
  labelStyles,
  textStyles,
  disabled
}) => {
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange ? onChange(event.target.value) : false;

  return (
    <div style={style}>
      <LabelContainer style={labelStyles}>
        <LabelText>{label}</LabelText>
      </LabelContainer>
      <StyledInput
        value={value}
        placeholder={placeholder}
        onChange={onValueChange}
        style={textStyles}
        disabled={disabled}
      />
    </div>
  );
};

export const TextAreaInput: React.FC<TextInputProps> = ({
  label,
  value,
  placeholder,
  onChange,
  style,
  labelStyles,
  disabled,
  handleMouseLeave
}) => {
  const onValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    onChange ? onChange(event.target.value) : false;

  return (
    <div style={style}>
      <LabelContainer style={labelStyles}>
        <LabelText>{label}</LabelText>
      </LabelContainer>
      <StyledTextArea
        value={value}
        autoSize={{ minRows: 3 }}
        placeholder={placeholder}
        onChange={onValueChange}
        disabled={disabled}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
};

const StyledPassword = styled(Input.Password)`
  border-radius: 8px;
  height: 40px;
  padding: 18px 5px 8px 10px;
  .ant-input-suffix {
    font-weight: bold;
    padding: 0px 10px 10px 0;
  }
`;

export const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  value,
  placeholder,
  onChange,
  style,
  iconRender,
  labelStyles,
  textStyles
}) => {
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange ? onChange(event.target.value) : false;

  return (
    <div style={style}>
      <LabelContainer style={labelStyles}>
        <LabelText>{label}</LabelText>
      </LabelContainer>
      <StyledPassword
        value={value}
        placeholder={placeholder}
        onChange={onValueChange}
        iconRender={iconRender}
        style={textStyles}
      />
    </div>
  );
};
