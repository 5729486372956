import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import styled from "styled-components";
import { Colors } from "helpers/colors";
import { AppAvatar } from "components/avatar";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import { MessageData } from "components/messageContainer/messageContainer";

export enum PerspectiveTypes {
  Patient = "patient",
  Provider = "provider",
  OtherProvider = "other-provider"
}

interface MessageItemProps {
  readonly width: string;
  readonly allMessagesFormat?: boolean;
  readonly data: MessageData;
}

const MESSAGE_COLOR_LUMINANCE_REDUCTION_CONSTANT = 2;

const hashCode = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const intToRGB = (i: number) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
};

function colorLuminance(hex: string, lum: number) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}

const convertStringToRGB = (string: string) =>
  colorLuminance(
    intToRGB(hashCode(string)),
    MESSAGE_COLOR_LUMINANCE_REDUCTION_CONSTANT
  );

export const MessageItemComponent: React.FC<MessageItemProps> = ({
  width,
  data,
  allMessagesFormat
}) => {
  const [randomColor, setRandomColor] = useState("");

  useEffect(() => {
    const senderKey = `${data.sender_first_name}_${data.sender_last_name}_${data.sender_degree}`;
    setRandomColor(convertStringToRGB(senderKey));
  }, []);

  const rightMessageTheme = {
    backgroundColor: Colors.AliceBlue,
    borderColor: Colors.DodgerBlue,
    width: width,
    border: `1px solid ${Colors.DodgerBlue}`
  };

  const leftMessageTheme = {
    backgroundColor: Colors.White,
    borderColor: Colors.DodgerBlue,
    width: width,
    border: `1px solid ${Colors.DodgerBlue}`
  };

  const otherMessageTheme = {
    backgroundColor: randomColor,
    borderColor: Colors.DodgerBlue,
    width: width,
    border: `1px solid ${Colors.DodgerBlue}`
  };

  return (
    <>
      {data.perspective === PerspectiveTypes.Provider && (
        <SentMessage
          style={{
            backgroundColor: rightMessageTheme.backgroundColor,
            borderColor: `transparent transparent transparent ${rightMessageTheme.borderColor}`,
            width: rightMessageTheme.width,
            border: rightMessageTheme.border
          }}
          color={"10px"}
        >
          <Row>
            <div className="container">
              {data.content.split(/\n/).map(contentItem => (
                <div key={contentItem}>{contentItem}</div>
              ))}
            </div>
            <AvatarWrapper>
              <AppAvatar imageSrc={data.picture} size="large" />
            </AvatarWrapper>
          </Row>

          <Row>
            {allMessagesFormat ? (
              <SentMessageSubText>
                <SentMessageName>
                  {data.sender_first_name} {data.sender_last_name || ""}{" "}
                  {data.sender_degree || ""} to {data.receiver_first_name}{" "}
                  {data.receiver_last_name || ""} {data.receiver_degree || ""}
                </SentMessageName>{" "}
                <Row>
                  <DateTimeFormat date={data.timestamp} />
                </Row>
              </SentMessageSubText>
            ) : (
              <SentMessageSubText>
                <SentMessageName>
                  {data.sender_first_name} {data.sender_last_name || ""}{" "}
                  {data.sender_degree || ""}
                </SentMessageName>{" "}
                <Row>
                  <DateTimeFormat date={data.timestamp} />
                </Row>
              </SentMessageSubText>
            )}
          </Row>
          {/* <Row>
            <SentMessageSubText>
              {data.readerName}, {data.providerDegree} | &nbsp;
              <DateTimeFormat date={data.date} />
            </SentMessageSubText>
          </Row> */}
        </SentMessage>
      )}
      {data.perspective === PerspectiveTypes.Patient && (
        <ReceivedMessage
          style={{
            backgroundColor: leftMessageTheme.backgroundColor,
            borderColor: `transparent transparent transparent ${leftMessageTheme.borderColor}`,
            width: leftMessageTheme.width,
            border: leftMessageTheme.border
          }}
        >
          <Row>
            <AvatarWrapper>
              <AppAvatar imageSrc={data.picture} size="large" />
            </AvatarWrapper>
            <div className="container">{data.content}</div>
          </Row>
          {allMessagesFormat ? (
            <>
              <ReceivedMessageNameText>
                {data.sender_first_name} {data.sender_last_name || ""}{" "}
                {data.sender_degree || ""}
                {data.regarding_first_name && data.regarding_last_name
                  ? ` regarding ${data.regarding_first_name} ${data.regarding_last_name}`
                  : ""}
              </ReceivedMessageNameText>
              <ReceivedMessageSubText>
                <DateTimeFormat date={data.timestamp} />
              </ReceivedMessageSubText>
            </>
          ) : (
            <>
              <ReceivedMessageNameText>
                {!data.sender_first_name && !data.sender_last_name
                  ? data.reciver_name
                  : `${
                      data.sender_first_name + " " + data.sender_last_name
                    }`}{" "}
                {data.sender_degree || ""}
              </ReceivedMessageNameText>
              <ReceivedMessageSubText>
                <DateTimeFormat date={data.timestamp} />
              </ReceivedMessageSubText>
            </>
          )}
          {/* <ReceivedMessageSubText>
              {data.senderName} |&nbsp; <DateTimeFormat date={data.date} />
            </ReceivedMessageSubText> */}
        </ReceivedMessage>
      )}
      {data.perspective === PerspectiveTypes.OtherProvider && (
        <SentMessage
          style={{
            backgroundColor: rightMessageTheme.backgroundColor,
            borderColor: `transparent transparent transparent ${otherMessageTheme.borderColor}`,
            width: otherMessageTheme.width,
            border: otherMessageTheme.border
          }}
        >
          <Row>
            <div className="container">{data.content}</div>
            <AvatarWrapper>
              <AppAvatar imageSrc={data.picture} size="large" />
            </AvatarWrapper>
          </Row>
          <Row>
            {allMessagesFormat ? (
              <SentMessageSubText>
                <SentMessageName>
                  {data.sender_first_name} {data.sender_last_name || ""}{" "}
                  {data.sender_degree || ""} to {data.receiver_first_name}{" "}
                  {data.receiver_last_name || ""} {data.receiver_degree || ""}
                </SentMessageName>{" "}
                <Row>
                  <DateTimeFormat date={data.timestamp} />
                </Row>
              </SentMessageSubText>
            ) : (
              <SentMessageSubText>
                <SentMessageName>
                  {data.sender_first_name} {data.sender_last_name || ""}{" "}
                  {data.sender_degree || ""}
                </SentMessageName>
                <Row>
                  <DateTimeFormat date={data.timestamp} />
                </Row>
              </SentMessageSubText>
            )}
          </Row>
        </SentMessage>
      )}
    </>
  );
};

const SentMessageName = styled.div`
  text-align: right;
  color: ${Colors.Black};
`;

const AvatarWrapper = styled.div`
  display: inline-block;
  align-self: flex-end;
`;

const SentMessageSubText = styled.div`
  padding: 5px 55px 0px 0px;
  font-size: 12px;
  margin-left: auto;
  color: #878c97;
`;

const ReceivedMessageSubText = styled(Row)`
  padding: 5px 0px 0px 55px;
  font-size: 12px;
  color: #878c97;
`;

const ReceivedMessageNameText = styled(Row)`
  padding: 5px 0px 0px 55px;
  margin-bottom: -5px;
  font-size: 12px;
  color: ${Colors.Black};
`;

const CommonBoxStyle = styled.div.attrs<any>(props => ({ props }))`
  padding: 10px;
  .container {
    background: ${props => props.style?.backgroundColor};
    border: ${props => props.style?.border};
    width: ${props => props.style?.width};
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: start;
    border-radius: 10px;
    padding: 10px;
    overflow-wrap: break-word;
  }

  /* this border color controlls the color of the triangle (what looks like the fill of the triangle) */
  .container:after {
    bottom: 10px;
    border-color: ${props => props.style?.borderColor};
    border-width: 6px;
  }

  /* this border color controlls the outside, thin border */
  .container:before {
    bottom: 9px;
    border-color: ${props => props.style?.borderColor};
    border-width: 7px;
  }
`;

const ReceivedMessage = styled(CommonBoxStyle)`
  .container {
    margin-left: auto;
    margin-left: 10px;
  }

  background: none !important;
  border-color: none !important;
  width: 100% !important;
  border: none !important;

  /* this CS forms the triangles */
  .container:before,
  .container:after {
    content: "";
    display: block;
    position: absolute;
    right: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    transform: rotate(180deg);
  }
`;

const SentMessage = styled(CommonBoxStyle)`
  .container {
    margin-left: auto;
    margin-right: 10px;
  }
  background: none !important;
  border-color: none !important;
  width: 100% !important;
  border: none !important;

  /* this CS forms the triangles */
  .container:after,
  .container:before {
    content: "";
    display: block;
    position: absolute;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;
