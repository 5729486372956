import React from "react";
import Switch from "antd/lib/switch";
import Tooltip from "antd/lib/tooltip";
import styled from "styled-components";
interface SwitchProps {
  readonly tooltip?: string;
  readonly altTooltip?: string;
  onSwitchToggle(identifier: string | number, state: boolean): void;
  readonly checked: boolean;
  readonly identifier: string | number;
  readonly color: string;
  readonly className?: string;
  readonly loading?: boolean;
}

export const AppSwitchComponent: React.FC<SwitchProps> = ({
  tooltip,
  altTooltip,
  onSwitchToggle,
  checked,
  identifier,
  color,
  className,
  loading
}) => {
  //Arrow styling handled in App.css
  const tooltipInnerStyling = {
    borderRadius: "10px",
    backgroundColor: "white",
    color: "black",
    fontSize: 11,
    padding: "9px 10px 0px 12px",
    fontWeight: 800
  };
  return (
    <AppSwitchWrapper style={{ color: checked ? "#979dab" : color }}>
      <Tooltip
        title={checked ? altTooltip : tooltip}
        placement="bottomRight"
        overlayInnerStyle={tooltipInnerStyling}
      >
        <Switch
          size="small"
          checked={checked}
          onChange={e => onSwitchToggle(identifier, e)}
          className={className}
          loading={loading}
        />
      </Tooltip>
    </AppSwitchWrapper>
  );
};

const AppSwitchWrapper = styled.span`
  .ant-switch-checked {
    background-color: red;
  }
`;
