import moment from "moment";
import { dateToLocal, lowerCaseRemoveSpace } from "helpers/utils";
import { ExtendedSymptomData } from "pages/symptoms/SymptomsModal";

export const vitalSignsMapper = ({
  filteredExtendedSymptomData
}: {
  filteredExtendedSymptomData: ExtendedSymptomData[] | null;
  mapperType?: string | undefined;
  mapperValue?: string | number | undefined;
}) => {
  const amSystolic: [string, number, string][] = [];
  const weightlb: [string, number, string][] = [];
  const weightkg: [string, number, string][] = [];
  const amDiastolic: [string, number, string][] = [];
  const pmSystolic: [string, number, string][] = [];
  const pmDiaslotic: [string, number, string][] = [];

  const sortedData = filteredExtendedSymptomData?.sort((a, b) => {
    if (a.data && b.data) {
      const aWeightTakenOn = a.data.filter(
        item => lowerCaseRemoveSpace(item.key) === "weighttakenon"
      );
      const bWeightTakenOn = b.data.filter(
        item => lowerCaseRemoveSpace(item.key) === "weighttakenon"
      );

      if (aWeightTakenOn[0] && bWeightTakenOn[0]) {
        return (
          moment(aWeightTakenOn[0].value).valueOf() -
          moment(bWeightTakenOn[0].value).valueOf()
        );
      } else {
        return moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf();
      }
    }
    return moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf();
  });

  sortedData?.forEach(item => {
    const weightTakenOn = item.data?.find(
      item => lowerCaseRemoveSpace(item.key) === "weighttakenon"
    );
    item.data?.map(item2 => {
      if (lowerCaseRemoveSpace(item2?.key) === "amsyst") {
        amSystolic.push([
          String(dateToLocal(item.timestamp).valueOf()),
          Number(item2?.value),
          "AM Systolic"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "amdias") {
        amDiastolic.push([
          String(dateToLocal(item.timestamp).valueOf()),
          Number(item2?.value),
          "AM Diastolic"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "pmsyst") {
        pmSystolic.push([
          String(dateToLocal(item.timestamp).valueOf()),
          Number(item2?.value),
          "PM Systolic"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "pmdias") {
        pmDiaslotic.push([
          String(dateToLocal(item.timestamp).valueOf()),
          Number(item2?.value),
          "PM Diastolic"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "wt(kg)") {
        weightlb.push([
          String(dateToLocal(weightTakenOn?.value || item.timestamp).valueOf()),
          Number(item2?.value),
          "Weight(Kg)"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "wt(lb)") {
        weightkg.push([
          String(dateToLocal(weightTakenOn?.value || item.timestamp).valueOf()),
          Number(item2?.value),
          "Weight(Lb)"
        ]);
      }
    });
  });

  return [amSystolic, amDiastolic, pmSystolic, pmDiaslotic, weightlb, weightkg];
};
