import React, { useEffect, useState } from "react";
import Layout from "antd/lib/layout";
import styled from "styled-components";

import { Colors } from "helpers/colors";
import { TopbarLogo } from "components/caregiverTopbar/topbarLogo";
import { useLocation } from "react-router-dom";
import { CaregiverRoutesRef, CommonRoutesRef } from "constants/routes";
import { MyMedicalDataSidebar } from "components/sideBar/myMedicalDataSidebar";
import { MyHealthStatusSidebar } from "components/sideBar/myHealthStatusSidebar";
import { CaregiverTopbar } from "components/caregiverTopbar";
import { TopbarButtons } from "components/caregiverTopbar/topbarButtons";

export const CaregiversLayout: React.FC = ({ children }) => {
  const location = useLocation();

  const [showHealthStatusSidebar, setShowHealthStatusSidebar] = useState(false);

  const renderSidebar =
    !location.pathname.includes(CaregiverRoutesRef.ReportSymptoms) &&
    !location.pathname.includes(CaregiverRoutesRef.MyCareTeam) &&
    !location.pathname.includes(CommonRoutesRef.MyProfile) &&
    !location.pathname.includes(CommonRoutesRef.Info) &&
    !location.pathname.includes(CaregiverRoutesRef.AllMessages) &&
    !location.pathname.includes(CaregiverRoutesRef.Patients) &&
    !location.pathname.includes(CaregiverRoutesRef.AllMessages) &&
    !location.pathname.includes(CaregiverRoutesRef.AllNotifications);

  useEffect(() => {
    if (location.pathname.includes(CaregiverRoutesRef.MyHealthStatus)) {
      setShowHealthStatusSidebar(true);
    } else {
      setShowHealthStatusSidebar(false);
    }
  }, [location]);

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100vh",
        paddingTop: location.pathname.includes(CaregiverRoutesRef.AllMessages)
          ? "60px"
          : "125px"
      }}
    >
      <CaregiverTopbar />
      <TopbarButtons />
      <TopbarLogo />
      {renderSidebar ? (
        <LayoutStyled style={{ padding: "0px", overflowX: "hidden" }}>
          {showHealthStatusSidebar ? (
            <MyHealthStatusSidebar>{children}</MyHealthStatusSidebar>
          ) : (
            <MyMedicalDataSidebar>{children}</MyMedicalDataSidebar>
          )}
        </LayoutStyled>
      ) : (
        <LayoutStyled>{children}</LayoutStyled>
      )}
    </Layout>
  );
};

const LayoutStyled = styled(Layout)`
  overflow-x: hidden;
  overflow: auto;
  padding: 15px 18px 18px 18px;
  background-color: ${Colors.GhostWhite};
`;
