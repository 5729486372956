import Button from "antd/lib/button";
import React, { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import { Colors } from "helpers/colors";

export enum ButtonType {
  Secondary = "secondary",
  Primary = "primary",
  Link = "link",
  Skeleton = "skeleton",
  Action = "action"
}

type ButtonHTMLType = "submit" | "reset" | "button";

interface ButtonProps {
  readonly type: ButtonType;
  readonly buttonContent: ReactNode;
  onClick?(): void;
  readonly loading?: boolean;
  readonly style?: React.CSSProperties;
  readonly className?: string;
  readonly htmlType?: ButtonHTMLType;
  readonly disabled?: boolean;
  readonly onMouseEnter?: MouseEventHandler<any>;
  readonly onMouseLeave?: MouseEventHandler<any>;
}

export const ButtonComponent: React.FC<ButtonProps> = ({
  type,
  buttonContent,
  onClick,
  style,
  className,
  htmlType,
  loading,
  disabled,
  onMouseEnter,
  onMouseLeave
}) => {
  if (type === "secondary") {
    return (
      <SecondaryButton
        style={style}
        className={className}
        onClick={onClick}
        loading={loading}
        disabled={disabled}
      >
        {buttonContent}
      </SecondaryButton>
    );
  }
  if (type === "link") {
    return (
      <LinkButton
        style={style}
        className={className}
        type="link"
        onClick={onClick}
        loading={loading}
        disabled={disabled}
      >
        {buttonContent}
      </LinkButton>
    );
  }

  if (type === "skeleton") {
    return (
      <SkeletonButton
        style={style}
        className={className}
        onClick={onClick}
        loading={loading}
        disabled={disabled}
      >
        {buttonContent}
      </SkeletonButton>
    );
  }

  if (type === "action") {
    return (
      <ActionButton
        style={style}
        className={className}
        onClick={onClick}
        loading={loading}
        disabled={disabled}
      >
        {buttonContent}
      </ActionButton>
    );
  }
  return (
    <PrimaryButton
      style={style}
      className={className}
      onClick={onClick}
      htmlType={htmlType}
      loading={loading}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {buttonContent}
    </PrimaryButton>
  );
};

const SkeletonButton = styled(Button)`
  height: 44px;
  font-size: 14px;
  border-radius: 10px;
  background-color: ${Colors.White};
  color: ${Colors.DodgerBlue};
  border-color: ${Colors.DodgerBlue};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  font-family: Century Gothic Bold;

  :disabled {
    background-color: ${Colors.White};

    :hover {
      filter: brightness(1.1);
      color: ${Colors.Grey} !important;
      border-color: ${Colors.Black} !important;
    }
  }

  :hover {
    filter: brightness(1.1);
    background-color: ${Colors.White} !important;
    color: ${Colors.DodgerBlue} !important;
    border-color: ${Colors.DodgerBlue} !important;
  }

  :active {
    filter: brightness(1.1);
    background-color: ${Colors.White};
    color: ${Colors.DodgerBlue};
    border-color: ${Colors.DodgerBlue};
  }

  :focus {
    filter: brightness(1.1);
    background-color: ${Colors.White};
    color: ${Colors.DodgerBlue};
    border-color: ${Colors.DodgerBlue};
  }
`;

const PrimaryButton = styled(Button)`
  height: 44px;
  font-size: 14px;
  border-radius: 10px;
  background-color: ${Colors.DodgerBlue};
  color: ${Colors.White};
  border-color: ${Colors.DodgerBlue};
  font-family: Century Gothic Bold;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);

  @media (hover: hover) {
    :hover {
      filter: brightness(1.1);
      background-color: ${Colors.DodgerBlue} !important;
      color: ${Colors.White} !important;
      border-color: ${Colors.DodgerBlue} !important;
    }

    :active {
      filter: brightness(1.1);
      background-color: ${Colors.DodgerBlue};
      color: ${Colors.White};
      border-color: ${Colors.DodgerBlue};
    }
    :focus {
      filter: brightness(1.1);
      background-color: ${Colors.DodgerBlue};
      color: ${Colors.White};
      border-color: ${Colors.DodgerBlue};
    }
  }
  @media (hover: none) {
    :focus {
      filter: brightness(1.1);
      background-color: ${Colors.DodgerBlue};
      color: ${Colors.White};
      border-color: ${Colors.DodgerBlue};
    }
  }
`;

const SecondaryButton = styled(Button)`
  height: 44px;
  color: #979dab;
  border-color: #caced4;
  font-size: 14px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  font-family: Century Gothic Bold;

  :hover {
    color: ${Colors.DodgerBlue};
    border-color: ${Colors.DodgerBlue};
  }
`;

const LinkButton = styled(Button)`
  border: none;
  background-color: transparent;
  box-shadow: none;
  font-size: 14px;
  height: 22px;
  line-height: 22px;
  color: ${Colors.DodgerBlue};
  font-family: Century Gothic Bold;

  :hover {
    color: ${Colors.DodgerBlue};
    filter: brightness(1.2);
  }
`;

const ActionButton = styled(Button)`
  height: 44px;
  font-size: 14px;
  font-family: Century Gothic Bold;
  border-radius: 10px;
  background-color: ${Colors.Beige};
  color: ${Colors.Black};
  border-color: #b5d264;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);

  :hover {
    filter: brightness(1.1);
    background-color: ${Colors.Beige} !important;
    color: ${Colors.Black} !important;
    border-color: #b5d264 !important;
  }

  :active {
    background-color: ${Colors.Beige};
    color: ${Colors.Black};
    border-color: #b5d264;
  }

  :focus {
    background-color: ${Colors.Beige};
    color: ${Colors.Black};
    border-color: #b5d264;
  }
`;
