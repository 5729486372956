import React, { useContext, useEffect, useState } from "react";
import { Modal } from "antd";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { UserRoles } from "constants/roles";
import {
  MeetingContextState,
  MeetingModalContext,
  sessionStatusValues
} from "contextApis/meetingModalContext";
import { MeetingPortalModal } from "components/meetingPortal/Modal/meetingPortalModal";
import { UserContext, UserContextState } from "contextApis/userContext";

export const VCMeetPage: React.FC = () => {
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const [modalVisible, setModalVisible] = useState(true);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(
    null
  );
  const userContext = useContext<UserContextState>(UserContext);

  const userRole = sessionStorage.getItem("userRole");
  const userId = sessionStorage.getItem("userId");
  const platform = sessionStorage.getItem("platform");

  const { refetch: checkForActiveMeeting } = useQuery(
    "active-meeting",
    async () => {
      return await createApiClient().activeMeeting(userId ? userId : "");
    },
    {
      enabled: false,
      onSuccess: data => {
        if (typeof data !== "string" && data.MeetingId) {
          setModalContent(
            <>
              <div style={{ fontWeight: "600" }}>
                You have been invited to join a meeting by{" "}
                {data.user_info.first_name + " " + data.user_info.last_name},
                click below to join:
              </div>
              <AppButton
                buttonContent="Join Meeting"
                type={ButtonType.Primary}
                onClick={() => {
                  meetingContext.setTargetUser(
                    data.user_info.first_name + " " + data.user_info.last_name
                  );
                  meetingContext.setJoinMeetingID(data.MeetingId);
                  meetingContext.setCurrentPage(1);
                  meetingContext.maximize(true);
                  setModalVisible(false);
                }}
              />
            </>
          );
          setModalVisible(true);
        } else {
          setModalContent(null);
          setModalVisible(true);
        }
      },
      onError: error => {
        setModalContent(
          <div>
            There was a problem checking for active meetings. Please try again
            later.
          </div>
        );
        setModalVisible(true);
      }
    }
  );

  useEffect(() => {
    if (userRole === UserRoles.PATIENT) {
      checkForActiveMeeting();
    }
  }, []);

  useEffect(() => {
    if (meetingContext.sessionStatus === sessionStatusValues.ENDED) {
      console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^");
      console.log("MEETING ENDED");
      // window.close();
      // window.location.href = "/landing-page";
      userContext.onLogout();
    }
  }, [meetingContext.sessionStatus]);

  return (
    <>
      <div className="VCMeet">WELCOME TO VC SERVICE</div>
      <MeetingPortalModal />
      <Modal title="Meeting Invitation" visible={modalVisible} footer={null}>
        {modalContent}
      </Modal>
    </>
  );
};
