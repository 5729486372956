// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import styled from "styled-components";

interface Props {
  showNav: boolean;
  showRoster: boolean;
  showChat: boolean;
  pstnCall: boolean;
}

export const StyledLayout = styled.main<Props>`
  height: ${props => (props.pstnCall ? "30vh" : "92vh")};
  width: 100%;
  position: absolute;
  overflow: hidden;

  display: grid;

  .video-content {
    grid-area: content;
    height: 92vh;
  }

  ${({ showNav, showRoster, showChat }) => {
    if (showNav && (showRoster || showChat)) {
      return `
        grid-template-columns: auto auto 1fr;
        grid-template-areas: 'nav side-panel content';
      `;
    }

    if (showNav && (showRoster || showChat)) {
      return `
        grid-template-columns: auto auto 1fr;
        grid-template-areas: 'nav side-panel content';
      `;
    }

    if (showNav) {
      return `
        grid-template-columns: auto 1fr;
        grid-template-areas: 'nav content';
      `;
    }

    if (showRoster || showChat) {
      return `
        grid-template-columns: auto 1fr;
        grid-template-areas: 'side-panel content';
      `;
    }

    return `
      grid-template-columns: 1fr;
      grid-template-areas: 'content';
    `;
  }}

  .nav {
    grid-area: nav;
    height: 92vh;
  }

  .roster {
    grid-area: side-panel;
    z-index: 2;
    height: 92vh;
  }

  @media screen and (min-width: 769px) {
    .mobile-toggle {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas: "content";

    .nav {
      grid-area: unset;
      position: fixed;
      height: 92vh;
    }

    .roster {
      grid-area: unset;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 320px;
      height: 92vh;
    }
  }

  @media screen and (max-width: 460px) {
    .roster {
      max-width: 100%;
    }
  }
`;

export const StyledContent = styled.div`
  grid-area: content;
  height: 100%;
  width: 100%;
  display: flex;

  .videos {
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
  }

  .controls {
    position: absolute;
    width: 27%;
    bottom: 1rem;
    left: 53.5%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 768px) {
    .controls {
      position: static;
      transform: unset;
    }
  }
`;

export const TimerContent = styled.div`
  // position: absolute,
  // marginLeft: 190px,
  // fontSize: 10px,
  // marginTop: 40
`;
