import Space from "antd/lib/space";
import Dropdown from "antd/lib/dropdown";
import Button from "antd/lib/button";
import Menu from "antd/lib/menu";
import Row from "antd/lib/row";
import { DownOutlined } from "@ant-design/icons";
import { EyeOutlined } from "@ant-design/icons";
import { Footer, Header } from "antd/lib/layout/layout";
import Checkbox from "antd/lib/checkbox/Checkbox";
import styled, { css, CSSProp } from "styled-components";
import { Colors } from "helpers/colors";

export interface SpacerProps {
  margin?: CSSProp;
  mt?: CSSProp;
  mr?: CSSProp;
  mb?: CSSProp;
  ml?: CSSProp;
  padding?: CSSProp;
  pt?: CSSProp;
  pr?: CSSProp;
  pb?: CSSProp;
  pl?: CSSProp;
}

const spacer = (props: SpacerProps) => css`
  margin: ${props.margin};
  margin-top: ${props.mt};
  margin-right: ${props.mr};
  margin-bottom: ${props.mb};
  margin-left: ${props.ml};
  padding: ${props.padding};
  padding-top: ${props.pt};
  padding-right: ${props.pr};
  padding-bottom: ${props.pb};
  padding-left: ${props.pl};
`;

//TODO: Use spacer in future
interface DropdownProps {
  cursor: CSSProp;
  padding?: CSSProp;
  borderradius?: CSSProp;
}
interface OverlayContainerProps {
  borderradius?: CSSProp;
}

interface MessageProps {
  color?: CSSProp;
  fontSize: CSSProp;
}

export const DropDownStyled = styled(Dropdown)<DropdownProps>`
  padding: ${props => props.padding};
  border-radius: ${props => props.borderradius};
  cursor: ${props => props.cursor};
  ${spacer};
`;

export const MessageRow = styled(Row)<MessageProps>`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
`;

/**Below styled divs are just for the usage of developer
 *  one has to write custom compenents independently */
export const DownOutlinedStyle = styled(DownOutlined)`
  margin-right: -40px;
  content: bold;
`;

export const Container = styled.div`
  display: inline;
  margin-right: 60px;
  margin-left: -30px;
`;

export const ItemStyled = styled(Menu.Item)`
  padding: 10px 20px 10px 40px;
  margin-left: 10px;
  font-size: 14px;
  font-family: "Century Gothic";
  :hover {
    border-radius: 8px;
    background-color: ${Colors.BabyBlue};
  }
`;

export const ButtonStyled = styled(Button)`
  padding: 20px 50px 20px 50px;
  margin: 20px;
  position: relative;
  margin-left: 30px;
  display: flex;
  align-items: center;
  width: 196px;
  height: 44px;
`;

export const HeaderStyled = styled(Header)`
  background-color: ${Colors.White};
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  border-radius: 8px;
`;

export const FooterStyled = styled(Footer)`
  background-color: ${Colors.White};
  display: flex;
  font-size: 16px;
  border-radius: 0 0 20px 20px;
  float: right;
  width: 100%;
  padding-left: 260px;
`;

export const StyledEyeOutline = styled(EyeOutlined)`
  border: 1px solid #979dab;
  width: 44px;
  padding: 10px;
  border-radius: 9.5px;
`;

export const CheckboxSpace = styled(Space)`
  height: 37px;
  width: 150px;
  margin-left: 100px;
  border: 1px solid #e2edf3;
  border-radius: 8px;
  background-color: #f5f9fc;
`;

export const CheckboxStyled = styled(Checkbox)`
  padding-left: 10px;
`;

export const OverlayContainer = styled.div<OverlayContainerProps>`
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: ${props => props.borderradius || "0px"};
`;
