import React, { ReactNode } from "react";
import { UserOutlined } from "@ant-design/icons";
import Row from "antd/lib/row";
import Avatar from "antd/lib/avatar";
import Col from "antd/lib/col";
import styled from "styled-components";
import { Colors } from "helpers/colors";
import { AvatarSize } from "antd/lib/avatar/SizeContext";

interface AvatarProps {
  readonly imageSrc: string;
  readonly mainContent?: ReactNode;
  readonly subText?: string;
  readonly size: AvatarSize;
  readonly className?: string;
  readonly additionalNote?: string;
  readonly leftColWidth?: string;
  readonly rightColWidth?: string;
  readonly suffixIcon?: ReactNode;
}

export const AvatarComponent: React.FC<AvatarProps> = ({
  mainContent,
  subText,
  additionalNote,
  size,
  className,
  leftColWidth,
  rightColWidth,
  suffixIcon
}) => {
  return (
    <AvatarWrapper>
      <Col
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
          width: leftColWidth
        }}
      >
        <Avatar
          shape="circle"
          size={size}
          icon={(<UserOutlined />) as any}
          src={undefined}
          className={className}
        />
      </Col>
      <Col style={{ width: rightColWidth, maxWidth: "100%" }}>
        {mainContent && (
          <ContentContainer>
            <MessageUpperRow>{mainContent}</MessageUpperRow>
            {subText && (
              <MessageMiddleRow>
                {subText}
                {suffixIcon}
              </MessageMiddleRow>
            )}
            {additionalNote && (
              <MessageLowerRow>{additionalNote}</MessageLowerRow>
            )}
          </ContentContainer>
        )}
      </Col>
    </AvatarWrapper>
  );
};

const AvatarWrapper = styled(Row)`
  max-width: 100%;
`;

const ContentContainer = styled.div`
  margin-left: 15px;
`;

const MessageUpperRow = styled(Row)`
  font-size: 14px;
  font-family: Century Gothic Bold;
`;

const MessageMiddleRow = styled(Row)`
  color: ${Colors.gothicBold};
  font-size: 12px;
  max-width: 225px;
`;

const MessageLowerRow = styled(Row)`
  color: ${Colors.gothicRegular};
  font-size: 12px;
  max-width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;

  :hover {
    overflow: auto;
    white-space: normal;
  }
`;

//re-committ
