import React from "react";

interface ArrowUpwardIconProps {
  color?: string;
}

export const ArrowUpwardIcon: React.FC<ArrowUpwardIconProps> = ({
  color = "#F5A623"
}) => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-VD-Provider-MobileApp-RemoteVitals-V1.2"
          transform="translate(-304.000000, -269.000000)"
          fill={color}
        >
          <polygon
            id="arrow_upward---material"
            points="304.32 276 311 269.32 317.68 276 316.46 277.18 311.82 272.52 311.82 282.68 310.18 282.68 310.18 272.52 305.5 277.18"
          ></polygon>
        </g>
      </g>
    </svg>
  );
};
