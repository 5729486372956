import React from "react";

export interface AddBillIconProps {
  readonly fill: string;
}

export const AddBillIcon: React.FC<AddBillIconProps> = ({ fill }) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_102_33)">
        <g filter="url(#filter0_d_102_33)">
          <path
            d="M10.2854 16.0371H8.13641L8.12515 18.3436C8.12381 18.6187 7.90035 18.8411 7.62516 18.8411H6.62272C6.34658 18.8411 6.12272 18.6173 6.12272 18.3411V16.0371H3.96219C3.68605 16.0371 3.46219 15.8132 3.46219 15.5371V14.5133C3.46219 14.2371 3.68605 14.0133 3.96219 14.0133H6.12899V11.8318C6.12899 11.5556 6.35284 11.3318 6.62899 11.3318H7.63385C7.90999 11.3318 8.13385 11.5556 8.13385 11.8318C8.13385 11.8326 8.13385 11.8335 8.13384 11.8343L8.12272 14.0133H10.2854C10.5615 14.0133 10.7854 14.2371 10.7854 14.5133V15.5371C10.7854 15.8132 10.5615 16.0371 10.2854 16.0371Z"
            fill="black"
          />
        </g>
        <path
          d="M10.2854 16.0371H8.13641L8.12515 18.3436C8.12381 18.6187 7.90035 18.8411 7.62516 18.8411H6.62272C6.34658 18.8411 6.12272 18.6173 6.12272 18.3411V16.0371H3.96219C3.68605 16.0371 3.46219 15.8132 3.46219 15.5371V14.5133C3.46219 14.2371 3.68605 14.0133 3.96219 14.0133H6.12899V11.8318C6.12899 11.5556 6.35284 11.3318 6.62899 11.3318H7.63385C7.90999 11.3318 8.13385 11.5556 8.13385 11.8318C8.13385 11.8326 8.13385 11.8335 8.13384 11.8343L8.12272 14.0133H10.2854C10.5615 14.0133 10.7854 14.2371 10.7854 14.5133V15.5371C10.7854 15.8132 10.5615 16.0371 10.2854 16.0371Z"
          fill={fill}
        />
        <g filter="url(#filter1_d_102_33)">
          <path
            d="M6.09606 9.95217H4.69606C4.53037 9.95217 4.39606 9.81786 4.39606 9.65217V4.42984C4.39606 3.32527 5.29149 2.42984 6.39606 2.42984H16.2298C17.3344 2.42984 18.2298 3.32527 18.2298 4.42984V14.8305C18.2298 15.9351 17.3344 16.8305 16.2298 16.8305H12.4701C12.3044 16.8305 12.1701 16.6962 12.1701 16.5305V15.1305C12.1701 14.9648 12.3044 14.8305 12.4701 14.8305H16.2298V4.42984H6.39606V9.65217C6.39606 9.81786 6.26174 9.95217 6.09606 9.95217Z"
            fill="black"
          />
        </g>
        <path
          d="M6.09606 9.95217H4.69606C4.53037 9.95217 4.39606 9.81786 4.39606 9.65217V4.42984C4.39606 3.32527 5.29149 2.42984 6.39606 2.42984H16.2298C17.3344 2.42984 18.2298 3.32527 18.2298 4.42984V14.8305C18.2298 15.9351 17.3344 16.8305 16.2298 16.8305H12.4701C12.3044 16.8305 12.1701 16.6962 12.1701 16.5305V15.1305C12.1701 14.9648 12.3044 14.8305 12.4701 14.8305H16.2298V4.42984H6.39606V9.65217C6.39606 9.81786 6.26174 9.95217 6.09606 9.95217Z"
          fill={fill}
        />
        <g filter="url(#filter2_d_102_33)">
          <path
            d="M9 10.1V9.4C9 9.17909 9.17909 9 9.4 9H13.0784C13.2993 9 13.4784 9.17909 13.4784 9.4V10.1C13.4784 10.3209 13.2993 10.5 13.0784 10.5H9.4C9.17909 10.5 9 10.3209 9 10.1Z"
            fill="black"
          />
        </g>
        <path
          d="M9 10.1V9.4C9 9.17909 9.17909 9 9.4 9H13.0784C13.2993 9 13.4784 9.17909 13.4784 9.4V10.1C13.4784 10.3209 13.2993 10.5 13.0784 10.5H9.4C9.17909 10.5 9 10.3209 9 10.1Z"
          fill={fill}
        />
        <g filter="url(#filter3_d_102_33)">
          <path
            d="M9 7.1V6.4C9 6.17909 9.17909 6 9.4 6H13.0784C13.2993 6 13.4784 6.17909 13.4784 6.4V7.1C13.4784 7.32091 13.2993 7.5 13.0784 7.5H9.4C9.17909 7.5 9 7.32091 9 7.1Z"
            fill="black"
          />
        </g>
        <path
          d="M9 7.1V6.4C9 6.17909 9.17909 6 9.4 6H13.0784C13.2993 6 13.4784 6.17909 13.4784 6.4V7.1C13.4784 7.32091 13.2993 7.5 13.0784 7.5H9.4C9.17909 7.5 9 7.32091 9 7.1Z"
          fill={fill}
        />
        <g filter="url(#filter4_d_102_33)">
          <path
            d="M9 12.85V12.15C9 11.9291 9.17909 11.75 9.4 11.75H13.0784C13.2993 11.75 13.4784 11.9291 13.4784 12.15V12.85C13.4784 13.0709 13.2993 13.25 13.0784 13.25H9.4C9.17909 13.25 9 13.0709 9 12.85Z"
            fill="black"
          />
        </g>
        <path
          d="M9 12.85V12.15C9 11.9291 9.17909 11.75 9.4 11.75H13.0784C13.2993 11.75 13.4784 11.9291 13.4784 12.15V12.85C13.4784 13.0709 13.2993 13.25 13.0784 13.25H9.4C9.17909 13.25 9 13.0709 9 12.85Z"
          fill={fill}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_102_33"
          x="0.462189"
          y="9.33177"
          width="13.3232"
          height="13.5093"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0235294 0 0 0 0 0.615686 0 0 0 0 0.870588 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_102_33"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_102_33"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_102_33"
          x="1.39606"
          y="0.42984"
          width="19.8337"
          height="20.4007"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0235294 0 0 0 0 0.615686 0 0 0 0 0.870588 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_102_33"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_102_33"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_102_33"
          x="6"
          y="7"
          width="10.4784"
          height="7.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0235294 0 0 0 0 0.615686 0 0 0 0 0.870588 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_102_33"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_102_33"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_102_33"
          x="6"
          y="4"
          width="10.4784"
          height="7.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0235294 0 0 0 0 0.615686 0 0 0 0 0.870588 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_102_33"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_102_33"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_102_33"
          x="6"
          y="9.75"
          width="10.4784"
          height="7.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0235294 0 0 0 0 0.615686 0 0 0 0 0.870588 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_102_33"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_102_33"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_102_33">
          <rect width="22" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
