import React, { useContext, useEffect, useState } from "react";
import { message, notification, Modal } from "antd";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { Platform, UserRoles } from "constants/roles";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { CaregiverRoutes } from "./CaregiverRoutes";
import { MobileRoutes } from "./MobileRoutes";
import { PatientRoutes } from "./PatientRoutes";
import { ProviderRoutes } from "./ProviderRoutes";
import {
  PatientListContext,
  PatientListContextState
} from "contextApis/patientsListContext";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";

export const RoutesContainer: React.FC = () => {
  const userContext = useContext<UserContextState>(UserContext);
  const patientListContext =
    useContext<PatientListContextState>(PatientListContext);
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(
    null
  );
  const patientContext = useContext<PatientContextState>(PatientContext);

  const { data } = useQuery("userData", async () => {
    return await createApiClient().getLoggedInUserData();
  });

  const userOrg =
    data && Array.isArray(data?.userOrg) ? data.userOrg[0] : undefined;

  useEffect(() => {
    patientContext.setOrgId(userOrg);
  }, [userOrg]);

  const userRole = sessionStorage.getItem("userRole");
  const userId = sessionStorage.getItem("userId");
  const platform = sessionStorage.getItem("platform");

  //Added if check for mobile platform

  const { refetch: checkForActiveMeeting } = useQuery(
    "active-meeting",
    async () => {
      return await createApiClient().activeMeeting(userId ? userId : "");
    },
    {
      enabled: false,
      onSuccess: data => {
        if (typeof data !== "string" && data.MeetingId) {
          setModalContent(
            <>
              <div style={{ fontWeight: "600" }}>
                You have been invited to join a meeting by{" "}
                {data.user_info.first_name + " " + data.user_info.last_name},
                click below to join:
              </div>
              ), duration: 1200, description: (
              <AppButton
                buttonContent="Join Meeting"
                type={ButtonType.Primary}
                onClick={() => {
                  meetingContext.setTargetUser(
                    data.user_info.first_name + " " + data.user_info.last_name
                  );
                  meetingContext.setJoinMeetingID(data.MeetingId);
                  meetingContext.setCurrentPage(1);
                  meetingContext.maximize(true);
                  setModalVisible(false);
                }}
              />
            </>
          );
          setModalVisible(true);
        }
      },
      onError: error => {
        if (platform === Platform.MOBILE) return;
        setModalContent(
          <div>
            There was a problem checking for active meetings. Please try again
            later.
          </div>
        );
        setModalVisible(true);
      }
    }
  );

  useEffect(() => {
    if (userRole === UserRoles.PATIENT) {
      checkForActiveMeeting();
    }
  }, []);

  useEffect(() => {
    if (data) {
      userContext.setLoggedInUserData(data);
    }
  }, [data]);

  return (
    <>
      {platform === Platform.MOBILE ? (
        <MobileRoutes />
      ) : userRole === UserRoles.PATIENT ? (
        <PatientRoutes />
      ) : userRole === UserRoles.CAREGIVER ? (
        <CaregiverRoutes />
      ) : (
        <ProviderRoutes />
      )}
      <Modal
        title="Meeting Invitation"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {modalContent}
      </Modal>
    </>
  );
};

// import React, { useContext, useEffect } from "react";
// import { message, notification } from "antd";
// import { useQuery } from "react-query";
// import { createApiClient } from "apiClient";
// import { AppButton } from "components/button";
// import { ButtonType } from "components/button/appButton";
// import { Platform, UserRoles } from "constants/roles";
// import {
//   MeetingContextState,
//   MeetingModalContext
// } from "contextApis/meetingModalContext";
// import { UserContext, UserContextState } from "contextApis/userContext";
// import { CaregiverRoutes } from "./CaregiverRoutes";
// import { MobileRoutes } from "./MobileRoutes";
// import { PatientRoutes } from "./PatientRoutes";
// import { ProviderRoutes } from "./ProviderRoutes";

// export const RoutesContainer: React.FC = () => {
//   const userContext = useContext<UserContextState>(UserContext);
//   const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

//   const { data } = useQuery("userData", async () => {
//     return await createApiClient().getLoggedInUserData();
//   });

//   const userRole = sessionStorage.getItem("userRole");
//   const userId = sessionStorage.getItem("userId");
//   const platform = sessionStorage.getItem("platform");

//   //Added if check for mobile platform
//   console.log("<<<<<<userId>>>>>>>", userId);
//   console.log("<<<<<<userRole>>>>>>>", userRole);
//   console.log("<<<<<<platform>>>>>>>", platform);

//   const { refetch: checkForActiveMeeting } = useQuery(
//     "active-meeting",
//     async () => {
//       return await createApiClient().activeMeeting(userId ? userId : "");
//     },
//     {
//       enabled: false,
//       onSuccess: data => {
//         if (typeof data !== "string" && data.MeetingId) {
//           notification.open({
//             message: (
//               <div style={{ fontWeight: "600" }}>
//                 You have been invited to join a meeting by{" "}
//                 {data.user_info.first_name + " " + data.user_info.last_name},
//                 click below to join:
//               </div>
//             ),
//             duration: 1200,

//             description: (
//               <AppButton
//                 buttonContent="Join Meeting"
//                 type={ButtonType.Primary}
//                 onClick={() => {
//                   meetingContext.setTargetUser(
//                     data.user_info.first_name + " " + data.user_info.last_name
//                   ); //Change to
//                   meetingContext.setJoinMeetingID(data.MeetingId);
//                   meetingContext.setCurrentPage(1);
//                   meetingContext.maximize(true);
//                 }}
//               />
//             )
//           });
//         }
//       },
//       onError: error => {
//         console.log("########ERROR#########", error);
//         if (platform === Platform.MOBILE) return;
//         message.error("There was a problem checking for active meetings.");
//       }
//     }
//   );

//   useEffect(() => {
//     if (userRole === UserRoles.PATIENT) {
//       checkForActiveMeeting();
//     }
//   }, []);

//   useEffect(() => {
//     if (data) {
//       userContext.setLoggedInUserData(data);
//     }
//   }, [data]);

//   return platform === Platform.MOBILE ? (
//     <MobileRoutes />
//   ) : userRole === UserRoles.PATIENT ? (
//     <PatientRoutes />
//   ) : userRole === UserRoles.CAREGIVER ? (
//     <CaregiverRoutes />
//   ) : (
//     <ProviderRoutes />
//   );
// };
