import React from "react";
import { useContext } from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { PatientProfileData } from "sharedTypes";
import { PatientProfilePage } from "./patientProfile";

export const PatientProfile: React.FC = () => {
  const patientContext = useContext<PatientContextState>(PatientContext);
  const callPatientProfileAPI = patientContext.patientData ? true : false;
  const { data, isFetching, isLoading } = useQuery<PatientProfileData>(
    "patient-profile",
    async () => {
      return await createApiClient().getPatientProfile(
        patientContext.patientData?.id.toString() || "",
        true
      );
    },
    { enabled: callPatientProfileAPI }
  );
  return <PatientProfilePage loading={isFetching || isLoading} data={data} />;
};
