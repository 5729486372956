import React, { useContext, useMemo } from "react";
import styled from "styled-components";

import { AppAvatar } from "components/avatar";
import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import Table, { ColumnsType } from "antd/lib/table";
import { TableMessageIcon } from "svgIcons/tableMessageIcon";
import { FilterDisplay } from "components/filterDisplay";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { ChannelData, GetUserChannelsResponse, UserInfoMap } from "sharedTypes";
import { UserContext, UserContextState } from "contextApis/userContext";
import moment from "moment";
import { Message, SelectedConversationUserInfo } from "./messages";
import { Spin } from "antd";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { CircleIndicator } from "components/circleIndicator";
import { lowerCaseRemoveSpace } from "helpers/utils";

interface AllMessagesTableProps {
  messageType: Message;
  selectedConversationUserInfo: SelectedConversationUserInfo | null;
  setSelectedConversationUserInfo: (item: {
    userInfo: UserInfoMap;
    channel: ChannelData;
  }) => void;
  setShowMessages(show: boolean): void;
  readonly searchNameValue: string;
  readonly searchSpecialtyValue: string;
  readonly allUserChannelsListData: GetUserChannelsResponse | undefined;
  readonly isLoadingAllUserChannelsList: boolean;
  readonly isAllUserChannelsListError: unknown;
  readonly isAllUserChannelsListRefetchLoading: boolean;
}

export const AllMessagesTable: React.FC<AllMessagesTableProps> = ({
  messageType,
  selectedConversationUserInfo,
  setSelectedConversationUserInfo,
  setShowMessages,
  searchNameValue,
  searchSpecialtyValue,
  allUserChannelsListData,
  isLoadingAllUserChannelsList,
  isAllUserChannelsListError,
  isAllUserChannelsListRefetchLoading
}) => {
  const userContext = useContext<UserContextState>(UserContext);

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const unreadMessageNotifications = useMemo(() => {
    const unseenNotifications =
      notificationsContext.filterMessageNotificationsByStatus(
        notificationsContext.notifications?.messages || [],
        "unread"
      );
    return unseenNotifications;
  }, [notificationsContext.notifications?.messages]);

  const loggedInUsername = userContext.userData?.userName || "";

  const compareNames = (a: any, b: any) => {
    const aUserInfo = a.user2_uname
      ? a.user2_uname === loggedInUsername
        ? allUserChannelsListData?.user_info[a.user1_uname]
        : allUserChannelsListData?.user_info[a.user2_uname]
      : allUserChannelsListData?.user_info[a.pat_uname];

    const bUserInfo = b.user2_uname
      ? b.user2_uname === loggedInUsername
        ? allUserChannelsListData?.user_info[b.user1_uname]
        : allUserChannelsListData?.user_info[b.user2_uname]
      : allUserChannelsListData?.user_info[b.pat_uname];

    if (aUserInfo && bUserInfo) {
      const aUserFirstName = lowerCaseRemoveSpace(aUserInfo.first_name);
      const aUserLastName = lowerCaseRemoveSpace(aUserInfo.last_name);

      const bUserFirstName = lowerCaseRemoveSpace(bUserInfo.first_name);
      const bUserLastName = lowerCaseRemoveSpace(bUserInfo.last_name);

      return aUserLastName === bUserLastName
        ? aUserFirstName.localeCompare(bUserFirstName)
        : aUserLastName.localeCompare(bUserLastName);
    } else {
      return 0;
    }
  };

  const columns: ColumnsType<ChannelData> = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (_, row: ChannelData) => {
        const userInfo = row.user2_uname
          ? row.user2_uname === loggedInUsername
            ? allUserChannelsListData?.user_info[row.user1_uname]
            : allUserChannelsListData?.user_info[row.user2_uname]
          : allUserChannelsListData?.user_info[row.pat_uname];
        return (
          <AppAvatar
            imageSrc={""}
            size="large"
            mainContent={`${userInfo?.last_name || ""}, ${
              userInfo?.first_name || ""
            } ${userInfo?.degree ? userInfo?.degree : ""}`}
            subText={userInfo?.specialty || ""}
            additionalNote={row.latest_message || ""}
          />
        );
      },
      width: "35%",
      sorter: (a, b) => compareNames(a, b),
      showSorterTooltip: { title: "Sort alphabetically" },
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: null
    },
    {
      title: "Subject",
      key: "subject",
      dataIndex: "subject",
      render: (_, row: ChannelData) => {
        const subjectName = row.user2_uname
          ? `${allUserChannelsListData?.user_info[row.pat_uname].first_name} ${
              allUserChannelsListData?.user_info[row.pat_uname].last_name
            }`
          : "";
        return <RowStyle>{subjectName ? `Re: ${subjectName}` : ""}</RowStyle>;
      },
      width: "20%"
    },
    {
      title: "Date of Last Message",
      key: "dateOfLastMessage",
      dataIndex: "dateOfLastMessage",
      render: (_, row: ChannelData) => {
        return (
          <RowStyle>
            <DateTimeFormat
              date={Number(
                moment(
                  moment.utc(row.latest_message_timestamp).toDate(),
                  "YYYY/MM/DD, HH:mm:ss"
                ).valueOf()
              )}
            />
          </RowStyle>
        );
      },
      width: "35%",
      sorter: (a, b) => {
        const timestampA = a.latest_message_timestamp
          ? a.latest_message_timestamp
          : 1;
        const timestampB = b.latest_message_timestamp
          ? b.latest_message_timestamp
          : 1;
        return moment(timestampA).valueOf() - moment(timestampB).valueOf();
      },
      showSorterTooltip: { title: "Sort by last message date" },
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend"
    },
    {
      key: "actions",
      dataIndex: "actions",
      render: (_, row: ChannelData) => {
        const unreadMessages = unreadMessageNotifications.filter(
          item => row.channel_name === item.channel_name
        );

        return (
          <div style={{ display: "grid", placeItems: "center" }}>
            <span
              onClick={() => {
                setSelectedConversationUserInfo({
                  userInfo: allUserChannelsListData?.user_info || {},
                  channel: row
                });
                setShowMessages(true);
              }}
              style={{ cursor: "pointer" }}
            >
              {unreadMessages.length > 0 && (
                <CircleIndicatorWrapper>
                  <CircleIndicator
                    outerColor={"#fbddb7"}
                    innerColor={"#f18e13"}
                  />
                </CircleIndicatorWrapper>
              )}
              <TableMessageIcon />
            </span>
          </div>
        );
      },
      width: "10%"
    }
  ];

  const filteredChannelList = useMemo<ChannelData[]>(() => {
    if (allUserChannelsListData) {
      const messageTypeFilteredChannelList =
        allUserChannelsListData.channel_arns.filter(channel => {
          if (messageType === Message.AllMessages) {
            return true;
          }
          if (messageType === Message.DirectMessages) {
            return channel.is_patient_linked_channel === 0;
          }
          return channel.is_patient_linked_channel === 1;
        });
      if (searchNameValue) {
        return messageTypeFilteredChannelList.filter(channel => {
          const userInfo = channel.user2_uname
            ? allUserChannelsListData.user_info[channel.user2_uname]
            : allUserChannelsListData.user_info[channel.pat_uname];
          const subjectName = `${
            allUserChannelsListData.user_info[channel.pat_uname].first_name
          } ${allUserChannelsListData.user_info[channel.pat_uname].last_name}`;
          if (
            userInfo.first_name
              .toLowerCase()
              .includes(searchNameValue.toLowerCase()) ||
            userInfo.last_name
              .toLowerCase()
              .includes(searchNameValue.toLowerCase()) ||
            subjectName.toLowerCase().includes(searchNameValue.toLowerCase())
          ) {
            return true;
          }
          return false;
        });
      }
      if (searchSpecialtyValue) {
        return messageTypeFilteredChannelList.filter(channel => {
          const userInfo = channel.user2_uname
            ? allUserChannelsListData.user_info[channel.user2_uname]
            : allUserChannelsListData.user_info[channel.pat_uname];
          if (
            userInfo.specialty
              ?.toLowerCase()
              .includes(searchSpecialtyValue.toLowerCase()) ||
            false // added error boundary for cases where speciality isnt present
          ) {
            return true;
          }
          return false;
        });
      }
      return messageTypeFilteredChannelList;
    }
    return [];
  }, [
    allUserChannelsListData,
    searchNameValue,
    searchSpecialtyValue,
    messageType
  ]);

  return (
    <AppCard cardHeight="fit-content" cardWidth="100%">
      <FilterDisplay
        value1={searchNameValue}
        value2={searchSpecialtyValue}
        value3={messageType}
      />
      {isAllUserChannelsListError ? (
        <CommonErrorMessage message="There was an error fetching the messages list" />
      ) : isLoadingAllUserChannelsList ? (
        <span style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <Spin />
        </span>
      ) : (
        <Table<ChannelData>
          columns={columns}
          dataSource={filteredChannelList}
          rowKey={record => record.channel_arn}
          size="small"
          pagination={false}
          scroll={{ y: "60vh" }}
          loading={
            isLoadingAllUserChannelsList || isAllUserChannelsListRefetchLoading
          }
          rowClassName={record =>
            record.channel_arn ===
            selectedConversationUserInfo?.channel.channel_arn
              ? "care-team-member-selected"
              : ""
          }
        />
      )}
    </AppCard>
  );
};

const RowStyle = styled.div`
  font-size: 12px;
  color: ${Colors.Black};
`;

const CircleIndicatorWrapper = styled.span`
  position: absolute;
  margin-left: 7px;
`;
