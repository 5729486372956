import React, { useContext, useLayoutEffect, useMemo } from "react";
import styled from "styled-components";
import Table, { ColumnsType } from "antd/lib/table";

import { Colors } from "helpers/colors";
import { AppCard } from "components/card";
import { TableFilters } from "./tableFilters";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import { StandingPostureIcon } from "./standingPostureIcon";
import { SeatedPostureIcon } from "./seatedPostureIcon";
import { FilterDisplay } from "components/filterDisplay";
import { BloodPressureDetailsDropdown } from "./bloodPressureDetailsDropdown";
import { BloodPressureVitals } from "sharedTypes";
import { LyingDownIcon } from "./LyingDownIcon";
import moment from "moment";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { useParams } from "react-router";
import { CircleIndicator } from "components/circleIndicator";
import { useMutation, useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { message } from "antd";
import { UserRoles } from "constants/roles";
import { getAccessToken } from "helpers/utils";
import { SunIcon } from "./sunIcon";
import { MoonIcon } from "./moonIcon";
import { BloodPressureIcon } from "./bloodPressureIcon";
import { HeartRateIcon } from "./heartRateIcon";

interface BloodPressureTableProps {
  readonly data: BloodPressureVitals[];
  readonly loading: boolean;
  readonly filter: string;
  setTypeFilter(type: string): void;
  readonly typeFilter: string;
  setShowVitalsCharts(type: string): void;
  readonly selectedTab: string;
}

export const BloodPressureTable: React.FC<BloodPressureTableProps> = ({
  data,
  loading,
  filter,
  setTypeFilter,
  typeFilter,
  setShowVitalsCharts,
  selectedTab
}) => {
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const { id: patientId } = useParams();

  const userId = sessionStorage.getItem("userId") || "";

  let sortedData = data;

  if (data[0]) {
    sortedData = data.sort((item1, item2) => {
      return (
        moment(item2.bp_taken_on, "MM-DD-YYYY hh:mm:ss").valueOf() -
        moment(item1.bp_taken_on, "MM-DD-YYYY hh:mm:ss").valueOf()
      );
    });
  }

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const { refetch: getNotificationsList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        userIsPatient
          ? UserRoles.PATIENT
          : userIsCaregiver
          ? UserRoles.CAREGIVER
          : UserRoles.PROVIDER
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      }
    }
  );

  const { mutate: markNotification } = useMutation(
    "mark-notification",
    createApiClient().putNotifications,
    {
      onSuccess: () => {
        getNotificationsList();
      },
      onError: () => {
        message.error("There was a problem updating notifications.");
      }
    }
  );

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const unreadNotifications = useMemo(() => {
    return notificationsContext.filterNotificationsByStatusAndPatient(
      notificationsContext.notifications?.remote_vitals || [],
      userIsPatient ? userId : patientId || "",
      "unread"
    );
  }, [notificationsContext.notifications?.remote_vitals]);

  useLayoutEffect(() => {
    return () => {
      if (getAccessToken()) {
        unreadNotifications.forEach(item => {
          markNotification({
            notificationId: item?.notification_id || "",
            body: { status: "read", type: "remote_vitals" }
          });
        });
      }
    };
  }, [unreadNotifications]);

  const convertUTCDateToLocal = (dateString: any) => {
    const localDateTime = moment.utc(dateString).local();
    const formattedDateTime = moment(
      localDateTime,
      "MMM/DD/YYYY hh:mm A",
      true
    );
    return formattedDateTime;
  };

  const convertUTCDateToLocalWithTime = (dateString: any) => {
    const date = moment.utc(dateString).local();
    const hour = date.hour();
    if (hour >= 0 && hour < 12) {
      return "AM";
    } else if (hour >= 12 && hour < 24) {
      return "PM";
    } else {
      return "Unknown";
    }
  };

  const convertDateToLocal = (value: string | Date, format?: string) => {
    return moment.utc(value, format).local();
  };

  const convertUTCDateToLocals = (dateString: any) => {
    const [datePart, timePartWithPeriod] = dateString.split(" ");
    const [timePart, period] = [
      timePartWithPeriod.slice(0, -2),
      timePartWithPeriod.slice(-2)
    ];

    // Convert the time to 24-hour format if it's in 12-hour format with AM/PM
    let [hours]: any = timePart.split(":").map(Number);
    const [, minutes] = timePart.split(":").map(Number);
    if (period === "PM" && hours < 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;

    // Reconstruct the date
    const [day, month, year]: any = datePart.split("-").map(Number);
    const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes));

    // Format the UTC date to local time
    const options: any = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true
    };

    const localDateString = utcDate.toLocaleString("en-US", options);

    return localDateString.split("at").join("");
  };

  const columns: ColumnsType<BloodPressureVitals> = [
    {
      title: "Date Submitted",
      key: "reported_on",
      dataIndex: "reported_on",
      render: (date: number, record) => {
        const unreadItem = unreadNotifications.find(
          item => String(item.item_id) === String(record.key_id)
        );

        return (
          <DateStyle>
            {unreadItem && (
              <CircleIndicatorWrapper>
                <CircleIndicator
                  outerColor={"#fbddb7"}
                  innerColor={"#f18e13"}
                />
              </CircleIndicatorWrapper>
            )}
            {record.reported_on ? (
              <DateTimeFormat
                date={convertDateToLocal(
                  record.reported_on,
                  "M/D/YYYY H:mm:ss" || "M/D/YYYY" || "MM-DD-YYYY hh:mm A"
                )}
              />
            ) : (
              <>
                <DateTimeFormat
                  date={moment
                    .utc(record.bp_taken_on, "MM-DD-YYYY hh:mm A")
                    .local()}
                />
                {/* <>{convertUTCDateToLocals(record?.bp_taken_on)}</> */}
              </>
            )}
          </DateStyle>
        );
      },
      width: "18%"
    },
    {
      title: "Date Taken",
      key: "bp_taken_on",
      dataIndex: "bp_taken_on",
      render: (date: number, record) => {
        if (record.reported_on) {
          if (!record.bp_taken_date) {
            return (
              <DateStyle>
                <div style={{ display: "flex", gap: 8 }}>
                  <HeartRateIcon />
                  <DateTimeFormat
                    date={convertDateToLocal(
                      record.hr_taken_date,
                      "M/D/YYYY H:mm:ss" || "M/D/YYYY" || "MM-DD-YYYY hh:mm A"
                    )}
                    hideTime={record.reported_on ? true : false}
                  />
                </div>
              </DateStyle>
            );
          } else if (
            convertUTCDateToLocal(record.bp_taken_on) ===
            convertUTCDateToLocal(record.hr_taken_date)
          ) {
            return (
              <DateStyle>
                <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                  <span
                    style={{ display: "flex", gap: 8, flexDirection: "column" }}
                  >
                    <BloodPressureIcon />
                    <HeartRateIcon />
                  </span>
                  <>{convertUTCDateToLocals(record.bp_taken_on)}</>
                  <DateTimeFormat
                    date={moment
                      .utc(record.bp_taken_on, "MM-DD-YYYY hh:mm A")
                      .local()}
                    hideTime={record.reported_on ? true : false}
                  />
                </div>
              </DateStyle>
            );
          } else {
            return (
              <DateStyle>
                {record.bp_taken_on && (
                  <div style={{ display: "flex", gap: 8 }}>
                    <BloodPressureIcon />
                    {/* <>{convertUTCDateToLocals(record.bp_taken_on)}</> */}
                    <DateTimeFormat
                      date={moment
                        .utc(record.bp_taken_on, "MM-DD-YYYY hh:mm A")
                        .local()}
                      hideTime={record.reported_on ? true : false}
                    />
                  </div>
                )}

                {record.hr_taken_date && (
                  <>
                    <div style={{ display: "flex", gap: 8, marginTop: 5 }}>
                      <HeartRateIcon />
                      <DateTimeFormat
                        date={convertDateToLocal(
                          record.hr_taken_date,
                          "M/D/YYYY H:mm:ss" ||
                            "M/D/YYYY" ||
                            "MM-DD-YYYY hh:mm A"
                        )}
                        hideTime={record.reported_on ? true : false}
                      />
                    </div>
                  </>
                )}
              </DateStyle>
            );
          }
        } else {
          return (
            <div
              style={{
                display: "flex",
                gap: 8,
                marginTop: 5,
                alignItems: "center"
              }}
            >
              <span
                style={{ display: "flex", gap: 8, flexDirection: "column" }}
              >
                <BloodPressureIcon />
                <HeartRateIcon />
              </span>
              <DateTimeFormat
                date={moment
                  .utc(record.bp_taken_on, "MM-DD-YYYY hh:mm A")
                  .local()}
              />
              {/* {convertUTCDateToLocals(record.bp_taken_on)} */}
            </div>
          );
        }
      },
      width: "24%"
    },
    {
      title: "Blood Pressure",
      key: "pm_bp",
      dataIndex: "pm_bp",
      width: "20%",
      render: (text: string, record: any) => {
        if (record.bp_taken_on) {
          return (
            <BPDiv>
              {record.am_bp !== "" && (
                <SunDiv>
                  <SunIcon />
                </SunDiv>
              )}
              {record.pm_bp !== "" && (
                <MoonDiv>
                  <MoonIcon />
                </MoonDiv>
              )}
              <AMPMStyled>
                {record.am_bp !== "" ? record.am_bp : record.pm_bp}
              </AMPMStyled>
            </BPDiv>
          );
        }
      }
    },
    {
      title: "Posture",
      key: "pos",
      dataIndex: "pos",
      width: "12%",
      render: (posture: string) => (
        <PostureCol>
          {posture === "Standing" && <StandingPostureIcon />}
          {posture === "Sitting" && <SeatedPostureIcon />}
          {posture === "Lying" && (
            <span style={{ marginLeft: "-5px" }}>
              <LyingDownIcon />
            </span>
          )}
        </PostureCol>
      )
    },
    {
      title: "Heart Rate",
      key: "h_pulse",
      dataIndex: "h_pulse",
      width: "12%",
      render: (heartRate: string, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <GroupCol>{heartRate}</GroupCol>
          </div>
        );
      }
    },
    {
      title: "Symptoms",
      key: "symptoms",
      dataIndex: "symptoms",
      width: "25%",
      render: (symptoms: string) => <GroupCol>{symptoms}</GroupCol>
    },
    {
      key: "actions",
      dataIndex: "actions",
      render: (_, row) => (
        <BloodPressureDetailsDropdown
          reportedBy={row.reported_by}
          reportedOn={row.bp_taken_on}
          bloodPressureVitals={row}
          typeFilter={typeFilter}
        />
      ),
      width: "5%"
    }
  ];

  return (
    <AppCard
      cardHeight="fit-content"
      cardWidth="100%"
      style={{
        borderRadius: "0px 0px 8px 8px",
        marginTop: "-2px"
      }}
    >
      <FilterWrapper>
        <FilterDisplay value1={`${filter}`} />
      </FilterWrapper>
      <TableFilters
        setTypeFilter={setTypeFilter}
        typeFilter={typeFilter}
        setShowVitalsCharts={setShowVitalsCharts}
        selectedTab={selectedTab}
        loading={loading}
      />
      <Table<BloodPressureVitals>
        columns={columns}
        dataSource={sortedData}
        pagination={false}
        rowKey={record => Number(record.key_id)}
        scroll={{ y: "43vh" }}
        size="middle"
        loading={loading}
        data-testid="blood-pressure-table"
      />
    </AppCard>
  );
};

const FilterWrapper = styled.div`
  margin-top: -15px;
`;

const BPDiv = styled.div`
  display: flex;
`;

const SunDiv = styled.div`
  padding-right: 5px;
  margin-left: -4px;
`;

const MoonDiv = styled.div`
  padding-right: 8px;
`;

const AMPMStyled = styled.div`
  font-weight: 800;
  font-size: 14px;
`;

const DateStyle = styled.div`
  font-size: 12px;
  color: ${Colors.Grey};
`;

const PostureCol = styled.div`
  margin-left: 10px;
`;

const GroupCol = styled.div`
  font-size: 12px;
  color: ${Colors.Black};
`;

const CircleIndicatorWrapper = styled.span`
  position: absolute;
  top: 4px;
  left: -5px;
`;
