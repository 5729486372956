import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Col from "antd/lib/col";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";

import { CheckboxMenu } from "components/checkboxMenu";
import { Dropdown } from "components/dropDownBox";
import { Colors } from "helpers/colors";
import { FilterIcon } from "./medicationsFilter";
import {
  MedicationContext,
  MedicationContextState
} from "contextApis/medicationContext";

interface PrescriptionReasonCheckboxDropdownProps {
  setShowDropdown(show: boolean): void;
  readonly showDropdown: boolean;
}

export const DiscontinuedReasonsCheckboxDropdown: React.FC<
  PrescriptionReasonCheckboxDropdownProps
> = ({ setShowDropdown, showDropdown }) => {
  const checkboxMenuContext =
    useContext<MedicationContextState>(MedicationContext);
  const [applied, setApplied] = useState(false);

  const [localMenuOneItems, setLocalMenuOneItems] = useState<
    { code: string; desc: string; detail_desc: string; checked?: boolean }[]
  >(
    checkboxMenuContext.discontinuedMenuOneReasons.reasons.map(item => {
      return {
        code: item.id,
        desc: item.reason,
        detail_desc: "",
        checked: item.checked
      };
    })
  );

  const [localMenuTwoItems, setLocalMenuTwoItems] = useState<
    { code: string; desc: string; detail_desc: string; checked?: boolean }[]
  >(
    checkboxMenuContext.discontinuedMenuTwoReasons.reasons.map(item => {
      return {
        code: item.id,
        desc: item.reason,
        detail_desc: "",
        checked: item.checked
      };
    })
  );

  const applyFilters = () => {
    setApplied(true);
    checkboxMenuContext.setDiscontinuedMenuOneReasons({
      reasons: localMenuOneItems.map(item => {
        return {
          id: item.code,
          reason: item.desc,
          checked: item.checked
        };
      })
    });
    checkboxMenuContext.setDiscontinuedMenuTwoReasons({
      reasons: localMenuTwoItems.map(item => {
        return {
          id: item.code,
          reason: item.desc,
          checked: item.checked
        };
      })
    });
    setShowDropdown(false);
  };

  useEffect(() => {
    //On close dropdown -> set menu to initial state if nothing is applied
    if (!applied && !showDropdown) {
      setLocalMenuOneItems(
        checkboxMenuContext.discontinuedMenuOneReasons.reasons.map(item => {
          return {
            code: item.id,
            desc: item.reason,
            detail_desc: "",
            checked: item.checked
          };
        })
      );
      setLocalMenuTwoItems(
        checkboxMenuContext.discontinuedMenuTwoReasons.reasons.map(item => {
          return {
            code: item.id,
            desc: item.reason,
            detail_desc: "",
            checked: item.checked
          };
        })
      );
    }
    if (!showDropdown) {
      setApplied(false);
    }
  }, [showDropdown]);

  return (
    <Dropdown
      arrow
      borderradius="8px"
      trigger={["click"]}
      placement="bottomRight"
      destroyPopupOnHide
      dropDownMenuHeader={
        <DiagnosisDropdownHeader>
          <TitleCol span={12}>Reason Discontinued</TitleCol>
        </DiagnosisDropdownHeader>
      }
      dropDownMenuBody={
        <Row style={{ backgroundColor: Colors.GhostWhite }}>
          <MenuCol>
            <CheckboxMenu
              menuItems={localMenuOneItems}
              getCheckedItems={setLocalMenuOneItems}
            />
          </MenuCol>
          <div
            style={{
              borderLeft: `1px solid ${Colors.Lavender}`,
              height: "312px",
              // position: "absolute",
              left: "50%",
              marginLeft: "0px",
              top: 83,
              width: "1px"
            }}
          />
          <MenuCol>
            <CheckboxMenu
              menuItems={localMenuTwoItems}
              getCheckedItems={setLocalMenuTwoItems}
            />
          </MenuCol>
        </Row>
      }
      dropDownMenuFooter={
        <DiagnosisDropdownFooter>
          <Col
            style={{ textAlign: "right", width: "100%", paddingRight: "10px" }}
          >
            <Space>
              <AppButton
                type={ButtonType.Secondary}
                buttonContent="Cancel"
                onClick={() => {
                  setShowDropdown(false);
                  setLocalMenuOneItems(
                    checkboxMenuContext.discontinuedMenuOneReasons.reasons.map(
                      item => {
                        return {
                          code: item.id,
                          desc: item.reason,
                          detail_desc: "",
                          checked: item.checked
                        };
                      }
                    )
                  );
                  setLocalMenuTwoItems(
                    checkboxMenuContext.discontinuedMenuTwoReasons.reasons.map(
                      item => {
                        return {
                          code: item.id,
                          desc: item.reason,
                          detail_desc: "",
                          checked: item.checked
                        };
                      }
                    )
                  );
                }}
                style={{ width: "120px" }}
              />
              <AppButton
                type={ButtonType.Primary}
                buttonContent="Apply"
                onClick={() => applyFilters()}
                style={{ width: "120px" }}
              />
            </Space>
          </Col>
        </DiagnosisDropdownFooter>
      }
      visible={showDropdown}
      setVisible={setShowDropdown}
    >
      <AppButton
        type={ButtonType.Skeleton}
        buttonContent={
          <Row>
            <FilterIcon />
          </Row>
        }
        onClick={() => setShowDropdown(!showDropdown)}
      />
    </Dropdown>
  );
};

const MenuCol = styled(Col)`
  max-height: 312px;
  overflow: auto;
`;

const TitleCol = styled(Col)`
  padding: 20px;
  padding-left: 17px;
  font-family: Century Gothic Bold;
`;

const DiagnosisDropdownHeader = styled(Row)`
  background: ${Colors.White};
  border-radius: 8px 8px 0px 0px;
  height: 60px;
  font-size: 14px;
  box-shadow: 0 5px 6px -1px rgba(0, 0, 0, 0.05);
  border-bottom: 2px solid ${Colors.Lavender};
`;

const DiagnosisDropdownFooter = styled(Row)`
  background: ${Colors.White};
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid ${Colors.Lavender};
  height: 68px;
  font-size: 14px;
  margin-bottom: 3px;
  display: grid;
  place-items: center;
`;
