import React from "react";
import { Colors } from "helpers/colors";

interface VideoIconProps {
  readonly isActive: boolean;
}

export const VideoIcon: React.FC<VideoIconProps> = ({ isActive }) => {
  return (
    <svg
      width="44px"
      height="44px"
      viewBox="0 0 44 44"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <defs>
        <filter
          x="-28.4%"
          y="-28.4%"
          width="156.8%"
          height="156.8%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feMorphology
            radius="0.5"
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          ></feMorphology>
          <feOffset
            dx="0"
            dy="2"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="shadowInner"
          ></feMorphology>
          <feOffset
            dx="0"
            dy="2"
            in="shadowInner"
            result="shadowInner"
          ></feOffset>
          <feComposite
            in="shadowOffsetOuter1"
            in2="shadowInner"
            operator="out"
            result="shadowOffsetOuter1"
          ></feComposite>
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g
        id="Asset-Artboard-Page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="video-icon-button">
          <g id="Rectangle_203-6-Copy" filter="url(#filter-1)">
            <rect
              id="Rectangle"
              stroke={isActive ? Colors.DodgerBlue : "#B5D264"}
              fill={isActive ? Colors.AliceBlue : "#EFF4DF"}
              x="0.5"
              y="0.5"
              width="43"
              height="43"
              rx="9.5"
            ></rect>
            <path
              d="M33.6992188,17.6894531 C33.8997406,17.861329 34,18.0761706 34,18.3339844 L34,25.1230469 C34,25.3951837 33.8997406,25.6171866 33.6992188,25.7890625 C33.5273429,25.9179694 33.3483082,25.9824219 33.1621094,25.9824219 C32.9902335,25.9824219 32.832683,25.9394536 32.6894531,25.8535156 L30.046875,24.15625 L30.046875,27.5175781 C30.046875,27.9329448 29.9000666,28.2910141 29.6064453,28.5917969 C29.3128241,28.8925796 28.951174,29.0429688 28.5214844,29.0429688 L13.5253906,29.0429688 C13.110024,29.0429688 12.7519546,28.8925796 12.4511719,28.5917969 C12.1503891,28.2910141 12,27.9329448 12,27.5175781 L12,15.9824219 C12,15.5670552 12.1503891,15.2089859 12.4511719,14.9082031 C12.7519546,14.6074204 13.110024,14.4570313 13.5253906,14.4570313 L28.5214844,14.4570313 C28.951174,14.4570313 29.3128241,14.6074204 29.6064453,14.9082031 C29.9000666,15.2089859 30.046875,15.5670552 30.046875,15.9824219 L30.046875,19.0214844 L32.7539062,17.5820313 C32.8828131,17.5104163 33.0117181,17.4746094 33.140625,17.4746094 C33.3554698,17.4746094 33.5416659,17.5462232 33.6992188,17.6894531 Z M32.3027344,19.7519531 L30.0898438,20.9335938 L30.0898438,22.1582031 L32.3027344,23.5761719 L32.3027344,19.7519531 Z M28.3496094,16.6699219 C28.3496094,16.526692 28.3030604,16.4049484 28.2099609,16.3046875 C28.1168615,16.2044266 27.9986986,16.1542969 27.8554688,16.1542969 L14.1914063,16.1542969 C14.0624994,16.1542969 13.9479172,16.2044266 13.8476563,16.3046875 C13.7473953,16.4049484 13.6972656,16.526692 13.6972656,16.6699219 L13.6972656,26.8300781 C13.6972656,26.973308 13.7473953,27.0950516 13.8476563,27.1953125 C13.9479172,27.2955734 14.0624994,27.3457031 14.1914063,27.3457031 L27.8554688,27.3457031 C27.9986986,27.3457031 28.1168615,27.2955734 28.2099609,27.1953125 C28.3030604,27.0950516 28.3496094,26.973308 28.3496094,26.8300781 L28.3496094,16.6699219 Z"
              id=""
              stroke="#212B40"
              strokeWidth="0.3"
              fill="#212B40"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
