import React, { useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Checkbox from "antd/lib/checkbox";
import Divider from "antd/lib/divider";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import styled from "styled-components";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";

import { AppCard } from "components/card";
import { MessageRow } from "components/dropDownBox/customStyles";
import { AppTextArea } from "components/inputs";
import { Colors } from "helpers/colors";

enum Options {
  Yes = "Yes",
  No = "No",
  PatientDeclines = "Patinetdeclines"
}

export const PatientDetail: React.FC = () => {
  const [form] = Form.useForm();
  const [clearForm, setClearForm] = useState<boolean>(false);
  const onFinish = () => {
    setClearForm(!clearForm);
  };

  const [notes, setNotes] = useState("");

  const onNotesChange = (value: string) => {
    setNotes(value);
  };

  const onReset = () => setClearForm(!clearForm);

  return (
    <AppCard
      cardHeight="inherit"
      cardWidth="100%"
      style={{
        borderRadius: "0px 0px 8px 8px",
        marginTop: "-2px"
      }}
    >
      <Form name="call records" form={form} onFinish={onFinish}>
        <div>
          <Form.Item name="date" rules={[{ required: true }]}>
            <Col>
              Are you concerned that in the next two months you may not have
              stable housing that you own, rent, or stay in?
              <Row>
                <Radio.Group>
                  <CustomRadio value={Options.Yes}>Yes</CustomRadio>
                  <Divider type="vertical" />
                  <CustomRadio value={Options.No}>No</CustomRadio>
                  <Divider type="vertical" />
                  <Radio value={Options.PatientDeclines}>
                    Patient Declines
                  </Radio>
                </Radio.Group>
              </Row>
            </Col>
          </Form.Item>
          <Form.Item name="date" rules={[{ required: true }]}>
            <Col>
              Are you concerned about not having access to food in the next 2
              months?
              <Row>
                <Radio.Group>
                  <CustomRadio value={Options.Yes}>Yes</CustomRadio>
                  <Divider type="vertical" />
                  <CustomRadio value={Options.No}>No</CustomRadio>
                  <Divider type="vertical" />
                  <Radio value={Options.PatientDeclines}>
                    Patient Declines
                  </Radio>
                </Radio.Group>
              </Row>
            </Col>
          </Form.Item>
          <Form.Item name="date" rules={[{ required: true }]}>
            <Col>
              Do you put off or neglect going to a doctor because of distance or
              transportation?
              <Row>
                <Radio.Group>
                  <CustomRadio value={Options.Yes}>Yes</CustomRadio>
                  <Divider type="vertical" />
                  <CustomRadio value={Options.No}>No</CustomRadio>
                  <Divider type="vertical" />
                  <Radio value={Options.PatientDeclines}>
                    Patient Declines
                  </Radio>
                </Radio.Group>
              </Row>
            </Col>
          </Form.Item>
          <Form.Item name="date" rules={[{ required: true }]}>
            <Col>
              Does access to childcare make it difficult for you to work or
              study?
              <Row>
                <Radio.Group>
                  <CustomRadio value={Options.Yes}>Yes</CustomRadio>
                  <Divider type="vertical" />
                  <CustomRadio value={Options.No}>No</CustomRadio>
                  <Divider type="vertical" />
                  <Radio value={Options.PatientDeclines}>
                    Patient Declines
                  </Radio>
                </Radio.Group>
              </Row>
            </Col>
          </Form.Item>
          <Form.Item name="date" rules={[{ required: true }]}>
            <Col>
              Do you have a job?
              <Row>
                <Radio.Group>
                  <CustomRadio value={Options.Yes}>Yes</CustomRadio>
                  <Divider type="vertical" />
                  <CustomRadio value={Options.No}>No</CustomRadio>
                  <Divider type="vertical" />
                  <Radio value={Options.PatientDeclines}>
                    Patient Declines
                  </Radio>
                </Radio.Group>
              </Row>
            </Col>
          </Form.Item>
          <Form.Item name="date" rules={[{ required: true }]}>
            <Col>
              Are you able to pay your monthly bills regularly?
              <Row>
                <Radio.Group>
                  <CustomRadio value={Options.Yes}>Yes</CustomRadio>
                  <Divider type="vertical" />
                  <CustomRadio value={Options.No}>No</CustomRadio>
                  <Divider type="vertical" />
                  <Radio value={Options.PatientDeclines}>
                    Patient Declines
                  </Radio>
                </Radio.Group>
              </Row>
            </Col>
          </Form.Item>
          <Form.Item name="date" rules={[{ required: true }]}>
            <Col>
              Do you feel physically and emotionally safe in the environment
              that you live in?
              <Row>
                <Radio.Group>
                  <CustomRadio value={Options.Yes}>Yes</CustomRadio>
                  <Divider type="vertical" />
                  <CustomRadio value={Options.No}>No</CustomRadio>
                  <Divider type="vertical" />
                  <Radio value={Options.PatientDeclines}>
                    Patient Declines
                  </Radio>
                </Radio.Group>
              </Row>
            </Col>
          </Form.Item>
          <Form.Item>
            <AppTextArea
              value={notes}
              onChange={onNotesChange}
              label="Notes"
              placeholder="Add Notes"
            />
          </Form.Item>
          <MessageRow fontSize={"14px"}>
            <b>Social Determinants of Health: Action Plan</b>
          </MessageRow>
          <Row justify="space-between">
            <Form.Item>
              <Col span={24}>
                <Checkbox value={false}>Refer to Social Services</Checkbox>
              </Col>
            </Form.Item>
            <Form.Item>
              <Col span={16}>
                <Checkbox value={false}>
                  Refer to Community based Resources
                </Checkbox>
              </Col>
            </Form.Item>

            <Form.Item>
              <Col flex={-1} span={32}>
                <Checkbox value={false}>No Action Needed</Checkbox>
              </Col>
            </Form.Item>
          </Row>
        </div>

        <Form.Item>
          <TasksFooter>
            <ButtonContainer>
              <Col span={18}>
                <Wrapper>
                  <Checkbox /> Add to Care Plan
                </Wrapper>
              </Col>
              <AppButton
                type={ButtonType.Secondary}
                onClick={onReset}
                buttonContent="Cancel"
                style={{ marginRight: 10, width: 120 }}
              />
              <AppButton
                type={ButtonType.Primary}
                onClick={form.submit}
                buttonContent="Save"
                style={{ width: 120 }}
              />
            </ButtonContainer>
          </TasksFooter>
        </Form.Item>
      </Form>
    </AppCard>
  );
};

const CustomRadio = styled(Radio)`
  width: 100px;
`;

const ButtonContainer = styled(Row)`
  padding-top: 14px;
`;

const TasksFooter = styled.div`
  border-top: 1px solid ${Colors.Lavender};
  margin-bottom: -20px;
`;

const Wrapper = styled.div`
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 8px;
  border: 1px solid ${Colors.Lavender};
  background-color: ${Colors.GhostWhite};
  width: fit-content;
  float: inline-start;
  margin-top: 2px;
  margin-right: 8px;
  height: 40px;
`;
