import React, { useContext, useEffect, useState } from "react";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Checkbox from "antd/lib/checkbox";
import styled from "styled-components";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";

import { CheckboxMenu } from "components/checkboxMenu";
import { Dropdown } from "components/dropDownBox";
import { AppSelect } from "components/inputs";
import { Colors } from "helpers/colors";
import { CheckboxMenuCommonInterface } from "sharedTypes";
import {
  PatientListContext,
  PatientListContextState
} from "contextApis/patientsListContext";

interface DiagnosisCheckboxDropdownProps {
  readonly menuOneItems: CheckboxMenuCommonInterface[];
  readonly menuTwoItems: CheckboxMenuCommonInterface[];
  setShowDropdown(show: boolean): void;
  readonly showDropdown: boolean;
  readonly selectedDiagnosisDisplayText: string;
  allDiagnosisChecked: boolean;
  setAllDiagnosisChecked(checked: boolean): void;
  applied: boolean;
  setApplied(checked: boolean): void;
}

export const DiagnosisCheckboxDropdown: React.FC<
  DiagnosisCheckboxDropdownProps
> = ({
  menuOneItems,
  menuTwoItems,
  setShowDropdown,
  showDropdown,
  selectedDiagnosisDisplayText,
  allDiagnosisChecked,
  setAllDiagnosisChecked,
  applied,
  setApplied
}) => {
  const [selectedItemText, setSelectedItemText] = useState("Select Diagnoses");

  const patientListContext =
    useContext<PatientListContextState>(PatientListContext);

  const [localMenuOneItems, setLocalMenuOneItems] = useState(menuOneItems);
  const [localMenuTwoItems, setLocalMenuTwoItems] = useState(menuTwoItems);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setLocalMenuOneItems(menuOneItems);
    setLocalMenuTwoItems(menuTwoItems);
  }, [menuOneItems, menuTwoItems]);

  useEffect(() => {
    const anyMenuOneItemFalse = localMenuOneItems.some(
      (obj: {
        desc: string;
        code: string;
        detail_desc: string;
        checked?: boolean;
      }) => {
        return obj.checked === false;
      }
    );

    const anyMenuTwoItemFalse = localMenuTwoItems.some(
      (obj: {
        desc: string;
        code: string;
        detail_desc: string;
        checked?: boolean;
      }) => {
        return obj.checked === false;
      }
    );

    const allMenuOneItemTrue =
      localMenuOneItems.length > 0 &&
      localMenuOneItems.every(
        (obj: {
          desc: string;
          code: string;
          detail_desc: string;
          checked?: boolean;
        }) => {
          return obj.checked === true;
        }
      );

    const allMenuTwoItemTrue =
      localMenuTwoItems.length > 0 &&
      localMenuTwoItems.every(
        (obj: {
          desc: string;
          code: string;
          detail_desc: string;
          checked?: boolean;
        }) => {
          return obj.checked === true;
        }
      );

    if (anyMenuOneItemFalse || anyMenuTwoItemFalse) {
      setAllDiagnosisChecked(false);
    }

    if (allMenuOneItemTrue && allMenuTwoItemTrue) {
      setAllDiagnosisChecked(true);
    }
  }, [localMenuOneItems, localMenuTwoItems]);

  useEffect(() => {
    if (!applied && !showDropdown) {
      setAllDiagnosisChecked(
        JSON.parse(
          sessionStorage.getItem("patientAllDiagnosisCheckboxMenu") || "[]"
        )
      );
    }

    if (!showDropdown && applied) {
      if (allDiagnosisChecked) {
        setSelectedItemText("All Diagnoses");
      } else if (selectedDiagnosisDisplayText === "") {
        setSelectedItemText("Select Diagnosis");
      } else {
        setSelectedItemText(selectedDiagnosisDisplayText);
      }
    }
  }, [showDropdown, selectedDiagnosisDisplayText, allDiagnosisChecked]);

  const applyFilters = () => {
    let newMenuOneItems = [...localMenuOneItems];
    let newMenuTwoItems = [...localMenuTwoItems];
    if (allDiagnosisChecked) {
      newMenuOneItems = newMenuOneItems.map(item => {
        item.checked = true;
        return item;
      });
      newMenuTwoItems = newMenuTwoItems.map(item => {
        item.checked = true;
        return item;
      });
      patientListContext.setDiagnosesMenuOne(newMenuOneItems);
      patientListContext.setDiagnosesMenuTwo(newMenuTwoItems);
    } else {
      const selectedMenuOneCodes = newMenuOneItems
        .filter(
          (obj: {
            desc: string;
            code: string;
            detail_desc: string;
            checked?: boolean;
          }) => obj.checked
        )
        .map((obj: { code: string }) => obj.code);
      const selectedMenuTwoCodes = newMenuTwoItems
        .filter(
          (obj: {
            desc: string;
            code: string;
            detail_desc: string;
            checked?: boolean;
          }) => obj.checked
        )
        .map((obj: { code: string }) => obj.code);
      sessionStorage.setItem(
        "diagonose",
        selectedMenuOneCodes.concat(selectedMenuTwoCodes).join(",")
      );
      patientListContext.setDiagnosesMenuOne(newMenuOneItems);
      patientListContext.setDiagnosesMenuTwo(newMenuTwoItems);
    }
    sessionStorage.setItem(
      "patientAllDiagnosisCheckboxMenu",
      JSON.stringify(allDiagnosisChecked)
    );
    setAllDiagnosisChecked(
      JSON.parse(
        sessionStorage.getItem("patientAllDiagnosisCheckboxMenu") || "[]"
      )
    );
    setApplied(true);
    setShowDropdown(false);
  };

  const handleAllDiagnonisChecked = (checked: boolean) => {
    setAllDiagnosisChecked(checked);

    setApplied(false);
    let newMenuOneItems = [...localMenuOneItems];
    let newMenuTwoItems = [...localMenuTwoItems];
    if (checked) {
      newMenuOneItems = newMenuOneItems.map(item => {
        item.checked = true;
        return item;
      });
      newMenuTwoItems = newMenuTwoItems.map(item => {
        item.checked = true;
        return item;
      });

      const codesArrayMenuOne = newMenuOneItems.map(obj => obj.code);
      const codesArrayMenuTwo = newMenuTwoItems.map(obj => obj.code);
      sessionStorage.setItem(
        "diagonose",
        codesArrayMenuOne.concat(codesArrayMenuTwo).join(",")
      );
    } else {
      newMenuOneItems = newMenuOneItems.map(item => {
        item.checked = false;
        return item;
      });
      newMenuTwoItems = newMenuTwoItems.map(item => {
        item.checked = false;
        return item;
      });
      sessionStorage.setItem("diagonose", "");
    }
    setLocalMenuOneItems(newMenuOneItems);
    setLocalMenuTwoItems(newMenuTwoItems);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);

    const filteredMenuOneItems = patientListContext?.diagnosesMenuOne.filter(
      item =>
        item.desc.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.detail_desc.toLowerCase().includes(e.target.value.toLowerCase())
    );

    const filteredMenuTwoItems = patientListContext?.diagnosesMenuTwo.filter(
      item =>
        item.desc.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.detail_desc.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setLocalMenuOneItems(filteredMenuOneItems);
    setLocalMenuTwoItems(filteredMenuTwoItems);
  };

  return (
    <Dropdown
      arrow
      borderradius="8px"
      trigger={["click"]}
      placement="bottomRight"
      destroyPopupOnHide
      dropDownMenuHeader={
        <DiagnosisDropdownHeader>
          <TitleCol span={12} style={{ display: "flex", gap: 10 }}>
            Diagnosis{" "}
            <input
              placeholder="Search Diagnoses"
              style={{
                marginTop: -4,
                outline: "none",
                borderRadius: "10px",
                border: "1px solid rgb(226, 237, 243)",
                padding: "4px 11px"
              }}
              value={searchQuery}
              onChange={handleSearch}
            />
          </TitleCol>
          <HeaderButtonCol span={12}>
            <AllDiagnosisWrapper>
              <Checkbox
                checked={allDiagnosisChecked}
                onChange={e => {
                  handleAllDiagnonisChecked(e.target.checked);
                }}
              />{" "}
              All Diagnoses
            </AllDiagnosisWrapper>
          </HeaderButtonCol>
        </DiagnosisDropdownHeader>
      }
      dropDownMenuBody={
        <Row style={{ backgroundColor: Colors.GhostWhite, width: "1015px" }}>
          <MenuCol>
            <CheckboxMenu
              menuItems={localMenuOneItems}
              getCheckedItems={setLocalMenuOneItems}
              applied={applied}
              setApplied={setApplied}
            />
          </MenuCol>
          <div
            style={{
              borderLeft: `1px solid ${Colors.Lavender}`,
              height: "312px",
              left: "50%",
              marginLeft: "0px",
              top: 83,
              width: "1px"
            }}
          />
          <MenuCol>
            <CheckboxMenu
              menuItems={localMenuTwoItems}
              getCheckedItems={setLocalMenuTwoItems}
              applied={applied}
              setApplied={setApplied}
            />
          </MenuCol>
        </Row>
      }
      dropDownMenuFooter={
        <DiagnosisDropdownFooter>
          <Col
            style={{ textAlign: "right", width: "100%", paddingRight: "10px" }}
          >
            <Space>
              <AppButton
                type={ButtonType.Secondary}
                buttonContent="Cancel"
                onClick={() => setShowDropdown(false)}
                style={{ width: "120px" }}
              />
              <AppButton
                type={ButtonType.Primary}
                buttonContent="Apply"
                onClick={() => applyFilters()}
                style={{ width: "120px" }}
              />
            </Space>
          </Col>
        </DiagnosisDropdownFooter>
      }
      visible={showDropdown}
      setVisible={setShowDropdown}
    >
      <AppSelect
        value={selectedItemText}
        label="Show"
        style={{
          marginRight: 5,
          width: "196px",
          pointerEvents: "none"
        }}
        options={[]}
      />
    </Dropdown>
  );
};

const MenuCol = styled(Col)`
  max-height: 330px;
  overflow: auto;
`;

const AllDiagnosisWrapper = styled.div`
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 8px;
  border: 1px solid ${Colors.Lavender};
  background-color: ${Colors.GhostWhite};
  width: fit-content;
  float: right;
  margin-top: 10px;
  margin-right: 8px;
  height: 40px;
`;

const TitleCol = styled(Col)`
  padding: 20px;
  padding-left: 17px;
  font-family: Century Gothic Bold;
`;

const HeaderButtonCol = styled(Col)`
  text-align: right;
  font-weight: 400 !important;

  svg {
    margin-top: -11px;
    margin-right: -10px;
  }
`;

const DiagnosisDropdownHeader = styled(Row)`
  background: ${Colors.White};
  border-radius: 8px 8px 0px 0px;
  height: 60px;
  font-size: 14px;
  box-shadow: 0 5px 6px -1px rgba(0, 0, 0, 0.05);
  border-bottom: 2px solid ${Colors.Lavender};
`;

const DiagnosisDropdownFooter = styled(Row)`
  background: ${Colors.White};
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid ${Colors.Lavender};
  height: 68px;
  font-size: 14px;
  margin-bottom: 3px;
  display: grid;
  place-items: center;
`;
