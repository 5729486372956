import * as React from "react";
import styled from "styled-components";
import CopyIconSVG from "./copyIcon.svg";

export const CopyIcon: React.FC = () => (
  <StyledImg src={CopyIconSVG} alt="copy icon" />
);

const StyledImg = styled.img`
  width: 13px;
  height: 13px;
  color: white;
`;
