import styled from "styled-components";
import { Colors } from "helpers/colors";

export const FluidMetricsTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 18px;
  padding-top: 18px;
`;
export const FluidMetricsSubtitleContainer = styled.div`
  height: 35px;
  border: 1px solid ${Colors.Lavender};
  border-radius: 8px;
  background-color: ${Colors.BabyBlue};
  font-size: 16px;
  font-weight: bold;
  display: grid;
  place-items: center;
`;
export const OverflowContainer = styled.div`
  height: calc(100vh - 270px);
  overflow: auto;
  padding-right: 5px;
`;
export const FluidMetricsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(5, 18vh);
  column-gap: 10px;
  row-gap: 10px;
  margin-top: 10px;
`;
interface FluidMetricsGridItemProps {
  readonly colSpan?: string | number;
  readonly rowSpan?: string | number;
}
export const FluidMetricsGridItem = styled.div<FluidMetricsGridItemProps>`
  grid-column: ${props => (props.colSpan ? `span ${props.colSpan}` : "span 1")};
  grid-row: ${props => (props.rowSpan ? `span ${props.rowSpan}` : "span 1")};
`;

export const FluidMetricsResponsiveGridItem = styled.div<FluidMetricsGridItemProps>`
  grid-column: ${props => (props.colSpan ? `span ${props.colSpan}` : "span 1")};
  grid-row: ${props => (props.rowSpan ? `span ${props.rowSpan}` : "span 1")};

  @media screen and (max-width: 1300px) {
    grid-column: ${() => "span 4"};
    grid-row: ${() => "span 2"};
  }
`;

export const CountItemTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: ${Colors.BabyBlue};
  border: 1px solid ${Colors.Lavender};
  border-radius: 0 0 8px 8px;
  margin-top: -15px;
`;
export const CountItemCountText = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 1.5vh;
`;
export const CountItemDescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  line-height: 3vh;
  height: 6vh;
  overflow: hidden;
`;
export const CardTitleText = styled.div`
  font-size: 12px;
`;
export const StepContainer = styled.div`
  margin-top: 5px;
`;

export const StepLabelText = styled.span`
  font-weight: bold;
`;
export const StepValueText = styled.span`
  margin-left: 5px;
`;
export const StepDataContainer = styled.div`
  border-radius: 8px;
  margin-top: 5px;
  display: grid;
  place-items: center;
  height: calc(100% - 105px);
  background-color: ${Colors.GhostWhite};
`;
export const ViewMoreText = styled.div`
  color: ${Colors.DodgerBlue};
  font-style: 14px;
  margin-top: 5px;
  font-weight: bold;
`;

export const FeedbackCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
`;

export const FeedbackCardButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
`;
export const FeedbackModalContainer = styled.div`
  float: right;
  width: 30vw;
  height: calc(100vh - 170px);
  max-height: 530px;
  max-width: 530px;
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 2;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  background-color: white;
`;
export const FeedbackModalTitleContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid ${Colors.Lavender};
`;
export const FeedbackOverflowContainer = styled.div`
  width: 100%;
  height: calc(100% - 110px);
  overflow: auto;
  padding: 12px 12px 12px 25px;
`;

export const ErrorMessage = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  font-size: 22px;
  color: red;
  opacity: 0.3;
`;

export const NoDataText = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  font-size: 22px;
  opacity: 0.3;
`;
