import React, { useContext, useEffect, useMemo, useState } from "react";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import styled from "styled-components";
import { Colors } from "helpers/colors";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import SurveysTable from "./surveysTable";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { createApiClient } from "apiClient";
import { Button, message, Divider, Spin } from "antd";
import { useMutation, useQuery } from "react-query";
import { UserRoles } from "constants/roles";
import { AppModal } from "components/modal";
import { AppSelect } from "components/inputs";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { AssignSurvey } from "sharedTypes";

export const SurveysPage: React.FC = () => {
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const [patientId, setpatientId] = useState<any>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const userRole = sessionStorage.getItem("userRole");
  const userId = sessionStorage.getItem("userId");
  const [allSurveyList, setAllSurveyList] = useState<any>();
  const [surveyOptions, setSurveyOptions] = useState<any>();
  const [chosenSurvey, setChosenSurvey] = useState<any>();
  const [postSurveyObj, setPostSurveyObj] = useState<AssignSurvey>({
    survey_id: chosenSurvey,
    patient_internal_id: Number(sessionStorage.getItem("patientId"))
  });
  const [saveState, setSaveState] = useState<boolean>();

  useEffect(() => {
    if (userRole != "patient") {
      setpatientId(sessionStorage.getItem("patientId"));
    } else {
      setpatientId(sessionStorage.getItem("userId"));
    }
  }, []);

  const { mutate: markNotification } = useMutation(
    "mark-notification",
    createApiClient().putNotifications,
    {
      onSuccess: () => {
        getNotificationsList();
      },
      onError: () => {
        message.error("There was a problem updating notifications.");
      }
    }
  );

  const { refetch: getNotificationsList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId || "",
        userRole == "patient"
          ? UserRoles.PATIENT
          : userRole == "caregiver"
          ? UserRoles.CAREGIVER
          : UserRoles.PROVIDER
      );
    },
    {
      enabled: false,
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      }
    }
  );

  const {
    isLoading: isLoadingAllSurveyList,
    data: allSurveyListData,
    error: isAllSurveyListError,
    isFetching: isFetchingAllSurveyList,
    refetch: refetchAllSurveyList
  } = useQuery(
    "allpatientSurveyList",
    async () => {
      const data = await createApiClient().getAllPatientSurveyList();
      setAllSurveyList(data);
      return data;
    },
    {
      enabled: sessionStorage.getItem("userRole") !== "patient" ? true : false
    }
  );

  const {
    isLoading: isLoadingPostAssignSurvey,
    data: postAssignSurvey,
    error: isErrorPostAssignSurvey,
    isFetching: isFetchingPostAssignSurvey,
    refetch: refetchAssignSuvey
  } = useQuery(
    "postAssignedSurvey",
    async () => {
      setSaveState(false);
      const data = await createApiClient().postAssignSurvey(postSurveyObj);
      if (data == "Survey added") {
        modalOpenClose();
        setSaveState(true);
      } else {
        message.error(data);
        modalOpenClose();
        setSaveState(true);
      }
      return data;
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    const DDsurveyOptions = allSurveyList?.map((item: any) => ({
      label: item.name,
      value: item.id.toString()
    }));
    setSurveyOptions(DDsurveyOptions);
    if (DDsurveyOptions != undefined) {
      setChosenSurvey(DDsurveyOptions[0]?.value);
    }
  }, [allSurveyList, allSurveyListData]);

  const unreadNotifications = useMemo(() => {
    if (userRole == "patient") {
      return notificationsContext?.filterNotificationsByStatusAndPatient(
        notificationsContext?.notifications?.survey || [],
        patientId,
        "unread"
      );
    } else {
      const careteamNotificatonsSurvey =
        notificationsContext?.notifications?.care_team.filter((item: any) => {
          return item.type === "careteam-survey";
        });
      return notificationsContext?.filterNotificationsByStatusAndPatient(
        careteamNotificatonsSurvey || [],
        patientId,
        "unread"
      );
    }
  }, [notificationsContext?.notifications?.survey, patientId]);

  function markNotificationRead(userType: any) {
    unreadNotifications?.forEach(item => {
      markNotification({
        notificationId: item?.notification_id || "",
        body: {
          status: "read",
          type: userType == "patient" ? "survey" : "care_team"
        }
      });
    });
  }

  useEffect(() => {
    if (userRole == "patient") {
      markNotificationRead("patient");
    } else {
      markNotificationRead("caregivers");
    }
  }, [unreadNotifications]);

  const modalOpenClose = () => {
    setModalOpen(!modalOpen);
  };

  const chooseSurvey = (type: any) => {
    setChosenSurvey(type);
  };

  const assignSurvey = () => {
    const tempArr = [];
    tempArr.push(Number(chosenSurvey));
    postSurveyObj.survey_id = tempArr;
    refetchAssignSuvey();
  };

  return (
    <>
      {sessionStorage.getItem("userRole") !== "patient" && (
        <CommonPatientExtensionItems />
      )}
      <Row gutter={[0, 0]}>
        <Col style={{ display: "grid", placeItems: "start" }}>
          <div className="top_container" style={{ display: "flex" }}>
            <TitleCol>Surveys</TitleCol>
            {sessionStorage.getItem("userRole") != "patient" && (
              <AddSurveyButton>
                <Button
                  type="primary"
                  onClick={() => {
                    modalOpenClose();
                  }}
                >
                  Add Survey
                </Button>
              </AddSurveyButton>
            )}
          </div>
          <Space
            style={{ padding: "0 0 20px 20px" }}
            color={Colors.Grey}
          ></Space>
        </Col>
      </Row>
      <SurveysTable surveyAssignedData={saveState} />
      <AppModal
        title="Add Survey"
        visible={modalOpen}
        width="50%"
        footer={false}
        onCancel={modalOpenClose}
      >
        <ModalContentContainer>
          <PopUpText>
            Assign Survey to Patient{" "}
            <span style={{ fontWeight: "bold" }}>
              <b>{` ${" "} ${patientContext.patientData?.first_name} ${" "} ${
                patientContext.patientData?.last_name
              }${"."}`}</b>
            </span>{" "}
            <AppSelect
              value={chosenSurvey}
              label="Select Survey"
              style={{
                minWidth: "196px",
                borderRadius: "8px",
                marginTop: "10px"
              }}
              onChange={chooseSurvey}
              options={surveyOptions}
              dropdownStyle={{ borderRadius: "8px" }}
            />
            {saveState == false && (
              <SpinContainer>
                <Spin size="large" />
              </SpinContainer>
            )}
          </PopUpText>
          <Divider />
          <ButtonContainer>
            <Button
              onClick={() => {
                modalOpenClose();
              }}
            >
              Close
            </Button>
            <Button type="primary" onClick={() => assignSurvey()}>
              Save
            </Button>
          </ButtonContainer>
        </ModalContentContainer>
      </AppModal>
    </>
  );
};

const TitleCol = styled(Col)`
  font-family: Century Gothic Bold;
  padding: 20px 20px 0 20px;
`;

const ModalContentContainer = styled.div`
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
`;

const PopUpText = styled.div`
  padding: 5px 30px 0px 30px;
  color: ${Colors.gothicBold};
  font-weight: 400;
`;

const ButtonContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  margin-right: 15px;
`;

const AddSurveyButton = styled.div`
  margin-top: 15px;
  position: fixed;
  right: 40px;
`;

const SpinContainer = styled.div`
  top: 60%;
  left: 50%;
  position: absolute;
`;
