import React from "react";
import styled from "styled-components";

const ListStyle = styled.ul`
  margin-left: -40px;
`;

export const heartRateQA = [
  {
    question: "What is the definition of heart rate?",
    answer: (
      <ListStyle>
        <li>
          When doctors measure your heart rate, they are counting how many times
          your heart beats in one minute.
        </li>
      </ListStyle>
    )
  },
  {
    question: "Why is the heart rate important?",
    answer: (
      <ListStyle>
        <li>
          Measuring your heart rate is important because heart rates that are
          too low or too high might mean there is something health related that
          may need to be examined by a doctor.
        </li>
      </ListStyle>
    )
  },
  {
    question: "How is heart rate measured?",
    answer: (
      <ListStyle>
        <li>
          Heart rate is measured by counting your pulse. You can feel your pulse
          in four places on your body:
          <ol>
            <li>Wrists</li>
            <li>Inside of your elbow</li>
            <li>Your neck, just below your jaw</li>
            <li>Inside of your foot by your heel</li>
          </ol>
        </li>
        Once you find your pulse, count how many beats you feel in 1 minute.
      </ListStyle>
    )
  },
  {
    question: "What is considered a healthy heart rate?",
    answer: (
      <ListStyle>
        <li>A typical heart rate is between 60-100 beats per minute</li>
        <li>
          Heart rate should also be measured when you are at rest (at rest means
          that you are calm, sitting down and have not done any rigorous
          exercise prior to measuring your heart rate)
        </li>
      </ListStyle>
    )
  },
  {
    question:
      "What if my heart rate is below 60? Or what if my heart rate is above 100?",
    answer: (
      <ListStyle>
        <li>
          If your heart beat is lower than 60 or higher than 100, this does not
          necessarily mean you might have a medical condition. Some medications
          may lower or raise your heart beat. Your heart rate can also be
          affected by exercise, air temperature and body position. Consult your
          doctor to be sure whether your range is right for you.
        </li>
      </ListStyle>
    )
  }
];

export const ejectionFractionQA = [
  {
    question: "What is ejection fraction?",
    answer: (
      <ListStyle>
        <li>
          Ejection fraction is the amount of blood that your heart pushes out
          every time it squeezes
        </li>
      </ListStyle>
    )
  },
  {
    question: "What does the heart do in the body?",
    answer: (
      <ListStyle>
        <li>
          The heart’s sole job in the body is to pump blood through the arteries
          and veins to all the parts of your body that need it
        </li>
      </ListStyle>
    )
  },
  {
    question: "How do the kidneys affect the heart?",
    answer: (
      <>
        <ListStyle>
          <li>
            When the kidneys aren’t able to do their job, this puts more stress
            on the heart and forces the heart to work harder. This can lead to
            heart diseases.
          </li>
        </ListStyle>
      </>
    )
  },
  {
    question:
      "How do I keep my heart healthy while I’m receiving treatment for my kidneys?",
    answer: (
      <>
        <ListStyle>
          <li>Exercise regularly</li>
          <li>Get enough sleep – reduce stress</li>
          <li>Eat a healthy diet low in cholesterol and sodium</li>
        </ListStyle>
      </>
    )
  }
];

export const bnpValueQA = [
  {
    question: "What is BNP value?",
    answer: (
      <ListStyle>
        <li>
          BNP stands for ‘brain natriuretic peptide’ and it is a chemical
          produced in your body. This BNP chemical is used by doctors to test
          whether someone might be experiencing heart failure.
        </li>
      </ListStyle>
    )
  },
  {
    question: "How does BNP work?",
    answer: (
      <ListStyle>
        <li>
          When your heart starts to fail, your body produces large amounts of
          BNP as a response to try to keep your heart working as it should.
          Therefore, if you have very high levels of BNP, this can help your
          doctor understand what might be going on with your heart.
        </li>
      </ListStyle>
    )
  },
  {
    question: "How do you measure BNP?",
    answer: (
      <ListStyle>
        <li>
          BNP is measured by testing a sample of blood. So, a blood test will be
          necessary in order to determine your BNP level. Consult your doctor if
          you have questions about your BNP levels.
        </li>
      </ListStyle>
    )
  }
];

export const lipidsQA = [
  {
    question: "What are lipids?",
    answer: (
      <ListStyle>
        <li>
          Lipids refer to small, fatty acid molecules in your body. A common and
          very familiar example of one kind of lipid is cholesterol
        </li>
      </ListStyle>
    )
  },
  {
    question: "What do lipid tests measure?",
    answer: (
      <ListStyle>
        <li>Lipid tests are used to measure levels of fats in the blood</li>
      </ListStyle>
    )
  },
  {
    question: "What are lipid tests for?",
    answer: (
      <ListStyle>
        <li>
          We know that too much cholesterol (a type of lipid) can cause
          blockages in your arteries. This can lead to heart diseases, if the
          arteries to the heart are blocked
        </li>
      </ListStyle>
    )
  },
  {
    question: "What’s the difference between cholesterol test and lipid test?",
    answer: (
      <ListStyle>
        <li>
          These tests actually refer to the same thing. Lipid tests have
          multiple names including:
        </li>
        <ul>
          <li>Lipid profile</li>
          <li>Lipid test</li>
          <li>Cholesterol panel</li>
          <li>Coronary risk panel</li>
          <li>Fasting lipid panel or non-fasting lipid panel</li>
        </ul>
        All of these tests mentioned above are meant to measure the level of
        fats in your blood.
      </ListStyle>
    )
  }
];
