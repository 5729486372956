// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext, useEffect } from "react";
import {
  useMeetingManager,
  useNotificationDispatch,
  Severity,
  ActionType
} from "amazon-chime-sdk-component-library-react";

import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";
import { message } from "antd";

const NoMeetingRedirect: React.FC = ({ children }) => {
  const dispatch = useNotificationDispatch();
  const meetingManager = useMeetingManager();
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

  const payload: { severity: Severity; message: string; autoClose: boolean } = {
    severity: Severity.INFO,
    message: "No meeting found, please enter a valid meeting Id",
    autoClose: true
  };

  useEffect(() => {
    if (!meetingManager.meetingSession) {
      dispatch({
        type: ActionType.ADD,
        payload: payload
      });
      message.info("No meeting found, please try again");
      meetingContext.closeSession();
    }
  }, []);

  return <>{children}</>;
};

export default NoMeetingRedirect;
