import Spin from "antd/lib/spin";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AppCard } from "components/card";

import { Colors } from "helpers/colors";
import { AllBillsList } from "sharedTypes";
import { AddBill } from "./AddBill";
import { DraftItem } from "./draftItem";
import { EditBill } from "./EditBill";

interface TabListProps {
  readonly data: AllBillsList[];
  setShowAddBill(show: boolean): void;
  readonly showAddBill: boolean;
  setShowEditBill(show: boolean): void;
  readonly showEditBill: boolean;
  getBillingList(): void;
  readonly loading: boolean;
  readonly latestApprovedBill?: AllBillsList;
  readonly enableBilling: boolean;
}

export const DraftTabList: React.FC<TabListProps> = ({
  data,
  showAddBill,
  setShowAddBill,
  showEditBill,
  setShowEditBill,
  getBillingList,
  loading,
  latestApprovedBill,
  enableBilling
}) => {
  const [selectedDraftID, setSelectedDraftID] = useState<number | string>("");

  const selectedDraftData = data.filter(obj => {
    return obj.id === selectedDraftID;
  });

  useEffect(() => {
    if (showAddBill || !showEditBill) {
      setSelectedDraftID("");
    }
  }, [showAddBill, showEditBill]);

  return (
    <Row gutter={[30, 0]}>
      <Col span={showAddBill || showEditBill ? 15 : 24}>
        {loading ? (
          <AppCard
            cardHeight="55px"
            cardWidth="100%"
            style={{
              borderRadius: "0px 0px 8px 8px",
              marginTop: "-2px",
              display: "grid",
              placeItems: "center"
            }}
          >
            <Spin />
          </AppCard>
        ) : (
          <ListContainer>
            {data.length <= 0 && (
              <div>No draft bills associated with this patient</div>
            )}
            {data.map(data => {
              return (
                <DraftItem
                  data={data}
                  key={data.id}
                  setShowEditBill={setShowEditBill}
                  setShowAddBill={setShowAddBill}
                  setSelectedDraftID={setSelectedDraftID}
                  getBillingList={getBillingList}
                  selectedDraftID={selectedDraftID}
                  enableBilling={enableBilling}
                />
              );
            })}
          </ListContainer>
        )}
      </Col>

      <Col span={9}>
        {showAddBill && (
          <AddBill
            showAddBill={showAddBill}
            getBillingList={getBillingList}
            setShowAddBill={setShowAddBill}
            latestApprovedBill={latestApprovedBill}
            enableBilling={enableBilling}
          />
        )}
        {showEditBill && (
          <EditBill
            selectedDraftData={selectedDraftData[0]}
            showAddBill={showAddBill}
            selectedDraftID={selectedDraftID}
            getBillingList={getBillingList}
            setShowEditBill={setShowEditBill}
            enableBilling={enableBilling}
          />
        )}
      </Col>
    </Row>
  );
};

const ListContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 8px 8px;
  margin-top: -2px;
  padding: 12px 12px 12px 12px;
  background-color: ${Colors.White};
  max-height: 70vh;
  overflow: scroll;
`;
