/* eslint-disable @typescript-eslint/no-unused-vars */
import message from "antd/lib/message";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { Colors } from "helpers/colors";
import { AllBillsList } from "sharedTypes";
import { EditIcon } from "./EditIcon";
import { BillingAlertModal } from "./BillingAlertModal";
import { Checkbox, Modal } from "antd";
import moment from "moment";
import { useDraftBillContext } from "contextApis/draftBillContext";

interface DraftItemProps {
  readonly data: AllBillsList;
  setShowAddBill(show: boolean): void;
  setShowEditBill(show: boolean): void;
  setSelectedDraftID(id: string | number): void;
  getBillingList(): void;
  readonly selectedDraftID: string | number;
  readonly enableBilling: boolean;
}

export const DraftItem: React.FC<DraftItemProps> = ({
  data,
  setShowAddBill,
  setShowEditBill,
  setSelectedDraftID,
  getBillingList,
  selectedDraftID,
  enableBilling
}) => {
  const patientContext = useContext<PatientContextState>(PatientContext);
  const userContext = useContext<UserContextState>(UserContext);

  const [showBillingAlertModal, setBillingAlertModal] = useState(false);
  const { draftBillId, setDraftBillId } = useDraftBillContext();

  const {
    isLoading: isLoadingEditBillMutation,
    mutate: editBillMutation,
    data: billData
  } = useMutation<string>(
    "add-bill",
    async () => {
      const myGMTDate = new Date(`${data.dateOfService} GMT`);
      return await createApiClient().editBill(data.id, {
        status: "Approve",
        date_of_service: myGMTDate.toISOString(),
        billing_diagnose_code: JSON.stringify(data.diagnoses),
        provider_name: data.providerName,
        billing_charge_code: JSON.stringify(data.charges),
        patient_internal_id: patientContext.patientData?.id
          ? String(patientContext.patientData?.id)
          : "",
        provider_internal_id: userContext.userData?.internal_id
          ? userContext.userData?.internal_id
          : "",
        patient_location: data.patientLocation,
        total_duration_billed: data.currentMonthContactTime,
        provider_location: data.providerLocation
      });
    },
    {
      onSuccess: (e: any) => {
        if (typeof e !== "object") {
          message.error(e);
        } else {
          getBillingList();
          message.success("Draft Approved!");
          setShowEditBill(false);
        }
      },
      onError: (e: any) => {
        message.warning(
          "Please fill all the details before approving this draft bill."
        );
      }
    }
  );
  const refIdBillings = draftBillId;
  const refIdBillingsNumber = Number(refIdBillings);

  const minutesValue = Number(data.currentMonthContactTime) / 60;
  const fullMinutes = Math.floor(minutesValue);
  const seconds = Math.round((minutesValue - fullMinutes) * 60);
  const formattedDuration =
    seconds > 0 ? `${fullMinutes}.${seconds}` : `${fullMinutes}`;

  const [modal, setModal] = useState(false);

  const [isChecked, setISChecked] = useState(false);

  const handleCheckboxChange = (e: any) => {
    setISChecked(e.target.checked);
  };

  const isRequiredFieldEmpty = data?.patientLocation !== null;
  data?.providerLocation !== null &&
    data?.diagnoses.length !== 0 &&
    data?.charges.length !== 0;

  const convertUTCDateToLocal = (dateString: any) => {
    const localDateTime = moment.utc(dateString).local();
    const formattedDateTime = localDateTime.format("MMM, DD, YYYY | hh:mm A");
    return formattedDateTime;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data && refIdBillingsNumber && enableBilling) {
        setSelectedDraftID(refIdBillingsNumber);
        setShowAddBill(false);
        setShowEditBill(true);
        setModal(false);
        console.log("Data is ready and effect is triggered", data);
      } else {
        console.log("Data is not ready yet", data);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
      setDraftBillId("");
    };
  }, [data, refIdBillingsNumber, enableBilling]);

  return (
    <>
      <Container data-testid="draft-tab-item">
        <InnerContainer
          style={
            selectedDraftID === data.id
              ? {
                  backgroundColor: Colors.Lavender,
                  border: `0.3px solid #BADDED`
                }
              : {}
          }
        >
          <UpperRowStyled>
            <DateTimeCol span={12}>
              {convertUTCDateToLocal(data.dateOfService)}
            </DateTimeCol>
            <DurationEditApproveCol span={12}>
              <Space size={35}>
                <span>{formattedDuration} minutes</span>

                {isRequiredFieldEmpty ? (
                  <span
                    onClick={() => {
                      if (enableBilling) {
                        setShowAddBill(false);
                        setShowEditBill(true);
                        setSelectedDraftID(data.id);
                      } else {
                        setBillingAlertModal(true);
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <EditIcon />
                  </span>
                ) : (
                  <AppButton
                    type={ButtonType.Skeleton}
                    buttonContent="Edit Bill"
                    onClick={() => {
                      if (enableBilling) {
                        setShowAddBill(false);
                        setShowEditBill(true);
                        setSelectedDraftID(data.id);
                      } else {
                        setBillingAlertModal(true);
                      }
                    }}
                    style={{ padding: "0px 35px", marginLeft: 50 }}
                  />
                )}

                {isRequiredFieldEmpty && (
                  <AppButton
                    buttonContent="Approve"
                    type={ButtonType.Skeleton}
                    onClick={() => {
                      if (data && data?.bill_type === "Automated") {
                        setModal(!modal);
                      } else {
                        if (enableBilling) {
                          editBillMutation();
                        } else {
                          setBillingAlertModal(true);
                        }
                      }
                    }}
                    style={
                      enableBilling
                        ? { width: "120px" }
                        : {
                            backgroundColor: Colors.Gainsboro,
                            color: Colors.LightGrey,
                            borderColor: Colors.Gainsboro,
                            width: "120px"
                          }
                    }
                    loading={isLoadingEditBillMutation || !enableBilling}
                    disabled={selectedDraftID === data.id}
                  />
                )}
              </Space>
            </DurationEditApproveCol>
          </UpperRowStyled>
          <CenterRowStyled>
            <ColContent span={5}>
              Patient Location <BoldContent>{data.patientLocation}</BoldContent>
            </ColContent>
            <ColContent span={5}>
              Diagnosis
              {data?.diagnoses
                ? data?.diagnoses?.map((item, index) => {
                    return (
                      <BoldContent key={index}>
                        {item.desc}{" "}
                        <span style={{ fontFamily: "sans-serif" }}>
                          ({item.code})
                        </span>
                      </BoldContent>
                    );
                  })
                : ""}
            </ColContent>
            <ColContent span={14}>
              Charges
              {data.charges.map(item => {
                return (
                  <>
                    <BoldContent key={data.id}>{item.code}</BoldContent>
                    {item.desc}
                  </>
                );
              })}
            </ColContent>
          </CenterRowStyled>
          <Row>
            <ColContent>
              Provider Location
              <BoldContent>{data.providerLocation}</BoldContent>
            </ColContent>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <ColContent span={3}>
              Source
              <BoldContent>
                {data.bill_type === "Automated"
                  ? "System Generated"
                  : "Manually Generated"}
              </BoldContent>
            </ColContent>
          </Row>
        </InnerContainer>
        {showBillingAlertModal && (
          <BillingAlertModal
            title="You are not authorized for billing"
            visible={showBillingAlertModal}
            closeModal={() => {
              setBillingAlertModal(false);
            }}
          />
        )}
      </Container>

      <Modal
        centered
        title="Physician Consent"
        open={modal}
        onCancel={() => {
          setModal(false);
        }}
        width="40%"
        footer={[
          <AppButton
            key={"Edit Bill"}
            type={ButtonType.Skeleton}
            buttonContent="Edit Bill"
            onClick={() => {
              if (enableBilling) {
                setShowAddBill(false);
                setShowEditBill(true);
                setSelectedDraftID(data.id);
              } else {
                setBillingAlertModal(true);
              }
              setModal(false);
            }}
          />,
          <AppButton
            key={"Approve"}
            type={ButtonType.Skeleton}
            buttonContent="Approve"
            onClick={() => {
              if (enableBilling) {
                editBillMutation();
              } else {
                setBillingAlertModal(true);
              }
            }}
            disabled={isChecked ? false : true}
          />
        ]}
      >
        <Checkbox style={{ marginTop: 2 }} onChange={handleCheckboxChange} />
        <span style={{ textAlign: "justify" }}>
          &nbsp;&nbsp;By approving this bill, I confirm that I have reviewed and
          verified the billing details and I have provided the services as
          listed in the submitted bill.{" "}
        </span>
      </Modal>
    </>
  );
};

const BoldContent = styled.div`
  font-family: Century Gothic Bold;
  color: ${Colors.Black};
  font-size: 14px;
`;

const ColContent = styled(Col)`
  color: ${Colors.Grey};
  font-size: 12px;
`;

const CenterRowStyled = styled(Row)`
  font-size: 14px;

  .ant-col {
    border-left: 1px solid ${Colors.Lavender};
    padding-left: 10px;

    :first-child {
      padding-left: 0px;
      border: none;
    }
  }
`;

const DateTimeCol = styled(Col)`
  padding: 8px 12px 12px 0px;
`;

const DurationEditApproveCol = styled(Col)`
  text-align: right;
`;

const UpperRowStyled = styled(Row)`
  font-family: Century Gothic Bold;
  font-size: 12px;
`;

const Container = styled.div`
  background-color: ${Colors.White};
  border-bottom: 1px solid ${Colors.Lavender};
`;

const InnerContainer = styled.div`
  padding: 0px;
  padding: 12px 8px 15px 8px;
  border-radius: 8px 8px 8px 8px;
  :hover {
    background-color: ${Colors.Lavender};
  }
`;
