import React from "react";
import { MENU_ITEM_ICON_DISABLED_COLOR } from "components/sideBar/sideBar";

interface IconProps {
  readonly isDisabled?: boolean;
}

export const NotificationsIcon: React.FC<IconProps> = ({ isDisabled }) => {
  return (
    <svg
      width="39px"
      height="36px"
      viewBox="0 0 39 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Provider-Global-navigation-V2.3"
          transform="translate(-14.000000, -507.000000)"
        >
          <g
            id="EducationTips-menu-Copy-5"
            transform="translate(14.000000, 507.000000)"
          >
            <g id="Group-4" transform="translate(7.000000, 6.000000)">
              <g id="Rectangle-Copy-210">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill={isDisabled ? MENU_ITEM_ICON_DISABLED_COLOR : "#F27B6E"}
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <path
                d="M14.2591099,14.0656777 L14.2591099,10.8430896 C14.2591099,10.3962218 14.161934,9.98803136 13.9675794,9.61850607 C13.7732247,9.24898079 13.4962735,8.96109913 13.1367174,8.75485246 C12.7674436,8.53141857 12.335011,8.4197033 11.8394066,8.4197033 C11.3438022,8.4197033 10.9113696,8.53141857 10.5420958,8.75485246 C10.1825397,8.96109913 9.90558847,9.24898079 9.71123382,9.61850607 C9.51687917,9.98803136 9.4197033,10.3962218 9.4197033,10.8430896 L9.4197033,14.0656777 L14.2591099,14.0656777 Z M15.4617169,13.9203079 L16.6788132,15.1020235 L16.6788132,15.6788132 L7,15.6788132 L7,15.1020235 L8.21709627,13.9203079 L8.21709627,11.0082231 C8.21709627,10.0891064 8.45375151,9.29661461 8.92706909,8.63072393 C9.41004622,7.94607578 10.0813744,7.50059222 10.9410736,7.2942599 L10.9410736,6.88628667 C10.9410736,6.64243939 11.0280082,6.43376551 11.20188,6.26025878 C11.3757517,6.08675206 11.5882585,6 11.8394066,6 C12.0905547,6 12.3054763,6.08675206 12.4841778,6.26025878 C12.6628794,6.43376551 12.7522288,6.64243939 12.7522288,6.88628667 L12.7522288,7.2942599 C13.6022685,7.50059222 14.268767,7.95076508 14.7517441,8.64479198 C15.2250617,9.31068265 15.4617169,10.0984851 15.4617169,11.0082231 L15.4617169,13.9203079 Z M11.4361227,17.2919487 C11.1096532,17.2919487 10.8263971,17.21326 10.586346,17.0558801 C10.3462948,16.8985002 10.2262711,16.7083358 10.2262711,16.485381 L12.6459744,16.485381 C12.6459744,16.7083358 12.5259506,16.8985002 12.2858995,17.0558801 C12.0458483,17.21326 11.7625923,17.2919487 11.4361227,17.2919487 Z"
                id="notifications_none---material"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
