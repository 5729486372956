import moment from "moment";
import { lowerCaseRemoveSpace } from "helpers/utils";
import { ExtendedSymptomData } from "pages/symptoms/SymptomsModal";

export const dialysisMapper = ({
  filteredExtendedSymptomData,
  mapperType,
  mapperValue
}: {
  filteredExtendedSymptomData: ExtendedSymptomData[] | null;
  mapperType?: string | undefined;
  mapperValue?: string | number | undefined;
}) => {
  const dizzyness: [string, number, string][] = [];
  const cramping: [string, number, string][] = [];
  const headaches: [string, number, string][] = [];
  const nausea: [string, number, string][] = [];
  const chestPain: [string, number, string][] = [];
  const legSwelling: [string, number, string][] = [];
  const shortnessOfBreath: [string, number, string][] = [];
  const weightGain: [string, number, string][] = [];

  const sortedData = filteredExtendedSymptomData?.sort(
    (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
  );

  if (mapperType) {
    let tooltipValue = "";
    if (lowerCaseRemoveSpace(mapperType) === "dizzy/lightheaded") {
      switch (mapperValue) {
        case 0:
          tooltipValue = "No";
          break;
        case 1:
          tooltipValue = "Rarely (1-2 / Month)";
          break;
        case 3:
          tooltipValue = "Occasional (3-4 / Month)";
          break;
        case 7:
          tooltipValue = "Frequent (5-10 / Month)";
          break;
        case 10:
          tooltipValue = "Every dialysis (11-13 / Month)";
          break;
      }
    } else if (lowerCaseRemoveSpace(mapperType) === "cramping") {
      switch (mapperValue) {
        case 0:
          tooltipValue = "No";
          break;
        case 1:
          tooltipValue = "Rarely (1-2 / Month)";
          break;
        case 3:
          tooltipValue = "Occasional (3-4 / Month)";
          break;
        case 7:
          tooltipValue = "Frequent (5-10 / Month)";
          break;
        case 10:
          tooltipValue = "Every dialysis (11-13 / Month)";
          break;
      }
    } else if (lowerCaseRemoveSpace(mapperType) === "headaches") {
      switch (mapperValue) {
        case 0:
          tooltipValue = "No";
          break;
        case 1:
          tooltipValue = "Rarely (1-2 / Month)";
          break;
        case 3:
          tooltipValue = "Occasional (3-4 / Month)";
          break;
        case 7:
          tooltipValue = "Frequent (5-10 / Month)";
          break;
        case 10:
          tooltipValue = "Every dialysis (11-13 / Month)";
          break;
      }
    } else if (lowerCaseRemoveSpace(mapperType) === "nausea/vomiting") {
      switch (mapperValue) {
        case 0:
          tooltipValue = "None";
          break;
        case 1:
          tooltipValue = "Rarely (1-2 / Month)";
          break;
        case 3:
          tooltipValue = "Occasional (3-4 / Month)";
          break;
        case 7:
          tooltipValue = "Frequent (5-10 / Month)";
          break;
        case 10:
          tooltipValue = "Every dialysis (11 -13/ month)";
          break;
      }
    } else if (lowerCaseRemoveSpace(mapperType) === "chestpain") {
      switch (mapperValue) {
        case 0:
          tooltipValue = "None";
          break;
        case 1:
          tooltipValue = "Rarely (1-2 / Month)";
          break;
        case 3:
          tooltipValue = "Occasional (3-4 / Month)";
          break;
        case 7:
          tooltipValue = "Frequent (5-10 / Month)";
          break;
        case 10:
          tooltipValue = "Every dialysis (11 -13/ month)";
          break;
      }
    } else if (lowerCaseRemoveSpace(mapperType) === "legswelling") {
      switch (mapperValue) {
        case 0:
          tooltipValue = "NONE";
          break;
        case 2:
          tooltipValue = "Mild up to ankles or shins only";
          break;
        case 5:
          tooltipValue = "Moderate, up to knees";
          break;
        case 10:
          tooltipValue = "Severe, up to thighs";
          break;
      }
    } else if (lowerCaseRemoveSpace(mapperType) === "shortnessofbreath") {
      switch (mapperValue) {
        case 0:
          tooltipValue = "None";
          break;
        case 1:
          tooltipValue = "Rarely (1-2 / Month)";
          break;
        case 3:
          tooltipValue = "Occasional (3-4 / Month)";
          break;
        case 7:
          tooltipValue = "Frequent (5-10 / Month)";
          break;
        case 10:
          tooltipValue = "Every dialysis (11 -13/ month)";
          break;
      }
    } else if (lowerCaseRemoveSpace(mapperType) === "weightgain") {
      switch (mapperValue) {
        case 0:
          tooltipValue = "None";
          break;
        case 2:
          tooltipValue = "Less than 2 kilograms (4 pounds)";
          break;
        case 5:
          tooltipValue = "2 - 3 kilograms (4 - 7 pounds)";
          break;
        case 8:
          tooltipValue = "3 - 5 kilograms (7 - 11 pounds)";
          break;
        case 10:
          tooltipValue = "More than 5 kilograms";
          break;
      }
    }
    return tooltipValue;
  }

  sortedData?.forEach(item => {
    item.data?.map(item2 => {
      if (lowerCaseRemoveSpace(item2?.key) === "dizzy/lightheaded") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "no":
            value = 0;
            break;
          case "rarely(1-2/month)":
            value = 1;
            break;
          case "occasional(3-4/month)":
            value = 3;
            break;
          case "frequent(5-10/month)":
            value = 7;
            break;
          case "everydialysis(11-13/month)":
            value = 10;
            break;
        }
        dizzyness.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Dizzy/Lightheaded"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "cramping") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "no":
            value = 0;
            break;
          case "rarely(1-2/month)":
            value = 1;
            break;
          case "occasional(3-4/month)":
            value = 3;
            break;
          case "frequent(5-10/month)":
            value = 7;
            break;
          case "everydialysis(11-13/month)":
            value = 10;
            break;
        }
        cramping.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Cramping"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "headaches") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "no":
            value = 0;
            break;
          case "rarely(1-2/month)":
            value = 1;
            break;
          case "occasional(3-4/month)":
            value = 3;
            break;
          case "frequent(5-10/month)":
            value = 7;
            break;
          case "everydialysis(11-13/month)":
            value = 10;
            break;
        }
        headaches.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Headaches"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "nausea/vomiting") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "None":
            value = 0;
            break;
          case "rarely(1-2/month)":
            value = 1;
            break;
          case "occasional(3-4/month)":
            value = 3;
            break;
          case "frequent(5-10/month)":
            value = 7;
            break;
          case "everydialysis(11-13/month)":
            value = 10;
            break;
        }
        nausea.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Nausea/Vomiting"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "chestpain") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "none":
            value = 0;
            break;
          case "rarely(1-2/month)":
            value = 1;
            break;
          case "occasional(3-4/month)":
            value = 3;
            break;
          case "frequent(5-10/month)":
            value = 7;
            break;
          case "everydialysis(11-13/month)":
            value = 10;
            break;
        }
        chestPain.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Chest pain"
        ]);
      } else if (
        item2?.key?.toLowerCase().replace(/\s/g, "") === "legswelling"
      ) {
        let value = 0;
        switch (item2?.value?.toLowerCase().replace(/\s/g, "")) {
          case "none":
            value = 0;
            break;
          case "milduptoanklesorshinsonly":
            value = 2;
            break;
          case "moderate,uptoknees":
            value = 5;
            break;
          case "severe,uptothighs":
            value = 10;
            break;
        }
        legSwelling.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Leg Swelling"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "shortnessofbreath") {
        let value = 0;
        switch (item2?.value?.toLowerCase().replace(/\s/g, "")) {
          case "none":
            value = 0;
            break;
          case "rarely(1-2/month)":
            value = 1;
            break;
          case "occasional(3-4/month)":
            value = 3;
            break;
          case "frequent(5-10/month)":
            value = 7;
            break;
          case "everydialysis(11-13/month)":
            value = 10;
            break;
        }
        shortnessOfBreath.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Shortness of Breath"
        ]);
      } else if (lowerCaseRemoveSpace(item2?.key) === "weightgain") {
        let value = 0;
        switch (lowerCaseRemoveSpace(item2?.value)) {
          case "none":
            value = 0;
            break;
          case "lessthan2kilograms(4pounds)":
            value = 2;
            break;
          case "2-3kilograms(4-7pounds)":
            value = 5;
            break;
          case "3-5kilograms(7-11pounds)":
            value = 8;
            break;
          case "morethan5kilograms":
            value = 10;
            break;
        }
        weightGain.push([
          String(moment(item.timestamp).valueOf()),
          value,
          "Weight Gain"
        ]);
      }
    });
  });

  return [
    dizzyness,
    cramping,
    headaches,
    nausea,
    chestPain,
    legSwelling,
    shortnessOfBreath,
    weightGain
  ];
};
