import React, { createContext, useState } from "react";
import { AllPatientsList } from "sharedTypes";

export type PatientContextState = {
  readonly patientData: AllPatientsList | undefined;
  readonly setSelectedPatientData: (patientData: AllPatientsList) => void;
  readonly patientLastMonthSymptoms: string[];
  readonly setPatientLastMonthSymptoms: (symptoms: string[]) => void;
  readonly setPatientEducationResult: (result: any) => void;
  readonly patientEducationResult: any;
  readonly setOrgId: (flag: any) => void;
  readonly orgId: any;
};

const initialValue: PatientContextState = {
  patientData: undefined,
  setSelectedPatientData: () => null,
  patientLastMonthSymptoms: [],
  setPatientLastMonthSymptoms: () => null,
  setPatientEducationResult: () => null,
  patientEducationResult: null,
  setOrgId: () => null,
  orgId: null
};

export const PatientContext = createContext<PatientContextState>(initialValue);
PatientContext.displayName = "PatientContext";

export const PatientProvider: React.FC = ({ children }) => {
  const [patientData, setPatientData] = useState<AllPatientsList | undefined>(
    undefined
  );
  const [patientLastMonthSymptoms, setPatientLastMonthSymptoms] = useState<
    string[]
  >([]);

  const [patientEducationResult, setPatientEducationResult] = useState<any>();

  const setSelectedPatientData = (userData: AllPatientsList) =>
    setPatientData(userData);

  const [orgId, setOrgId] = useState<any>();

  return (
    <PatientContext.Provider
      value={{
        patientData,
        setSelectedPatientData,
        patientLastMonthSymptoms,
        setPatientLastMonthSymptoms,
        patientEducationResult,
        setPatientEducationResult,
        orgId,
        setOrgId
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};
