import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { AppTabs } from "components/tabs";
import { useDebounce } from "helpers/customHooks";
import { ProvidersList } from "sharedTypes";
import axios from "axios";
import Tableau from "./Tableau";
import { Empty } from "antd";

const providerTypes = {
  pamSurvey: "PAM Survey",
  sdohSurvey: "SDOH SURVEY"
};

export const DashboardTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("PAM Survey");
  const [data, setData] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const getSelectedTab = (key: string) => {
    setSelectedTab(key);
  };

  const [enablePAM, setEnablePAM] = useState<any>(
    JSON.parse(localStorage.getItem("disableComponent") || "")
  );

  const tabsContent = [
    {
      key: providerTypes.sdohSurvey,
      tabName: "SDOH Survey"
    }
  ];

  const tabsContentPam = [
    {
      key: providerTypes.pamSurvey,
      tabName: "PAM Survey"
    },
    {
      key: providerTypes.sdohSurvey,
      tabName: "SDOH Survey"
    }
  ];

  useEffect(() => {
    const fetchToken = async () => {
      if (enablePAM.PAM_Dashboard === 1) return;
      try {
        const config = {
          headers: {
            Authorization: sessionStorage.getItem("access_token") || ""
          }
        };
        const response = await axios.get(
          `${process.env["REACT_APP_API_ENDPOINT"] + "/users/tableau-token"}`,
          config
        );

        setData(perv =>
          perv === response?.data?.data ? perv : response?.data?.data
        );
        setLoading(false);
      } catch (error: any) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchToken();
  }, []);

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  //   if (error) {
  //     return (
  //       <div>
  //         Error: {error}
  //         <br />
  //         Could not locate unexpired trusted ticket. Please{" "}
  //         <a href="https://public.tableau.com">try again</a>.
  //       </div>
  //     );
  //   }

  const options = {
    width: "1200px",
    height: "800px",
    onFirstInteractive: (e: any) => console.log("Viz loaded!", e)
  };

  return (
    <>
      <div>
        <AppTabs
          defaultActiveKey={selectedTab}
          tabsContent={
            enablePAM?.PAM_Dashboard !== 1 ? tabsContentPam : tabsContent
          }
          tabPosition="top"
          getSelectedTab={getSelectedTab}
        />
        {enablePAM?.PAM_Dashboard !== 1 &&
        selectedTab === providerTypes.pamSurvey ? (
          data && (
            <Tableau
              url="https://prod-apnortheast-a.online.tableau.com/t/consinttableauvisualizations/views/PAMSurvey/PAMSurveyCareGem?:origin=card_share_link&:embed=n"
              options={options}
              token={data}
            />
          )
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            style={{ marginTop: "20vh" }}
          />
        )}
      </div>
    </>
  );
};
