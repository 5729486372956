import React, { useContext, useMemo, useState } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { useParams } from "react-router";

import { createApiClient } from "apiClient";
import { NotificationsMenu } from "components/notificationsMenu";
import { UserRoles } from "constants/roles";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { NotificationsHeader } from "./notificationsHeader";
import { NotificationProps } from "sharedTypes";
import { Spin } from "antd";
import { SpinWrapper } from "./style";

export const NotificationsPage = () => {
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const [statusFilter, setStatusFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  const {
    refetch: getNotificationsList,
    isRefetching: isRefetchingGetNotificationsList,
    isLoading: isLoadingGetNotificationsList
  } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        "provider"
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      },
      enabled: false
    }
  );

  const { id: patientId } = useParams();

  const userId = sessionStorage.getItem("userId") || "";

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const patientNotificationItems = useMemo(() => {
    const patientMessageNotificationItems = notificationsContext
      .filterMessageNotificationsByPatient(
        notificationsContext.notifications?.messages || [],
        userIsPatient ? userId : patientId || ""
      )
      .map(item => {
        return {
          desc: item.desc,
          type: item.type,
          status: item.status,
          notification_id: item.notification_id,
          severity: item.severity,
          created_on: item.created_on
        };
      });

    let tempNotifications: NotificationProps[] = [];
    if (notificationsContext.notifications) {
      tempNotifications = notificationsContext.filterNotificationsByPatient(
        notificationsContext.notifications?.remote_vitals?.concat(
          notificationsContext.notifications?.symptoms,
          notificationsContext.notifications?.medications,
          notificationsContext.notifications?.care_team
        ) || [],
        userIsPatient ? userId : patientId || ""
      );
    }
    return notificationsContext.filterNotificationsListByStatusAndType(
      tempNotifications
        .filter(item => item.created_on)
        .map(item => {
          return {
            desc: item.desc,
            type: item.type,
            status: item.status,
            notification_id: item.notification_id,
            severity: item.severity,
            created_on: item.created_on
          };
        })
        .concat(patientMessageNotificationItems)
        .sort(
          (a, b) =>
            moment(b.created_on).valueOf() - moment(a.created_on).valueOf()
        ),
      statusFilter,
      typeFilter
    );
  }, [notificationsContext.notifications, statusFilter, typeFilter]);

  return (
    <>
      <CommonPatientExtensionItems />
      <NotificationsHeader
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        setTypeFilter={setTypeFilter}
        typeFilter={typeFilter}
      />
      {isLoadingGetNotificationsList ? (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      ) : (
        <NotificationsMenu
          loading={isRefetchingGetNotificationsList}
          notifications={patientNotificationItems}
          getNotificationsList={getNotificationsList}
          avatarColSpan={2}
          wrapperStyle={{
            padding: "5px 0px 5px 0px",
            boxShadow:
              "0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08)",
            borderRadius: "8px",
            maxHeight: "calc(100vh - 230px)",
            overflow: "scroll"
          }}
        />
      )}
    </>
  );
};
