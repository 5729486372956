import React, { useState } from "react";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Col from "antd/lib/col";
import Spin from "antd/lib/spin";
import styled from "styled-components";

import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { SearchBox } from "components/searchBox";
import { DiscontinuedReasonsCheckboxDropdown } from "./discontinuedReasonsCheckboxDropdown";
import { DiscontinuedMedicationIcon } from "./discontinuedMedicationIcon";
import { AddMedicationIcon } from "./addMedicationIcon";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { MedicationPages } from "./medication";
import { Colors } from "helpers/colors";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { ActiveMedicineData } from "sharedTypes";
import { PrescriptionReasonCheckboxDropdown } from "./prescriptionCheckboxDropdown";
import { AppSelect } from "components/inputs";

enum ReasonFilterOptions {
  PRESCRIBED = "Prescribed",
  DISCONTINUED = "Discontinued"
}
interface MedicationHeaderProps {
  setMedicaitonDetailsPage: React.Dispatch<
    React.SetStateAction<MedicationPages>
  >;
  medicationDetailsPage: MedicationPages;
  readonly isLoadingReasonsList: boolean;
  readonly isReasonsListError: unknown;
  readonly onSelectedMedicationforHistoryChange: (
    value: ActiveMedicineData | null
  ) => void;
  readonly searchValue: string;
  readonly onSearchValueChange: (value: string) => void;
  setSelectedReasons(reasons: ""): void;
  readonly isLoadingDiscontinuedReasons: boolean;
  readonly isDiscontinuedReasonsError: unknown;
}

export const MedicationsHeader: React.FC<MedicationHeaderProps> = ({
  setMedicaitonDetailsPage,
  medicationDetailsPage,
  isLoadingReasonsList,
  isReasonsListError,
  onSelectedMedicationforHistoryChange,
  searchValue,
  onSearchValueChange,
  setSelectedReasons,
  isLoadingDiscontinuedReasons,
  isDiscontinuedReasonsError
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDiscontinuedDropdown, setShowDiscontinuedDropdown] =
    useState(false);
  const [filterType, setFilterType] = useState("Prescribed");

  const onBackToMedicationsClick = () => {
    setMedicaitonDetailsPage(MedicationPages.ActiveMedications);
    onSelectedMedicationforHistoryChange(null);
    onSearchValueChange("");
    setSelectedReasons("");
  };

  const filterOptions: { label: string; value: string }[] = [
    {
      label: ReasonFilterOptions.PRESCRIBED,
      value: ReasonFilterOptions.PRESCRIBED
    },
    {
      label: ReasonFilterOptions.DISCONTINUED,
      value: ReasonFilterOptions.DISCONTINUED
    }
  ];

  return (
    <RowStyled gutter={[30, 0]}>
      {medicationDetailsPage !== MedicationPages.AddMedicationForm &&
      medicationDetailsPage !== MedicationPages.ActiveMedications ? (
        <TitleCol>
          <Row>{medicationDetailsPage}</Row>
          <Row>
            <AppButton
              type={ButtonType.Link}
              style={{
                color: "#8E94A0",
                fontStyle: "normal",
                fontSize: "11px"
              }}
              buttonContent={
                <Space align="start" style={{ marginLeft: "-16px" }}>
                  <ArrowLeftOutlined />
                  <span style={{ marginLeft: "-5px" }}>
                    Back to Medications
                  </span>
                </Space>
              }
              onClick={onBackToMedicationsClick}
            />
          </Row>
        </TitleCol>
      ) : (
        <TitleCol style={{ paddingTop: "18px" }}>
          {medicationDetailsPage === MedicationPages.AddMedicationForm
            ? `Add Medication`
            : `Active Medication`}
        </TitleCol>
      )}
      {!(
        medicationDetailsPage === MedicationPages.AddMedicationForm ||
        medicationDetailsPage === MedicationPages.EditMedication ||
        medicationDetailsPage === MedicationPages.MedicationHistory ||
        medicationDetailsPage === MedicationPages.MedicineDetails
      ) && (
        <Col>
          <Space size={20}>
            <SearchBox
              value={searchValue}
              placeholder="Search by name"
              onChange={e => onSearchValueChange(e.target.value)}
            />
            {medicationDetailsPage ===
              MedicationPages.DiscontinuedMedication && (
              <AppSelect
                value={filterType.charAt(0).toUpperCase() + filterType.slice(1)}
                label="Filter By Reasons"
                onChange={type => {
                  setFilterType(type);
                }}
                style={{ marginRight: 5, width: "140px" }}
                options={filterOptions}
                dropdownStyle={{ borderRadius: "8px" }}
              />
            )}
            {(filterType === ReasonFilterOptions.PRESCRIBED ||
              medicationDetailsPage === MedicationPages.ActiveMedications) && (
              <span>
                {isLoadingReasonsList ? (
                  <SpinErrorContainer>
                    <Spin />
                  </SpinErrorContainer>
                ) : isReasonsListError ? (
                  <CommonErrorMessage message="Error fetching diagnosis list" />
                ) : (
                  <PrescriptionReasonCheckboxDropdown
                    setShowDropdown={setShowDropdown}
                    showDropdown={showDropdown}
                  />
                )}
              </span>
            )}

            {medicationDetailsPage === MedicationPages.DiscontinuedMedication &&
              filterType === ReasonFilterOptions.DISCONTINUED && (
                <span>
                  {isLoadingDiscontinuedReasons ? (
                    <SpinErrorContainer>
                      <Spin />
                    </SpinErrorContainer>
                  ) : isDiscontinuedReasonsError ? (
                    <CommonErrorMessage message="Error fetching diagnosis list" />
                  ) : (
                    <DiscontinuedReasonsCheckboxDropdown
                      setShowDropdown={setShowDiscontinuedDropdown}
                      showDropdown={showDiscontinuedDropdown}
                    />
                  )}
                </span>
              )}
          </Space>
        </Col>
      )}
      {medicationDetailsPage === MedicationPages.ActiveMedications && (
        <ButtonContainer>
          <Row>
            <Col style={{ paddingTop: "7px" }}>
              <AppButton
                type={ButtonType.Link}
                buttonContent={
                  <Row>
                    <DiscontinuedMedicationIcon />
                    <ButtonText>Discontinued Medication</ButtonText>
                  </Row>
                }
                onClick={() => {
                  setMedicaitonDetailsPage(
                    MedicationPages.DiscontinuedMedication
                  );
                  onSearchValueChange("");
                  setSelectedReasons("");
                }}
              />
            </Col>
            <Col>
              <AppButton
                type={ButtonType.Skeleton}
                buttonContent={
                  <Row>
                    <div>
                      <AddMedicationIcon />
                    </div>
                    <ButtonText>Add New Medication</ButtonText>
                  </Row>
                }
                onClick={() =>
                  setMedicaitonDetailsPage(MedicationPages.AddMedicationForm)
                }
              />
            </Col>
          </Row>
        </ButtonContainer>
      )}
    </RowStyled>
  );
};

const SpinErrorContainer = styled.div`
  padding: 5px;
  padding-top: 15px;
  color: ${Colors.Red};
`;

const ButtonText = styled.span`
  margin-left: 5px;
  font-family: Century Gothic Bold;

  @media screen and (max-width: 1260px) {
    display: none;
  }
`;

const RowStyled = styled(Row)`
  padding-bottom: 12px;
`;

const TitleCol = styled.div`
  padding-left: 20px !important;
  font-family: Century Gothic Bold;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 20px;
`;
