import React from "react";
import styled from "styled-components";
import AddMedication from "./addMedication.svg";

export const AddMedicationIcon: React.FC = () => (
  <StyledImg src={AddMedication} alt="discontinued medicaiton" />
);

const StyledImg = styled.img`
  margin-left: -5px;
`;
