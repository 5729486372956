import React, { useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

import { AllMessagesSection } from "./allMessagesSection";
import { PatientMessagesHeader } from "./patientMessagesHeader";
import { PatientMessagesTable } from "./patientMessagesTable";
import { IndividualMessagesSection } from "./individualMessagesSection";

import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { SelectedConversationUserInfo } from "pages/messages/messages";
import { UserRoles } from "constants/roles";
import { CaregiverAllMessagesSection } from "./CaregiverAllMessagesSection";

export const PatientMessagesPage: React.FC = () => {
  const [viewSelected, setViewSelected] = useState<string>("individual");
  const [showMessages, setShowMessages] = useState(false);
  const [selectedConversationUserInfo, setSelectedConversationUserInfo] =
    useState<SelectedConversationUserInfo | null>(null);
  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const onCloseMessageContainer = () => {
    setShowMessages(false);
    setSelectedConversationUserInfo(null);
  };

  return (
    <>
      <CommonPatientExtensionItems />
      <PatientMessagesHeader
        setViewSelected={setViewSelected}
        viewSelected={viewSelected}
      />
      {viewSelected === "individual" && (
        <Row>
          <Col span={24} style={{ height: "60vh", marginBottom: "18px" }}>
            <IndividualMessagesSection />
          </Col>
        </Row>
      )}
      {viewSelected === "all" && !userIsCaregiver && (
        <Row gutter={[20, 0]}>
          <Col span={showMessages ? 16 : 24} style={{ height: "72vh" }}>
            <PatientMessagesTable
              selectedConversationUserInfo={selectedConversationUserInfo}
              setSelectedConversationUserInfo={setSelectedConversationUserInfo}
              setShowMessages={setShowMessages}
            />
          </Col>
          <Col span={showMessages ? 8 : 0} style={{ height: "72vh" }}>
            <AllMessagesSection
              selectedConversationUserInfo={selectedConversationUserInfo}
              onCloseMessageContainer={onCloseMessageContainer}
            />
          </Col>
        </Row>
      )}
      {viewSelected === "all" && userIsCaregiver && (
        <CaregiverAllMessagesSection />
      )}
    </>
  );
};
