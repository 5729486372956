import React, { useContext, useMemo } from "react";
import { useParams } from "react-router";
import {
  NotificationAlertLevels,
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { SymptomsListItemContainer } from "./styles";
import { SymptomListItem } from "./SymptomsListItem";
import { createApiClient } from "apiClient";
import { useQuery } from "react-query";
import { NotificationProps } from "sharedTypes";
import { UserRoles } from "constants/roles";
interface SymptomsListProps {
  readonly data: SymptomsListData[];
  readonly onModalOpen: (selectedSymptom: string) => void;
}

interface SymptomDetail {
  readonly key: string;
  readonly value: string;
}

export interface Symptom {
  readonly id: string | number;
  readonly name: string;
  readonly description?: string;
  readonly data?: SymptomDetail[];
}

export interface SymptomsListData {
  readonly timestamp: string;
  readonly millisTimestamp: number;
  readonly reporterName: string;
  readonly reporterRole: string;
  readonly symptoms: Symptom[];
}

export const SymptomsList: React.FC<SymptomsListProps> = ({
  data,
  onModalOpen
}) => {
  const { id: patientId } = useParams();

  const userId = sessionStorage.getItem("userId") || "";

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const { refetch: getNotificationList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        userIsPatient
          ? UserRoles.PATIENT
          : userIsCaregiver
          ? UserRoles.CAREGIVER
          : UserRoles.PROVIDER
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      },
      refetchInterval: 60000
    }
  );

  const unseenSymptomNotifications = useMemo(() => {
    const patientNotifications =
      notificationsContext.filterNotificationsByStatusAndPatient(
        notificationsContext.notifications?.symptoms
          ? notificationsContext.notifications?.symptoms
          : [],
        patientId ? patientId : "",
        "unread"
      );
    return patientNotifications.filter(
      (item: NotificationProps) => item.status === "unread"
    );
  }, [notificationsContext.notifications]);

  return (
    <>
      {data.map((item, index) => {
        const rowSymptomIds = item.symptoms.map(item => String(item.id));
        const rowSpecificNotifications = unseenSymptomNotifications.filter(
          (item: NotificationProps) =>
            rowSymptomIds.includes(String(item.item_id))
        );
        const highestRowSeverity = rowSpecificNotifications.some(
          (item: NotificationProps) => item.severity === 2
        )
          ? NotificationAlertLevels.HIGH
          : rowSpecificNotifications.some(
              (item: NotificationProps) => item.severity === 1
            )
          ? NotificationAlertLevels.MODERATE
          : NotificationAlertLevels.LOW;

        return (
          <SymptomsListItemContainer
            key={index}
            data-testid="symptom-list-item"
          >
            <SymptomListItem
              symptomListItem={item}
              onModalOpen={onModalOpen}
              highestRowSeverity={highestRowSeverity}
              rowSpecificNotifications={rowSpecificNotifications}
              getNotificationsList={getNotificationList}
            />
          </SymptomsListItemContainer>
        );
      })}
    </>
  );
};
