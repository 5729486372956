import { Result } from "antd";
import React from "react";
import styled from "styled-components";
import { UserRoles } from "constants/roles";

import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";

export const ImagesPage: React.FC = () => {
  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const showTopbarExtesion = !userIsPatient;
  return (
    <>
      {showTopbarExtesion && <CommonPatientExtensionItems />}
      {/* <ImagesPageTitle>Images(Work In Progress)</ImagesPageTitle> */}
      <Result
        status="404"
        title="Work In Progress"
        subTitle="Sorry, the page you visited is still in development."
      />
    </>
  );
};

export const ImagesPageTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 18px;
  padding-top: 18px;
`;
