import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CaregiverRoutesRef } from "constants/routes";

import { CareGemLogo } from "./careGemLogo";

export const TopbarLogo: React.FC = () => {
  const navigate = useNavigate();

  return (
    <LogoContainerStyled onClick={() => navigate(CaregiverRoutesRef.Patients)}>
      <CareGemLogo />
    </LogoContainerStyled>
  );
};

const LogoContainerStyled = styled.div`
  top: 15px;
  left: 22px;
  position: fixed;
  cursor: pointer !important;
  z-index: 100;
`;
