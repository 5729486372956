import * as React from "react";

export const AddSymptomsIcon: React.FC = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <desc>Created with sketchtool.</desc>
    <defs>
      <linearGradient
        x1="41.3288455%"
        y1="0%"
        x2="52.6070599%"
        y2="100%"
        id="linearGradient-1"
      >
        <stop stopColor="#06BFE3" offset="0%"></stop>
        <stop stopColor="#069DDE" offset="100%"></stop>
      </linearGradient>
      <path
        d="M13.9245144,11.8767101 C13.9245144,10.9856484 13.4911158,10.2049087 12.8150139,9.70421691 L12.8150139,3.18673732 C12.8150139,2.28718936 12.0695683,1.55736742 11.1507633,1.55736742 C10.2319583,1.55736742 9.48651265,2.28718936 9.48651265,3.18673732 L9.48651265,9.70421691 C8.81041083,10.2049087 8.37701222,10.9856484 8.37701222,11.8767101 C8.37701222,13.3787855 9.61653223,14.5923266 11.1507633,14.5923266 C12.6849944,14.5923266 13.9245144,13.3787855 13.9245144,11.8767101 Z M15.0340148,11.8767101 C15.0340148,13.9728266 13.2917524,15.6785732 11.1507633,15.6785732 C9.00977418,15.6785732 7.26751179,13.9728266 7.26751179,11.8767101 C7.26751179,10.8413813 7.69224242,9.90788814 8.37701222,9.22049772 L8.37701222,3.18673732 C8.37701222,1.68466195 9.61653223,0.471120825 11.1507633,0.471120825 C12.6849944,0.471120825 13.9245144,1.68466195 13.9245144,3.18673732 L13.9245144,9.22049772 C14.6092842,9.90788814 15.0340148,10.8413813 15.0340148,11.8767101 Z"
        id="path-2"
      ></path>
      <filter
        x="-48.3%"
        y="-18.1%"
        width="196.6%"
        height="149.3%"
        filterUnits="objectBoundingBox"
        id="filter-3"
      >
        <feMorphology
          radius="0.25"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="1"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="1"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0.0235294118   0 0 0 0 0.615686275   0 0 0 0 0.870588235  0 0 0 0.15 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
      <path
        d="M12.8150141,11.8767104 C12.8150141,12.7762583 12.0695684,13.5060803 11.1507634,13.5060803 C10.2319583,13.5060803 9.4865127,12.7762583 9.4865127,11.8767104 C9.4865127,11.1978062 9.91991132,10.5783062 10.5960132,10.3406898 L10.5960132,6.98860056 C10.6241393,6.46757593 10.8090561,6.20706361 11.1507634,6.20706361 C11.4924707,6.20706361 11.6773875,6.46757593 11.7055136,6.98860056 L11.7055136,10.3406898 C12.3816155,10.5783062 12.8150141,11.1978062 12.8150141,11.8767104 Z"
        id="path-4"
      ></path>
      <filter
        x="-112.7%"
        y="-33.6%"
        width="325.3%"
        height="198.6%"
        filterUnits="objectBoundingBox"
        id="filter-5"
      >
        <feMorphology
          radius="0.25"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="1"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="1"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0.0235294118   0 0 0 0 0.615686275   0 0 0 0 0.870588235  0 0 0 0.15 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
      <path
        d="M2.8,5 L4.359,5 C4.52468542,5 4.659,5.13431458 4.659,5.3 L4.659,7.55311491 L6.99287098,7.55311491 C7.1585564,7.55311491 7.29287098,7.68742948 7.29287098,7.85311491 L7.29287098,9.24382492 C7.29287098,9.40951034 7.1585564,9.54382492 6.99287098,9.54382492 L4.659,9.54382492 L4.659,11.9363656 C4.659,12.102051 4.52468542,12.2363656 4.359,12.2363656 L2.8,12.2363656 C2.63431458,12.2363656 2.5,12.102051 2.5,11.9363656 L2.5,9.54382492 L0.3,9.54382492 C0.134314575,9.54382492 -1.46242841e-16,9.40951034 -1.66533454e-16,9.24382492 L0,7.85311491 C-2.41027941e-16,7.68742948 0.134314575,7.55311491 0.3,7.55311491 L2.5,7.55311491 L2.5,5.3 C2.5,5.13431458 2.63431458,5 2.8,5 Z"
        id="path-6"
      ></path>
      <filter
        x="-48.0%"
        y="-34.5%"
        width="196.0%"
        height="196.7%"
        filterUnits="objectBoundingBox"
        id="filter-7"
      >
        <feOffset
          dx="0"
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="1"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0.0235294118   0 0 0 0 0.615686275   0 0 0 0 0.870588235  0 0 0 0.15 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="CareGEM-Symptoms-V1.1"
        transform="translate(-1145.000000, -154.000000)"
      >
        <g
          id="icon-button-secondary-symptoms"
          transform="translate(1132.000000, 141.000000)"
        >
          <g id="symptoms">
            <g id="Symptoms-icon" transform="translate(15.000000, 14.000000)">
              <g id="">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-3)"
                  xlinkHref="#path-2"
                ></use>
                <use
                  stroke="#208CFF"
                  strokeWidth="0.5"
                  fill="#208CFF"
                  fillRule="evenodd"
                  xlinkHref="#path-2"
                ></use>
              </g>
              <g id="Path">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-5)"
                  xlinkHref="#path-4"
                ></use>
                <use
                  stroke="#208CFF"
                  strokeWidth="0.5"
                  fill="#208CFF"
                  fillRule="evenodd"
                  xlinkHref="#path-4"
                ></use>
              </g>
              <g id="Path-2">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-7)"
                  xlinkHref="#path-6"
                ></use>
                <use
                  fill="#208CFF"
                  fillRule="evenodd"
                  xlinkHref="#path-6"
                ></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
