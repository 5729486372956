import React from "react";

export const GFRIcon = () => {
  return (
    <svg
      width="70px"
      height="70px"
      viewBox="0 0 70 70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stopColor="#F69907" offset="0%"></stop>
          <stop stopColor="#F27B6E" offset="75.0564487%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Patient-MyHealthStatus-MyCurrentStatus-V1.1-O2"
          transform="translate(-279.000000, -172.000000)"
        >
          <g id="Group-3-Copy-11" transform="translate(255.000000, 148.000000)">
            <g id="gfr-icon" transform="translate(24.000000, 24.000000)">
              <path
                d="M35,-2.72848411e-12 L35,-2.72848411e-12 C54.3299662,-2.73203496e-12 70,15.6700338 70,35 L70,35 C70,54.3299662 54.3299662,70 35,70 L35,70 C15.6700338,70 7.27832485e-12,54.3299662 7.27595761e-12,35 L7.27595761e-12,35 C7.27359038e-12,15.6700338 15.6700338,-2.72493325e-12 35,-2.72848411e-12 Z"
                id="Rectangle-Copy-204"
                fill="#EBF3FB"
              ></path>
              <path
                d="M60.7405892,34.8196346 C60.7405892,49.1578809 49.1176431,60.7808271 34.7793968,60.7808271 C20.4411505,60.7808271 8.81820438,49.1578809 8.81820438,34.8196346 C8.81820438,20.4813884 20.4411505,8.8584422 34.7793968,8.8584422 C40.1556536,8.8584422 45.150569,10.4934314 49.2947279,13.2921207 C52.9422998,15.7563164 55.9293173,19.1237383 57.9372152,23.0730102 C59.7296131,26.6006517 60.7405892,30.5920866 60.7405892,34.8196346"
                id="Fill-314"
                fill="#FFFFFF"
              ></path>
              <path
                d="M57.9448046,34.8196346 C57.9448046,47.6137765 47.5736324,57.9858856 34.7794905,57.9858856 C21.9853486,57.9858856 11.6141765,47.6137765 11.6141765,34.8196346 C11.6141765,22.0264297 21.9853486,11.6543206 34.7794905,11.6543206 C47.5736324,11.6543206 57.9448046,22.0264297 57.9448046,34.8196346"
                id="Fill-316"
                fill="#FFFFFF"
              ></path>
              <path
                d="M24.3347697,37.5470246 L12.1724057,39.3556755 C10.9761582,30.831982 13.0069027,23.8831673 18.264639,18.5092316 C23.5223754,13.1352958 29.8958526,10.9301578 37.3850707,11.8938177 L36.2161285,24.242241 C32.6268656,23.8519025 29.5016679,25.0697144 26.8405354,27.8956767 C24.1794029,30.7216389 23.3441476,33.9387549 24.3347697,37.5470246 Z"
                id="Path-10"
                fill="url(#linearGradient-1)"
              ></path>
              <path
                d="M42.9121615,28.5608146 C41.1141884,25.9816505 38.7782137,24.5710361 35.9042372,24.3289713 C36.230892,22.0463505 36.5254683,17.3958562 37.1493214,12.0780053 C43.7309373,12.7626435 49.0129911,15.6309286 52.9954828,20.6828606 L42.9121615,28.5608146 Z"
                id="Path-16"
                fill="#E0D123"
              ></path>
              <path
                d="M42.5376373,28.0814455 L52.6209586,20.2034915 C56.7617782,25.7416983 58.448304,31.8891757 57.6805361,38.6459237 C52.2369438,37.7947897 47.4798355,37.3661184 45.1302634,37.059523 C45.5222908,34.1248378 44.6580821,31.1321453 42.5376373,28.0814455 Z"
                id="Path-16-Copy"
                fill="#80B844"
              ></path>
              <path
                d="M52.5303711,20.9510536 C60.1776458,30.7391189 58.4360432,44.9233342 48.6479779,52.5706089 C38.8599126,60.2178836 24.6756973,58.476281 17.0284225,48.6882157 C9.38114782,38.9001504 11.1227505,24.7159351 20.9108158,17.0686604 C30.6988811,9.42138565 44.8830963,11.1629883 52.5303711,20.9510536 Z M16.0213379,49.4750364 C7.94027344,39.1317456 9.77996583,24.143217 20.123995,16.0615757 C30.4672859,7.98051127 45.4563912,9.82094199 53.5374557,20.1642328 C61.6185202,30.5075237 59.7780895,45.4966291 49.4347986,53.5776935 C39.0907694,61.6593349 24.1024024,59.8183273 16.0213379,49.4750364 Z"
                id="Fill-318"
                fill="#212B40"
              ></path>
              <path
                d="M45.4299694,34.8196346 C45.4299694,40.7018479 40.6617975,45.4709568 34.7795842,45.4709568 C28.8964339,45.4709568 24.128262,40.7018479 24.128262,34.8196346 C24.128262,28.9383583 28.8964339,24.1692494 34.7795842,24.1692494 C40.6617975,24.1692494 45.4299694,28.9383583 45.4299694,34.8196346"
                id="Fill-320"
                fill="#A3A3A3"
              ></path>
              <path
                d="M25.8829411,41.7703076 C22.0503625,36.8648309 22.9226056,29.7555451 27.8280824,25.9229666 C32.7335591,22.0903881 39.8426834,22.9639463 43.6752619,27.8694231 C47.5078404,32.7748999 46.6350205,39.8834473 41.7295437,43.7160258 C36.824067,47.5486043 29.7155196,46.6757844 25.8829411,41.7703076 Z"
                id="Fill-322"
                fill="#212B40"
              ></path>
              <path
                d="M42.6681773,28.6562439 C46.066966,33.0064951 45.2929742,39.3101524 40.942723,42.7089412 C36.5924718,46.1077299 30.2888145,45.3337381 26.8900257,40.9834869 C23.4912369,36.6332356 24.2646519,30.32884 28.6149031,26.9300513 C32.9651544,23.5312625 39.2693885,24.3059926 42.6681773,28.6562439 Z"
                id="Path"
                fill="#FFFFFF"
              ></path>
              <path
                d="M30.9161823,25.7459126 L44.4916404,29.3834456 C45.4913095,29.6513062 46.0845576,30.678842 45.8166971,31.6785111 C45.5488365,32.6781802 44.5213007,33.2714283 43.5216316,33.0035678 L29.9461734,29.3660347 C28.9465043,29.0981742 28.3532562,28.0706384 28.6211168,27.0709693 C28.8889773,26.0713001 29.9165131,25.478052 30.9161823,25.7459126 Z"
                id="Rectangle"
                fill="#212B40"
                transform="translate(37.218907, 29.374740) rotate(101.000000) translate(-37.218907, -29.374740) "
              ></path>
              <path
                d="M8.1792,34.8192599 C8.1792,20.1521418 20.1122788,8.22 34.7793968,8.22 C40.1097427,8.22 45.2526973,9.7903392 49.6526453,12.7623654 C49.9449757,12.9600632 50.0218062,13.3573328 49.8241083,13.6506002 C49.6264105,13.9429307 49.2291409,14.0188242 48.9368105,13.8220633 C44.7495516,10.9933914 39.8539536,9.49800875 34.7793968,9.49800875 C20.8168701,9.49800875 9.45720875,20.8576701 9.45720875,34.8192599 C9.45720875,48.7827235 20.8168701,60.1423849 34.7793968,60.1423849 C48.7419235,60.1423849 60.1015849,48.7827235 60.1015849,34.8192599 C60.1015849,34.4669642 60.3882936,34.1811924 60.7405892,34.1811924 C61.0928849,34.1811924 61.3795936,34.4669642 61.3795936,34.8192599 C61.3795936,49.4873148 49.4465148,61.4203936 34.7793968,61.4203936 C20.1122788,61.4203936 8.1792,49.4873148 8.1792,34.8192599 Z"
                id="Fill-324"
                fill="#212B40"
              ></path>
              <path
                d="M36.1690152,24.6576004 C35.8184155,24.6145522 35.5757035,24.2463798 35.62613,23.8356893 L37.00423,12.6119655 C37.0546564,12.2012751 37.3792278,11.9027563 37.7298274,11.9458045 C38.0794972,11.9887386 38.3231391,12.3570251 38.2727126,12.7677156 L36.8946126,23.9914394 C36.8441862,24.4021299 36.5186849,24.7005344 36.1690152,24.6576004 Z"
                id="Fill-334"
                fill="#212B40"
              ></path>
              <path
                d="M45.5984391,37.7606217 C45.3379756,37.7286408 45.1613917,37.4190411 45.2044399,37.0684415 C45.247374,36.7187717 45.4937257,36.4601581 45.7541892,36.492139 L52.6263055,37.3417997 C52.886769,37.3737806 53.3093067,37.7086526 53.2663727,38.0583223 C53.2233244,38.408922 52.7244442,38.6426876 52.4639807,38.6107067 L45.5984391,37.7606217 Z"
                id="Fill-334-Copy"
                fill="#212B40"
              ></path>
              <path
                d="M11.9088628,39.2468941 C11.8597023,38.8970991 12.1525111,38.5673674 12.5622589,38.5097811 L23.7602221,36.93601 C24.16997,36.8784237 24.5423222,37.1146732 24.5914827,37.4644682 C24.6405128,37.8133353 24.3478344,38.1439949 23.9380866,38.2015812 L12.7401234,39.7753523 C12.3303755,39.8329386 11.9578929,39.5957613 11.9088628,39.2468941 Z"
                id="Fill-336-Copy"
                fill="#212B40"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
