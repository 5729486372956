import React from "react";

export const FluidMetricsIcon: React.FC = () => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Provider-Global-navigation-V2.3"
          transform="translate(-13.000000, -306.000000)"
        >
          <g
            id="medication-menu-copy-41"
            transform="translate(14.000000, 307.000000)"
          >
            <g id="Group-3" transform="translate(7.000000, 5.000000)">
              <g id="Rectangle-Copy-212">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill="#7CBA5B"
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <rect
                id="Rectangle"
                fill="#FFFFFF"
                x="6"
                y="12.6666667"
                width="1.33333333"
                height="4.66666667"
                rx="0.3"
              ></rect>
              <rect
                id="Rectangle-Copy-5"
                fill="#FFFFFF"
                x="8.66666667"
                y="11.3333333"
                width="1.33333333"
                height="6"
                rx="0.3"
              ></rect>
              <rect
                id="Rectangle-Copy-6"
                fill="#FFFFFF"
                x="11.3333333"
                y="12"
                width="1.33333333"
                height="5.33333333"
                rx="0.3"
              ></rect>
              <rect
                id="Rectangle-Copy-7"
                fill="#FFFFFF"
                x="14"
                y="10.6666667"
                width="1.33333333"
                height="6.66666667"
                rx="0.3"
              ></rect>
              <path
                d="M16.9666667,10 L17.7,10 C17.8656854,10 18,10.1343146 18,10.3 L18,17.0333333 C18,17.1990188 17.8656854,17.3333333 17.7,17.3333333 L16.9666667,17.3333333 C16.8009812,17.3333333 16.6666667,17.1990188 16.6666667,17.0333333 L16.6666667,10.3 C16.6666667,10.1343146 16.8009812,10 16.9666667,10 Z"
                id="Rectangle-Copy-8"
                fill="#FFFFFF"
              ></path>
              <path
                d="M7.26948835,10.8136058 C7.11225639,11.146535 6.71490225,11.2889658 6.38197303,11.1317339 C6.04904381,10.9745019 5.90661303,10.5771478 6.06384499,10.2442186 C6.63781226,9.02887733 7.52418196,8.36557612 8.66666667,8.36557612 C9.27988637,8.36557612 9.69790499,8.51349894 10.2664218,8.84746439 C10.3043811,8.86976291 10.3594928,8.90252071 10.4473468,8.95467156 C10.9527001,9.25306862 11.2617776,9.3585554 11.8243353,9.3585554 C12.3532814,9.3585554 12.627072,9.2268303 13.0344057,8.8584695 C13.0652751,8.83055364 13.2086937,8.69859729 13.2495295,8.66179183 C13.7085005,8.24811919 14.0821139,8.04314104 14.6344551,8.01642216 C15.1467401,7.99164095 15.6633581,7.6718336 16.1927145,7.00072706 C16.4207379,6.71164389 16.8399356,6.66214519 17.1290187,6.8901686 C17.4181019,7.11819202 17.4676006,7.53738973 17.2395772,7.82647289 C16.4900919,8.77665416 15.6403905,9.30265361 14.6988782,9.3481982 C14.5185237,9.35692265 14.3940426,9.42521758 14.142196,9.65220802 C14.1078844,9.68313329 13.9655575,9.81408516 13.9287176,9.84740031 C13.2971975,10.4184978 12.7289543,10.6918887 11.8243353,10.6918887 C10.9893018,10.6918887 10.4627075,10.5121644 9.76941294,10.1027933 C9.67669151,10.0477603 9.62542552,10.0172884 9.59108051,9.99711298 C9.20886626,9.77258778 9.00065691,9.69890945 8.66666667,9.69890945 C8.08492428,9.69890945 7.63796065,10.0333878 7.26948835,10.8136058 Z"
                id="Path-16"
                fill="#FFFFFF"
              ></path>
              <path
                d="M15.0025901,7.36533981 L16.8644585,9.15892151 C16.9440085,9.23555394 17.0706194,9.23318875 17.1472518,9.15363871 C17.1738459,9.12603213 17.1919464,9.09136461 17.1993982,9.05376358 L17.7203167,6.42527378 C17.7417896,6.3169241 17.671362,6.2116821 17.5630124,6.19020918 C17.5279711,6.18326465 17.4917094,6.18583221 17.4579963,6.197645 L15.0752095,7.0325531 C14.9709665,7.06907895 14.9160709,7.18319464 14.9525967,7.28743762 C14.9629401,7.31695712 14.9800631,7.34363906 15.0025901,7.36533981 Z"
                id="Path-8"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
