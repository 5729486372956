import React from "react";

export const StandingPostureIcon: React.FC = () => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23657 3.97321C7.14282 3.97321 6.24994 3.09151 6.24994 1.9866C6.24994 0.892851 7.14282 0 8.23657 0C9.34148 0 10.2343 0.892851 10.2343 1.9866C10.2343 3.09151 9.34148 3.97321 8.23657 3.97321ZM15.4688 4.97641C15.7552 4.97641 15.9987 5.0767 16.1992 5.27722C16.3997 5.47774 16.5 5.7212 16.5 6.00766C16.5 6.29412 16.3997 6.53764 16.1992 6.73816C15.9987 6.93868 15.7552 7.03891 15.4688 7.03891H11.2578C10.8568 7.15349 10.599 7.35404 10.4844 7.6405C10.3411 7.98425 10.3555 8.72904 10.5273 9.87488L10.6992 10.7772V10.8202L11.9883 18.2108C12.0456 18.5259 11.9883 18.7837 11.8164 18.9843C11.6159 19.2421 11.401 19.3853 11.1719 19.4139C10.8568 19.4712 10.599 19.4139 10.3984 19.242C10.1406 19.0415 9.9974 18.8123 9.96875 18.5545L9.06641 13.3983V13.4413L8.98047 13.0545C8.92318 12.7967 8.83724 12.5676 8.72266 12.367C8.57943 12.1379 8.4362 12.0233 8.29297 12.0233H8.20703C7.86328 12.0233 7.60547 12.496 7.43359 13.4413V13.3983L6.53125 18.5545C6.5026 18.8123 6.35938 19.0415 6.10156 19.242C5.90104 19.4139 5.64323 19.4712 5.32812 19.4139C5.09896 19.3853 4.88412 19.2421 4.68359 18.9843C4.51172 18.7837 4.45443 18.5259 4.51172 18.2108L5.80078 10.8202V10.7772L5.97266 9.87488C6.14453 8.72904 6.15886 7.98425 6.01562 7.6405C5.90104 7.35404 5.64323 7.15349 5.24219 7.03891H1.03125C0.74479 7.03891 0.501303 6.93868 0.300781 6.73816C0.100259 6.53764 0 6.29412 0 6.00766C0 5.7212 0.100259 5.47774 0.300781 5.27722C0.501303 5.0767 0.74479 4.97641 1.03125 4.97641H15.4688Z"
        fill="#212B40"
      />
    </svg>
  );
};
