import React from "react";

export const SeatedPostureIcon: React.FC = () => {
  return (
    <svg
      width="15px"
      height="18px"
      viewBox="0 0 15 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Vital-BloodPressure-V1.1-(2)"
          transform="translate(-768.000000, -324.000000)"
          fill="#212B40"
        >
          <path
            d="M782.696,340.588 L781.354,341.93 L778.142,338.696 L773.5,338.696 C773.015998,338.696 772.561335,338.571335 772.136,338.322 C771.710665,338.072665 771.373335,337.735335 771.124,337.31 C770.874665,336.884665 770.75,336.430002 770.75,335.946 L770.75,330.666 C770.75,330.108664 770.955331,329.624669 771.366,329.214 C771.776669,328.803331 772.260664,328.598 772.818,328.598 L772.862,328.598 C773.404669,328.598 773.903331,328.825331 774.358,329.28 L775.656,330.71 C776.154669,331.238003 776.807329,331.677998 777.614,332.03 C778.420671,332.382002 779.197996,332.558 779.946,332.558 L779.946,334.56 C779.109996,334.56 778.208005,334.354669 777.24,333.944 C776.345329,333.577332 775.56067,333.115336 774.886,332.558 L774.886,335.946 L778.054,335.946 L782.696,340.588 Z M769.848,336.848 C769.848,337.332002 769.972665,337.786665 770.222,338.212 C770.471335,338.637335 770.808665,338.974665 771.234,339.224 C771.659335,339.473335 772.113998,339.598 772.598,339.598 L778.098,339.598 L778.098,341.446 L772.598,341.446 C771.761996,341.446 770.992004,341.240669 770.288,340.83 C769.583996,340.419331 769.026669,339.862004 768.616,339.158 C768.205331,338.453996 768,337.684004 768,336.848 L768,328.598 L769.848,328.598 L769.848,336.848 Z M771.345238,327.404762 C770.948411,326.992061 770.75,326.519844 770.75,325.988095 C770.75,325.456347 770.948411,324.992065 771.345238,324.595238 C771.742065,324.198411 772.206347,324 772.738095,324 C773.269844,324 773.738093,324.198411 774.142857,324.595238 C774.547621,324.992065 774.75,325.456347 774.75,325.988095 C774.75,326.519844 774.551589,326.988093 774.154762,327.392857 C773.757935,327.797621 773.293653,328 772.761905,328 C772.230156,328 771.757939,327.801589 771.345238,327.404762 Z"
            id=""
          ></path>
        </g>
      </g>
    </svg>
  );
};
