import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Spin, message } from "antd";
import "./respond.css";
import styled from "styled-components";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { useSurveyContext } from "../../../contextApis/surveyContext";
import { useNavigate } from "react-router-dom";
import {
  CaregiverRoutesRef,
  PatientsRoutesRef,
  ProvidersRoutesRef
} from "constants/routes";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { SurveyResponseDict, QuestionAnswer } from "sharedTypes";
import { UserContext, UserContextState } from "contextApis/userContext";
import { useLocation } from "react-router-dom";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import moment from "moment";
import PAM from "../surveyQuestions/PAM";
import PHQ9 from "../surveyQuestions/PHQ9";
import SDoH from "../surveyQuestions/SDOH";
import { PatientProfileData } from "sharedTypes";

interface RespondPageProps {
  readonly setSurveyLocationHandler?: (flag: any) => void | undefined;
  readonly setPostSurveyPayload?: (flag: any) => void | undefined;
  readonly setSurveyIdHandler?: (flag: any) => void | undefined;
  readonly setSurveyHandler?: (flag: any) => void | undefined;
}

export const RespondPage: React.FC<RespondPageProps> = ({
  setSurveyLocationHandler,
  setPostSurveyPayload,
  setSurveyIdHandler,
  setSurveyHandler
}) => {
  const patientContext = useContext<PatientContextState>(PatientContext);
  const userContext = useContext<UserContextState>(UserContext);
  const navigate = useNavigate();
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [printingState, setPrintingState] = useState<boolean>(false);
  const [iframeWidth, setIframeWidth] = useState<string>("100%");
  const [iframeHeight, setIframeHeight] = useState<string>("500px");

  const printing = () => {
    setTimeout(() => {
      window.print();
      setPrintingState(false);
    }, 47);
    setPrintingState(true);
  };

  const openPrintPreview = () => {
    const fileUrls = `data:${
      uploadedSurvey?.type === "jpg" ? "image/jpeg" : "application/pdf"
    };base64,${uploadedSurvey?.data}`;

    const fileType =
      uploadedSurvey.type === "jpg" ? "image/jpeg" : "application/pdf";
    const byteCharacters = atob(uploadedSurvey.data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const fileUrl = URL.createObjectURL(blob);

    // Open print preview
    const printWindow = window.open(fileUrl);
    printWindow?.print();
  };

  const { surveyresponses, surveyName, surveyId, selectedSurvey } =
    useSurveyContext();
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const location = useLocation();
  const isViewForm = location.pathname.includes("/surveys/viewform/");
  const isRespond = location.pathname.includes("/surveys/respond/");
  const isScan = location.pathname.includes("/surveys/viewform/scan");

  useEffect(() => {
    if (localStorage.getItem("firstLoad") == "Yes") {
      localStorage.setItem("firstLoad", "No");
    } else {
      back();
    }
  }, []);

  const currentPatientId = useMemo(
    () => window.location.pathname.split(/\//).slice(-1)[0],
    [window.location.pathname]
  );

  const {
    isLoading: isLoadingReviewSurveyResponse,
    data: reviewSurveyResponse,
    error: isReviewSurveyResponseError,
    isFetching: isFetchingReviewSurveyResponse,
    refetch: refetchReviewSurveyResponse
  } = useQuery(
    "surveyResponse",
    async () => {
      const data = await createApiClient().getReviewSurveyResponse(surveyId);
      refetchPatientSurveyNotification();
      back();
    },
    {
      enabled: false
    }
  );

  const callPatientProfileAPI = patientContext.patientData ? true : false;

  const { data, isFetching, isLoading } = useQuery<PatientProfileData>(
    "patient-profile",
    async () => {
      return await createApiClient().getPatientProfile(
        patientContext.patientData?.id.toString() || "",
        true
      );
    },
    { enabled: callPatientProfileAPI }
  );

  const {
    isLoading: isLoadingPatientSurveyNotification,
    data: patientSurveyNotification,
    error: isPatientSurveyNotificationError,
    isFetching: isFetchingPatientSurveyNotification,
    refetch: refetchPatientSurveyNotification
  } = useQuery(
    "PatientSurveyNotification",
    async () => {
      const data = await createApiClient().getPatientSurveyNotification(
        Number(sessionStorage.getItem("patientId"))
      );
      sessionStorage.setItem("surveyNotification", data);
      notificationsContext.setPatientSurveyNotifications(data ? data : null);
    },
    {
      enabled: false
    }
  );

  const {
    isLoading: isLoadingPostSurvey,
    data: PostSurvey,
    error: isErrorPostSurvey,
    isFetching: isFetchingPostSurvey,
    refetch
  } = useQuery(
    "postsurveyid",
    async () => {
      return await createApiClient().postSurveyResponse(
        postSurveyObj,
        currentPatientId,
        surveyId
      );
    },
    {
      enabled: false
    }
  );

  const {
    isLoading: isLoadingUploadedSurvey,
    data: uploadedSurvey,
    error: isuploadedSurveyError,
    isFetching: isFetchingUploadedSurvey,
    refetch: refetchUploadedSuvey
  } = useQuery(
    "uploadedPatientSurvey",
    async () => {
      if (isScan) {
        return await createApiClient().getUploadedSurvey(surveyId);
      }
    },
    {
      enabled: true
    }
  );

  // useEffect(() => {
  //   if (uploadedSurvey?.type === "jpg") {
  //     const img = new Image();
  //     img.src = `data:image/jpeg;base64,${uploadedSurvey?.data}`;
  //     img.onload = () => {
  //       setIframeWidth(`${img.width}px`);
  //       setIframeHeight(`${img.height}px`);
  //     };
  //   } else if (uploadedSurvey?.type === "pdf") {
  //     setIframeWidth("600px");
  //     setIframeHeight("800px");
  //   }
  // }, [uploadedSurvey]);

  const addOptionsToQuestions = (questions: QuestionAnswer[]) => {
    let options: any[] = [];
    if (surveyName == "PAM") {
      options = [
        "Disagree Strongly",
        "Disagree",
        "Agree",
        "Agree Strongly",
        "N/A"
      ];
    }
    if (surveyName == "PHQ9") {
      options = [
        "Not at all",
        "Several days",
        "More than half the days",
        "Nearly every day"
      ];
    }
    if (surveyName == "SDoH") {
      options = ["Yes", "No", "Patient Declines"];
    }

    return questions.map(question => ({
      ...question,
      options
    }));
  };

  const calculateHealthCareQuestions = () => {
    if (surveyresponses && surveyresponses["response"]?.length > 0) {
      // console.log("if response", surveyresponses);
      return addOptionsToQuestions(surveyresponses["response"]);
    }
    if (surveyName == "PAM") {
      // console.log("if pam", PAM);
      return addOptionsToQuestions(PAM);
    }
    if (surveyName == "PHQ9") {
      return addOptionsToQuestions(PHQ9);
    }
    if (surveyName == "SDoH") {
      return addOptionsToQuestions(SDoH);
    }
  };

  const healthCareQuestions = calculateHealthCareQuestions();
  // console.log("calculateHealthCareQuestions", healthCareQuestions);

  const initialResponses = healthCareQuestions?.map(question => ({
    q_id: question.q_id,
    question: question.question,
    answer: question.answer,
    issue: question.issue,
    action_item: question.action_item,
    action_response: question.action_response
  }));

  const [responses, setResponses] = useState(initialResponses || []);

  const [postSurveyObj, setPostSurveyObj] = useState<SurveyResponseDict>({
    data_json: surveyresponses,
    id: surveyId,
    task: surveyName
  });

  useEffect(() => {
    setPostSurveyPayload && setPostSurveyPayload(responses);
    setSurveyIdHandler && setSurveyIdHandler(surveyId);
    setSurveyHandler && setSurveyHandler(currentPatientId);
    setSurveyLocationHandler && setSurveyLocationHandler(location);
  }, [responses, surveyId, currentPatientId, location]);

  useEffect(() => {
    const isAllAnswered = responses.every(response => response.answer !== "");
    setAllQuestionsAnswered(isAllAnswered);
  }, [responses]);

  const handleOptionChange = (questionIndex: number, answer: string) => {
    if (healthCareQuestions) {
      const newResponses = [...responses];
      newResponses[questionIndex] = {
        q_id:
          surveyName === "PAM"
            ? healthCareQuestions[questionIndex].q_id
            : healthCareQuestions[questionIndex].q_id,
        question: healthCareQuestions[questionIndex].question,
        answer,
        issue:
          surveyName === "PAM" &&
          (answer == "Disagree Strongly" ||
            answer == "Disagree" ||
            answer === "N/A")
            ? healthCareQuestions[questionIndex].issue
            : "",
        action_item:
          surveyName === "PAM" &&
          (answer === "Disagree Strongly" || answer === "Disagree")
            ? healthCareQuestions[questionIndex].action_item
            : answer === "N/A"
            ? `No Patient Response: ${healthCareQuestions[questionIndex].action_item}`
            : "",
        action_response: ""
      };
      // console.log("newResponses", healthCareQuestions, newResponses);
      setResponses(newResponses);
    }
  };

  const postResponse = (type: string) => {
    const tempPostSurveyObj = {
      data_json: {
        response: responses
      },
      id: surveyId,
      task: type
    };
    tempPostSurveyObj.data_json.response.forEach((response: any) => {
      if (
        response.answer === "Disagree" ||
        response.answer === "Disagree Strongly" ||
        response.answer === "N/A"
      ) {
        // response.issue = response.issue;
      } else {
        response.issue = "";
        response.action_item = "";
        response.action_response = "";
      }
    });
    postSurveyObj.task = tempPostSurveyObj.task;
    postSurveyObj.id = tempPostSurveyObj.id;
    postSurveyObj.data_json = tempPostSurveyObj.data_json;
    // console.log("post", postSurveyObj, tempPostSurveyObj.data_json.response);
    refetch();
  };

  useEffect(() => {
    if (PostSurvey) {
      if (PostSurvey == "success") {
        message.success("Submitted Successfully");
        back();
      }
    }
  }, [PostSurvey]);

  async function back() {
    reFetchPostSurvey();
    closeSurveyEdit();
    const id =
      sessionStorage.getItem("userRole") == "patient"
        ? sessionStorage.getItem("userId")
        : sessionStorage.getItem("patientId");
    if (sessionStorage.getItem("userRole") == "caregiver") {
      navigate(CaregiverRoutesRef.Survey + "/" + id);
    } else if (sessionStorage.getItem("userRole") == "patient") {
      navigate(PatientsRoutesRef.Survey + "/" + id);
    } else {
      navigate(ProvidersRoutesRef.Survey + "/" + id);
    }
  }

  const closeSurveyEdit = async () => {
    console.log("selectedSurveyIdinAPI", surveyId);

    try {
      // setLoading(true);
      const responseMessage = await createApiClient().closeEditSurvey(
        currentPatientId,
        surveyId
      );
      if (responseMessage) {
        // sessionStorage.setItem("surveyNotification", "0");
        // notificationsContext.setPatientSurveyNotifications(null);
        console.log("Close_resMesg", responseMessage);
      } else {
        console.log("ELSE_NOresMesg_CLOSE", responseMessage);
      }
      return responseMessage;
      // setLoading(false);
    } catch (error: any) {
      // setLoading(false);
      message.error(error.message);
    }
  };

  const submitterNameTemp = selectedSurvey?.submitter_name;
  const submitterName = submitterNameTemp?.replace(/\s*\[-\]\s*/, " ");
  const assignerName = selectedSurvey?.assigner_name;

  const responseRef = useRef<any>();

  useEffect(() => {
    responseRef.current = responses;
  }, [responses]);

  const { refetch: reFetchPostSurvey } = useQuery(
    "postsurveyid",
    async () => {
      const tempPostSurveyObj = {
        data_json: {
          response: responseRef.current
        },
        id: surveyId,
        task: "draft"
      };
      return await createApiClient().postSurveyResponse(
        tempPostSurveyObj,
        currentPatientId,
        surveyId
      );
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    return () => {
      reFetchPostSurvey();
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("userRole") !== "patient" && (
        <CommonPatientExtensionItems />
      )}
      <SurveyContainer>
        <div id="print-section">
          {isRespond ? (
            <h2>Health Care Survey</h2>
          ) : (
            <h2> {surveyName} Survey Form</h2>
          )}
          {isRespond && (
            <>
              {sessionStorage.getItem("userRole") !== "patient" ? (
                <IntroMessage>
                  <p>
                    You are filling {surveyName} survey on behalf of Patient{" "}
                    <b>{` ${" "} ${
                      patientContext.patientData?.first_name
                    } ${" "} ${patientContext.patientData?.last_name}`}</b>
                    .
                  </p>

                  <p>
                    Please explain the below to patient before discussing
                    questions and responses.
                  </p>
                  <ul>
                    <li>
                      The questionnaire should take less than 5 minutes to
                      complete.
                    </li>
                    <li>Responses remain confidential.</li>
                    <li>It’s important to be truthful.</li>
                    <li>
                      Your answers should not be influenced by what others think
                      or say.
                    </li>
                    <li>There are no right or wrong answers.</li>
                    {surveyName === "PAM" && (
                      <li>It’s OK to disagree with some of the statements.</li>
                    )}
                  </ul>
                  <p>
                    Please discuss each question with the patient and document
                    their response for it.
                  </p>
                  {surveyName == "PHQ9" && (
                    <p>
                      Over the last 2 weeks, how often have you been bothered by
                      any of the following problems?
                    </p>
                  )}
                </IntroMessage>
              ) : (
                <IntroMessage>
                  {surveyName === "PAM" && (
                    <>
                      <p>
                        Your feedback is pertinent to helping your care team
                        provide you the best care possible.
                      </p>
                      <p>
                        Your responses to the Patient Activation Measurement
                        (PAM) questionnaire gives us insight and guidance to
                        establish individual goals and activities that are
                        realistic and achievable for you. Please take a few
                        moments to complete the PAM questionnaire.
                      </p>
                      <p>PAM Key Points</p>
                      <ul>
                        <li>
                          The questionnaire should take less than 5 minutes to
                          complete.
                        </li>
                        <li>Responses remain confidential.</li>
                        <li>It’s important to be truthful.</li>
                        <li>
                          Your answers should not be influenced by what others
                          think or say.
                        </li>
                        <li>There are no right or wrong answers.</li>
                        <li>
                          It’s OK to disagree with some of the statements.
                        </li>
                      </ul>
                      <p>
                        Thank you for taking the time to complete the
                        questionnaire. Your response is appreciated and will
                        help us identify areas of support for you and deliver
                        information more effectively and efficiently.
                      </p>
                    </>
                  )}
                  {(surveyName == "PHQ9" || surveyName == "SDoH") && (
                    <>
                      <p>You are filling {surveyName} survey.</p>
                      <p>{surveyName} Survey Key Points.</p>
                      <ul>
                        <li>
                          The questionnaire should take less than 5 minutes to
                          complete.
                        </li>
                        <li>Responses remain confidential.</li>
                        <li>It’s important to be truthful.</li>
                        <li>
                          Your answers should not be influenced by what others
                          think or say.
                        </li>
                        <li>There are no right or wrong answers.</li>
                      </ul>
                      <p>
                        Over the last 2 weeks, how often have you been bothered
                        by any of the following problems?
                      </p>
                    </>
                  )}
                </IntroMessage>
              )}
            </>
          )}

          {isViewForm && (
            <IntroMessage>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between"
                }}
              >
                <h4>Competed By: {submitterName}</h4>
                <h4>
                  Assigned By:{" "}
                  {assignerName !== ""
                    ? assignerName === "0"
                      ? "Auto Assigned"
                      : assignerName
                    : "CDS"}
                </h4>
              </div>
              <h4>Patient Name: {data?.name}</h4>
              {isScan && (
                <h4>
                  Uploaded On:{" "}
                  {moment(selectedSurvey?.updated_at).format("MMM DD, YYYY") ||
                    "N/A"}
                </h4>
              )}
              <h4>
                Submitted On:{" "}
                {moment(selectedSurvey?.completed_at).format("MMM DD, YYYY") ||
                  "N/A"}
              </h4>
              {sessionStorage.getItem("userRole") !== "patient" &&
                selectedSurvey?.review_date != null && (
                  <h4>
                    Reviewed At:{" "}
                    {moment(selectedSurvey?.review_date).format(
                      "MMM DD, YYYY hh:mm A"
                    ) || "N/A"}
                  </h4>
                )}
            </IntroMessage>
          )}
          {!isScan ? (
            <>
              {healthCareQuestions?.map((question, questionIndex) => (
                <QuestionContainer key={questionIndex}>
                  <Question>
                    {questionIndex + 1}) {question.question}
                  </Question>
                  <Options>
                    {question.options.map((option, optionIndex) => (
                      <Label key={optionIndex}>
                        <input
                          type="radio"
                          name={`question-${questionIndex}`}
                          value={option}
                          checked={responses[questionIndex].answer === option}
                          onChange={() =>
                            handleOptionChange(questionIndex, option)
                          }
                          style={{
                            pointerEvents:
                              responses[questionIndex].answer !== option &&
                              isViewForm
                                ? "none"
                                : "auto"
                          }}
                        />
                        {option}
                      </Label>
                    ))}
                  </Options>
                </QuestionContainer>
              ))}
            </>
          ) : (
            <>
              {!isLoadingUploadedSurvey ? (
                <>
                  <iframe
                    id="uploadedSurveyIframe"
                    src={`data:${
                      uploadedSurvey?.type === "jpg"
                        ? "image/jpeg"
                        : "application/pdf"
                    };base64,${uploadedSurvey?.data}#toolbar=0`}
                    width="100%"
                    height="500px"
                    title="Uploaded File Viewer"
                  ></iframe>
                  {/* <iframe
                    src={`data:${
                      uploadedSurvey?.type === "jpg"
                        ? "image/jpeg"
                        : "application/pdf"
                    };base64,${uploadedSurvey?.data}#toolbar=0`}
                    width={iframeWidth}
                    height={iframeHeight}
                    title="Uploaded File Viewer"
                  ></iframe> */}
                </>
              ) : (
                <SpinContainer>
                  <Spin size="large" />
                </SpinContainer>
              )}
            </>
          )}

          {(isLoadingPostSurvey || isFetchingPostSurvey) && (
            <SpinContainer>
              <Spin size="large" />
            </SpinContainer>
          )}
        </div>
        <ButtonsContainer>
          {isRespond && (
            <Button
              className="btn-save-draft"
              type="primary"
              onClick={() => postResponse("draft")}
            >
              Save as Draft
            </Button>
          )}
          {isRespond && (
            <Button
              className="btn-save-submit"
              type="primary"
              disabled={!allQuestionsAnswered}
              onClick={() => postResponse("submit")}
            >
              Save and Submit
            </Button>
          )}
          {isViewForm &&
            sessionStorage.getItem("userRole") !== "patient" &&
            selectedSurvey?.review_date == null && (
              <>
                <Button
                  onClick={() => {
                    refetchReviewSurveyResponse();
                  }}
                  type="primary"
                >
                  Review Completed
                </Button>
              </>
            )}
          {isViewForm && isScan ? (
            <>
              <Button type="default" onClick={openPrintPreview}>
                Print
              </Button>
            </>
          ) : (
            <>
              {isViewForm ? (
                <>
                  <Button type="default" onClick={printing}>
                    Print
                  </Button>
                </>
              ) : null}
            </>
          )}
          <Button
            className="btn-close"
            type="default"
            onClick={() => {
              reFetchPostSurvey();
              back();
            }}
          >
            Close
          </Button>
        </ButtonsContainer>
      </SurveyContainer>
      {printingState ? (
        <style>
          {`
        @media print {
          body * {
            visibility: hidden;
          }
          #print-section, #print-section * {
            visibility: visible;
          }
          #print-section {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      `}
        </style>
      ) : (
        <style></style>
      )}
    </>
  );
};

const IntroMessage = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
`;

const SurveyContainer = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
`;

const Question = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;

const SpinContainer = styled.div`
  top: 60%;
  left: 50%;
  position: absolute;
`;
