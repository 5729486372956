import React from "react";

export const BillingIcon: React.FC = () => {
  return (
    <svg
      width="39px"
      height="36px"
      viewBox="0 0 39 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Provider-Global-navigation-V2.3"
          transform="translate(-14.000000, -815.000000)"
        >
          <g
            id="Fluid-MetricsFluid-Metrics-Copy-36"
            transform="translate(14.000000, 815.000000)"
          >
            <g id="Billing-icon" transform="translate(7.000000, 6.000000)">
              <g id="Rectangle-Copy-215">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill="#55CDE6"
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <path
                d="M8.41176471,7.41176471 L8.41176471,16.5882353 L15.4705882,16.5882353 L15.4705882,7.41176471 L8.41176471,7.41176471 Z M7,6 L16.8823529,6 L16.8823529,18 L7,18 L7,6 Z"
                id="Rectangle"
                fill="#FFFFFF"
              ></path>
              <rect
                id="Rectangle-Copy-2"
                fill="#FFFFFF"
                x="9.82352941"
                y="8.82352941"
                width="4.23529412"
                height="1.41176471"
              ></rect>
              <rect
                id="Rectangle-Copy-3"
                fill="#FFFFFF"
                x="9.82352941"
                y="10.9411765"
                width="4.23529412"
                height="1.41176471"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
