import Spin from "antd/lib/spin";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import {
  PatientContextState,
  PatientContext
} from "contextApis/patientContext";
import { PatientProfileData, AllPatientsList } from "sharedTypes";
import { SymptomForms } from "./SymptomForms";

export const MobileSymptomFormsComponent: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const patientId = params.get("patient_id");
  const selectedSymptoms: string[] = params.getAll("selected_symptoms[]");
  const patientContext = useContext<PatientContextState>(PatientContext);

  const callPatientProfileAPI = patientContext.patientData ? false : true;
  const { data, isLoading, isFetching, error } = useQuery<PatientProfileData>(
    "patient-data",
    async () => {
      return await createApiClient().getPatientProfile(patientId || "", true);
    },
    {
      enabled: callPatientProfileAPI
    }
  );

  useEffect(() => {
    if (data && !patientContext.patientData) {
      const patientContextData: AllPatientsList = {
        age: moment().diff(moment(data.dob, "MM-DD-YYYY"), "years"),
        appointment_id: data.appointment_id,
        dob: data.dob,
        first_name: data.name.split(/\s+/)[0] || "",
        id: Number(data.id),
        last_name: data.name.split(/\s+/)[1] || "",
        name: data.name,
        notification_level: data.notification_level?.toString() || "",
        picture: data.picture,
        remote_monitoring: data.remote_monitoring,
        tasks: "",
        visit_date: data.visit_date,
        username: data.username || "",
        phoneNumbers: data.phoneNumbers,
        internal_id: data.internal_id,
        vbc: data.vbc
      };
      patientContext.setSelectedPatientData(patientContextData);
    }
  }, [data]);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "30px"
      }}
    >
      {(isLoading || isFetching) && (
        <div
          style={{
            display: "grid",
            placeItems: "center",
            width: "100%",
            height: "100vh"
          }}
        >
          <Spin spinning={true} />
        </div>
      )}
      {!error && data && (
        <SymptomForms selectedSymptomList={selectedSymptoms} />
      )}
    </div>
  );
};
