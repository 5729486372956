import React, { useEffect, useRef } from "react";

// Props are pulled from the Viz class constructor
// https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#viz_class
export interface TableauProps {
  url: string;
  options?: any;
  token?: any;
}

declare global {
  interface Window {
    tableau: any;
  }
}

const loadTableauScript = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.getElementById("tableau-api-script")) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.id = "tableau-api-script";
    script.type = "module";
    script.src =
      "https://embedding.tableauusercontent.com/tableau.embedding.3.1.0.min.js";
    script.onload = () => resolve();
    script.onerror = error => reject(error);
    document.body.appendChild(script);
  });
};

const loadTableau = (token: any, url: string, targetDiv: HTMLElement) => {
  const tableauViz = document.createElement("tableau-viz");
  tableauViz.id = "tableauViz";
  tableauViz.setAttribute("src", url);
  tableauViz.setAttribute("token", token);
  tableauViz.setAttribute("device", "Desktop");
  tableauViz.setAttribute("toolbar", "hidden");

  tableauViz.style.height = "100vh";
  tableauViz.style.width = "100vw";

  targetDiv.appendChild(tableauViz);
};

const Tableau: React.FC<TableauProps> = ({ url, token }) => {
  const vizContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    loadTableauScript()
      .then(() => {
        if (vizContainerRef.current) {
          loadTableau(token, url, vizContainerRef.current);
        }
      })
      .catch(error => {
        console.error("Failed to load Tableau script", error);
      });
  }, []);

  return <div className="tableau-render" ref={vizContainerRef}></div>;
};

export default Tableau;
