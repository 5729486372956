import React from "react";

export const LipidsIcon: React.FC = () => {
  return (
    <svg
      width="70px"
      height="70px"
      viewBox="0 0 70 70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Patient-MyHealthStatus-MyCurrentStatus-MyHeartHealth-V1.1-O1"
          transform="translate(-1095.000000, -172.000000)"
        >
          <g id="lipids" transform="translate(1071.000000, 148.000000)">
            <g id="lipids-icon" transform="translate(24.000000, 24.000000)">
              <path
                d="M35,-2.72848411e-12 L35,-2.72848411e-12 C54.3299662,-2.73203496e-12 70,15.6700338 70,35 L70,35 C70,54.3299662 54.3299662,70 35,70 L35,70 C15.6700338,70 2.36723813e-15,54.3299662 0,35 L0,35 C-2.36723813e-15,15.6700338 15.6700338,-2.72493325e-12 35,-2.72848411e-12 Z"
                id="Rectangle-Copy-204"
                fill="#EBF3FB"
              ></path>
              <g
                id="Group-7-Copy-2"
                transform="translate(7.000000, 7.000000)"
                stroke="#212B40"
                strokeWidth="1.5"
              >
                <polygon
                  id="Polygon"
                  fill="#7CBA5B"
                  points="21.8689196 25.0560676 27.6601597 28.3996416 27.6601597 35.0867896 21.8689196 38.4303636 16.0776796 35.0867896 16.0776796 28.3996416"
                ></polygon>
                <polygon
                  id="Polygon-Copy-3"
                  fill="#FFFFFF"
                  points="27.1282642 35.0747568 32.4864916 38.1683308 32.4864916 44.3554788 27.1282642 47.4490528 21.7700369 44.3554788 21.7700369 38.1683308"
                ></polygon>
                <polygon
                  id="Polygon-Copy-6"
                  fill="#7CBA5B"
                  points="16.6577061 16.0373784 22.0159334 19.1309524 22.0159334 25.3181004 16.6577061 28.4116744 11.2994788 25.3181004 11.2994788 19.1309524"
                ></polygon>
                <polygon
                  id="Polygon-Copy-9"
                  points="22.3689196 6.51868921 27.727147 9.6122632 27.727147 15.7994112 22.3689196 18.8929852 17.0106923 15.7994112 17.0106923 9.6122632"
                ></polygon>
                <polygon
                  id="Polygon-Copy-15"
                  fill="#F27B6E"
                  points="49.0212494 16.0373784 54.3794767 19.1309524 54.3794767 25.3181004 49.0212494 28.4116744 43.6630221 25.3181004 43.6630221 19.1309524"
                ></polygon>
                <polygon
                  id="Polygon-Copy-31"
                  fill="#F27B6E"
                  points="6.18714798 16.0373784 11.5453753 19.1309524 11.5453753 25.3181004 6.18714798 28.4116744 0.828920653 25.3181004 0.828920653 19.1309524"
                ></polygon>
                <polyline
                  id="Path-43"
                  points="22.0842863 0.163859443 22.0842863 6.5625 27.5755776 9.87869655 32.327577 6.5625 32.327577 0.163859443"
                ></polyline>
                <polygon
                  id="Polygon-Copy"
                  fill="#7CBA5B"
                  points="32.8394778 25.5560676 38.1977051 28.6496416 38.1977051 34.8367896 32.8394778 37.9303636 27.4812504 34.8367896 27.4812504 28.6496416"
                ></polygon>
                <polygon
                  id="Polygon-Copy-4"
                  fill="#7CBA5B"
                  points="37.5988224 35.0747568 42.9570497 38.1683308 42.9570497 44.3554788 37.5988224 47.4490528 32.240595 44.3554788 32.240595 38.1683308"
                ></polygon>
                <polygon
                  id="Polygon-Copy-7"
                  fill="#F27B6E"
                  points="27.1282642 16.0373784 32.4864916 19.1309524 32.4864916 25.3181004 27.1282642 28.4116744 21.7700369 25.3181004 21.7700369 19.1309524"
                ></polygon>
                <polygon
                  id="Polygon-Copy-10"
                  fill="#FFFFFF"
                  points="32.8394778 6.51868921 38.1977051 9.6122632 38.1977051 15.7994112 32.8394778 18.8929852 27.4812504 15.7994112 27.4812504 9.6122632"
                ></polygon>
                <polygon
                  id="Polygon-Copy-13"
                  fill="#F27B6E"
                  points="31.8876088 44.593446 37.2458362 47.68702 37.2458362 53.874168 31.8876088 56.967742 26.5293815 53.874168 26.5293815 47.68702"
                ></polygon>
                <polygon
                  id="Polygon-Copy-2"
                  fill="#FFFFFF"
                  points="43.3100359 25.5560676 48.6682632 28.6496416 48.6682632 34.8367896 43.3100359 37.9303636 37.9518086 34.8367896 37.9518086 28.6496416"
                ></polygon>
                <polygon
                  id="Polygon-Copy-8"
                  fill="#7CBA5B"
                  points="38.0988224 15.5373784 43.8900624 18.8809524 43.8900624 25.5681004 38.0988224 28.9116744 32.3075823 25.5681004 32.3075823 18.8809524"
                ></polygon>
                <polygon
                  id="Polygon-Copy-11"
                  fill="#F27B6E"
                  points="43.3100359 6.51868921 48.6682632 9.6122632 48.6682632 15.7994112 43.3100359 18.8929852 37.9518086 15.7994112 37.9518086 9.6122632"
                ></polygon>
                <polygon
                  id="Polygon-Copy-26"
                  fill="#7CBA5B"
                  points="16.6577061 35.0747568 22.0159334 38.1683308 22.0159334 44.3554788 16.6577061 47.4490528 11.2994788 44.3554788 11.2994788 38.1683308"
                ></polygon>
                <polygon
                  id="Polygon-Copy-29"
                  fill="#F27B6E"
                  points="10.9464926 25.5560676 16.3047199 28.6496416 16.3047199 34.8367896 10.9464926 37.9303636 5.58826526 34.8367896 5.58826526 28.6496416"
                ></polygon>
                <polyline
                  id="Path-33"
                  points="0.951868921 38.1683308 5.85951374 35.0747568 11.2098835 38.1683308 11.2098835 44.5869869 6.08087623 47.3273088"
                ></polyline>
                <polyline
                  id="Path-34"
                  points="58.69787 28.8876088 53.8958197 25.5560676 48.545315 28.4116744 48.545315 34.8401891 53.6215925 37.7059945 58.69787 35.4765815"
                ></polyline>
                <polyline
                  id="Path-34-Copy"
                  points="53.6727054 37.7059945 48.6938444 35.0747568 43.326215 38.1683308 43.326215 44.0495209 48.9851961 47.2246837 54.0614737 44.9952707"
                ></polyline>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
