import Form from "antd/lib/form";
import message from "antd/lib/message";
import Skeleton from "antd/lib/skeleton";
import { Moment } from "moment";
import React, { useContext, useMemo, useState } from "react";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import { AddNewAppointmentIcon } from "svgIcons/addNewAppointment";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import {
  AppointmentTitleText,
  TitleContainer,
  AddAppointmentButtonContaner,
  AddAppointmentButtonText,
  OverflowContainer,
  ErrorMessage,
  EmptyDataText,
  SetAppointmentFormContainer,
  FormActionButtonContainer
} from "./styles";
import moment from "moment";
import {
  AppointmentFormDateInput,
  AppointmentFormDateInputProps
} from "./AppointmentFormDateInput";
import get from "lodash/get";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { ScheduleAppointmentRequestBody } from "sharedTypes";
import { useMutation, useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { UserRoles } from "constants/roles";
import { useNavigate } from "react-router";
import { dateToLocal, dateToUTC } from "helpers/utils";
import { AppointmentList } from "./appointmentList";

moment.updateLocale("en", {
  weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
});

export interface AppointmentListData {
  readonly id: number;
  readonly provider_internal_id: number;
  readonly patient_internal_id: number;
  readonly date_time: string;
  readonly provider_name: string;
  readonly patient_name: string;
  readonly degree: string;
  readonly specialty: string;
}

interface AppointmentFormSubmitData {
  readonly date: AppointmentFormDateInputProps;
}

export const AppointmentPage: React.FC = () => {
  const [form] = Form.useForm();
  const { patientData } = useContext<PatientContextState>(PatientContext);
  const { userData } = useContext<UserContextState>(UserContext);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;
  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;
  const selectedProvider = sessionStorage.getItem("selectedProviderName");
  const userId = sessionStorage.getItem("userId") || "";
  const navigate = useNavigate();

  const showTopbarExtesion = !userIsPatient;

  const [isSetAppointmentFormOpen, setIsSetAppointmentFormOpen] =
    useState<boolean>(false);
  const { data, isLoading, isFetching, error, refetch } = useQuery(
    "appointment_list",
    async () => {
      return createApiClient().getAppointmentList(
        userIsPatient ? userId : patientData?.id || ""
      );
    },
    {
      enabled: userIsPatient
        ? userId
          ? true
          : false
        : patientData
        ? true
        : false
    }
  );
  const { mutate: scheduleAppointment, isLoading: scheduleAppointLoading } =
    useMutation(createApiClient().scheduleAppointment, {
      onSuccess: () => {
        if (selectedDate) {
          message.success("Successfully updated appointment");
        } else {
          message.success("Successfully scheduled appointment");
        }
        refetch();
        setIsSetAppointmentFormOpen(false);
      },
      onError: () => {
        message.error("Failed to schedule appointment");
        setIsSetAppointmentFormOpen(false);
      }
    });

  const { mutate: deleteAppointment, isLoading: deleteAppointmentLoading } =
    useMutation(createApiClient().removeAppointment, {
      onSuccess: () => {
        message.success("Successfully removed appointment");
        refetch();
      },
      onError: () => {
        message.error("Failed to remove appointment");
      }
    });

  const appointmentList: AppointmentListData[] = useMemo(() => {
    if (data) {
      if (Object.keys(data).length <= 0) {
        return [];
      }
      return data;
    }
    return [];
  }, [data]);

  const checkDate = (_: any, value: AppointmentFormDateInputProps) => {
    if (value) {
      const now = moment();
      const diff = now.diff(get(value, "date", null));
      if (diff < 0) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("Please select valid future date and time")
      );
    }
    return Promise.reject(new Error("Date is required"));
  };

  const [clearForm, setClearForm] = useState<boolean>(false);

  const onFinish = (values: AppointmentFormSubmitData) => {
    const date = moment(get(values, "date.date", null)).toString();
    const apiBody: ScheduleAppointmentRequestBody = {
      date_time: dateToUTC(date),
      patient_internal_id: userIsPatient
        ? get(userData, "internal_id", "")
        : get(patientData, "id", "").toString(),
      provider_internal_id:
        userIsPatient || userIsCaregiver
          ? sessionStorage.getItem("selectedProviderInternalId") || ""
          : get(userData, "internal_id", "")
    };

    scheduleAppointment(apiBody);
    setSelectedDate(null);
    setClearForm(!clearForm);
  };

  const onReset = () => {
    setSelectedDate(null);
    setClearForm(!clearForm);
  };

  const onAddNewAppointmentFormClick = () => setIsSetAppointmentFormOpen(true);

  const onAddNewAppointmentFormCancel = () => {
    setIsSetAppointmentFormOpen(false);
    onReset();
  };

  const onEditAppointment = (appointment: AppointmentListData) => {
    setSelectedDate(moment(dateToLocal(appointment.date_time)));
    setIsSetAppointmentFormOpen(true);
  };

  const onDeleteAppointment = (appointment: AppointmentListData) => {
    deleteAppointment(appointment.id);
  };

  const onBackClick = () => navigate(-1);

  return (
    <>
      {showTopbarExtesion && <CommonPatientExtensionItems />}
      <AppButton
        type={ButtonType.Secondary}
        onClick={onBackClick}
        buttonContent="Back"
        style={{ width: "100px" }}
      />
      {isSetAppointmentFormOpen ? (
        <>
          {!userIsPatient && !userIsCaregiver && (
            <AppointmentTitleText>
              {`Appointment with ${get(patientData, "last_name", "")} ${get(
                patientData,
                "first_name",
                ""
              )}`}
            </AppointmentTitleText>
          )}
          {userIsPatient && (
            <AppointmentTitleText>
              {`Appointment with ${selectedProvider}`}
            </AppointmentTitleText>
          )}
          {userIsCaregiver && (
            <AppointmentTitleText>
              {`Appointment between ${selectedProvider} and ${get(
                patientData,
                "last_name",
                ""
              )} ${get(patientData, "first_name", "")}`}
            </AppointmentTitleText>
          )}
          <OverflowContainer>
            <AppCard cardHeight="100%" cardWidth="100%">
              <Form
                name="appointment"
                form={form}
                onFinish={onFinish}
                style={{ height: "100%" }}
              >
                <SetAppointmentFormContainer>
                  <Form.Item
                    name="date"
                    rules={[{ required: true, validator: checkDate }]}
                  >
                    <AppointmentFormDateInput
                      clearForm={clearForm}
                      selectedDate={selectedDate}
                    />
                  </Form.Item>
                  <FormActionButtonContainer>
                    <AppButton
                      buttonContent="Cancel"
                      type={ButtonType.Secondary}
                      style={{ width: 120, marginRight: 10 }}
                      onClick={onAddNewAppointmentFormCancel}
                    />
                    <AppButton
                      buttonContent="Set Appointment"
                      type={ButtonType.Primary}
                      style={{ width: 160, marginRight: 10 }}
                      onClick={form.submit}
                      loading={scheduleAppointLoading}
                    />
                  </FormActionButtonContainer>
                </SetAppointmentFormContainer>
              </Form>
            </AppCard>
          </OverflowContainer>
        </>
      ) : (
        <>
          <TitleContainer>
            <AppointmentTitleText>Appointments</AppointmentTitleText>
            <AppButton
              buttonContent={
                <AddAppointmentButtonContaner>
                  <AddNewAppointmentIcon />
                  <AddAppointmentButtonText>
                    Add New Appointment
                  </AddAppointmentButtonText>
                </AddAppointmentButtonContaner>
              }
              onClick={onAddNewAppointmentFormClick}
              style={{ width: 206 }}
              type={ButtonType.Skeleton}
            />
          </TitleContainer>
          <OverflowContainer>
            {isLoading ? (
              <AppCard cardHeight="110px" cardWidth="100%">
                <Skeleton
                  loading={isLoading || isFetching}
                  active={isLoading || isFetching}
                  paragraph={{ rows: 1, width: "100%" }}
                  avatar={false}
                />
              </AppCard>
            ) : error ? (
              <ErrorMessage>{"Failed to load appointment list"}</ErrorMessage>
            ) : data && appointmentList.length === 0 ? (
              <EmptyDataText>No Data</EmptyDataText>
            ) : (
              <AppointmentList
                appointmentList={appointmentList}
                onEditAppointment={onEditAppointment}
                onDeleteAppointment={onDeleteAppointment}
                deleteAppointmentLoading={deleteAppointmentLoading}
              />
            )}
          </OverflowContainer>
        </>
      )}
    </>
  );
};
