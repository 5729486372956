const PAM = [
  {
    q_id: 1,
    question:
      "When all is said and done, I am the person who is responsible for taking care of my health.",
    answer: "",
    issue: "Taking Responsibility",
    action_item:
      "Educate on tips for  taking responsibility to manage health. Address barriers.",
    action_response: ""
  },
  {
    q_id: 2,
    question:
      "Taking an active role in my own health care is the most important thing that affects my health.",
    answer: "",
    issue: "Understaning importance of taking active role in healthcare",
    action_item:
      "Educate on the benefits of taking active role in their healthcare",
    action_response: ""
  },
  {
    q_id: 3,
    question:
      "I am confident I can help prevent or reduce problems associated with my health.",
    answer: "",
    issue: "Empowerment on ability to prevent or reduce problems",
    action_item:
      "Educate on tips on  how to prevent or reduce problems associated with their health: Address barriers",
    action_response: ""
  },
  {
    q_id: 4,
    question: "I know what each of my prescribed medications do.",
    answer: "",
    issue: "Knowledge of medications.",
    action_item: "Educate on purpose of each of their medications.",
    action_response: ""
  },
  {
    q_id: 5,
    question:
      "I am confident that I can tell whether I need to go to the doctor or whether I can take care of a health problem myself.",
    answer: "",
    issue: "Determine  when to see the Doctor or pursue self care ",
    action_item:
      "Educate on reasons for seeking a doctor visit or managing with self-care",
    action_response: ""
  },
  {
    q_id: 6,
    question:
      "I am confident that I can tell a doctor concerns I have even when he or she does not ask.",
    answer: "",
    issue: "Empowerment to communicate  health care concerns with doctor",
    action_item:
      "Educate patient on keeping track of, and communicating their health concerns with their health care team. Address barriers to communication",
    action_response: ""
  },
  {
    q_id: 7,
    question:
      "I am confident that I can follow through on medical treatments I may need to do at home.",
    answer: "",
    issue: "Empowerment on ability to manage  treatment at home",
    action_item:
      "Educate on how to manage medical treatments  at home. Address barriers and  support options",
    action_response: ""
  },
  {
    q_id: 8,
    question: "I understand my health problems and what causes them.",
    answer: "",
    issue: "Understanding health problems",
    action_item: "Educate on  patients health problems  and causes",
    action_response: ""
  },
  {
    q_id: 9,
    question: "I know what treatments are available for my health problems.",
    answer: "",
    issue: "Knowledge of treatment  options for health problems",
    action_item: "Educate on treatment options for their health problems",
    action_response: ""
  },
  {
    q_id: 10,
    question:
      "I have been able to maintain (keep up with) lifestyle changes, like eating right or exercising.",
    answer: "",
    issue: "Manage lifestyle changes",
    action_item:
      "Educate on strategies to maintain lifestyle changes (diet and exercise): Address barriers",
    action_response: ""
  },
  {
    q_id: 11,
    question: "I know how to prevent problems with my health.",
    answer: "",
    issue: "Knowledge on  preventing health problems",
    action_item:
      "Educate on how to prevent or reduce problems associated with their health. Address any  barriers",
    action_response: ""
  },
  {
    q_id: 12,
    question:
      "I am confident I can figure out solutions when new problems arise with my health.",
    answer: "",
    issue: "Ability to work out solutions for new health problems",
    action_item:
      "Educate on strategies to work out solutions for new health problems.",
    action_response: ""
  },
  {
    q_id: 13,
    question:
      "I am confident that I can maintain lifestyle changes, like eating right and exercising, even during times of stress.",
    answer: "",
    issue: "Empowerment ot maintain  lifestyle changes during stress",
    action_item:
      "Educate on how to manage stress and priortize lifestyle changes during stress",
    action_response: ""
  }
];

export default PAM;
