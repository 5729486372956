import React, { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { SeriesData } from "components/lineChart/lineChart";
import {
  PatientContextState,
  PatientContext
} from "contextApis/patientContext";
import { FluidMetricsContent } from "./fluidMetricsContent";

export interface ModalSeriesData {
  readonly title: string;
  readonly data: SeriesData[];
  readonly colors: string[];
}

export const FluidMetricsPage: React.FC = () => {
  const patientContext = useContext<PatientContextState>(PatientContext);

  const isFluidMetricsApiEnabled = useMemo(() => {
    if (patientContext.patientData) {
      if (patientContext.patientData.id) return true;
    }
    return false;
  }, [patientContext.patientData]);

  const patientId = useMemo(() => {
    if (patientContext.patientData) {
      if (patientContext.patientData.id)
        return patientContext.patientData.id.toString();
    }
    return "";
  }, [patientContext.patientData]);

  const { isLoading, data, error, isFetching } = useQuery(
    "fluid-metrics",
    async () => {
      return await createApiClient().getFluidMetricsData(patientId);
    },
    {
      enabled: isFluidMetricsApiEnabled
    }
  );

  return (
    <FluidMetricsContent
      loading={isFetching || isLoading}
      error={error}
      data={data}
    />
  );
};
