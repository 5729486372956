import styled from "styled-components";

interface LoadingContainerProps {
  readonly height: string;
}

export const LoadingContainer = styled.div<LoadingContainerProps>`
  width: 100%;
  height: ${props => (props.height ? props.height : "100%")};
  display: grid;
  place-items: center;
`;
