import React from "react";
import InputNumber from "antd/lib/input-number";
import styled, { CSSProperties } from "styled-components";

interface SurveyInputProps {
  value: string;
  onChange?(e: string | number | null): void;
  placeholder: string;
  decimalLimit: number;
  style?: CSSProperties;
  min?: number;
  max?: number;
  id?: string;
}

export const SurveyNumberInput: React.FC<SurveyInputProps> = ({
  value,
  onChange,
  placeholder,
  decimalLimit,
  style,
  min,
  max,
  id
}) => {
  return (
    <InputStyled
      value={value}
      id={id}
      onChange={onChange}
      placeholder={placeholder}
      precision={decimalLimit}
      style={style}
      min={min}
      max={max}
    />
  );
};

const InputStyled = styled(InputNumber)`
  border-top: none;
  box-shadow: none !important;
  border-right: none;
  border-left: none;
`;
