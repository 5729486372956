import React from "react";
import styled from "styled-components";
import { LabelContainer, LabelText } from "./textInput";
import TimePicker from "antd/lib/time-picker";
import { Moment } from "moment";
import "./picker.css";

interface SelectInputProps {
  readonly label: string;
  readonly value: Moment | null;
  readonly placeholder?: string;
  readonly customTimeFormat?: string;
  readonly onChange: (value: Moment | null) => void;
  readonly style?: React.CSSProperties;
  readonly autoFocus?: boolean;
  readonly open?: boolean;
  readonly use12Hours?: boolean;
  readonly disabled?: boolean;
  readonly disabledHours?: () => number[];
  readonly disabledMinutes?: () => number[];
  readonly disabledSeconds?: () => number[];
}

export const TimeInput: React.FC<SelectInputProps> = ({
  label,
  value,
  placeholder,
  customTimeFormat,
  onChange,
  style,
  autoFocus,
  open,
  use12Hours,
  disabled,
  disabledHours,
  disabledMinutes,
  disabledSeconds
}) => {
  return (
    <div style={style}>
      <LabelContainer>
        <LabelText>{label}</LabelText>
      </LabelContainer>
      <StyledTimePicker
        value={value}
        open={open}
        autoFocus={autoFocus}
        use12Hours={use12Hours}
        format={customTimeFormat}
        placeholder={placeholder}
        onChange={onChange}
        onSelect={onChange}
        disabledHours={disabledHours}
        disabledMinutes={disabledMinutes}
        disabledSeconds={disabledSeconds}
        getPopupContainer={(triggerNode: HTMLElement) =>
          triggerNode.parentNode as HTMLElement
        }
        disabled={disabled}
        dropdownAlign={{
          overflow: {
            adjustX: 0,
            adjustY: 0
          }
        }}
      />
    </div>
  );
};

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  input {
    padding-top: 10px;
  }
`;
