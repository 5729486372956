import moment from "moment";

export const startCase = (string: string, customSeperator?: string) => {
  if (!string) return "";
  const stringArray = string.split(customSeperator || " ");
  const formattedString = stringArray.map(
    string => string[0].toUpperCase() + string.substring(1)
  );
  return formattedString.join(customSeperator || " ");
};

export function disableFutureDate(current: any) {
  return current > moment().endOf("day");
}

export function lowerCaseRemoveSpace(value: string) {
  if (value) {
    return value.toLowerCase().replace(/\s/g, "");
  } else {
    return "";
  }
}

export function lowerCaseRemoveSpaceRemoveSpecialCharacters(value: string) {
  if (value) {
    return value.toLowerCase().replace(/[^a-z]/g, "");
  } else {
    return "";
  }
}

export const customRound = (number: number, roundDecimal = 2) =>
  0 +
  Number(
    parseFloat((number + Number.EPSILON).toString()).toFixed(roundDecimal)
  );

export const getAccessToken = () => {
  return sessionStorage.getItem("access_token");
};
export const dateToUTC = (date: string) =>
  moment(date).utc().format("MM/DD/YYYY HH:mm:ss");

export const dateToLocal = (date: string) => moment(moment.utc(date).toDate());

export const maskAlternateNumbers = (phoneNumber: string) => {
  const digitsOnly = phoneNumber.replace(/\D/g, "");

  let countryCodeLength = 3;

  if (phoneNumber.includes("+1")) countryCodeLength = 2;

  const maskCharacter = "X";

  const digitsToMask = Math.ceil((digitsOnly.length - countryCodeLength) / 2);

  const digitsArray = digitsOnly.split("");

  for (let i = countryCodeLength; i < digitsArray.length; i++) {
    if ((i - countryCodeLength) % 2 === 0) {
      digitsArray[i] = maskCharacter;
    }
  }

  const maskedPhoneNumber = digitsArray.join("");

  return "+" + maskedPhoneNumber;
};
