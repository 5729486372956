import React from "react";
import { MENU_ITEM_ICON_DISABLED_COLOR } from "components/sideBar/sideBar";

interface IconProps {
  readonly isDisabled?: boolean;
}

export const CarePlanIcon: React.FC<IconProps> = ({ isDisabled }) => {
  return (
    <svg
      width="39px"
      height="36px"
      viewBox="0 0 39 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CareGEM-Provider-Global-navigation-V2.3"
          transform="translate(-14.000000, -543.000000)"
        >
          <g
            id="EducationTips-menu-Copy-6"
            transform="translate(14.000000, 543.000000)"
          >
            <g id="Care-Plan-icon" transform="translate(7.000000, 6.000000)">
              <g id="Rectangle-Copy-210">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill={isDisabled ? MENU_ITEM_ICON_DISABLED_COLOR : "#F27B6E"}
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <path
                d="M8.35294118,10.0658088 L8.35294118,16.1470588 L15.1176471,16.1470588 L15.1176471,7.35294118 L11.0627492,7.35294118 L8.35294118,10.0658088 Z M10.4109901,6 L16.4705882,6 L16.4705882,17.5 L7,17.5 L7,9.40248775 L10.4109901,6 Z"
                id="Rectangle"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <rect
                id="Rectangle-Copy-2"
                stroke="#FFFFFF"
                strokeWidth="2"
                x="10.7058824"
                y="12.4117647"
                width="2.05882353"
                height="1"
              ></rect>
              <path
                d="M10.7058824,12.4117647 L12.7647059,12.4117647 L12.7647059,12.7647059 L13.1176471,12.4117647 L13.7647059,12.4117647 L13.4411765,12.0882353 L13.7647059,11.7647059 L13.1176471,11.7647059 L12.7647059,11.4117647 L12.7647059,11.7647059 L10.7058824,11.7647059 L10.7058824,11.4117647 L10.3529412,11.7647059 L9.70588235,11.7647059 L10.0294118,12.0882353 L9.70588235,12.4117647 L10.3529412,12.4117647 L10.7058824,12.7647059 L10.7058824,12.4117647 Z"
                id="Rectangle-Copy-4"
                stroke="#FFFFFF"
                strokeWidth="2"
                transform="translate(11.735294, 12.088235) rotate(-90.000000) translate(-11.735294, -12.088235) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
