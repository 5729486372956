import React, { useContext, useMemo, useState } from "react";
import { Col } from "antd";
import Row from "antd/lib/row";
import { get, isArray } from "lodash";
import { useQuery } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";

import { AppCard } from "components/card";
import { FilterDisplay } from "components/filterDisplay";
import { UserRoles } from "constants/roles";
import {
  MedicationContext,
  MedicationContextState
} from "contextApis/medicationContext";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { Colors } from "helpers/colors";
import {
  DiscontinuedMedicationListInterface,
  DiscontinuedMedicationListResponse,
  DiscontinuedReason
} from "sharedTypes";
import { useDebounce } from "helpers/customHooks";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { DiscontinuedMedicationList } from "./discontinuedMedicationList";

interface DiscountinuedMedicationTableProps {
  readonly searchValue: string;
}

export const DisContinuedMedication: React.FC<
  DiscountinuedMedicationTableProps
> = ({ searchValue }) => {
  const patientContext = useContext<PatientContextState>(PatientContext);
  const checkboxMenuContext =
    useContext<MedicationContextState>(MedicationContext);
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);
  const debouncedSearchValue = useDebounce(searchValue);

  const isApiEnabled = useMemo(() => {
    if (patientContext.patientData) {
      if (patientContext.patientData.id) return true;
    }
    return false;
  }, [patientContext.patientData]);

  const [discontinuedData, setDiscontinuedData] =
    useState<DiscontinuedMedicationListResponse>();

  const userId = sessionStorage.getItem("userId") || "";

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const { isLoading, isFetching } = useQuery(
    [
      "discontinued-medication",
      debouncedSearchValue,
      checkboxMenuContext.discontinuedMenuOneReasons,
      checkboxMenuContext.discontinuedMenuTwoReasons,
      checkboxMenuContext.prescribedMenuOneReasons,
      checkboxMenuContext.prescribedMenuTwoReasons,
      notificationsContext.notifications?.medications
    ],
    async () => {
      const checkedPrescribedItems: string[] = [];

      checkboxMenuContext.prescribedMenuOneReasons.reasons
        .concat(checkboxMenuContext.prescribedMenuTwoReasons.reasons)
        .filter(item => {
          if (item.checked) {
            checkedPrescribedItems.push(item.desc);
          }
          return item.checked;
        });

      return await createApiClient().getDiscontinuedMedicationList(
        userIsPatient ? userId : get(patientContext, "patientData.id", ""),
        searchValue,
        checkedPrescribedItems.join(","),
        true
      );
    },
    {
      enabled: userIsPatient || isApiEnabled ? true : false,
      onSuccess: data => {
        filterDiscontinuedData(data);
      }
    }
  );

  const discontinuedMedicationsLoading = isLoading || isFetching;

  const discontinuedReasonsExist =
    checkboxMenuContext.discontinuedMenuOneReasons.reasons.some(
      item => item.checked
    ) ||
    checkboxMenuContext.discontinuedMenuTwoReasons.reasons.some(
      item => item.checked
    );

  const prescribedReasonExists =
    checkboxMenuContext.prescribedMenuOneReasons.reasons.some(
      item => item.checked
    ) ||
    checkboxMenuContext.prescribedMenuTwoReasons.reasons.some(
      item => item.checked
    );

  const filterDiscontinuedData = (data: DiscontinuedMedicationListResponse) => {
    const filteredData: DiscontinuedMedicationListResponse = { Medication: [] };
    if (data && discontinuedReasonsExist) {
      const checkedItems: string[] = [];

      checkboxMenuContext.discontinuedMenuOneReasons.reasons
        .concat(checkboxMenuContext.discontinuedMenuTwoReasons.reasons)
        .filter(item => {
          if (item.checked) {
            checkedItems.push(String(item.id));
          }
          return item.checked;
        });
      if (isArray(data.Medication)) {
        data.Medication.forEach((main: DiscontinuedMedicationListInterface) => {
          main.DiscontinueReasons.forEach((sub: DiscontinuedReason) => {
            if (checkedItems.includes(String(sub.Id))) {
              filteredData.Medication.push(main);
            }
          });
        });
      }

      setDiscontinuedData(filteredData);
    } else {
      setDiscontinuedData(data);
    }
  };

  return (
    <AppCard cardHeight="calc(100vh - 220px)" cardWidth="100%">
      {(discontinuedReasonsExist || prescribedReasonExists) && (
        <FilterWrapper>
          {discontinuedReasonsExist && (
            <Col span={!prescribedReasonExists ? 24 : 12}>
              Reasons Discontinued
              <FilterDisplay
                list1={checkboxMenuContext.discontinuedMenuOneReasons.reasons
                  .filter(item => item.checked)
                  .map(
                    (obj: { reason: string; id: string; checked?: boolean }) =>
                      obj.reason
                  )}
                list2={checkboxMenuContext.discontinuedMenuTwoReasons.reasons
                  .filter(item => item.checked)
                  .map(
                    (obj: { reason: string; id: string; checked?: boolean }) =>
                      obj.reason
                  )}
                ignoreNativeWrapperStyle
              />
            </Col>
          )}
          {prescribedReasonExists && (
            <Col span={!discontinuedReasonsExist ? 24 : 12}>
              Reasons Prescribed
              <FilterDisplay
                list1={checkboxMenuContext.prescribedMenuOneReasons.reasons
                  .filter(item => item.checked)
                  .map(
                    (obj: { desc: string; code: string; checked?: boolean }) =>
                      obj.desc
                  )}
                list2={checkboxMenuContext.prescribedMenuTwoReasons.reasons
                  .filter(item => item.checked)
                  .map(
                    (obj: { desc: string; code: string; checked?: boolean }) =>
                      obj.desc
                  )}
                ignoreNativeWrapperStyle
              />
            </Col>
          )}
        </FilterWrapper>
      )}
      <DiscontinuedMedicationList
        discontinuedData={discontinuedData}
        discontinuedMedicationsLoading={discontinuedMedicationsLoading}
      />
    </AppCard>
  );
};

const FilterWrapper = styled(Row)`
  max-height: 110px;
  overflow: scroll;
  border: 1px solid ${Colors.Lavender};
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 10px;
  padding-bottom: 6px;
`;
