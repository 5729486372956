import { ArrowLeftOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Checkbox from "antd/lib/checkbox";
import message from "antd/lib/message";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import React, { useContext } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { NephrologyLogo } from "pages/symptoms/surveyForms/nephrologyLogo";
import wongBakerFaces from "./wongBakerFaces.png";
import { Platform, UserRoles } from "constants/roles";
import { FormSubmitButton } from "pages/symptoms/surveyForms/FormSubmitButton";
import { FormClearButton } from "pages/symptoms/surveyForms/FormClearButton";

interface chestPainFormProps {
  setShowSurveyForm(show: boolean): void;
  selectedItems: string[];
  goToNextFormOrReturn(): void;
}

export const ChestPainForm: React.FC<chestPainFormProps> = ({
  setShowSurveyForm,
  selectedItems,
  goToNextFormOrReturn
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [form] = Form.useForm();

  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const userRole = sessionStorage.getItem("userRole");

  const { isLoading: isLoadingChestPainMutation, mutate: submitChestPainForm } =
    useMutation<string>(
      "add-bill",
      async () => {
        return await createApiClient().addChestPainFormData({
          patient_internal_id:
            userRole === UserRoles.PATIENT && userContext.userData
              ? userContext.userData?.internal_id
              : patientContext?.patientData?.id || "",
          chestpain: "Yes",
          recentpain: form.getFieldValue("Most recent episode"),
          restpain: form.getFieldValue("Chest pain at rest"),
          type: form.getFieldValue("Type of chest pain"),
          length: form.getFieldValue("Duration of chest pain"),
          worse: form.getFieldValue("What worsens chest pain"),
          better: form.getFieldValue("What relieves chest pain"),
          frequency: form.getFieldValue("Frequency of chest pain"),
          level: form.getFieldValue("Level of chest pain"),
          submitted_by: `${userContext.userData?.firstName} ${userContext.userData?.lastName}`
        });
      },
      {
        onSuccess: () => {
          message.success("Form Submitted Successfully");
          goToNextFormOrReturn();
        },
        onError: () => {
          message.error({
            content: (
              <span>
                There was a problem submitting this form, resubmit or{" "}
                <a onClick={() => goToNextFormOrReturn()}>
                  click here to continue to the next survey
                </a>
              </span>
            ),
            style: {
              bottom: "10px",
              position: "fixed",
              left: "30%"
            },
            duration: 5
          });
        }
      }
    );

  const onFinish = () => {
    submitChestPainForm();
  };
  return (
    <div>
      <div style={{ display: platform === Platform.MOBILE ? "none" : "block" }}>
        <AppButton
          type={ButtonType.Primary}
          onClick={() => setShowSurveyForm(false)}
          buttonContent={
            <>
              <ArrowLeftOutlined onClick={() => setShowSurveyForm(false)} />{" "}
              Back
            </>
          }
        />
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Form
          name="nausea"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          style={{ padding: "0px" }}
        >
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
              style={{ borderTop: "8px solid #4284f3" }}
            >
              <PageTitle>Survey - {selectedItems[0]}</PageTitle>
            </AppCard>

            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                  fontWeight: "600"
                }}
              >
                Please do not rely upon this survey for immediate medical
                attention. This survey may not be reviewed by your care team
                immediately. Please call your doctor for any medical problems.
                IF YOU THINK THIS IS A MEDICAL EMERGENCY, OR YOUR SYMPTOMS ARE
                WORRISOME PLEASE CALL 911 OR GO TO THE NEAREST EMERGENCY ROOM.
              </Space>
            </AppCard>

            {/* <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                  fontWeight: "600"
                }}
              >
                <NephrologyLogo />
              </Space>
            </AppCard> */}
            <Space direction="vertical" size={20}>
              <AppCard
                cardHeight="fit-content"
                cardWidth={
                  platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
                }
              >
                <Space
                  direction="vertical"
                  size={20}
                  style={{ width: "100%", padding: "10px" }}
                >
                  <CardTitle>
                    When was the most recent episode of chest pain?
                    <AsteriskStyle> *</AsteriskStyle>
                  </CardTitle>
                  <FormItemStyle
                    name="Most recent episode"
                    label=""
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    initialValue={""}
                  >
                    <Radio.Group value={""}>
                      <Space direction="vertical">
                        <Radio value={"1 - 2 days ago"}>1 - 2 days ago</Radio>
                        <Radio value={"3 - 7 days ago"}>3 - 7 days ago</Radio>
                        <Radio value={"8 - 14 days ago"}>8 - 14 days ago</Radio>
                        <Radio value={"15 - 30 days ago"}>
                          15 - 30 days ago
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </FormItemStyle>
                </Space>
              </AppCard>
              <AppCard
                cardHeight="fit-content"
                cardWidth={
                  platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
                }
              >
                <Space
                  direction="vertical"
                  size={20}
                  style={{ width: "100%", padding: "10px" }}
                >
                  <CardTitle>
                    Do you have chest pain at rest?
                    <AsteriskStyle> *</AsteriskStyle>
                  </CardTitle>
                  <FormItemStyle
                    name="Chest pain at rest"
                    label=""
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    initialValue={""}
                  >
                    <Radio.Group value={""}>
                      <Space direction="vertical">
                        <Radio value={"Yes"}>Yes</Radio>
                        <Radio value={"No"}>No</Radio>
                      </Space>
                    </Radio.Group>
                  </FormItemStyle>
                </Space>
              </AppCard>
              <AppCard
                cardHeight="fit-content"
                cardWidth={
                  platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
                }
              >
                <Space
                  direction="vertical"
                  size={20}
                  style={{ width: "100%", padding: "10px" }}
                >
                  <CardTitle>
                    How severe is your chest pain?
                    <AsteriskStyle> *</AsteriskStyle>
                  </CardTitle>
                  <FormItemStyle
                    name="Level of chest pain"
                    label=""
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    initialValue={""}
                  >
                    <Radio.Group
                      onChange={e =>
                        localStorage.setItem("levelOfChestPain", e.target.value)
                      }
                    >
                      <Row
                        style={{
                          width: "calc(100% + 20px)",
                          paddingLeft:
                            platform === Platform.MOBILE ? "8px" : "25px"
                        }}
                      >
                        <Col>
                          <Radio value={"1"}>1</Radio>
                          <Radio value={"2"}>2</Radio>
                          <Radio value={"3"}>3</Radio>
                          <Radio value={"4"}>4</Radio>
                          <Radio value={"5"}>5</Radio>
                        </Col>
                        {platform === Platform.MOBILE ? (
                          <Col span={24}>
                            <Radio value={"6"}>6</Radio>
                            <Radio value={"7"}>7</Radio>
                            <Radio value={"8"}>8</Radio>
                            <Radio value={"9"}>9</Radio>
                            <Radio value={"10"}>10</Radio>
                          </Col>
                        ) : (
                          <>
                            <Radio value={"6"}>6</Radio>
                            <Radio value={"7"}>7</Radio>
                            <Radio value={"8"}>8</Radio>
                            <Radio value={"9"}>9</Radio>
                            <Radio value={"10"}>10</Radio>
                          </>
                        )}
                      </Row>
                    </Radio.Group>
                  </FormItemStyle>
                </Space>
              </AppCard>
              <AppCard
                cardHeight="fit-content"
                cardWidth={
                  platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
                }
              >
                <Space
                  direction="vertical"
                  size={20}
                  style={{ width: "100%", padding: "10px" }}
                >
                  <img
                    src={wongBakerFaces}
                    height={platform === Platform.MOBILE ? "100px" : "225px"}
                  />
                </Space>
              </AppCard>
              <AppCard
                cardHeight="fit-content"
                cardWidth={
                  platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
                }
              >
                <Space
                  direction="vertical"
                  size={20}
                  style={{ width: "100%", padding: "10px" }}
                >
                  <CardTitle>
                    Where is your pain?
                    <AsteriskStyle> *</AsteriskStyle>
                  </CardTitle>
                  <FormItemStyle
                    name="Type of chest pain"
                    label=""
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    initialValue={""}
                  >
                    <Checkbox.Group style={{ width: "100%" }}>
                      <Row gutter={[10, 10]}>
                        <Col span={24}>
                          <Checkbox value="Heaviness">Heaviness</Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox value="Dull/Aching">Dull/Aching</Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox value="Sharp/Stabbing">
                            Sharp/Stabbing
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </FormItemStyle>
                </Space>
              </AppCard>
              <AppCard
                cardHeight="fit-content"
                cardWidth={
                  platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
                }
              >
                <Space
                  direction="vertical"
                  size={20}
                  style={{ width: "100%", padding: "10px" }}
                >
                  <CardTitle>
                    How long did the chest pain last? (List longest episode)
                    <AsteriskStyle> *</AsteriskStyle>
                  </CardTitle>
                  <FormItemStyle
                    name="Duration of chest pain"
                    label=""
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    initialValue={""}
                  >
                    <Radio.Group value={""}>
                      <Space direction="vertical">
                        <Radio value={"Less than 5 mins"}>
                          Less than 5 mins
                        </Radio>
                        <Radio value={"5 to 15 mins"}>5 to 15 mins</Radio>
                        <Radio value={"15 to 30 mins"}>15 to 30 mins</Radio>
                        <Radio value={"More than 30 mins"}>
                          More than 30 mins
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </FormItemStyle>
                </Space>
              </AppCard>
              <AppCard
                cardHeight="fit-content"
                cardWidth={
                  platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
                }
              >
                <Space
                  direction="vertical"
                  size={20}
                  style={{ width: "100%", padding: "10px" }}
                >
                  <CardTitle>What makes the chest pain worse?</CardTitle>
                  <FormItemStyle
                    name="What worsens chest pain"
                    label=""
                    initialValue={[]}
                  >
                    <Checkbox.Group style={{ width: "100%" }}>
                      <Row gutter={[10, 10]}>
                        <Col span={24}>
                          <Checkbox value="Exertion">Exertion</Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox value="Deep breathing/coughing">
                            Deep breathing/coughing
                          </Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox value="Movement">Movement</Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox value="Lying flat">Lying flat</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </FormItemStyle>
                </Space>
              </AppCard>
              <AppCard
                cardHeight="fit-content"
                cardWidth={
                  platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
                }
              >
                <Space
                  direction="vertical"
                  size={20}
                  style={{ width: "100%", padding: "10px" }}
                >
                  <CardTitle>What makes the chest pain better?</CardTitle>
                  <FormItemStyle
                    name="What relieves chest pain"
                    label=""
                    initialValue={[]}
                  >
                    <Checkbox.Group style={{ width: "100%" }}>
                      <Row gutter={[10, 10]}>
                        <Col span={24}>
                          <Checkbox value="Nothing/unrelieved by rest or medicine">
                            Nothing/unrelieved by rest or medicine
                          </Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox value="Resting">Resting</Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox value="Shallow breathing">
                            Shallow breathing
                          </Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox value="Sitting forward">
                            Sitting forward
                          </Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox value="Medication">Medication</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </FormItemStyle>
                </Space>
              </AppCard>
              <AppCard
                cardHeight="fit-content"
                cardWidth={
                  platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
                }
              >
                <Space
                  direction="vertical"
                  size={20}
                  style={{ width: "100%", padding: "10px" }}
                >
                  <CardTitle>
                    How often has the chest pain occurred in the last one month?
                    <AsteriskStyle> *</AsteriskStyle>
                  </CardTitle>
                  <FormItemStyle
                    name="Frequency of chest pain"
                    label=""
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    initialValue={""}
                  >
                    <Radio.Group value={""}>
                      <Space direction="vertical">
                        <Radio value={"1 - 2 times per month"}>
                          1 - 2 times per month
                        </Radio>
                        <Radio value={"3 - 5 times per month"}>
                          3 - 5 times per month
                        </Radio>
                        <Radio value={"6 - 10 times per month"}>
                          6 - 10 times per month
                        </Radio>
                        <Radio value={"More than 10 times per month"}>
                          More than 10 times per month
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </FormItemStyle>
                </Space>
              </AppCard>
              <Row>
                <Col span={5}>
                  <Form.Item>
                    <FormClearButton onClear={() => form.resetFields()} />
                  </Form.Item>
                </Col>
                <Col span={19} style={{ textAlign: "right" }}>
                  <Form.Item>
                    <FormSubmitButton
                      type={ButtonType.Primary}
                      buttonContent="Submit"
                      htmlType="submit"
                      loading={isLoadingChestPainMutation}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </Space>
        </Form>
      </div>
    </div>
  );
};

const PageTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
