import styled from "styled-components";

export const PatientProfileTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 18px;
`;

interface GridItemProps {
  readonly leftBorder?: boolean;
}
interface GridItemValueProps {
  readonly bold?: boolean;
}
interface GridItemTitleProps {
  readonly topPadding?: boolean;
}

export const PageTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  padding-bottom: 18px;
  padding-top: 18px;
`;

export const ProfileHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const AvatarTextContainer = styled.div`
  margin-left: 20px;
  font-weight: 700;
`;
export const AvatarNameText = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
export const Divider = styled.hr`
  margin-top: 10px;
  border: 0.5px solid #dcdfe4;
`;
export const ProfileContentContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const GridItem = styled.div<GridItemProps>`
  padding-left: 10px;
  border-left: ${props => (props.leftBorder ? "1px solid #dcdfe4" : "none")};
`;
export const GridItemTitle = styled.div<GridItemTitleProps>`
  color: gray;
  font-size: 12px;
  ${props => (props.topPadding ? "padding-top: 5px;" : "")}
`;
export const GridItemValue = styled.div<GridItemValueProps>`
  font-size: 14px;
  font-weight: ${props => (props.bold ? "700" : "normal")};
`;
export const GridItemGap = styled.div`
  height: 25px;
`;
export const ContactNumberContainer = styled.div`
  padding: 15px;
  border: 1px solid #dcdfe4;
  border-radius: 8px;
`;
export const ContactNumberGap = styled.div`
  height: 10px;
`;
