export const SymptomConstants = {
  AchesPain: "aches/pain",
  LegSwelling: "legswelling",
  WeightChange: "weightchange",
  Fatigue: "fatigue",
  ShortnessOfBreath: "shortnessofbreath",
  ChestPain: "chestpain",
  Lightheadedness: "lightheadedness",
  Falls: "falls",
  Fever: "fever",
  Ulcers: "ulcers",
  Nausea: "nausea",
  MoodImpairment: "moodimpairment",
  AppetiteImpairment: "appetiteimpairment",
  Dialysis: "dialysissymptom",
  VitalSigns: "vitalsigns",
  UrinarySymptoms: "urinarysymptoms"
};
