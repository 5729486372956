import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { RiskProfileData, RiskProfileList } from "./RiskProfileList";
import { RiskProfileTitleText } from "./styles";
import { Col, Row, Table } from "antd";
import { CardComponent } from "components/card/card";
import styled from "styled-components";
import { Colors } from "helpers/colors";

type ContentContainerProps = {
  marginTop: string;
};

export const RiskProfilePage: React.FC = () => {
  const [riskProfileData, setRiskProfileData] = useState<RiskProfileData[]>([]);
  const patientContext = useContext<PatientContextState>(PatientContext);
  const { isLoading, data, error, isFetching } = useQuery(
    "risk-profile",
    async () => {
      return await createApiClient().getPatientRiskProfile(
        patientContext.patientData?.id || "",
        true
      );
    },
    { enabled: false }
  );

  const tableData = [
    {
      factor: "Z55  Problems related to education and literacy",
      risk: "Low",
      key: "1"
    },
    {
      factor: "Z56  Problems related to employment and unemployment",
      risk: "High",
      key: "2"
    },
    {
      factor: "Z57  Occupational exposure to risk factors",
      risk: "High",
      key: "3"
    },
    {
      factor: "Z58  Problems related to physical environment",
      risk: "High",
      key: "4"
    },
    {
      factor: "Z59  Problems related to housing and economic circumstance",
      risk: "Low",
      key: "5"
    },
    {
      factor: "Z60  Problems related to social environment",
      risk: "Low",
      key: "6"
    },
    { factor: "Z62  Problems related to upbringing", risk: "Low", key: "7" },
    {
      factor:
        "Z63  Other problems related to primary support group, including family circumstances",
      risk: "Low",
      key: "8"
    },
    {
      factor: "Z64  Problems related to certain psychosocial circumstances",
      risk: "Low",
      key: "9"
    },
    {
      factor: "Z65  Problems related to other psychosocial circumstances",
      risk: "Low",
      key: "10"
    }
  ];

  const chartOptions = {
    chart: {
      type: "pie",
      height: "100px",
      width: 100
    },
    title: {
      text: ""
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        dataLabels: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: "Data",
        data: [
          {
            x: "1-Jan-25",
            y: 0,
            color: "#808080"
          },
          {
            x: "1-Jan-24",
            y: 20,
            color: "#FFA500"
          },
          {
            x: "1-Jan-23",
            y: 40,
            color: "#FF0000"
          },
          {
            x: "1-Jan-22",
            y: 60,
            color: "#34973E"
          },
          {
            x: "1-Jan-21",
            y: 80,
            color: "#007FFF"
          }
        ]
      }
    ],
    credits: {
      enabled: false
    }
  };

  const lineChartOption = {
    chart: {
      type: "line",
      height: 190
    },
    plotOptions: {
      line: {
        allowPointSelect: false,
        dataLabels: {
          enabled: false
        }
      }
    },
    title: {
      text: `<div style="font-size: 13px; font-weight: bold; color: ${Colors.DodgerBlue}; letter-spacing: 1px;">
              Rate of GFR change – 15ml/min/year
            </div>`
    },
    xAxis: {
      categories: ["1-Jan-2021", "1-Jan-2022", "1-Jan-2023"],
      title: {
        text: ``
      }
    },
    yAxis: {
      reversed: true,
      title: {
        text: null
      },
      tickPositions: [80, 60, 40, 20, 0]
    },
    series: [
      {
        name: "EGFR",
        data: [65, 50, 29]
      }
    ],
    credits: {
      enabled: false
    }
  };

  const tableColumns = [
    {
      dataIndex: "factor",
      key: "factor",
      title: "Health Equity Factor"
    },
    {
      dataIndex: "risk",
      key: "risk",
      title: "Attributed Risk",
      width: "30%"
    }
  ];

  useEffect(() => {
    if (!data) return;
    const riskProfileData: RiskProfileData[] = data.map(profile => ({
      timestamp: moment(
        profile.create_date,
        "ddd, DD MMM YYYY hh:mm:ss z"
      ).format("MMM DD, YYYY | hh:mm a"),
      riskData: profile.risk_profile.split(/\n/).map(item => {
        const [key, value] = item.split(/:/);
        return {
          key: key,
          value: value.replace("#R", "").replace("#", "")
        };
      })
    }));
    setRiskProfileData(riskProfileData);
  }, [data]);

  return (
    <>
      <CommonPatientExtensionItems />
      <RiskProfileTitleText>Risk Profile</RiskProfileTitleText>
      <Row gutter={8}>
        <Col span={8}>
          <Col span={24}>
            <Row gutter={10}>
              <Col
                span={12}
                sm={{ span: 16 }}
                style={{
                  alignItems: "center",
                  display: "flex"
                }}
              >
                <CardComponent
                  cardHeight={"50px"}
                  cardWidth={"100%"}
                  title="Clinical Risk Score: 0.8"
                />
              </Col>
              <Col span={12} sm={{ span: 8 }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <CardComponent cardHeight={"100%"} cardWidth={"100%"}>
              <Row gutter={10} style={{ flexDirection: "column" }}>
                <StyledContentContainer>
                  <LabelTextComponent>
                    Current CKD Stage: Stage 3b
                  </LabelTextComponent>
                  <ContentContainer marginTop="15px">
                    <b>Diagnostic Values</b>
                    <ContentContainer marginTop="5px">
                      <StyledText>
                        eGFR : 29 (measured on 01-25-2023)
                      </StyledText>
                      <StyledText>
                        uACR : 770 (measured on 01-25-2023)
                      </StyledText>
                      <StyledText>
                        HgA1C: 8.9 (measured on 01-25-2023)
                      </StyledText>
                      <StyledText>
                        Non-HDL Chol: 99 (measured on 01-25-2023)
                      </StyledText>
                    </ContentContainer>
                    <br />
                    <b>Physical Values</b>
                    <ContentContainer marginTop="5px">
                      <StyledText>BMI : 42 (measured on 02-15-2023)</StyledText>
                      <StyledText>
                        BP: 175/100 (measured on 01-11-2023)
                      </StyledText>
                      <StyledText>
                        Heart Failure : EF 45 (measured on 02-15-2023)
                      </StyledText>
                      <StyledText>Last Hospitalization – Nov 2022</StyledText>
                    </ContentContainer>
                  </ContentContainer>
                </StyledContentContainer>
              </Row>
            </CardComponent>
          </Col>
          <Col
            span={24}
            style={{
              marginTop: 10
            }}
          >
            <CardComponent cardHeight={"100%"} cardWidth={"100%"}>
              <HighchartsReact
                highcharts={Highcharts}
                options={lineChartOption}
              />
            </CardComponent>
          </Col>
          <Col
            span={24}
            style={{
              marginTop: 10
            }}
          >
            <CardComponent cardHeight={"100%"} cardWidth={"100%"}>
              <Row gutter={[10, 10]} style={{ flexDirection: "column" }}>
                <LabelTextComponent>
                  CMS Benchmark Cost – $ 35,000
                </LabelTextComponent>
                <LabelTextComponent>
                  Actual YTD Cost – $ 24,950
                </LabelTextComponent>
                <LabelTextComponent>
                  Forecasted Care Cost – $ 39,000
                </LabelTextComponent>
              </Row>
            </CardComponent>
          </Col>
        </Col>
        <Col span={6}>
          <Col
            span={24}
            style={{
              marginTop: "6.3rem"
            }}
          >
            <CardComponent cardHeight={"100%"} cardWidth={"100%"}>
              <Row
                gutter={10}
                style={{
                  flexDirection: "column"
                }}
              >
                <StyledContentContainer>
                  <LabelTextComponent>
                    Progression Risk: CKD 5 (6 months)
                  </LabelTextComponent>
                  <ContentContainer marginTop="15px">
                    <StyledText>{"uACR > 30"}</StyledText>
                    <StyledText>{"eGFR < 30"}</StyledText>
                    <StyledText>{"Diabetes w HbA1C > 8"}</StyledText>
                    <StyledText>Anemia</StyledText>
                  </ContentContainer>
                </StyledContentContainer>
              </Row>
            </CardComponent>
          </Col>
          <Col span={24} style={{ marginTop: 10 }}>
            <CardComponent cardHeight={"100%"} cardWidth={"100%"}>
              <Row
                gutter={10}
                style={{
                  flexDirection: "column"
                }}
              >
                <StyledContentContainer>
                  <LabelTextComponent>
                    Risk of Hospitalization: 1 months (High)
                  </LabelTextComponent>
                  <ContentContainer marginTop="15px">
                    <StyledText>Uncontrolled Hypertension</StyledText>
                    <StyledText>Rapid rise in creatinine</StyledText>
                    <StyledText>Serum albumin low</StyledText>
                  </ContentContainer>
                </StyledContentContainer>
              </Row>
            </CardComponent>
          </Col>
          <Col span={24} style={{ marginTop: 10 }}>
            <CardComponent cardHeight={"100%"} cardWidth={"100%"}>
              <Row
                gutter={10}
                style={{
                  flexDirection: "column"
                }}
              >
                <StyledContentContainer>
                  <LabelTextComponent>
                    Risk of ER Visit: 6 months (Medium)
                  </LabelTextComponent>
                  <ContentContainer marginTop="15px">
                    <StyledText>Cerebrovascular Disease</StyledText>
                    <StyledText>Race</StyledText>
                    <StyledText>{"BMI > 40"}</StyledText>
                  </ContentContainer>
                </StyledContentContainer>
              </Row>
            </CardComponent>
          </Col>
          <Col span={24} style={{ marginTop: 10 }}>
            <CardComponent cardHeight={"100%"} cardWidth={"100%"}>
              <Row
                gutter={10}
                style={{
                  flexDirection: "column"
                }}
              >
                <StyledContentContainer>
                  <LabelTextComponent>
                    Risk of Readmission: 6 months (High)
                  </LabelTextComponent>
                  <ContentContainer marginTop="15px">
                    <StyledText>Previous Hospitalization in 6months</StyledText>
                    <StyledText>Heart Failure</StyledText>
                    <StyledText>Arrhythmia</StyledText>
                  </ContentContainer>
                </StyledContentContainer>
              </Row>
            </CardComponent>
          </Col>
          <Col span={24} style={{ marginTop: 10 }}>
            <CardComponent cardHeight={"100%"} cardWidth={"100%"}>
              <LabelTextComponent>Recommended PHQ9: Yes</LabelTextComponent>
            </CardComponent>
          </Col>
        </Col>
        <Col span={10}>
          <Col span={24}>
            <Row gutter={10}>
              <Col
                span={12}
                sm={{ span: 16 }}
                xs={{ span: 18 }}
                style={{
                  alignItems: "center",
                  display: "flex"
                }}
              >
                <CardComponent
                  cardHeight={"50px"}
                  cardWidth={"100%"}
                  title="Health Equity Risk Score: 0.75"
                />
              </Col>
              <Col span={12} sm={{ span: 8 }} xs={{ span: 6 }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                  containerProps={{ className: "chart-container" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <CardComponent cardHeight={"100%"} cardWidth={"100%"}>
              <Table
                columns={tableColumns}
                dataSource={tableData}
                pagination={false}
                size="large"
              />
            </CardComponent>
          </Col>
        </Col>
      </Row>
      {/* <RiskProfileList
        riskProfileList={riskProfileData}
        isLoading={isLoading || isFetching}
        errorMessage={
          error ? "There was an error in fetching Risk Profile" : ""
        }
      /> */}
    </>
  );
};

const LabelTextComponent = styled.div`
  font-size: 15px;
  font-weight: bold;
  line-height: 17px;
  color: ${Colors.DodgerBlue};
  letter-spacing: 1px;
`;

const StyledContentContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
`;

const ContentContainer = styled.div<ContentContainerProps>`
  align-self: end;
  margin-top: ${props => (props.marginTop ? props.marginTop : "")};
`;

const StyledText = styled.p`
  margin-bottom: 0px;
`;
