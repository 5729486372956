import React, { useState } from "react";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import styled from "styled-components";
import { SearchBox } from "components/searchBox";
import { AppSelect } from "components/inputs";
// import { MedicationIcon } from "./medicationIcon";

interface ExtensionItemsProps {
  setSearchNameValue(value: string): void;
  setSearchSpecialtyValue(value: string): void;
  readonly searchNameValue: string;
  readonly searchSpecialtyValue: string;
}

export const ExtensionItems: React.FC<ExtensionItemsProps> = ({
  setSearchNameValue,
  setSearchSpecialtyValue,
  searchNameValue,
  searchSpecialtyValue
}) => {
  const [filterType, setFilterType] = useState("Name");

  const filterOptions: { label: string; value: string }[] = [
    {
      label: "Name",
      value: "Name"
    },
    {
      label: "Specialty",
      value: "Specialty"
    }
  ];

  return (
    <RowStyled>
      <TitleCol span={4}>All Providers</TitleCol>
      <FiltersCol span={20}>
        <Space size={20}>
          <AppSelect
            value={filterType.charAt(0).toUpperCase() + filterType.slice(1)}
            label="Filter By"
            onChange={type => {
              setFilterType(type);
              setSearchSpecialtyValue("");
              setSearchNameValue("");
            }}
            style={{ marginRight: 5, width: "120px" }}
            options={filterOptions}
            dropdownStyle={{ borderRadius: "8px" }}
          />
          <SearchBox
            placeholder={`Search by ${filterType.toLowerCase()}`}
            value={searchSpecialtyValue || searchNameValue}
            onChange={e => {
              if (filterType === "Name") {
                setSearchSpecialtyValue("");
                setSearchNameValue(e.target.value);
              }
              if (filterType === "Specialty") {
                setSearchNameValue("");
                setSearchSpecialtyValue(e.target.value);
              }
            }}
          />
        </Space>
      </FiltersCol>
    </RowStyled>
  );
};

const TitleCol = styled(Col)`
  padding: 20px;
  font-family: Century Gothic Bold;
`;

const FiltersCol = styled(Col)`
  text-align: left;
  padding: 10px;
`;

const RowStyled = styled(Row)`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
`;
