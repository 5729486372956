import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import Collapse from "antd/lib/collapse";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

import { Dropdown } from "components/dropDownBox";
import { Colors } from "helpers/colors";

const { Panel } = Collapse;

interface KidneyFunctionInfoDropdownProps {
  data: { question: string; answer: ReactNode }[];
}

export const KidneyFunctionHeartRateInfoDropdown: React.FC<
  KidneyFunctionInfoDropdownProps
> = ({ data, children }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  return (
    <Dropdown
      borderradius="8px"
      trigger={["click"]}
      placement="bottomRight"
      dropDownMenuBody={
        <BodyContainer>
          <Collapse
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) =>
              isActive ? <MinusOutlined /> : <PlusOutlined />
            }
            className="site-collapse-custom-collapse"
            style={{ background: Colors.GhostWhite, fontFamily: "sans-serif" }}
          >
            {data.map(item => {
              return (
                <Panel
                  header={item.question}
                  key={item.question}
                  className="site-collapse-custom-panel"
                >
                  <p>{item.answer}</p>
                </Panel>
              );
            })}
          </Collapse>
        </BodyContainer>
      }
      visible={isDropdownVisible}
      setVisible={setIsDropdownVisible}
    >
      {children}
    </Dropdown>
  );
};

const BodyContainer = styled.div`
  padding: 10px;
  width: 300px;
  max-height: 300px;
  background-color: ${Colors.GhostWhite};
  border-radius: 8px;
  overflow: scroll;

  //Q&A container styling
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid ${Colors.Lavender};
    font-size: 12px;
  }

  //Answer styling
  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-left: 40px;
  }

  //Answer container Styling
  .ant-collapse-content-box {
    padding: 0px;
    font-size: 14px;
  }

  //Question styling
  .ant-collapse-header {
    font-weight: 600;
  }

  //Question container styling
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 10px 0px 12px 15px;
  }
`;
