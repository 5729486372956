// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext } from "react";
import styled from "styled-components";
import {
  Heading,
  PreviewVideo,
  QualitySelection,
  CameraSelection,
  Label
} from "amazon-chime-sdk-component-library-react";

import {
  title,
  StyledInputGroup
} from "components/meetings/components/DeviceSelection/Styled";
import { useAWSMeetingState } from "components/meetings/providers/AWSMeetingStateProvider";
import { VideoFiltersCpuUtilization } from "components/meetings/types";
import { VideoTransformDropdown } from "components/meetings/components/DeviceSelection/CameraDevices/VideoTransformDropdown";
import { Switch } from "antd";
import { Colors } from "helpers/colors";
import {
  MeetingContextState,
  MeetingModalContext
} from "contextApis/meetingModalContext";

const CameraDevices: React.FC = () => {
  const { videoTransformCpuUtilization } = useAWSMeetingState();
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);

  const videoTransformsEnabled =
    videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled;

  return (
    <div>
      <Heading tag="h2" level={6} css={title}>
        Video
      </Heading>
      <StyledInputGroup>
        <CameraSelection />
        <Switch onClick={meetingContext.setDeviceSettingsCameraToggle} />{" "}
        <OnMeetingJoinText>
          On Join Meeting: Switch camera{" "}
          <span style={{ fontWeight: "bold" }}>
            {meetingContext.deviceSettingsCameraToggle ? "ON" : "OFF"}
          </span>
        </OnMeetingJoinText>
      </StyledInputGroup>
      <StyledInputGroup>
        <QualitySelection />
      </StyledInputGroup>
      {videoTransformsEnabled ? (
        <StyledInputGroup>
          <VideoTransformDropdown />
        </StyledInputGroup>
      ) : (
        ""
      )}
      <Label style={{ display: "block", marginBottom: ".5rem" }}>
        Video preview
      </Label>
      <PreviewVideo />
    </div>
  );
};

export default CameraDevices;

const OnMeetingJoinText = styled.span`
  color: ${Colors.Black};
  font-size: 14px;
`;
