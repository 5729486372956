import React, { useContext, useEffect, useState } from "react";
import { DiseasesCategory } from "./diseasesCategory";
import { QuickLink } from "./quickLinks";
import { DiseasesAnswer } from "./diseasesAnswer";
import { useMutation } from "react-query";
import { createApiClient } from "apiClient";
import { Skeleton } from "antd";
import HeartIcon from "../../Images/heartIcon.png";
import styled from "styled-components";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";

interface RenderTabContentProps {
  content: any[];
  isLoadingPatientCategory: boolean;
}

export const Cardiovascular: React.FC<RenderTabContentProps> = ({
  content,
  isLoadingPatientCategory
}) => {
  const patientContext = useContext<PatientContextState>(PatientContext);
  const [selectedItemId, setSelectedItemId] = useState<any>();
  const [answers, setAnswers] = useState<any>(null);
  const [titleId, setTitleId] = useState<any>();
  const [applyQuickink, setApplyQuickLink] = useState<any>();

  useEffect(() => {
    if (patientContext.patientEducationResult !== null) {
      setSelectedItemId(patientContext?.patientEducationResult?.title_id);
    }
  }, [patientContext.patientEducationResult]);

  const { mutate: getSelectedAnswer, isLoading: isSelectedAnswerLoading } =
    useMutation(
      async () => {
        return await createApiClient().getSelectedAnswer(selectedItemId);
      },
      {
        mutationKey: [selectedItemId],
        onSuccess: (e: any) => {
          console.log("getSelectedAnswer", e);
          setAnswers(e);
        },
        onError: (err: any) => {
          console.error(err);
        }
      }
    );

  useEffect(() => {
    getSelectedAnswer();
  }, [selectedItemId]);

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 173px)",
        display: "flex"
      }}
    >
      {/* Diseases categories */}
      <DiseasesCategory
        question={content}
        setSelectedItemId={setSelectedItemId}
        titleId={titleId}
      />
      {answers === null && !isSelectedAnswerLoading ? (
        <div
          style={{
            width: "84%",
            height: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <HeaderIcon src={HeartIcon} alt="HeartIcon" />
          <h1
            style={{
              fontWeight: "bold",
              fontFamily: "Century Gothic Bold",
              fontSize: "21px"
            }}
          >
            What would you like to learn about Cardiology?
          </h1>
          <h4
            style={{
              fontSize: "16px",
              marginTop: "-5px"
            }}
          >
            Select an item in the list to learn more about that
            disease/condition
          </h4>
        </div>
      ) : (
        <>
          {isSelectedAnswerLoading ? (
            <div style={{ width: "84%", padding: 15 }}>
              <Skeleton
                active
                paragraph={{ rows: 14 }}
                style={{ width: "100%" }}
              />
            </div>
          ) : (
            <>
              {/* Quick Links */}
              <QuickLink
                answers={answers}
                setQuickLink={setApplyQuickLink}
                applyQuickink={applyQuickink}
              />
              {/* Diseases answers */}
              <DiseasesAnswer
                applyQuickink={applyQuickink}
                answers={answers}
                isSelectedAnswerLoading={isSelectedAnswerLoading}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

const HeaderIcon = styled.img`
  width: 120px;
  margin-bottom: 20px;
`;
