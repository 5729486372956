import React from "react";
import { MENU_ITEM_ICON_DISABLED_COLOR } from "components/sideBar/sideBar";
interface IconProps {
  readonly isDisabled?: boolean;
}

export const MedicalDataCarePlanIcon: React.FC<IconProps> = ({
  isDisabled
}) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M12,0 L12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 L24,12 C24,18.627417 18.627417,24 12,24 L12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
          id="path-1"
        ></path>
        <filter
          x="-54.2%"
          y="-45.8%"
          width="208.3%"
          height="208.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="LeftPannel-Patient-0"
          transform="translate(-13.000000, -214.000000)"
        >
          <g
            id="Care-Plan-icon-Copy-41"
            transform="translate(14.000000, 214.000000)"
          >
            <g id="Care-Plan-icon" transform="translate(7.000000, 6.000000)">
              <g id="Rectangle-Copy-212">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill={isDisabled ? MENU_ITEM_ICON_DISABLED_COLOR : "#7CBA5B"}
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <path
                d="M8.41176471,10.2425831 L8.41176471,16.5882353 L15.4705882,16.5882353 L15.4705882,7.41176471 L11.2393905,7.41176471 L8.41176471,10.2425831 Z M10.559294,6 L16.8823529,6 L16.8823529,18 L7,18 L7,9.55042199 L10.559294,6 Z"
                id="Rectangle"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <rect
                id="Rectangle-Copy-2"
                stroke="#FFFFFF"
                strokeWidth="2"
                x="10.8235294"
                y="12.6470588"
                width="2.23529412"
                height="1"
              ></rect>
              <path
                d="M10.8235294,12.6470588 L13.0588235,12.6470588 L13.0588235,13.0588235 L13.4705882,12.6470588 L14.0588235,12.6470588 L13.7647059,12.3529412 L14.0588235,12.0588235 L13.4705882,12.0588235 L13.0588235,11.6470588 L13.0588235,12.0588235 L10.8235294,12.0588235 L10.8235294,11.6470588 L10.4117647,12.0588235 L9.82352941,12.0588235 L10.1176471,12.3529412 L9.82352941,12.6470588 L10.4117647,12.6470588 L10.8235294,13.0588235 L10.8235294,12.6470588 Z"
                id="Rectangle-Copy-4"
                stroke="#FFFFFF"
                strokeWidth="2"
                transform="translate(11.941176, 12.352941) rotate(-90.000000) translate(-11.941176, -12.352941) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
