import styled from "styled-components";

interface TabInfoContentProps {
  readonly padding?: string;
}

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 18% 1fr;
  height: 100%;
`;

export const SidebarPlaceholder = styled.div`
  position: sticky;
  top: 0px;
  margin-right: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  padding: 5px;
  border-radius: 6px;
  min-height: 100%;
`;
export const TabInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TabTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
`;
export const TabInfoContentHeader = styled.div`
  font-size: 12px;
  font-weight: bold;
`;
export const TabInfoContent = styled.div<TabInfoContentProps>`
  font-size: 14px;
  ${props => (props.padding ? `padding: ${props.padding}` : "")}
`;
export const TabInfoContentContainer = styled.div`
  overflow-y: auto;
  height: calc(100vh - 230px);
`;
export const LicenseItemContainer = styled.div`
  padding: 15px;
  border: 1px solid #dcdfe4;
  border-radius: 8px;
  margin-bottom: 20px;
`;
export const LicenseTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
`;
export const StyledHr = styled.hr`
  border: 0.5px solid #dcdfe4;
`;
export const AboutLogoContainer = styled.div`
  width: 200px;
  height: 200px;
  background-color: #ebf4f8;
  border-radius: 50%;
  margin-left: 30px;
`;
export const AboutLogoTextContainer = styled.div`
  display: grid;
  place-items: center;
`;
export const AboutLogoTextTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;
export const AboutLogoTextSubtitle = styled.div`
  color: #636a79;
  font-size: 14px;
  text-align: center;
`;
