import { Skeleton, Spin, Tabs } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Cardiovascular } from "./Category/Cardiovascular";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { Colors } from "helpers/colors";

interface PatientEducationHeaderProps {
  readonly selectedKeyWordFunction: (flag: string[]) => void;
}

export const PatientEducationHeader: React.FC<PatientEducationHeaderProps> = ({
  selectedKeyWordFunction
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleItemClick = (item: string) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter(selectedItem => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  useEffect(() => {
    selectedKeyWordFunction(selectedItems);
  }, [selectedItems]);

  const {
    isLoading: isLoadingPatientCategory,
    data: patientCategoryData,
    error: patientCategoryError,
    isFetching: PatientCategoryFetching,
    refetch: refetchPatientSurveyNotification
  } = useQuery("PatientSurveyNotification", async () => {
    return await createApiClient().getCategoricalData();
  });

  useEffect(() => {
    refetchPatientSurveyNotification();
  }, [patientCategoryData]);

  return (
    <MainContainer>
      <HeaderContainer>
        <TitleWrapper>
          <TitleText>Categories</TitleText>
        </TitleWrapper>
      </HeaderContainer>
      {isLoadingPatientCategory ? (
        <div
          style={{
            width: "100vw",
            marginTop: "15%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <CustomTabs defaultActiveKey="" type="card" size={"small"}>
          {patientCategoryData?.map((item: any, index: any) => (
            <Tabs.TabPane
              tab={item.title}
              key={index}
              style={{ marginTop: "0px" }}
            >
              <Cardiovascular
                content={item}
                isLoadingPatientCategory={isLoadingPatientCategory}
              />
            </Tabs.TabPane>
          ))}
        </CustomTabs>
      )}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  background-color: #fffff;
`;

const HeaderContainer = styled.div`
  letter-spacing: 0.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  margin: 15px 0px 15px 0px;
`;

const TitleText = styled.div`
  font-size: 1rem;
  font-family: Century Gothic;
  color: white;
  font-weight: 1000;
  color: #1f8cff;
  padding-left: 20px;
`;

const CustomTabs = styled(Tabs)`
  .ant-tabs-tab {
    font-size: 12px !important;
    background: ${Colors.White} !important;
    border: none !important;
    border-radius: 3px 3px 0px 0px !important;
    padding: 8px 30px 8px 30px !important;
    font-weight: bold;
  }
  .ant-tabs-tab-active {
    box-shadow: 2px 2px solid black !important;
    background: #1f8cff !important;
    font-size: 12px !important;
    .ant-tabs-tab-btn {
      color: white !important;
    }
  }
`;

export default PatientEducationHeader;
